import {
  AppBar,
  Button,
  Grid,
  Link,
  SvgIcon,
  Toolbar,
  Typography
} from "@mui/material";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
} from "react-icons/fa6";


const numberSX = {
  fontSize: 14,
  fontWeight: "600",
  letterSpacing: "0.1em",
  width: "7.14vw",
  color: "#62B6D0",
  "@media screen and (max-width: 1023px)": {
    width: "14.28vw",
  },
};

const titleSx = {
  fontSize: "4vw",
  fontFamily: "Changa",
  color: "#F1B9D0",
  lineHeight: "1em",
  fontWeight: "500",
  letterSpacing: "0.1em",
  display: "flex",
  alignItems: "center",
  "@media (max-width: 960px)": {
    fontSize: "48px !important",
  },
  "@media (max-width: 430px)": {
    fontSize: "28px !important",
  },
  "&:hover": {
    color: "rgba(98,182,208) !important",
  },
};


export default function AppNavbarMenuContent({ drawerClose }) {
  return (
    <Grid
      sx={{
        width: "100%",
        alignContent: "center",
        fontFamily: "Changa",
      }}
    >
      <AppBar            
        sx={{ 
          position: "relative",
          background: "transparent", 
          boxShadow: "none", 
          transform: "translateY(0px)"
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "100%",
            paddingLeft: "5px !important",
            paddingTop: "15px !important",
            paddingRight: "5px !important"
          }}
        >
          <Link
            style={{ textDecoration: "none" }}
            className="link"
            href="/"
            onClick={drawerClose}
          >
            <Grid
              style={{
                lineHeight: "1.6em",
                display: "flex",
                alignItems: "center",
                minWidth: "72px",
                minHeight: "72px",
                justifyContent: "center",
                paddingTop: "20px",
                paddingLeft:"7.14vw"
              }}
            >
              <img
                src="/images/logo.png"
                style={{ width: "80px" }}
                alt="nav-logo"
              />
            </Grid>
          </Link>
          <Button
            disableRipple
            style={{
              position: 'relative',
              backgroundColor: "transparent",
              pointerEvents: "all",
              transform: 'translateY(0) !important',
              minWidth: "72px",
              minHeight: "72px",
              paddingRight: "7.14vw",
            }}
            onClick={drawerClose}
          >
            <SvgIcon
              viewBox="0 0 21 21"
              sx={{
                color: "white",
                position: "absolute",
                maxWidth: "44px",
                minWidth: "36px",
                width: "100%",
                height: "auto"
              }}
            >
              <path
                id="rect847"
                d="M 4.7186434,1.4582859 H 16.471634 a 3.5282337,3.5282337 0 0 1 3.528233,3.5282337 V 15.279148 a 3.820306,3.820306 0 0 1 -3.820306,3.820306 H 4.4265711 A 3.5282337,3.5282337 0 0 1 0.89833741,15.57122 V 5.2785919 A 3.820306,3.820306 0 0 1 4.7186434,1.4582859 Z"
              />
            </SvgIcon>
            <SvgIcon
              className="transform-close"
              viewBox="0 0 21 21"
              sx={{
                color: "white",
                position: "absolute",
                maxWidth: "44px",
                minWidth: "36px",
                width: "100%",
                height: "auto",
              }}
            >
              <g
                fill="#62B6D0"
                fillRule="evenodd"
                stroke="#62B6D0"
                strokeLinecap="round"
                strokeLinejoin="round"
                id="g10"
                style={{ pointerEvents: "bounding-box" }}
              >
                <path
                  id="circle2"
                  d="m 6.8614992,6.8288703 a 0.51737178,0.51677148 44.159117 0 1 -0.51678,0.5167632 0.51737178,0.51677148 44.159117 0 1 -0.517381,-0.5173622 0.51737178,0.51677148 44.159117 0 1 0.516781,-0.516764 0.51737178,0.51677148 44.159117 0 1 0.51738,0.517363 z"
                  style={{ strokeWidth: 0.558536 }}
                />
                <path
                  id="circle4"
                  d="m 13.958074,6.5694281 a 0.51737178,0.51677148 44.159117 0 1 -0.51678,0.5167632 0.51737178,0.51677148 44.159117 0 1 -0.517381,-0.5173631 0.51737178,0.51677148 44.159117 0 1 0.516781,-0.5167627 0.51737178,0.51677148 44.159117 0 1 0.51738,0.5173626 z"
                  style={{ strokeWidth: 0.558536 }}
                />
                <path
                  id="circle6"
                  d="M 7.045732,13.730397 A 0.51737178,0.51677148 44.159117 0 1 6.5289513,14.24716 0.51737178,0.51677148 44.159117 0 1 6.0115711,13.729797 0.51737178,0.51677148 44.159117 0 1 6.5283514,13.213034 0.51737178,0.51677148 44.159117 0 1 7.045732,13.730397 Z"
                  style={{ strokeWidth: 0.558536 }}
                />
                <path
                  id="circle8"
                  d="m 13.905266,13.569728 a 0.51737178,0.51677148 44.159117 0 1 -0.51678,0.516763 0.51737178,0.51677148 44.159117 0 1 -0.517381,-0.517362 0.51737178,0.51677148 44.159117 0 1 0.516781,-0.516764 0.51737178,0.51677148 44.159117 0 1 0.51738,0.517363 z"
                  style={{ strokeWidth: 0.558536 }}
                />
              </g>
            </SvgIcon>
          </Button>
        </Toolbar>
      </AppBar>
      <Grid
        container
        sx={{
          display: "flex",
          flexWrap: "initial",
          alignItems: "center",
          position: "relative",
          zIndex: 1,
          minHeight: "561px",
          paddingLeft: "7.14vw",
          "@media screen and (min-width: 1024px)": {
            height: "calc(100% - 7.14vw)",
            paddingBottom: "7.14vw",
          },
          "@media screen and (max-width: 1023px)": {
            flexWrap: "wrap",
            alignContent: "space-evenly",
            paddingRight: "7.14vw",
          },
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <Grid
            sx={{
              width: "50%",
              marginBottom: "92px",
              "@media screen and (max-width: 1023px)": {
                marginBottom: "24px",
                width: "100%",
              },
            }}
          >
            <Link
              href={"/"}
              style={{ textDecoration: "none" }}
              className="link"
              onClick={drawerClose}
            >
              <Typography sx={titleSx} component={'span'}>
                <Typography component={'span'} sx={numberSX}>01 -</Typography>
                Home
              </Typography>
            </Link>
          </Grid>
          <Grid
            sx={{
              width: "50%",
              marginBottom: "92px",
              "@media screen and (max-width: 1023px)": {
                marginBottom: "24px",
                width: "100%"
              }
            }}
          >
            <Link
              href="/about"
              style={{ textDecoration: "none" }}
              className="link"
              onClick={drawerClose}
            >
              <Typography sx={titleSx} component={'span'}>
                <Typography component={'span'} sx={numberSX}>02 -</Typography>
                About
              </Typography>
            </Link>
          </Grid>
          <Grid
            sx={{
              width: "50%",
              marginBottom: "92px",
              "@media screen and (max-width: 1023px)": {
                marginBottom: "24px",
                width: "100%"
              }
            }}
          >
            <Link
              href={"/programs"}
              style={{ textDecoration: "none" }}
              className="link"
              onClick={drawerClose}
            >
              <Typography sx={titleSx} component={'span'}>
                <Typography sx={numberSX} component={'span'}>03 -</Typography>
                Our Programs
              </Typography>
            </Link>
          </Grid>
          <Grid
            sx={{
              width: "50%",
              marginBottom: "92px",
              "@media screen and (max-width: 1023px)": {
                marginBottom: "24px",
                width: "100%"
              }
            }}
          >
            <Link
              href="/about/leadership"
              style={{ textDecoration: "none" }}
              className="link"
              onClick={drawerClose}
            >
              <Typography component={'span'} sx={titleSx}>
                <Typography component={'span'} sx={numberSX}>04 -</Typography>
                Leadership
              </Typography>
            </Link>
          </Grid>
          <Grid
            sx={{
              width: "50%",
              "@media screen and (max-width: 1023px)": {
                marginBottom: "24px",
                width: "100%"
              }
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              className="link"
              href="/contact"
              onClick={drawerClose}
            >
              <Typography component={'span'} sx={titleSx}>
                <Typography component={'span'} sx={numberSX}>05 -</Typography>
                Contact Us
              </Typography>
            </Link>
          </Grid>
           <Grid
            sx={{
              width: "50%",
              "@media screen and (max-width: 1023px)": {
                marginBottom: "24px",
                width: "100%"
              }
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              className="link"
              href="/philanthropic-spectrum-survey"
              onClick={drawerClose}
            >
              <Typography component={'span'} sx={titleSx}>
                <Typography component={'span'} sx={numberSX}>06 -</Typography>
                Our Survey
              </Typography>
            </Link>
          </Grid> 
          <Grid
            sx={{
              width: "50%",
              "@media screen and (max-width: 1023px)": {
                marginBottom: "24px",
                width: "100%"
              }
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              className="link"
              href="/care"
              onClick={drawerClose}
            >
              <Typography component={'span'} sx={titleSx}>
                <Typography component={'span'} sx={numberSX}>07 -</Typography>
                Care Fair
              </Typography>
            </Link>
          </Grid> 
          <Grid
            sx={{
              width: "50%",
              "@media screen and (max-width: 1023px)": {
                marginBottom: "24px",
                width: "100%"
              }
            }}
          >
            <Link
              style={{ textDecoration: "none" }}
              className="link"
              href="/donate"
              onClick={drawerClose}
            >
              <Typography component={'span'} sx={titleSx}>
                <Typography component={'span'} sx={numberSX}>08 -</Typography>
                Support Us
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            width: "100%",
            flexShrink: 0,
            marginTop: 0,
            marginBottom: 0,
            "@media screen and (min-width: 1024px)": {
              flexWrap: "wrap",
              justifyContent: "center",
              alignContent: "space-evenly",
              width: "7.14vw",
            },
            "@media screen and (max-width: 1023px)": {
              justifyContent: "space-evenly",
            },
          }}
        >
          <Grid
            sx={{
              color: "white",
              "@media screen and (min-width: 1024px)": {
                width: "100%",
                textAlign: "center",
              },
              "&:hover": {
                color: "rgba(98,182,208) !important",
              },
            }}
          >
            <Link
              href="https://www.linkedin.com/company/clarintel/about/"
              rel={"noopener noreferrer"}
              target="_blank"
              className="link"
              sx={{ color: "#62B6D0" }}
            >
              <FaLinkedin size={20} />
            </Link>
          </Grid>
          <Grid
            sx={{
              color: "white",
              "@media screen and (min-width: 1024px)": {
                width: "100%",
                textAlign: "center",
              },
              "&:hover": {
                color: "rgba(98,182,208) !important",
              },
            }}
          >
            <Link
              href="https://www.facebook.com/profile.php?id=61552601214541"
              rel={"noopener noreferrer"}
              target="_blank"
              className="link"
              sx={{ color: "#62B6D0" }}
            >
              <FaFacebook size={20}  />
            </Link>
          </Grid>
          <Grid
            sx={{
              color: "white",
              "@media screen and (min-width: 1024px)": {
                width: "100%",
                textAlign: "center",
              },
              "&:hover": {
                color: "rgba(98,182,208) !important",
              },
            }}
          >
            <Link
              href="https://twitter.com/ClarintelInc"
              rel={"noopener noreferrer"}
              target="_blank"
              className="link"
              sx={{ color: "#62B6D0" }}
            >
              <FaXTwitter size={20} />
            </Link>
          </Grid>
          <Grid
            sx={{
              color: "white",
              "@media screen and (min-width: 1024px)": {
                width: "100%",
                textAlign: "center",
              },
              "&:hover": {
                color: "rgba(98,182,208) !important",
              },
            }}
          >
            <Link
              href="https://www.instagram.com/clarintel/"
              rel={"noopener noreferrer"}
              target="_blank"
              className="link"
              sx={{ color: "#62B6D0" }}
            >
              <FaInstagram size={20} />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}