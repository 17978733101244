import { Grid, Typography } from '@mui/material';
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { ProgramItem } from '../../components';
import { data } from '../../data/data_file';


export default function ProgramAreasSection() {
  return (
    <Grid container>
      <Grid
        container
        style={{
          fontSize: 10,
          background: "#FFF",
          color: "black",
          paddingTop: "3.14vw",
          paddingBottom: "5w",
          display: "flex",
          alignItems:"center",
          flexWrap: "wrap",
        }}
      >
        <ThemeProvider theme={theme}>
          <Grid
            container
            sx={{
              width: "100%",
              borderBottom: 2, 
              borderBottomColor: 'white',
              paddingTop: "1.0vw",
              "@media screen and (max-width: 768px)": {
                paddingTop: "3.14vw",
                paddingBottom: "0",
              },
            }}
          >
            <Grid 
              container 
              direction="row" 
              sx={{ 
                "@media screen and (max-width: 798px)": {
                  paddingBottom: "0",
                }
              }}
            >              
              <ScrollAnimation animateIn="fadeIn">
                <Grid item xs={12} 
                  style={{ 
                    paddingLeft: "7.14vw",
                    paddingRight: "7.14vw"
                  }}
                >
                  <Typography
                    // variant="h2" 
                  sx={{
                    margin: 0,
                    display: "flex",
                    fontFamily: "Changa",
                    color: "#62B6D0",
                    fontSize:"1.25rem",
                    lineHeight:"1.6rem",
                    "@media screen and (max-width: 900px)": {
                      flexDirection:"column",
                    }}}
                  >
                    Program Areas
                    <Typography
                      variant="h6"
                      component={'span'}
                      style={{
                        letterSpacing: "-0.09em",
                        fontFamily: "Changa",
                        paddingLeft: "5px",
                        lineHeight:"1.6rem",
                      }}
                    >
                      {`----`}
                    </Typography>
                  </Typography>
                </Grid>
              </ScrollAnimation>
            </Grid>
            <Grid item
              xs={12}
              md={12}
              lg={12}
              sx={{
                paddingTop:"3.14vw",
                paddingBottom: { xs: "30px", md: "40px", lg: "60px" }
              }}
              style={{ 
                textAlign: "left", 
                width: "100%",
                paddingLeft: "7.14vw",
                paddingRight: "7.14vw",
              }}
            >
              <Grid item>
                <ScrollAnimation animateIn="fadeIn">
                  <Typography variant="subtitle1" sx={{fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},}}>
                    {data.programs_page.snippet}
                  </Typography>
                </ScrollAnimation>
              </Grid>
            </Grid>
            <Grid 
              container 
              style={{ 
                boxShadow: 0, 
                marginBottom: '7.14vw', 
                wordBreak: "break-word"
              }}
            >
              {data.program_areas.map((i, k) => (
                <Grid key={k} style={{ width: "100%" }}>
                  <ProgramItem  item={i} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}


let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h3 = {
  fontFamily: "Changa",
  fontSize: 48,
  lineHeight:"48px",

  fontWeight: 500,
  "@media (max-width: 1440px)": {
    fontSize: "40px !important",
    lineHeight:"40px"
  },
  "@media screen and (max-width: 1200px)": {
    marginTop: 0,
  },
};

theme.typography.subtitle1 = {
  

  fontWeight: 500,
  fontFamily: "Changa",
  // "@media screen and (max-width:1440px)": {
  //   fontSize: 40
  // },
};