import React, { useEffect } from "react";
import AirProgramDataSection from "../sections/DACSection/AirProgramData";
import EventCollabSection from "../sections/DACSection/EventCollabSection";


export default function AirProgramPage({op_data,flyer_data,page_title}) {

  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0)
  }, []);
  return (
    <main> 
     
      <AirProgramDataSection op_data={op_data} flyer_data={flyer_data}/>

    <EventCollabSection/>
    </main>
  )
}
