import {
  Box, Grid, Typography
} from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { SectionHeader } from "../../components";
import { data } from '../../data/data_file';
export default function ValuesSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  theme.typography.h3= {
    color: "#62B6D0", 
    fontFamily: "Changa",
      fontSize: "50px",
      fontWeight:500,
      "@media (max-width: 1440px)": {
        fontSize: "40px !important",
      },
      "@media screen and (max-width: 1200px)": {
        marginTop:0,
      },
    };
  theme.typography.subtitle1 = {
    lineHeight: "1.1em",
    fontSize:"1.5rem",
    "@media screen and (max-width: 992px)": {
      width: "100%",
      fontSize:"1.3rem",
    },
    "@media screen and (max-width: 798px)": {
      fontSize: "1.1rem", 
    },
    fontWeight: 500,
    color: "#000",
    fontFamily: "Changa",
    paddingBottom:"20px",
    // "@media screen and (max-width:1440px)": {
    //   fontSize:20
    // },
  };
  return (
    <Grid
    container
    style={{
      fontSize: 10,
      background: "white",
      padding: "7.28vw 7.14vw 0px",
      flexWrap: "wrap",
    }}
    sx={{
      "@media screen and (max-width: 640px)": {
        // padding:"28.56vw 14.28vw !important",
      },
    }}
  >            
  <ThemeProvider theme={theme}>
      <Grid style={{ width: "100%" }} sx={{ borderBottom: 2, borderBottomColor: 'white'}}>
        <Box sx={{ lineHeight: '1.1em' }}>
          <Grid item style={{ width: "100%" }}>
                      <ScrollAnimation animateIn="fadeIn"> 

          <SectionHeader sectionTitle={"Values"} />
          </ScrollAnimation>

        </Grid>          
        </Box>
        {data.about_us.values.map((item,index) => (
        <Grid key={index} container  sx={{ boxShadow: 0, wordBreak:"break-word" }}>
            <Grid style={{width:"100%"}}>
              <Grid sx={{  
                width: '100%',
                display:"flex", 
                flexWrap: "wrap", 
                borderBottom: '2px solid #62B6D0', 
                marginBottom: '2.8rem', 
                paddingBottom: '.8rem',
                alignItems:"center",
                "@media screen and (max-width: 640px)": {
                  justifyContent:"center",
                  // textAlign:"center",
                },
                }}>
                <Grid sx={{ 
                  width: '30%',
                  "@media screen and (max-width: 640px)": {
                    width:"100%",
                  },
                  }}>
                  <ScrollAnimation animateIn="fadeInDown" >
                    <Typography sx={{ color: '#62B6D0', fontSize: 30, padding:0, fontFamily:"Changa"  }}>00{index+1}</Typography>
                  </ScrollAnimation>
                </Grid>
                <Grid  sx={{ 
                  width:"70%",
                  "@media screen and (max-width: 640px)": {
                    width:"100%",
                  },
                  }} >
                  <ScrollAnimation animateIn="fadeInDown" >
                    <Typography variant="h3" >{item.title}</Typography>
                    <Typography variant="subtitle1" >{item.description}</Typography>
                  </ScrollAnimation>
                </Grid>
              </Grid>
              
            </Grid>
        </Grid>
        ))}
      </Grid>
      </ThemeProvider>
      </Grid>
  );
}
