export const philanthropicActionOptions = [
  { value: "volunteer", label: "Volunteering", color: "#00B8D9", isFixed: true },
  { value: "donation", label: "Donating", color: "#0052CC", },
  { value: "petition", label: "Petition Signing", color: "#5243AA" },
  { value: "pledge", label: "Pledge Signing", color: "#FF5630", isFixed: true },
  { value: "word_of_mouth", label: "Word of Mouth", color: "#FF8B00" },
  { value: "rectuitment", label: "Recruitment", color: "#FFC400" },
  { value: "employment", label: "Employment", color: "#36B37E" },
  { value: "social_media", label: "Social Media Support", color: "#36B37E" },
  { value: "other", label: "Other", color: "#36B37E" },
  { value: "not_applicable", label: "Not Applicable", color: "#0052CC", },
];

export const philanthropicInfluences = [
  { value: "family", label: "Family", color: "#0052CC", },
  { value: "friends", label: "Friends", color: "#0052CC", },
  { value: "tax", label: "Tax Benefits", color: "#FF5630", isFixed: true },
  { value: "news_media", label: "News & Media", color: "#FF8B00" },
  { value: "social_media", label: "Social Media", color: "#FF8B00" },
  { value: "current_events", label: "Current Events", color: "#FF8B00" },
  { value: "nonprofit_belief", label: "Belief in the Nonprofit", color: "#FF8B00" },
  { value: "action_belief", label: "Belief in the Action", color: "#FFC400" },
  { value: "faith", label: "Faith or Religion", color: "#36B37E" },
  { value: "experience", label: "Personal Experience", color: "#36B37E" },
  { value: "status", label: "Social Status", color: "#36B37E" },
  { value: "mental", label: "Mental Health", color: "#36B37E" },
  { value: "community", label: "Community Health & Welfare", color: "#36B37E" },
  { value: "community", label: "Community Empowerment", color: "#36B37E" },
  { value: "drive", label: "Inner Drive", color: "#36B37E" },
  { value: "benefit", label: "Social Benefit", color: "#36B37E" },
  { value: "zodiac", label: "Zodiac Sign", color: "#36B37E" },
  { value: "personal_si", label: "Personal Connection to the Social Issue or Cause", color: "#00B8D9", isFixed: true },
  { value: "personal_pa", label: "Personal Connection to the Philanthropic Action", color: "#5243AA" },
  { value: "personal_co", label: "Personal Connection to the Nonprofit or Community Based Organization", color: "#5243AA" },
  { value: "other", label: "Other", color: "#36B37E" },
  { value: "not_applicable", label: "Not Applicable", color: "#0052CC", },
];

export const factorsForPhilanthropy = [
  { value: "personal_si", label: "Personal Connection to the Social Issue", color: "#00B8D9", isFixed: true },
  { value: "personal_pa", label: "Personal Connection to the Philanthropic Action", color: "#5243AA" },
  { value: "personal_co", label: "Personal Connection to the Nonprofit or Community Based Organization", color: "#5243AA" },
  { value: "purpose", label: "Purpose of Organization", color: "#0052CC", },
  { value: "mission", label: "The Organization's Mission", color: "#0052CC", },
  { value: "social", label: "Social Gain & Influence", color: "#FF5630", isFixed: true },
  { value: "transparent", label: "Transparency", color: "#FF8B00" },
  { value: "professional", label: "Professionalism", color: "#FFC400" },
  { value: "stats", label: "Statistics and Data", color: "#36B37E" },
  { value: "communication", label: "Clear, Consistent and Personlaized Communication", color: "#36B37E" },
  { value: "stories", label: "Personal Stories & Testimonials", color: "#36B37E" },
  { value: "budget", label: "Personal Budget", color: "#36B37E" },
  { value: "location", label: "Location", color: "#36B37E" },
  { value: "action_type", label: "Type of Engagement Opportunity", color: "#36B37E" },
  { value: "other", label: "Other", color: "#36B37E" },
  { value: "not_applicable", label: "Not Applicable", color: "#0052CC", },
];

export const nonprofitActionsforTrust = [
  { value: "marketing", label: "Execute Attractive Markting Campaigns", color: "#00B8D9", isFixed: true },
  { value: "positive_impact", label: "Prove Positive Impact", color: "#5243AA" },
  { value: "meaningful", label: "Show the Human Element of Each Operation and Endevour", color: "#5243AA" },
  { value: "social", label: "Have Social Gain & Influence", color: "#FF5630", isFixed: true },
  { value: "professional", label: "Be Professional", color: "#FFC400" },
  { value: "stories", label: "Share Personal Stories & Testimonials", color: "#36B37E" },
  { value: "purpose_mission", label: "Showcase the Purpose and Mission of the Organization", color: "#0052CC", },
  { value: "communication", label: "Clear, Consistent and Personlaized Communication", color: "#36B37E" },
  { value: "other", label: "Other", color: "#36B37E" },
  { value: "not_applicable", label: "Not Applicable", color: "#0052CC", },
];

export const trackYourImpact = [
  { value: "social_media", label: "Social Media", color: "#00B8D9", isFixed: true },
  { value: "local_news", label: "Local News Sources", color: "#00B8D9", isFixed: true },
  { value: "national_news", label: "National News Sources", color: "#00B8D9", isFixed: true },
  { value: "nonprofit_communications", label: "Nonprofit and Commmmunity Organization Websites, Newsletters, and Emails", color: "#00B8D9", isFixed: true },
  { value: "word_of_mouth", label: "Word of Mouth", color: "#00B8D9", isFixed: true },
  { value: "personal", label: "Personal Tracking Measures", color: "#00B8D9", isFixed: true },
  { value: "free", label: "Free and Open Source Tools and Services", color: "#00B8D9", isFixed: true },
  { value: "paid", label: "Paid Tools and Services", color: "#00B8D9", isFixed: true },
  { value: "friend", label: "Friend Connected to the Organization or Nonprofit", color: "#00B8D9", isFixed: true },
  { value: "family", label: "Family Connected to the Organization or Nonprofit", color: "#00B8D9", isFixed: true },
  { value: "research", label: "Personal Research", color: "#00B8D9", isFixed: true },
  { value: "follow_up", label: "Nonprofit or Community Organization Follow Ups", color: "#00B8D9", isFixed: true },
  { value: "other", label: "Other", color: "#36B37E" },
  { value: "no_tracking", label: "I don't track my impact.", color: "#00B8D9", isFixed: true },
];