import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default function EventCollabSection() {
  
  const text = "Interested in Collaborating, Partnering, or Discussing More with Us?";
  const text2 =
    "Interested? Connect with us.";
    const text3 =
    "Through free programs, data, and technology, we make it easy to access social issue information, philanthropic opportunities and organizations that drive a positive impact you can trust within your community and beyond.";
  
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  theme.typography.h3 = {
    fontSize: "2.7rem",
    lineHeight: "2.7rem",
    "@media (max-width: 1077px)": {
      fontSize: "2.5rem",
      lineHeight: "2.5rem",
    },
    "@media (max-width: 900px)": {
      fontSize: "2.3rem",
      lineHeight: "2.3rem",
    },
  };
  theme.typography.h4 = {
    fontSize: "1.2rem",
    fontFamily: "Changa",
    lineHeight: "1.2rem",
    fontWeight: 500,
  
    "@media (max-width: 900px)": {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  };
  

  theme.typography.subtitle1 = {
    lineHeight: "1.2em",
    marginLeft: "7.14vw",
    fontSize: "2rem",
    fontWeight: 500,
    color: "#000",
    fontFamily: "Changa",
    alignSelf: "center",
    "@media (max-width: 1077.8443113772px)": {
      fontSize: "18px !important",
    },
    "@media screen and (max-width: 790px)": {
      marginLeft: "0",
      marginTop: "16px",
    },
  }


  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        // padding: "150px 0px 0px",
        display: "flex",
        flexWrap: "wrap",
        paddingTop: "7.14vw",


      }}
    >
      <ThemeProvider theme={theme}>
     
      <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            paddingBottom: "50px",
            alignItems: "center",
            display: "flex",
            flexWrap: "initial",
            flexDirection: "row",
            overflow: "hidden",

            "@media screen and (max-width: 790px)": {
              width: "100%",
              flexDirection: "column",

            },
          }}
        >

          <Grid

            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              top: "-1px",
              background: "#FFF",
              width: "50vw",
              height: "100%",
              "@media screen and (min-width: 790px)": {
                // maxHeight: "50vw",
              },
              "@media screen and (max-width: 790px)": {
                width: "100vw",
              },
            }}
          >
            <Grid
              sx={{

                "@media screen and (min-width: 790px)": {
                  // height: "calc(100% - 7.14vw)",
                  height: "100% ",
                  padding: "0 3.14vw",


                  // maxHeight: "calc(100% - 72px)",
                },
                "@media screen and (max-width: 790px)": {
                  height: "100% ",
                  padding: "3.14vw",

                },
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
                boxSizing: "inherit",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  height: "100%",
                  "@media screen and (min-width: 790px)": {
                    padding: "0 4.14vw",
                    width:"50vw",

                  },
                  "@media screen and (max-width: 790px)": {
                    padding: "0 3.14vw",
                    width:"100%",
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={true}
                  
                  style={{ width: "100%", height:"100%", display:"flex", flexDirection:"column", justifyContent:"center"  }}
                >
                    <Typography
                      color="black"
                      gutterBottom
                    variant="h3"
                    style={{
                      fontFamily: "Changa",
                      fontWeight: 700,
                    }}
                    >
                      {text}
                    </Typography>
           
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Grid>
          <ScrollAnimation
                animateIn="fadeInRight"
                initiallyVisible={true}
                style={{  display:"flex", justifyContent:"flex-end", width:"100%"}}
              >
          <Grid
            sx={{
              display:"flex",
              justifyContent:"center",
              position: "relative",
              width: "48vw" ,      
              paddingLeft:"2vw",     
               minHeight: 400,
              background: "#FFF",
              overflow: "hidden",
              borderTopLeftRadius: 30,
              borderBottomLeftRadius: 30,
              "@media screen and (max-width: 790px)": {
                width: "100%",
                minHeight: "65vw",
                padding:0,
                borderRadius: 0,
              },
            }}
          >
            
            <Grid
              container
              sx={{
                maxHeight:400,
                width: "calc(100% )",
                height: "calc(100% )",
                // maxWidth: "calc(100% - 72px)",
                // maxHeight: "calc(100% - 36px)",
                // width: "calc(100% )",
                // height: "calc(100%)",
                // maxWidth: "calc(100% )",
                // maxHeight: "calc(100% )",
                // padding:"72px",
                borderTopLeftRadius: 30,
                overflow:"hidden",
                    borderBottomLeftRadius: 30,
                position: "absolute",
                zIndex: 1,
                right: 0,
                backgroundSize: "cover",
                "@media screen and (max-width: 790px)": {
                 
                  borderRadius: 0,
                },
              }}
            >
              
                <img
                  src="/images/opportunities-landing.jpg"
                  style={{
                    
                    width: "100%",
                    height: "100%",
                    "--aspect": "100%",
                    position: "absolute",
                    objectFit: "cover",


                  }}
                  alt={"focus"}
                  loading="lazy"
                />
                <Grid
                  container
                  style={{
                    width: "100%",

                    background: "rgba(0,0,0,.5)",
                    height: "100%",
                    bottom: 0,
                    position: "absolute",
                    // borderBottomLeftRadius: 30,
                    // borderBottomRightRadius: 30,

                  }}
                >


                  <Grid
                    container
                    style={{
                      width: "100%",

                      background: "rgba(0,0,0,.5)",
                      height: "50px",
                      bottom: 0,
                      position: "absolute",
                      // borderBottomLeftRadius: 30,
                      // borderBottomRightRadius: 30,

                    }}
                  >
                    <Typography
                      color="white"
                      variant="h4"
                      gutterBottom
                      sx={{
                        // background: "rgba(0,0,0,.5)",
                        padding: "0px 1.14vw 0px",
                        bottom: 10,
                        zIndex: 10,
                        position: "absolute",
                        "@media screen and (max-width: 790px)": {
                          padding: "0px 3.14vw 0px",
                        },
                      }}
                    >
                      {text2}
                    </Typography>
                  </Grid>
                  <Typography
                    color="white"
                    variant="h4"
                    sx={{
                      fontFamily: "Changa",
                      bottom:50,
                      // bottom: 125,
                      zIndex: 10,
                      padding: "1.14vw",
                      position: "absolute",
                      "@media screen and (max-width: 790px)": {
                        padding: "2.14vw 3.14vw",
                      },
                    }}
                  >
                    {text3}
                  </Typography>
                </Grid>
            </Grid>
          </Grid>
          </ScrollAnimation>

          </Grid>
    </ThemeProvider>
      </Grid>

  )
}
