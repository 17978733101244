import React, { useEffect } from 'react';
import {
  IntroSection, 
  ProgramAreaSection, 
  WhatWeDoSection, 
  WhoWeAreSection,
} from '../sections';
import CollabInterestSection from '../sections/OpportunitySection/InterestedinCollab';
import HelpClarintelSection from '../sections/WhoWeAreSection/HelpClarintelBlock';

export default function Home({page_title}) {
  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0)
  }, []);
  return (
    <main style={{overflowX:"hidden"}}>
      <IntroSection />
      <WhoWeAreSection />
      <WhatWeDoSection />
      <ProgramAreaSection />
      <HelpClarintelSection />
      <CollabInterestSection />
    </main>
  )
}
