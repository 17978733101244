import { Box, Grid, Typography } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { ProgramItem } from '../../components';
import { data } from "../../data/data_file";

export default function ProgramAreaSection() {
  let theme = createTheme();

  theme = responsiveFontSizes(theme);
  theme.typography.h3 = {
    // color: "#000", 
    fontFamily: "Changa",
    fontSize: "48px",
    lineHeight:"48px",
    fontWeight: 500,
    // "&:hover": {
    //   color: "#FFF",
    // },
    "@media (max-width: 1440px)": {
      fontSize: "40px !important",
      lineHeight:"40px",

    },
    "@media screen and (max-width: 1200px)": {
      marginTop: 0,
    },
  };
  theme.typography.h6 = {
    fontSize: "0.6rem",
    "@media (min-width:300px)": {
      fontSize: ".77rem",
    },
    "@media (min-width:500px)": {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.2rem",
    },
  };
  theme.typography.subtitle1 = {
    lineHeight: "1.1em",
    fontSize: "1.1vw",
    fontWeight: 500,
    // color: "#000",
    fontFamily: "Changa",
    // "&:hover": {
    //   color: "#FFF",
    // },
    "@media screen and (max-width:1440px)": {
      fontSize: 20
    },
  };
  
  // function Item({ item }) {
  //   return (
  //     <Link smooth href={item.link}
  //       style={{ textDecoration: 'none', color: "#000" }}

  //       className="link"
  //     >

  //       <Grid
  //         sx={{
  //           "@media (min-width: 992px) and (max-width: 1199px), (min-width: 1200px), (min-width: 1440px) and (max-width: 1800px)":
  //           {
  //             padding: "7rem 7.14vw",
  //             display: "grid",
  //             gridTemplateColumns: "repeat(2,1fr)",
  //             gridTemplateRows: "1fr",
  //             gridTemplateAreas: "title content",
  //             gridRowGap: 0,
  //           },
  //           width: '100%',
  //           display: "flex",
  //           flexWrap: "wrap",
  //           borderBottom: '1px solid rgba(98,182,208,.3)',
  //           paddingTop: '',
  //           padding: "5rem 4rem",
  //           background: "linear-gradient(#FFF, #FFF 60%, #62B6D0 50%, #62B6D0)",
  //           backgroundSize: "100% 250%",
  //           /*trasition effect for background*/
  //           transition: "fill ease-out .1s, background .5s ease,color .5s ease,transform .5s",
  //           alignItems: "center",
  //           color: "#000",
  //           "@media screen and (max-width: 790px)": {
  //             justifyContent: "center",
  //             textAlign: "left",
  //           },
  //           "&:hover": {
  //             backgroundPosition: "100% 100%",
  //             // backgroundPosition: "bottom",
  //             fill: "#FFF",
  //             color: "#FFF !important",

  //             transform: "translateY(-10px)",
  //             // transformOrigin: "bottom center",
  //           },


  //         }}>



  //         <Grid sx={{
  //           paddingRight:"3.14vw",
  //           "@media screen and (max-width: 790px)": {
  //             width: "100%",

  //           },
  //         }} >
  //           <ScrollAnimation animateIn="fadeInDown" >
  //             <Typography variant="h3" >{item.program_area}</Typography>
  //             <Grid sx={{
  //               display: "flex", alignItems: "center", paddingTop:"15px", "@media screen and (max-width: 790px)": {
  //                 justifyContent: "center",
  //                 textAlign: "left", width: "100%", paddingTop: "5px", paddingBottom: "5px",
  //               },
  //             }}>
  //               <svg fill='current' width="15" height="15" x="0" y="0" viewBox="0 0 15 15" ><circle r="6" cx={8} cy={8}
  //                 fill="white" /> <path d="M9 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" ></path><path d="M7.5 1a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zM2 7.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"   ></path></svg>
  //               <Typography variant="subtitle1" sx={{ paddingLeft: "5px",fontWeight:600 }} >Find Out More</Typography>

  //             </Grid>
  //           </ScrollAnimation>
  //         </Grid>
  //         <Grid sx={{
  //           "@media screen and (max-width: 790px)": {
  //             width: "100%",
  //           },

  //         }}>
  //           <ScrollAnimation animateIn="fadeInDown" >
  //             <Typography sx={{ fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
  //                           lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},  "@media screen and (max-width: 790px)": {
  //             maxWidth: "100%",
  //           },maxWidth: "40vw" , padding: 0, margin: 0, fontFamily: "Changa" }}>{item.program_description}</Typography>
  //           </ScrollAnimation>
  //         </Grid>
  //       </Grid>
  //     </Link>
  //   )
  //     ;
  // }

  // function MyList(items) {
  //   return (
  //     <Grid style={{ padding: 0, width: "100%" }}>
  //       {items.map((item) => <Item key={item.id} item={item} />)}
  //     </Grid>
  //   );
  // }
  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "white",
        flexWrap: "wrap",
      }}
      sx={{
        "@media screen and (max-width: 640px)": {
          // padding:"28.56vw 14.28vw !important",
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <Grid style={{ width: "100%" }} sx={{ borderBottom: 2, borderBottomColor: 'white' }}>
          <Box sx={{ lineHeight: '1.1em' }}>
            <ScrollAnimation animateIn="fadeIn">
              <Grid item style={{ width: "100%", paddingBottom: "50px" }}>
                <Typography
                  variant="h2"
                  sx={{
                    margin: 0,
                    display: "flex",
                    fontSize: "1.25rem !important",
                    lineHeight: "1.6rem",
                    fontFamily: "Changa",
                    color: "#62B6D0",
                    padding: "0px 7.14vw 0px ",
                    "@media screen and (max-width: 790px)": {
                      padding: "0px 0px 0px 7.14vw"
                    },
                  }}
                >
                  {`Our Program Areas  `}
                  <Typography
                    variant="h6"
                    component={'span'}
                    style={{
                      letterSpacing: "-0.09em",
                      fontFamily: "Changa",
                      paddingLeft: "5px",
                      lineHeight: "1.6rem",
                    }}
                  >
                    {`----`}
                  </Typography>
                </Typography>
              </Grid>
            </ScrollAnimation>
          </Box>
          {data.program_areas.map((i, k) => (
                <Grid key={k} style={{ width: "100%" }}>
                  <ProgramItem key={k} item={i} />
                </Grid>
              ))}
        </Grid>
      </ThemeProvider>
    </Grid>
  );
}
