import { Grid, Typography } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';
import React from 'react';
import { data } from '../../data/data_file';


export default function WhatWeDoSection() {
  return (
    <Grid container>
      <Grid
        container
        style={{
          fontSize: 10,
          background: "#FFF",
          color: "black",
          paddingLeft: "7.14vw",
          paddingRight: "7.14vw",
          paddingTop: "7.28vw",
          display: "flex",
          alignItems:"center",
          flexWrap: "wrap",
        }}
      >
        <ThemeProvider theme={theme}>
          <Grid
            container
            style={{ textAlign: "left" , justifyContent:"center"}}
            sx={{
              paddingTop: "1.0vw",
              "@media screen and (max-width: 798px)": {
                paddingTop: "7.28vw",
                paddingBottom: "0",
              },
            }}
          >
            <Grid 
              container 
              direction="row" 
              sx={{ 
                "@media screen and (max-width: 900px)": {
                  paddingBottom: "0",
                },
                paddingBottom: "3.14vw", 
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  style={{
                    margin: 0,
                    display: "flex",
                    fontFamily: "Changa",
                    color: "#62B6D0",
                    fontSize:"1.25rem",
                    lineHeight:"1.6rem",
                  }}
                >
                  What We Do
                  <Typography
                    variant="h6"
                    component={'span'}
                    style={{
                      letterSpacing: "-0.09em",
                      fontFamily: "Changa",
                      paddingLeft: "5px",
                      lineHeight:"1.6rem",
                    }}
                  >
                    {`----`}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
            <Grid 
              container 
              direction="row" 
              style={{
                justifyContent: "space-between", 
                display: "flex", 
                alignItems: "stretch" 
              }}
            >
              {data.what_we_do.map((i, k) => (
                <Grid  key={k}
                  item xs={12} sm={12} md={4} lg={4} 
                  style={{
                    margin: 0,
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: "3.14vw"
                  }} 
                >
                  <Grid 
                    elevation={0} 
                    sx={{ 
                      alignItems: "flex-start",
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      flexDirection: 'column', 
                      textAlign: "left", 
                      padding: "0",
                      "@media screen and (max-width: 900px)": {
                        padding:"3.14vw 0 0 0",
                      }, 
                    }}
                  >
                    <Grid 
                      container 
                      direction="column" 
                      alignItems="left" 
                      justifyContent="space-between" 
                      style={{
                        paddingRight: k === 2 ? 0 : "16px",  
                      }}
                    >
                      <Typography 
                        variant="title" 
                        sx={{ 
                          margin:0,
                          "@media screen and (max-width: 1250px)": {
                            maxWidth:"300px"
                          },
                          "@media screen and (max-width: 900px)": {
                            maxWidth:"100%"
                          },
                          minHeight:{ xs: "0", sm: "0", md:"30px", lg: "50px" },
                          fontSize: { xs: "25px", sm: "30px", lg: "30px", xl: "32px", },
                          pb: { xs: "5px", sm: "10px", md: "15px", lg: "30px"  },
                          lineHeight: { xs: "25px", sm: "30px",  lg: "30px", xl: "32px" },
                        }}
                      >
                        {i.title}
                      </Typography>
                      <Typography 
                        variant="subtitle" 
                        sx={{ 
                          fontSize: { 
                            xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"
                          },
                          lineHeight:  { 
                            xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"
                          },
                        }}
                      >
                        {i.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}


let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920
    },
  },
});

theme = responsiveFontSizes(theme);

theme.typography.title = {
  color: "#000", 
  fontFamily: "Changa",
  lineHeight:"1.1em",
  fontWeight: 700
}

theme.typography.subtitle = {
  fontWeight: 200,
  color: "#000", 
  fontFamily: "Changa", 
}

theme.typography.btn1 ={
  fontWeight:700,
  fontFamily: "Changa", 
  fontSize:"1.2rem",
  alignSelf:"center",
}
