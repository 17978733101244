import { validate } from "validate.js";

export const schema = {
  question_1: {
    presence: { allowEmpty: false, message: "is required" },
    // length: {
    //   maximum: 10000,
    // },
  },
  question_2: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: {
      onlyInteger: true,
    },
  },
  question_3: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 500,
    },
  },
  question_4: {
    presence: { allowEmpty: false, message: "is required" },
    // length: {
    //   maximum: 500,
    // },
  },
  question_5: {
    presence: { allowEmpty: false, message: "is required" },
    // numericality: {
    //   onlyInteger: true,
    // },
  },
  question_6: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      minimum: 1,
      maximum: 5,
      tooShort: 'needs to have %{count} words or more',
      tooLong: 'needs to have %{count} words or less',
    }
  },
  question_7: {
    presence: { allowEmpty: false, message: "is required" },
  },
  question_8: {
    presence: { allowEmpty: false, message: "is required" },
  },
  question_9: {
    presence: { allowEmpty: false, message: "is required" },
  },
  question_10: {
    presence: { allowEmpty: false, message: "is required" },
  },
  question_11: {
    presence: { allowEmpty: false, message: "is required" },
  },
  question_12: {
    presence: { allowEmpty: false, message: "is required" },
  },
 
  first_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  last_name: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  email_address: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
  phone_number: {
    presence: { allowEmpty: false, message: "is required" },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^%{num} is not a valid phone number", {
          num: value
        });
      }
    }
  },
  race: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  gender: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  opt_in: {
    presence: false,
  },
  enter_raffle: {
    presence: { allowEmpty: false, message: "is required" },
  },
  umd_student: {
    presence: false,
  },
  uid: {
    presence: false,
    length: {
      maximum: 9,
    },
  },
  instagram_username: {
    presence: false,
  },
  twitter_username: {
    presence: false,
  },
  facebook_username: {
    presence: false,
  },
  linkedin_username: {
    presence: false,
  },
  social_media_follow: {
    presence: false,
  },
};

