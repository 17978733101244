import React from 'react';
import { createTheme, responsiveFontSizes, ThemeProvider, styled } from "@mui/material/styles";
import { Grid, Typography, Stack, Button } from '@mui/material';
import { FaLinkedin, FaInstagram, FaTwitter } from "react-icons/fa";

import './styles.css';

export default function DonateSection() {
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid 
          container 
          justifyContent="center" 
          alignItems="center"
        >
          <Stack
            className="donateSection"
            sx={{
              pt: 5, pb: 5, pl: 2, pr: 2
            }}
          >
            <Grid item sx={{ pb: 2 }}>
              <Grid container direction="column" alignItems="center">
                <Typography variant="donateTitle">Help Clarintel provides</Typography>
                <Typography variant="donateSubtitle">#Technology4PositiveImpact</Typography>
              </Grid>
            </Grid>
            <Grid item sx={{ pb: 4 }}>
              <Grid container direction="row" justifyContent="space-evenly" alignItems="center" spacing={4}>
                <Grid item>
                  <Grid container>
                    <a href="/"><FaLinkedin size={20} color="black" /></a>
                    <Typography variant="socials">@Clarintel</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <a href="/"><FaInstagram size={20} color="black" /></a>
                    <Typography variant="socials">@Clarintel</Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container>
                    <a href="/">
                      <FaTwitter
                        className="socials" 
                        size={20}
                        style={{ 
                          color: "black"
                        }} 
                      />
                    </a>
                    <Typography variant="socials">@Clarintel</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent="center" alignItems="center">
                <CssButton href="/">
                  <Typography variant="donateButtonText">Donate</Typography>
                </CssButton>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}


const CssButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#F1B9D0",
  color: "#ffff",
  fontWeight: 700,
  borderRadius: "40px !important",
  padding: "10px 80px",
  fontSize: '1.5rem',
  "&:hover": {
    backgroundColor: "#FFFFFF",
    color: "#F1B9D0",
  },
  width: '15%'
}));


let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.donateTitle = {
  fontSize: "5.0vw",
  fontWeight: 700,
  color: "#000", 
  fontFamily: "Changa", 
  "@media screen and (min-width: 768px)": {
    fontSize: "4.0vw"
  }
};

theme.typography.donateSubtitle = {
  fontSize: "4.0vw",
  fontWeight: 200,
  color: "#000", 
  fontFamily: "Changa",
  "@media screen and (min-width: 768px)": {
    fontSize: "3.0vw"
  }
};

theme.typography.socials = {
  fontSize: "3.2vw",
  fontWeight: 200,
  color: "#000", 
  marginLeft: 5,
  fontFamily: "Changa",
  "@media screen and (min-width: 768px)": {
    fontSize: "1.6vw"
  }
};

theme.typography.donateButtonText = {
  fontSize: "3.2vw",
  fontWeight: 700,
  color: "#FFFF", 
  fontFamily: "Changa",
  "@media screen and (min-width: 768px)": {
    fontSize: "1.5vw"
  }
};
