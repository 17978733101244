import axios from 'axios';


const donationApiEndpoint = process.env.REACT_APP_DONATION_ENDPOINT;
const emailApiEndpoint = process.env.REACT_APP_EMAIL_ENDPOINT; 
const subscribeApiEndpoint = process.env.REACT_APP_SUBSCRIBE_ENDPOINT;
const surveyApiEndpoint = process.env.REACT_APP_SURVEY_ENPOINT;
const generalAttendeeApiEndpoint = process.env.REACT_APP_GENERAL_ATTENDEE_ENDPOINT;
const nonprofitApiEndpoint = process.env.REACT_APP_NONPROFIT_ATTENDEE_ENDPOINT;
const orderPaymentApiEndpoint = process.env.REACT_APP_ORDER_PAYMENT_ENDPOINT;
const EMAIL_API = axios.create({ baseURL: emailApiEndpoint });
const DONATION_API = axios.create({ baseURL: donationApiEndpoint,withCredentials:false });
const SUBSCRIBE_API = axios.create({ baseURL: subscribeApiEndpoint });
const SURVEY_API = axios.create({ baseURL: surveyApiEndpoint });
const GENERAL_ATTENDEE_API = axios.create({ baseURL: generalAttendeeApiEndpoint });
const NONPROFIT_GENERAL_ATTENDEE_API = axios.create({ baseURL: nonprofitApiEndpoint });
const ORDER_PAYMENT_API = axios.create({ baseURL: orderPaymentApiEndpoint });


const PostGeneralAttendee = async (registrationData, event, event_date) => {
  try {
    const response = await GENERAL_ATTENDEE_API.post(
      '/register-general-attendee', 
      {
        first_name: registrationData.firstName,
        last_name: registrationData.lastName,
        email_address: registrationData.emailAddress,
        phone_number: registrationData.phoneNumber,
        share_info: registrationData.share_info,
        event_reasons: registrationData.event_reasons,
        event: event,
        event_date: event_date,
        agreement: registrationData.agreement,
      },
    );
    const results = response.data;
    return results;
  } catch (err) {
    console.log({ message: err.message });
    throw err.message;
  }
}

const PostNonprofitAttendee = async (registrationData) => {
  try {
    console.log(registrationData);
    const response = await NONPROFIT_GENERAL_ATTENDEE_API.post(
      '/register-nonprofit-attendee', 
      registrationData,
    );
    const results = response.data;
    return results;
  } catch (err) {
    console.log({ message: err.message });
    throw err.message;
  }
}

const SendEmailMessage = async (emailFormData) => {
  const emailMessage = {
    first_name: emailFormData.firstName,
    last_name: emailFormData.lastName,
    email_address: emailFormData.emailAddress,
    phone_number: emailFormData.phoneNumber,
    topic: emailFormData.topic,
    message: emailFormData.message,
    opt_in: emailFormData.opt_in
  };

  try {
    await EMAIL_API.post('/send-email', emailMessage);
  } catch (err) {
    console.error({ message: err.message });
  }
};

const PostDonationReceipt = async (donationData, paymentIntentId, paymentMethodDetails) => {
  const { data } = donationData;
  
  const receiptData = {
    donation_amount: data.donationAmount,
    first_name: data.firstName,
    last_name: data.lastName,
    email_address: data.emailAddress,
    message: data.message,
    phone_number: data.phoneNumber,
    program_description: data.programDescription,
    organization: data.organization ? data.organization : 'Clarintel, Inc.',
    program_title: data.programTitle, 
    payment_intent_id: paymentIntentId, 
    payment_method_type: paymentMethodDetails.paymentMethodType,
    last_4: paymentMethodDetails.last4,
    card_type: paymentMethodDetails.cardType,
  };

  try {
    const response = await DONATION_API.post('/create-donation-receipt', receiptData);
    const results = response.data;

    return results;
  } catch (err) {
    console.log({ message: err });
  }
};

const PostOrderPaymentReceipt = async (orderData, paymentIntentId, paymentMethodDetails) => {
  const { data } = orderData;

  const receiptData = {
    order_amount: data.donationAmount,
    first_name: data.firstName,
    last_name: data.lastName,
    email_address: data.emailAddress,
    message: data.message,
    phone_number: data.phoneNumber,
    order_details: data.programDescription,
    organization: data.organization,
    date_of_delivery: data.dateOfDelivery,
    order: data.programTitle, 
    order_type: data.orderType,
    payment_intent_id: paymentIntentId, 
    payment_method_type: paymentMethodDetails.paymentMethodType,
    last_4: paymentMethodDetails.last4,
    card_type: paymentMethodDetails.cardType,
    timestamp:data.timestamp,
  };

  try {
    const response = await ORDER_PAYMENT_API.post('/create-order-receipt', receiptData);
    const results = response.data;

    return results;
  } catch (err) {
    console.log({ message: err });
  }
};

const CreatePaymentIntent = async (paymentData) => {
  try {
    const response = await DONATION_API.post('/create-payment-intent', paymentData);
    const results = response.data;
    const clientSecret = results.client_secret;

    return clientSecret;
  } catch (err) {
    console.error({ message: err.message });
  }
};

const CreateOrderPaymentIntent = async (paymentData) => {
  try {
    const response = await ORDER_PAYMENT_API.post('/create-payment-intent', paymentData);
    const results = response.data;
    const clientSecret = results.client_secret;

    return clientSecret;
  } catch (err) {
    console.error({ message: err.message });
  }
};

const GetPaymentMethodData = async (id) => {
  try {
    const response = await DONATION_API.get(`/get-customer-payment-method/${id}`);
    const results = response.data;
    
    return {
      paymentMethodType: results.type,
      last4: results.card.last4,
      cardType: results.card.brand
    };
  } catch (err) {
    console.error({ message: err.message });
  }
};

const GetOrderPaymentMethodData = async (id) => {
  try {
    const response = await ORDER_PAYMENT_API.get(`/get-customer-payment-method/${id}`);
    const results = response.data;
    
    return {
      paymentMethodType: results.type,
      last4: results.card.last4,
      cardType: results.card.brand
    };
  } catch (err) {
    console.error({ message: err.message });
  }
};

const PostSubscriber = async (email) => {
  try {
    const response = await SUBSCRIBE_API.post('/add-subscriber', email);
    const results = response.data;
    return results;
  } catch (err) {
    console.log({ message: err.message });
  }
};

const PostSurvey = async (survey) => {
  try {
    const response = await SURVEY_API.post('/send-survey', survey);
    const results = response.data;
    return results;
  } catch (err) {
    console.log({ message: err.message });
  }
}

const SendSubscribedEmail = async (subData) => {
  try {
    console.log(subData);
  } catch (err) {
    console.log({ message: err.message });
  }
}

const api = {
  SendEmailMessage,
  CreatePaymentIntent,
  PostDonationReceipt,
  GetPaymentMethodData,
  PostSubscriber,
  PostSurvey,
  SendSubscribedEmail,
  PostGeneralAttendee,
  PostNonprofitAttendee,
  PostOrderPaymentReceipt,
  CreateOrderPaymentIntent,
  GetOrderPaymentMethodData,
}

export default api;
