import { Button, SvgIcon } from "@mui/material";

export default function AppNavbarButton({ open }) {
  return (
    <Button
      sx={{
        backgroundColor: "transparent",
        width: "90px" ,
        justifyContent:"flex-start",
        display: { xs: 'flex', md: 'none', lg: 'none' },
      }}
      onClick={open}
    >
      <SvgIcon
        viewBox="0 0 21 21"
        sx={{
          color: "white",
          position: "absolute",
          maxWidth: "44px",
          minWidth: "36px",
          width: "100%",
          height: "auto",
        }}
      >
        <path
          id="rect847"
          d="M 4.7186434,1.4582859 H 16.471634 a 3.5282337,3.5282337 0 0 1 3.528233,3.5282337 V 15.279148 a 3.820306,3.820306 0 0 1 -3.820306,3.820306 H 4.4265711 A 3.5282337,3.5282337 0 0 1 0.89833741,15.57122 V 5.2785919 A 3.820306,3.820306 0 0 1 4.7186434,1.4582859 Z"
        />
      </SvgIcon>
      <SvgIcon
        className="transform"
        viewBox="0 0 21 21"
        sx={{
          color: "white",
          position: "absolute",
          maxWidth: "44px",
          minWidth: "36px",
          width: "100%",
          height: "auto",
        }}
      >
        <g
          fill="#7C90F6"
          fillRule="evenodd"
          stroke="#7C90F6"
          strokeLinecap="round"
          strokeLinejoin="round"
          id="g10"
          transform="rotate(45.840883,10.5,10.5)"
          style={{ pointerEvents: "bounding-box" }}
        >
          <path
            id="circle2"
            d="m 6.8614992,6.8288703 a 0.51737178,0.51677148 44.159117 0 1 -0.51678,0.5167632 0.51737178,0.51677148 44.159117 0 1 -0.517381,-0.5173622 0.51737178,0.51677148 44.159117 0 1 0.516781,-0.516764 0.51737178,0.51677148 44.159117 0 1 0.51738,0.517363 z"
            style={{ strokeWidth: 0.558536 }}
          />
          <path
            id="circle4"
            d="m 13.958074,6.5694281 a 0.51737178,0.51677148 44.159117 0 1 -0.51678,0.5167632 0.51737178,0.51677148 44.159117 0 1 -0.517381,-0.5173631 0.51737178,0.51677148 44.159117 0 1 0.516781,-0.5167627 0.51737178,0.51677148 44.159117 0 1 0.51738,0.5173626 z"
            style={{ strokeWidth: 0.558536 }}
          />
          <path
            id="circle6"
            d="M 7.045732,13.730397 A 0.51737178,0.51677148 44.159117 0 1 6.5289513,14.24716 0.51737178,0.51677148 44.159117 0 1 6.0115711,13.729797 0.51737178,0.51677148 44.159117 0 1 6.5283514,13.213034 0.51737178,0.51677148 44.159117 0 1 7.045732,13.730397 Z"
            style={{ strokeWidth: 0.558536 }}
          />
          <path
            id="circle8"
            d="m 13.905266,13.569728 a 0.51737178,0.51677148 44.159117 0 1 -0.51678,0.516763 0.51737178,0.51677148 44.159117 0 1 -0.517381,-0.517362 0.51737178,0.51677148 44.159117 0 1 0.516781,-0.516764 0.51737178,0.51677148 44.159117 0 1 0.51738,0.517363 z"
            style={{ strokeWidth: 0.558536 }}
          />
        </g>
      </SvgIcon>
      {/* <Grid style={{position:"relative", paddingBottom:10, alignSelf:"center" }}> 
      </Grid> */}
    </Button>
  );
}