import { Grid, Typography, Link } from '@mui/material';
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

export default function ProgramItem({ item }) {
  return (
    <Link 
      href={item.link}
       to={item.link}
      style={{ 
        textDecoration: 'none', 
        color: "#000" 
      }}
      className="link"
    >
      <Grid
        sx={{
          "@media (min-width: 992px) and (max-width: 1199px), (min-width: 1200px), (min-width: 1440px) and (max-width: 1800px)": {
            padding: "7.14rem 7.14vw",
            display:"grid",
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateRows: "1fr",
            gridTemplateAreas: "title content",
            gridRowGap: 0,
          },
          width: '100%',
          display: "flex",
          flexWrap: "wrap",
          borderBottom: '1px solid rgba(98,182,208,.3)',
          paddingTop: '',
          padding:"3rem 7.14vw",
          background: "linear-gradient(#FFF, #FFF 60%, #62B6D0 50%, #62B6D0)",
          backgroundSize: "100% 250%",
          transition: "fill ease-out .1s, background .5s ease,color .5s ease,transform .5s",
          alignItems: "center",
          color: "#000",
          "@media screen and (max-width: 798px)": {
            justifyContent: "center",
            textAlign: "left",
          },
          "&:hover": {
            backgroundPosition: "100% 100%",
            fill:"#FFF",
            color: "#FFF !important",
            transform: "translateY(-10px)"
          },
        }}
      >
        <Grid 
          sx={{
            paddingRight:"3.14vw",
            "@media screen and (max-width: 798px)": {
              width: "100%",
            },
          }}
        >
          <ScrollAnimation animateIn="fadeInDown">
            <Typography variant="h3" >{item.program_area}</Typography>
            <Grid style={{ display:"flex",paddingBottom:"15px",  paddingTop:"7px", alignItems:"center" }}>
              <svg fill='current'  width="15" height="15" x="0" y="0" viewBox="0 0 15 15" >
                <circle r="6" cx={8} cy={8} fill="white" /> 
                <path d="M9 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" ></path>
                <path d="M7.5 1a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zM2 7.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"></path>
              </svg>
              <Typography variant="subtitle1" sx={{ paddingLeft:"5px", fontSize:"1.3rem", lineHeight:"1.3rem", fontWeight:600,  "@media screen and (max-width: 900px)": {
            fontSize:"1rem",lineHeight:"1rem", //"color:"rgb(98, 182, 208)","
          }, }}>Find Out More</Typography>
            </Grid>
          </ScrollAnimation>
        </Grid>
        <Grid 
          sx={{
            "@media screen and (max-width: 992px)": {
              width: "100%",
            }
          }}
        >
          <ScrollAnimation animateIn="fadeInDown">
            <Typography 
              sx={{ 
                fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                lineHeight:  { xs: "1.1rem",  md: "1.2rem", lg: "1.5rem"},                
                width: "40vw",
                  "@media screen and (max-width: 992px)": {
                    width: "100%",
                  },
                  "@media screen and (max-width: 798px)": {
                  },
                padding: 0, 
                margin: 0, 
                fontFamily: "Changa" 
              }}
            >
              {item.program_snippet}
            </Typography>
          </ScrollAnimation>
        </Grid>
      </Grid>
    </Link>
  );
}
