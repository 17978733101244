import {
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validate } from "validate.js";
import api from "../../api";
import { schema } from "../AttendeeRegistrationPage/attendeeSchema";

const formValues = {};

export default function Terms({page_title}) {
  const [useMyInfo, setuseMyInfo] = useState(false);
  const registrationForm = useRef();
  const navigate = useNavigate();
  const [characterLimit] = useState(1000);
  const [connectedSocialIssues, setSocialIssues] = useState([]);
  const [event_reasons, setevent_reasons] = useState([]);
  const [repCount, setRepCount] = useState([0]);
  const [registrationSubmitionStatus, setRegistrationSubmitionStatus] = useState({
    alert: false,
    message: '',
    severity: '',
  });

  const storedValue = JSON.parse(
    sessionStorage.getItem("attendee_registration")
  );

  const [formState, setFormState] = useState(
    storedValue
      ? storedValue
      : {
          isValid: false,
          values: formValues,
          touched: {},
          errors: {},
        }
  );

  const isFormComplete = () => {
    return (
      Object.keys(formState?.values).length > 0 &&
      formState.values?.firstName?.length > 0 &&
      formState.values?.lastName?.length > 0 &&
      formState.values?.phoneNumber?.toString().length > 0 &&
      formState.values?.emailAddress?.length > 0 &&
      formState.values?.agreement &&
      formState.isValid
    );
  };

  useEffect(() => {
    const errors = validate(formState.values, schema);
    document.title = page_title;
    window.scrollTo(0, 0)

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleChange = (e) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
  };

  const handleSetValue = (value, setValue, id, type) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [id]: type === 'checkbubble' ? value : Object.values(value.map(i => i.label)),
      },
      touched: {
        ...formState.touched,
        [id]: true,
      },
    }));
    setValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    sessionStorage.removeItem("attendee_registration");
    try {
      const result = await api.PostGeneralAttendee(formState.values, 'The Clarintel Care Fair', '2024-04-04');
      if (result.status === 'success') {
        setRegistrationSubmitionStatus({
          alert: true,
          message: result.message,
          severity: result.status,
        });
        navigate('/care/register/success',{state:{created_at:Date.now(), ...formState.values}});
      } else {
        setRegistrationSubmitionStatus({
          alert: true,
          message: result.message,
          severity: result.status,
        });
        navigate('/care/register/error',{state:{message:result.message}});
      }
    } catch (err) {
      // console.log({ message: err.message });
    }
    setTimeout(() => {
      setRegistrationSubmitionStatus({
        alert: false,
        message: '',
        severity: '',
      });
    }, 3000);
  };

  const hasError = (field) =>
    formState.errors[field] ? (formState.touched[field] ? true : false) : false;

  const terms_data = [
    {
      title: "Purpose",
      text: [
        {
          focusText: null,
          subText:
            "Clarintel’s mission is to promote and redefine social action through clarity and intelligence. With everything that we do, especially our programs, events, and gatherings, we seek to create a respectful, friendly, enjoyable, and inclusive experience within a healthy and safe environment where everyone, and anyone, is treated equally, with fairness and dignity.",
          bullets: null,
        },
      ],
    },
    {
      title: "Applicability",
      text: [
        {
          focusText: null,
          subText:
            "This agreement supplements, and does not affect, the application of other relevant policies, regulations, rules, and laws, including laws regulating the premises in which the Clarintel event takes place. Participants have the duty to respect the local laws and regulations. ",
          bullets: null,
        },
        {
          focusText: null,
          subText:
            "The Clarintel Community Agreement applies to any Clarintel event, which included fairs, meetings, conferences, receptions, scientific and technical events, expert meetings, workshops, exhibits, side events and any other forum or event organized, hosted, or sponsored in whole or part by Clarintel, wherever it takes place.",
          bullets: null,
        },
        {
          focusText: null,
          subText:
            "Clarintel, Inc. is committed to implementing the Clarintel Community Agreement fairly and without discrimination.",
          bullets: null,
        },
      ],
    },
    {
      title: "Expected Conduct",
      text: [
        {
          focusText: "We are Socially Conscious.",
          subText:
            "We are mindful of our surroundings and of other event attendees and venue/contracted support staff. Please respect the rules and policies of the meeting venue, hotels, Clarintel contracted facility, or any other venue associated with a Clarintel event. Participants are encouraged to alert on-premises security personnel and Clarintel staff if you notice a dangerous situation or someone in distress.",
          bullets: null,
        },
        {
          focusText: "We are Respectful.",
          subText:
            "We are mindful of our surroundings and of other event attendees and venue/contracted support staff. Please respect the rules and policies of the meeting venue, hotels, Clarintel contracted facility, or any other venue associated with a Clarintel event. Participants are encouraged to alert on-premises security personnel and Clarintel staff if you notice a dangerous situation or someone in distress.",
          bullets: null,
        },
        {
          focusText: "We are Inclusive.",
          subText:
            "We are committed to upholding the fundamental human rights, dignity and worth of the human person, and equal human rights of all participants in a Clarintel event. Our events are guided by the highest ethical and professional standards, and all participants and attendees are expected to act with integrity and respect towards all participants and attendees. We do not discriminate on any basis, show judgment towards conflicting or different ideals, or attack participants — including all attendees, speakers, exhibitors, partners, and volunteers.",
          bullets: null,
        },
        {
          focusText: "We are Honest and Accountable.",
          subText:
            "All philanthropic activities must be transparent, legal, and ethical, i.e. the public perception of the activity must not be injurious to Clarintel. The following activities are prohibited: a) Programs that raise money on commission; and b) Events involving the promotion or support of a political party or candidate, or those which appear to endorse a political activity or party. Clarintel holds all nonprofits and community-based organizations accountable to the social issues and causes, populations, and communities that they serve. Any discrepancy or dishonesty discovered between nonprofits and community-based organizations, and the cause they support and the populations they serve, will result in whatever actions Clarintel deems appropriate including removal from the event without refund and disqualification from attending future events.",
          bullets: null,
        },
        {
          focusText: "We Listen to Learn and Not to Respond.",
          subText:
            "For our communities to unify and improve, participants are encouraged to participate in constructive discussions with one another and are invited to step outside of their comfort zone. When offering feedback, do so with the understanding that people can change. Likewise, when receiving new information, listen to others with a willingness to change.",
          bullets: null,
        },
        {
          focusText: "We Will Not Generalize Communities.",
          subText:
            "Keep in mind that representatives are the spokespeople of their respective organizations. When representatives speak, they do so for their organization.",
          bullets: null,
        },
        {
          focusText:
            "We Will Not Discriminate Against or Harass Other Participants.",
          subText:
            "Clarintel expressly prohibits harassment and discrimination based on race, color, religion, national origin, sex, age, pregnancy, genetic information, military and veteran status, marital status, personal appearance, gender identity or expression, sexual orientation, disability or perceived disability (physical or mental), family responsibilities, political affiliation, or any other legally protected characteristic.",
          subtext2:
            "Examples of conduct prohibited by the Clarintel Community Agreement include, without limitation:",
          subtext3:
            "Clarintel reserves the right in its sole discretion to determine what constitutes unacceptable behavior and what actions it will take to address incidents that occur.",
          bullets: [
            "Harassment in any form, including bullying, threatening, stalking, intimidation, microaggressions, insinuations or disparaging comments that are hurtful or interfere with any other attendee’s experience.",
            "Sexual harassment, including but not limited to, unwanted or inappropriate conduct, unwanted sexual attention, comments, jokes, or advances.",
            "Violence, threats of violence, or violent language directed against another person.",
            "Possession of a weapon or any item that can be used as a weapon, which may be used to threaten or cause injury to others if used in a certain manner.",
            "Discriminatory jokes and language based on sexual orientation, race/ethnicity, gender identity, religion/creed, national origin or political views.",
            "Inappropriate photography or recording, including within the event environment for use on social media, without the prior permission of the individuals or owners of the content therein.",
            "Failure to obey any rules or regulations of the event venue, including any destruction or vandalizing of personal or event property and materials.",
            "Boisterous, lewd or offensive behavior or language that disrupts the event, a session, or other formal or event segment in any way.",
          ],
        },
      ],
    },
    {
      title: "No Retaliation",
      text: [
        {
          focusText: null,
          subText:
            "Clarintel will not tolerate any actual or attempted reprisals or retaliation against individuals who raise in good faith a concern that this Clarintel Community Agreement has been violated, or who participate in the investigation of such a concern. Clarintel takes all allegations of harassment and discrimination seriously and is committed to ensuring an environment that is free of any harassment or discriminatory activities.",
          bullets: null,
        },
      ],
    },
    {
      title: "Consequences for Unacceptable Behavior",
      text: [
        {
          focusText: null,
          subText:
            "Participants who are asked to stop their behavior will be expected to comply immediately. If an attendee or participant continues to violate this Agreement, Clarintel may take whatever actions it deems appropriate, including removal from event without refund, disqualification from attending future events, notification of participant’s employer, or notification of on-premises security or local law enforcement as necessary.",
          bullets: null,
        },
      ],
    },

    // {title: "", text:[{focusText:"",subText:"", bullets:""}]}
  ];

  return (
   <Grid
          style={{
            // paddingLeft: "7.14vw",
            // paddingRight: "7.14vw",
            paddingTop: "50px",
          }}
        >
      <ThemeProvider theme={theme}>
        
        
        
            <Grid item sx={{ pb: "20px" }}>
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Changa",
                  fontWeight: 700,
                  color: "#43b1d3",
                  textAlign: "center",
                  width: "100%",
                  "@media screen and (max-width: 900px)": {
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",

                    padding: "20px 0 0 0",

                    // textAlign: "center",
                  },
                  position: "relative",
                }}
              >
                {"Clarintel Commnuity Agreement"}
              </Typography>
              <Typography variant="subtitle1">
                Please review the following community agreement before
                finalizing your registration. This agreement reflects the values
                of our organization and is designed to foster inclusive, equal,
                honest, and respectful interactions. Participants — including
                all attendees, speakers, exhibitors, partners, and volunteers —
                are expected to agree to and follow the community agreement as
                outlined below:
              </Typography>
            </Grid>
            <Grid item sx={{ pb: "20px" }}>
              {terms_data.map((term, item) => (
                <Grid key={item}>
                  <Typography key={item}
                    variant="h4"
                    sx={{
                      fontFamily: "Changa",
                      fontWeight: 700,
                      // color:"#43b1d3",
                      padding: "20px 0 5px",
                      "@media screen and (max-width: 900px)": {
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",

                        // textAlign: "center",
                      },
                      position: "relative",
                    }}
                  >
                    {term.title}
                  </Typography>
                  {term.text.map((support, item) => (
                    <Grid key={item}>
                      <Typography variant="subtitle1" key={item}>
                        {support.focusText ? (
                          <span
                            style={{
                              color: "#62B6D0",
                              fontWeight: "700",
                            }}
                          >
                            {`${support.focusText} `}
                          </span>
                        ) : null}
                        {support.subText}
                      </Typography>

                      {support.subtext2 ? (
                      <Typography variant="subtitle1">
                      {`${support.subtext2}`}
                    </Typography>
                        ) : null}

                      {support.bullets
                        ? support.bullets.map((bullet, item) => (
                            <Grid key={item}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                alignItems: "flex-start",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  padding: "10px 5px 0 0",
                                  alignSelf: "unset",
                                  fontSize: "2rem",
                                }}
                              >
                                {`\u2022`}
                              </Typography>

                              <Typography variant="subtitle1">
                                {`${bullet}`}
                              </Typography>
                            </Grid>
                          ))
                        : null}
                         {support.subtext3 ? (
                      <Typography variant="subtitle1">
                      {`${support.subtext3}`}
                    </Typography>
                        ) : null}
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>

        
      </ThemeProvider>
    </Grid>
  );
}
let theme = createTheme();

theme = responsiveFontSizes(theme);

theme = responsiveFontSizes(theme);

theme.typography.contactTitle = {
  fontFamily: "Changa",
  fontSize: "2.7rem",
  lineHeight: "2.7rem",
  fontWeight: "900",
  color: "#000",
  // paddingTop: "40px",

  "@media screen and (max-width: 900px)": {
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    paddingTop: "25px",
  },
};

theme.typography.contactSubTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontWeight: 500,
  fontSize: "1.3rem",
  lineHeight: "1.3rem",

  paddingBottom: "30px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.fieldTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "18px",
  fontWeight: "900",
  lineHeight: "18px",
};

theme.typography.h3 = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "2.7rem",
  lineHeight: "2.7rem",

  "@media screen and (max-width:900px)": {
    fontSize: "2rem !important",
    lineHeight: "2rem",
  },
};

theme.typography.h4 = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "1.9rem",
  lineHeight: "1.9rem",

  "@media screen and (max-width:900px)": {
    fontSize: "1.25rem !important",
    lineHeight: "1.25rem",
  },
};

theme.typography.formStyle = {
  // paddingLeft: "1.14vw",
  // paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.formStyleLeft = {
  paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.formStyleRight = {
  paddingLeft: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.topText = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.subtitle1 = {
  lineHeight: "1.3rem",
  paddingTop: "10px",
  fontSize: "1.3rem",
  fontWeight: 500,
  color: "#000",
  fontFamily: "Changa",
  alignSelf: "center",
  textAlign: "left",
  "@media (max-width: 900px)": {
    fontSize: "1rem",
    lineHeight: "1rem",
  },
};
