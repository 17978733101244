import { Button, Grid, Typography } from "@mui/material";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import React from "react";
import { data } from "../../data/data_file";


export default function WhoWeAreSection() {
  return (
    <Grid>
      <Grid 
        container
        style={{
          fontSize: 10,
          background: "#FFF",
          color: "black",
          display: "flex",
          alignItems:"center",
          flexWrap: "wrap",
        }}
      >
        <ThemeProvider theme={theme}>
          <Grid 
            container
            direction={"row"}
            sx={{
              alignItems:"center",
              textAlign: "left",
              margn:0,
              paddingLeft: "7.14vw",
              paddingRight: "7.14vw",
              paddingTop: "3.14vw",
              "@media screen and (max-width: 790px)": {
                paddingTop: "7.28vw",
                paddingBottom: "0",
              },
            }}
          >
            <Grid  
            item
              xs={12} 
              sm={12} 
              md={6} 
              lg={6} 
              sx={{
                paddingTop:"15px", 
                height: "100%",
                "@media screen and (max-width: 900px)": { paddingBottom: "3vw", height: "100%" }
              }}
            >
              <img 
                src="images/home-landing.jpg" 
                style={{ 
                  width: "100%", 
                  borderRadius: "40px",
                  height: "100%" 
                }}
                alt={"background"}
                loading="lazy"
              />
            </Grid>
            <Grid item
              xs={12} 
              sm={12} 
              md={6} 
              lg={6} 
              sx={{
                "@media screen and (min-width: 900px)": { paddingLeft: "3.14vw" }
              }}
            >
              <Grid justifyContent="space-between">
                <Grid item>
                  <Typography
                    // variant="h2" 
                  sx={{
                    margin: 0,
                    display: "flex",
                    fontFamily: "Changa",
                    color: "#62B6D0",
                    fontSize:"1.25rem",
                    lineHeight:"1.6rem",
                    "@media screen and (max-width: 900px)": {
                      flexDirection:"column",
                    }}}
                  >
                    Who We Are
                  <Typography
                    variant="h6"
                    component={'span'}
                    style={{
                      letterSpacing: "-0.09em",
                      fontFamily: "Changa",
                      color: "#62B6D0",
                      paddingLeft: "5px",
                      lineHeight:"1.6rem",
                    }}
                    >
                      {`----`}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="h3" 
                    sx={{
                      paddingTop: "15px", 
                      pb: { xs: "10px", md: "20px", lg: "30px" }, 
                      fontSize: { xs: "5.0vw", sm: "35px", md: "20px", lg: "30px", xl: "32px", xxl: "37px" } 
                    }}
                  >
                    {data.who_we_are.title}
                  </Typography>
                </Grid>

                <Grid 
                  item 
                  sx={{ 
                    pb: { xs: "0px", md: "35px", lg: "60px" } 
                  }}
                >
                  <Typography 
                    variant="subtitle1" 
                    sx={{ 
                      fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"}, 
                    }}
                  >
                    {data.who_we_are.description}
                  </Typography>
                </Grid>

                <Button
                  size="small"
                  href="/about"
                  sx={{
                    margin:"15px 0 0 0",
                    "&:hover": {
                      background: "rgba(98, 182, 208,0.5) !important",
                    },
                    backgroundColor: "#62B6D0",
                    color: "#ffff",
                    fontWeight: "700",
                    borderRadius: "40px",
                    width: { xs: "100%", md: "50%" },
                    padding: { xs: "10px 20px", md: "10px 20px", lg: "15px 50px" }
                  }}
                >
                  <Typography 
                    variant="btn1" 
                    sx={{ 
                      fontWeight: "700", 
                      fontSize: { xs: "1.0rem", sm: "20px", md: "1.2rem", lg: "1.4rem" } 
                    }}
                  >
                    Learn More
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}


let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920
    },
  },
});

theme = responsiveFontSizes(theme);

theme.typography.h3= {
  color: "#000", 
  fontFamily: "Changa",
  fontSize: "2.5rem",
  lineHeight:"2.5rem",
  "@media screen and (max-width:960px)": {
    fontSize: "2rem !important",
    lineHeight:"2rem"
  },
};

theme.typography.subtitle1 ={
  // lineHeight:"1.2em",
  fontWeight:200,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  width: "100%",
  // fontSize: "1.5rem !important",
  //   lineHeight:"1.5rem",
  "@media screen and (max-width: 790px)": 
  { paddingBottom: "3vw" ,
  // fontSize: "1.1rem !important",
  // lineHeight:"1.1rem",
}
}

theme.typography.h4 ={
  lineHeight:"1.2em",
  fontWeight:700,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  textTransform: "uppercase",
}

theme.typography.subtitle2 ={
  lineHeight:"1.2em",
  fontWeight:200,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  paddingBottom: "30px",
}

theme.typography.btn1 ={
  fontWeight:200,
  color: "#ffff", 
  fontFamily: "Changa", 
  alignSelf:"center",
  textTransform: "capitalize !important"
}