import {
  Box,
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes
} from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { LottieControl, PageHeader } from '../../components';
import ReceiptPDF from './ReceiptPdf';
import './styles.css';


export default function DonationSuccess({page_title}) {
  const location = useLocation();
  const { first_name, program_title } = location.state;

 useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <PageHeader title="Fund The Future" background={"#F1B9D0"} />
        <Box sx={{ pb: 8, backgroundColor: 'white' }}>
          <Grid style={{ paddingLeft: "7.14vw", paddingRight: "7.14vw", paddingTop: "3.14vw" }}>
            <Grid container direction="column" justifyContent="left" alignItems="left">
              <Grid item container direction="column" justifyContent="center" alignItems="center" sx={{ mb: 6 }}>
                <Grid item sx={{ mb: 6 }}>
                  <LottieControl />
                </Grid>
                <Grid item>
                  <Typography variant="thankYouTitle">
                    Thank you for supporting us{first_name? ` ${first_name}`:null}!
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sx={{ mb: 4 }}>
                <Typography variant="thankYouText">
                  <p>We will send an email with your receipt and additional information about <b>{program_title}</b>. If you have any questions, please contact us at <b>contact.us@clarintel.org</b>. As a token of our appreciation, please read our letter below. Once again, thank you for your generosity and support. We can't change the world without you!</p>
                </Typography>
              </Grid>
              <Grid 
                container 
                item 
                direction="column" 
                justifyContent="center" 
                alignItems="center"
              >
                <ReceiptPDF />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </main>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.topText = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
}

theme.typography.thankYouTitle = {
  fontFamily: "Changa",
  fontSize: "2.7rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "2.7rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
  },
}

theme.typography.thankYouText = {
  fontFamily: "Changa",
  fontSize: "1.7rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.7rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
}

theme.typography.thankYouParagraph = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
}

theme.typography.thankYouDetails = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
}

theme.typography.thankYouReceipt = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "left",
  flexGrow: 1,
  fontWeight: "bold",
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
}

theme.typography.info = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "left",
  fontWeight: "bold",
  fontStyle: "italic",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
}

theme.typography.contact = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
}