import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { data } from "../../data/data_file";

export default function ProgramIntroSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  theme.typography.subtitle1 = {
    fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
    lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},
    fontWeight: 500,
    fontFamily: "Changa",
    // "@media (max-width: 1285px)": {
    //   lineHeight: "18px",

    //   fontSize: "18px !important",
    // },
    "@media screen and (max-width: 790px)": {
      // lineHeight: "1.1em",
      marginTop: "12px",
    },
  };
  theme.typography.h3 = {
    fontSize: "2.3rem",
    "@media (max-width: 900px)": {
      fontSize: "2rem",
    },
  };
  theme.typography.h6 = {
    fontSize: "0.6rem",
    "@media (min-width:300px)": {
      fontSize: ".77rem",
    },
    "@media (min-width:500px)": {
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "2.9rem",
    },
  };
  
  
  function Item({ item }) {
    return (
      <Grid>
      <Grid style={{
        // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
      
        
        display: "flex",
        alignItems:"center",
        textAlign:"left",
        
      }}>
        <Grid
      container
      style={{
        fontSize: 10,
        // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
        background: "#FFF",
        color: "black",
        borderRadius:180,
        
        width:"60px",
        height:"60px",
        boxShadow: '0px 2px 9px #F4AAB9',
        marginLeft:15,
        display: "flex",
        alignItems:"center",
        flexWrap: "wrap",
        textAlign:"center",
        justifyContent:"center"
      }}
    >
  <svg  width="35" height="35" x="0" y="0" viewBox="0 0 32 32" fill="#F4AAB9" ><g><path d="M6 11V7a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0zM20 9h-4V4a2 2 0 1 1 4 0zM25 9h-4V5a2 2 0 1 1 4 0zM26 13v5.343a3.995 3.995 0 0 1-1.172 2.828l-2.243 2.243A2.004 2.004 0 0 0 22 24.828V30H9v-5.333c0-.433-.14-.854-.4-1.2l-1.8-2.4a4.002 4.002 0 0 1-.8-2.4v-4.223A3.959 3.959 0 0 0 8 15c.951 0 1.813-.347 2.5-.903A3.958 3.958 0 0 0 13 15c.499 0 .97-.104 1.408-.276a4 4 0 0 0 2.982 2.23C15.802 18.6 15 20.29 15 22a1 1 0 1 0 2 0c0-1.659 1.211-3.408 3.6-5.2A.999.999 0 0 0 20 15h-2c-1.103 0-2-.897-2-2v-2h8a2 2 0 0 1 2 2z" ></path><path d="M11 11V5a2 2 0 1 1 4 0v4a1 1 0 0 0-1 1v2.723A1.987 1.987 0 0 1 13 13a2 2 0 0 1-2-2z" fill="#F4AAB9" ></path></g></svg>
  </Grid>
  <Grid style={{
        // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
        flexDirection:"row",
        display: "flex",
        width:"80%",
        paddingLeft:"15px",
        alignItems:"center",
        flexWrap: "wrap",
      
      }}>
  
  <Typography
                        color="black"
                        variant="h3"
                        style={{
                          fontFamily: "Changa",
                          lineHeight: 1.2,
                          fontSize: "2rem",
                          fontWeight: 700,
                          width:"100%"
                        }}
                      >
                        {item.goal}
                      </Typography>
                        <Typography
                          color="black"
                          gutterBottom
                          variant="body1"
                          sx={{
                            fontFamily: "Changa",
                            lineHeight: 1.2,
                            fontSize: "1rem",
                           
                          }}
                        >
                          {item.goal_text}
                        </Typography>
            </Grid>
  
    </Grid>
    {item.id===2? null :
    <Grid style={{
        // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
        flexDirection:"row",
        display: "flex",
        alignItems:"center",
          height:3,
          borderBottom:"1px dashed #A5A5A5",
          margin:"10px 10% 20px"
      }}/>
    }
      </Grid>
    )
    ;
  }

  function MyList(items) {
    return (
      <Grid style={{ padding: 0 , width:"100%"}}>
        {items.map((item) => <Item key={item.id} item={item} />)}
      </Grid>
    );
  }

  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        padding: "7.14vw 7.14vw 0px",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-1px",

      }}
    >
      <ThemeProvider theme={theme}>
        <Grid item style={{ width: "100%" }}>
          <Typography
            variant="h2"
            style={{
              margin: 0,
              display: "flex",
              fontFamily: "Changa",
              color: "#62B6D0",
              fontSize:"1.25rem",
              lineHeight:"1.6rem",
            }}
          >
            {`About Our Programs  `}
            <Typography
              variant="h6"
              component={'span'}
              style={{
                letterSpacing: "-0.09em",
                fontFamily: "Changa",
                paddingLeft: "5px",
                lineHeight:"1.6rem",
              }}
            >
              {`----`}
            </Typography>
          </Typography>
        </Grid>
        <Grid item style={{ width: "100%", paddingTop: "1vw" }}>
        <ScrollAnimation animateIn="fadeIn" >
          <Typography
            variant="subtitle1"
            sx={{ color: "#000", fontFamily: "Changa",textAlign:"left",
            fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},
          }}
          >
            {data.programs_page.intro}
          </Typography>
          </ScrollAnimation>
        </Grid>
        
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            alignItems:"center",
            display: "flex",
            flexWrap: "initial",
            flexDirection:"row",
            overflow: "hidden",
             
              "@media screen and (max-width: 790px)": {
                width: "100%",
                flexDirection:"column",

              },
          }}
        >
          
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              top: "-1px",
              background:"#FFF"
            }}
            sx={{
              width: "50vw",
              // "@media screen and (min-width: 769px)": {
              //   maxHeight: "50vw",
              // },
              "@media screen and (max-width: 790px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              sx={{
                "@media screen and (min-width: 769px)": {
                  height: "calc(100% - 7.14vw)",
                  maxHeight: "calc(100% - 72px)",
                },
                "@media screen and (max-width: 790px)": {
                  padding: "3.14vw 0",
                },
                // padding: "3.14vw",
                paddingRight:"3.14vw",
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                boxSizing: "inherit",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  "@media screen and (min-width: 769px)": {
                    padding: "3.14vw",
                    paddingLeft:0,
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={true}
                  style={{ width: "100%" }}
                >
                    <Typography
                      color="black"
                      gutterBottom
                      variant="h3"
                      style={{
                        fontFamily: "Changa",
                        lineHeight: 1.2,
                        fontWeight: 700,
                      }}
                    >
                      {data.programs_page.subtitle}
                    </Typography>
                      <Typography
                        color="black"
                        gutterBottom
                        variant="subtitle1"
                        sx={{
                          fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                          lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},
                          
                        }}
                      >
                        {data.programs_page.subtitle_text}
                      </Typography>
                      <Typography
                        color="black"
                        gutterBottom
                        variant="subtitle1"
                        sx={{
                          paddingTop:"15px",
                          fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                          lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},
                        }}
                      >
                        {data.programs_page.subtitle_text2}
                      </Typography>
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{
              position: "relative",
              width: "50%",
              justifyContent:"center",
              display:"flex",
              background: "FFF",
              overflow: "hidden",
              "@media screen and (max-width: 790px)": {
                width:"100%"
              },
            }}
          >
            <Grid
              container
              sx={{
                // width: "calc(100% )",
                // height: "calc(100% - 7.14vw)",
                // maxWidth: "calc(100% - 72px)",
                // maxHeight: "calc(100% - 72px)",
                width: "calc(100%- 72px)",
                height: "calc(100%)",
                maxWidth: "500px",
                maxHeight: "calc(100% )",
                padding:"72px 10px",
                alignItems:"center",
                // paddingRight:"20px",
                "@media screen and (max-width: 790px)": {
                  padding:"20px 3.14vw 72px 3.14vw",
                  maxWidth: "100%",
                },
                // position: "absolute",
                zIndex: 1,
                // right: 0,
                backgroundSize: "cover",
              }}
            >
              <ScrollAnimation
                animateIn="fadeInRight"
                initiallyVisible={true}
                style={{ width: "100%" }}
              >
                                 <Grid>
                 <Grid
    container
    style={{
      fontSize: 10,
      // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
      background: "#FFF",
      color: "black",
      borderRadius:50,
      
      width:"calc(100%)",
      height:"calc(100%)",
      boxShadow: '0px 2px 9px #F4AAB9',
      
      display: "flex",
      alignItems:"center",
      flexWrap: "wrap",
      textAlign:"center",
      padding: '3.14vw 15px 3.14vw ',
    }}
  >
<Typography
            style={{ color: "#000", fontSize:18, fontWeight:"bolder", fontFamily: "Changa",textAlign:"center",paddingBottom:15,width:"100%",fontStyle:"italic"}}
          >
           {"Clarintel aims to achieve..."}
          </Typography>

          <Grid container sx={{ boxShadow: 0, wordBreak: "break-word" }}>
            {MyList(data.programs_page.objectives)}

            
          </Grid>
          
             </Grid>
             </Grid>

              </ScrollAnimation>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
}
