import React, { useState } from 'react';
import {
  Box,
  Alert,
  IconButton,
  Collapse,
  AlertTitle,
  Typography
} from '@mui/material';
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider
} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';


export default function TransitionalAlerts({ severity, status, bannerAlert }) {
  const [open, setOpen] = useState(bannerAlert);

  const handleOnClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box 
        sx={{ 
          width: '100%', 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 
          zIndex: 9999,
          p: 4
        }}
      >
        <Collapse in={open}>
          <Alert
            sx={{ mb: 2, p: 2 }}
            severity={severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={handleOnClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <AlertTitle>
              <Typography variant="h3">
                {severity}
              </Typography>
            </AlertTitle>
            <Typography variant="text">{status}</Typography>
          </Alert>
        </Collapse>
      </Box>
    </ThemeProvider>
  );
}


let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h3 = {
  fontSize: "1.2rem",
  lineHeight: "1.4rem",
  fontWeight: 900,
  width: "100%",
  textTransform: "capitalize",
  fontFamily: "Changa",
  overflowWrap: "break-word",
  "@media (max-width:900px)": {
    fontSize: "1.0rem",
  },
};

theme.typography.text = {
  fontSize: "1.0rem",
  width: "100%",
  textTransform: "capitalize",
  fontFamily: "Changa",
  overflowWrap: "break-word",
  "@media (max-width:900px)": {
    fontSize: "0.8rem"
  },
};