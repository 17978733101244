import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const DonateButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "900",
  backgroundColor: "#F1B9D0",
  fontSize: "1.5rem",
  lineHeight:"1.5rem",
  borderRadius: "30px",
  paddingBottom: "10px",
  paddingTop: "10px",
  "&:hover": {
    backgroundColor: "#FFFF",
    color: "#F1B9D0",
  },
  "@media screen and (max-width: 900px)": {
    fontSize: "1rem",
    lineHeight:"1rem",
  },
});

export const CustomRadio = styled(Radio)({
  "& .MuiListItemText-root": { }
});

export const TextButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "900",
  fontSize: "20px",
  padding: 0,
  color: "rgb(98, 182, 208)",
  backgroundColor: "transparent",
  boxShadow: "none",
  textTransform: "capitalize",
  "&:hover": {
    color: "rgb(98, 182, 208)",
    backgroundColor: "transparent",
    boxShadow: "none",
  }
});

export const CustomChip = styled(Chip)({
  fontFamily: "Changa",
  marginRight:"10px",
  fontWeight:"500",
  background:"rgb(98, 182, 208)",
  color:"#FFF",
});

export const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    
    height: "70px",
    fontFamily: "Changa",
    background:"#FFF",
    fontSize: "18px",
    marginTop:"10px",
    '&:hover fieldset': {
      borderColor: '#62B6D0',
      borderWidth:"1.5px",

    },
    '&.Mui-focused fieldset': {
      borderColor: '#62B6D0',
      borderWidth:"3px",

    },
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#62B6D0',
  },
  '& label.Mui-focused': {
    color: '#62B6D0',
  },
});


export const CustomCheckbox = styled(Checkbox)({
    '&.Mui-checked': {
      color: '#62B6D0 !important',
    },
    '&.MuiFormControlLabel-root':{
      marginRight:0,
    }
  },
);

export const CustomSelect = styled(Select)({
  "&.MuiInputBase-root": {
    borderRadius: "40px !important",
    fontFamily: "Changa",
    background:"#FFF",
    marginTop:"10px",
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#62B6D0',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#62B6D0',
  },
  // '.MuiOutlinedInput-notchedOutline': {
  //     borderColor: '#62B6D0',
  //   },
});

export const CustomMessageBox = styled(TextField)({
  '& .MuiInput-underline:after': {
    borderBottomColor: '#62B6D0',
  },
  '& label.Mui-focused': {
    color: '#62B6D0',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: "30px",
    fontFamily: "Changa",
    background:"#FFF",
    fontSize: "18px",
    marginTop:"10px",
    // '& fieldset': {
    //   borderColor: '#62B6D0',
    // },
    '&:hover fieldset': {
      borderColor: '#62B6D0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#62B6D0',
    },
  },
});

export const CustomFormControlLabel = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    fontFamily: "Changa",
    fontSize:"1rem",
    background:"transparent",
    lineHeight:"1rem",
    color: "black"
  }
});

export const CustomSubscribeButton = styled(Button)({
  height: '70px',
  fontFamily: "Changa",
  fontWeight: "900",
  color:"#FFF",
  backgroundColor: "#F1B9D0",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",
  borderRadius: "50px",
  width:"100%", 
  maxWidth:"50vw",
  paddingBottom: "20px",
  paddingTop: "20px",
  marginLeft:"15px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1rem",
    lineHeight: "1rem",
    maxWidth:"100%",
    marginLeft:"0",

  },
  "&:hover": {
    backgroundColor: "#FFFF",
    color: "#F1B9D0",
  },
  "&.Mui-disabled": {
    backgroundColor: 'lightgrey'
  }
})

export const CustomButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "900",
  color:"#FFF",
  backgroundColor: "#F1B9D0",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",
  borderRadius: "30px",
  width:"100%", 
  maxWidth:"50vw",
  paddingBottom: "20px",
  paddingTop: "20px",
  marginLeft:"15px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1rem",
    lineHeight: "1rem",
    maxWidth:"100%",
    marginLeft:"0",

  },
  "&:hover": {
    backgroundColor: "#FFFF",
    color: "#F1B9D0",
  },
});

export const CustomRedirectionButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "900",
  color:"#FFF",
  backgroundColor: "#62B6D0",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",
  borderRadius: "30px",
  width:"100%", 
  maxWidth:"40vw",
  minWidth: "40vw",
  paddingBottom: "25px",
  paddingTop: "25px",
  marginLeft:"15px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1rem",
    lineHeight: "1rem",
    maxWidth:"100%",
    marginLeft:"0",

  },
  "&:hover": {
    backgroundColor: "#FFFF",
    color: "#62B6D0",
  },
});

export const CustomSendDonationButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "900",
  color:"#FFF",
  backgroundColor: "#F1B9D0",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",
  borderRadius: "30px",
  width:"100%", 
  maxWidth:"40vw",
  minWidth: "40vw",
  paddingBottom: "25px",
  paddingTop: "25px",
  marginLeft:"15px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
    maxWidth:"100%",
    marginLeft:"0",
    width:"100%",
  },
  "&:hover": {
    backgroundColor: "#FFFF",
    color: "#F1B9D0",
  },
});

export const CustomSubmitButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "900",
  color:"#FFF",
  backgroundColor: "#62B6D0",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",
  borderRadius: "30px",
  width:"30%", 
  maxWidth:"50vw",
  paddingBottom: "20px",
  paddingTop: "20px",
  marginLeft:"15px",
  flexBasis:"auto",
  "@media screen and (max-width: 900px)": {
    fontSize: "1rem",
    lineHeight: "1rem",
    maxWidth:"100%",
    marginLeft:"0",

  },
  "&:hover": {
    backgroundColor: "#FFFF",
    color: "#62B6D0",
  },
});

export const CustomMenuItem = styled(MenuItem)({
  fontFamily: "Changa"
});

export const DonationTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "50px",
    fontFamily: "Changa",
    fontWeight: "900",
    cursor: "text",    overflow:"hidden",

    maxHeight: "80px",
  },

  "& .MuiInputBase-input": {
    // textAlign: "right",
    padding: "1em 1.5rem",
    // maxHeight: "80px",
    overflow:"hidden",
    fontSize: "2rem",
    textAlign:"center"
  }
});