import { Container, CssBaseline, Grid, Typography } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import Carousel from '../../sections/AirSlider/Carousel';


export default function Leadership({leaders,page_title}) {
  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0)
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <Box
          sx={{
            mt: 15,
            pt: 2,
            pb: 8,
            backgroundColor: "white"
          }}
        >
          <Container sx={{display:"flex", alignItems:"center", flexDirection:"column",paddingLeft:"7.14vw",paddingRight:"7.14vw", "@media screen and (max-width:900px)": {
    paddingLeft:"5.14vw",paddingRight:"5.14vw",
  },}}>
            <Grid container>
              <Grid
                container
                justifyContent="center"
                xs={12}
                md={12}
                lg={12}
              >
                <Typography sx={{pt:5}} variant="h3">The Dedicated Leaders of Tomorrow</Typography>
              </Grid>
              <Grid
                container
                xs={12}
                md={12}
                lg={12}
                sx={{
                  textAlign: "left",
                }}
              >
                <Typography sx={{fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},}} variant="subtitle1">
                Our founders are committed to providing leadership and resources to ensure that all solutions, offerings, and tools provided to nonprofits and community members remain free, forever. Our executive leadership team is dedicated to the belief that credible data and strong relationships can bridge the gaps between communities and community leaders to create a measurable, sustainable positive impact within their communities and beyond.
                </Typography>
              </Grid>
              {/* <Grid
                container
                xs={12}
                md={12}
                lg={12}
                sx={{
                  textAlign: "left"
                }}
              >
                <Typography variant="subtitle1">
                  Sed risus ultricies tristique nulla aliquet enim. Justo nec ultrices dui sapien eget mi proin sed. In cursus turpis massa tincidunt dui ut ornare. Purus semper eget duis at tellus at. Auctor eu augue ut lectus arcu. Sit amet aliquam id diam maecenas ultricies mi. Morbi non arcu risus quis. Mus mauris vitae ultricies leo.
                  Mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Eu scelerisque felis imperdiet proin fermentum leo vel orci. Vel orci porta non pulvinar neque laoreet suspendisse. Eget velit aliquet sagittis id consectetur purus ut faucibus. 
                </Typography>
              </Grid> */}
            </Grid>
            <Grid
              container
              sx={{
                mt: 5,
                width:"100%",
              }}
            >
              <Grid item xs={12} md={12} lg={12}  sx={{ pb: 5,textAlign:"center" }}>
                <Typography variant="h3">
                  Our Team
                </Typography>  
              </Grid>
              {/* <Grid container style={{justifyContent:"center",width:"100%",marginLeft:0}}   direction="row" spacing={8}> */}
              <Carousel isLeaders={true} flyer_data={leaders}/>
                
                {/* <Grid item xs={12} md={6} lg={4}>
                  <Grid item sx={{ pb: 2, justifyContent:"center", display:"flex"}}>
                    <img 
                      style={{width:"100%",height:"100%",maxWidth:"400px"}}
                      src="/images/muyi.png"
                      alt="Muyi Gbowa"
                    />
                  </Grid>
                  <Grid item >
                    <Typography variant="nameSubtitle">Muyi Gbowa</Typography>
                  </Grid>
                  <Grid item sx={{ pt: "5px" }}>
                    <Typography variant="positionSubtitle">Secretary & CMO</Typography>
                  </Grid>
                  <Grid item sx={{ pt: "5px" }}>
                    <Typography variant="execSubtitle">
                      Nisl pretium fusce id velit ut tortor pretium viverra. Commodo quis imperdiet massa tincidunt nunc pulvinar sapien et. Urna et pharetra pharetra massa massa ultricies mi quis hendrerit. Nibh tellus molestie nunc non blandit massa enim nec.
                    </Typography>
                  </Grid>
                </Grid> */}
              {/* </Grid> */}
            </Grid>
          </Container>
        </Box>
      </main>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h3= {
  color: "#000", 
  fontFamily: "Changa",
  fontSize: "2.2rem",
  lineHeight:"2.2rem",
  paddingBottom: "30px",
  // textTransform: "capitalize",
  "@media screen and (max-width:900px)": {
    fontSize: "2rem !important",
    lineHeight:"2rem",
    textAlign:"center",
    paddingBottom: "0",
  },
};

theme.typography.subtitle1 ={
  // lineHeight:"1.7rem",
  // fontSize:"1.7rem",
  fontWeight:200,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  paddingBottom: "30px",
  "@media screen and (max-width: 900px)": {
    marginLeft:"0",
    marginTop:"16px",
    paddingBottom: "10px",
    // lineHeight:"1.3rem",
    // fontSize:"1.3rem",

  },
}

theme.typography.execSubtitle ={
  lineHeight:"1.2rem",
  fontSize:"1.2rem",
  fontWeight:200,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  paddingBottom: "30px",
  "@media screen and (max-width: 900px)": {
    marginLeft:"0",
    marginTop:"0",
    paddingBottom: "10px",
    fontSize: "18px !important",
    lineHeight:"18px",

  },
}

theme.typography.positionSubtitle ={
  lineHeight:"1.3rem",
  fontSize:"1.3rem",
  textAlign: "left",
  fontWeight:700,
  color: "#62B6D0", 
  fontFamily: "Changa", 
  alignSelf:"center",
  paddingBottom: "30px",
 
  "@media screen and (max-width: 900px)": {
    marginLeft:"0",
    marginTop:"16px",
    lineHeight:"1.1rem",
  fontSize:"1.1rem",
  },
}

theme.typography.nameSubtitle ={
  lineHeight:"1.7rem",
  fontSize:"1.7rem",
  textAlign: "left",
  fontWeight:700,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  paddingBottom: "30px",
 
  "@media screen and (max-width: 900px)": {
    marginLeft:"0",
    marginTop:"16px",
    lineHeight:"1.5rem",
    fontSize:"1.5rem",
  },
}