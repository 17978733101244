import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
} from "react-icons/fa6";
import validate from 'validate.js';
import api from '../../api';
import { TransitionalAlerts } from "../../components";
import { footerBannerData } from "../../data/footerData/footer";
import {
  CustomButton,
  CustomSubscribeButton,
  CustomTextField,
} from "../FormComponents/FormComponents";
import "./styles.css";
import { schema } from "./subscribeSchema";


export default function AppFooter() {
  const subscribeForm = useRef();
  const [subscribeStatus, setSubscribeStatus] = useState({
    alert: false,
    message: '',
    severity: '',
  });
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      emailAddress: '',
    },
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const subscribe = async (e) => {
    e.preventDefault();

    try {
      const result = await api.PostSubscriber({ 
        email_address: formState.values.emailAddress 
      });

      setSubscribeStatus({
        alert: true,
        message: result.message,
        severity: result.status,
      });
    } catch (err) {
      // console.log({ message: err.message });
    }

    setFormState({
      isValid: false,
      values: {
        emailAddress: '',
      },
      touched: {},
      errors: {}
    });

    setTimeout(() => {
      setSubscribeStatus({
        alert: false,
        message: '',
        severity: '',
      });
    }, 5000);
  };

  const handleChange = (e) => {
    setFormState(formState => ({
      ...formState,
      values: {
        emailAddress: e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
  };

  const hasError = (field) => formState.errors[field] ? formState.touched[field] ? true : false : false;

  return (
    <div>
      {subscribeStatus.alert &&
        <TransitionalAlerts 
          bannerAlert={subscribeStatus.alert}
          severity={subscribeStatus.severity} 
          status={subscribeStatus.message} 
        />
      }
      <div className={styles.root}>
        <footer
          style={{
            backgroundImage: "url(/images/sandra-seitamaa-rg-3DFEo3v0-unsplash.jpg)",
            clear: "both",
            backgroundSize: "cover",
            margin: "auto",
            position: "relative"
          }}
        >
          <div style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
            <ThemeProvider theme={theme}>
              <div 
                style={{ 
                  backgroundColor: "#62B6D0", 
                  paddingTop: "40px", 
                  paddingBottom: "40px", 
                  marginBottom: "4vw" 
                }}
              >
                <Container sx={{ paddingLeft: "3.14vw", paddingRight: "3.14vw" }}>
                  <Grid container justifyContent="flex-end">
                    <Grid 
                      container 
                      
                      justifyContent="center" 
                      alignItems="center"
                    >
                      <Typography 
                        sx={{
                          fontWeight: "700", 
                          paddingTop: "10px",
                          paddingBottom: "10px", 
                          "@media screen and (max-width: 900px)": {
                            fontSize: "15px",
                            width: "100%", 
                            textAlign: "center", 
                            marginRight: "0"
                          }, 
                          fontSize: "1.8rem", 
                          marginRight: "20px"
                        }}
                      >
                        {footerBannerData.promotionBannerTitle}
                      </Typography>
                      <CustomButton 
                        sx={{
                          maxWidth: "300px", 
                          "@media (max-width:900px)": {
                            maxWidth: "30vw", 
                            fontSize: "15px"
                          },
                        }} 
                        href="/donate"
                      >
                        {footerBannerData.dontationButtonText}
                      </CustomButton>
                    </Grid>
                  </Grid>
                </Container>
              </div>
              <Container sx={{ paddingLeft: "3.14vw", paddingRight: "3.14vw" }}>
                <Grid
                  container
                  flexDirection={"column"}
                  style={{ flexDirection: "row" }}
                >
                  <Grid 
                    xs={12} 
                    sm={6}
                    item
                    sx={{ 
                      flexDirection: 'column', 
                      display: "flex", 
                      justifyContent: "space-between"
                    }}
                  >
                    <Grid 
                      item 
                      xs={12} 
                      sx={{
                        flexDirection:"row",
                        display:"flex",
                        "@media (max-width:900px)": {
                          marginTop: "40px",
                        },
                      }}
                    >
                      <Grid 
                        item 
                        xs={12} 
                        sx={{
                          paddingBottom: "40px",
                          "@media (max-width:900px)": {
                            paddingBottom: "20px",
                            paddingLeft: "1.14vw",
                            paddingRight: "1.14vw",
                          },
                        }}
                      >
                        <Typography variant="h3"sx={{ height: "fit-content" }}>
                          About Clarintel
                        </Typography>
                        <div style={{ height: "100%" }}>
                          <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                            <li>
                              <Button
                                href="/about"
                                sx={{
                                  p: 0,
                                  paddingBottom: '20px',
                                  '&:hover': {
                                    backgroundColor: "transparent"
                                  }
                                }}
                              >
                                <Typography variant="subheading">
                                  About Us
                                </Typography>
                              </Button>
                            </li>
                            <li>
                              <Button
                                href="/about/leadership"
                                sx={{
                                  p: 0,
                                  paddingBottom: '20px',
                                  '&:hover': {
                                    backgroundColor: "transparent"
                                  }
                                }}
                              >
                                <Typography variant="subheading">
                                  Dedicated Leadership
                                </Typography>
                              </Button>
                            </li>
                            <li>
                              <Button
                                href="/contact"
                                sx={{
                                  p: 0,
                                  paddingBottom: '20px',
                                  '&:hover': {
                                    backgroundColor: "transparent"
                                  }
                                }}
                              >
                                <Typography variant="subheading">
                                  Connect With Us
                                </Typography>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </Grid>
                      <Grid 
                        item 
                        xs={12} 
                        sx={{
                          paddingBottom: "40px",
                          paddingRight: "3.14vw",
                          "@media (max-width:900px)": {
                            paddingBottom: "20px",
                            paddingLeft: "1.14vw",
                          },
                        }}
                      >
                        <Typography variant="h3">Programs</Typography>
                        <div>
                          <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                            <li>
                              <Button
                                href="/programs"
                                sx={{
                                  p: 0,
                                  paddingBottom: '20px',
                                  '&:hover': {
                                    backgroundColor: "transparent"
                                  }
                                }}
                              >
                                <Typography variant="subheading">
                                  Our Programs
                                </Typography>
                              </Button>
                            </li>
                            <li>
                              <Button
                                href="/programs/air"
                                sx={{
                                  p: 0,
                                  paddingBottom: '20px',
                                  '&:hover': {
                                    backgroundColor: "transparent"
                                  }
                                }}
                              >
                                <Typography variant="subheading">
                                  Apprenticeship & Internship Rotational Programs (AIR)
                                </Typography>
                              </Button>
                            </li>
                            <li>
                              <Button
                                href="/programs/philanthropic-access"
                                sx={{
                                  p: 0,
                                  paddingBottom: '20px',
                                  '&:hover': {
                                    backgroundColor: "transparent"
                                  }
                                }}
                              >
                                <Typography variant="subheading">
                                  Philanthropic Access
                                </Typography>
                              </Button>
                            </li>
                            <li>
                              <Button
                                href="/programs/unity"
                                sx={{
                                  p: 0,
                                  paddingBottom: '20px',
                                  '&:hover': {
                                    backgroundColor: "transparent"
                                  }
                                }}
                              >
                                <Typography variant="subheading">
                                  Community Unity & Capacity Building
                                </Typography>
                              </Button>
                            </li>
                          </ul>
                        </div>
                      </Grid>
                    </Grid>
                    {/* <Grid 
                      item 
                      xs={12}  
                      sx={{
                        paddingBottom: "10%",
                        "@media (max-width:900px)": {
                          paddingBottom: "0px",
                        },
                      }}
                    >
                      <Typography variant="h3" >
                        Support Clarintel & Get Involved
                      </Typography>
                      <div>
                        <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                          <li>
                            <Button
                              href="/contact"
                              sx={{
                                p: 0,
                                paddingBottom: '20px',
                                '&:hover': {
                                  backgroundColor: "transparent"
                                }
                              }}
                            >
                              <Typography variant="subheading">
                                Join Our Team
                              </Typography>
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </Grid> */}
                  </Grid>
                  <Grid 
                    item 
                    xs={12} 
                    sm={6} 
                    // direction="column" 
                    sx={{
                      justifyContent: "space-between",
                      flexDirection:"column",
                      display: "flex", 
                      "@media (max-width:796px)": {
                        display: "flex"
                      },
                    }}
                  >
                    <Box 
                      justifyContent="center" 
                      sx={{ 
                        marginBottom: "20px",
                        "@media (max-width:900px)": {
                          marginTop: "40px",
                        },
                        "@media (max-width:600px)": {
                          marginTop: "20px",
                        } 
                      }}
                    >
                      <Typography 
                        variant="h3" 
                        sx={{ 
                          "@media (max-width:900px)": { 
                            paddingBottom: "5px" 
                          } 
                        }}
                      >
                        Join Our Community
                      </Typography>
                      <Typography 
                        variant="subheading"
                        sx={{ 
                          display: "flex", 
                          pb: "10px" 
                        }}
                      >
                        Stay up-to-date with the latest news and events at Clarintel!
                      </Typography>
                      <form 
                        ref={subscribeForm} 
                        onSubmit={subscribe} 
                        style={{ width: "100%" }}
                      >
                        <Grid
                          container
                          direction="row"
                          sx={{ alignItems: "center" }}
                        >
                          <Grid 
                            item 
                            xs={12} 
                            sm={12} 
                            md={8} 
                            lg={8} 
                            sx={{ 
                              pb: { xs: "15px", sm: "10px" } 
                            }}
                          >
                            <CustomTextField
                              sx={{ 
                                "& .MuiOutlinedInput-root": { 
                                  margin: 0, 
                                  marginRight: "10px", 
                                } 
                              }}
                              name="emailAddress"
                              placeholder="Enter Your Email"
                              variant="outlined"
                              size="medium"
                              id="email"
                              fullWidth
                              type="email"
                              onChange={handleChange}
                              value={formState.values.emailAddress}
                              helperText={
                                hasError('emailAddress') ? formState.errors.emailAddress[0] : null
                              }
                              error={hasError('emailAddress')}
                            />
                          </Grid>
                          <Grid 
                            item 
                            xs={12} 
                            sm={12} 
                            md={4} 
                            lg={4} 
                            sx={{ 
                              paddingLeft: { xs: 0 },
                            }} 
                          >
                            <CustomSubscribeButton
                              variant="contained" 
                              type="submit"
                              sx={{
                                maxWidth: "300px", 
                                "@media (max-width:900px)": {
                                  maxWidth: "100%", fontSize: "1rem"
                                },
                              }} 
                              disabled={!formState.isValid}
                            >
                              Subscribe
                            </CustomSubscribeButton>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                    </Grid>
                    <Grid container sx={{ flexDirection:"column", maxHeight:"150px",
                                "@media (max-width:600px)": {
                                  flexDirection:"row",
                                },
                              }} >
                    <Box 
                      // sx={{
                      //   marginBottom: "7%",
                      //   // "@media (max-width:1100px)": {
                      //   //   marginBottom: "16%",
                      //   // }, 
                      //   "@media (max-width:900px)": {
                      //     marginTop: "20px", 
                      //     marginBottom: "20px"
                      //   }
                      // }}
                    >
                      <Typography variant="h3" >
                        Follow Us On Social Media
                      </Typography>
                      <Stack direction="row" style={{ flexWrap: "wrap" }}>
                        <div style={{ marginRight: "36px", marginBottom: "16px" }}>
                          <a 
                            href="https://www.linkedin.com/company/clarintel/about/"
                            target="_blank" rel="noopener noreferrer"
                          >
                            <FaLinkedin size={30} color="white" />
                          </a>
                        </div>
                        <div style={{ marginRight: "36px", marginBottom: "16px" }}>
                          <a 
                            href="https://twitter.com/ClarintelInc"
                            target="_blank" rel="noopener noreferrer"
                          >
                            <FaXTwitter size={30} color="white" />
                          </a>
                        </div>
                        <div style={{ marginRight: "36px", marginBottom: "16px" }}>
                          <a 
                            href="https://www.facebook.com/profile.php?id=61552601214541"
                            target="_blank" rel="noopener noreferrer"
                          >
                              <FaFacebook size={30} color="white" />
                          </a>
                        </div>
                        <div style={{ marginRight: "36px", marginBottom: "16px" }}>
                          <a 
                            href="https://www.instagram.com/clarintel/"
                            target="_blank" rel="noopener noreferrer"
                          >
                            <FaInstagram size={30} color="white" />
                          </a>
                        </div>
                      </Stack>
                    </Box>
                    <Grid 
                      item 
                      xs={12}  
                      sx={{
                        marginLeft:"5%",
                        // paddingBottom: "10%",
                        "@media (max-width:900px)": {
                          paddingBottom: "0px",
                          marginLeft:0
                        },
                      }}
                    >
                      <Typography variant="h3" >
                        Support Clarintel & Get Involved
                      </Typography>
                      <div>
                        <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                          <li>
                            <Button
                              href="/contact"
                              sx={{
                                p: 0,
                                paddingBottom: '20px',

                                paddingTop:"10px",
                                '&:hover': {
                                  backgroundColor: "transparent"
                                }
                              }}
                            >
                              <Typography variant="subheading">
                                Join Our Team
                              </Typography>
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </Grid>
                    </Grid>
                </Grid>
                <div style={{ marginTop: "12px", marginBottom: "32px", padding: 0 }}>
                  <div 
                    style={{ 
                      maxWidth: "100%", 
                      borderTopColor: "#ffff", 
                      borderTopWidth: "1px", 
                      borderTopStyle: "solid" 
                    }}></div>
                </div>
                <Grid
                  container
                  justifyContent="flex-end"
                >
                  <Grid 
                    container 
                    item 
                    md={3} 
                    lg={3} 
                    sx={{
                      alignItems: "center", 
                      justifyContent: "center", 
                      "@media (max-width:900px)": {
                        display: "none"
                      },
                    }}
                  >
                    <img
                    style={{width:"200px"}}
                      src='/images/logo.png'
                      alt="clarintel"
                      loading="lazy"
                    />
                  </Grid>
                  <Grid  item xs={12} md={9} lg={9} >
                    <Stack direction="column">
                      <Typography 
                        sx={{
                          fontWeight: "900", 
                          fontSize: "3rem", 
                          lineHeight: "3rem", 
                          paddingBottom: "10px", 
                          "@media (max-width:900px)": {
                            fontSize: "2.5rem", 
                            lineHeight: "2.5rem",
                          }
                        }}
                      >
                        We're a trusted charity
                      </Typography>
                      <Typography variant="subheading">
                        {/* Our mission is to promote and redefine social action through clarity and intelligence. Donate today! */}
                        Our mission is to promote and redefine social action through clarity and intelligence. Donate today!
                      </Typography>
                      <Stack direction="row" style={{ paddingTop: "30px" }}>
                        {/* <img
                          src="/images/logo-charity-navigator-3.png"
                          alt="charity-nav"
                          style={{ height: "100%" }}
                          loading="lazy"
                        /> */}
                        <a 
                          href="https://www.guidestar.org/profile/shared/126b0494-3bfe-49ac-84bd-9fdce22580ba" 
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img alt="guidestar" src="https://widgets.guidestar.org/TransparencySeal/9962423" />
                        </a>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <div style={{ marginTop: "32px", marginBottom: "48px", padding: 0 }}>
                  <div 
                    style={{ 
                      maxWidth: "100%", 
                      borderTopColor: "#ffff", 
                      borderTopWidth: "1px", 
                      borderTopStyle: "solid" 
                    }}
                  ></div>
                </div>
                <Grid
                  container
                  justifyContent="flex-end"
                >
                  <Grid container  style={{ paddingBottom: "60px" }}>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="subheading">
                        © 2020-2023 Clarintel, Inc. All Rights Reserved.
                      </Typography>
                      <Typography variant="subheading">
                        Clarintel, Inc. is a registered 501(c)(3) non-profit organization working to promote and redefine social action through clarity and intelligence.
                      </Typography>
                      <Typography variant="subheading">
                        Federal Tax ID / EIN: 85-3018069 | Clarintel, Inc. is a 501(c)(3) organization.
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Container>
            </ThemeProvider>
          </div>
        </footer>
      </div>
    </div>
  );
}


const styles = theme => ({
  root: {
    paddingTop: "4vw",
    paddingBottom: "4vw",
    backgroundColor: "black",
    overflowX: 'hidden'
  },
  optionContainer: {
    paddingBottom: "40px",
    "@media (max-width:900px)": {
      paddingBottom: "20px",
      paddingLeft: "1.14vw",
      paddingRight: "1.14vw",
    },
  }
});


let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h3 = {
  fontSize: "1.4rem",
  lineHeight: "1.4rem",
  color: '#62B6D0',
  fontWeight: 900,
  width: "100%",
  textTransform: "capitalize",
  fontFamily: "Changa",
  overflowWrap: "break-word",
  paddingBottom: '40px',
  "@media (max-width:900px)": {
    fontSize: "1.2rem !important",
    lineHeight: "1.2rem",
    paddingBottom: '20px',

  },
};

theme.typography.subheading = {
  fontSize: "1.2rem",
  lineHeight: "1.2rem",
  // textTransform: "capitalize",
  color: '#FFF',
  fontWeight: 500,
  width: "100%",
  fontFamily: "Changa",
  overflowWrap: "break-word",
  "@media (max-width:900px)": {
    fontSize: "1rem !important",
    lineHeight: "1rem",
  },
};
theme.typography.body1 = {
  fontSize: "1.9vw",
  lineHeight: "1.1em",
  fontWeight: 500,
  color: "white",
  fontFamily: "Changa",
  overflowWrap: "break-word",

  "@media (max-width:900px)": {
    fontSize: "18px",
  },
};
theme.typography.h5 = {
  fontSize: "1.5vw",
  lineHeight: "1.1em",
  fontWeight: 700,
  fontFamily: "Changa",
  color: "#FFF",
  overflowWrap: "break-word",

  "@media (max-width:900px)": {
    fontSize: "15px ",
  },
};
