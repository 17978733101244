export const topics = [
  "Career Opportunities",
  "Partnerships",
  "Join or Volunteer with Clarintel",
  "General Questions",
  "Donations - Monetary",
  "Donations - Supplies, Goods",
  "Press & Media",
  "Grants"
];

export const genders = [
  "Male",
  "Female",
];

export const races = [
  "Hispanic or Latino",
"Not Hispanic or Latino",
"American Indian or Alaska Native",
"Asian",
"Black or African American",
"Native Hawaiian or Other Pacific Islander",
"White",
"2 or More Races",
];

export const states = [
  "Alabama (AL)",
  "Alaska (AK)",
  "Arizona (AZ)",
  "Arkansas (AR)",
  "California (CA)",
  "Colorado (CO)",
  "Connecticut (CT)",
  "Delaware (DE)",
  "District of Columbia (DC)",
  "Florida (FL)",
  "Georgia (GA)",
  "Hawaii (HI)",
  "Idaho (ID)",
  "Illinois (IL)",
  "Indiana (IN)",
  "Iowa (IA)",
  "Kansas (KS)",
  "Kentucky (KY)",
  "Louisiana (LA)",
  "Maine (ME)",
  "Maryland (MD)",
  "Massachusetts (MA)",
  "Michigan (MI)",
  "Minnesota (MN)",
  "Mississippi (MS)",
  "Missouri (MO)",
  "Montana (MT)",
  "Nebraska (NE)",
  "Nevada (NV)",
  "New Hampshire (NH)",
  "New Jersey (NJ)",
  "New Mexico (NM)",
  "New York (NY)",
  "North Carolina (NC)",
  "North Dakota (ND)",
  "Ohio (OH)",
  "Oklahoma (OK)",
  "Oregon (OR)",
  "Pennsylvania (PA)",
  "Rhode Island (RI)",
  "South Carolina (SC)",
  "South Dakota (SD)",
  "Tennessee (TN)",
  "Texas (TX)",
  "Utah (UT)",
  "Vermont (VT)",
  "Virginia (VA)",
  "Washington (WA)",
  "West Virginia (WV)",
  "Wisconsin (WI)",
  "Wyoming (WY)"
];