import { Button, Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import { useStickyroll } from "@stickyroll/hooks";
import "animate.css/animate.min.css";
import React, { useEffect, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import ImpactAreasSection from "../ProgramAreasSection/ImpactAreasSection";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default function FundraiseDataSection({op_data}) {
  let theme = createTheme();


  // const [currentFilter, setCurrentFilter] = useState(false);
  // const [completed, setCompleted] = useState((Number(Number(fundingData[0].number_objective)/Number(fundingData[1].number_objective))*100).toFixed(0));
  // const completed = (Number(Number(fundingData[0].number_objective) / Number(fundingData[1].number_objective)) * 100).toFixed(0);

  // useEffect(() => {
  //   setInterval(() => setCompleted(Math.floor(Math.random() * 100) + 1), 5000);
  // }, []);

  // function handleCurrentFilter() {
  //   setCurrentFilter(!currentFilter);
  // }

  theme = responsiveFontSizes(theme);
  theme.typography.h4 = {
    fontSize: "2rem",
    lineHeight: "2rem",
    fontFamily:"Changa",
    paddingBottom:"30px",

    "@media (max-width: 900px)": {
      fontSize: "1.7rem",
      lineHeight: "1.7rem",

    },
  };
  theme.typography.h5 = {
    fontSize: "1.4rem",
    lineHeight: "1.1em",
    color: "#000",
    fontWeight: "bolder",
    width: "100%",
    fontFamily: "Changa",
    overflowWrap: "break-word",
    margin: 0,

    "@media (max-width:900px)": {
      // fontSize: "24px !important",
    },
  };
  theme.typography.h2 = {
    color: "#000",
    fontFamily: "Changa",
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
    "@media screen and (min-width: 1024px)": {
      fontSize: "5rem",
    lineHeight: "5rem",
    },
    
    // "@media screen and (max-width:600px)": {
    //   fontSize: "2.5rem !important",
    //   lineHeight: "3.5 rem",
    // },
  };


  theme.typography.h3 = {
    color: "#000",
    fontFamily: "Changa",
    fontSize: "2rem",
    lineHeight: "2rem",

    "@media screen and (max-width:900px)": {
      fontSize: "1.5rem !important",
      lineHeight: "1.5rem",
    },
    
    "@media screen and (max-width:600px)": {
      fontSize: "1.2 rem !important",
      lineHeight: "1.2 rem",
    },
  };



  theme.typography.h6 = {
    fontSize: "1.15rem",
    color: "#000",
    lineHeight: "1.1em",
    fontWeight: "bold",
    width: "100%",
    fontFamily: "Changa",
    overflowWrap: "break-word",

    "@media (max-width:900px)": {
      fontSize: "1rem !important",
    },
  };


  theme.typography.subtitle1 = {
    lineHeight: "1.2em",
    marginRight: "7.14vw",
    fontSize: "2rem",
    fontWeight: 500,
    color: "#000",
    fontFamily: "Changa",
    alignSelf: "center",
    "@media (max-width: 1077.8443113772px)": {
      fontSize: "2vw",
    },
    "@media screen and (max-width: 900px)": {
      marginLeft: "0",
      marginTop: "16px",
    },
  }

  const headlines = [
    "Digital Acts Of Care",
    "Connected & DAC Posts",
    "Instant Action Button",
  ];
  var statTheme = {
    width: "50%",
    "@media screen and (max-width: 900px)": {
      width: "100%",
    },
  };


 var volunteering = "We are actively searching for and recruiting talented individuals to help us serve our communities and community leaders. Volunteers will help drive our mission through hands-on activities that build leadership, management, and technical skills applicable to solving real world social challenges. If you're interested in changing the world, feel free to reach out. We accept volunteers of all ages!";

  const [wrapper,] =
    useStickyroll({
      pages: headlines,
    });

  const opData = [{
    link: "section-1",
    title:
      "About", text: op_data.about, key: 0,
  },
  { title: "Goals & Objectives", text: op_data.goals.text, link: "section-2", key: 1, goals: op_data.goals.details },
  { title: "Funding Uses", text: op_data.funding.text, link: "section-3", key: 2,details: op_data.funding.details},
  // { title: "Want to Partner?", text: method2, link: "section-4", key: 3 },
  { title: "Interested in Volunteering?", text: volunteering, link: "section-4", key: 3 },

  { title: "Impact Areas", text: op_data.impact_intro, link: "section-5", key: 4, },
  ];

  const fundsPage = () => {
    return (
      <Grid
        sx={{
          position: "relative",
          // width: "50vw",
          // minHeight: "46vw",
          background: "FFF",
          "@media screen and (max-width: 900px)": {
            width: "100%",
          },
        }}
      >
        <Grid
          container
          sx={{
            // width: "calc(100% )",
            // height: "calc(100% - 7.14vw)",
            // maxWidth: "calc(100% - 72px)",
            // maxHeight: "calc(100% - 72px)",
            width: "calc(100%- 72px)",
            height: "calc(100%)",
            maxWidth: "calc(100%- 72px)",
            maxHeight: "calc(100% )",
            padding: "24px 20px 0px 0px",
            //  position: "absolute",
            zIndex: 1,
            // right: 0,
            backgroundSize: "cover",
            "@media screen and (max-width: 900px)": {
              padding: "3.14vw  0 ",
              justifyContent:"center",
            },
          }}
        >

          <Grid sx={{
            // padding: "0 10.14vw 0 0 ", 
            // width: "50vw", 
            "@media screen and (max-width: 900px)": {
              width: "100%",
              maxWidth:"400px",
              alignSelf:"center",
              padding: "1.14vw"
            },
          }}>
            <Grid

              style={{
                fontSize: 10,
                // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
                background: "#FFF",
                color: "black",
                borderRadius: 50,

                width: "calc(100%)",
                height: "calc(100%)",
                boxShadow: op_data.event_type === 0 ? "0px 2px 9px rgba(98,182,208)" : "0px 2px 9px rgba(241 ,185, 208)",

                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexWrap: "wrap",
                textAlign: "center",
                padding: '3.14vw 0px 3.14vw ',
              }}
            >
              <Grid container direction="row" style={{ alignItems: "center", width: "100%", justifyContent: "space-between", paddingLeft: "10%", paddingRight: "10%" }}>
                <Typography
                  style={{ color: "#A3A3A3", fontSize: 18, fontWeight: "bolder", fontFamily: "Changa", textAlign: "left" }}
                >
                  {"2024-2025 Fundraising Goal"}
                </Typography>
                {/* <Button onClick={handleCurrentFilter} style={{ background: "#F1B9D0", borderRadius: 50, maxWidth: 300, textAlign: "center" }}>
                <Typography
                  color="#FFF"
                  gutterBottom
                  variant="h5"
                  style={{ fontSize: ".7rem", paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, margin: 0 }}
                >
                  {currentFilter? "View Stats":"View Donors"}
                </Typography>

              </Button> */}
              </Grid>
              {/* <Grid style={{width:"100%",justifyContent:"center",display:"flex", flexDirection:"column"}}>
                      <ProgressBar bgcolor={"#F1B9D0"} completed={completed} />

                      <Typography
              color="#A3A3A3"
              // variant="body1"
              sx={{
                fontFamily: "Changa",
                lineHeight: 1.2,
                margin:0,
                padding:0,
                marginBottom:"10px",
                fontSize: "11px",
                textTransform:"uppercase",
                "@media (max-width: 900px)": {
                  fontSize: "2vw",
                },
              }}
            >
              {`${completed}% to Goal`}
            </Typography>
            </Grid> */}
              <Grid sx={{ boxShadow: 0, wordBreak: "break-word", marginTop: "25px", width: "100%" }}>

                {MyFundingList(op_data.funding.funding_goals)}


              </Grid>
              <Grid style={{textDecoration:"none"}} state={{activeIndex:op_data.id+1}} href="/donate"> 
              <Button href="/donate" style={{ background: "#F1B9D0", borderRadius: 50, marginTop: "25px", maxWidth: 300, textAlign: "center" }}>
                <Typography
                  color="#FFF"
                  gutterBottom
                  variant="h5"
                  style={{ fontSize: "1rem", paddingTop: 15, paddingBottom: 15, paddingLeft: 30, paddingRight: 30, margin: 0 }}
                >
                  {"Donate"}
                </Typography>

              </Button>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </Grid>
    );
  };


  // const recentDonorPage = () => {
  //   return (
  //     <Grid
  //       sx={{
  //         position: "relative",
  //         width: "50vw",
  //         // minHeight: "46vw",
  //         background: "FFF",
  //         "@media screen and (max-width: 900px)": {
  //           width: "100%",
  //         },
  //       }}
  //     >
  //       <Grid
  //         container
  //         sx={{
  //           // width: "calc(100% )",
  //           // height: "calc(100% - 7.14vw)",
  //           // maxWidth: "calc(100% - 72px)",
  //           // maxHeight: "calc(100% - 72px)",
  //           width: "calc(100%- 72px)",
  //           height: "calc(100%)",
  //           maxWidth: "calc(100%- 72px)",
  //           maxHeight: "calc(100% )",
  //           padding: "24px 20px 0px 0px",
  //           //  position: "absolute",
  //           zIndex: 1,
  //           // right: 0,
  //           backgroundSize: "cover",
  //           "@media screen and (max-width: 900px)": {
  //             padding: "3.14vw 3.14vw 0 3.14vw"
  //           },
  //         }}
  //       >

  //         <Grid sx={{
  //           padding: "0 14.14vw 0 0 ", width: "45vw", "@media screen and (max-width: 900px)": {
  //             width: "100%",
  //             padding: "3.14vw"
  //           },
  //         }}>
  //           <Grid

  //             style={{
  //               fontSize: 10,
  //               // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
  //               background: "#FFF",
  //               color: "black",
  //               borderRadius: 50,

  //               width: "calc(100%)",
  //               height: "calc(100%)",
  //               boxShadow: op_data.event_type === 0 ? "0px 2px 9px rgba(98,182,208)" : "0px 2px 9px rgba(241 ,185, 208)",

  //               display: "flex",
  //               alignItems: "center",
  //               justifyContent: "center",
  //               flexWrap: "wrap",
  //               textAlign: "center",
  //               padding: '3.14vw 0px 3.14vw ',
  //             }}
  //           >
  //             <Grid container direction="row" style={{ alignItems: "center", width: "100%", justifyContent: "space-between", paddingLeft: "10%", paddingRight: "10%" }}>
  //               <Typography
  //                 style={{ color: "#A3A3A3", fontSize: 18, fontWeight: "bolder", fontFamily: "Changa", textAlign: "left" }}
  //               >
  //                 {"Our Generous Donors"}
  //               </Typography>
  //               <Button onClick={handleCurrentFilter} style={{ background: "#F1B9D0", borderRadius: 50, maxWidth: 150, textAlign: "center" }}>
  //                 <Typography
  //                   color="#FFF"
  //                   gutterBottom
  //                   variant="h5"
  //                   style={{ fontSize: ".7rem", paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, margin: 0 }}
  //                 >
  //                   {currentFilter ? "View Stats" : "View Donors"}
  //                 </Typography>

  //               </Button>
  //             </Grid>
  //             <Grid sx={{ boxShadow: 0, wordBreak: "break-word", marginTop: "25px", width: "100%" }}>
  //               {DonorList(donorData)}
  //             </Grid>
  //             <Grid container direction="row" style={{ width: "100%", display: "flex", justifyContent: "space-between", paddingLeft: "10%", paddingRight: "10%" }}>
  //             <Link style={{textDecoration:"none"}} state={{activeIndex:item.key+1}} to={
  //                            {     
  //                              pathname: '/donate',
  //                             }
  //                       }> 
  //               <Grid style={{ background: "#F1B9D0", borderRadius: 50, marginTop: "25px", maxWidth: 150, textAlign: "center" }}>
  //                 <Typography
  //                   color="#FFF"
  //                   gutterBottom
  //                   variant="h5"
  //                   style={{ fontSize: "1rem", paddingTop: 15, paddingBottom: 15, paddingLeft: 30, paddingRight: 30, margin: 0 }}
  //                 >
  //                   {"Donate"}
  //                 </Typography>
  //               </Grid>                        
  //               </Link>

  //               <Grid style={{ background: "rgba(98,182,208)", borderRadius: 50, marginTop: "25px", maxWidth: 150, textAlign: "center" }}>
  //                 <Typography
  //                   color="#FFF"
  //                   gutterBottom
  //                   variant="h5"
  //                   style={{ fontSize: "1rem", paddingTop: 15, paddingBottom: 15, paddingLeft: 30, paddingRight: 30, margin: 0 }}
  //                 >
  //                   {"View All"}
  //                 </Typography>

  //               </Grid>
  //             </Grid>
  //           </Grid>
  //         </Grid>

  //       </Grid>
  //     </Grid>
  //   );
  // };



  function FundingItem({ item }) {
    return (
      <Grid container style={{ flexDirection: "column", width: "100%" }}>
        <Grid style={{
          // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",


          display: "flex",
          justifyContent: "center",
          textAlign: "left",
          width: "100%",

        }}>
          <Grid style={{ maxWidth: "90%", flexDirection: "row", display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>

            <Grid
              container
              style={{
                fontSize: 10,
                // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
                background: "#FFF",
                color: "black",
                borderRadius: 180,

                width: "75px",
                height: "75px",
                boxShadow: op_data.event_type === 0 ? "0px 2px 9px rgba(98,182,208)" : "0px 2px 9px rgba(241 ,185, 208)",
                marginLeft: 15,
                marginRight: 15,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center"
              }}
            >
              <svg width="40" height="40" x="0" y="0" viewBox="0 0 32 32" fill={op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"}><g><path d="M6 11V7a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0zM20 9h-4V4a2 2 0 1 1 4 0zM25 9h-4V5a2 2 0 1 1 4 0zM26 13v5.343a3.995 3.995 0 0 1-1.172 2.828l-2.243 2.243A2.004 2.004 0 0 0 22 24.828V30H9v-5.333c0-.433-.14-.854-.4-1.2l-1.8-2.4a4.002 4.002 0 0 1-.8-2.4v-4.223A3.959 3.959 0 0 0 8 15c.951 0 1.813-.347 2.5-.903A3.958 3.958 0 0 0 13 15c.499 0 .97-.104 1.408-.276a4 4 0 0 0 2.982 2.23C15.802 18.6 15 20.29 15 22a1 1 0 1 0 2 0c0-1.659 1.211-3.408 3.6-5.2A.999.999 0 0 0 20 15h-2c-1.103 0-2-.897-2-2v-2h8a2 2 0 0 1 2 2z" ></path><path d="M11 11V5a2 2 0 1 1 4 0v4a1 1 0 0 0-1 1v2.723A1.987 1.987 0 0 1 13 13a2 2 0 0 1-2-2z" fill={op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"} ></path></g></svg>
            </Grid>
            <Grid sx={{
              // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
              flexDirection: "column",
              display: "flex",
              // width: "30%",
              maxWidth:"fit-content",
              flexWrap: "wrap",
             

            }}>

              <Typography
                color="black"
                variant="h3"
                style={{
                  fontFamily: "Changa",
                  fontWeight: 700,
                }}
              >
                ${item.number_objective.toLocaleString()}
              </Typography>
              <Typography
                color="black"
                gutterBottom
                sx={{
                  fontFamily: "Changa",
                  lineHeight: "1.5rem",
                  fontSize: "1.2rem",
                  textTransform: "uppercase",
                  paddingTop: "5px",
                  "@media (max-width: 900px)": {
                    lineHeight: "18px",
                    fontSize: "18px",
                  },
                }}
              >
                {item.objective_description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {item.key === op_data.funding.funding_goals.length - 1 ? null :
          <Grid style={{
            // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            height: 3,
            borderBottom: "1px dashed #A5A5A5",
            margin: "10px 10% 20px"
          }} />
        }
      </Grid>
    )
      ;
  }

  function MyFundingList(items) {
    return (
      <Grid style={{ padding: 0, width: "100%", maxHeight: "300px" }}>
        {items.map((item) => <FundingItem key={item.key} item={item} />)}
      </Grid>
    );
  }


  // function DonorItem({ item }) {
  //   return (
  //     <Grid container style={{ flexDirection: "column", display: "flex", width: "100%", marginTop: "5px" }}>
  //       <Grid style={{
  //         // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",


  //         display: "flex",
  //         justifyContent: "center",
  //         textAlign: "left",
  //         width: "100%",

  //       }}>
  //         <Grid style={{ maxWidth: "90%", flexDirection: "row", display: "flex", width: "100%", alignItems: "center" }}>

  //           <Grid
  //             container
  //             style={{
  //               fontSize: 10,
  //               // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
  //               background: "#FFF",
  //               color: "black",
  //               borderRadius: 180,

  //               width: "75px",
  //               height: "75px",
  //               boxShadow: op_data.event_type === 0 ? "0px 2px 9px rgba(98,182,208)" : "0px 2px 9px rgba(241 ,185, 208)",
  //               marginRight: 15,
  //               display: "flex",
  //               alignItems: "center",
  //               flexWrap: "wrap",
  //               justifyContent: "center"
  //             }}
  //           >
  //             <svg width="40" height="40" x="0" y="0" viewBox="0 0 32 32" fill={op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"} ><g><path d="M6 11V7a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0zM20 9h-4V4a2 2 0 1 1 4 0zM25 9h-4V5a2 2 0 1 1 4 0zM26 13v5.343a3.995 3.995 0 0 1-1.172 2.828l-2.243 2.243A2.004 2.004 0 0 0 22 24.828V30H9v-5.333c0-.433-.14-.854-.4-1.2l-1.8-2.4a4.002 4.002 0 0 1-.8-2.4v-4.223A3.959 3.959 0 0 0 8 15c.951 0 1.813-.347 2.5-.903A3.958 3.958 0 0 0 13 15c.499 0 .97-.104 1.408-.276a4 4 0 0 0 2.982 2.23C15.802 18.6 15 20.29 15 22a1 1 0 1 0 2 0c0-1.659 1.211-3.408 3.6-5.2A.999.999 0 0 0 20 15h-2c-1.103 0-2-.897-2-2v-2h8a2 2 0 0 1 2 2z" ></path><path d="M11 11V5a2 2 0 1 1 4 0v4a1 1 0 0 0-1 1v2.723A1.987 1.987 0 0 1 13 13a2 2 0 0 1-2-2z" fill={op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"} ></path></g></svg>
  //           </Grid>
  //           <Grid sx={{
  //             // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
  //             flexDirection: "column",
  //             display: "flex",
  //             width: "45%",
  //             flexWrap: "wrap",
  //             "@media (max-width: 900px)": {
  //               maxWidth: "45%",

  //             },

  //           }}>
  //             <Typography
  //               color="black"
  //               gutterBottom
  //               variant="body1"
  //               sx={{
  //                 fontFamily: "Changa",
  //                 lineHeight: "1.5rem",
  //                 margin: 0,
  //                 fontSize: "1.5rem",
  //                 textTransform: "uppercase",
  //                 paddingRight: "0",
  //                 "@media (max-width: 900px)": {
  //                   fontSize: "1rem",
  //                   lineHeight: "1rem"
  //                 },
  //               }}
  //             >
  //               {item.isAnonymous ? "Anonymous" : item.name}
  //             </Typography>
  //             <Typography
  //               color="black"
  //               variant="h3"
  //               style={{
  //                 fontFamily: "Changa",
  //                 lineHeight: "30px",
  //                 margin: 0,
  //                 fontSize: "30px",
  //                 fontWeight: 700,
  //               }}
  //             >
  //               ${item.donation_amount.toLocaleString()}
  //             </Typography>
  //             <Typography
  //               color="#A3A3A3"
  //               gutterBottom
  //               variant="body1"
  //               sx={{
  //                 fontFamily: "Changa",
  //                 lineHeight: 1.2,
  //                 margin: 0,
  //                 fontSize: "11px",
  //                 textTransform: "uppercase",
  //                 paddingRight: "0",
  //                 "@media (max-width: 900px)": {
  //                   fontSize: "2vw",
  //                 },
  //               }}
  //             >
  //               {item.time_since_donation}
  //             </Typography>
  //           </Grid>
  //         </Grid>
  //       </Grid>

  //       {/* {item.key === donorData.length-1? null : */}
  //       <Grid style={{
  //         // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
  //         flexDirection: "row",
  //         display: "flex",
  //         alignItems: "center",
  //         height: 3,
  //         borderBottom: "1px dashed #FFF",
  //         // borderBottom: "1px dashed #A5A5A5",
  //         margin: "10px 10% 10px"
  //       }} />
  //       {/* } */}
  //     </Grid>
  //   )
  //     ;
  // }


  function OpTextItem({ item }) {
    return (
      <Grid style={{ display: "flex", flexDirection: "column", }}>
        {/* {item.key !== 2 && item.key !== 3 ? */}

          <Grid id={item.link} sx={{
            width: "100%", flexDirection: item.key === 0 ||item.key === 1 || item.key === 3  ?"column":"row", display: "flex", paddingBottom: item.key !== opData.length - 1 ? "50px" : 0,
            "@media screen and (max-width: 900px)": {
              alignItems: "center",
              flexDirection: "column",
              flexWrap: "wrap",
              padding: "3.14vw",
            },
          }}>
            <Grid style={{ justifyContent: "center", zIndex: 10, display:item.key === 0  ? "none" : "block" }} sx={statTheme}>
              <Typography
                variant="h4"
                color="black"
                style={{ position: item.key === 3 ? "unset" : "sticky", top: "150px", }}
              >
                {item.title}
                {item.key ===2? 
                fundsPage()
                : null}
              </Typography>

            </Grid>
            <Grid container sx={{
              justifyContent: "center", paddingRight: item.key === 0 ? 0 : "3.14vw", width: item.key === 0 ||item.key === 1 || item.key === 3 ? "auto" :"45vw",
              "@media screen and (max-width: 900px)": {
                padding: 0,
                width: "100%",

              },
            }} >
              <Typography
                color="black"
                gutterBottom
                variant="body1"
                sx={{
                  fontFamily: "Changa",
                  fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                            lineHeight:  { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},
                              paddingRight: "15px",
                              margin: 0,
                              paddingBottom:"30px",
                            //   "@media (max-width: 1400px)": {
                            //     fontSize: "18px !important",
                            //     lineHeight: "18px",
                            // }
                }}
              >
                {item.text}
                {
                // item.key === 0 ?
                // <Grid style={{textDecoration:"none"}} state={{activeIndex:op_data.id+1}} > 
                //   <Button href="/donate" style={{ background: "#F1B9D0", borderRadius: 50, maxWidth: 150, textAlign: "center", marginTop: "15px" }}>
                //     <Typography
                //       color="#FFF"
                //       gutterBottom
                //       variant="h5"
                //       style={{ fontSize: "1rem", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingRight: "10px", margin: 0 }}
                //     >
                //       {"Donate"}
                //     </Typography>

                //   </Button>
                //   </Grid>
                //   : 
                  item.key === 3 ?
                  <Grid container direction="row" style={{ width: "100%", display: "flex", justifyContent: "space-between", paddingRight: "10%" }}>

                    <Button href="/contact" style={{ background: "rgba(98,182,208)", borderRadius: 50, marginTop: "25px", maxWidth: 300, textAlign: "center" }}>
                      <Typography
                        color="#FFF"
                        gutterBottom
                        variant="h5"
                        style={{ fontSize: "1rem", paddingTop: 15, paddingBottom: 15, paddingLeft: 30, paddingRight: 30, margin: 0 }}
                      >
                        {"Contact Us to Volunteer"}
                      </Typography>

                    </Button>
                  </Grid>
                  : item.key === 1 ?

                    item.goals.map((el, index) =>

                      <Grid container>
                        <Grid style={{
                          // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",

                          marginTop: "15px",
                          width:"100%",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "left",

                        }}>
                          <Grid
                            container
                            style={{
                              fontSize: 10,
                              // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
                              background: "#FFF",
                              color: "black",
                              borderRadius: 180,
                              width:"40px",
                              minWidth: "40px",
                              height: "40px",
                              marginRight: 15,
                              boxShadow: op_data.event_type === 0 ? "0px 2px 9px rgba(98,182,208)" : "0px 2px 9px rgba(241 ,185, 208)",
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              textAlign: "center",
                              justifyContent: "center"
                            }}
                          >
                            <svg width="20" height="20" x="0" y="0" viewBox="0 0 32 32" fill={op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"} ><g><path d="M6 11V7a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0zM20 9h-4V4a2 2 0 1 1 4 0zM25 9h-4V5a2 2 0 1 1 4 0zM26 13v5.343a3.995 3.995 0 0 1-1.172 2.828l-2.243 2.243A2.004 2.004 0 0 0 22 24.828V30H9v-5.333c0-.433-.14-.854-.4-1.2l-1.8-2.4a4.002 4.002 0 0 1-.8-2.4v-4.223A3.959 3.959 0 0 0 8 15c.951 0 1.813-.347 2.5-.903A3.958 3.958 0 0 0 13 15c.499 0 .97-.104 1.408-.276a4 4 0 0 0 2.982 2.23C15.802 18.6 15 20.29 15 22a1 1 0 1 0 2 0c0-1.659 1.211-3.408 3.6-5.2A.999.999 0 0 0 20 15h-2c-1.103 0-2-.897-2-2v-2h8a2 2 0 0 1 2 2z" ></path><path d="M11 11V5a2 2 0 1 1 4 0v4a1 1 0 0 0-1 1v2.723A1.987 1.987 0 0 1 13 13a2 2 0 0 1-2-2z" fill={op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"} ></path></g></svg>
                          </Grid>

                          <Typography
                            color="black"
                            gutterBottom
                            variant="body1"
                            sx={{
                              fontFamily: "Changa",
                              // maxWidth:"75%",
                              fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},
                              // paddingRight: "15px",
                              margin: 0,
                              // "@media (max-width: 1400px)": {
                              //   fontSize: "18px !important",
                              //   lineHeight: "18px",

                              // },
                            }}
                          >
                            {el}
                          </Typography>

                        </Grid>
                      </Grid>

                    )

                    :
                    item.key === 2 ?

                    op_data.funding.details.map((el, index) =>

                      <Grid container>
                        <Grid style={{
                          // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",

                          marginTop: "15px",
                          width:"100%",
                          display: "flex",
                          alignItems: "center",
                          textAlign: "left",

                        }}>
                          <Grid
                            container
                            style={{
                              fontSize: 10,
                              // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
                              background: "#FFF",
                              color: "black",
                              borderRadius: 180,
                              minWidth: "40px",
                              width:"40px",
                              height: "40px",
                              boxShadow: op_data.event_type === 0 ? "0px 2px 9px rgba(98,182,208)" : "0px 2px 9px rgba(241 ,185, 208)",
                              marginRight: 15,
                              display: "flex",
                              alignItems: "center",
                              flexWrap: "wrap",
                              textAlign: "center",
                              justifyContent: "center"
                            }}
                          >
                            <svg width="20" height="20" x="0" y="0" viewBox="0 0 32 32" fill={op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"} ><g><path d="M6 11V7a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0zM20 9h-4V4a2 2 0 1 1 4 0zM25 9h-4V5a2 2 0 1 1 4 0zM26 13v5.343a3.995 3.995 0 0 1-1.172 2.828l-2.243 2.243A2.004 2.004 0 0 0 22 24.828V30H9v-5.333c0-.433-.14-.854-.4-1.2l-1.8-2.4a4.002 4.002 0 0 1-.8-2.4v-4.223A3.959 3.959 0 0 0 8 15c.951 0 1.813-.347 2.5-.903A3.958 3.958 0 0 0 13 15c.499 0 .97-.104 1.408-.276a4 4 0 0 0 2.982 2.23C15.802 18.6 15 20.29 15 22a1 1 0 1 0 2 0c0-1.659 1.211-3.408 3.6-5.2A.999.999 0 0 0 20 15h-2c-1.103 0-2-.897-2-2v-2h8a2 2 0 0 1 2 2z" ></path><path d="M11 11V5a2 2 0 1 1 4 0v4a1 1 0 0 0-1 1v2.723A1.987 1.987 0 0 1 13 13a2 2 0 0 1-2-2z" fill={op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"} ></path></g></svg>
                          </Grid>

                          <Typography
                            color="black"
                            gutterBottom
                            variant="body1"
                            sx={{
                              fontFamily: "Changa",
                              // width:"75%",
                              fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},
                              // paddingRight: "15px",
                              margin: 0,
                            //   "@media (max-width: 1400px)": {
                            //     fontSize: "18px !important",
                            //     lineHeight: "18px",
                            // }
                          }}
                          >
                            {el}
                          </Typography>

                        </Grid>
                      </Grid>

                    )
                    :
                    <></>}
              </Typography>

            </Grid>
          </Grid>
          

      </Grid>

    )
      ;
  }

  function getOpTextList(items) {
    return (
      <Grid ref={wrapper} style={{ width: "100%", flexDirection: "column", display: "flex",maxWidth:1500,paddingTop:"100px"  }}>
        {items.map((item) =>
        (item.key === 4 ?
          <Grid style={{ width: "100%" }}>
            <OpTextItem key={item.key} item={item} />
            <ImpactAreasSection eventType={op_data.event_type} impact_areas={op_data.impact_areas} />
          </Grid> :
          <OpTextItem key={item.key} item={item} />)
        )}
      </Grid>
    );
  }


  return (
    <>
            <ThemeProvider theme={theme}>

      <div style={{ position: "relative", overflow: "hidden",paddingTop:"150px" }}>
        {/* <DACHeader slide={op_data} /> */}        

        <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: "center", textAlign:"center" , width:"100%", padding: "100px 3.14vw"}} sx={statTheme}>
        <Grid 
              container 
              direction="row" 
              sx={{ 
                "@media screen and (max-width: 900px)": {
                  paddingBottom: "0",
                },
                paddingBottom: "3.14vw", 
              }}
            >
              <Grid item xs={12} sx={{textAlign:"left",display:"flex", flexDirection:"row","@media screen and (max-width: 900px)": {
                      textAlign:"center",
                      flexDirection:"column",
                      alignItems:"center",
                    }}}>
                <Typography
                  // variant="h2" 
                  component={'span'}

                  sx={{
                    margin: 0,
                    // width:"100%",
                    flexShrink:0,
                    display: "flex",
                    fontFamily: "Changa",
                    color: "#62B6D0",
                    fontSize:"1.25rem",
                    lineHeight:"1.6rem",
                  }}
                >
                  {/* The Clarintel Apprenticeship Rotational Emergence Program */}
                  {op_data.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    component={'span'}
                    style={{
                      letterSpacing: "-0.09em",
                      fontFamily: "Changa",
                      paddingLeft: "5px",
                      lineHeight:"1.6rem",
                      color: "#62B6D0",

                    }}
                  >
                    {`----`}
                  </Typography>
              </Grid>
            </Grid>
        <Typography
          variant="h2"
        >
          {op_data.tagline}
        </Typography>
      </Grid>
      <img
        src={op_data.image}
        style={{ width: "100%" }}
        alt={"background"}
        loading="lazy"
      />      </div>
      <Grid container style={{
        background: "#FFFF",
        color: "white",
        padding: "0px 3.14vw",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-1px",
        justifyContent:"center",
      }}>

          {/* <Grid container direction="row" style={{ width: "100%", justifyContent: "space-around", paddingBottom: "30px", paddingTop: "30px" }}>

            {op_data.philopics.map((philopic, index) => (
              <Grid key={index} style={{ borderStyle: "solid", borderColor: op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)", borderRadius: 50, textAlign: "center", margin: "5px" }}>
                <Typography
                  color={op_data.event_type === 0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"}
                  gutterBottom
                  variant="h5"
                  style={{ fontSize: ".8rem", paddingTop: 5, paddingBottom: 5, paddingLeft: 10, paddingRight: 10, margin: 0 }}
                >
                  {philopic}
                </Typography>
              </Grid>
            ))}
          </Grid> */}

          {getOpTextList(opData)}

      </Grid>
      </ThemeProvider>

    </>

  );
}

