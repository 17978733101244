export const nonprofits = [
  
  {
    value: "nonprofit-0",
    label: "Not Applicable",
    color: "#00B8D9",
    isFixed: true,
  },
  // {
  //   value: "nonprofit-00",
  //   label: "Other",
  //   color: "#00B8D9",
  //   isFixed: true,
  // },
  {
    value: "nonprofit-8552",
    label: "Caribbeana Communications Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8553",
    label: "Sow A Seed Community Chest",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8554",
    label: "Italian American Civic Club Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8555",
    label: "Progress Solutions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8556",
    label: "Mountain Express Track Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8557",
    label: "W O M A N Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8558",
    label: "Asbury Communities Hcbs Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8559",
    label: "Young Artists Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8560",
    label: "Capital Air Chapter 134 Of The Association Of Civilian Technician",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8561",
    label: "Tilghman Area Youth Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8562",
    label: "You Too Can Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8563",
    label: "Ministry Of Blessing",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8564",
    label: "Amazing Grace Orphans And Battered Women Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8565",
    label: "Fire Ablaze Ministries International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8566",
    label: "Command Jos Alumni Of North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8567",
    label: "Alfred Mensah Charities",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8568",
    label: "Give Life",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8569",
    label: "Chi Health Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8570",
    label: "Linganore High School Sports Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8571",
    label: "Pen Step Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8572",
    label: "Prodigal Sons Christian Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8573",
    label: "Uaa-Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8574",
    label: "Safe Silver Spring Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8575",
    label: "Made In His Image Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8576",
    label: "Children Landcare Center International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8577",
    label: "Still Pond Preservation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8578",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8579",
    label: "Big Big World Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8580",
    label: "Northstar Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8581",
    label: "Dc Diplomats Team Handball Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8582",
    label: "Ravens Roost 119 Rising Sun Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8583",
    label: "Reunion Sportive D Haiti Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8584",
    label: "City Harbor Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8585",
    label: "Drim Missions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8586",
    label: "Finest Performance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8587",
    label: "Foundation For Maryland Conservation History Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8588",
    label: "National Foundation For Cancer Research Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8589",
    label: "International Leadership Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8590",
    label: "Awaken Aurora Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8591",
    label: "Mbatu Cultural And Development Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8592",
    label: "Imo State Women Association Of D C Metro Area Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8593",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8594",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8595",
    label: "John Armiger Scholarship Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8596",
    label: "Butz Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8597",
    label: "Lake Roland Nature Council Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8598",
    label: "Livelihoodngo-Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8599",
    label: "Ridge To Reefs Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8600",
    label: "House Of Music Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8601",
    label: "Love Fellowship Temple Of Praise Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8602",
    label: "New Mount Zion Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8603",
    label: "Maryland Ultrasound Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8604",
    label: "First Panamanian Marching Band Of Maryland Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8605",
    label: "Tabernacle Of David Worship Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8606",
    label: "Cross Roads Youth Empowerment Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8607",
    label: "Iglesia Jesus Fuente De Vida Jovenes Cristianos",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8608",
    label: "Birhan Christian Followship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8609",
    label: "Garland Hayward Youth Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8610",
    label: "George & Carolyn Metskas Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8611",
    label: "Hr Source Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8612",
    label: "Chinese Friendship Affairs Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8613",
    label: "Partners For Cancer Care And Prevention Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8614",
    label: "Carroll County Foster-Adoptive Parent Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8615",
    label: "Omega Baltimore Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8616",
    label: "Organize For Liberia",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8617",
    label: "Chananya Backer Memorial Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8618",
    label: "Life4Sofala Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8619",
    label: "Power Of Compassion Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8620",
    label: "Wildlife Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8621",
    label: "Wildlife Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8622",
    label: "Susquehannock Wildlife Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8623",
    label: "J80 Usa Class Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8624",
    label: "Asambleas De Dios Monte Sion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8625",
    label: "House Of Hope Animal Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8626",
    label: "Cwff Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8627",
    label: "Ks2 4Ds Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8628",
    label: "Joyce S Home Of New Beginnings",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8629",
    label: "Baltimore Field Hockey Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8630",
    label: "Celtic Star Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8631",
    label: "Msd Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8632",
    label: "Dpha Council Of Fellows Scholarship Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8633",
    label: "Little Hands Of Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8634",
    label: "Ispar Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8635",
    label: "Fox Hollow Mens Golf Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8636",
    label: "Korean Association For Asian Classic Studies Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8637",
    label: "Iglesia Pentecostal Tabernaculo De Restauracion Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8638",
    label: "Richards Group Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8639",
    label: "Heavenly Gate Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8640",
    label: "Healthy Presbyterian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8641",
    label: "Champ House Recovery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8642",
    label: "Second Chance Community Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8643",
    label: "Tree Of Life Garden Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8644",
    label: "Thomas S Wootton High School Patrons Of The Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8645",
    label: "Stand As One Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8646",
    label: "Pauperism Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8647",
    label: "Feral Cat Rescue-Research",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8648",
    label: "Bill And Karen Itzel Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8649",
    label: "Chabad Of O C Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8650",
    label: "Collaborative Council Of Western Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8651",
    label: "Antigua International Education Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8652",
    label: "Assembly Of Ichthus Ministries Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8653",
    label: "Aki Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8654",
    label: "Brotherhood Of The Cross Star Maryland Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8655",
    label: "Salisbury School Educational Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8656",
    label: "Tri-State Christian Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8657",
    label: "Cecil County Junior Football Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8658",
    label: "Change That Cares",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8659",
    label:
      "Sports & Entertainment Promoters Of America Inc Education Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8660",
    label: "Tamil Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8661",
    label: "Ohr Chadash Congregation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8662",
    label: "Bwotc Community Outreach Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8663",
    label: "Military Health Research Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8664",
    label: "Extended Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8665",
    label: "Wildlife Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8666",
    label: "Wildlife Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8667",
    label: "New Freedom Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8668",
    label: "Holy Archangels Orthodox Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8669",
    label: "Gulistan E Zahara Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8670",
    label: "Hub Opera Ensemble Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8671",
    label: "Esperanza Center Health Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8672",
    label: "Shiloh Baptist Church Foreign Missions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8673",
    label: "Arumdaun Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8674",
    label: "South Harbor Renaissance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8675",
    label: "International Communion Of Evangelical Churches Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8676",
    label: "Metropolitan Youth Sports Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8677",
    label: "Calvary Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8678",
    label: "Creative City Public Charter School Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8679",
    label: "Surfers Helping Our Children With Autism Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8680",
    label: "Donnacize Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8681",
    label: "Himarriotan Society Of America H Xeimappa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8682",
    label:
      "Friends Of Baltimore City Public Schools Great Kids Farm Foundtn In",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8683",
    label: "Maryland Council For Social Studies",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8684",
    label: "Gender Rights Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8685",
    label: "Gender Rights Maryland Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8686",
    label: "American Culinary Federation Greater Baltimore Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8687",
    label: "Confraternity Of St Gabriel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8688",
    label: "Gods Visions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8689",
    label: "Mechanicsville Braves Football & Cheer Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8690",
    label: "Herman & Walter Samuelson Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8691",
    label: "Common Ground Community Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8692",
    label: "Nourish Now Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-8693", label: "Fc Usa", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-8694",
    label: "Meor Dc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8695",
    label: "Iglesia De Dios De La Profesia Nuevo Pacto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8696",
    label: "Grace Classical Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8697",
    label: "Church Without Walls Outreach Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8698",
    label: "Fortified City Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8699",
    label: "Ywam Brandywine Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8700",
    label: "Baltimore Heritage Area Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8701",
    label: "Youth As Resources Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8702",
    label: "Basa Balinese Language Preservation Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8703",
    label: "Foundation For Jewish Womens Arts And Letters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8704",
    label: "Worcester County Fire Police Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8705",
    label: "Sons Of Unions Veterans Of The Civil War",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8706",
    label: "Healing Vessels International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8707",
    label: "Mike Murray Memorial Outreach Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8708",
    label: "One Montgomery Green Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8709",
    label: "American Society For Nutrition Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8710",
    label: "Out For Justice Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8711",
    label: "Xsquad Parents Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8712",
    label: "Iglesia Pentecostes Jesus Es Mi Refugio",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8713",
    label: "Muddy Branch Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8714",
    label: "The Watchmen Ministry Worship Cente R",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8715",
    label: "In-One International Ministeries Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8716",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8717",
    label: "Spartan Community Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8718",
    label: "Ancient Order Of Hibernians In America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8719",
    label: "Asbury Church Assistance Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8720",
    label: "Scares That Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8721",
    label: "Ghana Has A Need Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8722",
    label: "Sramani Institute Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8723",
    label: "Blue Print Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8724",
    label: "Evergreen Swim Team Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8725",
    label: "Xtreme Australian Cattle Dog Juniors Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8726",
    label: "Kingdom Celebration Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8727",
    label: "American Federation Of State County & Municipal Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8728",
    label: "Digital Harbor Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8729",
    label: "At The House Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8730",
    label: "Give2Cuba",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8731",
    label: "Donations From The Heart Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8732",
    label: "Maryland Student Hockey League Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8733",
    label: "Broken Road Associates Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8734",
    label: "Deliver The Captives Church-Temple Of Praise Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8735",
    label: "Friends Of The Palestinian Happy Child Centre",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8736",
    label: "Caribbean Health And Education Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8737",
    label: "Aspire 2 Move Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8738",
    label: "Embry Center For Family Life",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8739",
    label: "People For Change Coalition",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8740",
    label: "National 4-H Congress Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8741",
    label: "House Of Serenity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8742",
    label: "Zone 1 Porsche Club Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8743",
    label: "Marriott Daughters Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8744",
    label: "St Thomas Aquinas Tutorial Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8745",
    label: "Oscar E Brown Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8746",
    label: "Thomas H Redmiles Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8747",
    label: "Monarch Academy Baltimore Campus Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8748",
    label: "Burlington-Camden Achievement Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8749",
    label: "Hans Creek Community Hall Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8750",
    label: "Frag Infinity Tournament Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8751",
    label: "O L Pathy Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8752",
    label: "Chris Lantz Memorial Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8753",
    label: "Crossway Christian Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8754",
    label: "Marcus A Johnson Sr Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8755",
    label: "Community Services Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8756",
    label: "St John Long Green Valley Roman Catholic Elem School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8757",
    label: "Learn In To Live Again",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8758",
    label: "St John Westminster Catholic School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8759",
    label: "St Ursula Roman Catholic Elem School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8760",
    label: "Fun-Raising Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8761",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8762",
    label: "Grace Chinese Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8763",
    label: "Chestertown Lions Charities Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8764",
    label: "Our Lady Of Mt Carmel School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8765",
    label: "St Agnes Roman Catholic Elem School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8766",
    label: "Believe Big Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8767",
    label: "Love Works Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8768",
    label: "Riverside Bmx Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8769",
    label: "Compass Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8770",
    label: "Bessie Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8771",
    label: "Foundation Of Life Evangelical Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8772",
    label: "Sundis Gift Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8773",
    label: "Leveling The Playing Field Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8774",
    label: "Delmarva Poodle Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8775",
    label: "Helping Hands Non-Profit Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8776",
    label: "Warrior Events",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8777",
    label: "Journey Church Columbia Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8778",
    label: "Support Beam Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8779",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8780",
    label: "Leslie & Samuel Kaplan Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8781",
    label: "Our Lady Of Grace Roman Catholic Elem School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8782",
    label: "Csc Faith Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8783",
    label: "Rory J Weichbrod Unfinished Business Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8784",
    label: "Gar Post 25 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8785",
    label: "Disability-Inclusive Development Programs And Services - Didepas",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8786",
    label: "Field Trip Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8787",
    label: "Imo Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8788",
    label: "This Is America Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8789",
    label: "The Reed Center For Ecosystem Reintegration",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8790",
    label: "Through You New Life Hope And Faith Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8791",
    label: "Global Virus Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8792",
    label: "La Mano Of Hope Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8793",
    label: "6Th Marine Division Assoc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8794",
    label: "Chesapeake Institute For Local Sustainable Food And Agriculture I",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8795",
    label: "Foundation For The Kent County Public Library Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-8796", label: "Dt Co", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-8797",
    label: "Upper Chesapeake Basketball Officials Assoc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8798",
    label: "Shay Sharpes Pink Wishes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8799",
    label: "Old Line Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8800",
    label: "Raising An Individuals Self Esteem",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8801",
    label: "Partnership And Inspiration For Engineering Education And Entrepre",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8802",
    label: "Chesapeake Creation Spirituality Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8803",
    label: "Apostolic Breakthrough International Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8804",
    label: "Believers United In Christ Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8805",
    label: "Igbo Council Of Traditional Titleholders Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8806",
    label: "Islamic Society Of Princess Anne Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8807",
    label: "El Pilar Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8808",
    label: "Hyattsville Aging In Place",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8809",
    label: "Jubilee Temple International Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8810",
    label: "Patuxent Lapidary Guild Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8811",
    label: "Keepers Of The Flame International Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8812",
    label: "Barbara J Dreyer Cares Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8813",
    label: "Neighbors Near And Far Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8814",
    label: "Hope And Helping Hands Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8815",
    label: "Wildlife Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8816",
    label: "Center For Understanding Change Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8817",
    label: "The Legacy At North August Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8818",
    label: "Northwest Haiti Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8819",
    label: "Wayne Stinchcomb Big Orange Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8820",
    label: "Southern Maryland Roundup Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8821",
    label: "Mizo Chin Christian Fellowship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8822",
    label: "Fullerton Fireworks Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8823",
    label: "Black Advancement Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8824",
    label: "Olive Tree Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8825",
    label: "Payne Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8826",
    label: "Christ Glory Outreach Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8827",
    label: "Christs Church Of The Mid-Atlantic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8828",
    label: "Plan Ahead Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8829",
    label: "The Heavners",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8830",
    label: "Peter J Golueke Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8831",
    label: "Spark Camp Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8832",
    label: "Tidewater Rotary Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8833",
    label: "Evans Victory Against Neuroblastoma Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8834",
    label: "Cincinnatus Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8835",
    label: "Preston Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8836",
    label: "Baltimore Torah School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8837",
    label: "Victory Court Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8838",
    label: "Frederick Indian Lutheran Fellowship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8839",
    label: "Prince Georges County Police Tep",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8840",
    label: "For Life Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8841",
    label: "Team Hustle Dmv",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8842",
    label: "Hindu American Seva Communities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8843",
    label: "American Academy Of Pain Medicine Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8844",
    label: "Maryland International Film Festival Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8845",
    label: "Gurmu Abishe Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8846",
    label: "Global Health And Education Projects Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8847",
    label: "Church Of Philadelphia At Port Tobacco",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8848",
    label: "Warrior Canine Connection Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8849",
    label: "Chesapeake Farmland Preservation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8850",
    label: "Next Wave Muslim Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8851",
    label: "Baltimore Lab School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8852",
    label: "Howard J Purnell Memorial Post 145 The American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8853",
    label: "Seize The Days Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8854",
    label: "Wholly Christian Ministries International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8855",
    label: "Lower Shore Parkinsons Support Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8856",
    label: "Frederick Health Medical Group Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8857",
    label: "Fort Meade Alliance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8858",
    label: "Stories Change Power",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8859",
    label: "Amaigbo Daughters Association Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8860",
    label: "Open Heart Sanctuary",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8861",
    label: "Association Of Roman Catholic Homeschoolers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8862",
    label: "Friendly Menoua Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8863",
    label: "Olivia Constants Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8864",
    label: "Stoney Creek Ravens Roost 121",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8865",
    label: "Hands On Patterson Park Public Charter School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8866",
    label: "Assurant Educational Alliance Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8867",
    label: "Circadian Sleep Disorders Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8868",
    label: "Shore Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8869",
    label: "World Street Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8870",
    label: "Music Is Medicine",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8871",
    label: "I M Still Standing Community Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8872",
    label: "Coach Doug Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8873",
    label: "Metta Cultural And Development Association Of Washington Dc Metro",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8874",
    label: "Lakeview Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8875",
    label: "Cpm Vineyard Of Grace",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8876",
    label: "Iranian-American Strategic Research Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8877",
    label: "Little League Baseball Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8878",
    label: "Grace Life Bible Fellowship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8879",
    label: "Rural Dog Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8880",
    label: "Good Sower Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8881",
    label: "Daniel Carl Torsch Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8882",
    label: "Iglesia Eben-Ezer Palabra De Esperanza",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8883",
    label: "Down Syndrome Network Of Montgomery County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8884",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8885",
    label: "Paul Rabil Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8886",
    label: "Flying V Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8887",
    label: "Victory Outreach Services In",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8888",
    label: "Glenelg Marching Unit Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8889",
    label: "Edgewood High School Athletic Booster Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8890",
    label: "Friends Of The Urbana Regional Library",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8891",
    label: "Federated Garden Clubs Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8892",
    label: "Fatima Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8893",
    label: "Immediate Impacts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8894",
    label: "Gloria Mission Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8895",
    label: "Phi Sigma Sigma Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8896",
    label: "Phi Sigma Sigma Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8897",
    label: "Infrastructure Financial Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8898",
    label: "Brothers And Sisters Without Borders",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8899",
    label: "Pool Of Siloam Healing Ministry Posh Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8900",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8901",
    label: "Troys Dream Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8902",
    label: "Arts Unleashed Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8903",
    label: "United 2 Save Animals Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8904",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8905",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8906",
    label: "Peer Wellness And Recovery Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8907",
    label: "Maryland Families For Safe Birth",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8908",
    label: "Friends Of St Johns Chapel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8909",
    label: "Frank And Marta Jager Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8910",
    label: "Birds Of Prey Ravens Nest No 18 Ventnor Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8911",
    label: "Kingdom Harvest Discipleship Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8912",
    label: "Hope For Lives In Sierra Leone Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8913",
    label: "Farm Food Freedom Coalition",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8914",
    label: "Deep Impact Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8915",
    label: "Charlie & Del Major Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8916",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8917",
    label: "Ark Christian Covenant Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8918",
    label: "Sister S With Purpose Kingdom Of Heaven Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8919",
    label: "Mkcsbk Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8920",
    label: "Girls Rule The World",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8921",
    label: "Keestone Katz Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8922",
    label: "Body Of Christ Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8923",
    label: "Willow Brook Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8924",
    label: "Industrial Workers Of The World",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8925",
    label: "American Citizens For Italian Matters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8926",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8927",
    label: "Learning Forward Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8928",
    label: "Iglesia Cristiana Columna De Fuego Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8929",
    label: "Mission 14 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8930",
    label: "Mount Clare Community Council Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8931",
    label: "Step Up Lacrosse Charity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8932",
    label: "Joy Of Life A Developmental Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8933",
    label: "Haydens Heroes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8934",
    label: "Life Through Christ Christian Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8935",
    label: "Firm Foundation And Faith Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8936",
    label: "Globeinvent Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8937",
    label: "Glen Echo Fire Department Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8938",
    label: "Golden Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8939",
    label: "Martin Circle Of Hope Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8940",
    label: "Isc2 National Capital Region Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8941",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8942",
    label: "Dance Happens Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8943",
    label: "Federal Hill Preparatory School Parent Teacher Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8944",
    label: "Bens Gift Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8945",
    label: "Amicimusic",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8946",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8947",
    label: "David And June Trone Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8948",
    label: "International Alumnae Of Delta Epsilon Sorority Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8949",
    label: "Altventures Media Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8950",
    label: "South River Volleyball Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8951",
    label: "Lifegate International Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8952",
    label: "Blacks Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8953",
    label: "Topless For Tatas",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8954",
    label: "Ebeauty Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8955",
    label: "Iglesia Bautista De Waldorf",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8956",
    label:
      "Fu Jen Catholic University Greater Washington Dc Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8957",
    label: "Impact Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8958",
    label: "Makuyu Education Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8959",
    label: "Triple S Cares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8960",
    label: "Tabernacles Of Deliverance Christian Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8961",
    label: "Team Dedication Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8962",
    label: "Brass House",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8963",
    label: "Washington Dc Great Faith Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8964",
    label: "Operation Save A Child",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8965",
    label: "Mid-Atlantic Society For Biofeedback And Behavioral Medicin",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8966",
    label: "Living Bytruth Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8967",
    label: "Virtue In Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8968",
    label: "Nternational Ministry Of Praise And Worship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8969",
    label: "Niwap Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8970",
    label: "Finksburg Baseball",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8971",
    label: "By Faith Christian Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8972",
    label: "African Refugees And Victims Relief Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8973",
    label: "Union Community House Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8974",
    label: "Youths Mentoring Abilities Professionally",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8975",
    label: "Malik Attila Cosby Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8976",
    label: "Vietnam Veterans Of Hotel Company 2Nd Battalion 7Th Marines",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8977",
    label: "Sun Stand Still Recovery Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8978",
    label: "Congregation Of Bon Secours Of Paris Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8979",
    label: "Ireland America Science Forum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8980",
    label: "Parks And Rec Woodshop Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8981",
    label: "International Central Gospel Church Of Southern Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8982",
    label: "Baltimore County Monument Commission",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8983",
    label: "Afghan Womens Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8984",
    label: "Jewels Heritage Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8985",
    label: "Ojczyzna Polish Dancers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8986",
    label: "Edward B Byrd Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8987",
    label: "Maryland Community And Small Business Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8988",
    label: "Victory Brookside Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8989",
    label: "Figure Skating Club Of Southern Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8990",
    label: "Shalom Square Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8991",
    label: "Hope In South Africa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8992",
    label: "Blacks In Government",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8993",
    label: "Clarion Call",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8994",
    label: "Demi Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8995",
    label: "Redeemed Christian Church Of God Kings Court Inc Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8996",
    label: "Woape Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8997",
    label: "New Covenant Life Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8998",
    label: "Grand Bassa County Scholarship Foundation Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8999",
    label: "Psoriasis Network Support",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9000",
    label: "Global Impact Sport Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9001",
    label: "Wildlife Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9002",
    label: "Zera Missions International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9003",
    label: "Deep Vision Dance Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9004",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9005",
    label: "Sofcc Warriors Athletics",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9006",
    label: "Kelsie Holtje Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9007",
    label: "Shou Chu Organization Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9008",
    label: "Prince Georges County Education Coalition",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9009",
    label: "Maryland Fund For Horses",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9010",
    label: "Oakdale High School Safe And Sane Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9011",
    label: "Mahamevnawa Meditation Center Of Greater Washington Dc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9012",
    label: "We Lead By Example Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9013",
    label: "We Helping Us Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9014",
    label: "Gpccn Recreational Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9015",
    label: "Asylee Women Enterprise",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9016",
    label: "Feed My Sheep Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9017",
    label: "Greater Washington Agape Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9018",
    label: "Path Of Life Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9019",
    label: "Heart N Hands Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9020",
    label: "Association Of Clinical Elbow And Shoulder Surgeons Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9021",
    label: "Charm City Players Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9022",
    label: "Pinkney Innovation Complex For Science & Technology At Montgomery",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9023",
    label: "Macedonia Life Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9024",
    label: "Global Women Entrepreneurs Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9025",
    label: "Be Great Alexanders Foundation For Children Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9026",
    label: "Egg Track Club A Nj Nonprofit Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9027",
    label: "Best In Shelter Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9028",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9029",
    label: "Health Empowerment Network Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9030",
    label: "Moshe Ben Shlomo Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9031",
    label: "Maryland Bangla-Islamic School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9032",
    label: "Biohealth Innovation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9033",
    label: "Westminster Ingleside King Farm Presbyterian Retirement Communitie",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9034",
    label: "Laurlene Straughn Pratt Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9035",
    label: "Team Captain Kids Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9036",
    label: "Blessed In Tech Ministries Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9037",
    label: "Smyrna Church Of Washington",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9038",
    label: "Protect The Children Future Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9039",
    label: "Coexist Foundation America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9040",
    label: "Community Ministry Of Calvert County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9041",
    label: "Operation We Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9042",
    label: "National Taiwan Univ Alumni Assoc In The Washington-Baltimore Area",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9043",
    label: "Redfern Social Ventures Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9044",
    label: "Igbo Catholic Community Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9045",
    label: "Sholk-Kaplan Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9046",
    label: "Marvin And Delia Lang Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9047",
    label: "Seeds Of Excellence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9048",
    label: "J P Home Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9049",
    label: "February Star Sanctuary Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9050",
    label: "Renaissance Gardens Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9051",
    label: "Ebooks For Students Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9052",
    label: "Concilio Internacional Luz Y Vida",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9053",
    label: "Sala Evangelica Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9054",
    label: "911 For Neck And Head Cancer Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9055",
    label: "Auroras Lights",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9056",
    label: "Dominican Province Of Saint Joseph",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9057",
    label: "Excellence In Education Foundation For Pgcps Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9058",
    label: "Iglesia Pentecostes Fuente De Vida",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9059",
    label: "Junior Hubs Youth Athletic Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9060",
    label: "Sports Neuropsychology Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9061",
    label: "1000 Urban Scholars",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9062",
    label: "Maryland Youth Pipe Band Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9063",
    label: "Restore Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9064",
    label: "Nightwatch Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9065",
    label: "World At Ease Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9066",
    label: "Dannys Day Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9067",
    label: "Living Water Holistic Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9068",
    label: "Jehovah Jireh Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9069",
    label: "Tri-State Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9070",
    label: "Village At Crystal Spring Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9071",
    label: "Path To Greatness",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9072",
    label: "Repair & Restore Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9073",
    label: "Baltimore Cemetery Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9074",
    label: "Vavs Partners Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9075",
    label: "Maryland Society For The Rheumatic Diseases Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9076",
    label: "Assets For Financial Independence Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9077",
    label: "Iglesia Misionera Lirio De Los Valles Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9078",
    label: "Prince Georges County Emergency Radio Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9079",
    label: "Living Waters For Christ Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9080",
    label: "Northeastern Maryland University Research Park",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9081",
    label: "Grace Place",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9082",
    label: "Learning-At-Large",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9083",
    label: "Halls That Inspire Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9084",
    label: "Hunger 4 Humanity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9085",
    label: "Izzys Love Equine Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9086",
    label: "Manuel J Marshall Memorial Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9087",
    label: "4 Wheel To Heal",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9088",
    label: "Moco Kidsco",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9089",
    label: "Asian American Retailers Association Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9090",
    label: "Nina Beck Memorial",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9091",
    label: "Salih Self-Development Center Of Aboabo Kumasi Ghana",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9092",
    label: "Old-Growth Forest Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9093",
    label: "Rona And Jeffrey Abramson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9094",
    label: "Church Of The Great Anointing",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9095",
    label: "Tutor For America Now Dot Org Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9096",
    label: "Gathering International Worship Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9097",
    label: "Survivors Global Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9098",
    label: "Coloton Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9099",
    label: "Frederick Health Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9100",
    label: "Marine Corps League",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9101",
    label: "Michael Jones Mentoring Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9102",
    label: "Solid Rock Outreach Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9103",
    label: "Gnanananda Seva Samajam Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9104",
    label: "Zions Daughters Dance Ensemble Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9105",
    label: "Faith Filled Women Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9106",
    label: "Agape Temple Of Praise And Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9107",
    label: "New Covenant International Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9108",
    label: "Ff Karl Henri Joseph Education Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9109",
    label: "Friends Of Olney Farmers & Artists Market Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9110",
    label: "Encounter Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9111",
    label: "Great Love Mission Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9112",
    label: "Ms 4 Ms",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9113",
    label: "Sister Supporting Salvation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9114",
    label: "Harford Swim League Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9115",
    label: "Talisman Therapeutic Riding",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9116",
    label: "Iglesia Apostoles Y Profetas Rios De Agua Viva",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9117",
    label: "Assegid Habtewold Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9118",
    label: "Calvert Cure Baseball Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9119",
    label: "Department Of Oral And Maxillofacial Surgery At The Washi",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9120",
    label: "Shekinah Glory Worship Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9121",
    label: "Leftridge Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9122",
    label: "Sourlis-Eleuterio Homes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9123",
    label: "Pauline F And W David Robbins Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9124",
    label: "My Brothers Keeper Of Bethesda Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9125",
    label: "Greater Oxon Hill Community Devlopment Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9126",
    label: "Eko Community Development Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9127",
    label: "Tina Grimes Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9128",
    label: "3 Angels Gospel Ministry International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9129",
    label: "Vine Corps Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9130",
    label: "Freedom Hky Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9131",
    label: "Alternatives To Violence Project Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9132",
    label: "Santo Outreach Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9133",
    label: "Word & Worship Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9134",
    label: "Journey Of Faith Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9135",
    label: "Lemou Bafou Usa Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9136",
    label: "Skill Will Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9137",
    label: "American Friends Of The Us-Israel Binational Science Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9138",
    label: "Home At Last Sanctuary Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9139",
    label: "Village Crossroads Senior Housing Ii Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9140",
    label: "Aviation Lawyers Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9141",
    label: "Creole Descendant Association Washington Metropolitan Area Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9142",
    label: "C&C Advocacy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9143",
    label: "West Inverness Recreation And Parks",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9144",
    label: "Learning Ladders Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9145",
    label: "Open Heaven Realities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9146",
    label: "Loving And Healthy And Vibrant Marriages Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9147",
    label: "Southern Maryland Roller Derby Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9148",
    label: "Us Johnson Map Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9149",
    label: "Innovation Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9150",
    label: "Serenity Sistas",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9151",
    label: "Aarons Hope",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9152",
    label: "Golden Mile Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9153",
    label: "Teach Em To Fish Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9154",
    label: "Waterkeepers Chesapeake Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9155",
    label: "Iglesia Evangelica Roca De Salvacion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9156",
    label: "The Keith Warren Justice Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9157",
    label: "Sanaria Institute For Global Health And Tropical Medicine Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9158",
    label: "German Law Enforcement Officers Association Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9159",
    label: "Friends Of Maryland Becoming An Outdoors-Woman Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9160",
    label: "Daniel P Stone Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9161",
    label: "Psalms In Motion Musical Theater Center Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9162",
    label: "Manta Ray Swim Team Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9163",
    label: "Nutribee National Nutrition Competition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9164",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9165",
    label: "Under Armour Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9166",
    label: "A Bigger Picture",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9167",
    label: "New Vision Presbyterian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9168",
    label: "Culture Train Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9169",
    label: "Guykids",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9170",
    label: "Outreach To Mainland China Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9171",
    label: "Baltimore Dockers Afc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9172",
    label: "Ravenquoth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9173",
    label: "Pure Integrate Health Services Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9174",
    label: "Learn For Life Education Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9175",
    label: "Araminta Freedom Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9176",
    label: "Adv Yehweh Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9177",
    label: "Occasional Symphony",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9178",
    label: "Vision Of Nations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9179",
    label: "William J Sacco Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9180",
    label: "Harvest Time International Outreach Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9181",
    label: "Catholic Business Network Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9182",
    label: "Indian Head Center For The Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9183",
    label: "Community Life At The Towers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9184",
    label: "Iglesia De Dios Pentecostal Monte De Los Olivos",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9185",
    label: "Toolbank Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9186",
    label: "Minders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9187",
    label: "100 Black Men Of Prince Georges County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9188",
    label: "Achieving Community Empowerment Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9189",
    label: "Seaaffinity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9190",
    label: "Partnership League For Africas Development",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9191",
    label: "Iglesia Pentecostal Unida Hispana De Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9192",
    label: "Renaissance Equestrian Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9193",
    label: "Mezu International Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9194",
    label: "Aqua Dawg Swim Camp Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9195",
    label: "Heroes For The Homeland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9196",
    label: "Legacy Flight Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9197",
    label: "Iglesia Biblica Bautista La Roca Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9198",
    label: "Madieu Williams Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9199",
    label: "American Society Of Highway Engineers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9200",
    label: "100 Entrepreneurs Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9201",
    label: "Abundant Life Ministries International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9202",
    label: "Hands Of An Angel",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-9203", label: "Relove", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-9204",
    label: "High Bridge Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9205",
    label: "Cristata Cares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9206",
    label: "Alice D Hamling Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9207",
    label: "Judith Bramble Highways & Byways Ministries International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9208",
    label: "Bayside Breeze Fastpitch Youth Softball",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9209",
    label: "Opportunity Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9210",
    label: "Maryland Foster Parent Assn Balt Co",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9211",
    label: "Beat The Streets Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9212",
    label: "Northstar Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9213",
    label: "Wounded Heroes Hunting Camp Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9214",
    label: "Rccg Royal Priesthood Ministry Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9215",
    label: "Lunar Solis Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9216",
    label: "The Gemz Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9217",
    label: "Victory Kingdom Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9218",
    label: "Iglesia Cristiana Hebron",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9219",
    label: "Mission Launch Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9220",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9221",
    label: "Semyon And Janna Friedman Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9222",
    label: "Driving Force Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9223",
    label: "Soteria Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9224",
    label: "Empowering Community Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9225",
    label: "Hephzibah Reformation Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9226",
    label: "Koz Minisries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9227",
    label: "Mizo Society Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9228",
    label: "Sleeping With God Meditation Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9229",
    label: "Dog Tags And Tails Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9230",
    label: "Great Commission Network Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9231",
    label: "Kw March For Kids Health Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9232",
    label: "International Mountain Bicycling Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9233",
    label: "Morris Weinman Memorial Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9234",
    label: "Jcrafts By Chabad Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9235",
    label: "Center For Muslim Life A Nj Nonprofit Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9236",
    label: "Seek And To Save Missions International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9237",
    label: "Providence Charitable Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9238",
    label: "Baltimore Metro Silent Oriole Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9239",
    label: "Live And Learn Bethesda Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9240",
    label: "Hope Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9241",
    label: "Care Packages Of Love Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9242",
    label: "Association Of Physicians Of Pakistani Descent Of North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9243",
    label: "Second Chances Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9244",
    label: "Robert W Awalt Sr Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9245",
    label: "White Aisle Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9246",
    label: "Owerri Peopless Association Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9247",
    label: "Brigance Brigade Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9248",
    label: "Hpi Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9249",
    label: "World Nurture Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9250",
    label: "It Takes Two Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9251",
    label: "Kappa Foundation Of Fort Washington",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9252",
    label: "Children Of Promise Children Of Hope Partnership",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9253",
    label: "Maryland Area College Music Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9254",
    label: "Philanthropik Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9255",
    label: "Cat Crusaders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9256",
    label: "Washington Care Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9257",
    label: "Kerala Cultural Society Of Metropolitan Washington",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9258",
    label: "Jaylens Journey",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9259",
    label: "Winfield Elementary Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9260",
    label: "Boomer Policy Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9261",
    label: "Caterpillar Clubhouse",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9262",
    label: "Pax River Raiders Youth Football League Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9263",
    label: "Happiness Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9264",
    label: "Nancy Sherertz Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9265",
    label: "Mid-Atlantic Writing Centers Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9266",
    label: "Jesus House Elkridge Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9267",
    label: "Dream For Nations Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9268",
    label: "Wylie Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9269",
    label: "Parkville Carney Business Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9270",
    label: "Walk2Learn International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9271",
    label: "Catie Carnes Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9272",
    label: "Unchained Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9273",
    label: "Ministerio De Sanacion Fuente De Vida Abundante Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9274",
    label: "Association For The Public Defender Of Maryland Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9275",
    label: "Shine Alliance International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9276",
    label: "Beautiful You Mrkh Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9277",
    label: "Safe Haven Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9278",
    label: "Rwanda Prayer Banquet",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9279",
    label: "Rho Tau Lambda Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9280",
    label: "Fudan Alumni Foundation In Great Washington Dc Area",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9281",
    label: "Grace And Peace Fellowship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9282",
    label: "Collaborative Practice Community Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9283",
    label: "Chin Association Of Maryland Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9284",
    label: "Just Dream Foundation Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9285",
    label: "Farming 4 Hunger Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9286",
    label: "Healing Praise Full Gospel Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9287",
    label: "Better Health Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9288",
    label: "Modern Woodmen Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9289",
    label: "Pflag Chestertown",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9290",
    label: "Hbcu Career Development Marketplace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9291",
    label: "Iglesia De Dios Profetica Del Viviente Que Te Ve",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9292",
    label: "Emmanuel Trinity Lutheran Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9293",
    label: "Marinduquenos Of The Capital Area",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9294",
    label: "Rays Of Hope Outreach Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9295",
    label: "The Lilabean Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9296",
    label: "Ladies Of Vision Charities",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9297",
    label: "Wise Winning In Sports And Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9298",
    label: "Patriot Elite Wrestling Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9299",
    label: "Grassroots Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9300",
    label: "Franklin E Wimp Hamburg Memorial Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9301",
    label: "Sss3 Angel Of Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9302",
    label: "Family Recovery Program Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9303",
    label: "Kaski Samaj Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9304",
    label: "Harris Family Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9305",
    label: "Sigma Reach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9306",
    label: "Youre Choice Fellowship Center Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9307",
    label: "Artistic Synergy Of Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9308",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9309",
    label: "Association Of South Ethiopian Women Producers Exporters An",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9310",
    label: "Oliver E Pope Sr Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9311",
    label: "Marinduqueno Association Of The Capital Area Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9312",
    label: "Foliocollaborative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9313",
    label: "Mid-Atlantic Aronia Growers Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9314",
    label: "Open Hands",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9315",
    label: "Latter Rain Commandment Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9316",
    label: "Sidney M & Phyllis O Bresler Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9317",
    label: "Chesapeake Bay Association Of The Deaf",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9318",
    label: "Northwestern Chinese American Association Of Greater Washington",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9319",
    label: "Bestcare Home Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9320",
    label: "Immigrant Coummunity Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9321",
    label: "Macuda Dmv",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9322",
    label: "Cultural Synergy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9323",
    label: "Just Imagine",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9324",
    label: "Root 53 Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9325",
    label: "Hogs And Heroes Motorcycle Foundation Md Chapter 6",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9326",
    label: "Big Purple Barn Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9327",
    label: "Fruits Of The Spirit Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9328",
    label: "Sons Of Confederate Veterans Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9329",
    label: "Ravens Roost 75 Inc Magothy Beach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9330",
    label: "Pocomoke Indian Nation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9331",
    label: "Mid Atlantic Chapter Of Corenet Global Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9332",
    label: "Community In Action For Self Help Projects",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9333",
    label: "Patapsco High School Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9334",
    label: "Ncministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9335",
    label: "Jackmercy Limited Liability Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9336",
    label: "Nation Of Power Kingdom Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9337",
    label: "Friends Of The John Coltrane Memorial Concert Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9338",
    label: "Iglesia Bautista Cristo Vive",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9339",
    label: "Association For The Advancement Of African Women Economists",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9340",
    label: "Animal Allies Rescue Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9341",
    label: "Maryland Iaf Action",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9342",
    label: "Hhs Athletic Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9343",
    label: "Clarrion Call Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9344",
    label: "World Seabird Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9345",
    label: "Shristee Nrittyangon",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9346",
    label: "An Earthly Embrace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9347",
    label: "Dhs Pride Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9348",
    label: "Iglesia Apostoles Y Profetas Casa De Misericordia",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9349",
    label: "Deep Roots Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9350",
    label: "Council For Yoga Accreditation International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9351",
    label: "Engineer Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9352",
    label: "Camp Rock Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9353",
    label: "Saint John Paul Ii Formation Cooperative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9354",
    label: "Peace By Tourism Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9355",
    label: "Charm City Fringe",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9356",
    label: "By His Grace Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9357",
    label: "Kol Nefesh",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9358",
    label: "Bethesda Temple International Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9359",
    label: "Gedco Homes",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9360",
    label: "Veterans Of Foreign Wars Of The United States Auxiliary Dept Of Md",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9361",
    label: "St Marys Assocation Of Supervisors And Adminstrators",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9362",
    label: "Marc Lipella Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9363",
    label: "Southern Maryland Black Chamber Of Commerce",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9364",
    label: "Phi Sigma Sigma Iota Pi Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9365",
    label: "Seven Locks Elementary School Educational Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9366",
    label: "Maryland State Athletic Hall Of Fame Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9367",
    label: "Mid-Atlantic Chapter Of The Association For Contextual Behavio",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9368",
    label: "Lower Shore Resource Parent Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9369",
    label: "Kanada Biblical Missionary Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9370",
    label: "Mason-Dixon Lawnmower Racing Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9371",
    label: "Maryland State Geographic Information Committee Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9372",
    label: "Neighborhood Safety Team Baltimore County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9373",
    label: "Cultural Creation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9374",
    label: "Goodwill Mission And Job Creation Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9375",
    label: "The Learners Lab Foundation Tllf Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9376",
    label: "Starlite Performing Arts Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9377",
    label: "Help For Hungry & Homeless Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9378",
    label: "Natural Beauty Hair Designers Professional Training Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9379",
    label: "Iglesia De Cristo Misionera Maranatha Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9380",
    label: "Mountain Of Fire And Miracles Ministries-Rockville",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9381",
    label: "Analytica94",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9382",
    label: "Youth Art For Healing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9383",
    label: "Maryland Hands And Voices Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9384",
    label: "Tabithas Table Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9385",
    label: "Great Mills Aquatic Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9386",
    label: "Queen Annes Railroad Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9387",
    label: "Eya Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9388",
    label: "Loving Touch Nursing Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9389",
    label: "East Coast Azusa Womens Conference And Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9390",
    label: "This Generation Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9391",
    label: "Free State Roller Derby",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9392",
    label: "Brillo De Sol",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9393",
    label: "Superheroes For Kids Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9394",
    label: "Jesus Is Lord Outreach International Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9395",
    label: "In His Presence Ministry World Wide",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9396",
    label: "Fountain Comunity Enrichment Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9397",
    label:
      "North Elevation Ministries & Center For Youth Family And Community Dev",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9398",
    label: "Iglesia Evangelica Apostoles Y Profetas Tabernaculo De Oracion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9399",
    label: "Saving Our Children",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9400",
    label: "New Pathways To Enterprise",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9401",
    label: "Latin American Relief Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9402",
    label: "Murphy Family Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9403",
    label: "Burleigh Manor Animal Sanctuary And Eco-Retreat",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9404",
    label: "Iglesia Cristiana Renacer Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9405",
    label: "A Hat For Harold Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9406",
    label: "Society Of St Vincent De Paul St John Neumann",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9407",
    label: "Victoria House Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9408",
    label: "Fight 4 Diabetes Cure Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9409",
    label: "Coalition Halting Obesity In Children Everywhere Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9410",
    label: "Thurmont Senior Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9411",
    label: "Iron Crow Theatre Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9412",
    label: "Development Center For Pregnant Teenager",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9413",
    label: "Lenora Paschal Johnson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9414",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9415",
    label: "Ourcare Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9416",
    label: "Redeemed Life Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9417",
    label: "Queens Court Funds",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9418",
    label: "Morning Star Korean Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9419",
    label: "Woodside Mikvah Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9420",
    label: "Society Of St Vincent De Paul Baltimore Council Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9421",
    label: "Walkersville Historical Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9422",
    label: "Policy Forum Armenia Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9423",
    label: "Prayer And Praise Community Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9424",
    label: "Chuck Brown Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9425",
    label: "Kdb Tecnology Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9426",
    label: "Goodis Center For Research And Reform Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9427",
    label: "Lillians Resource Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9428",
    label: "Shadows Private Social Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9429",
    label: "Rapha House Wholistic Healing Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9430",
    label: "University Of Md Fire Protection Engineering Alumni Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9431",
    label: "Open Heavens Transformation Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9432",
    label: "Red & Tan Nation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9433",
    label: "Fsk Instrumental Music Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9434",
    label: "Trumpet Of God Deliverance Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9435",
    label: "Iglesia Pentecostal Promesa De Dios",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9436",
    label: "Edens Gate Ltd - Supporting Recovery From Disorder",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9437",
    label: "Spar Of Hope Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9438",
    label: "Pamela J S Bustard Memorial Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9439",
    label: "Road Runners Club Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9440",
    label: "Boxer Transfer Network Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9441",
    label: "Amateur Athletic Union Of The United States Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9442",
    label: "Journey Of Life Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9443",
    label: "Moed Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9444",
    label: "Bikemore Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9445",
    label: "Pikesville Garden Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9446",
    label: "Ocean Research Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9447",
    label: "St Francis Old Girls Association Of North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9448",
    label: "Delmarva Muslim Community Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9449",
    label: "Donnie Williams Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9450",
    label: "Souls In Harmony",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9451",
    label: "Wish List Depot Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9452",
    label: "Woolee Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9453",
    label: "Laborers Of The Harvest",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9454",
    label: "Bethel Stand For Life Outreach Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9455",
    label: "Papas Land Family Educational Advancement International Foundati",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9456",
    label: "Baobab Tree Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9457",
    label: "Sisters For Health",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9458",
    label: "Rock Of Hope Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9459",
    label: "Diasporic Africa Press",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9460",
    label: "Fgb Power Booster Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9461",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9462",
    label: "Brook Lane Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9463",
    label: "Dymonds Quality Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9464",
    label: "Aei Health And Education Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9465",
    label: "Institute For Jewish Continuity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9466",
    label: "Rising Sun Middle School Ptso Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9467",
    label: "Sustainafest Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9468",
    label: "Kleins Shoprite Of Maryland Charitable Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9469",
    label: "Maryland Combat Veterans Motorcycle Association Chapter 40-2 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9470",
    label: "God Family Friends Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9471",
    label: "Kids-4-K9S Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9472",
    label: "Medway Charitable Trust",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9473",
    label: "Heart Of The Earth Sanctuary And Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9474",
    label: "Association For Lifelong Learning",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9475",
    label: "Seven Loaves Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9476",
    label: "North America Tetra Forum",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9477",
    label: "Jesus Is Lord Crusades Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9478",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9479",
    label: "Blacks In Government",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9480",
    label: "Daniel Lynch Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9481",
    label: "Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9482",
    label: "Operation Backbone Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9483",
    label: "Boy Scouts Of America Troop 209",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9484",
    label: "Catholic Publishers Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9485",
    label: "Jewish Uniformed Service Association Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9486",
    label: "Blueprint",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9487",
    label: "Healthy Happy Kids Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9488",
    label: "Lovingkindness Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9489",
    label: "Progressive Steps",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9490",
    label: "Igl Misionera La Voz Del Espirito Santo A Las Naciones",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9491",
    label: "Kitzmiller Learning Cooperative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9492",
    label: "Bryan Michael Burnett Scholarship Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9493",
    label: "Generosity-Global Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9494",
    label: "Shaolin Traditional Kung Fu Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9495",
    label: "Childrens First Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9496",
    label: "Chill Station",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9497",
    label: "Annapolis Naval Sailing Association Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9498",
    label: "Women Of Today Working Opportunity Network Wotwon",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9499",
    label: "Global Cancer Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9500",
    label: "Phoenix Computers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9501",
    label: "Iglesia Evangelica Apostoles Y Profetas Ef 2 20",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9502",
    label: "Outreach Song And Dance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9503",
    label: "Victory In Christ International Christian Ministries Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9504",
    label: "Truth & Reconciliation Corner Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9505",
    label: "Greater Washington Aquatic Plants Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9506",
    label: "Transitions Dhg Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9507",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9508",
    label: "Eagles Outreach Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9509",
    label: "Igbani Awo Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9510",
    label: "Saint Mark African Orthodox Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9511",
    label: "Kinship Activity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9512",
    label: "My Fathers House Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9513",
    label: "Good Neighbor Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9514",
    label: "Acrel Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9515",
    label: "Bethesda International Church Of The Lord Jesus Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9516",
    label: "Phi Alpha Delta Law Fraternity International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9517",
    label: "Phi Alpha Delta Law Fraternity International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9518",
    label: "Rivendell Homes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9519",
    label: "Frederick County Se",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9520",
    label: "Kingdom Of God Worship Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9521",
    label: "Jewels School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9522",
    label: "Australian Stock Horse Society Usa Branch",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9523",
    label: "Dc Selects Hockey Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9524",
    label: "Pfw Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9525",
    label: "Ancient Order Of Hibernians In America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9526",
    label: "Center For The Rights Of Ethiopian Women Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9527",
    label: "The Samantha Kelly Memorial Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9528",
    label: "Harford County Sheriffs Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9529",
    label: "Rhema Word Evangelistic Christian Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9530",
    label: "Mustard Seed Rehabilitation Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9531",
    label: "New Birth By Faith For All People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9532",
    label: "Growingstems Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9533",
    label: "Chesapeake Tri-Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9534",
    label: "4227 Frederick Ave Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9535",
    label: "Maryum Islamic Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9536",
    label: "Sasaya Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9537",
    label: "Centro De Restauracion Familiar Betesda Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9538",
    label: "Tarbiyah Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9539",
    label: "Ave Maria Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9540",
    label: "Everybodys Quilt Guild Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9541",
    label: "Warrior Aviation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9542",
    label: "Mt Olive Church Cemetery Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9543",
    label: "Elshadai World Wide Satellite Tv Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9544",
    label: "Blue Moon Rising Center For Sustainable Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9545",
    label: "Manna Prayer Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9546",
    label: "Casa Of Western Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9547",
    label: "Empowering Women In Need",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9548",
    label: "My Fathers Heart",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9549",
    label: "Baisanons Of The World",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9550",
    label: "Clay Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9551",
    label: "My Life Learning Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9552",
    label: "Bethlehem Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9553",
    label: "Third Practice Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9554",
    label: "Encouragement For The Soul Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9555",
    label: "Jah Bless Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9556",
    label: "Sharanya Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9557",
    label: "From Wounded To Worship Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9558",
    label: "4 The Healing In You",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9559",
    label: "Independent Gospel Artists Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9560",
    label: "Concerned Muslims Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9561",
    label: "Bahouan Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9562",
    label: "Maryland Association Of Public Library Administrators Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9563",
    label: "Iglesia Cristiana Pacto De Vida Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9564",
    label: "Keys To Success Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9565",
    label: "Silver Spring Village Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9566",
    label: "Medstar Southern Maryland Hospital Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9567",
    label: "Iglesia Casa De Dios Y Puerta Del Cielo Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9568",
    label: "Backpack Buddies Of Anne Arundel County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9569",
    label: "Jewish Academy School Of Fine Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9570",
    label: "Iglesia Misionera Cristo Es La Roca Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9571",
    label: "Theta Chi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9572",
    label: "Project Mobile Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9573",
    label: "Deaf History International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9574",
    label: "Baltimore Underground Science Space",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9575",
    label: "Amer-Indo Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9576",
    label: "Roses And Rainbows Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9577",
    label: "Times12 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9578",
    label: "Iglesia Conexiones",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9579",
    label: "Homeward Bound Cat Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9580",
    label: "New Orchestra Of Washington Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9581",
    label: "Lisas Ark Critter Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9582",
    label: "Breakfree Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9583",
    label: "Tagore Music Group Of Greater Washington Dc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9584",
    label: "Technology Growth Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9585",
    label: "Mission To Heal",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9586",
    label: "Runnymede Elementary School Pto Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9587",
    label: "Community Cats Coalition",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9588",
    label: "Empowering Word Kingdom Ministries International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9589",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9590",
    label: "Clmwww National Executive Committee",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9591",
    label: "Chesapeake Energy Conservation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9592",
    label: "Fishes & Loaves Pantry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9593",
    label: "Brightstar Youth Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9594",
    label: "Christ Embassy Church Of Bowie Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9595",
    label: "Kisseman Childrens Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9596",
    label: "Abada-Capoeira Dc Brazilian Arts Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9597",
    label: "Iglesia Evangelica Apostoles Y Profetas Monte De Sion Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9598",
    label: "Ascension Catholic Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9599",
    label: "St Kitts Nevis Nationals Association - Dc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9600",
    label: "City Of Prayer",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9601",
    label: "International Association Of Lions Clubs",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9602",
    label: "The Well Community Church Silver",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9603",
    label: "New Day Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9604",
    label: "Undescriptively Blessed Missionary Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9605",
    label: "Gunpowder United Mountain Bike Operators Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9606",
    label: "Wrinkle Erase For The Cause Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9607",
    label: "Reisterstown Bible International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9608",
    label: "Consolation Evangelistic Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9609",
    label: "Charles County Youth Orchestra Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9610",
    label: "Ochs Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9611",
    label: "El-Shaddai Prayer And Deliverance Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9612",
    label: "Bogoso Area Township Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9613",
    label: "Ted Rullo Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9614",
    label: "Mitchell Akers Memorial Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9615",
    label: "James Place Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9616",
    label: "Buffalo Soldiers Aberdeen Maryland Motorcycle Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9617",
    label: "Citadel Of Prayer International Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9618",
    label: "Miracle 4 Melanie",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9619",
    label: "Elev8 Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9620",
    label: "Bruce Outreach Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9621",
    label: "Chesapeake Multicultural Resource Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9622",
    label: "Maryland Tamil Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9623",
    label: "Maryland Christ Restoration Ministries International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9624",
    label: "Jesus Christ Is Lord Prayer Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9625",
    label: "Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9626",
    label: "Calvert Amateur Radio Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9627",
    label: "Start School Later Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9628",
    label: "Grand Commandery Knights Templar Of The District Of Columbia",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9629",
    label: "Scl Dynamics Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9630",
    label: "Maryland Token And Metal Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9631",
    label: "For His Glory World Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9632",
    label: "Community Center For Professional Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9633",
    label: "Washingtons Spelmanslag",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9634",
    label: "Youth Writers Challenge Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9635",
    label: "Peter J Spann Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9636",
    label: "Cycle For Unity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9637",
    label: "Chabad Of Germantown",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9638",
    label: "Food Tank The Think Tank For Food",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9639",
    label: "Warnock Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9640",
    label: "Jayne Koskinas Ted Giovanis Foundation For Health And Policy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9641",
    label: "Kingdom Kare",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9642",
    label: "Sollay Kenyan Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9643",
    label: "Foxie G Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9644",
    label: "Mision Evangelica Plantios De Jehova",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9645",
    label: "Hfc Salisbury",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9646",
    label: "Gaithersburg Rotary Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9647",
    label: "Charles Shelton Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9648",
    label: "Feed A Healthy Mind Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9649",
    label: "Ppf Benevolent Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9650",
    label: "Mosaic Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9651",
    label: "Connor Rice Memorial Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9652",
    label: "Renewed Hope Life Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9653",
    label: "Iglesia Asambleas Principe De Paz Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9654",
    label: "Csd Hot Feet Company Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9655",
    label: "A Dollar A Tree For Haiti Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9656",
    label: "Centreville Holding Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9657",
    label: "Benevolent And Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9658",
    label: "Sisters Of Esteem Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9659",
    label: "Camp Ma-He-Tu Endowment Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9660",
    label: "Teebo Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9661",
    label: "Child Steps International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9662",
    label: "Apartment Angel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9663",
    label: "Marine Corps League",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9664",
    label: "Damascus House Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9665",
    label: "Baltimore Moos",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9666",
    label: "Disciples Promise Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9667",
    label: "Noor Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9668",
    label: "Center For Secure Nuclear Future Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9669",
    label: "Bernadettes House",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9670",
    label: "Combined Communities Advocacy Council Of Greater Randallstown In",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9671",
    label: "Friends Of Naval Academy Music",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9672",
    label: "Magfest Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9673",
    label: "Reignworks Foundation Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9674",
    label: "Invest In Children Of The World-Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9675",
    label: "St Philip Neri School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9676",
    label: "St Augustine School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9677",
    label: "Tabitha Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9678",
    label: "Hogs And Heroes Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9679",
    label: "Emerging Health Initiatives Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9680",
    label: "Chem-Bio Booster Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9681",
    label: "Christian Church In Clarksburg",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9682",
    label: "Chase Brexton Realty Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9683",
    label: "American Federation Of State County & Municipal Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9684",
    label: "The Belize Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9685",
    label: "The Friends Of The Leonardtown Theater Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9686",
    label: "National Entrepreneur Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9687",
    label: "Rmhs Choral Booster Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9688",
    label: "Mirae Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9689",
    label: "Iglesia Apostolica De Baltimore El Manantial Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9690",
    label: "Mid-Maryland Woodturneres",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9691",
    label: "Development Of Village Education India Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9692",
    label: "Baltimore Arts Realty Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9693",
    label: "Charm Cityl Labor Chorus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9694",
    label: "Mundy Buddy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9695",
    label: "Kenny Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9696",
    label: "Ronald E Mcnair Lodge 146 Community Outreach Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9697",
    label: "Wingz Of Lyfe",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9698",
    label: "Mcpa Community Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9699",
    label: "Caregiver Sos",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9700",
    label: "Refreshing Springs Baptist Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9701",
    label: "Rhema Christ Kingdom International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9702",
    label: "Indonesian Kids Performing Arts",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9703",
    label: "Royal-Seed Church International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9704",
    label: "Apostolic Faith Fellowship International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9705",
    label: "Man-Up Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9706",
    label: "Purbachal Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9707",
    label: "Cameroon Deaf Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9708",
    label: "Mustard Seed Chinese Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9709",
    label: "Baltimore Regional Housing Partnership Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9710",
    label: "Krystal Medical Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9711",
    label: "Helping Children Succeed Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9712",
    label: "Optimal Wellness Research Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9713",
    label: "Toys For Children With Cancer Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9714",
    label: "Transplant Recipients International Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9715",
    label: "Humanitys Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9716",
    label: "Deliverance Headquarters For All People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9717",
    label: "The Sacred Monastery Of Saint Nina Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9718",
    label: "Olive Tree Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9719",
    label: "Celebrate8",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9720",
    label: "Society Of Saint Vincent De Paul - Saint Andrew By The Bay",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9721",
    label: "Masjid Ad Dawah Ilat Tawheed",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9722",
    label: "Asia Pacific Career Development Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9723",
    label: "Scientific Association Of Forensic Examiners Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9724",
    label: "Cantonese School Of Greater Washington",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9725",
    label: "Nguru Umuaro Development Union A Nj Nonprofit Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9726",
    label: "Alliance Health Education Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9727",
    label: "Amici Lovaniensis Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9728",
    label: "North East Action Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9729",
    label: "Ati Annang Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9730",
    label: "Maryland Citizens United For The Rehabilitation Of Errants Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9731",
    label: "Brooklyn Park Seniors Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9732",
    label: "Woman At The Well World-Wide Outreach And Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9733",
    label: "Inspiration Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9734",
    label: "Kappa Alpha Psi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9735",
    label: "Friends Of Carroll Creek Urban Park Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9736",
    label: "Divine Connection Praise Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9737",
    label: "Blacks In Government",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9738",
    label: "Totalhealth Kids Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9739",
    label: "Gamma Chi Chapter Foundation For Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9740",
    label: "Playgroup In The Park Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9741",
    label: "Nkumu Fed Fed Washington Dc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9742",
    label: "Iglesia Auditorio Fuente De Vida Eterna",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9743",
    label: "Girls On The Run Of Mid And Western Md",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9744",
    label: "University Bible Fellowship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9745",
    label: "Saving Lives Adding Purpose Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9746",
    label: "Quan Am Buddhist Temple Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9747",
    label: "Helen J Serini Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9748",
    label: "Radio Airwaves Productions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9749",
    label: "Union Of Chinese American Professional Organizations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9750",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9751",
    label: "Greater Immanuel Faith Temple",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9752",
    label: "St Marys Youth Cultural Exchange",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9753",
    label: "Mid-Atlantic Pyrotechnic Arts Guild Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9754",
    label: "Touching Young Lives",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9755",
    label: "National Organization Of Chamorro Veterans In America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9756",
    label: "Social Performance Task Force",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9757",
    label: "Amateur Athletic Unions Of The Us Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9758",
    label: "Parlor Room Theater Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9759",
    label: "Percy Lee Dairy Farm Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9760",
    label: "Nahum Heritage Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9761",
    label: "India Community Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9762",
    label: "Milestone Medical Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9763",
    label: "Glorious Time Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9764",
    label: "Todd And Karen Ruppert Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9765",
    label: "Unity Fund Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9766",
    label: "Washington County Housing Solutions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9767",
    label: "Shoe Girl Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9768",
    label: "A M Digiulian Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9769",
    label: "Anothen Dm Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9770",
    label: "Uncomaa North America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9771",
    label: "Jodah Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9772",
    label: "June Brandy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9773",
    label: "Wheels For Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9774",
    label: "Jane Krieger Schapiro Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9775",
    label: "Angels In Disguise",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9776",
    label: "Family Ministry Institution Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9777",
    label: "Suri Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9778",
    label: "Seeds Of Healing Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9779",
    label: "Top Ladies Of Distinction Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9780",
    label: "Cecil County Rotary Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9781",
    label: "The Revived Apostolic Mission",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9782",
    label: "Demand Progress Action Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9783",
    label: "Busy Bees Art Studio Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9784",
    label: "Intl Assn Of Machinists & Aerospace Workers Local Lodge I-460",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9785",
    label: "Global Good Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9786",
    label: "Ashreinu School For Girls Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9787",
    label: "Cyber-Green Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9788",
    label: "Capitol Frigate Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9789",
    label: "Passano Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9790",
    label: "Baltimore Area Ntrak Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9791",
    label: "Edgewood Community Support Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9792",
    label: "Columbia Downtown Housing Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9793",
    label: "Bowie City Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9794",
    label: "Christian Church In Rockville",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9795",
    label: "Primera Iglesia Bautista Hispana Emanuel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9796",
    label: "Nicholas H Baker Fund For Special Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9797",
    label: "Maryland Chinese Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9798",
    label: "Chesapeake Camp And Conference Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9799",
    label: "Oyate Wookiye-For The People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9800",
    label: "Bmore Artists Guild Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9801",
    label: "Stablein Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9802",
    label: "The Nathan Chris Baker Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9803",
    label: "Mcfa Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9804",
    label: "Always Abiding In God Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9805",
    label: "Golden Key Opportunity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9806",
    label: "Somdapa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9807",
    label: "Pickett Fences Senior Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9808",
    label: "Churchone Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9809",
    label: "Heartwood Outdoors",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9810",
    label: "Bangladesh Christian Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9811",
    label: "Friends Of Delmarva Public Radio Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9812",
    label: "Emissaries Of Mary Of Manaoag",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9813",
    label: "Adventist Healthcare Urgent Care Centers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9814",
    label: "Siamak Pourzand Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9815",
    label: "Iranian-American Academics And Professionals Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9816",
    label: "Team Trace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9817",
    label: "Cumberland Heat Fastpitch Softball Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9818",
    label: "Maryland Emergency Response Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9819",
    label: "Ohr Hatorah",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9820",
    label: "Golf At The Highest Level Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9821",
    label: "Iglesia Bautista Biblica Getsemani Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9822",
    label: "Imwtl Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9823",
    label: "Lords Church Of Frederick",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9824",
    label: "Bahari Sisters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9825",
    label: "Jerry & Brenda Rush Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9826",
    label: "Wiki Project Med Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9827",
    label: "Asian American Civic Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9828",
    label: "Pushing Pink Elephants",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9829",
    label: "F R E E Usa Friendship Respect Enrichment Engagement",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9830",
    label: "Saving Grace Animal Rescue Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9831",
    label: "Yeshivas Tiferes Shlomo Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9832",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9833",
    label: "Baby Sisters Song Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9834",
    label: "Sweet Mercy Church Of Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9835",
    label: "South Creek Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9836",
    label: "Frank E & Miriam Loveman Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9837",
    label: "Destiny Changers Deliverance Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9838",
    label: "Society For Neuroscience",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9839",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9840",
    label: "Gorgora Renaissance And Development Association Grda",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9841",
    label: "Brown Girl Wellness Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9842",
    label: "2Bridge Cdx Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9843",
    label: "With God Ministries International Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9844",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9845",
    label: "Nathans Story",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9846",
    label: "Faith Academy Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9847",
    label: "The Frederick Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9848",
    label: "Redeemed Christian Church Of God Resurrection And Life",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9849",
    label: "Wounded Warrior Housing Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9850",
    label: "Evelyn Johnson Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9851",
    label: "Bags Of Love Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9852",
    label: "Redemption House Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9853",
    label: "Trotter Crossing Homeowners Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9854",
    label: "Simply Social Women With A Vision Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9855",
    label: "Tauber-Manovill Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9856",
    label: "Pay It Forward Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9857",
    label: "Foundry Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9858",
    label: "New Creation Church Anglican Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9859",
    label: "Lacc Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9860",
    label: "Presencia De Dios",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9861",
    label: "Little Falls Village",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9862",
    label: "American Samueltheology College And University",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9863",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9864",
    label: "Enduring Joy Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9865",
    label: "4 Horsemen Charities Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9866",
    label: "Unity Thunder Car Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9867",
    label: "It Is Well International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9868",
    label: "Independence Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9869",
    label: "Upcounty Community Resources Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9870",
    label: "Primera Iglesia Adventista Eben-Ezer",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9871",
    label: "Becoming American Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9872",
    label: "John Wesley Historical Trust Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9873",
    label: "Eleven 21 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9874",
    label: "Zhcet-Na Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9875",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9876",
    label: "Rising Sun Base Ball Club Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9877",
    label: "National Spay Neuter Now Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9878",
    label: "Rosaria Communities In Olney Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9879",
    label: "Wlw Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9880",
    label: "Wildlife Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9881",
    label: "Friends Of Liberia Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9882",
    label: "Howard County Concert Odyssey Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9883",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9884",
    label: "Love Chapel International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9885",
    label: "Whole Heart Grief & Loss Resource Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9886",
    label: "Horizons Of Kent And Queen Annes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9887",
    label: "Iglesia Pentecostal Palabra En Accion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9888",
    label: "Marylanders To Prevent Gun Violence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9889",
    label: "David Wagga Hunt Scholarship Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9890",
    label: "Maryland Sting B&G Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9891",
    label: "American Cricket Federation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9892",
    label: "Ravens Roost 129 Of Lake Shore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9893",
    label: "Forty West Lion Club Charity Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9894",
    label: "Venus Chinese School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9895",
    label: "Bulldog Athletic Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9896",
    label: "Mid Atlantic Climbers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9897",
    label: "Dees House Of Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9898",
    label: "Chabad-Lubavitch Of Marland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9899",
    label: "Essex-Stembridge Recreation & Parks Council Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9900",
    label: "Room To Rebloom",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9901",
    label: "Iglesia Rio De Dios River Of God Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9902",
    label: "Baltimore Worship Center - The Church Of The Living God Internati",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9903",
    label: "The Church On The Boulevard Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9904",
    label: "Bas Melech Performing Arts Program Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9905",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9906",
    label: "Centro Familiar Cristiano",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9907",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9908",
    label: "Amateur Athletic Union Of The United States",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9909",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9910",
    label: "I C You Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9911",
    label: "Soar Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9912",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9913",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9914",
    label: "Wisnosky Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9915",
    label: "Heroes Haven Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9916",
    label: "Faith Covenant Ministries International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9917",
    label: "Tabithas Compassion International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9918",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9919",
    label: "La Dolce Vita Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9920",
    label: "Natalie Williams Breast Care Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9921",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9922",
    label: "Whitman Lacrosse Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9923",
    label: "Society Of St Vincent De Paul At St Bernard",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9924",
    label: "Pharez In Christ Evangelical Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9925",
    label: "Mason-Dixon Roller Vixens Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9926",
    label: "Rendezvous Haiti",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9927",
    label: "Louis Max Israel Sr Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9928",
    label: "Gods Power Chapel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9929",
    label: "Bennett College Alumnae Club Of Metropolitan Washington Dc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9930",
    label: "Smithsburg Junior Leopards",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9931",
    label: "Project Eden Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9932",
    label: "Scholar Athlete Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9933",
    label: "American Youth Football Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9934",
    label: "Fbc Alumni Na Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9935",
    label: "Brendan James Huber Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9936",
    label: "Missionary At Heart",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9937",
    label: "Wayward Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9938",
    label: "Laughing Wolfe Resources",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9939",
    label: "There I Stood - Haiti Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9940",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9941",
    label: "Friends Of The Brunswick Library",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9942",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9943",
    label: "Tabernacle Christian Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9944",
    label: "Maryland City Communities Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9945",
    label: "Hannahs Heroes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9946",
    label: "Spirit And Truth Restoration Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9947",
    label: "Washington Metropolitan Gamer Symphony Orchestra",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9948",
    label: "Alliance For Innovation In Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9949",
    label: "Csl Central Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9950",
    label: "In-House Benefits Counsel Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9951",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9952",
    label: "Inner City Church Of Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9953",
    label: "Omnimuseum Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9954",
    label: "Beth Tfiloh Dahan Community School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9955",
    label: "Christian Missions Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9956",
    label: "North Silver Spring Community Dental And Health Clinic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9957",
    label: "Learning Communities Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9958",
    label: "Drama Lady Theatre Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9959",
    label: "Avaris Institute For Human Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9960",
    label: "Beats For A Better Tomorrow Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9961",
    label: "Life Fountain Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9962",
    label: "Veterans Education Success Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9963",
    label: "National Association Of Mothers And Daughters United Worldwide",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9964",
    label: "Enon Community Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9965",
    label: "Hub City Ravens Roost Number 124 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-9966", label: "Wanpae", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-9967",
    label: "Ballet Institute For The Arts",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9968",
    label: "Starboard Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9969",
    label: "Arms Of Hope Family Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9970",
    label: "All Fur Paws Animal Sanctuary And Adoption Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9971",
    label: "Watermelon Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9972",
    label: "Succor Transitional Program",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9973",
    label: "Umsj Health System Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9974",
    label: "David D Smith Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9975",
    label: "Kingdom Shakers International Deliverance Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9976",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9977",
    label: "Columbia Pedal & Paddle Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9978",
    label: "Sigma Phi Gamma International Sorority Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9979",
    label: "Friends Of Maryland Community Band",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9980",
    label: "Polish Drama Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9981",
    label: "Changing Point Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9982",
    label: "Glorious Expression Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9983",
    label: "Ministerio Internacional Lirios Del Valle Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9984",
    label: "Greater Washington Association Of Physicians Of Indian Origin",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9985",
    label: "Keep Punching",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9986",
    label: "Iglesia Evangelica Apostoles Y Profetas Efesio 220 Fuente De Vida",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9987",
    label: "Changing Destinations Journey To Excellence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9988",
    label: "Networks Of Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9989",
    label: "International New Life Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9990",
    label: "Laps For Lexi",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9991",
    label: "Jesus For All Christian Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9992",
    label: "Miracel Church Of Healing And Deliverance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9993",
    label: "Voices In Pink Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9994",
    label: "World Folk Music Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9995",
    label: "Nepal Sports & Culture International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9996",
    label: "Higher Ground Worldwide Evangelism Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9997",
    label: "Firstfruits Prayer Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9998",
    label: "Pyo Parent Resource Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-9999",
    label: "The Official Piscataway Conoy Tribe Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10000",
    label: "Church Of Christ In Baltimore County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10001",
    label: "Iglesia El Sendero De La Cruz De Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10002",
    label: "Fallston Country Prek Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10003",
    label: "Somerset County Long Term Recovery Committee Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10004",
    label: "Edge Of The Universe Players 2 Incorportated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10005",
    label: "Honduras Compassion Partners Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10006",
    label: "Glory Glow International Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10007",
    label: "Christ Child Society Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10008",
    label: "L E O S A Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10009",
    label: "Society Of St Vincent Depaul Baltimore Council Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10010",
    label: "Gods Best Family Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10011",
    label: "Music In Me Foundation International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10012",
    label: "Ronny Maher Memorial",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10013",
    label: "American Citizens Abroad Global Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10014",
    label: "Creative Opportunities Unlimited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10015",
    label: "Ascovime",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10016",
    label: "Southern Maryland Equine Council",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10017",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10018",
    label: "Code In The Schools Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10019",
    label: "Rapid Lemon Productions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10020",
    label: "Finding Your Purpose",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10021",
    label: "Haitian S Harmony Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10022",
    label: "Run For The Stripes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10023",
    label: "Dentmed Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10024",
    label: "Blossoms House Of Restoration",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10025",
    label: "Maryland State Guard Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10026",
    label: "Patricia White Ministries International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10027",
    label: "Gasaways Children Enrichment Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10028",
    label: "Friends Of Cabin John Creek Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10029",
    label: "Southern Maryland Shellfish Growers Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-10030", label: "Flame", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-10031",
    label: "Tuscarora High School Instrumental Music Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10032",
    label: "Reach Pg",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10033",
    label: "Maryland Association Of Dermatology Physicians Assistants Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10034",
    label: "Celebrate Hope Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10035",
    label: "Fish Feel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10036",
    label: "Society Of One Americas Defenders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10037",
    label: "Lifemark Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10038",
    label: "Honors Class Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10039",
    label: "Friends Of Herring Run Parks Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10040",
    label: "Council On Dairy Cattle Breeding",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10041",
    label: "Susquehanna Valley Scottish Rite Of Freemasonry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10042",
    label: "Girls Charter School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10043",
    label: "International Transformation Institute For Humanity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10044",
    label: "In Christ Washington Fellowship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10045",
    label: "Polished Arrow Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10046",
    label: "Masjid Mustafa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10047",
    label: "All The Kings Children Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10048",
    label: "Consumer Advocates Of Pjm States Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10049",
    label: "Charm City Referee Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10050",
    label: "Open Door Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10051",
    label: "Hampstead Lions Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10052",
    label: "Icarus Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10053",
    label: "Squirrel Wyde Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10054",
    label: "Hollywood Farmers Market",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10055",
    label: "Tip Me Frederick Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10056",
    label: "United Soldiers And Sailors Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10057",
    label: "Mount Airy - Parrs Ridge Elementary Schools Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10058",
    label: "All About The Youth",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10059",
    label: "Renovate Life Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10060",
    label: "Iskcon Deity Worship Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10061",
    label: "Sensible Women Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10062",
    label: "Amateur Athletic Union Of The United States",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10063",
    label: "Maryland Off-Highway Vehicle Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10064",
    label: "United Capital Blades Speedskating Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10065",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10066",
    label: "Modern Quilt Guild Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10067",
    label: "Angels Touch International Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10068",
    label: "Hunt Valley Symphony",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10069",
    label: "Living God Christian Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10070",
    label: "Judson Education Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10071",
    label: "World Evangelism Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10072",
    label: "Phi Eta Sigma National Honor Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10073",
    label: "Victory Chapel Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10074",
    label: "Carol Highlands Neighborhood Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10075",
    label: "Church Without Walls Ministry Of Charles County Md",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10076",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10077",
    label: "Wrestling To Beat The Streets Dc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10078",
    label: "Senior Psychiatrists Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10079",
    label: "His Heart Passion Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10080",
    label: "Aahrg Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10081",
    label: "Pleasant Housing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10082",
    label: "Acts 4 29 30 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10083",
    label: "Cave Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10084",
    label: "Band Together Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10085",
    label: "Bennett Institute Booster Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10086",
    label: "Montford Point Marine Association Maryland Chapter 17",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10087",
    label: "Korean Hope Church Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10088",
    label: "Child Justice Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10089",
    label: "Sistas Thrilled About Reading Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10090",
    label: "Reaching Back Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10091",
    label: "Voice Of Malawi Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10092",
    label: "Havre De Grace Green Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10093",
    label: "Purpose For Life Outreach Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10094",
    label: "Chabad Of Towson Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10095",
    label: "Fells Point Residents Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10096",
    label: "Samfsn Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10097",
    label: "Bromo Tower Arts & Entertainment Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10098",
    label: "Springdale Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10099",
    label: "Royal Impact Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10100",
    label: "Kinera Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10101",
    label: "Henrietta Lacks Legacy Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10102",
    label: "Ncr Dolphins",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10103",
    label: "Fraternite Ephphata Of The Washington Metropolitan Area",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10104",
    label: "Joyful House Church Defenders Of The Christian Faith Movement",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10105",
    label: "Nam Knights Of America Fort Mchenry Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10106",
    label: "Aligarh Muslim University Alumni Association Washington Dc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10107",
    label: "Freewill Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10108",
    label: "Pocomoke Boat Docking Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10109",
    label: "Iglesia Profetica La Vina Escogida Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10110",
    label: "Ever Creative Word Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10111",
    label: "Crossroads Adaptive Athletic Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10112",
    label: "Jt Cares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10113",
    label: "Pal Ping Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10114",
    label: "I Am Assistance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10115",
    label: "Carolyn E Parker Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10116",
    label: "Bravely Beautiful Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10117",
    label: "Thao Nguyen Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10118",
    label: "Sssbc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10119",
    label: "Sewa Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10120",
    label: "Great Young Society Ii",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10121",
    label: "Community Educational Research Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10122",
    label: "All Sons & Daughters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10123",
    label: "Floodgates Ministries International House Of Mercy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10124",
    label: "Mary Elizabeths House Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10125",
    label: "Jagannath Seva Sanstha Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10126",
    label: "Greenprint Aid Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10127",
    label: "Ghadef Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10128",
    label: "Natural Philosophy Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10129",
    label: "Saint Peters Igbo Anglican Church Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10130",
    label: "Mt Moriah New Life Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10131",
    label: "Bicycle Advocates For Annapolis & Anne Arundel County Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10132",
    label: "Ian Berry Memorial Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10133",
    label: "Foundation For A Better Tomorrow Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10134",
    label: "Excellence Christian School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10135",
    label: "Dmv Generals",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10136",
    label: "United Association Labor Management Cooperation Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10137",
    label: "Impact Baltimore Edc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10138",
    label: "Washington Korean Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10139",
    label: "Salvation Ministries Usa Canada Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10140",
    label: "Friendsville Days Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10141",
    label: "Linthicum Decorating Committee Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10142",
    label: "Catenas Kitchen House",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10143",
    label: "Taylor Morse & Brown",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10144",
    label: "Health Solutions Research Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10145",
    label: "House Of Divine Guidance Incorporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10146",
    label: "Cultura Plenera Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10147",
    label: "Future Farmers Of America State Association & Local Chapters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10148",
    label: "Jazzway Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10149",
    label: "We Still Do Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10150",
    label: "Ares Human Rights International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10151",
    label: "Climate First Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10152",
    label: "Ascent",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10153",
    label: "Invested Enrichment Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10154",
    label: "Ana A Brito Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10155",
    label: "Cardinal Soccer Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10156",
    label: "The Maryland Book Bank Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10157",
    label: "Jerusalem Church Of The Lord Int",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10158",
    label: "Kin-Nect",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10159",
    label: "Mary Mac Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10160",
    label: "Katelyns Krusade Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10161",
    label: "Worksource Montgomery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10162",
    label: "Howard County Extension Advisory Council",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10163",
    label: "Better Family",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10164",
    label: "National Union For Democracy In Iran Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10165",
    label: "Peiying Chinese School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10166",
    label: "Mission 01 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10167",
    label: "Haitibrigade Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10168",
    label: "Inner Arbor Trust Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10169",
    label: "Iglesia Misionera Peniel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10170",
    label: "Marylanders To Prevent Gun Violence Education Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10171",
    label: "William F And Barbara K Peel Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10172",
    label: "Iglesia Alfa Y Omega Principio Y Fin Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10173",
    label: "Monterey Institute Of Natural Medicine",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10174",
    label: "Big Blue Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10175",
    label: "Krma-U S Partners Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10176",
    label: "Navy Operational Support Center Baltimore Chief Petty Officer",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10177",
    label: "Holy Hill Chapel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10178",
    label: "U Empower Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10179",
    label: "Maryland Classical Dance Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10180",
    label: "Gumdrop Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10181",
    label: "Cheryl Lynn Waters-Hassan",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10182",
    label: "Erika&Eric Home Of Care Ii Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10183",
    label: "Madis Place Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10184",
    label: "Udoyon Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10185",
    label: "Zion Freedom Fellowship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10186",
    label: "Women Of God Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10187",
    label:
      "Business Womens Network Of Howard County Scholarship Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10188",
    label: "Transforming The Community Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10189",
    label: "Makel Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10190",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10191",
    label: "Thoughts Of A Working Man",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10192",
    label: "Lets Play America Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10193",
    label: "Whiting-Turner Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10194",
    label: "Dair Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10195",
    label: "Gods Glorious Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10196",
    label: "Mid-Shore Restoring Hope In Women",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10197",
    label: "Off The Quill Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10198",
    label: "Aci International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10199",
    label: "1012 Sports Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10200",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10201",
    label: "Field Artillery Association Apg Chapter Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10202",
    label: "Sarahs House Of Refuge Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10203",
    label: "Frank K And Jacqueline W Harney Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10204",
    label: "Ganga Library Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10205",
    label: "Hope Esperanza Restoration And Empowerment Center Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10206",
    label: "Brendan Looney Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10207",
    label: "Victory Lakeside Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10208",
    label: "South Washington County Food Pantry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10209",
    label: "Yvonnes Heart Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10210",
    label: "Remnant Of God Deliverance Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10211",
    label: "Ministerio Pentecostal Impacto De Dios Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10212",
    label: "Musical Autist",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10213",
    label: "Community Emergency Response Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10214",
    label: "Mill Creek Village Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10215",
    label: "Miracle Mile Holding Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10216",
    label: "Nick Liberatore Memorial Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10217",
    label: "Sister Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10218",
    label: "Happy Hubz Preventive Veterinary Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10219",
    label: "Sierra Leone Nurses Association North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10220",
    label: "Ancient Order Of Hibernians In America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10221",
    label: "Through The Heart",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10222",
    label: "Tree House Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10223",
    label: "Leonardtown Business Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10224",
    label: "Denney House Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10225",
    label: "American Bhutanese Associates For Health Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10226",
    label: "Il E A D Success Tutoring And Mentoring Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10227",
    label: "Stepping Into Purpose Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10228",
    label: "Schwemm Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10229",
    label: "Judaic Heritage Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10230",
    label: "Olney Civic Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10231",
    label: "Winners Chapel International Americas Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10232",
    label: "Civitan International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10233",
    label: "Society Of Italian American Businessmen Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10234",
    label: "Fairytale Brides On A Shoestring Boutique Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10235",
    label: "Noahs Arc Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10236",
    label: "Fortune Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10237",
    label: "College Park Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10238",
    label: "Sophie And Madigans Playground Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10239",
    label: "Ubakala Development Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10240",
    label: "Anath Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10241",
    label: "Potomac Valley Radio Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10242",
    label: "Fraternal Order Of Police",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10243",
    label: "Qah Legacy Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10244",
    label: "Mountain Of Fire And Miracles Ministries-Laurel Md",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10245",
    label: "Christopher Grace Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10246",
    label: "Maryland Geothermal Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10247",
    label: "Adventist New Vision Fellowship Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10248",
    label: "77Rescue Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10249",
    label: "Building Families For Children",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10250",
    label: "Bayside Hoyas",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10251",
    label: "Stephen Decatur High School Athletic Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10252",
    label: "S & D K9 Rehabilitation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10253",
    label: "All About Caring Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10254",
    label: "Pitties & Purrs Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10255",
    label: "Military Family Relief Fund Mid Atlantic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10256",
    label: "Center For Social Awareness Advocacy And Ethics",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10257",
    label: "Green Branch Management Group Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10258",
    label: "Ahavat Shalom Congregation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10259",
    label: "Marcie & Ellen Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10260",
    label: "Alpha Genesis Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10261",
    label: "Companion Bridge Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10262",
    label: "Comforters House Chapel International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10263",
    label: "Interact Story Theatre Education Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10264",
    label: "Resiliance Human Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10265",
    label: "West Charles Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10266",
    label: "Midnight Hour Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10267",
    label: "Reach One Touch One Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10268",
    label: "Little Paws Dachshund Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10269",
    label: "Veterans Health And Wellness Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10270",
    label: "Fellowship Christian School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10271",
    label: "Maryland Cpcu Society Chapter Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10272",
    label: "Run-Hope-Work",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10273",
    label: "Center For Technology Access And Training",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10274",
    label: "John W Brick Mental Health Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10275",
    label: "Harvest Building Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10276",
    label: "Iglesia Biblica Bautista Peniel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10277",
    label: "Reverend Dr Eric W Gritsch Memorial Fund Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10278",
    label: "Modern Quilt Guild Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10279",
    label: "Howard Evangelical Presbyterian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10280",
    label: "Kollel D Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10281",
    label: "Jafai Support Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10282",
    label: "Imara Roose Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10283",
    label: "Center For International Maritime Security Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10284",
    label: "Pikesville Jewish Congregation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10285",
    label: "Family Law Community Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10286",
    label: "Rebirth Tabernacle Of Glory Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10287",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10288",
    label: "Take The Stage Performance Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10289",
    label: "Cove Mountain Educational Broadcasting Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10290",
    label: "Baltimore Jewelry Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10291",
    label: "Savior For Life International Missions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10292",
    label: "Fireworks Educational Programs",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10293",
    label: "Southern Maryland Civil War Round Table",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10294",
    label: "Fountain Of Life Transitional Supportive Housing",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10295",
    label: "Bet Chaverim Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10296",
    label: "Startup Shell Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10297",
    label: "Brothers In Action",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10298",
    label: "Carol Schwartz Greenwald Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10299",
    label: "Kingdom Restorers International Christian Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10300",
    label: "Everybody Grows Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10301",
    label: "East Coast Elite Volleyball Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10302",
    label: "Paint Branch Athletic Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10303",
    label: "Harford Tv Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10304",
    label: "Ministerio Evengelico De El Principe De Paz Isaias 9-6",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10305",
    label: "Kids Of Addicts Inmates And Alcoholics",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10306",
    label: "Ivy Woods Feline Sanctuary Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10307",
    label: "Molluscan Science Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10308",
    label: "Ichmati A Friends And Family Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10309",
    label: "Caring Sisters Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10310",
    label: "Successful Children Learning Center Ii Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10311",
    label: "Baltimore Transit Campaign Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10312",
    label: "Ne Regional Carwash Convention A Nj Nonprofit Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10313",
    label: "Libertad Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10314",
    label: "Grace Era Mission International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10315",
    label: "Workers United",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10316",
    label: "The Dsfederal Idea Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10317",
    label: "Blessed Hope Communications Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10318",
    label: "Precious Present Truth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10319",
    label: "Limousine Association Of Maryland Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10320",
    label: "Community Empowerment Network Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10321",
    label: "Southern African Community In The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10322",
    label: "Christ Laborers Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10323",
    label: "Kapd Kares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10324",
    label: "Sisters4Fitness Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10325",
    label: "Longview Bible Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10326",
    label: "Diompillor America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10327",
    label: "Fit Fathers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10328",
    label: "Bcps Organization Of Professional Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10329",
    label: "Sandy Spring Bank Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10330",
    label: "Prophetic Ark Tabernacle",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10331",
    label: "Christian Network Solutions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10332",
    label: "Living In His Presence Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10333",
    label: "Cecil County Neighborhood Youth Panel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10334",
    label: "Newday Usa Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10335",
    label: "Diversity In Transition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10336",
    label: "Family Worship Place Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10337",
    label: "Centre For International Development Governance And Conflic",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10338",
    label: "National String Symphonia",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10339",
    label: "The Lazarus Rite Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10340",
    label: "Sabillasville Elementary School Parent Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10341",
    label: "Assembly Of Christ S Followers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10342",
    label: "Your Baltimore Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10343",
    label: "Childbirth Survival International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10344",
    label: "Brain Health Education And Research Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10345",
    label: "Chen Guangcheng Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10346",
    label: "Walt Whitman Drama Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10347",
    label: "Klap Volleyball",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10348",
    label: "Her Story Is My Story Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10349",
    label: "St Marys Syro-Malankara Catholic Church Greater Washington Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10350",
    label: "Rock Church Of Christ In Clarksburg",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10351",
    label: "Maryland Center For Advancement Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10352",
    label: "Polyhymnia Chamber Chorus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10353",
    label: "Empower Deliverance Church Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10354",
    label: "Destiny Recovery Prayer Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10355",
    label: "Rainbows And Windows",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10356",
    label: "Be More Grateful Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10357",
    label: "Christian Union Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10358",
    label: "Southern Maryland Community Resources",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10359",
    label: "Vedanta Medical Clinic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10360",
    label: "Freedom Through Faith Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10361",
    label: "Mission For Christ International Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10362",
    label: "Maryland Blazers Basketball Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10363",
    label: "Kappa Alpha Theta Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10364",
    label: "Village In Howard Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10365",
    label: "Lambda Alpha International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10366",
    label: "Covered By Gods Hands Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10367",
    label: "Benfield Community Sportscenter Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10368",
    label: "Our Place Waldorf",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10369",
    label: "Jefferson Family Private Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10370",
    label: "Starting With Today Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10371",
    label: "Ndi Ngwa Community Association Incorporation Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10372",
    label: "Living Waters Of The Word Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10373",
    label: "Fusion Community Development Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10374",
    label: "Interfaith Partners For The Chesapeake Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10375",
    label: "Daughters Of Dorcas",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10376",
    label: "Germantown Kids Cricket Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10377",
    label: "Swim Ocean City Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10378",
    label: "Hoopco",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10379",
    label: "Operation Reach Back",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10380",
    label: "Kitties & Pitties Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10381",
    label: "Stars For Stars",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10382",
    label: "Iglesia Pentecostal De Dios Es El Poder",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10383",
    label: "All Charities Count Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10384",
    label: "Baltimore Food Hub Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10385",
    label: "Baltimore Industry Liaison Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10386",
    label:
      "Baltimore Washington Chapter Of The National Association Of Residenti",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10387",
    label: "Ambassadors For Christ Ministries International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10388",
    label: "Chinese American Society Of Nuclear Medicine & Molecular Image Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10389",
    label: "Fibroid Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10390",
    label: "One Healing Educating And Renewing Through Therapy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10391",
    label: "New Spire Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10392",
    label: "Chesapeake & Potomac Assoc Of Coll Registrars & Admiss Off Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10393",
    label: "Paxspace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10394",
    label: "Joey Sudo Memorial Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10395",
    label: "City Of Praise Family Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10396",
    label: "Chesapeake Microscopy And Microanalysis Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10397",
    label: "The Gurdjieff Society Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10398",
    label: "Jungto Society Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10399",
    label: "Sisters Of The Son Ministries Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10400",
    label: "Clarksburg Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10401",
    label: "Christian Life Services Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10402",
    label: "Laurel Community Garden",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10403",
    label: "Richard Montgomery High School Magnet Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10404",
    label: "Hope Center Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10405",
    label: "Maryland Integrity Benefit Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10406",
    label: "Iglesia Evangelica Tabernaculo De Fe Y Esperanza Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10407",
    label: "Center For Civic Design",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10408",
    label: "Blackrock Fury Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10409",
    label: "Companions Of Paradise Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10410",
    label: "Our Dream Pursuits Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10411",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10412",
    label: "Trebor Alzheimers Resource And Support Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10413",
    label: "Detour Movement Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10414",
    label: "Iglesia Fuente De Paz",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10415",
    label: "Business Network For Maryland Offshore Wind Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10416",
    label: "Cherish Life Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10417",
    label: "Iglesia La Luz Del Mundo",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10418",
    label: "Acting Out For Als Research Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10419",
    label: "Perry Hall High School Theatre Booster Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10420",
    label: "Vconnections",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10421",
    label: "Rumah Indonesia Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10422",
    label: "Call To Action Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10423",
    label: "Iglesia De Restauracion Adonai Gaithersburg Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10424",
    label: "Brave Heart Program Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10425",
    label: "The Armand Center For Conflict Resolution Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10426",
    label: "Scotland Storm Community Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10427",
    label: "Fashion Umbrella Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10428",
    label: "Young Professionals In Transportation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10429",
    label: "Iraqi Community Development Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10430",
    label: "Maryland Hope Presbyterian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10431",
    label: "Wagners",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10432",
    label: "Friends Of Historic Linthicum Walks Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10433",
    label: "Hope Chapel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10434",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10435",
    label: "Live Peace Tech Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10436",
    label: "Leep To College Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10437",
    label: "Maryland Independent School Consortium",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10438",
    label: "Rockville Soccer Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10439",
    label: "Ica International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10440",
    label: "Maryland High School Rodeo Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10441",
    label: "Success Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10442",
    label: "Camp Inclusion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10443",
    label: "Gods Way Chapel International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10444",
    label: "King Of Kings International Ministerio Internacional Rey De",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10445",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10446",
    label: "River Church Of Delmarva",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10447",
    label: "Powerup Parent Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10448",
    label: "Washington International Chorus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10449",
    label: "Zoglio-Culbertson Charitable Trust",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10450",
    label: "Association Of Operating Room Nurses Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10451",
    label: "A Grande Design Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10452",
    label: "Charis For You Unlimited Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10453",
    label: "New Revelations In Christ Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10454",
    label: "Wildlife Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10455",
    label: "Association Of Independent Midwives Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10456",
    label: "Joshua Bembo Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10457",
    label: "People Against Commercial Kennels",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10458",
    label: "Hatton Animal Rescue Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10459",
    label: "Wicomico Valley Foundation Of Southern Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10460",
    label: "Spiritual Awakening Experience Empowerment Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10461",
    label: "Be Empower Community Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10462",
    label: "Sunshines Friends Cat Rescue And Sanctuary",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10463",
    label: "Christway Chapel Internationl",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10464",
    label: "2Youth 4Youth Empowerment Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10465",
    label: "Warhawks Field Hockey Program Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10466",
    label: "Justice House Of Prayer Dc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10467",
    label: "Changing Turn Community Outreach Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10468",
    label: "Cristo Rey San Antonio High School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10469",
    label: "Maryland Pesticide Education Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10470",
    label: "Nisala Arana Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10471",
    label: "Supporting Older Adults Through Resources Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10472",
    label: "Qachs Lions Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10473",
    label: "Chariaid International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10474",
    label: "Maryland Cardiac Surgery Quality Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10475",
    label: "Iglesia Pentecostes Poder Y Accion De Dios Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10476",
    label: "Ligaya Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10477",
    label: "No Limits Outreach Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10478",
    label: "Charles H Miller Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10479",
    label: "Volatility Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10480",
    label: "Hong Sports Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10481",
    label: "Futures Forum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10482",
    label: "Damascus High School Athletic Booster Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10483",
    label: "Maryland Sportsmens Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10484",
    label: "Morgan State University Alumni Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10485",
    label: "Dc Premier Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10486",
    label: "True Oyster Restoration Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10487",
    label: "New Life Evangelical Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10488",
    label: "Truthunity Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10489",
    label: "Ukombozi",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10490",
    label: "Help 4 People Thriftstore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10491",
    label: "Anew Inspired Change Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10492",
    label: "Iglesia Evangelica Pentecostes Fe Y Amor",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10493",
    label: "Salisbury Hertiage Lions Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10494",
    label: "Stone Of Help Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10495",
    label: "Family Of God Christian Fellowship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10496",
    label: "Rescue Angels Of Southern Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10497",
    label: "House Of Hope Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10498",
    label: "Harmony Baseball Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10499",
    label: "Ministerio Edificando Las Familias",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10500",
    label: "True Worship Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10501",
    label: "Morgan State University Prince Georges County Alumni Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10502",
    label: "Phi Sigma Sigma Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10503",
    label: "Cambridge Power Boat Racing Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10504",
    label: "Twenty-First Century United States Light House Service",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10505",
    label: "Modern Woodmen Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10506",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10507",
    label: "Pattys Place Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10508",
    label: "Slate Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10509",
    label: "Leon Roth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10510",
    label: "Eager Enhancing Adolescent Girls For An Evolving Resolution",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10511",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10512",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10513",
    label: "Widow Care Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10514",
    label: "Animal Rescue Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10515",
    label: "Ywam Dc Prince Georges County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10516",
    label: "Modern Woodmen Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10517",
    label: "Chat And Chew Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10518",
    label: "Chinese American Community Connection",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10519",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10520",
    label: "Maryland Lyric Opera",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10521",
    label: "Stepping Stones To A Successful Life Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10522",
    label: "Jesus Tabernacle Holdings Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10523",
    label: "Heights Capital Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10524",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10525",
    label: "Mindful Mentors Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10526",
    label: "National Disaster Relief Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10527",
    label: "Society Of Loyola Old Boys Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10528",
    label: "Above & Beyond Rare International Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10529",
    label: "Ghana Unity Association1",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10530",
    label: "Ace Mentor Program Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10531",
    label: "Ivymount Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10532",
    label: "Downtown Columbia Arts And Culture Commission Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10533",
    label: "Friends Of Enoch Pratt Free Library Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10534",
    label: "Farm Davis",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10535",
    label: "Steel Gun Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10536",
    label: "Red Cristiana Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10537",
    label: "Maryland Association For Parkinson Support Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10538",
    label: "Sertoma Off-Street Basketball League",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10539",
    label: "Apostles Continuation Church International Maryland Assembly In",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10540",
    label: "Geo-Max New Hope Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10541",
    label: "We Are The Fellowship Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10542",
    label: "Trinity Cemetery",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10543",
    label: "Strong 4 Sean Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10544",
    label: "Boniface Animal Rescue Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10545",
    label: "Builsa Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10546",
    label: "Come Up Higher Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10547",
    label: "Larche Frederick Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10548",
    label: "Clarksburg Community Assistance Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10549",
    label: "Omega Harvest Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10550",
    label: "Easton Economic Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10551",
    label: "Macadoo Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10552",
    label: "Active Achievers Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10553",
    label: "Internation Guards Union Of America - Local 154",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10554",
    label: "National Veterans Association Business Forum",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10555",
    label: "Reading Kingdoms",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10556",
    label: "Hearts Of The Future Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10557",
    label: "Kehoe Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10558",
    label: "First Few Moments",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10559",
    label: "Hall At North Point Edgemere Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10560",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10561",
    label: "Mid-Shore Early Learning Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10562",
    label: "Us Patriot Sailing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10563",
    label: "Ernest And Eileen Bell Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10564",
    label: "Emmitsburg Community Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10565",
    label: "Endtimes Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10566",
    label: "Mbs Research Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10567",
    label: "Velocity Fast Pitch Softball Club Of Charles County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10568",
    label: "Fasmarketplace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10569",
    label: "National Board Of Physician Nutrition Specialists",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10570",
    label: "Treble Harmony Brigade Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10571",
    label: "Marine Corps League",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10572",
    label: "Tony Rose Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10573",
    label: "Comfort Cases Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10574",
    label: "The Potters Follies Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10575",
    label: "Living Stone Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10576",
    label: "Associacao Cultural De Lingua Portuguesa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10577",
    label: "Chennat Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10578",
    label: "Washington City Fife And Drum Corps Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10579",
    label: "Grace Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10580",
    label: "In For Of Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10581",
    label: "Hungarian Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10582",
    label: "Black Dogs And Company Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10583",
    label: "Iglesia De Dios Pentecostal Nuevo Renacer",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10584",
    label: "Transformation Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10585",
    label: "Heart Of The Civil War Heritage Area Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10586",
    label: "Indian Childrens Angel Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10587",
    label: "Lovesavers International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10588",
    label: "Positive-Strides Llc Org",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10589",
    label: "Mid-Shore Pancreatic Cancer Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10590",
    label: "Iglesia Restauracion Familiar Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10591",
    label: "Botf Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10592",
    label: "Ars Biotechnica Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10593",
    label: "Francis Scott Key Antique Car Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10594",
    label: "St Timothy Catholic Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10595",
    label: "The Peter G Dodge Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10596",
    label: "Chessed Vemess Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10597",
    label: "Sole Defined",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10598",
    label: "Us Gospel Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10599",
    label: "M & M Islamic Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10600",
    label: "Tennis Alliance Of Anne Arundel County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10601",
    label: "English Consul Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10602",
    label: "Worldwide All Cancer Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10603",
    label: "Association Of Certified Fraud Examiners-Maryland Chapter Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10604",
    label: "Victorias Hope International Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10605",
    label: "Neilom Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10606",
    label: "Idia College Old Girls Association N A",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10607",
    label: "Allies Kidney Beans Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10608",
    label: "Chaarg",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10609",
    label: "Wounded Veterans Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10610",
    label: "World Youth Value Society Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10611",
    label: "Project Hope Coach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10612",
    label: "Community Development Network Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10613",
    label: "Watts Branch Watershed Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10614",
    label: "4M Swim Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10615",
    label: "Church Of Christ In Randallstown",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10616",
    label: "Food Cowboy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10617",
    label: "Opdea Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10618",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10619",
    label: "Wildlife Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10620",
    label: "Dawat-E-Islami Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10621",
    label: "Friendly Daughters Of Saint Patrick Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10622",
    label: "Public Health And International Development Administrative Service",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10623",
    label: "Sjc87 Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10624",
    label: "Annapolis Rotaract Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10625",
    label: "National Jewish Fun For Legal Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10626",
    label: "Oneness Mobility Resource Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10627",
    label: "4Montgomerys Kids Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10628",
    label: "My Sister My Seed",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10629",
    label: "Operation - Safe Escape",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10630",
    label: "Core Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10631",
    label: "Growth Action Network Of Anne Arundel County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10632",
    label: "Impact Missions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10633",
    label: "Chosen Generation Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10634",
    label: "Dc2Dc Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10635",
    label: "Elijah Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10636",
    label: "Sarfaty Siegel Foundation For The Future",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10637",
    label: "Animal Safe Haven And Adoptions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10638",
    label: "Tec Share Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10639",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10640",
    label: "Churches Here Aimed To Rehabilitate Innovate And Treat Youth",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10641",
    label: "Garrett Park Community Fund C",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10642",
    label: "Inspiring More Minds Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10643",
    label: "Greater Destiny Christian Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10644",
    label: "Ladies On The Verge Of Excellence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10645",
    label: "Toy Breeds In Need Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10646",
    label: "Mid-Atlantic Vintage Bikes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10647",
    label: "Faith Community Chapel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10648",
    label: "Vessel Of Grace Evangelical Church International Of Lanham Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10649",
    label: "Wildlife Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10650",
    label: "Modern Maestro Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10651",
    label: "Adelante Latina Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10652",
    label: "Solomon Northup Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10653",
    label: "Omega Psi Phi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10654",
    label: "Randy Ortanez Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10655",
    label: "Cornerstone Church Of The Living God International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10656",
    label: "Karmacisim Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10657",
    label: "Mfantsipim Old Boys Association 1989 Year Group - North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10658",
    label: "Community Of Strength Project Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10659",
    label: "International Miracle Chapel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10660",
    label: "Measure Of Faith Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10661",
    label: "Btq Productions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10662",
    label: "Redeemed Christian Church Of God Tree Of Life",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10663",
    label: "Siu Joint Employment Building Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10664",
    label: "Renewed By Grace Christian Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10665",
    label: "Madeline Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10666",
    label: "Catholic Apostolate Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10667",
    label: "American Youth Football Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10668",
    label: "For 3 Sisters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10669",
    label: "Charles And Molly Linehan Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10670",
    label: "Midnights Equine Rescue & Sanctuary",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10671",
    label: "Presbyterian Church In Cameroon",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10672",
    label: "Trinity Presbyterian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10673",
    label: "Centre De St Jean Bosco Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10674",
    label: "American Center For Intellectual And Diverse Disorders - Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10675",
    label: "Jewish Senior Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10676",
    label: "St Theresa Of The Child Jesus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10677",
    label: "Salisbury Sunrise Rotary Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10678",
    label: "Stephen E And Maryanne G Means Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10679",
    label: "Strasbourg Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10680",
    label: "Destiny Family Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10681",
    label: "Robiotech Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10682",
    label: "Empowerment Liberation Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10683",
    label: "Yet Another Chance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10684",
    label: "Nora Roberts Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10685",
    label: "Cuddles Cat Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10686",
    label: "Friends Of The Osk Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10687",
    label: "Redeemed Christian Church Of God-Gethsemane",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10688",
    label: "Parents Against Depression Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10689",
    label: "Kenilles Kupboard Pet Pantry & Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10690",
    label: "Dance Alchemy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10691",
    label: "It Only Takes One",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10692",
    label: "Zack Warfield Memorial Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10693",
    label: "Advantage Ethiopia Kids Tennis And Education Initiative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10694",
    label: "Rising Sun Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10695",
    label: "Sierra Leone Education Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10696",
    label: "Alabaster House Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10697",
    label: "Coalition Of Community College Architecture Programs Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10698",
    label: "Samuel Williams Leadership Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10699",
    label: "Qo Marching Cougars Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10700",
    label: "Interrobang Productions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10701",
    label: "Friends Of Chevy Chase Circle",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10702",
    label: "American Zen College",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10703",
    label: "Divine Young Women With Purpose Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10704",
    label: "Christian Church In Montgomery Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10705",
    label: "Sligo Creek Neighbors For A Sustainable Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10706",
    label: "Redbirds Baseball Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10707",
    label: "Hw Global Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10708",
    label: "Brotherhood Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10709",
    label: "Highland Thrift Shop",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10710",
    label: "Purpose Driven Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10711",
    label: "Wollo Meredaja Maheber Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10712",
    label:
      "Judge Alexander Williams Jr Center For Education Justice And Ethics",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10713",
    label: "Coalition For Public Safety Training In Schools",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10714",
    label: "Ehs Alumni Association Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10715",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10716",
    label: "Kingdom Seekers Fellowship Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10717",
    label: "Sgt Preston Ashley Scholarship Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10718",
    label: "Reclaiming Our Children Community Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10719",
    label: "Melchisedek Missionary Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10720",
    label: "Shield Of Faith International Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10721",
    label: "Concerts For Causes",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10722",
    label: "Integrity Business Partners",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10723",
    label: "Finding Your Destiny Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10724",
    label: "Project Affinity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10725",
    label: "Asia Partners Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10726",
    label: "Madonna Shriver Calender Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10727",
    label: "Division Of Firms Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10728",
    label: "Iglesia De Dios Pentecostal Nueva Jerusalen La Celestial Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10729",
    label: "Love In Action Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10730",
    label: "Chesapeake Baseball Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10731",
    label: "Operation Safe Shelter Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10732",
    label: "Learning For Real Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10733",
    label: "Morton And Tobia Mower Art Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10734",
    label: "Arise&Flourish",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10735",
    label: "The Vine",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10736",
    label: "Kingdom Of Glory Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10737",
    label: "The Friends Of The Perry Hall Library Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10738",
    label: "College Park Resurrection Power Of Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10739",
    label: "Performing Arts And Creative Expression",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10740",
    label: "Wyneken Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10741",
    label: "Brooks-Davis Institute For Brain Cancer Awareness Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10742",
    label: "Religious Freedom & Business Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10743",
    label: "Richard Carbaughs Hope Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10744",
    label: "Cursillo Movement Of Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10745",
    label: "National Center For Citizen Safety Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10746",
    label: "G Krug & Son Ironworks Museum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10747",
    label: "Rukundo International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10748",
    label: "Planning With Purpose Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10749",
    label: "Takoma Ensemble",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10750",
    label: "Legion Of Christ The King Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10751",
    label: "Hellenic Heritage Museum Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10752",
    label: "Mid Atlantic Scottish Terrier Education And Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10753",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10754",
    label: "Ride N Shine Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10755",
    label: "World Newah Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10756",
    label: "Greater Glen Burnie Athletic Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10757",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10758",
    label: "Bjh Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10759",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10760",
    label: "S And Pb Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10761",
    label: "Wexler Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10762",
    label: "William Averette Anderson Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10763",
    label: "Mt Pleasant Heritage Preservation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10764",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10765",
    label: "National Deaf Freedom Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10766",
    label: "Evening With Christ Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10767",
    label: "United States-Iran Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10768",
    label: "Baltimore Abortion Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10769",
    label: "The Hornets Nest Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10770",
    label: "National Society United States Daughters Of 1812",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10771",
    label: "Seed Of Life Christian Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10772",
    label: "Instruments Of Healing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10773",
    label: "Asa Women Baltimore Metro Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10774",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10775",
    label: "Living By The Word Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10776",
    label: "Christ Embassy Silver Spring",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10777",
    label: "The Monk Us Memorial Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10778",
    label: "Uncuffed Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10779",
    label: "The Wah Vision Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10780",
    label: "Wicomico Day School Foundation Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10781",
    label: "Mi Casa Es Su Casa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10782",
    label: "Hagerstown Ice Amateur Athletic Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10783",
    label: "Pearl Elegance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10784",
    label: "City Life - Community Builders",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10785",
    label: "Carolyn Anne Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10786",
    label: "Church Of The I Am That I Am Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10787",
    label: "Partakers Church Of Christ Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10788",
    label: "Sowharvest",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10789",
    label: "Friends Of 1967 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10790",
    label: "How Do You Like Me Now Productions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10791",
    label: "International Bible Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10792",
    label: "Global Health Awareness Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10793",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10794",
    label: "Aletheia College Park Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10795",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10796",
    label: "Md Covenant Of Ministers Wives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10797",
    label: "Maryland Information Literacy Exchange Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10798",
    label: "Cubbys Place",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10799",
    label: "Eden Community Ventures Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10800",
    label: "John Wall Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10801",
    label: "Mary Helen Brooks Scholarship Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10802",
    label: "Iglesia Pentecostal Fuente De Amor",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10803",
    label: "Start The Adventure In Reading Stair Annapolis",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10804",
    label: "Little Hands Preschool",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10805",
    label: "Lighthouse Mission Of Havre De Grace",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10806",
    label: "Hope And Peace Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10807",
    label: "Word Focus Chapel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10808",
    label: "El Poder De Ser Mujer",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10809",
    label: "Amateur Athletic Union Of The United States Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10810",
    label: "First Suit Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10811",
    label: "Ancient Order Of Hibernians In America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10812",
    label: "Rugby Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10813",
    label: "Supplying Hope",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10814",
    label: "Kelsey And Taylor Smith Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10815",
    label: "We Are One Community Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10816",
    label: "Pax River Aikikai Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10817",
    label: "Cuz We Care",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10818",
    label: "Foundation 4 Heroes",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10819",
    label: "Iuec Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10820",
    label: "Y-Not Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10821",
    label: "Inazuma Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10822",
    label: "Shekinah Faith Global Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10823",
    label: "Darlington Lions Independence Day Committee Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10824",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10825",
    label: "Young Women Of Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10826",
    label: "Childrens Global Readiness Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10827",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10828",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10829",
    label: "Building Confident Women Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10830",
    label: "Chabad Of Downtown Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10831",
    label: "Ilove Now",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10832",
    label: "Yissakar Incorporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10833",
    label: "National Recreation And Park Ethnic Minority Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10834",
    label: "Coppin University Police Lodge No145 Of The Fraternal Order Of Po",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10835",
    label: "Youth Empowerment And Stability Mentorship Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10836",
    label: "Grace Beyond The Walls",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10837",
    label: "Leslies Week",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10838",
    label: "Voice Of Desert Vod Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10839",
    label: "Congolese Community Of Washington Metropolitan",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10840",
    label: "Children Of The Body In Christ International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10841",
    label: "Washington Area Tennis Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10842",
    label: "Muslim Community Center For Thaqalayn Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10843",
    label: "Chizoba Morah Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10844",
    label: "Creative And Performing Arts Of South County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10845",
    label: "Chabad Of Downtown Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10846",
    label: "Earth Instinct",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10847",
    label: "York Area Radio Control Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10848",
    label: "Spin Off Public Media Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10849",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10850",
    label: "Lah Leng Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10851",
    label: "Demolay International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10852",
    label: "Baltimores Promise Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10853",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10854",
    label: "Philippine Humanitarian Coalition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10855",
    label: "Digestive Health Physicians Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10856",
    label: "Home Assist Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10857",
    label: "Sisterslivingworks Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10858",
    label: "Association Of Propasal Management Professionals",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10859",
    label: "Don E Riddle Leadership Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10860",
    label: "Justice Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10861",
    label: "American Medical Global Exchange",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10862",
    label: "Ancient Order Of Hibernians In America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10863",
    label: "Rotary Club Of Howard West Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10864",
    label: "Maryland State Tigers Youth Development",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10865",
    label: "Land Of Lights Christian Fellowship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10866",
    label: "Christian Liberty Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10867",
    label: "True Light Freewill Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10868",
    label: "Filling Station Church Of God In Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10869",
    label: "Chirhoclin Research Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10870",
    label: "True Talents Of The Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10871",
    label: "Beyond The Natural Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10872",
    label: "Maryland Senior Resource Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10873",
    label: "Franklin Manor Boat Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10874",
    label: "Kylees Dancing Angels Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10875",
    label: "Mid Atlantic Foundation For Asian Artists Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10876",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10877",
    label: "Agape Deliverance Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10878",
    label: "Hack Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10879",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10880",
    label: "Disability Inclusion Guild Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10881",
    label: "United Citizens And Friends Of Mumford Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10882",
    label: "Muslim Family Center Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10883",
    label: "Noaa Association Of Corps Officers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10884",
    label: "Kingdom Purpose Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10885",
    label: "Friends Of Micronesia",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10886",
    label: "Sigma Alpha Epsilon Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10887",
    label: "Friends Of The Middletown Library",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10888",
    label: "Scholarship America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10889",
    label: "Autism Spectrum Support Group Of Southern Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10890",
    label: "Tekau Heart Memorial Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10891",
    label: "Purple Tie Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10892",
    label: "Hour Of Mercy Prayer Ministry Worldwide Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10893",
    label: "Fetch One Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10894",
    label: "Standing Strong Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10895",
    label: "Baltimore Next Generation Investing Event Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10896",
    label: "Mmen Cultural & Development Association-Usa I Nc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10897",
    label: "Attitudes In Motion Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10898",
    label: "Lovely Mind Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10899",
    label: "Bandjoun Foundation Of Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10900",
    label: "Charm City Covenant Church Baltimore Md",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10901",
    label: "Greater Baltimore Ashi",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10902",
    label: "Inter-African Committee-Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10903",
    label: "Come All Who Are Thirsty",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10904",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10905",
    label: "Chrysolite Charity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10906",
    label: "African Agribusiness Knowledge Centers Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10907",
    label: "Church International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10908",
    label: "Hope Christian Fellowship Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10909",
    label: "Daru Nuril Idayyat Wasaadat Islamic Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10910",
    label: "Ellicott City Soccer Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10911",
    label: "Holy Mountain International Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10912",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10913",
    label: "Abc Summer Bonanza Jackpot Show Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10914",
    label: "Vietnam Veterans Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10915",
    label: "H H A A",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10916",
    label: "Joseph Harris Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10917",
    label: "White Truck Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10918",
    label: "International College Of Dentists-Maryland Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10919",
    label: "Trinity House",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10920",
    label: "Dmv Kids Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10921",
    label: "Jewish Teen Advancement Program Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10922",
    label: "Colvin Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10923",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10924",
    label: "Maryland Open Horse Show Series Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10925",
    label: "Delmarva Review Literary Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10926",
    label: "Recovery Africa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10927",
    label: "Kuentai-Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10928",
    label: "Owen Community Healthcare Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10929",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10930",
    label: "New Creation Bible Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10931",
    label: "Dc Beauty Of Beijing Opera Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10932",
    label: "Christ Reigns Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10933",
    label: "Womens Equity Center And Action Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10934",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10935",
    label: "Zaching Against Cancer Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10936",
    label: "Presbyterian Church Of Ghana In Gaithersburg-Trinity Congregation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10937",
    label: "Road Runners Club Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10938",
    label: "Seal Of Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10939",
    label: "Friends Of The Mother And Child Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10940",
    label: "Center For Egyptian American Relations",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10941",
    label: "Washington Cricket Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10942",
    label: "Faith Temple Baptist Church Of Charles County Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10943",
    label:
      "Marine Corps Aviation Association John Glenn Squadron Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10944",
    label: "Strength & Honor Motorcycle Club-Maryland Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10945",
    label: "Getting It In Cyclist",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10946",
    label: "Go Team Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10947",
    label: "Ena Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10948",
    label: "Foundery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10949",
    label: "Brain Global Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10950",
    label: "Igbo Intercessory Prayer Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10951",
    label: "Mc Explosion Competitive Cheer",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10952",
    label: "Led Ladies",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10953",
    label: "International Youth Ambassadors",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10954",
    label: "International Cornea Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10955",
    label: "Heart Healer Foundation Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10956",
    label: "American Friends Of Tenufa Bakehila Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10957",
    label: "Baltimore Playhouse",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10958",
    label: "Anti Malaria Fashion Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10959",
    label: "Mayon Global Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10960",
    label: "Destany Founders International Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10961",
    label: "Michael Dipaola Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10962",
    label: "Cornstalk Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10963",
    label: "Alkaram Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10964",
    label: "Friends Of Afrikids Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10965",
    label: "Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10966",
    label: "Destiny Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10967",
    label: "United Bethel Presbyterian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10968",
    label: "Advocates For The Aging Of Frederick County Md Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10969",
    label: "Snow Hill Arts On The River Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10970",
    label: "The Prince Georges County Fire-Ems Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10971",
    label: "Hebrew Apostolic Church Assemblies And Fellowship Of Christ Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10972",
    label: "Heart-Mind International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10973",
    label: "Rachel Carson Landmark Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10974",
    label: "Kidsstream Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10975",
    label: "1911 United Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10976",
    label: "Veterans Football Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10977",
    label: "Ancient Order Of Hibernians In America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10978",
    label: "No More Cavities Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10979",
    label: "American Federation Of State County & Municipal Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10980",
    label: "Leo Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10981",
    label: "4615 Theater Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10982",
    label: "New Path Reformed Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10983",
    label: "Mcdaniel Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10984",
    label: "Tribe Fells Point",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10985",
    label: "Boost Her World International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10986",
    label: "Next Level Soccer Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10987",
    label: "Eysonu Productions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10988",
    label: "Franciscan Friars-Our Lady Of The Angels Province Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10989",
    label: "Partnership For Responsible Growth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10990",
    label: "Montgomery Agricultural Producers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10991",
    label: "Chinese Youth Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10992",
    label: "Preston Historical Society Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10993",
    label: "Schulte Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10994",
    label: "Partners Of Good Shepherd Jamaica Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10995",
    label: "College Bound Student Athletes",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10996",
    label: "School Of The Ancient Alkebu Lanic Mystic Rites Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10997",
    label: "Watergate Harbor Improvement Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10998",
    label: "Bridge The Gap Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-10999",
    label: "Apostle Betty P Peebles Youth Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11000",
    label: "H U M A N I T Y 360 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11001",
    label: "Community Care Community Does",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11002",
    label: "Iglesia Pentecostal Profetica Nueva Vida",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11003",
    label: "Maryland Academy Of Medical Pschologists Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11004",
    label: "American Legion Department Of Md Chesapeake Post No 127 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11005",
    label: "Waldorf Healing Place Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11006",
    label: "Foundation For Special Surgery",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11007",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11008",
    label: "Friends Of Greenbelt Theatre",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11009",
    label: "Changing Lives Ministries Of Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11010",
    label: "Kent County Learning Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11011",
    label: "Jolly Friends Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11012",
    label: "Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11013",
    label: "Calvary Life Baptist Chapel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11014",
    label: "Intelligencer Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11015",
    label: "Remnant Center Of Excellence",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11016",
    label: "Hampton Institute Class Of 1979",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11017",
    label: "Open Minds Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11018",
    label: "Shikuri Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11019",
    label: "Home Away From Home Developmental Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11020",
    label: "Mjw Spirit Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11021",
    label: "13Th Man",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11022",
    label: "Qm Quality Matters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11023",
    label: "Hcpi Development Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11024",
    label: "Korean Drycleaners Association Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11025",
    label: "Girls Expecting More Success Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11026",
    label: "Ministerio Pentecostal Linaje Escogido",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11027",
    label: "See The Ability",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11028",
    label: "Elite Registry Of Working Dogs Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11029",
    label: "Iglesia De Dios Jesus Pan De Vida Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11030",
    label: "Friends Of Boroume Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11031",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11032",
    label: "Easton Latino",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11033",
    label: "Elijahs Cave Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11034",
    label: "Rmhs Athletic Booster Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11035",
    label: "Rogue Swan Theatre Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11036",
    label: "Pure Living Water Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11037",
    label: "Nkwa Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11038",
    label: "Iglesia Cristiana El Buen Samaritano",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11039",
    label: "Positive Outcome Mentoring & Dance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11040",
    label: "Bryan P Healy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11041",
    label: "Ellsworth Ws Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11042",
    label: "Helping Our Seniors Thrive Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11043",
    label: "Kentra Care Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11044",
    label: "Rockville Meditation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11045",
    label: "Krobo Girls Alumni Association North America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11046",
    label: "Church Without The Walls In Jesus Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11047",
    label: "Purposed Resource And Community Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11048",
    label: "Kent Island Beach Cleanups Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11049",
    label: "Charm City Companions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11050",
    label: "Arham Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11051",
    label: "Karisafication Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11052",
    label: "Morehouse College Parents Association Of Metro Washington Dc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11053",
    label: "Geulah Generation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11054",
    label: "Abuja Country Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11055",
    label: "Mishkan Hashofar Messianic Jewish Congregation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11056",
    label: "Washington Brass Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11057",
    label: "Non Commissioned Officers Association Of The Us Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11058",
    label: "South Forest Drive Business Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11059",
    label: "A Micah Moment",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11060",
    label: "International Network On Appropriate Technology",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11061",
    label: "Fairmount Heights Cdc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11062",
    label: "Mavuno",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11063",
    label: "Sunny Day Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11064",
    label: "Society Of Saint Vincent De Paul Mother Seton Parish Conference",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11065",
    label: "Sligo Creek Education Foundation Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11066",
    label: "Pi Lambda Phi Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11067",
    label: "M & M Pleasant Quarters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11068",
    label: "House Of The Lord Community Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11069",
    label: "Y A S Sports Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11070",
    label: "Rockys Horse Rescue And Rehabilitation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11071",
    label: "Foundation Of Hope",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11072",
    label: "Iglesia Evangelica Jesucristo Es El Consejero Fiel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11073",
    label: "Marine Corps League",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11074",
    label: "Global Dream Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11075",
    label: "All City Elite Basketball Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11076",
    label: "Fongo Tongo Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11077",
    label: "Omnichen Solutions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11078",
    label: "Dalton Daley Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11079",
    label: "Staceys Plan Memorial Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11080",
    label: "John Easwar Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11081",
    label: "Beloveth Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11082",
    label: "Navy-Marine Corps Regional Training Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11083",
    label: "Caobas Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11084",
    label: "Action Faith Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11085",
    label: "Kollel Torah Temimah Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11086",
    label: "National Association Of Nigerian Nurses In North America Nanna Dmv",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11087",
    label: "Restoration Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11088",
    label: "Rockshire Swimming Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11089",
    label: "Victorias Veteran Housing Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11090",
    label: "Indiana Farmers And Hunters Feeding The Hungry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11091",
    label: "Poplar Hill Parks Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11092",
    label: "Pta Delaware Congress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11093",
    label: "Hindu Afghan Cultural & Religious Association Of Washington",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11094",
    label: "Luskin Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11095",
    label: "Love Covers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11096",
    label: "Edmondson Heights Civic Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11097",
    label: "Healing Is The Childrens Bread Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11098",
    label: "Cheng Family Charitable Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11099",
    label: "Puppy Paws Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11100",
    label: "Tehzibul Akhlaq Tr Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11101",
    label: "Maryland Veterans Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11102",
    label: "Irrevocable Declaration Of Charitable Trust Of Cohen Family",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11103",
    label: "National Association For Self- Esteem",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11104",
    label: "Three Angels Deaf Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11105",
    label: "Kenyan International Community Kic",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11106",
    label: "Excel Communities Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11107",
    label: "Jain Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11108",
    label: "Goshen Park Home Owners Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11109",
    label: "Ebenezer Full Gospel Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11110",
    label: "Redeemed Christian Church Of God New Covenant City Church Rccg",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11111",
    label: "Blaq Equity Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11112",
    label: "By The Spirit Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11113",
    label: "Christ Apostolic Church Inc Aya Babalola Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11114",
    label: "Adventist Peace Fellowship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11115",
    label: "Sree Narayana Mission Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11116",
    label: "Annie Mae Jenson Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11117",
    label: "Faith Deliverance Breakthrough Ministries Worldwide Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11118",
    label: "African Regional Youth Initative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11119",
    label: "Institute Of Neuromorphic Engineering Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11120",
    label: "Thomas Lambros Memorial Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11121",
    label: "Grace Korean Presbyterian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11122",
    label: "Galesville Community Center Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11123",
    label: "Friends Of Felines Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11124",
    label: "Woodlawn Senior Housing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11125",
    label: "Association Of Adult Musicians With Hearing Loss Aamhl Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11126",
    label: "Great Light Presbyterian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11127",
    label: "Faith Center Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11128",
    label: "Brunswick Junior Baseball",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11129",
    label: "Friends Of Jordan Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11130",
    label: "Shore Harvest Presbyterian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11131",
    label: "Our Victorious City Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11132",
    label: "Diversity International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11133",
    label: "Redeemed Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11134",
    label: "Whine & Cheese Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11135",
    label: "Laurel Cats Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11136",
    label: "Redeemed Christian Church Of God Jesus Pavilion Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11137",
    label: "The Jaimi Leigh Jones Foundation For Dual Diagnosis Of Chronic Ill",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11138",
    label: "Twan Children & Youth Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11139",
    label: "Edgewater Bible Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11140",
    label: "Owl Moon Raptor Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11141",
    label: "Scholarship America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11142",
    label: "Mon Reve Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11143",
    label: "Gathering A Place For Change",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11144",
    label: "Nlife Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11145",
    label: "Alexander And Mabel Bell Legacy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11146",
    label: "Hose2Habitat Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11147",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11148",
    label: "Talking Hands Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11149",
    label: "Serving Women Across Generations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11150",
    label: "Kibera Hamlets Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11151",
    label: "Old Achimotan Association - 1995 Year Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11152",
    label: "Reach The World Mission",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11153",
    label: "Mizo Wesleyan Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11154",
    label: "Ensuring The Future",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11155",
    label: "Elville Center For The Creative Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11156",
    label: "Dc Neighbor",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11157",
    label: "Steth Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11158",
    label: "Cherry Hill Eagles Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11159",
    label: "Carroll Camden Business Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11160",
    label: "Team Fire Elite Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11161",
    label: "Oakland Mills High School Fine Arts Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11162",
    label: "Iglesia Profetica De Restauracion La Nueva Jerusalen",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11163",
    label: "Guardians Of The Children Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11164",
    label: "Maryland Biodiversity Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11165",
    label: "Classic Motor Museum Of St Michaels Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11166",
    label: "Wilczynski Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11167",
    label: "Diva Movement Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11168",
    label: "Childrens Guild Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11169",
    label: "Stand For Ubuntu",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11170",
    label: "Shens Daughters Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11171",
    label: "Moniba Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11172",
    label: "Temple Of God Christian Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11173",
    label: "Home Away Transitional Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11174",
    label: "American Iris Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11175",
    label: "Peaces Institute For Leadership & Advocacy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11176",
    label: "Gamma Chi Chapter House Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11177",
    label: "Us Care Bridge",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11178",
    label: "Congregation Ohr Hatorah",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11179",
    label: "Ravens Roost 82",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11180",
    label: "Serenity And Wellness Clinic",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11181",
    label: "Deborah B Castro Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11182",
    label: "Medical Education Resources Initiative For Teens Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11183",
    label: "I Make Positive Attempts To Change Teens Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11184",
    label: "Christ The King Anglican",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11185",
    label: "Daughters Of Zion Dzm Compassion Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11186",
    label: "Viamistad Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11187",
    label: "Maryland Foster Parent Association Charles County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11188",
    label: "Home Land Eagle Eye Money Gate",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11189",
    label: "Maredith Cares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11190",
    label: "Mountainside Baroque",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11191",
    label: "Son Rise Fellowship Ministries Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11192",
    label: "Crossroads Educational Homeschool Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11193",
    label: "Fighting Parkinsons Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11194",
    label: "African Womens Network International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11195",
    label: "Atu Training And Education Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11196",
    label: "African Immigrant Caucus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11197",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11198",
    label: "Christ Resurrection Church Of God",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11199",
    label: "Visit Harford Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11200",
    label: "Iglesia De Dios Pentecostes Jesus El Buen Pastor Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11201",
    label: "Guru Nanak Girls Scholarship Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11202",
    label: "Redeemed Christian Church Of God - Mercy Court",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11203",
    label: "Pleasantview Historical Assn",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11204",
    label: "House Of Bankerd Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11205",
    label: "Holy Angels Catholic School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11206",
    label: "Mrg Media Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11207",
    label: "Baltimore Affordable Housing Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11208",
    label: "Trust Practice And Risk Management Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11209",
    label: "Friends Of Queen Annes County Libra Ry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11210",
    label: "Solutions From The Land",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11211",
    label: "The Child & Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11212",
    label: "Worcester County Public Schools Education Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11213",
    label: "360 Community Development Corporation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11214",
    label: "Qg Community Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11215",
    label: "St Michael St Clement School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11216",
    label: "Bethesda Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11217",
    label: "Burmese Community Association Of Washington Dc - Metro Area",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11218",
    label: "Adloris Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11219",
    label: "Beyond The Walls Builders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11220",
    label: "Alpha Sigma Phi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11221",
    label: "Nonprofitwater Org Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11222",
    label: "Tanaka Education Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11223",
    label: "Maryland Resource Parent Association- Howard County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11224",
    label: "Care Coordination Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11225",
    label: "Latino Child Care Association Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11226",
    label: "The Nasdaq Entrepreneurial Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11227",
    label: "Redencion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11228",
    label: "Bit Holdings Seventy-Two Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11229",
    label: "Hammond Girls Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11230",
    label: "Lost Towns Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11231",
    label: "Foundations Bible Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11232",
    label: "Crossroads Riders Motorcycle Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11233",
    label: "Institute For Women",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11234",
    label: "Transformation Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11235",
    label: "Iglesia De Dios Pentecostes Lirio De Los Valles",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11236",
    label: "Bharat Bhushan Foundation -Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11237",
    label: "Transformed To Glory Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11238",
    label: "Pinked Perspective Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11239",
    label:
      "Debre Tsion Kidist Mariam We Kidus Gabriel Ethiopian Orthodox Tewahdo",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11240",
    label: "Sugar Faces - Senior Dog Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11241",
    label: "Isaiahs Promise Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11242",
    label: "Joy To The World Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11243",
    label: "International Fallen Riders Relief Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11244",
    label: "Reconciliation Education Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11245",
    label: "Rage Against Addiction Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11246",
    label: "7Th Generation Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11247",
    label: "Calebs Corner Charities",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11248",
    label: "Trellis Arch Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11249",
    label: "Global Deaf Aid Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11250",
    label: "Society Of St Vincent De Paul Baltimore Council Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11251",
    label: "Jesus El Buen Pastor Pentecostal Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11252",
    label: "Open Door Christian Fellowship Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11253",
    label: "Beta Chapter Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11254",
    label: "Birthday Book Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11255",
    label: "Americcadd Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11256",
    label: "Ocean City Light Tackle Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11257",
    label: "Frederick Douglass Museum And Cultural Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11258",
    label: "Delta Chapter Of Kappa Alpha Psi Fraternity Alumni Assosciation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11259",
    label: "Gwu-Liberty3 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11260",
    label: "Iron Harbor Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11261",
    label: "Fred Sommer Aces Tennis Scholarship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11262",
    label: "Orrson Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11263",
    label: "The Movement Team Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11264",
    label: "Peace Studies Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11265",
    label: "Real Men Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11266",
    label: "Captivate Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11267",
    label: "Iglesia Pentecostal Apostolica Of Lusby Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11268",
    label: "Alayi Development Union Baltimore Washington Metro Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11269",
    label: "Northeastern Maryland Additive Manufacturing Innovation Authority",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11270",
    label: "Kingdom United Christian Fellowship Inc Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11271",
    label: "Maryland Mens Crew Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11272",
    label: "Words Of Life International Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11273",
    label: "Christ New Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11274",
    label: "Ancient Mystical Order Of Rosae Crucis",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11275",
    label: "Rrft Enterprise",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11276",
    label: "Ark Of The Covenant Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11277",
    label: "Perisphere Theater Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11278",
    label: "Pediatric Arachnoid Cyst Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11279",
    label: "Melekot International Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11280",
    label: "World Languages 360 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11281",
    label: "Exerceo Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11282",
    label: "Iglesia De Dios Pentecostal Luz Verdadera Salvos Por Gracia Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11283",
    label: "Bethesda Metro Area Village",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11284",
    label: "Table Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11285",
    label: "Silwad Foundation For Health Education And Better Life Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11286",
    label: "Bcml Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11287",
    label: "Frontline Outreach Ministires",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11288",
    label: "Closing The Gap Movement Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11289",
    label: "Bali Old Boys Association In The Usa Boba-Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11290",
    label: "Baltimore Rock Opera Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11291",
    label: "Church Of God In Christ Christian Mission",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11292",
    label: "Saint Leonard Elementary School Family And School Team",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11293",
    label: "Valley Reunion Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11294",
    label: "Maryland Potomac Valley Local Weigh Lifting Committee Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11295",
    label: "Care Connect International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11296",
    label: "Institute Of Real Estate Management Of The Natl Assn Of Realtors",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11297",
    label: "Air Force Sergeants Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11298",
    label: "Iglesia Pentecostes Cristo Viene Ya",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11299",
    label: "Bee Informed Partnership Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11300",
    label: "General S Future Charity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11301",
    label: "Face 2 Face Worship Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11302",
    label: "Church Of The Harbor",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11303",
    label: "Mikveh Of Annapolis Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11304",
    label: "The Leaf Program Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11305",
    label: "Messiah Village International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11306",
    label: "Respect Yourself Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11307",
    label: "International Support For Africas Children Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11308",
    label: "Alices Dream Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11309",
    label: "Reverend Callie Johnson Matthews Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11310",
    label: "Tau Beta Pi Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11311",
    label: "Sons Of Black Caesar Motorcycle Club Maryland Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11312",
    label: "Amandas Mission For God",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11313",
    label: "Oaa 91 Yeargroup - Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11314",
    label: "Mays Chapel Elementary School Parent-Teacher Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11315",
    label: "Bowen Leadership Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11316",
    label: "Indepth Bible Study Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11317",
    label: "Dmsca Supplier Development Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11318",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11319",
    label: "Nikipro Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11320",
    label: "African Diaspora United Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11321",
    label: "Warp Drive Robotics",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11322",
    label: "Gabriel Project Cluster Of Montgomery County Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11323",
    label: "Gray Mott Scholarship Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11324",
    label: "Loud Voices Together Educational Advocacy Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11325",
    label: "Scholars Of Tomorrow Cdc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11326",
    label: "Xavier Alexander Atienza Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11327",
    label: "Tooro American Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11328",
    label: "American Federation Of State County & Municipal Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11329",
    label: "Baltimore City College Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11330",
    label: "Baltimore Musicales Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11331",
    label: "Advanced Counseling",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11332",
    label: "Tools4Success Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11333",
    label: "Kappa Psi Pharmaceutical Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11334",
    label: "Baltimore Steam Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11335",
    label: "Pcr House Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11336",
    label: "Carroll Imrpovement Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11337",
    label: "Friends Of Anne Arundel County Animal Care & Control Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11338",
    label: "Kenya Connect Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11339",
    label: "Autism Fyi",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11340",
    label: "Team Up For 1 Incorporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11341",
    label: "Puma Pride Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11342",
    label: "Families For Cancer Prevention United",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11343",
    label: "New Heart Living Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11344",
    label: "Global Children Educational Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11345",
    label: "Maryland Kachin Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11346",
    label: "Kingdoms View Assembly Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11347",
    label: "Fostering Change Network Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11348",
    label: "Iglesia Evangelica Jesucristo Es El Senor Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11349",
    label: "Let Me Shine Guatemala Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11350",
    label: "Jamz Booster Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11351",
    label: "Aberdeen High School Band Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11352",
    label: "Focused On Your Future Christian Life Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11353",
    label: "G Ellis Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11354",
    label: "Toiletry And Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11355",
    label: "World Association For Academic Doctors Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11356",
    label: "Delmarva Aces Baseball And Softball Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11357",
    label: "Next Chapter Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11358",
    label: "Lgbt Fallen Heroes Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11359",
    label: "Emerald City Ranch Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11360",
    label: "Odu Americas",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11361",
    label: "United Christian Church And Ministerial Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11362",
    label: "Door Of Heaven Christian Church Icpc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11363",
    label: "Fort Washington Symphonic Winds",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11364",
    label: "Out Of County Inner City Org",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11365",
    label: "Yourstory International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11366",
    label: "Hope Empowerment Abundant Life Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11367",
    label: "Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11368",
    label: "Foulger Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11369",
    label: "Fordi Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11370",
    label: "Ingram Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11371",
    label: "Penn State Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11372",
    label: "Fund For Tikkun Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11373",
    label: "Prince Georges County Parks And Recreation Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11374",
    label: "A Place Of Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11375",
    label: "Pathway For Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11376",
    label: "William Sampson Brooks High School Alumni Association Incorpra",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11377",
    label: "Royalway Christian Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11378",
    label: "American Federation Of State County & Municipal Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11379",
    label: "Wing Chun Way",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11380",
    label: "Singletrack Cycling Open Registration Events Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11381",
    label: "Puppies Are Worth Saving Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11382",
    label: "Grace & Truth Community Cpp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11383",
    label: "Sparrock Management Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11384",
    label: "Fan The Fire Ministries International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11385",
    label: "Street Re-Entry Program Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11386",
    label: "Womenstrong International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11387",
    label: "Institute On The Constitution Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11388",
    label: "Tu An Buddhist Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11389",
    label: "Prince Georges County Memorial Library System Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11390",
    label: "Bel Air High School Athletic Booster Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11391",
    label: "Declass Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11392",
    label: "Father Thomas Hayden Liberia Scholarship Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11393",
    label: "John M Kamya Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11394",
    label: "Urban Kidney Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11395",
    label: "Deaf Lesbian Festival Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11396",
    label: "Treasureland Health Builders Initiative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11397",
    label: "Elk And North East Rivers Watershed Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11398",
    label: "Blue Valor Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11399",
    label: "Twistedtimber Hunt Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11400",
    label: "Tree Of Life International Kingdom Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11401",
    label: "Kindness And Care Beyond Borders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11402",
    label: "Iglesia Ministerio De Dios Unido",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11403",
    label: "Iglesia Misionera Monte Sinai",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11404",
    label: "New Start Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11405",
    label: "Links Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11406",
    label: "Center For Invasive Species Prevention Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11407",
    label: "Elevate Annapolis",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11408",
    label: "Birdflower Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11409",
    label: "Conversations For Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11410",
    label: "Visit Baltimore Education & Training Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11411",
    label: "Ministry Of Second Chances",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11412",
    label: "Ark Of God International Ministry Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11413",
    label: "Chesapeake Science Point Ptso",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11414",
    label: "Christ Foundation Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11415",
    label: "Tebah Educational Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11416",
    label: "Kachin Baptist Churches Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11417",
    label: "Alternative Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11418",
    label: "Zanmi Swen Caring Friends Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11419",
    label: "Perfecting Strength From Within Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11420",
    label: "United Destiny Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11421",
    label: "Doctor Sheik Humarr Khan Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11422",
    label: "Gotacs Global Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11423",
    label: "White Oak Youth Organizaton",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11424",
    label: "Et Cube International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11425",
    label: "We Need Diverse Books",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11426",
    label: "Loving Arms Christian Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11427",
    label: "The Posses Roar Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11428",
    label: "Platoon 22 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11429",
    label: "Friends Of Cecil County Parks And Recreation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11430",
    label: "Ministerios De Dios Sinai",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11431",
    label: "Is Real Life Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11432",
    label: "Michael Shane Plowden Jr Loving Legacy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11433",
    label: "Bisonrattler Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11434",
    label: "Denton Church Of Free Seventh Day Adventist",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11435",
    label: "Cause For Paws Cares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11436",
    label: "E Russell Hick Parent Support Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11437",
    label: "Dyc Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11438",
    label: "Iglesia De Dios Pentecostes El Cordero De Gloria",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11439",
    label: "Well-Wishers For The Animals Of The Greenbelt Shelter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11440",
    label: "Properties 4 Us Non Profit Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11441",
    label: "Breaking The Barriers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11442",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11443",
    label: "Cambridge Community Radio Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11444",
    label: "Linking Our Selves Together Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11445",
    label: "Usaf Police Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11446",
    label: "Broken Wall Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11447",
    label: "Aciu - Maryland Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11448",
    label: "Association Of Professional Researchers For Advancement",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11449",
    label: "Empowered Awareness Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11450",
    label: "Aviation Information Sharing And Analysis Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11451",
    label: "Design District Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11452",
    label: "Solar Village Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11453",
    label: "Return Us Home",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11454",
    label: "Wildlife Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11455",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11456",
    label: "Peoples Community Outreach Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11457",
    label: "Westminster Youth Baseball",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11458",
    label: "Reisterstown Community Cemetery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11459",
    label: "Kitty Angels Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11460",
    label: "Sugarloaf Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11461",
    label: "For Otis Sake",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11462",
    label: "Ministerios Jesucristo Rey De Reyes Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11463",
    label: "Maryland Cape Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11464",
    label: "Western Maryland K-9 Search And Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11465",
    label: "Sky Health Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11466",
    label: "Family New Life Ministries Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11467",
    label: "Sharing Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11468",
    label: "Tri County Mlk Coalition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11469",
    label: "Rising Over Standards And Excelling",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11470",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11471",
    label: "Upper Chesapeake Baseball Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11472",
    label: "American Federation Of State County & Municipal Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11473",
    label: "Iglesia Pentecostal Una Luz En El Desierto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11474",
    label: "Life Builders Africa Mission Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11475",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11476",
    label: "Al-Madina Masjid Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11477",
    label: "Coffee With A Christian Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11478",
    label: "Virtuous Woman Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11479",
    label: "William C & Susan S Mariner Private Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11480",
    label: "Federation Of Women And Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11481",
    label: "Shock Cancer Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11482",
    label: "Lower Shore Lesbian Gay Bisexual Transgender Queer-Questio",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11483",
    label: "Maryland Gives",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11484",
    label: "Project Hope Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11485",
    label: "Westminster Baseball For Teens Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11486",
    label: "Zionreach - Community Development Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11487",
    label: "Reach Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11488",
    label: "Storm Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11489",
    label: "All Kids Should Play Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11490",
    label: "Straight Key Century Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11491",
    label: "Korean Diaspora Leadership Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11492",
    label: "Life Church Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11493",
    label: "Urban Ecosystem Restorations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11494",
    label: "Howard & Anne Kaylor Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11495",
    label: "Rockville Bike Hub",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11496",
    label: "Chesapeake Math & It Academy North Pto Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11497",
    label: "Disabilities Forum Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11498",
    label: "Ofuobi Women Of Enugu State Wdc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11499",
    label: "First Christian Church Of Laurel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11500",
    label: "Parent Of Cub Scout Pack 1559 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11501",
    label: "American Legion Auxiliary Department Of Maryland Susquehanna",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11502",
    label: "Kolmac Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11503",
    label: "Journey School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11504",
    label: "Rati Dance Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11505",
    label: "Iglesia Bautista El Puente",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11506",
    label: "Mvp Youth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11507",
    label: "Foundation For Facial Recovery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11508",
    label: "Shepherds Of Zion Ministries International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11509",
    label: "Youth Empowerment Source Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11510",
    label: "Blunt Marsh Conservation Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11511",
    label: "Ivy And Pearls Of Southern Maryland Community Charities",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11512",
    label: "American Chinese Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11513",
    label: "Iglesia Bautista Vida Nueva",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11514",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11515",
    label: "Chesapeake Math And It Elementary Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11516",
    label: "Phase Cycling",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11517",
    label: "Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11518",
    label: "Living Springs Assisted Living Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11519",
    label: "Listen And Learn Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11520",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11521",
    label: "African Institute Of Arts Science & Technology Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11522",
    label: "Tidewater Association For Gymnast",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11523",
    label: "Lions Center For Rehabilitation And Extended Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11524",
    label: "International Association For Food And Waterborne Parasitology",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11525",
    label: "Steam Forward Us",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11526",
    label: "Optimist International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11527",
    label: "Jewish Studio Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11528",
    label: "Volunteers Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11529",
    label: "Brush Arbor Gospel Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11530",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11531",
    label: "Little Falls Friends Monthly Meeting",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11532",
    label: "Making Durable Impact For Growth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11533",
    label: "Uss Hamner Dd-718 Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11534",
    label: "Snow Hill Ecumenical Food Pantry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11535",
    label: "Eleanor Roosevelt High School Robotics Booster Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11536",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11537",
    label: "West Side Alumni Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11538",
    label: "Hristo Botev Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11539",
    label: "Redeemed Christian Church Of God Amazing Grace Chapel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11540",
    label: "Mspaa Memorial Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11541",
    label: "Breath Of Fresh Air Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11542",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11543",
    label: "Village Of Takoma Park",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11544",
    label: "Mas Alla Del Sol",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11545",
    label: "Mrb Thumbs Up",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11546",
    label: "Fetal Heart Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11547",
    label: "Together As One Covenant Outreach Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11548",
    label: "Community Improvement Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11549",
    label: "Great Pocomoke Fair Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11550",
    label: "124 East Ii Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11551",
    label: "Converted Heart",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11552",
    label: "Maryland Entertainment Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11553",
    label: "Cup Of Hope Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11554",
    label: "Girl Speak",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11555",
    label: "Luv U Project In Memory Of Carolyn C Mattingly Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11556",
    label: "Empowerment Essence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11557",
    label: "Potomac Lions Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11558",
    label: "Magic Minds Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11559",
    label: "Reid Temple Bible College Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11560",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11561",
    label: "Masonic Charities Of Havre De Grace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11562",
    label: "Jubilee Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11563",
    label: "Wschc Support Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11564",
    label: "Faith Hope Charity Love Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11565",
    label: "Sunnyside Solutions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11566",
    label: "Dawkins Dreams Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11567",
    label: "Kids Into Discovering Science",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11568",
    label: "Icarre Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11569",
    label: "Nigerian Women Association Maryland Metro -Nwamm-",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11570",
    label: "Real Estate Charitable Foundation Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11571",
    label: "Academy Of Georgian Heritage Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11572",
    label: "Passion To Help Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11573",
    label: "Association Of Guineans In The Dmv",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11574",
    label: "Breath Of God Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11575",
    label: "Lighthouse Of Hope Worship Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11576",
    label: "Severna Gardens Improvement Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11577",
    label: "A N Ts Army Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11578",
    label: "Maryland Campaign For Liberty Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11579",
    label: "Helping Our People Empowerment Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11580",
    label: "Whispering Woods Farm Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11581",
    label: "Brooklyn Park Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11582",
    label: "D Brown Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11583",
    label: "Ji Li Project Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11584",
    label: "Parklawn Sailing Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11585",
    label: "Mid-Shore Nursing Assistant Advisory Council",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11586",
    label: "W Lee And Rosalie M Ridenour Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11587",
    label: "St Vincent De Paul Society St Ambrose Conference Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11588",
    label: "Edu-Girls Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11589",
    label: "Mount Zion House Of Deliverance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11590",
    label: "Frederick County Gathering Of Eagles Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11591",
    label: "Foundation For Fair Contracting - Choice",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11592",
    label: "International Mutual Aid Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11593",
    label: "B3 Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11594",
    label: "Ingage Ministries Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11595",
    label: "American Tennis Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11596",
    label: "A Hand Up Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11597",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11598",
    label: "Consortium On Competitiveness And Cooperation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11599",
    label: "Imagination Library Of Montgomery County Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11600",
    label: "Living Life International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11601",
    label: "New Day Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11602",
    label: "International Hope Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11603",
    label: "Student Homelessness Initiative Partnership Of Frederick County In",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11604",
    label: "Oss Weightlifting Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11605",
    label: "Womens Global Impact Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11606",
    label: "Fairy Tails Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11607",
    label: "Risen Christ Gospel Mission International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11608",
    label: "Baltimore Hunger Project Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11609",
    label: "Sba Performing Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11610",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11611",
    label: "Pikesville Township Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11612",
    label: "Into Missional Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11613",
    label: "Outerarts Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11614",
    label: "Financial Education Foundation Of Anne Arundel County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11615",
    label: "American Guild Of Organists",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11616",
    label: "Southern Maryland Disc Golf Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11617",
    label: "North County Music Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11618",
    label: "State Center Neighborhood Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11619",
    label: "Greater Washington Academy Of Women Dentists Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11620",
    label: "Symphony Number One",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11621",
    label: "International Community Initiatives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11622",
    label: "Iglesia Pentecostes Libres Por Jesucristo",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11623",
    label: "Snow Hill High Boosters Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11624",
    label: "Iglesia Misionera Pentecostes Salem",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11625",
    label: "Sharebaby",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11626",
    label: "Holy Land Peace Fellowship Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11627",
    label: "Turner Station History Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11628",
    label: "Wuren Education Assisting Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11629",
    label: "Chesapeake Math & It Academy-South Pto Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11630",
    label: "Mountain Valley Baseball",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11631",
    label: "Lafont Tugman Memorial Scholarshipund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11632",
    label: "Masters Pieces Productions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11633",
    label: "Motivating Adolescents To Reach Success Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11634",
    label: "Abia State University Alumni Association - Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11635",
    label: "Love Inspires Friendship And Triumph Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11636",
    label: "Pta Delaware Congress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11637",
    label: "Presbytery Of The East",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11638",
    label: "Sister States Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11639",
    label: "Cesar Chavez Elementary School Parent Teacher Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11640",
    label: "Amen Church Of Kmc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11641",
    label: "Pool Of Bethesda Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11642",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11643",
    label: "Highlandtown Business Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11644",
    label: "Pi Kappa Alpha Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11645",
    label: "Gods Treasures Thrift Shop",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11646",
    label: "Takoma Park Fire Department",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11647",
    label: "Education And Literacy For Minorities",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11648",
    label: "International Federation Of Dental Hygienists Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11649",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11650",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11651",
    label: "Shimmering Smiles Healthy Hearts",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11652",
    label: "Building Our Nations Daughters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11653",
    label: "Maryland Dental Implant Study Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11654",
    label: "Jamsquad Cycling",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11655",
    label: "Anantha Narayana Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11656",
    label: "My Little Patient",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11657",
    label: "Delmarva Titans Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11658",
    label: "Bsp Blacksox Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11659",
    label: "Backstory Group Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11660",
    label: "Umps Care Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11661",
    label: "Write Synergy For Creative Cure Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11662",
    label: "Howgirlscode Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11663",
    label: "Christchurch And Ministries Of",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11664",
    label: "Crossover Elite Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11665",
    label: "Jeremy H Adams Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11666",
    label: "Purr It Forward Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11667",
    label: "Blessed Tree Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11668",
    label: "Sisterhood Of Southern Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11669",
    label: "Women & Men Of Substance International Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11670",
    label: "Federal Cultural Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11671",
    label: "Ladders To Leaders",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11672",
    label: "Association Of Liberian Christian Ministers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11673",
    label: "Kidogo Early Years Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11674",
    label: "Public Relations Society Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11675",
    label: "Glory Christian Fellowship International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11676",
    label: "Giac Son Buddhist Temple",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11677",
    label: "Southern Maryland Mission Of Mercy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11678",
    label: "Greater Dc Chapter Of National Action Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11679",
    label: "Pike District Community Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11680",
    label:
      "Future Farmers Of America And Its State Association & Local Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11681",
    label: "S A F E - Supplying Allergy Friendly And Emergency - Food Pant",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11682",
    label: "Christ You Live Saved Kingdom Inspired Divine Seeds Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11683",
    label: "Budhanikantha School Endowment Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11684",
    label: "Maryland Allergy And Asthma Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11685",
    label: "Global Investigative Journalism Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11686",
    label: "Shattered Canvas Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11687",
    label: "Spiritual Directors Of Color Network Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11688",
    label: "Thornton Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11689",
    label: "Dr Hollis Seunarine And Eileen Seunarine Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11690",
    label: "City Reach Church-Cumberland Md Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11691",
    label: "Angelwing Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11692",
    label: "Anetrice House Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11693",
    label: "Kristina And William Catto Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11694",
    label: "Eakin Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11695",
    label: "True Jesus Church In Washington Dc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11696",
    label: "Maya Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11697",
    label: "Eastern Shore Rabbit Rescue And Education Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11698",
    label: "Cte Vision Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11699",
    label: "Jamestowne Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11700",
    label: "Expanding Boundaries International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11701",
    label: "Poor Roberts Mission",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11702",
    label: "International Quranic Studies Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11703",
    label: "Global Collective",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11704",
    label: "Central States Private Education Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11705",
    label: "Ralph A Cusick Jr And Jaquelin A Cusick Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11706",
    label: "Living Hope Church Of Bowie Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11707",
    label: "Feed The Scene Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11708",
    label: "Xuejun And Andrea Chen Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11709",
    label: "Burkittsville Preservation Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11710",
    label: "Dea Code Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11711",
    label: "National Lutheran Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11712",
    label: "Life Bridge Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11713",
    label: "National Day Of Prayer Frederick",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11714",
    label: "Splendor Christian Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11715",
    label: "Amvets",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11716",
    label:
      "Holy Rosary Secondary School Kenema Old Girls Association In Na Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11717",
    label: "Whole Genome Science Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11718",
    label: "Jes Avanti Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11719",
    label: "Fahnbulleh-Freeman Family Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11720",
    label: "Journey Continues Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11721",
    label: "Sirnaik Green Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11722",
    label: "Christ Vision Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11723",
    label: "Eternal Kingdom Power Global Church Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11724",
    label: "Disability Partnership Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11725",
    label: "Mjm 24 Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11726",
    label: "Taa Mfoomi Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11727",
    label: "Little Black Dog Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11728",
    label: "Project Propel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11729",
    label: "Purple Moon Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11730",
    label: "Us Lacrosse Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11731",
    label: "Church Of Miracles International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11732",
    label: "Hasana Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11733",
    label: "Garden Club Of Rock Hall",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11734",
    label: "Miracle Center International Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11735",
    label: "Iglesia Evangelica Misionera La Gran Comision",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11736",
    label: "For Goodness Sake",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11737",
    label: "Southern Alumni Scholarship Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11738",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11739",
    label: "Coastal Arts Market",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11740",
    label: "Blue Ribbon Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11741",
    label: "Windmill Class Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11742",
    label: "Leos Garden Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11743",
    label: "Foundation For Education In Bandounga",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11744",
    label: "The Fuller Center For Housing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11745",
    label: "Make A Difference Engine Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11746",
    label: "Iglesia De Cristo Muros De Salvacion Y Puertas De Alabanza",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11747",
    label: "Dream Plant",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11748",
    label: "Chaplain Captain Henry Vinton Plummer Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11749",
    label: "Ayniy International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11750",
    label: "Sisters Inspiring Sisters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11751",
    label: "Riding The Wind Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11752",
    label: "Agape Empowerment Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11753",
    label: "Mdcia Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11754",
    label: "Super-Duper Fitness Troopers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11755",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11756",
    label: "Quilters By The Bay",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11757",
    label: "Bird River Restoration Campaign",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11758",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11759",
    label: "Transforming Christian Life Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11760",
    label: "Phs Alumni Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11761",
    label: "Breaking Cycles",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11762",
    label: "Vision Impact Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11763",
    label: "Kiddy Up With Horses",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11764",
    label: "Netspective Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11765",
    label: "Ocr Unleashed",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11766",
    label: "A Better Light",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11767",
    label: "Hidden Brook Equine Therapy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11768",
    label: "Ladies In Training Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11769",
    label: "Benjamin Banneker Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11770",
    label: "Champions Against Cancer Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11771",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11772",
    label: "Kings College Old Boys Association - North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11773",
    label: "Bridgehaven",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11774",
    label: "True American Heroes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11775",
    label: "Oldtown Mennonite Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11776",
    label: "Sleep Higher Ground",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11777",
    label: "Prince Georges Soccer Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11778",
    label: "Higher Dimension Glory Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11779",
    label: "Pinball Edu",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11780",
    label: "React International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11781",
    label: "Rosaria Communities Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11782",
    label: "Cross Chinese Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11783",
    label: "St Philomena Thespians",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11784",
    label: "We Feed The Need",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11785",
    label: "Rosaria Communities In Colesville Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11786",
    label: "Concerted Care Foundation Inc Nfp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11787",
    label: "Hip Hip Whoray",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11788",
    label: "Neen Cares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11789",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11790",
    label: "United For Clean Power",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11791",
    label: "Les Gemmes Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11792",
    label: "Old Line Football Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11793",
    label: "Center For Healthcare Innovation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11794",
    label: "Sisters Inspiring Others To Achieve Inc - Siota",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11795",
    label: "Pearls Of Hope Community Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11796",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11797",
    label: "Mindfully Anchored Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11798",
    label: "Dvs Legal Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11799",
    label: "Dancing Sunshine Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11800",
    label: "Our Dream Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11801",
    label: "Soul Saving Ministry Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11802",
    label: "Banneker Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11803",
    label: "Save Ontario Shores Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11804",
    label: "Paxworks Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11805",
    label: "U Dream I Dance Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11806",
    label: "North Carroll Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11807",
    label: "Iglesia De Restauracion Del Principe De Paz Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11808",
    label: "American Association Of Blacks In Energy Baltimore Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11809",
    label: "Tee 2 Green Golf Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11810",
    label: "Americas Mock Election Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11811",
    label: "Harvest Bible Chapel Annapolis",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11812",
    label: "Wbr Empowerment Legacy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11813",
    label: "Christ Restoration Fellowship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11814",
    label: "Maryland Legislative Latino Caucus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11815",
    label: "Global Z Recording Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11816",
    label: "Camerata Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11817",
    label: "Zeta Beta Tau Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11818",
    label: "Project 316 Faith And Family",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11819",
    label: "Elijahs Blessing Community Service Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11820",
    label: "Shifting Up Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11821",
    label: "Elkton Community Kitchen",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11822",
    label: "Jcs Mentoring Program Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11823",
    label: "Up & Out Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11824",
    label: "Fraternal Order Of Police Grand Lodge",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11825",
    label: "Save The Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11826",
    label: "Brittany K Booker Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11827",
    label: "Addiction Policy Forum",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11828",
    label: "Chesapeake Chapter 1957 Of The Indian Motorcycle Riders Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11829",
    label: "Women Of Peace Corps Legacy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11830",
    label: "Modufems Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11831",
    label: "Celestial Church Of Christ - El Beraca Blessing Parish Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11832",
    label: "Evangelistic Empowerment Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11833",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11834",
    label: "Iglesia De Cristo Familiar Bethania",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11835",
    label: "St Philomenas Apostolate",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11836",
    label: "Guinean Women Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11837",
    label: "Upstream Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11838",
    label: "Authority Gospel Church International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11839",
    label: "Nigerian American Lawyers Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11840",
    label: "City Of Annapolis Veba Health Savings Trust",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11841",
    label: "Agape Life Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11842",
    label: "Humane Society Of The Eastern Shore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11843",
    label: "Entrepreneurs Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11844",
    label: "United Okoyong Past Student Association Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11845",
    label: "Independent Of Autism",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11846",
    label: "Holy Angels Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11847",
    label: "Kingdom Building Ministries For Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11848",
    label: "Custom And Classic Car Educational Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11849",
    label: "Rockfish Swimming Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11850",
    label: "International Marine Electronics Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11851",
    label: "Shop With A Cop Cecil County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11852",
    label: "Global Reach Local Impact",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11853",
    label: "Baxter Family Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11854",
    label: "Have Hope",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11855",
    label: "Iglesia De Dios Cristo Viene",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11856",
    label: "Eotc Mahibere Bealwold North America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11857",
    label: "Kehillas Meor Hatorah Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11858",
    label: "Voices Of Hope",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11859",
    label: "Young Ladies Club Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11860",
    label: "Mother Catherine Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11861",
    label: "Rss Alumni Association North America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11862",
    label: "Element Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11863",
    label: "International Tree Of Life Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11864",
    label: "Mentoring Mentors Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11865",
    label: "Okoyong Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11866",
    label: "Ndokwa Association In America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11867",
    label: "Missionfit",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11868",
    label: "Every Child Is Worthy Ministries Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11869",
    label: "Frederick Rangers Youth Baseball Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11870",
    label: "Tbw Charities",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11871",
    label: "Trash Free Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11872",
    label: "Swing 4 More Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11873",
    label: "New Victory Christian Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11874",
    label: "Chabad Of Chevy Chase Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11875",
    label: "Captivate Global Initiatives Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11876",
    label: "Victories In Christ Jesus Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11877",
    label: "Cura Personalis Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11878",
    label: "Phyllis Glascoe Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11879",
    label: "Mary Way Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11880",
    label: "Gojam Mutual Association-Edir-Felege Ghion- Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11881",
    label: "Miriam Sei Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11882",
    label: "Don And Sandy Pyle Charity Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11883",
    label: "Charity For All Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11884",
    label: "Prometheus Consortium Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11885",
    label: "Science And Technology Policy Center For Development",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11886",
    label: "Fenix Youth Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11887",
    label: "Way To The Cross Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11888",
    label: "Little Hands Big Hearts",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11889",
    label: "Laurel Water Supply Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11890",
    label: "Iglesia De Restauracion Cristiana",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11891",
    label: "Citywide Goldstarz Marching Band Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11892",
    label: "Men On The Verge Of Excellence",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11893",
    label: "Coalition Of Assisted Living Providers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11894",
    label: "Baier Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11895",
    label: "Vantage Point Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11896",
    label: "Deaconchef Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11897",
    label: "Center For Public Administrators",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11898",
    label: "Jba Operation Warm Heart",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11899",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11900",
    label: "Kulungu Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11901",
    label: "Baltimore Youth Cycling Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11902",
    label: "Improving Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11903",
    label: "Heart Program Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11904",
    label: "Pg County Pd D1 Citizens Advisory Council",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11905",
    label: "Technology Student Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11906",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11907",
    label: "Maryland Chin Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11908",
    label: "Portersville Cemetery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11909",
    label: "Overseas Cantonese Youth Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11910",
    label: "Robs Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11911",
    label: "San Antonio Military Medical Center Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11912",
    label: "Mensa Press Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11913",
    label: "Outstretched Arms Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11914",
    label: "Daydream Sunshine Initiative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11915",
    label: "Sister Cities Girlchoir Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11916",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11917",
    label: "Jeffies Choice Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11918",
    label: "Talbot Watermen Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11919",
    label: "Catherines Family And Youth Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11920",
    label: "East Baltimore Graffiti Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11921",
    label: "Adullum Community Healthcare Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11922",
    label: "American Education Opportunities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11923",
    label: "Hope For Sickle Cell Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11924",
    label: "Baltimore Bike Party Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11925",
    label: "Historic Rocky Springs Chapel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11926",
    label: "Outlet Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11927",
    label: "Kids After Hours Child Care Centers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11928",
    label: "Deaf Emancipation Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11929",
    label: "Tabula Rasa Domestic Violence Relocation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11930",
    label: "Gods Vision Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11931",
    label: "Perfecting Worship Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11932",
    label: "Mt Washington Merchants Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11933",
    label: "Tri Community Mediation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11934",
    label: "Battle Of Falling Waters 1863 Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11935",
    label: "Suitable Attire Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11936",
    label: "Anointed House Of Prayer Church Global Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11937",
    label: "Western Orthopaedic Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11938",
    label: "Mbaitoli Association Of Maryland Washington Dc & Northern Virginia",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11939",
    label: "Early Childhood Math Development Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11940",
    label: "Global Revival Fire Assembly",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11941",
    label: "Iwo Baptist High School Old Students Assoc Sossahib N A Chapte",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11942",
    label: "Redeemed Christian Church Of God-Life Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11943",
    label: "Taylors Tribe Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11944",
    label: "Beloved Of God Gospel Assembly Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11945",
    label: "Methodist Church Nigeria Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11946",
    label: "Haironpurpose Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11947",
    label: "Tax Authority Game Show Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11948",
    label: "Beautiful I Am",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11949",
    label: "Living Well On Wilkens Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11950",
    label: "Values Proposition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11951",
    label: "Newlife Pentecostal Church & Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11952",
    label: "Hope For Life Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11953",
    label: "Change 4 Good Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11954",
    label: "Love Not Hurt Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11955",
    label: "Raising Young Lives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11956",
    label: "Brausa United Futebol Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11957",
    label: "Little League Baseball Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11958",
    label: "Better Health Initiative For Women And Children Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11959",
    label: "Citybeat Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11960",
    label: "Versakats Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11961",
    label: "Joseph And Vera Douglas Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11962",
    label: "Easton Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11963",
    label: "Cecil County Retired School Personnel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11964",
    label: "Jane Addams Resource Corporation - Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11965",
    label: "Friends Of Ten Mile Creek And Little Seneca Reservoir",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11966",
    label: "Next Stages Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11967",
    label: "Maryland Distillers Guild",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11968",
    label: "American Diversity Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11969",
    label: "Hunger Resolution International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11970",
    label: "Committee To Unleash Prosperity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11971",
    label: "The Eunice Casey Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11972",
    label: "Project Pneuma Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11973",
    label: "Street Angel Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11974",
    label: "Printers Forum",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11975",
    label: "Foundation For The Augmentation Of African-Americans In Film Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11976",
    label: "Tann Global Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11977",
    label: "Women For Democracy In America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11978",
    label: "Heaven Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11979",
    label: "Garcia Family Spotlight Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11980",
    label: "Julianas Animal Sanctuary Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11981",
    label: "Eshel Street Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11982",
    label: "Middle River Volunteer Fire & Rescue Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11983",
    label: "Maryland Horsemens Assistance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11984",
    label: "Diaspora Togolaise De Washington",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11985",
    label: "Friends Of Gwen Lehman Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11986",
    label: "Delta Upsilon International Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11987",
    label: "Drama Rocks Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11988",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11989",
    label: "Mindful Medicine Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11990",
    label: "Embassy Of Our Lord Jesus Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11991",
    label: "Church Circle Singers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11992",
    label: "Owings Mills Foursquare Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11993",
    label: "Friends Of The Bohemia Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11994",
    label:
      "Foundation For The Preservation Of Government House Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11995",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11996",
    label: "Imperial Gate",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11997",
    label: "Gimie S Organization For Youth-Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11998",
    label: "Ltg Alfred J Mallette Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-11999",
    label: "Amateur Athletic Union Of The United States Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12000",
    label: "The Anointed House Of Prayer",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12001",
    label: "Nathalie C Batts Charitable Trust Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12002",
    label: "Usa-China Sister Schools Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12003",
    label: "Ava Potter Pilcher Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12004",
    label: "Underserved Minorities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12005",
    label: "Recovering Felons House Of Hope",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12006",
    label: "Cyber Vikings",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12007",
    label: "Icc Runners Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12008",
    label: "Deliverance Church Of Christ Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12009",
    label: "Holding Hands For Haley Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12010",
    label: "Maxmore Creek Productions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12011",
    label: "Tap Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12012",
    label: "24 7 365 Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12013",
    label: "I Am Me Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12014",
    label: "Dual Academy Of Learning Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12015",
    label: "Africa Business And Entrepreneurship Research Soci",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12016",
    label: "Dorchester Lacrosse",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12017",
    label: "Lower Shore Support Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12018",
    label: "Filipino-American Christian Evangelism Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12019",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12020",
    label: "Senior Dog Sanctuary Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12021",
    label: "Hopes Journey Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12022",
    label: "Jayde M Schools Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12023",
    label: "In The Spirit Of Giving Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12024",
    label: "Gateway Second Chance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12025",
    label: "Lazy Panda Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12026",
    label: "Vidya-Niketanam Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12027",
    label: "Jarrettsville Lions Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12028",
    label: "North Hills Educational Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12029",
    label: "Gateway Boarding Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12030",
    label: "Opcmia International Training Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12031",
    label: "Micheline Ewang Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12032",
    label: "Genesis International Outreach Support Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12033",
    label: "Cape Conservation Corps",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12034",
    label: "North East Housing Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12035",
    label: "Kent Conservation And Preservation Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12036",
    label: "Mattie Smothers Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12037",
    label: "West African Methodist Collegiate Alumni Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12038",
    label: "Metropolitan Baltimore Fire Emerald Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12039",
    label: "Gunpowder Neck Boating Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12040",
    label: "Science And Engineering Partnership Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12041",
    label: "Enlighten Ministries Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12042",
    label: "Bradley Hills Village Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12043",
    label: "Hispanic Officers Law Enforcement Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12044",
    label: "Few Good Men Of Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12045",
    label: "Esperanza Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12046",
    label: "Within U",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12047",
    label: "Impact Africa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12048",
    label: "Blythewood Farms",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12049",
    label: "Greater Praise Christian Deliverance Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12050",
    label: "Batibo Alumni Association Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12051",
    label: "Funding Life Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12052",
    label: "Rejoice Bible Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12053",
    label: "Miracle Fellowship Gospel Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12054",
    label: "Joe Brooks Foundation For Conservation And Outdoor Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12055",
    label: "Mpanzi Empowering Women And Girls Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12056",
    label: "Jewish Childrens Library Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12057",
    label: "Giants Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12058",
    label: "Dova International Charities Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12059",
    label: "Good News Ministry International In",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12060",
    label: "Forage Center For Peacebuilding And Humantarian Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12061",
    label: "Creation Springs Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12062",
    label: "Laurel Auto Care Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12063",
    label: "Team Bbc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12064",
    label: "Bezwen At Home Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12065",
    label: "Western Heights Band Partnership Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12066",
    label: "Comprehensive Disaster Relief",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12067",
    label: "Calvary Torch Missions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12068",
    label: "Friends Of Shomer Shabbat Scouting",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12069",
    label: "Salisbury United Soccer Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12070",
    label: "Wenesso Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12071",
    label: "Sound Theory Urban Development Youth Outreach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12072",
    label: "Reachmore Church Of God In Christ Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12073",
    label: "Back Creek Benefits Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12074",
    label: "Vanessa D Booth Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12075",
    label: "New Galilee Community Outeach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12076",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12077",
    label: "The First Gibson Cemetary Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12078",
    label: "Sligo Woods Civic Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12079",
    label: "Birds Of Prey Ravens No 20 Queen Annes County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12080",
    label: "Covenant Haven Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12081",
    label: "House Of Praise Community Outreach Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12082",
    label: "Ava S T21 Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12083",
    label: "Doctors Defending Life Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12084",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12085",
    label: "A Chance To Dance Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12086",
    label: "Famileague Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12087",
    label: "South Point Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12088",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12089",
    label: "Empower4Lifemd Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12090",
    label: "St Barnabas And St Susanna Coptic Orthodox Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12091",
    label: "First United Trade Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12092",
    label: "Wyngate Neighbors Helping Neighbors Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12093",
    label: "Nicer Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12094",
    label: "Maryland Youth Club Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12095",
    label: "Restoration Through Christ Ministries Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12096",
    label: "Newwave Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12097",
    label: "Bmr Studio Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12098",
    label: "Open My Heart Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12099",
    label: "Greater Urbana Area Food Bank Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12100",
    label: "Wings For Val Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12101",
    label: "Dynamix Community Development Group Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12102",
    label: "In The Community Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12103",
    label: "Iglesia Pentecostal Unida Cristo Es La Roca",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12104",
    label: "Together Inspiration Acts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12105",
    label: "Hmg Management Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12106",
    label: "Villages Of Kensington Md Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12107",
    label: "Yesodot Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12108",
    label: "Anne Arundel Co And City Of Annapolis Community Emergency Resp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12109",
    label: "Nachlas Hatorah Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12110",
    label: "On The Flip Side Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12111",
    label: "James And Mary Schurz Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12112",
    label: "Christ Ladder Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12113",
    label: "Beit Chesed",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12114",
    label: "Voices Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12115",
    label: "Tree Of Hope Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12116",
    label: "Dc Project Connect",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12117",
    label: "Spirit Club Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12118",
    label: "Alpha Sigma Phi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12119",
    label: "Muddy Paws Farm",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12120",
    label: "Maryland Exiles Youth Rugby Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12121",
    label: "Chamber Encounters Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12122",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12123",
    label: "Feast Rockville Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12124",
    label: "Polk Dorman Family Reunion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12125",
    label: "Alpha Sigma Phi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12126",
    label: "Men And Women On The Move For Christ Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12127",
    label: "Ride To Survive Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12128",
    label: "Christ The Body Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12129",
    label: "Love To Life Church & Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12130",
    label: "Women Veterans United Committee Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12131",
    label: "National Veterans Association Business Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12132",
    label: "Reid Temple Senior Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12133",
    label: "Iglesia Evangelica Cristo Es La Respuesta Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12134",
    label: "Nkah Nikwi Ni Mankon Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12135",
    label: "Volosov Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12136",
    label: "Change Agent Network Africa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12137",
    label: "Tsinghua Alumni Association In Greater Washington Dc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12138",
    label: "Alpha Sigma Phi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12139",
    label: "Greater Severna Park Womens Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12140",
    label: "Alpha Sigma Phi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12141",
    label: "Southern Maryland Youth Athletic Conference",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12142",
    label: "Angelic Grace Ministries International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12143",
    label: "Eliza Lindsay Adult Care Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12144",
    label: "Farm Heritage Conservancy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12145",
    label: "Minikwu And Nchelem Healthcare Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12146",
    label: "Iglesia Vida Abundante Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12147",
    label: "Maryland Heroes Park Memorial Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12148",
    label: "Teens With Dreams",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12149",
    label: "Louise Emma Taylor Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12150",
    label: "Faith Based Sports & Youth Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12151",
    label: "Cybersecurity Association Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12152",
    label: "Eastern Shore Agriculture Sustains Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12153",
    label: "American Gays And Lesbians Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12154",
    label: "Student Global Ambassador Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12155",
    label: "Metro Washington Association Of Blind Athletes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12156",
    label: "White Rose Farm Circle Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12157",
    label: "Life Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12158",
    label: "Ltll Global Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12159",
    label: "Womens Care Center Of Maryland Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12160",
    label: "Fund For New Initiatives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12161",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12162",
    label: "Broken Spoke Family Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12163",
    label: "Ingleside At Home Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12164",
    label: "Sheet Metal Workers Local 100 Retirees Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12165",
    label: "Light Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12166",
    label: "Brentwood Volunteer Fire Department Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12167",
    label: "Joseph Riley Armstead Iii Memorial Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12168",
    label: "G Iv Wellness Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12169",
    label: "Enatyi Bayech Childrens Integrated Development Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12170",
    label: "David O And Martha G Campbell Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12171",
    label: "College Goal Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12172",
    label: "Lakhanpal Vein Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12173",
    label: "Morgan State University Alumni Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12174",
    label: "Help Our Schools",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12175",
    label: "Jagadguru Shri Vallabhacharya Peeth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12176",
    label: "Towercares Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12177",
    label: "Friends Of Waterford Park Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12178",
    label: "Open Heaven Apostolic Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12179",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12180",
    label: "Every Girl Can Be Smart Learning Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12181",
    label: "Access Hears Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12182",
    label: "Faith Covenant International Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12183",
    label: "God Has Chosen Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12184",
    label: "Rotary International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12185",
    label: "Washington Chamber Orchestra",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12186",
    label: "Project Love Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12187",
    label: "Sikhs Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12188",
    label: "Openwise Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12189",
    label: "Kingdom Ecclesia Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12190",
    label: "Bridge Us",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12191",
    label: "Kreative Influence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12192",
    label: "Divine News Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12193",
    label: "Milton Margai Alumni Association-Usa Incorporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12194",
    label: "Fierce Cheer Sponsors Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12195",
    label: "Academic Affairs",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12196",
    label: "Black Chamber Of Commerce Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12197",
    label: "Knights Of St Jerome Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12198",
    label: "Guyana Metropolitan Relief Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12199",
    label: "Friends Of Enoch Pratt Free Library Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12200",
    label: "East Baltimore Historical Library",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12201",
    label: "Central Maryland Wrestling Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12202",
    label: "Spencer Grace Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12203",
    label: "Greater Crisfield Action Coalition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12204",
    label: "The Connection",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12205",
    label: "Preservation And Enhancement Fund Of Mount Olivet Cemetery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12206",
    label: "New Broadway East Community Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12207",
    label: "Benjamin Olaosebikan Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12208",
    label: "Powerful Church Of Christ-House Of Prayer",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12209",
    label:
      "Methodist Boys High School Alumni Association Washington Metropolita",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12210",
    label: "Diamond Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12211",
    label: "Caribnationcdc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12212",
    label: "Financial Ua Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12213",
    label: "Ethlyns Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12214",
    label: "New Legacy Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12215",
    label: "Empact Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12216",
    label: "Dtr Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12217",
    label: "Htc Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12218",
    label: "Ark Farms",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12219",
    label: "Engage Diaspora Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12220",
    label: "New Christianity Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12221",
    label: "Village Youth & Family Center Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12222",
    label: "Church Of Our Lord Jesus Christ In The Upper Room Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12223",
    label: "Nutainment Charities Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12224",
    label: "Kimball Construction Co Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12225",
    label: "Mission To Morazan Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12226",
    label: "My Girls",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12227",
    label: "No Turning Back Behavorial Health Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12228",
    label: "Benchwarmers Benefit Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12229",
    label: "Talo Halo",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12230",
    label: "Agape Praise International Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12231",
    label: "Missionary Sisters Of Divine Providence",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12232",
    label: "Rotary Foundation Of West Anne Arundel County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12233",
    label: "El Callejon De La Loma Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12234",
    label: "Extraordinary Women Of God",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12235",
    label: "University Of Maryland Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12236",
    label: "Friendly Loving Opportunities - Flo",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12237",
    label: "Us-Spain Executives Community Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12238",
    label: "Southwest Partnership Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12239",
    label: "Clean Slate Rescue And Rehabilitation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12240",
    label: "Us International Development Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12241",
    label: "Hope Center Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12242",
    label: "Leo Wives Have Your 6",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12243",
    label: "Le Mondo Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12244",
    label: "Peace Through Compassion Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12245",
    label: "Project Mend Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12246",
    label: "Lehigh Riding Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12247",
    label: "Ricochet Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12248",
    label: "Greater Success Parenting Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12249",
    label: "Horizons Delmarva Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12250",
    label: "Spiritual Maintenance Help Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12251",
    label: "Elmseed Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12252",
    label: "Salvation Multipurpose Community Outreach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12253",
    label: "Fulfilment Of Calvary Apostolic Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12254",
    label: "Joy Overflow International Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12255",
    label: "Jr Biotek Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12256",
    label: "Kane Catholic Missions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12257",
    label: "The Real Wings Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12258",
    label: "Peace-Link Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12259",
    label: "Iglesia Evangelica Biblica Luz De Vida",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12260",
    label: "Destination Abroad",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12261",
    label: "Precious House",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12262",
    label: "Chesapeake Language Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12263",
    label: "Mimadre Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12264",
    label: "For Love And Music",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12265",
    label: "Walter Reed Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12266",
    label: "Healing Rooms Of Hagerstown Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12267",
    label: "Least Of These Ministry - The Adenike Olaleye Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12268",
    label: "Sigma Alpha Epsilon Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12269",
    label: "Mil-Net Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12270",
    label: "Prince Georges Pride Lacrosse Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12271",
    label: "Mosa 7883 Americas",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12272",
    label: "Tranquil Village Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12273",
    label: "Hmshost Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12274",
    label: "Something For The Journey",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12275",
    label: "Ravens Roost -137",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12276",
    label: "New Beginnings Horse Rescue And Sanctuary",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12277",
    label: "Frederick And Pennsylvania Line Railroad Museum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12278",
    label: "Elizabeth Ejekwu Charity Organization Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12279",
    label: "Marlow Guitar International Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12280",
    label: "Deafblind Camp Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12281",
    label: "Winterset Ballroom Dance Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12282",
    label: "Curtis Historic Transportation Preservation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12283",
    label: "Black Paw Athletics Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12284",
    label: "Civic Space Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12285",
    label: "Seed For Change",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12286",
    label: "Omega Psi",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12287",
    label: "Ejemeh Okojie Md Educational Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12288",
    label: "International Academic Conference For Taekwondo",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12289",
    label: "Creative Nomads Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12290",
    label: "Christ Cornerstone Ministries International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12291",
    label: "Pax River Silver Stars Basketball Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12292",
    label: "Powerhouse Church Of God",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12293",
    label: "Inge Benevolent Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12294",
    label: "Maryland Institute Of Development",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12295",
    label: "Chinese American Society Of Nanomedicine And Nanobiotechnology",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12296",
    label: "Lionheart Sports Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12297",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12298",
    label: "Shalom Pentecostal Church Of Silver Spring",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12299",
    label: "Mental Health Liaison Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12300",
    label: "Bravo Zulu Chess Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12301",
    label: "Tech Frederick Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12302",
    label: "Oasis Christian Center Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12303",
    label: "Falconry Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12304",
    label: "Walkersville High School Safe & Sound",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12305",
    label: "Royalhouse Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12306",
    label: "Shalom Pentecostal Church Of Salisbury",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12307",
    label: "South River Elite Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12308",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12309",
    label: "Imprint Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12310",
    label: "Faith Embassy International Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12311",
    label: "Radhasoami Satsang Of Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12312",
    label: "Black Fish Advocates",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12313",
    label: "Bcauseican Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12314",
    label: "Collegium Sanctorum Angelorum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12315",
    label: "Divine Global Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12316",
    label: "Buffalo Engineering And Robotics Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12317",
    label: "Mark D Pacione Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12318",
    label: "Peter Ellis Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12319",
    label: "Transformation Christian Fellowship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12320",
    label: "Beltsville Soccer Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12321",
    label: "Single Dads Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12322",
    label: "Dunaword Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12323",
    label: "Highlight Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12324",
    label: "Third Wall Productions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12325",
    label: "Maceo H Jones Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12326",
    label: "Center For Muslim Life At Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12327",
    label: "Excellence & Ambition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12328",
    label: "Glorias Gifts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12329",
    label: "Centerpoint Church At Kent Island",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12330",
    label: "Maipn Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12331",
    label: "Church At Carmel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12332",
    label: "Collaborative Theatre",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12333",
    label: "Pharmacy Ethics Education & Resources",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12334",
    label: "Active Social Communities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12335",
    label: "Bob Kemp Lacrosse Classic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12336",
    label: "Nolan Robison Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12337",
    label: "Pta Delaware Congress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12338",
    label: "1 Dream Makers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12339",
    label: "Outreach Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12340",
    label: "Office Charity Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12341",
    label: "Stepping Stone Animal Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12342",
    label: "Human Trafficking Awareness And Advocacy Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12343",
    label: "Amateur Athletic Union Of The United States Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12344",
    label: "Cumberland Economic Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12345",
    label: "Franklin Middle School Music Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12346",
    label: "Rising Sun Spartans",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12347",
    label: "Voices Of Life Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12348",
    label: "Unity Of Faith Christian Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12349",
    label: "Tessier Team Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12350",
    label: "Disabled Veterans Fishing Outing",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12351",
    label: "Children Exploration Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12352",
    label: "Baltimore City White Knight Track Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12353",
    label: "Faith And Love Christian Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12354",
    label: "Cecil Volleyball Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12355",
    label: "No Warrior Left Behind Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12356",
    label: "Turning Pointe Dance Academy Parent Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12357",
    label: "Echo Resource Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12358",
    label: "Solid Faith Ministries & Outreach Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12359",
    label: "Technica",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12360",
    label: "Survivors Worldwide Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12361",
    label: "Living Water Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12362",
    label: "Bridging The Nations Ministries International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12363",
    label: "Our Ravens Roost 94 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12364",
    label: "Ehtc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12365",
    label: "Chris Backyard Gamehouse Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12366",
    label: "Lambe Cameroon Diabetic And Health Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12367",
    label: "Scott Smith Memorial Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12368",
    label: "Breaking Chains Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12369",
    label: "Tops Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12370",
    label: "Amazin Graces Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12371",
    label: "Settlement House Books Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12372",
    label: "Residential Housing Counseling Agency",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12373",
    label: "Victory Grace Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12374",
    label: "The Complete Player Charity Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12375",
    label: "Hope Ministries Of Delmarva",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12376",
    label: "Garvey Senior Activity Center Council Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12377",
    label: "Ernest And Meta Oppenheimer Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12378",
    label: "Children Against Molesters And Pedophiles- Champs",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12379",
    label: "Opus Concert Theatre Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12380",
    label: "African And Caribbean Immigration And Social Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12381",
    label: "Northern High Ptso Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12382",
    label: "Teaching Peace Initiative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12383",
    label: "Ambitious Single Mothers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12384",
    label: "Platinum Rollers Motorcycle Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12385",
    label: "Kass And Michael Lazerow Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12386",
    label: "We Empower Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12387",
    label: "Tecanal",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12388",
    label: "Sunga Sunga Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12389",
    label: "Smith Island United Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12390",
    label: "The Aafia Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12391",
    label: "Salisbury Arts And Entertainment District Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12392",
    label: "Talking Drum Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12393",
    label: "Colonial Dames Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12394",
    label: "Nnewi Community Organization Of Washington Metropolitan Area Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12395",
    label: "Solomon Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12396",
    label: "Calvert Aquatics Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12397",
    label: "Back To Work Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12398",
    label: "Shadow Cats Tnr Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12399",
    label: "Lioness Lions Of Aberdeen Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12400",
    label: "National Association Of Black Male Educators Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12401",
    label: "Iglesia Apostoles Y Profetas La Nueva Jerusalen",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12402",
    label: "Jsynergy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12403",
    label: "Bernard A Letourneau Foundation For The Performing Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12404",
    label: "Heroic Reins",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12405",
    label: "Helping You Grow Successfully",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12406",
    label: "Wicomico Library Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12407",
    label: "Edwin Allen High School Alumni Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12408",
    label: "Called To Virtue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12409",
    label: "Northern High School Athletic Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12410",
    label: "Sigmas For Humanity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12411",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12412",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12413",
    label: "Spread His Word Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12414",
    label: "Brandon And Chanel Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12415",
    label: "Gardens Synchronized Skating Team Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12416",
    label: "Kingdom Promise Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12417",
    label: "International Association Of Machinists & Aerospace Workers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12418",
    label: "Way Family Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12419",
    label: "Proposition Enrichment For The Next Generation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12420",
    label: "Douglass Leadership Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12421",
    label: "Rescue Mission Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12422",
    label: "Pee Wee Cares",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12423",
    label: "Bulgarian Cultural Center Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12424",
    label: "Rebuild Dominica Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12425",
    label: "Heaven Link Christian Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12426",
    label: "Dc High School Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12427",
    label: "Diamond Campaign",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12428",
    label: "Maryland Baptist Aged Home Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12429",
    label: "United For Health And Education Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12430",
    label: "Pflag Salisbury Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12431",
    label: "Urban Smart Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12432",
    label: "Trader Foundation For Maryland Burial Sites Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12433",
    label: "Teach Me Dental Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12434",
    label: "Covenant Global Ministries Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12435",
    label: "Friends Of Children Affected By Hiv-Aids In Nagaland Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12436",
    label: "Mayan Hakesef Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12437",
    label: "Harrison Pay It Forward Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12438",
    label: "Adisadel Old Boys Association North America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12439",
    label: "Canine Humane Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12440",
    label: "Wells-Mccomas Charitable And Civic Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12441",
    label: "Center For Effective Reading Instruction Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12442",
    label: "Baltimore Sister Cities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12443",
    label: "Allegany Neighbors & Citizens For Home Owners Rights Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12444",
    label: "Nurtured Palate",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12445",
    label: "Tamil Chair Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12446",
    label: "Bryan Hewitt Botti Memorial Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12447",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12448",
    label: "Womens Education Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12449",
    label: "Woman To Woman Mentoring Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12450",
    label: "Dr Rahul M Jindal Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12451",
    label: "Baltimore Community Real Estate Center Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12452",
    label: "Ilora Baptist Grammar School Alumni Association Na Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12453",
    label: "Essex Flying Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12454",
    label: "Irko Self Help Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12455",
    label: "Rev Accessability Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12456",
    label: "Double Dog Dare Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12457",
    label: "Empathy And Resilience Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12458",
    label: "Hope Springs Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12459",
    label: "St Moses Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12460",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12461",
    label: "Calvin J Li Memorial Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12462",
    label: "Ravens Roost 138",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12463",
    label: "Rainbow Place Shelter For Homeless Women Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12464",
    label: "South County Animal Rescue And Sanctuary Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12465",
    label: "St Marys Youth Flag Football League Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12466",
    label: "Douglas Eze Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12467",
    label: "Learning To Overcome Various Life Experiences Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12468",
    label: "Comfort-Sherriff Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12469",
    label: "Mision Cristiana Elohim",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12470",
    label: "Military Order Of The Cootie Of The United States",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12471",
    label: "Identity Wallet Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12472",
    label: "Affordable Housing Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12473",
    label: "Somd Spartans",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12474",
    label: "Resurrection Power Luke 241-6 And Glorious Church Eph 5 27 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12475",
    label: "Northeast Trucking Association Executives Council",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12476",
    label: "Womens Bar Association Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12477",
    label: "Racheal Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12478",
    label: "Gvcs Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12479",
    label: "Luciano Emilio Brazilian Soccer Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12480",
    label: "Maryland Reentry Resource Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12481",
    label: "Mothers Of Murdered Sons And Daughters United Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12482",
    label: "Mirzapur Ex-Cadets Association Of North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12483",
    label: "Historic Heroines",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12484",
    label: "Contemporary Explorations Of Jewish Texts And Thinkers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12485",
    label: "Iron Workers Local Union No 5S Joint Apprenticeship And Training",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12486",
    label: "Building Restoring & Aiding Veterans Everywhere",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12487",
    label: "Americas Vets Helping Vets For Life",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12488",
    label: "Monster Maker Educational & Athletic Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12489",
    label: "Project Freedom Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12490",
    label: "Evolve Community Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12491",
    label: "Deep Waters Tabernacle",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12492",
    label: "Main Street Princess Anne",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12493",
    label: "Rotary Of Kent Island Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12494",
    label: "4 Petes Sake Als Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12495",
    label: "Federated Garden Clubs Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12496",
    label: "Powerful Divas With A Purpose",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12497",
    label: "Power 52 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12498",
    label: "Hornets Athletic Booster Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12499",
    label: "Fame Fashion Art Mentoring Etiquette Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12500",
    label: "Central Maryland Youth Ballet Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12501",
    label: "The Bison Repertory Theatre Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12502",
    label: "Gamma Omicron Omega Scholarship Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12503",
    label: "A Tribute To Women Of Color Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12504",
    label: "Team Thrill Youth Sports Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12505",
    label: "Chinese American Parent Association Of Howard County Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12506",
    label: "Iglesia Pentecostes Fuente Del Cielo Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12507",
    label: "Hope International Orphanage Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12508",
    label: "St Pauls Perpetual Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12509",
    label: "Jesus Church International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12510",
    label: "St Clement I Council No 16304 Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12511",
    label: "Kidkoders",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12512",
    label: "Hyman Zolet Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12513",
    label: "Freedom Empowerment Church International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12514",
    label: "Odaa Hulle Development Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12515",
    label: "Red Iberoamericana De Apego Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12516",
    label: "Maryland Local Assistance State Team Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12517",
    label: "Plank Foundation For Entrepreneurship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12518",
    label: "Somd Tkd Booster Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12519",
    label: "4 Her Womens Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12520",
    label: "Airedale Rescue And Adoption Service Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12521",
    label: "Sakhee For You Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12522",
    label: "Womens Bar Association Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12523",
    label: "Fenton Village Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12524",
    label: "New Hope Church Of God Of Waldorf Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12525",
    label: "Michael Veader Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12526",
    label: "Baltimore Disc Golf Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12527",
    label: "Dhaka University Alumni Forum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12528",
    label: "Frederick High School Music Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12529",
    label: "Stop Drop And Play Childrens Museum",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12530",
    label: "Opened Box",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12531",
    label: "Valhalla Sailing Project Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12532",
    label: "I3S Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12533",
    label: "Tomb Of The Unknown Soldier Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12534",
    label: "Fair Play Americas",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12535",
    label: "James Hollister Wellness Foundation Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-12536", label: "Ideal", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-12537",
    label: "Safe Ride Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12538",
    label: "Country Club Swim And Dive Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12539",
    label: "Hannahs Habitat Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12540",
    label: "Mt Royal Parent Teacher Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12541",
    label: "Optiven Foundation For Peace And Development Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12542",
    label: "Betttinger-Marshall Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12543",
    label: "1St Amendment Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12544",
    label: "Krio Decendant Union Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12545",
    label: "Adventureach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12546",
    label: "Im Worth It Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12547",
    label: "Amystrong Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12548",
    label: "City Church Presbyterian",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12549",
    label: "Wounded Warriors Day On The Bay Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12550",
    label: "Church Of God Inner Man Gospel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12551",
    label: "Pta Delaware Congress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12552",
    label: "John And Karen Harrison Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12553",
    label: "Anambra State Women Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12554",
    label: "New Beginning Christian Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12555",
    label: "Harford County Climate Action",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12556",
    label: "Blackfem Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12557",
    label: "350 Montgomery County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12558",
    label: "Iglesia Pentecostes Camino De Salvacion De Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12559",
    label: "Glory Forever Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12560",
    label: "Owings Mills Community Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12561",
    label: "Frederick County Career Firefighters Benevolent Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12562",
    label: "Wings Of Love Kuwait Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12563",
    label: "Reach One To Teach One Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12564",
    label: "Maryland Thompson Collectors Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12565",
    label: "Cambridge Sailing Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12566",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12567",
    label: "Sigma Alpha Epsilon Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12568",
    label: "Trace Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12569",
    label: "Advanced Therapeutic Health Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12570",
    label: "Chi Phi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12571",
    label: "Baltimore Symphony Musicians Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12572",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12573",
    label: "Food For Friends Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12574",
    label: "South Carolina State University National Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12575",
    label: "International Federation For Emergency Medicine Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12576",
    label: "Dick And Jody Vilardo Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12577",
    label: "Girls For Girls Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12578",
    label: "Delmarva Fisheries Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12579",
    label: "Anne Arundel Watermens Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12580",
    label: "Ammiel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12581",
    label: "Well Women Empowering Loving Lives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12582",
    label: "The Safe Place Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12583",
    label: "Fighting For Kaiden Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12584",
    label: "Pg County Police Department District Iv Citizens Advisory Coun",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12585",
    label: "Boundless Expectations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12586",
    label: "Bury Me Muslim",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12587",
    label: "Redeemed Christian Church Of God God S Inheritance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12588",
    label: "Project Out Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12589",
    label: "Friends Of Dcfc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12590",
    label: "Sligo Creek Cooperative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12591",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12592",
    label: "Askids Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12593",
    label: "Kent Island High School Band Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12594",
    label: "Mid-Atlantic Quality Assurance Workshop Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12595",
    label: "Lechic Academy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12596",
    label: "Morgan State University Alumni Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12597",
    label: "Friends Of Stevenson Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12598",
    label: "Liver Mommas & Families Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12599",
    label: "Ethiopian Childrens Appeal",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12600",
    label: "Gideon- Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12601",
    label: "Clearshark Charitable Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12602",
    label: "Onepuente Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12603",
    label: "Beyond The Game Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12604",
    label: "Journey To Josiah Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12605",
    label: "Morgan State University Alumni Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12606",
    label: "Char Hope Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12607",
    label: "Girlfriend Therapy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12608",
    label: "Charaka Research Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12609",
    label: "Maryland Theatre Education Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12610",
    label: "The Cd Fleming Jr Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12611",
    label: "Love Blanket Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12612",
    label: "Changing Lives Thailand",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12613",
    label: "International Institute Of Global Resilience Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12614",
    label: "Early Birds Playgroup",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12615",
    label: "Invisible Hand Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12616",
    label: "Combat Veterans Of America Motorcycle Club Smd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12617",
    label: "Phi Sigma Sigma Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12618",
    label: "St Thekla Antiochian Orthodox Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12619",
    label: "Heart For The Villagers Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12620",
    label: "Off My Block",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12621",
    label: "Vietnamese Americans Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12622",
    label: "Phi Sigma Sigma Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12623",
    label: "Living Legends Awards For Service To Humanity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12624",
    label: "Apostle Phillips Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12625",
    label: "Open Works Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12626",
    label: "True Worshippers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12627",
    label: "Game Plan Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12628",
    label: "Umuokpu Obowo Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12629",
    label: "Taps For Veterans",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12630",
    label: "Friends Of Hampden School No 55 Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12631",
    label: "Usaf Police Alumni Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12632",
    label: "Scientific Minds Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12633",
    label: "Your Future Leaders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12634",
    label: "Brazilian United Soccer Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12635",
    label: "Parent Booster Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12636",
    label: "Global Film Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12637",
    label: "Jiva Daya Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12638",
    label: "Washington Spanish Bilingual Seventh-Day Adventist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12639",
    label: "Maryland Dance Education Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12640",
    label: "Prince Georges County Police Athletic League Incorporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12641",
    label: "Matt Dillon Memorial Golf Tournament Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12642",
    label: "Project Give Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12643",
    label: "Sophia M Longo Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12644",
    label: "Tuba The Uganda-Baltimore Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12645",
    label: "Amazing Love Church Ministry Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12646",
    label: "Black Girls Vote Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12647",
    label: "Xpx Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12648",
    label: "Havre De Grace Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12649",
    label: "Good Gardening Videos Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12650",
    label: "Center For Innovation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12651",
    label: "Green Burial Association Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12652",
    label: "Women Walking In Their Own Shoes Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12653",
    label: "Full Circle Dance Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12654",
    label: "Fr Mobility Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12655",
    label: "Children And Youth Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12656",
    label: "New Chances",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12657",
    label: "Wrapunzel Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12658",
    label: "Society For Claustrum Research Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12659",
    label: "Sankofa Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12660",
    label: "Community Unity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12661",
    label: "American Friends Of The Celtic Saints Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12662",
    label: "Major Victory Promise Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12663",
    label: "Washington Hill Community Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12664",
    label: "Future 4 Kids Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12665",
    label: "Saving Lives Initiatives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12666",
    label: "St Augustine And St Josephs Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12667",
    label: "John Hopkins University",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12668",
    label: "Friends In Solidarity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12669",
    label: "Kiwuwa Achievement Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12670",
    label: "River City Baptist Church Of Perryville Md Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12671",
    label: "Knowing Thinking Giving And Growing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12672",
    label: "Amc World Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12673",
    label: "Single Vine Creator Of Noble Singles Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12674",
    label: "Bridging Hands Camps",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12675",
    label: "Restoration Farm Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12676",
    label: "United Chian Society Of Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12677",
    label: "Fundacion Caly Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12678",
    label: "Child Development International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12679",
    label: "Ywam Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12680",
    label: "Embassy Theatre Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12681",
    label: "Center For Dynamic Community Governance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12682",
    label: "Lev Avos Parenting Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12683",
    label: "Charity Connect",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12684",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12685",
    label: "Association Of Professors Of Gynecology & Obstetrics",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12686",
    label: "Charles T Bauer Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12687",
    label: "Amna Tr Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12688",
    label: "St Anns Donor Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12689",
    label: "Diamond Mind Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12690",
    label: "I A M Representative Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12691",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12692",
    label: "National Staff Association For The Improvement Of Instruction",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12693",
    label: "Kansas Society Of Clinical Oncology",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12694",
    label: "317 Th Veterans Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12695",
    label: "Hagerstown Fairgrounds Softball Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12696",
    label: "Maryland Association Of Campgrounds Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12697",
    label: "Christian Liberty International Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12698",
    label: "Washington Society Of Landscape Painters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12699",
    label: "Little Theatre Co Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12700",
    label: "Grenada International Uplift Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12701",
    label: "Sisters4Sisters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12702",
    label: "Refuge Temple School Of Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12703",
    label: "Prince Georges County Citizens Police Academy Alumni Assoc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12704",
    label: "American Music Therapy Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12705",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12706",
    label: "Marriage And Relationship Education Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12707",
    label: "Transport Workers Union Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12708",
    label: "Cloverleaf Standardbred Owners Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12709",
    label: "Bill Raskob Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12710",
    label: "Maryland City Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12711",
    label: "Phi Alpha Delta International Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12712",
    label: "National Marine Electronics Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12713",
    label: "Association Of Community Cancer Centers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12714",
    label: "American Association Of State Compensation Insurance Funds",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12715",
    label: "Hadassah The Womens Zionist Organization Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12716",
    label: "Solid Rock Pentecostal Church Of Christ Disciple Of Christ Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12717",
    label: "Lighthouse Baptist Church Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12718",
    label: "Sampson High School Alumni Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12719",
    label: "Carroll County Education Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12720",
    label: "Arundel Lodge Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12721",
    label: "Salisbury Jaycees Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12722",
    label: "Teachers Association Of Somerset County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12723",
    label: "Fraternal Order Of Police",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12724",
    label: "American-Tunisian Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12725",
    label: "Pioneer Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12726",
    label: "Inteleos Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12727",
    label: "Pediatric Nursing Certification Board Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12728",
    label: "American Institute Of Aeronautics And Astronautics",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12729",
    label: "First Virginia Regiment Of The Continental Line",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12730",
    label: "Air Force Sergeants Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12731",
    label: "Riva Volunteer Fire Department Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12732",
    label: "Ulmstead Swim Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12733",
    label: "Institute Of Real Estate Management Of The Natl Assn Of Realtors",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12734",
    label: "League Of Women Voters Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12735",
    label: "Messiah Lutheran Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12736",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12737",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12738",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12739",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12740",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12741",
    label: "League Of Historic American Theatres",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12742",
    label: "Smoloskyp Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12743",
    label: "St Marys Housing Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12744",
    label: "Road Runners Club Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12745",
    label: "American Hiking Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12746",
    label: "Air Force Sergeants Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12747",
    label: "Air Force Sergeants Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12748",
    label: "Deal Island-Chance Fire Co Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12749",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12750",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12751",
    label: "Roman Catholic Archdiocese Of Baltimore Div Of Elem & Secondary",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12752",
    label: "Delta Sigma Theta Sorority",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12753",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12754",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12755",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12756",
    label: "Aglow International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12757",
    label: "Disability Rights Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12758",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12759",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12760",
    label: "Second Saint Marys Housing Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12761",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12762",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12763",
    label: "Phi Alpha Delta Law Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12764",
    label: "Delmarva Walk To Emmaus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12765",
    label: "Bioelectromagnetics Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12766",
    label: "Felburn Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12767",
    label: "Free And Accepted Masons Of The District Of Columbia",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12768",
    label: "Accreditation Board For Engineering And Technology Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12769",
    label: "Huyetts Mennonite School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12770",
    label: "Potomac Squash Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12771",
    label: "Berea In Korea Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12772",
    label: "Society Of Fbi Alumni",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12773",
    label: "American College Of Real Estate Lawyers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12774",
    label: "Va Kappa Alumni Corp Of Spe",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12775",
    label: "Air Force Sergeants Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12776",
    label: "Korean Hosptal Mission",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12777",
    label: "American Youth Hostels Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12778",
    label: "American Federation Of Labor & Congress Of Industrial Orgs",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12779",
    label: "Pascal Seniors Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12780",
    label: "Prayerline Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12781",
    label: "Upper Bay Counseling & Support Svcs Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12782",
    label: "Gold Wing Road Riders Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12783",
    label: "Delaware Maryland Agribusiness Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12784",
    label: "Ecological Democracy Institute Of North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12785",
    label: "Fbi National Academy Associates Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12786",
    label: "Hacks Point Community Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12787",
    label: "Motorcycle Accident Victims Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12788",
    label: "Mar-Del Watermelon Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12789",
    label: "Sigma Alpha Epsilon Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12790",
    label: "Friends Of Mount Harmon Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12791",
    label: "Faith Praise Temple Of Joy Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12792",
    label: "Hellenic Electronic Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12793",
    label: "Walking In His Word Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12794",
    label: "Smash Molecule Mmr Conference",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12795",
    label: "Potters House Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12796",
    label: "Leadership Council On Child Abuse & Interpersonal Violence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12797",
    label: "First Mystery Church Of God Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12798",
    label: "The No Plain Jane Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12799",
    label: "Maryland Lacrosse Club Ii Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12800",
    label: "Rock Of Salvation Evangelical Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12801",
    label: "Camp Possibilities Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12802",
    label: "Revelation Universal Evangelical Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12803",
    label: "Affiliated Associates Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12804",
    label: "Schnauzer Rescue Of The Mid-Atlantic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12805",
    label: "Cornerstone Praise Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12806",
    label: "Meor Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12807",
    label: "Jazz Academy Of Music Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12808",
    label: "Social Welfare Activities-Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12809",
    label: "Southern Maryland Sabres Hockey Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12810",
    label: "R Wayne Feezer Memorial Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12811",
    label: "Tamias Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12812",
    label: "First African Methodist Episcopal Church Of Gaithersburg",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12813",
    label: "Kingdom Church Of Jesus Christ Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12814",
    label: "Emmanuel Baptist Church Of Gambrills",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12815",
    label: "New Freedom Ecumenical Ministries International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12816",
    label: "Garrett Sports Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12817",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12818",
    label: "Tumaini Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12819",
    label: "Phi Sigma Sigma",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12820",
    label: "Phi Sigma Sigma",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12821",
    label: "Life Church And Ministries Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12822",
    label: "Many Hands Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12823",
    label: "Aspiring To Serve Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12824",
    label: "Asian Pacific American Internal Revenue Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12825",
    label: "Redeemed Christian Church Of God High Tower Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12826",
    label: "Family Builders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12827",
    label: "Phi Alpha Delta Law Fratirnity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12828",
    label: "Phi Alpha Delta Law Fratirnity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12829",
    label: "Dans Mt Park Assoc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12830",
    label: "Redeemed Christian Church Of God Jesus Palace",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12831",
    label: "Redeemed Christian Church Of God Christ Chapel",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12832",
    label: "Salt And Light Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12833",
    label: "Baltimore And Chesapeake Steamboat Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12834",
    label: "Morrell Park Community Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12835",
    label: "Cross-Net International Fellowship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12836",
    label: "Passion For Learning Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12837",
    label: "Marbury Church Of God",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12838",
    label: "Northern Star Hunter Sailing Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12839",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12840",
    label: "T C Tillman Scholarship Fund Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12841",
    label: "Maryland Beverage Association C D C Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12842",
    label: "Friends Of Usu",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12843",
    label: "Squashempower Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12844",
    label: "El Shaddai Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12845",
    label: "Open Gate Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12846",
    label: "Friends Of The Maryland State Archives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12847",
    label: "Chesapeake Shepard & K-9 Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12848",
    label: "Agape Love Deaf International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12849",
    label: "Iglesia Vision Hechos 20 20 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12850",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12851",
    label: "Servant Of God Mission",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12852",
    label: "Deaf Calvary Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12853",
    label: "Spirit Of Liberty Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12854",
    label: "Oasis Of Living Waters Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12855",
    label: "Christ Throne Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12856",
    label: "Maryland State Steering Committee For Occupational Therapy &",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12857",
    label: "North Star Special Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12858",
    label: "Sweet Hope Village Community Development Corporation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12859",
    label: "Voiture Nationale La Societe Des 40 Hommes Et 8 Chevaux",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12860",
    label: "Bible Life Church Minstries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12861",
    label: "Wakirike-Washington Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12862",
    label: "Friends Of Poly Western",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12863",
    label: "Hope For Tomorrow Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12864",
    label: "Amalgamated Transit Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12865",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12866",
    label: "Foundation For Autism Support And Training Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12867",
    label: "Home Ports",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12868",
    label:
      "International Lyme And Associated Diseases Educational Foundation In",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12869",
    label: "Point Lookout Lighthouse Preservation Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12870",
    label: "The Word Of Restoration And Deliverance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12871",
    label: "New Creation Pentecostal Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12872",
    label: "Invest N Youth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12873",
    label: "Community Church Outreach Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12874",
    label: "Pta Delaware Congress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12875",
    label: "Waterside Homes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12876",
    label: "Amazing Theatre Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12877",
    label: "African Wellness Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12878",
    label: "Victory In Praise Fellowship Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12879",
    label: "The Fountain Of Living Water Ministry",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12880",
    label: "Queens School Enugu Alumni Association Inc Mid Atlantic Regio",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12881",
    label: "Mid Atlantic Youth Ballet And Center For Dance Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12882",
    label: "Humanity Tv Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12883",
    label: "Washington Association For Korean Schools",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12884",
    label: "Les Gemmes Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12885",
    label: "Iglesia Voz De La Esperanza Mision Evangelica Aposento Alto Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12886",
    label: "Fundacion Jojma Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12887",
    label: "Saint Peter Claver Seminary Okpala Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12888",
    label: "International Association Of Fire Fighters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12889",
    label: "New Life Fellowship Of Baltimore Md",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12890",
    label: "Greater Faith Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12891",
    label: "Centennial Lane Band And Orchestra Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12892",
    label: "American Federation Of State County & Municipal Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12893",
    label: "Love And Restoration Outreach Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12894",
    label: "Chi Eta Phi Education Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12895",
    label: "Chaverim Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12896",
    label: "Holly Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12897",
    label: "Shirat Hanefesh",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12898",
    label: "Asbury Community Association Incorporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12899",
    label: "Delaware State Grange Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12900",
    label: "National Society Of The Daughters Of The American Revolution",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12901",
    label: "Cecil County Breeders Fair Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12902",
    label: "American Federation Of Government Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12903",
    label: "Bible Life Christian Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12904",
    label: "Healthcare Information And Management Systems Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12905",
    label: "Church Of The Redeemed Of The Lord",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12906",
    label: "Hillandale Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12907",
    label: "Lansdowne Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12908",
    label: "St James P E Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12909",
    label: "New Life Global Baptist Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12910",
    label: "Seventh District Rescue Squad Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12911",
    label: "Concordia Lutheran Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12912",
    label: "Chesapeake Wildlife Heritage Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12913",
    label: "Enterprise Community Loan Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12914",
    label: "Chesapeake Bay Radio Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12915",
    label: "Hadassah The Womens Zionist Organization Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12916",
    label: "Jewish Community Relations Council Of Greater Washington",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12917",
    label: "Travelers Protective Association Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12918",
    label: "Abingdon Fire Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12919",
    label: "Ali Ghan Shriners Holding Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12920",
    label: "Alsatia Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12921",
    label: "Alumni Association Of The Union Memorial Hospital School Nursing",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12922",
    label: "The American Legion Department Of Maryland Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12923",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12924",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12925",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12926",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12927",
    label: "Ali Ghan Shriners",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12928",
    label:
      "Ancient & Accepted Scottish Rite Of Free Masonary Sthrn Jrsdctn Of Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12929",
    label: "Improved Benevolent & Protective Order Of Elks Of The World",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12930",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12931",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12932",
    label: "Ancient Free & Accepted Masons Of Maryland Grand Lodge",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12933",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12934",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12935",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12936",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12937",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12938",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12939",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12940",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12941",
    label: "Royal Arch Masons Of Maryland Grand Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12942",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12943",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12944",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12945",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12946",
    label: "Millington Lodge 166 Af&Am Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12947",
    label: "Annapolis Moose Lodge 296",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12948",
    label: "Annapolis Yacht Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12949",
    label: "Laborers District Council Trust Fund 2",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12950",
    label: "Baker Cemetery Company Of Harford County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12951",
    label: "Baltimore Country Club Of Baltimore City",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12952",
    label: "Baltimore Moose Legion No 10",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12953",
    label: "Post Office Credit Union Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12954",
    label: "Destinations Credit Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12955",
    label: "Baltimore Yacht Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12956",
    label: "Bar Association Of Baltimore City",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12957",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12958",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12959",
    label: "Better Business Bureau Of Greater Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12960",
    label: "Black Hawk Tribe 131",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12961",
    label: "Blue Ridge Cemetery Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12962",
    label: "Bohemian Cemetery Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12963",
    label: "Boumi Shriners",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12964",
    label: "Brotherhood Of Locomotive Engineers And Trainmen",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12965",
    label: "International Union Of Painters And Allied Trades",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12966",
    label: "Bryn Mawr School For Girls Of Baltimore City",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12967",
    label: "Building Congress & Exchange Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12968",
    label: "Building Owners And Managers Association Of Greater Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12969",
    label: "American Federation Of Government Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12970",
    label: "Burning Tree Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12971",
    label: "Dorchester Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12972",
    label: "Maryland 4-H Club Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12973",
    label: "Internatl Brotherhood Of Teamsters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12974",
    label: "Chesapeake Bay Yacht Club Of Talbot Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12975",
    label: "Choptank Electric Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12976",
    label: "Citizens Truck Company Of Frederick City",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12977",
    label: "American Association Of University Women",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12978",
    label: "Columbia Country Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12979",
    label: "Columbus Club Of Overlea Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12980",
    label: "Cresaptown Volunteer Fire Department Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12981",
    label: "Municipal Employees Credit Union Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12982",
    label: "Allegany County Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12983",
    label: "Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12984",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12985",
    label: "Edgemoor Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12986",
    label: "The Elkridge Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12987",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12988",
    label: "Engineering Society Of Baltimore",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12989",
    label: "General Council On Finance & Admins Of The United Methodist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12990",
    label:
      "International Association Of Brdge Strctrl & Ornamental Iron Workers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12991",
    label: "Fountain Head Country Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12992",
    label: "International Longshoremens Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12993",
    label: "14 W Hamilton Street Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12994",
    label: "Fraternal Order Of Eagles",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12995",
    label: "Chamber Of Commerce Of Frederick County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12996",
    label: "Frederick County Agricultural Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12997",
    label: "Frederick Elks Home Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12998",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-12999",
    label: "Inwood House Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13000",
    label: "Gibson Island Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13001",
    label: "Glen Burnie Improvement Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13002",
    label: "Goodfellowship Social Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13003",
    label: "Green Mount Cemetery Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13004",
    label: "Washington County Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13005",
    label: "Hamilton Street Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13006",
    label: "Harbor Club Of The Eastern Shore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13007",
    label: "Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13008",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13009",
    label: "Hill Crest Cemetery Inc Caroline County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13010",
    label: "Hillendale Country Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13011",
    label: "Independent Hose Company-1",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13012",
    label: "Independent Order Of Odd Fellows",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13013",
    label: "International Longshoremens Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13014",
    label: "International Union Of Elevator Constructors",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13015",
    label: "International Union Of Operating Engineers Local 37",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13016",
    label: "Ionic Building Company Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13017",
    label: "Jobs Daughters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13018",
    label: "Johns Hopkins Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13019",
    label: "Junior Fire Company No 2 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13020",
    label: "State Council Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13021",
    label: "Knights Templar Of The United States Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13022",
    label: "Knights Templar Of The United States Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13023",
    label: "Knights Templar Of The United States Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13024",
    label: "Leland Memorial Hospital Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13025",
    label: "Lhirondelle Club Of Ruxton Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13026",
    label: "Lithuanian Hall Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13027",
    label: "Bakery Confectionery Tobacco Workers And Grain Millers Intl Uni",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13028",
    label: "Frostburg Lodge No 348 Loyal Order Of Moose",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13029",
    label: "Frederick Lodge No 371 Loyal Order Of Moose",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13030",
    label: "Westminister Lodge No 1381",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13031",
    label: "Manor Country Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13032",
    label: "The Maryland Classified Employees Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13033",
    label: "Maryland Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13034",
    label: "Maryland Farm Bureau Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13035",
    label: "Maryland Historical Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13036",
    label: "Maryland Motor Truck Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13037",
    label: "Maryland State Funeral Directors Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13038",
    label: "Maryland Nurses Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13039",
    label: "Maryland Yacht Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13040",
    label: "Masonic Temple Trustees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13041",
    label: "Medical & Chirurgical Faculty Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13042",
    label: "Midland Fire Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13043",
    label: "Miles River Yacht Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13044",
    label: "Montgomery Sycamore Island Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13045",
    label: "Mid-Atlantic Nato National Association Of Theatre Owners Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13046",
    label: "The Mount Airy Volunteer Fire Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13047",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13048",
    label: "Mount Olivet Cemetery",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13049",
    label: "Mount Vernon Club Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13050",
    label: "International Alliance Theatrical Stage Employees & Moving Picture",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13051",
    label: "American Federation Of Musicians Of The Us & Canada",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13052",
    label: "International Brotherhood Of Teamsters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13053",
    label: "North American Rod & Gun Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13054",
    label: "North East Fire Co Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13055",
    label: "Oak Lawn Cemetery Of Baltimore County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13056",
    label: "Communication Workers Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13057",
    label: "Order Of The Eastern Star Of Maryland Grand Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13058",
    label: "Civista Medical Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13059",
    label: "Point Breeze Credit Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13060",
    label: "Polish Home Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13061",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13062",
    label: "Presbyterian Eye Ear & Throat Charity Hospital Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13063",
    label: "Greater Baltimore Board Of Realtors Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13064",
    label: "Restaurant Assn Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13065",
    label: "Rockville Volunteer Fire Dept Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13066",
    label: "Roland Park Roads And Maintenance Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13067",
    label: "Rose Bank Cemetery Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13068",
    label: "Rose Hill Cemetery Of Hagerstown",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13069",
    label: "Rotary International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13070",
    label: "Royal & Select Masters Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13071",
    label: "St Johns Cemetery Of Howard County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13072",
    label: "St Marys Cemetery Association Of Carroll County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13073",
    label: "Salisbury Area Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13074",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13075",
    label: "International Union United Auto Aerospace & Agricultural Workers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13076",
    label: "Sigma Nu Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13077",
    label: "Silver Spring Volunteer Fire Dept Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13078",
    label: "Sinai Hospital Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13079",
    label: "Laborers International Union Of North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13080",
    label: "Harbor Hospital Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13081",
    label: "Southern Maryland Electric Cooperative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13082",
    label: "Sparrows Point Country Club Inc Wise Avenue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13083",
    label: "Stoneleigh Community Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13084",
    label: "St Peters Libertytown Roman Catholic Congregation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13085",
    label: "The Suburban Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13086",
    label: "Takoma Park Volunteer Fire Department Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13087",
    label: "Talbot Country Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13088",
    label: "Tall Cedars Of Lebanon Of North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13089",
    label: "Tall Cedars Of Lebanon Of North America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13090",
    label: "International Brotherhood Of Teamsters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13091",
    label: "Amalgamated Transit Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13092",
    label: "International Brotherhood Of Electrical Workers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13093",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13095",
    label: "Tred Avon Yacht Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13096",
    label: "Internatl Brotherhood Of Teamsters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13097",
    label: "International Union United Auto Aerospace & Agricultural Workers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13098",
    label: "Printing & Graphics Association Midatlantic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13099",
    label: "14E-Cwa Afl-Cio Local 75 Furniture Workers Council",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13100",
    label: "United Hebrew Cemetery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13101",
    label: "United Steam Fire Engine Co-3",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13102",
    label: "Crisfield Fire Department Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13103",
    label: "International Brotherhood Of Teamsters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13104",
    label: "Westminster Fire Engine And Hose Company No 1 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13105",
    label: "Brunswick Volunteer Fire Company Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13106",
    label: "Womans Christian Temperance Union Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13107",
    label: "Womans Club Of Chevy Chase Md Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13108",
    label: "Womans Club Of Roland Park",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13109",
    label: "Womens Civic League Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13110",
    label: "Womens Club At Hagerstown Md Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13111",
    label: "Woodholme Country Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13112",
    label: "Woodmont Country Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13113",
    label: "Frederick County Farm Bureau",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13114",
    label: "Elkton Lodge No 851 Loyal Order Of Moose",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13115",
    label: "Corriganville Volunteer Fire Dept Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13116",
    label: "Humane Society Of Washington County Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13117",
    label: "Federal Employees Activities Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13118",
    label: "Cambridge Yacht Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13119",
    label: "Trinity Evangelical Lutheran Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13120",
    label: "Maryland Pharmaceutical Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13121",
    label: "Bel Air Volunteer Fire Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13122",
    label: "Hampstead Volunteer Fire Engine And Hose Company-1",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13123",
    label: "Naval Academy Golf Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13124",
    label: "League Of Women Voters Of Baltimore City Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13125",
    label: "Arbutus Volunteer Fire Dept Of Baltimore County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13126",
    label: "Bethesda Chevy Chase Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13127",
    label: "Sherwood Forest Club Boys And Girls Summer Camp Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13128",
    label: "Barton Hose Company No 1 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13129",
    label: "United States Naval Academy Alumni Assoc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13130",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13131",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13132",
    label: "Maryland Nurses Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13133",
    label: "Open Bible Christian Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13134",
    label: "American Legion Post 0017",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13135",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13136",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13137",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13138",
    label: "Steamship Trade Association Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13139",
    label: "Cumberland Outdoor Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13140",
    label: "Shaft Volunteer Fire Co",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13141",
    label: "Federation Of Independent Salaried Unions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13142",
    label: "Stronghold Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13143",
    label: "Bush River Yacht Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13144",
    label: "Glenbrook Nursery School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13145",
    label: "Maryland Delaware And District Of Columbia Elks Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13146",
    label: "Potomac Hunt Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13147",
    label: "Camp Airy & Camp Louise Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13148",
    label: "Takoma Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13149",
    label: "Caroline County Farm Bureau Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13150",
    label: "Comm Officers Assn Of The United States Public Health Serv Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13151",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13152",
    label: "The American Legion Department Of Maryland Carroll Post No 31 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13153",
    label: "Ancient Free & Accepted Masons Of Maryland",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13154",
    label: "Bethesda Country Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13155",
    label: "Denton Cemetery Association Of Caroline County",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13156",
    label: "American Legion Post 0121 Francis X Elder Post",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13157",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13158",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13159",
    label: "United Hebrew Cemetery Corp Inc Of Rosedale",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13160",
    label: "United Food And Commercial Workers International Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13161",
    label: "Potomac Fire Company No 2 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13162",
    label: "Star K Certification Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13163",
    label: "Sylvan Shores Services Co Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13164",
    label: "Charm City East Lodge 70 Loyal Order Of Moose Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13165",
    label: "Washington County Farm Bureau Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13166",
    label: "North East River Yacht Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13167",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13168",
    label: "American Legion Auxiliary",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13169",
    label: "Humane Society Of Harford County Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13170",
    label: "International Brotherhood Of Electrical Workers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13171",
    label: "Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13172",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13173",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13174",
    label: "Lavale Volunteer Fire Department Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13175",
    label: "Amvets",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13176",
    label: "Molleville Farm Post No 467 Veteran S Of Foreign Wars Of The Us",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13177",
    label: "Frederick County Fish & Game Protective Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13178",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13179",
    label: "American Legion Dundalk Post No 38 Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13180",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13181",
    label: "Maryland Charter Of The Associated General Contractors Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13182",
    label: "Dorchester Lodge Of Elks No 223 Ibpoe Of W Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13183",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13184",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13185",
    label: "Col Harry L Cooper Post No 160 Veterans Of Foreign Wars Of The",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13186",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13187",
    label: "General Federation Of Womens Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13188",
    label: "Chimes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13189",
    label: "The Redeemed Christian Church Of God Mt Zion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13190",
    label: "Steamship Trade Assn Of Baltimore Intl Longshoremens Assn Ben Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13191",
    label: "Fraternal Order Of Eagles",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13192",
    label: "Maryland Retailers Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13193",
    label: "American Legion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13194",
    label: "Benevolent & Protective Order Of Elks Of The Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13195",
    label: "Solomons Island Yacht Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13196",
    label: "Independent Insurance Agents Of Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13197",
    label: "Frostburg Memorial Park Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13198",
    label: "Maryland Horse Breeders Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13199",
    label: "Kensington Volunteer Fire Department Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13200",
    label:
      "United Association Of Journeymen & Apprentices Of The Plumbing & Pipe",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13201",
    label: "Hawthorne Country Club Of Charles County Maryland Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13202",
    label: "Linstead Community Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13203",
    label: "Amvets",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13204",
    label: "National Electrical Contractors Association Inc Maryland Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13205",
    label: "Bethesda Chevy Chase Rescue Squad Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13206",
    label: "Maryland School For The Blind",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13207",
    label: "Salisbury Lodge No 654 Loyal Order Of Moose",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13208",
    label: "Alpha Epsilon Pi Fraternity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13209",
    label: "Alpha Xi Delta Building Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13210",
    label: "Marlborough Hunt Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13211",
    label: "Maryland State Fair And Agricultural Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13212",
    label: "Greenbelt Federal Credit Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13213",
    label: "Sacred Hearted Home Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13214",
    label: "Saint Andrews Episcopal Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13215",
    label: "St Anns Catholic Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13216",
    label: "St Anthonys Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13217",
    label: "St Augustine Catholic Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13218",
    label: "St Bartholomews Episcopal Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13219",
    label: "St Casimirs Catholic Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13220",
    label: "St Cecilias Catholic Ch",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13221",
    label: "St Hughs Catholic Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13222",
    label: "Saint James School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13223",
    label: "St Jane Frances School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13224",
    label: "St Jerome Roman Catholic Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13225",
    label: "St Johns College",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13226",
    label: "St John The Evangelist Church And School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13227",
    label: "Divine Mercy Roman Catholic Congregation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13228",
    label: "St Michaels Catholic Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13229",
    label: "St Josephs Passionist Retreat Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13230",
    label: "St Josephs Society Josephite Fathers United States Catholic Con",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13231",
    label: "St Louis Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13232",
    label: "St Martin Of Tours Parish And School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13233",
    label: "St Mary Star Of The Sea Roman Catholic Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13234",
    label: "St Mary Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13235",
    label: "Saint Mary Of The Assumption",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13236",
    label: "Saint Marys Catholic Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13237",
    label: "Samuel Ready Scholarships Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13238",
    label: "The Boys School Of Saint Pauls Parish Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13239",
    label: "Severn School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13240",
    label: "School Sisters Of Notre Dame In The City Of Baltimore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13241",
    label: "Holy Angels Sacred Heart School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13242",
    label: "Roman Catholic Congregation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13243",
    label: "Mt Washington Pediatric Hospital Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13244",
    label: "Harford Memorial Hospital Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13245",
    label: "Keswick Multi Care Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13246",
    label: "Home For The Aged Of Frederick City",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13247",
    label: "The Wesley Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13248",
    label: "Corporation Of St Timothys School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13249",
    label: "Star Spangled Banner Flag House Association Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-13250",
    label: "Our Lady Of The Mountains Roman Catholic Congregation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6333",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6334",
    label: "Arc Of Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6335",
    label: "Knights Of Columbus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6336",
    label: "Aminda Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6337",
    label: "Race4Warriors Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6338",
    label: "Central Delaware Housing Collaborative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6339",
    label: "Aster Wellness Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6340",
    label: "Heroes Self Defense Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6341",
    label: "Precision Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6342",
    label: "Maud And Burton Goldfield Family Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6343",
    label: "David D Dodge Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6344",
    label: "Connecting Families 4 Justice",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6345",
    label: "Frauenloop Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6346",
    label: "Kaliski Fichtl Innovation Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6347",
    label: "Aster Impact Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6348",
    label: "Teal Society Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6349",
    label: "Jesus Christ Covenant Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6350",
    label: "Carlos Hodge Christmas Wish Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6351",
    label: "Alternative Housing 4 Humans & Animals Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6352",
    label: "135 Delaware Families Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6353",
    label: "Cornwall College Old Boys Association - Mid Atlantic",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6354",
    label: "Cape Henlopen Baseball Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6355",
    label: "Pursuit For Peace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6356",
    label: "Hope Academy Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6357",
    label: "Bryan Allen Stevenson School Of Excellence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6358",
    label: "Dual School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6359",
    label: "Patrick G Bromley Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6360",
    label: "Cape Henlopen Instrumental Music Boosters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6361",
    label: "Dow Kim Family Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6362",
    label: "Well Empowerment Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6363",
    label: "Agilent Community Outreach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6364",
    label: "Innocence Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6365",
    label: "Career Ready Education Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6366",
    label: "Scott And Jessica Mcclintock Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6367",
    label: "Friends Of First State Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6368",
    label: "Amateur Athletic Union Of The United States Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6369",
    label: "Pop Warner Harrington Little Spartans",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6370",
    label: "Chalkboard Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6371",
    label: "Delaware Hearing Connection",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6372",
    label: "Andreas And Shirley Kramvis Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6373",
    label: "Psquared Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6374",
    label: "Leep - Labor Economic Education Empowerment",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6375",
    label: "Wisdom On The Wall",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6376",
    label: "Word Of Power Global Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6377",
    label: "Women Of Value Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6378",
    label: "Walnut Grove Coop Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6379",
    label: "A Veterans Dream Rons Brigade",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6380",
    label: "Real Life Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6381",
    label: "A B Productions Creative Youth Resource Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6382",
    label: "Foundation Source Charitable Gift Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6383",
    label: "Envision Frankford",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6384",
    label: "Praise Power Celebration",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6385",
    label: "Christiana Campus Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6386",
    label: "Bing And Rachel Zhang Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6387",
    label: "Jennifer And Jonathan Blum Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6388",
    label: "Sorensen Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6389",
    label: "Global Echo Litigation Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6390",
    label: "House Of Mercy Transformation Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6391",
    label: "Horizon Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6392",
    label: "Transformation Worship Centre",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6393",
    label: "Cheyla S Rescue Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6394",
    label: "The Orvis Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6395",
    label: "David Wallace Smith And Joan Stowell Smith Charitable Foundatio",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6396",
    label: "Guardian Of Defenders Memorial Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6397",
    label: "Sandgaard Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6398",
    label: "Louis Lou Ann And Melissa Poynter Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6399",
    label: "Delaware State Education Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6400",
    label: "Dharma Karma Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6401",
    label: "Sussex County Volunteer Fire Chiefs Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6402",
    label: "Sylvan H Kline Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6403",
    label: "Institute For Human Relations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6404",
    label: "Association Of Bioimaging North America Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6405",
    label: "Community Empowerment Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6406",
    label: "Lillehei Nogawa Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6407",
    label: "Psi Chi The International Honor Society In Psychology",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6408",
    label: "Lillian Marie Kelley Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6409",
    label: "The Circles Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6410",
    label: "Brothers Brunch Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6411",
    label: "Caraccio Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6412",
    label: "A Mothers Love Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6413",
    label: "Innovate My World Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6414",
    label: "In Christ Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6415",
    label: "St Matthews Church Ncc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6416",
    label: "Friends Of Santa Maria Orphanage Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6417",
    label: "Destiny Calling Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6418",
    label: "Case Management Society Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6419",
    label: "Hope On Deck Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6420",
    label: "The Karuna Charitable Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6421",
    label: "Das Leben Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6422",
    label: "Peggy And Eric Johnson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6423",
    label: "Digital Missions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6424",
    label: "Moms On A Mission Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6425",
    label: "Beta Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6426",
    label: "Sollee Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6427",
    label: "Anthony Ford Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6428",
    label: "Andoni Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6429",
    label: "Peace Innovation Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6430",
    label: "4 Legs Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6431",
    label: "Mcarthur Kuhns Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6432",
    label: "All Things 13 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6433",
    label: "Michael And Nancy Berman Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6434",
    label: "God S Power & Light Theater Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6435",
    label: "Tap Faith Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6436",
    label: "This Is Kari & Philips Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6437",
    label: "Badou Jack Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6438",
    label: "Tapper Goodman Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6439",
    label: "Pastoral Training Institute Of Africa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6440",
    label: "Great Futures Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6441",
    label: "Ulster Project Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6442",
    label: "United States Parkour Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6443",
    label: "Dfoa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6444",
    label: "Mcknight Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6445",
    label: "Weeds To Seeds Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6446",
    label: "Grace Philanthropic Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6447",
    label: "Denman Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6448",
    label: "Cope Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6449",
    label: "Get Well Map Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6450",
    label: "Van Hale Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6451",
    label: "Andreas Rickenbach Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6452",
    label: "Delaware Elite Hoops",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6453",
    label: "Ibelievethem Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6454",
    label: "Carolinas Center For Medical Excellence Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6455",
    label: "William N Rom Md Mph Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6456",
    label: "Resolute Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6457",
    label: "Freedom Church Of God",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6458",
    label: "Wirtz Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6459",
    label: "Whitfield Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6460",
    label: "Wilmington Rugby Football Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6461",
    label: "Anderman Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6462",
    label: "Paul And June Rossetti Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6463",
    label: "Sw7 Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6464",
    label: "Neighbors Love Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6465",
    label: "Deoudes Childrens Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6466",
    label: "William And Anne Smith Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6467",
    label: "Sole Food",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6468",
    label: "Kadens Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6469",
    label: "Jean And Hing Wong Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6470",
    label: "Ericius Security Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6471",
    label: "The Schreiber & Packman Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6472",
    label: "Exponent Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6473",
    label: "Rafat Shaikh Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6474",
    label: "Witness Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6475",
    label: "Greg Peters Monsees Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6476",
    label: "Global Golf Centurions Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6477",
    label: "Double T Foundation De",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6478",
    label: "New Start Adult Learning Program Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6479",
    label: "Brick Mill Elementary Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6480",
    label: "More Than Fitness Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6481",
    label: "Africa Mingrid Developers Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6482",
    label: "Hope Scholarship Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6483",
    label: "Haiti Education Transformation Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6484",
    label: "Halff Windham Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6485",
    label: "Delaware Union Contractors Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6486",
    label: "Ann M Duffer Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6487",
    label: "Barb And Bob Bose Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6488",
    label: "Gennadius Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6489",
    label: "Kamath Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6490",
    label: "Mad Viking Maryland Delaware Beard Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6491",
    label: "Hickory Ridge Antique Tractor Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6492",
    label: "Garden Gate Garden Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6493",
    label: "Mortenson Company Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6494",
    label: "Elevated Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6495",
    label: "Appoquinimink High School All Sports Boosters Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6496",
    label: "The Tinybell Charitable Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6497",
    label: "Find The Point",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6498",
    label: "Papa Johns Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6499",
    label: "New Life Foundation Recovery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6500",
    label: "The Ajaib Singh And Sukhninder Kaur Bhadare Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6501",
    label: "Beds4Vets Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6502",
    label: "Women Birthing A Nation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6503",
    label: "Delaware Association Of Second Amendment Lawyers Ii",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6504",
    label: "Upper Room Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6505",
    label: "Paul And Marian Gerhart Charitable Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6506",
    label: "Kenya Gather Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6507",
    label: "Torrington Savings Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6508",
    label: "The Alex Kaz Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6509",
    label: "The Ripple Pie Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6510",
    label: "Doering Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6511",
    label: "Community Inspired Actions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6512",
    label: "Richard Brady Brualdi Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6513",
    label: "Motorcycle Santa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6514",
    label: "Van Drunen Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6515",
    label: "Africa Medical Relief Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6516",
    label: "Solar Frontiers Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6517",
    label: "Detv Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6518",
    label: "Dejavu Safety Solutions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6519",
    label: "Robert O Hing And Alice Y Hing Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6520",
    label: "Delaware Winds Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6521",
    label: "American Sewing Guild Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6522",
    label: "Toller Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6523",
    label: "The F & I Us Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6524",
    label: "International Friends Verderame Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6525",
    label: "First State Leathernecks Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6526",
    label: "Cunningham Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6527",
    label: "Glory Tabernacle Church Fellowship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6528",
    label: "Impact Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6529",
    label: "Lyons Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6530",
    label: "Santa S Letters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6531",
    label: "Irons Edge Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6532",
    label: "Hartland & Mackie Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6533",
    label: "Kirby-Randolph Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6534",
    label: "Absolutely Flawless Women Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6535",
    label: "Voorhis Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6536",
    label: "Redd Heart Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6537",
    label: "Better World Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6538",
    label: "Ctf Factory Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6539",
    label: "Benz Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6540",
    label: "Sanniquillie Connections Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6541",
    label: "Melissa S Cause Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6542",
    label: "Give A Future Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6543",
    label: "John M & Nancy S Murphy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6544",
    label: "Anthony And Susan Consigli Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6545",
    label: "Jeepers Back The Blue Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6546",
    label: "Nerdit Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6547",
    label: "Reiner Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6548",
    label: "Plymale Private Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6549",
    label: "Velma F Dulancy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6550",
    label: "Mary Eloise Murray Faw Private Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6551",
    label: "Neil And Roberta Ross Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6552",
    label: "Paul J Mueller Jr And Ann K Mueller Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6553",
    label: "Steedman Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6554",
    label: "The Moyer Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6555",
    label: "Rethink Identity Medicine Ethics Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6556",
    label: "The Truax Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6557",
    label: "Acahand Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6558",
    label: "Hungerford Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6559",
    label: "Ministry Out Of Bounds Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6560",
    label: "Missionary Disaster Response Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6561",
    label: "Air Mobility Command Museum Education Outreach Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6562",
    label: "Sino-American International Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6563",
    label: "Alternative Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6564",
    label: "Center For International Relations And International Security Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6565",
    label: "New Light Theatre Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6566",
    label: "Coppershield Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6567",
    label: "Resurrection Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6568",
    label: "Delaware Dance Education Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6569",
    label: "Red Stapler",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6570",
    label: "Gospel Church For All Nations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6571",
    label: "Enzos Mission To Spread Hope Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6572",
    label: "Sean Locke 24 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6573",
    label: "Woodstock Street Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6574",
    label: "Delaware State Education Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6575",
    label: "Focusing On Families Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6576",
    label: "Jakafetts Melanoma Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6577",
    label: "El Sandalo Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6578",
    label: "Balance Awakened Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6579",
    label: "Pop Warner Little Scholars Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6580",
    label: "Ny Astrology Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6581",
    label: "Ileap4 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6582",
    label: "Delaware Family Restoration Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6583",
    label: "Chincoteague Pony Drill Team",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6584",
    label: "Veda Sivagama Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6585",
    label: "Steps For Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6586",
    label: "Voices For The Voiceless Wildlife Rescue & Rehab",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6587",
    label: "Gloves Against Drugs Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6588",
    label: "Delaware Progressives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6589",
    label: "International Students For Students Organisation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6590",
    label: "Navarro Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6591",
    label: "Delaware Fury Facilities",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6592",
    label: "Gospel Rescue Mission Fellowship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6593",
    label: "Brandywine High School Parent Teacher Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6594",
    label: "Breakthrough Reformation Of Churches International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6595",
    label: "Coalition For Animal Rescue And Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6596",
    label: "Hepaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6597",
    label: "Nakajima Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6598",
    label: "The@Bayfront Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6599",
    label: "Earth Hacks Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6600",
    label: "Engineer Early Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6601",
    label: "John Wesley Community Center Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6602",
    label: "Newark Partnership Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6603",
    label: "Oliver Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6604",
    label: "The Sharda Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6605",
    label: "Blue Demon Athletics Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6606",
    label: "Dancecompany-X",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6607",
    label: "Understanding The Bible Ambassadors Assembly",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6608",
    label: "Woodland Heart Ancient Flute Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6609",
    label: "Friends Of Forwood Preserve Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6610",
    label: "Boxing Is Love Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6611",
    label: "Lazarus Educational Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6612",
    label: "American Friends Of Bean Voyage",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6613",
    label: "Lubetsky Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6614",
    label: "Second Chance Equine Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6615",
    label: "Youth Creative Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6616",
    label: "Action For Delaware S Children Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6617",
    label: "Gen1 Consulting Org Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6618",
    label: "I Can Do 26 Point 2 Kids",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6619",
    label: "Delaware Italian American Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6620",
    label: "Wicker Quinn Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6621",
    label: "Dharma Joy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6622",
    label: "Nusong International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6623",
    label: "Laurence T Bell Legacy Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6624",
    label: "Amber Oak Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6625",
    label: "Camp-A-Que",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6626",
    label: "Safe St Kitts Animal Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6627",
    label: "Benoliel Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6628",
    label: "Kingdom Awakening Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6629",
    label: "Truth And Transparency Ministries Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6630",
    label: "Cassatt Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6631",
    label: "Shepherds Office Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6632",
    label: "Ilana Pfeffer Memorial Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6633",
    label: "Push Through Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6634",
    label: "Dangerfield Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6635",
    label: "Delaware Valley Classical Schools Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6636",
    label: "Helping Through Hockey",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6637",
    label: "Finn & Chris Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6638",
    label: "Blue Paradigm Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6639",
    label: "The Middleton Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6640",
    label: "Jameil Aikeem Congo Cares Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6641",
    label: "Standard Industries Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6642",
    label: "League Of Women Voters Of New Castle County Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6643",
    label: "Seldin-Haring-Smith Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6644",
    label: "Breathe Mongolia - Clean Air Coalition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6645",
    label: "Delaware Blues Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6646",
    label: "Delaware Green Recycling Community Outreach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6647",
    label: "Delaware Teacher Academy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6648",
    label: "Brandywine Shad 2020 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6649",
    label: "Stanford Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6650",
    label: "Miracle Tabernacle Cogic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6651",
    label: "Red Wagon Calagione Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6652",
    label: "Shepherds Heartbeat-Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6653",
    label: "Concord Music Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6654",
    label: "Born Again Life Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6655",
    label: "Muslim American Waqf Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6656",
    label: "Ruta S Friends Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6657",
    label: "Bellefonte Lions Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6658",
    label: "Wellness Advocacy Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6659",
    label: "Pennsylvania Designer Craftsmen",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6660",
    label: "First State Fintech Lab",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6661",
    label: "Kip And Amy Palmer Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6662",
    label: "Sarahs Houze",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6663",
    label: "Buddy Speaks",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6664",
    label: "Harold Huffman Charitable Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6665",
    label: "Kathy & Rick Walton Charitable Tr Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6666",
    label: "Richard And Jeanette Wood Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6667",
    label: "Lumen Clarke M Williams Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6668",
    label: "Norman Foundation A Nonprofit Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6669",
    label: "Phyllis M Coors Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6670",
    label: "Clam Jam Benefit Committee Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6671",
    label: "Philadelphia Arms Townhomes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6672",
    label: "Milford Lincoln Area Missions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6673",
    label: "Community Christian Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6674",
    label: "United States Bowling Congress Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6675",
    label: "Gospel Growth Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6676",
    label: "Community Christian Worship Center At Capital Park",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6677",
    label: "Single Christian Womens Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6678",
    label: "John And Diane Kim Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6679",
    label: "Noblespond Veterans Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6680",
    label: "Stefphones Way Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6681",
    label: "Riverside Outreach Mission Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6682",
    label: "Move 2 Include Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6683",
    label: "4Th-Dimension Leaders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6684",
    label: "Sea Bridge Equine Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6685",
    label: "Community Refugee Relief Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6686",
    label: "Kleeberger Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6687",
    label: "Villages Of Five Points Community Enhancement Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6688",
    label: "Tucc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6689",
    label: "Purpose In Christ Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-6690", label: "Tuso", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-6691",
    label: "Cuus Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6692",
    label: "Platt Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6693",
    label: "The Mishpocha Charitable Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6694",
    label: "Coastal Animal Rescue Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6695",
    label: "Nbn Sports Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6696",
    label: "Aliyah S Cupboard Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6697",
    label: "Combat Veterans Motorcycle Association Chapter 50-1",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6698",
    label: "Coba Us",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6699",
    label: "Building Our Women Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6700",
    label: "Showtime Tv Productions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6701",
    label: "Sickle Cell Association Of Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6702",
    label: "Our Vets Now Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6703",
    label: "Next World Commons Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6704",
    label: "Military Women Of Valor",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6705",
    label: "Second Watch Motorcycle Association Of America",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6706",
    label: "The Davinci Foundation For Innovation Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6707",
    label: "Project Rescue Earth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6708",
    label: "Way Of Truth Evangelistic Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6709",
    label: "Fsb Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6710",
    label: "Build Her Brave",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6711",
    label: "Wilmington Firefighters Benevolent Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6712",
    label: "Wubu Foundation For Deaf Children Development International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6713",
    label: "Amateur Athletic Union Of The United States Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6714",
    label: "Urban Garden Initiative Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6715",
    label: "Orient Of Delaware Benevolent Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6716",
    label: "Foundation Of Education Art God Love Enrichment Service",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6717",
    label: "American Friends Of Big Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6718",
    label: "Southern Reconstruction Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6719",
    label: "Nigerian Nurses Association Of Delaware And The Delaware Valley",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6720",
    label: "Awesome Treasures Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6721",
    label: "Pflag Middletown",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6722",
    label: "Help Others Prosper Everywhere Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6723",
    label: "David Ian Stern Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6724",
    label: "Northern Delaware Shriners Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6725",
    label: "Empowered On Purpose Its A Movement",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6726",
    label: "Bhaidaswala Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6727",
    label: "Sigma Delta Epsilon Graduate Womens Scientific Frat",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6728",
    label: "Pittt Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6729",
    label: "Academia Booster Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6730",
    label: "Animal People Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6731",
    label: "First State Educate Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6732",
    label: "First State Educate Action Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6733",
    label: "Live Like Lil Bill Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6734",
    label: "Friends Of Pigeon Run Cemetery",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6735",
    label: "Placers Cares",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6736",
    label: "Atlas Kardia Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6737",
    label: "Delaware Alliance Against Sexual Violence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6738",
    label: "Don & Karen Cohn Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6739",
    label: "Lewes Junction Railroad And Bridge Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6740",
    label: "Coastal Volleyball Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6741",
    label: "Sumdo Park Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6742",
    label: "Simply Mobilizing Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6743",
    label: "Fostering Wishes Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6744",
    label: "The John Kwon And Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6745",
    label: "Sustainable Advancement Through Nurturing & Advocacy - Sana",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6746",
    label: "Outstretched Hands International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6747",
    label: "The Bucy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6748",
    label: "International Evangelization Christian Center Church Of God Rev",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6749",
    label: "Steelberg Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6750",
    label: "Weng Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6751",
    label: "Amateur Athletic Union Of The United States Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6752",
    label: "Jubilee Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6753",
    label: "Seaford Music And Education Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6754",
    label: "The Sok Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6755",
    label: "Delaware Outlaws Fast Pitch Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6756",
    label: "Most Excellent Prince Hall Grand Chapter - H R A M - Of Delaw",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6757",
    label: "Roberto Clemente Baseball League Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6758",
    label: "Bethel Cemetery Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6759",
    label: "Juventus Proc Musica Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6760",
    label: "Umuagbavu Diaspora Development Foundation Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6761",
    label: "The Cooperation Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6762",
    label: "Us Lng Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6763",
    label: "Africa Leadership Transformation Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6764",
    label: "Aristo Zeilstra Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6765",
    label: "Cape Henlopen High School Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6766",
    label: "Gus Belt Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6767",
    label: "Earth Rising Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6768",
    label: "Change A Life Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6769",
    label: "Wolff Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6770",
    label: "Barry B And Marilyn Eiland Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6771",
    label: "Dja Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6772",
    label: "Gurley Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6773",
    label: "Swanson Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6774",
    label: "The Henderson Family Kingdom Collection Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6775",
    label: "Sue & Bob Mccollum Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6776",
    label: "Bruce And Susan Kilgore Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6777",
    label: "Shen Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6778",
    label: "John And Julie Schmidt Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6779",
    label: "Reid Burns Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6780",
    label: "Madres Dominicas De La Casa Hogar Don Bosco Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6781",
    label: "The Tibore Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6782",
    label: "18220 Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6783",
    label: "Mulani Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6784",
    label: "Tisch S Place Dba African-Americans With Ataxia Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6785",
    label: "Trittschuh Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6786",
    label: "The Winkler Lowry Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6787",
    label: "Love Peace Amen Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6788",
    label: "Rick And Helga Bauerly Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6789",
    label: "Bob & Felicia Burger Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6790",
    label: "The Brad And Christy Carroll Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6791",
    label: "Edward F & Mary N Hooper Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6792",
    label: "Ty D Athletic Scholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6793",
    label: "White Diamond Double Merle Dane Rescue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6794",
    label: "T2G Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6795",
    label: "Wings Up Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6796",
    label: "Watermark Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6797",
    label: "Justus Denied Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6798",
    label: "Jaggers Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6799",
    label: "Sprunk Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6800",
    label: "Nueva Dominic Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6801",
    label: "The Royso Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6802",
    label: "Traynor Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6803",
    label: "Harpers Hope The Roth Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6804",
    label: "Rizing Tide Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6805",
    label: "Good Vibes Charitable Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6806",
    label: "House Of Grace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6807",
    label: "Jack Yanoff Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6808",
    label: "Damato Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6809",
    label: "Might Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6810",
    label: "Saturn Foundation Global",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6811",
    label: "Saraiya Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6812",
    label: "The Burke Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6813",
    label: "Bradlee Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6814",
    label: "Lane R Colvin Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6815",
    label: "The Judkins Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6816",
    label: "Daniel H Lome Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6817",
    label: "Charis Focus Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6818",
    label: "Barney Harford And Beth Birnbaum Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6819",
    label: "Dobs Law Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6820",
    label: "The Diane And Roger Manis Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6821",
    label: "Eaves Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6822",
    label: "Samuel & Nancy Jo Altschuler Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6823",
    label: "Delaware Aquaculture Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6824",
    label: "Most Worshipful Prince Hall Grand Lodge Of Delaware Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6825",
    label: "Impact Guru Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6826",
    label: "Zimmerman Mindful Asset Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6827",
    label: "John And Emily Park Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6828",
    label: "The Bergman Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6829",
    label: "Sussex County Music Educators Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6830",
    label: "Zain Jaffer Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6831",
    label: "Winchester Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6832",
    label: "Caraway Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6833",
    label: "Firm Foundation Recovery",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6834",
    label: "Friends Of Fort Dupont Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6835",
    label: "Society Of Critical Care Medicine",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6836",
    label: "Youniquely Creative School Of Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6837",
    label: "A Beautiful Life Sanctuary Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6838",
    label: "Compassionate Health Initiatives",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6839",
    label: "Local Changes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6840",
    label: "Flow Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6841",
    label: "Community Collaboration Of Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6842",
    label: "Southbridge Community Servicers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6843",
    label: "Seeka Educational Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6844",
    label: "Ncag Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6845",
    label: "Grp Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6846",
    label: "The Lundberg Family Founation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6847",
    label: "Maletin Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6848",
    label: "Financial Education Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6849",
    label: "Ovationant Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6850",
    label: "Intercept Poverty Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6851",
    label: "Tether Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6852",
    label: "Dover Days Committee",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6853",
    label: "Is15 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6854",
    label: "International Dostoevsky Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6855",
    label: "Pta Delaware Congress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6856",
    label: "A Friendly Couch Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6857",
    label: "Zack And Cambry Nelson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6858",
    label: "From The Ashes",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6859",
    label: "Hawks Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6860",
    label: "Masons Mobility Mission Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6861",
    label: "Aster Foundation Of Philadelphia",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6862",
    label: "Open New York Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6863",
    label: "Foundation For Truth Justice And Compassion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6864",
    label: "Intricate Knot Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6865",
    label: "Family Promise Of Southern Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6866",
    label: "Instruments Of Change International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6867",
    label: "Delaware Tamil Sangam",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6868",
    label: "Network Connect Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6869",
    label: "Social Media Day Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6870",
    label: "Analyticsfoundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6871",
    label: "The Chambless Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6872",
    label: "Wolf Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6873",
    label: "The Ahluwalia Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6874",
    label: "Hope Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6875",
    label: "Collins Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6876",
    label: "The Mountain Spring Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6877",
    label: "Thomas Edgar Dupuis Jr Michele Ann Dupuis And Thomas Edgar Dupuis",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6878",
    label: "Togive Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6879",
    label: "Little Peoples Place",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6880",
    label: "Venezuela Analysis Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6881",
    label: "Wiggs Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6882",
    label: "Virtuous Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6883",
    label: "Bridge To Independence Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6884",
    label: "Friends Of Christina School District",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6885",
    label: "Praise Worship Learning Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6886",
    label: "Solder True Life Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6887",
    label: "Boost Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6888",
    label: "Perfect Ashlar Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6889",
    label: "Gods Daughters Committee Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6890",
    label: "Nplm Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6891",
    label: "Mackler-Goding Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6892",
    label: "The Boyce And Alicia Watkins Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6893",
    label: "The Ajay Silvia Connor Natalia Anjali Gupta Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6894",
    label: "Warrior Community Connect",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6895",
    label: "Parker Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6896",
    label: "Caregiver Support Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6897",
    label: "Innersource Commons Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6898",
    label: "The Overflow Famliy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6899",
    label: "Loving Me Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6900",
    label: "Encourage & Impact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6901",
    label: "Crazy Old Lady Loving Animal Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6902",
    label: "Ubuntu Black Family Wellness Collective",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6903",
    label: "Bulldog Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6904",
    label: "Bridging The Gap Thrift Store Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6905",
    label: "Ben And Sheila Plotkin Famil Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6906",
    label: "Redy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6907",
    label: "Native Roots Farm Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6908",
    label: "Southern Delaware Unity Team Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6909",
    label: "Gospel Ministry Intl Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6910",
    label: "Newark Union Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6911",
    label: "Delaware State Education Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6912",
    label: "Dsp Womens Softball Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6913",
    label: "Improving Schools Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6914",
    label: "Eden Veterans Refuge Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6915",
    label: "I Am Me Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6916",
    label: "The Mathews Legacy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6917",
    label: "Around The Table Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6918",
    label: "Noble Women Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6919",
    label: "Bethany Asplundh Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6920",
    label: "Rv Advisor Consumer Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6921",
    label: "Andy Bruce Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6922",
    label: "Care Collaboratory",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6923",
    label: "Rao Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6924",
    label: "House Of Grace Community Development Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6925",
    label: "Regulatory Compliance Authority Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6926",
    label: "The Rosenthal-Spillar Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6927",
    label: "Followers Of The Truth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6928",
    label: "I Am You Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6929",
    label: "Laurel Womens Civic Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6930",
    label: "Why I Love Jesus - Sharing My Faith Story",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6931",
    label: "American Friends Of United For Global Mental Health",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6932",
    label: "Psi Kyiv Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6933",
    label: "She Saves A Nation Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6934",
    label: "The Concepcion Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6935",
    label: "Mispillion Performance Series",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6936",
    label: "First Founders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6937",
    label: "She Is Destined Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6938",
    label: "The Davenpore Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6939",
    label: "Church Of God Of The Redemptive Grace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6940",
    label: "Responsible Data Economy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6941",
    label: "Satisfaction Church International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6942",
    label: "New Beginnings Org",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6943",
    label: "Delaware Institute For Healthcare Education Advancement Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6944",
    label: "Foundry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6945",
    label: "Wilmington Childrens Chorus Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6946",
    label: "Rodriguez Cheever Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6947",
    label: "Summers Charity Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6948",
    label: "Rebuilders Faith Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6949",
    label: "American Legacy Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6950",
    label: "Nassau School Coalition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6951",
    label: "Love Team Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6952",
    label: "Command Purpose Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6953",
    label: "Southern Cameroons Medical Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6954",
    label: "Diaspora Ambazonian Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6955",
    label: "Hispanic Organization Of Latin Americans Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6956",
    label: "Terrie M Williams Expansion",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6957",
    label: "Arab Foundations Forum Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6958",
    label: "4Troy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6959",
    label: "The Research Foundation For The Innovation Of Eco-Education",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6960",
    label: "Center For Structural Equity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6961",
    label: "Lake Forest Community Education Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6962",
    label: "Repurpose Global Impact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6963",
    label: "Indoor Track Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6964",
    label: "Heartlight Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6965",
    label: "Re-Entry Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6966",
    label: "Miracles And Power Revival Faith Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6967",
    label: "Rasteger Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6968",
    label: "Speakenglish5 Scg Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6969",
    label: "2-4-3 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6970",
    label: "Altruity Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6971",
    label: "Code R E D Agency Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6972",
    label: "Frederick Douglass Marcus Garvey Leadership Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6973",
    label: "Holmes Helping Hands Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6974",
    label: "Freedom Faith Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6975",
    label: "Drug Prevention And Rehabilitation Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6976",
    label: "James V And Beverly R Zaleski Foundation Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6977",
    label: "Bishop W Francis Malooly Scholarship Fund For Catholic Educ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6978",
    label: "Pincus Family Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6979",
    label: "Aspen Trust",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6980",
    label: "M E Webber Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6981",
    label: "Bfc Veritas Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6982",
    label: "Bwr Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6983",
    label: "Lift Up Lake Street Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6984",
    label: "Consortium For Universal Healthcare Credentialing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6985",
    label: "Immigrant Care",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6986",
    label: "Natural State Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6987",
    label: "Aquarium Divers For Coral Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6988",
    label: "Impact Life",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6989",
    label: "Tri-State Veterinary Care Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6990",
    label: "Civic Influencers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6991",
    label: "Save Our Nurses Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6992",
    label: "Friends Feeding Families",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6993",
    label: "A House Of Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6994",
    label: "Incline Equity Partners Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6995",
    label: "Steve Tilford Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6996",
    label: "Favors In Faith Foundation Of St Augustine",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6997",
    label: "Delmar Social Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6998",
    label: "Good Never Lost Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-6999",
    label: "Donate Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7000",
    label: "Elite We Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7001",
    label: "Jireh Outreach Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7002",
    label: "High Asia Habitat Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7003",
    label: "Kif4A Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7004",
    label: "Global Citizen Action Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7005",
    label: "Thy Protector Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7006",
    label: "West Point Aviation Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7007",
    label: "Athenai Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7008",
    label: "Walking In Newness Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7009",
    label: "Boomerang Community Outreach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7010",
    label: "Mi2 Forever Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7011",
    label: "Everything Sucks Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7012",
    label: "Goin Far Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7013",
    label: "Robins Nest Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7014",
    label: "Xulon International Bible Insitutue",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7015",
    label: "Positive Thinking Positive Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7016",
    label: "Give Limbe Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7017",
    label: "Online Progressive Engagement Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7018",
    label: "W500G Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7019",
    label: "Research Institute For Antimicrobial Copper",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7020",
    label: "Lorenzini Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7021",
    label: "Impact Space Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7022",
    label: "Barbara Barnard Smith Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7023",
    label: "Pyc Giving Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7024",
    label: "Roofs From The Heart",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7025",
    label: "Good Graces Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7026",
    label: "Dweebs Global Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7027",
    label: "Intersections Of Pride Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7028",
    label: "First State African American Farmers Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7029",
    label: "Dreamr Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7030",
    label: "The Freedom Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7031",
    label: "Claude Eric Lazard Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7032",
    label: "Operation Look Forward Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7033",
    label: "Max Stenbeck Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7034",
    label: "Life Is Delicious",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7035",
    label: "Sendlight-Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7036",
    label: "Primera Iglesia Restauracion Y Vida Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7037",
    label: "Highlighting Hope Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7038",
    label: "Right Balance Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7039",
    label: "Martinism Group Of Colonial Court Apartments",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7040",
    label: "Youth In Diaspora",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7041",
    label: "Global Ripple Effect Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7042",
    label: "Leap Of Faith Hope Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7043",
    label: "Hbcu Week Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7044",
    label: "First Ray Of Sun Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7045",
    label: "Wilmington Freedom Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7046",
    label: "Marian Rosella Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7047",
    label: "The 302 Movement Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7048",
    label: "Apex Educational Systems Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7049",
    label: "Paul Kares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7050",
    label: "Rogue Marble Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7051",
    label: "Friends Of Coochs Bridge Historic Site Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7052",
    label: "Care For Nigeria Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7053",
    label: "Pass It Down For Football Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7054",
    label: "Supporting Sisters Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7055",
    label: "Sandsharks Dewey Beach Volleyball Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7056",
    label: "Barry Harris Institute Of Jazz",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7057",
    label: "The Richard Klevins And Gay Nichols Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7058",
    label: "Culture Creators Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7059",
    label: "Lewes Base Ball Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7060",
    label: "Molina Healthcare Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7061",
    label: "Weird Science Lab Edutech Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7062",
    label: "Latinicida Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7063",
    label: "Faith 2 Action 2 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7064",
    label: "Clausen Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7065",
    label: "Major Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7066",
    label: "Columbiettes Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7067",
    label: "Aleppo Heritage Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7068",
    label: "La Folie Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7069",
    label: "Pta Delaware Congress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7070",
    label: "Katalina And Michael Simon Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7071",
    label: "Varanasi Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7072",
    label: "The Patricia L And Thomas W Krise Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7073",
    label: "Ingressive For Good Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7074",
    label: "Friends Of Garrisons Lake Golf Course",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7075",
    label: "Golden Ladder Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7076",
    label: "Mcdonald Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7077",
    label: "Gay Marketing Assoc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7078",
    label: "Delaware Spartans Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7079",
    label: "Foundation For Art & Design Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7080",
    label: "United Benevolence Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7081",
    label: "Arise Professional Network For Women Of Color",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7082",
    label: "Institute For Transparent Policing",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7083",
    label: "Worship Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7084",
    label: "Giants Causeway",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7085",
    label: "Sikh10 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7086",
    label: "Help The Veterans Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7087",
    label: "Battle Scar Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7088",
    label: "Essex Avenue Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7089",
    label: "Friends Of Mt Olive Burial Grounds Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7090",
    label: "Alliance Global Intelligence Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7091",
    label: "Black Coalition For Safe Motherhood",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7092",
    label: "Autistic Angels Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7093",
    label: "Unity Community Services Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7094",
    label: "Ingeborg Fissler Diedenhofen Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7095",
    label: "Jiang Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7096",
    label: "Black Phoenix Ink Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7097",
    label: "Ikea Us Community Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7098",
    label: "Moses West Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7099",
    label: "Seaford Jrotc Booster Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7100",
    label: "Partners In Public Safety Solutions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7101",
    label: "National Association Of Occupational Safety And Health Con",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7102",
    label: "Andre Blake Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7103",
    label: "Filecoin Foundation For The Decentralized Web",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7104",
    label: "Informed & Inspired Women Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7105",
    label: "Girls Tech Together Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7106",
    label: "Learning Disabilities Association Of Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7107",
    label: "Terra Collective Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7108",
    label: "Delaware Regional Dream Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7109",
    label: "Black-Wall Real Estate Holdings",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7110",
    label: "Family Focus Development Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7111",
    label: "Delaware Black Chamber Of Commerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7112",
    label: "Meodh Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7113",
    label: "Connectde Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7114",
    label: "Tim 2 My Brother Awareness And Balance Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7115",
    label: "American Basho Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7116",
    label: "Scholars Equity Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7117",
    label: "Book Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7118",
    label: "Jehovah Jireh Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7119",
    label: "Lewis Street Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7120",
    label: "Couton Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7121",
    label: "Beyouth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7122",
    label: "Carl E Meadwell Hunting Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7123",
    label: "Dastarkhaan Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7124",
    label: "Just Like You Global Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7125",
    label: "Nccd Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7126",
    label: "Lamarlove Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7127",
    label: "Power Circle Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7128",
    label: "Viola Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7129",
    label: "Yoga Sciences And Vedic Research Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7130",
    label: "Baker Dickerson Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7131",
    label: "Down Syndrome Sports Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7132",
    label: "Upendo United Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7133",
    label: "Kings Deliverance Hope Helping Others Potential Evolve",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7134",
    label: "Delaware Regulators Elite Fastpitch",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7135",
    label: "Amit & Deepali Sinha Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7136",
    label: "Sky Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7137",
    label: "Connectdcare",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7138",
    label: "10-7 Cigar Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7139",
    label: "Hillcroft Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7140",
    label: "Youth Passion Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7141",
    label: "The Well Work Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7142",
    label: "The Louise And Michael D Burke Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7143",
    label: "The Jonsson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7144",
    label: "Paraffin Moon Media Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7145",
    label: "5 Minute Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7146",
    label: "Goods Blessing House Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7147",
    label: "Gentle Way Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7148",
    label: "Goodforce Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7149",
    label: "Delaware National Baseball Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7150",
    label: "Reel Families For Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7151",
    label: "Basketball Dreamers Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7152",
    label: "Be Thou My Vision Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7153",
    label: "Bridge Hackathon Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7154",
    label: "Christopher Early Learning Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7155",
    label: "Slicker Enterprise Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7156",
    label: "Plastic Free Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7157",
    label: "Wilmington Community Advisory Council Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7158",
    label: "Lia Edu Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7159",
    label: "Village Education Tutors Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7160",
    label: "Markham Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7161",
    label: "American League Of Tutoring Organizations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7162",
    label: "Team Mills Private Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7163",
    label: "The Lillian J Schauer Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7164",
    label: "Developing Nextlevel Achievers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7165",
    label: "Hargis Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7166",
    label: "Christopher Robert Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7167",
    label: "Stain Film",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7168",
    label: "Clayton Legacy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7169",
    label: "Friends Of Research4Life Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7170",
    label: "The Zankel Scala Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7171",
    label: "Church Of Exodus",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7172",
    label: "Agape Love Missionary Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7173",
    label: "God In Action Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7174",
    label: "A Fiore Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7175",
    label: "Healed In Hope Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7176",
    label: "Forest Glen Impact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7177",
    label: "Genomics Organization For Academic Laboratories",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7178",
    label: "The Silseth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7179",
    label: "Mat Ratz Wrestling Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7180",
    label: "The Little Flower Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7181",
    label: "Partnership Against Child Trafficking Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7182",
    label: "Mission Of Kindness",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7183",
    label: "Rank The Vote Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7184",
    label: "Rucker Collier Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7185",
    label: "School Social Workers Association Of Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7186",
    label: "International Mental Health Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7187",
    label: "Lets Change America Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7188",
    label: "Married To The Ring For Families Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7189",
    label: "Fridays At 4 Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7190",
    label: "Trinidad And Tobago Diaspora Associations Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7191",
    label: "Ronnie Stanley Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7192",
    label: "Shawtown Academy Of Recreations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7193",
    label: "The Lighthouse Beacon Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7194",
    label: "The Robert & Kelly Kaplan Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7195",
    label: "Bergen Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7196",
    label: "Mark P Whitfordscholarship Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7197",
    label: "Women In Voice Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7198",
    label: "Focused Impact Delaware Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7199",
    label: "Korndrop Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7200",
    label: "Tkb Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7201",
    label: "Gardners Standing In The Gap",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7202",
    label: "The Sal Terrae Lux Mundi Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7203",
    label: "The Jolie Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7204",
    label: "Sam And Virginia Patz Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7205",
    label: "Hillview Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7206",
    label: "Bravante Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7207",
    label: "Groebner Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7208",
    label: "Julias Light Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7209",
    label: "The Okusa Dellinger Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7210",
    label: "Working Theory Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7211",
    label: "Scholars Turf",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7212",
    label: "Springboard Collaborative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7213",
    label: "Prostate Cancer Research Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7214",
    label: "Responsible Innovation Labs Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7215",
    label: "Dynamic Divas Dance Company Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7216",
    label: "Brandywinebots Robotics Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7217",
    label: "Ocs Pto Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7218",
    label: "John Brune Law Enforcement K-9 Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7219",
    label: "Improve The News Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7220",
    label: "Howard T Ennis Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7221",
    label: "Blesall Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7222",
    label: "Colestrong Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7223",
    label: "Volumetric Format Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7224",
    label: "Future Vision Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7225",
    label: "Global Network For Good Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7226",
    label: "There Is A Light Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7227",
    label: "Medicare Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7228",
    label: "Million Meals Mission Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7229",
    label: "Bakers House Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7230",
    label: "Singley Strong Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7231",
    label: "Indian River Hs Volleyball Booster Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7232",
    label: "Academy Of Sports Performance & Innovation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7233",
    label: "Punoca Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7234",
    label: "The Carol Hepper Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7235",
    label: "The Golub Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7236",
    label: "Allen Lee Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7237",
    label: "Bck Akr Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7238",
    label: "Burbidge Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7239",
    label: "Foundation Source 001998 Inc Strafach Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7240",
    label: "Cj&J Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7241",
    label: "W-City Business Development Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7242",
    label: "Remnant Resource Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7243",
    label: "Kingston Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7244",
    label: "Jonathan And Rae Corr Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7245",
    label: "Gilgal Agape Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7246",
    label: "Rock Oak Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7247",
    label: "Gayna Gibson Wall Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7248",
    label: "The Egerter Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7249",
    label: "Passaic County Commission On The Status Of Women",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7250",
    label: "End Cyber Abuse Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7251",
    label: "Bal Educational Athletics Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7252",
    label: "Zeta Beta Tau Fraternity - Epsilon Theta Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7253",
    label: "Medical Legal Patient Advocacy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7254",
    label: "Delaware Data Innovation Lab Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7255",
    label: "Fly High Athletix Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7256",
    label: "Beans House Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7257",
    label: "Delaware Chapter Of Certified Fraud Examiners Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7258",
    label: "Across Africa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7259",
    label: "Sussex County Health Coalition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7260",
    label: "Black Mothers In Power",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7261",
    label: "Goldn Link Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7262",
    label: "Life Launch Circle",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7263",
    label: "Leder Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7264",
    label: "Engasser Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7265",
    label: "Simran Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7266",
    label: "The Aca Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7267",
    label: "Dr Win Tun Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7268",
    label: "Gerri And Rich Wong Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7269",
    label: "Native Nourishment",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7270",
    label: "Clein Lemann Esperanza Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7271",
    label: "The Well Future Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7272",
    label: "The Clarion Capital Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7273",
    label: "Flaig Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7274",
    label: "One Of 1 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7275",
    label: "Roy And Charlotte Martin Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7276",
    label: "St Vincent House Of Transformation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7277",
    label: "Delaware Foster Parent Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7278",
    label: "Levine Hirshfield Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7279",
    label: "Tidemark 10 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7280",
    label: "Boerke Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7281",
    label: "Lee Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7282",
    label: "Community Cat Action League Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7283",
    label: "Sonny Vick Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7284",
    label: "Nha Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7285",
    label: "The Winans Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7286",
    label: "Annadata Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7287",
    label: "The Family Tapestry Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7288",
    label: "Myra Cares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7289",
    label: "John T Campbell Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7290",
    label: "Sherri England Gough Christian Humanitarian Foundation 002032 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7291",
    label: "The Stainfeld Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7292",
    label: "Delaware Veterans Support Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7293",
    label: "Sidewalk University Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7294",
    label: "Courtney Christian Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7295",
    label: "Bridenbecker Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7296",
    label: "Daniel & Joylene Hegel Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7297",
    label: "Aisling Charitable Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7298",
    label: "Alan Spanier Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7299",
    label: "Petlanthropy Animal Care Health & Lifesaving Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7300",
    label: "Queen Of Sheba Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7301",
    label: "Consequence Of Habit Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7302",
    label: "Shining Rock Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7303",
    label: "Piccinini Pesco Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7304",
    label: "Teach A Person To Fish Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7305",
    label: "Raj And Santosh Singla Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7306",
    label: "The Lonnie And Emnet Parker Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7307",
    label: "The Tucker Foundation Source 002044 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7308",
    label: "The Perry Foundation For Better Future Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7309",
    label: "Mackenzie Ryan Brumberg Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7310",
    label: "Poscon Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7311",
    label: "Hope Storytelling Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7312",
    label: "Executive A&R Enterprises",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7313",
    label: "New Castle County Hope Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7314",
    label: "Ankitara Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7315",
    label: "Ambassadors Of Healing Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7316",
    label: "Kebaso Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7317",
    label: "Ruff & Tumble Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7318",
    label: "Partnership In Reentry Resource Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7319",
    label: "Moms Agency",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7320",
    label: "Angeles De Esperanza Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7321",
    label: "Msr Foundation Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7322",
    label: "Open Doors-Mblb Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7323",
    label: "Giving Bee Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7324",
    label: "Mmm Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7325",
    label: "Lance & Marianne Miller Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7326",
    label: "Books Across Borders",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7327",
    label: "Balcavage Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7328",
    label: "Skylight Charitable Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7329",
    label: "New Earth Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7330",
    label: "Centerpoint Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7331",
    label: "Celestial Church Of Christ-American Diocese",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7332",
    label: "Worship Christian Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7333",
    label: "Harriet Tubmans Safe House",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7334",
    label: "Charter School Of New Castle Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7335",
    label: "Psi Iotas Helping Others Pursue Education Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7336",
    label: "The Life Center Complex Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7337",
    label: "Global Youth Help Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7338",
    label: "Meg And Tim Callahan Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7339",
    label: "Supportive Accountability Hub Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7340",
    label: "Costolo Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7341",
    label: "Campaignprepde Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7342",
    label: "Truenorth Legacy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7343",
    label: "Three Sizes Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7344",
    label: "Matthew 13 23 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7345",
    label: "Fight Delaware Corruption",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7346",
    label: "Encounter Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7347",
    label: "Make Truth Matter Again",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7348",
    label: "The Good Sparrow Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7349",
    label: "Bur Oak Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7350",
    label: "Birch Ridge Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7351",
    label: "Misson Joy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7352",
    label: "Youlead Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7353",
    label: "Redeemed Fellowship Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7354",
    label: "Global Ubuntu Impact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7355",
    label: "Amigos Unidos Reconstruyendo Vidas Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7356",
    label: "Brad And Brea Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7357",
    label: "4Sight Delaware Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7358",
    label: "Pta Delaware Cogress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7359",
    label: "National Sovereighty Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7360",
    label: "Alonzo D Baker Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7361",
    label: "Kingdom Culture Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7362",
    label: "Core Influencer Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7363",
    label: "Manna House Fellowship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7364",
    label: "My Dads Pantry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7365",
    label: "Matt & Ann Desch Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7366",
    label: "Catalyze Partners Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7367",
    label: "Immovable Movers Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7368",
    label: "Islamic Center Of Eastern Shore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7369",
    label: "Gods True Treasures",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7370",
    label: "Right To Health Action Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7371",
    label: "Griff 54 Promise",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7372",
    label: "Focus On The Dream",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7373",
    label: "Haitian Vizyon Alliance Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7374",
    label: "Dme Donations",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7375",
    label: "Grateful Givers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7376",
    label: "Lion Rock Cafe",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7377",
    label: "4You Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7378",
    label: "Americas Veteran Party",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7379",
    label: "Original Wilmington Memorial Day Committee Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7380",
    label: "Music Crew Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7381",
    label: "Take Back Your Story",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7382",
    label: "Liddonfield Project Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7383",
    label: "Ijcf-International Jewish Chesed Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7384",
    label: "Cohort Sistas Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7385",
    label: "Panpbl Assoc Of Problem-Based Learning & Active Learning",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7386",
    label: "Covenant Community Resource Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7387",
    label: "Inka And Ezra Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7388",
    label: "Together We Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7389",
    label: "Balance Thru Wellness",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7390",
    label: "Black Girl Health Collective",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7391",
    label: "Visions Of Hope Hm",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7392",
    label: "Crave Saves Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7393",
    label: "Put America First Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7394",
    label: "Utility Broadband Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7395",
    label: "Meraki Cpc Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7396",
    label: "Philly Bikefest Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7397",
    label: "Black Coaches And Rowers Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7398",
    label: "Democracy Justice Anti-Corruption And Humanitarian F",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7399",
    label: "Egirl Power Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7400",
    label: "Musicverse Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7401",
    label: "Alrahma Orphans Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7402",
    label: "Love Matter International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7403",
    label: "Uplift Haiti Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7404",
    label: "Sociable Consulting Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7405",
    label: "Esteamed Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7406",
    label: "Hunter Pryor Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7407",
    label: "Wild Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7408",
    label: "Conquering Congenital Heart Disease",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7409",
    label: "Frances H & Harold E Gordon Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7410",
    label: "Foundation Source 002077 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7411",
    label: "Ariel University Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7412",
    label: "Rejuvenated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7413",
    label: "Marsh Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7414",
    label: "Delaware Jci Senate",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7415",
    label: "Collective Agreement Of Black Law Enforcement Study Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7416",
    label: "Milford Advocacy For The Homeless",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7417",
    label: "Maji Movement",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7418",
    label: "Zx Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7419",
    label: "Rajam Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7420",
    label: "Femtrade Box Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7421",
    label: "Boon Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7422",
    label: "Huck And Buck Farm Santuary Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7423",
    label: "National Organization For Women Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7424",
    label: "Channie P Bradley Senior Services Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7425",
    label: "House Of David Milford De Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7426",
    label: "Dream Four Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7427",
    label: "Riverfront Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7428",
    label: "Pta Delaware Congress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7429",
    label: "Wacone Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7430",
    label: "Ministry 4 Millennials",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7431",
    label: "The Voice De Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7432",
    label: "Foundation Of Fun Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7433",
    label: "Friends Of Zoar Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7434",
    label: "Family Care Initiatives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7435",
    label: "Klarity Counseling Associates",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7436",
    label: "9Eleven Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7437",
    label: "Andrew & Cynthia Staiger Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7438",
    label: "Justamere Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7439",
    label: "Bell Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7440",
    label: "Documentary Producers Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7441",
    label: "No Limits Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7442",
    label: "Long Arc Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7443",
    label: "Harsh And Anitha Maheshwari Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-7444", label: "Free", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-7445",
    label: "Foundation Source 002087 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7446",
    label: "The Kelley Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7447",
    label: "Barbara & Julian Cherubini Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7448",
    label: "Christs Church Of The Eastern Shore Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7449",
    label: "Center For Africa Missions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7450",
    label: "Six Hundred And Rising Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7451",
    label: "Andrew Kearse Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7452",
    label: "Yunes Foundation For Research On Aging",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7453",
    label: "Victorious Jaesettes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7454",
    label: "Survivors Of Childhood Sex Abuse Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7455",
    label: "Genesis Family Community Center Of Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7456",
    label: "Hope In Him",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7457",
    label: "Eha Impact Ventures Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7458",
    label: "Do Care Doula Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7459",
    label: "Arcadia Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7460",
    label: "International Academy Of Mind-Body Medicine",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7461",
    label: "Pre-Medical Explorers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7462",
    label: "Yelp-Sos-Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7463",
    label: "Lighthouse Monastery",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7464",
    label: "American Youth Football Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7465",
    label: "Cornerstone Community Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7466",
    label: "Soar Academy Charter School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7467",
    label: "Bytecode Alliance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7468",
    label: "Sydney Monfries Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7469",
    label: "Pearson Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7470",
    label: "91St Memorial Wing Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7471",
    label: "Connect Parent Group Network",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7472",
    label: "Pta Delaware Congress",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7473",
    label: "Curry Collaborative Corporations",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7474",
    label: "Regenesis Guild Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7475",
    label: "Lifework Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7476",
    label: "Water Source Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7477",
    label: "One Purpose Food Pantry Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7478",
    label: "Keller Williams De Md Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7479",
    label: "Nam Knights Of America Mc Delaware First State Chapter Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7480",
    label: "Happy Medicine Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7481",
    label: "Bright Sky Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7482",
    label: "Conexio Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7483",
    label: "King Porter Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7484",
    label: "Thrivers Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7485",
    label: "The Aiko Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7486",
    label: "Rajaram Impact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7487",
    label: "The Wildflower Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7488",
    label: "The Judith D Brown Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7489",
    label: "Scs Smith Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7490",
    label: "Global Shield Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7491",
    label: "Kns Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7492",
    label: "Playstudios Impact Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7493",
    label: "Blindsight Delaware Enterprises Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7494",
    label: "Viking Invitational Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7495",
    label: "5Pie Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7496",
    label: "Saving Lives Usa Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7497",
    label: "Hamlin Family Private Trust",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7498",
    label: "Promise Of Light Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7499",
    label: "Aircraft Manufacturers Flight Training Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7500",
    label: "Empowering Transformation Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7501",
    label: "Dose Of Hope Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7502",
    label: "Mount Moriah Chapter No 5 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7503",
    label: "Wilmington Sudbury School",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7504",
    label: "Grodman Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7505",
    label: "Delaware African American Health Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7506",
    label: "Meme America Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7507",
    label: "Friends Of The Mounted Patrol New Castle County Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7508",
    label: "Worldwide Solutions 4Life Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7509",
    label: "Talitha Koum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7510",
    label: "Tzedakah Box Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7511",
    label: "501Ctrees Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7512",
    label: "Ephphatha Care Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7513",
    label: "Mussar Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7514",
    label: "Rotger Research Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7515",
    label: "Foundation Source 002100 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7516",
    label: "Try Ai Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7517",
    label: "Odessa High School Touchdown Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7518",
    label: "Seaford Starfish Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7519",
    label: "Ezer Rising Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7520",
    label: "Start Where You Are Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7521",
    label: "School Simplified",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7522",
    label: "Nihei Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7523",
    label: "Ugr3Day Underground Railroad Experiences Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7524",
    label: "Viney Wallach Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7525",
    label: "Redemption City Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7526",
    label: "Super Liga Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7527",
    label: "National Council On Relationships And Disability Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7528",
    label: "Enharmony Community Social Care Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7529",
    label: "Gardp North America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7530",
    label: "Walter Schwer Decedents Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7531",
    label: "Doris Lee Teague Trust Fbo Christian Family Care Agency Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7532",
    label: "Doris Lee Teague Trust Fbo Childrens Home Of Lubbock",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7533",
    label: "Surplus Line Association Of Arizona Foundation Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7534",
    label: "George And Louise J Hauser Charitable Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7535",
    label: "Hume Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7536",
    label: "Robert S Carter Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7537",
    label: "Neal And Sherrie Savage Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7538",
    label: "Vision Of Hope Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7539",
    label: "Write Back Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7540",
    label: "Just Us",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7541",
    label: "Feral Friends Of Millsboro",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7542",
    label: "Walking Miracle Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7543",
    label: "Evangelistic Fellowship Churchs Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7544",
    label: "Sosiel Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7545",
    label: "Beloved Community Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7546",
    label: "Solidarity And Alliance For Government Equity--Sage",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7547",
    label: "Delaware Foundation Football League",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7548",
    label: "Crowdera Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7549",
    label: "Tremont Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7550",
    label: "Kenny & Maria C Chin Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7551",
    label: "Spurling Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7552",
    label: "Faith Deannajohnson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7553",
    label: "Lin Ho Yee Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7554",
    label: "Lee Oceans Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7555",
    label: "Cronstedt Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7556",
    label: "Operation Joy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7557",
    label: "Leonard And Sandra Jacob Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7558",
    label: "The Sundancer Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7559",
    label: "Honoring Neighbors Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7560",
    label: "Hemispheres Academy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7561",
    label: "Turning Point Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7562",
    label: "Sidley Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7563",
    label: "Nelson Levitt Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7564",
    label: "Playrs Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7565",
    label: "Christian Mix 106 Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7566",
    label: "Mentally Fit Muslims",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7567",
    label: "Teen Scene Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7568",
    label: "Harbourview Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7569",
    label: "Stephen & Peihua Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7570",
    label: "Aapi Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7571",
    label: "Metta Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7572",
    label: "Lillian Hirschbeck Wister Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7573",
    label: "Schwartz Family Giving Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7574",
    label: "First State Outreach Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7575",
    label: "Timbella Productions Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7576",
    label: "Geroulanos Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7577",
    label: "The Coffrini Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7578",
    label: "Joyy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7579",
    label: "Immigrants Gastroadvocacy Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7580",
    label: "Trout Walk Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7581",
    label: "Fernandez Buddin Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7582",
    label: "Stop Asian Hate Global Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7583",
    label: "Star Fire Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7584",
    label: "Healing Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7585",
    label: "Family Empowerment Media Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7586",
    label: "Ryan Dillon - Rjd Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7587",
    label: "Good Samaritan Home Repair Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7588",
    label: "Daily Bread Global Outreach",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7589",
    label: "Oncology Medical Physics Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7590",
    label: "Africancivilizations Preservationfoundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7591",
    label: "Protectthefirstfoundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7592",
    label: "National Sprint Car Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7593",
    label: "A Joanne Mcmahon Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7594",
    label: "Bridges For A Brighter Future Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7595",
    label: "Amplfire Giving Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7596",
    label: "Oasis Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7597",
    label: "League Of Champion Athletes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7598",
    label: "Myanmar Policy Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7599",
    label: "Createyourscholarship-Org Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7600",
    label: "Confidence In Your Shine Outreach Ministry Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7601",
    label: "Renewal Of Self Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7602",
    label: "Lang Cares Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7603",
    label: "Newtmont Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7604",
    label: "Conrad Football Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7605",
    label: "Moravia Cares Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7606",
    label: "Goodwillfinds Ecommerce Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7607",
    label: "Black Wombs In Solidarity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7608",
    label: "Major Taylor Capital City Cyclists",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7609",
    label: "Adult Non-Alcoholic Beverage Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7610",
    label: "Southern Cameroons Peoples Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7611",
    label: "Run Saniyah Run Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7612",
    label: "Mended And Blended Network Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7613",
    label: "Life Builders",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7614",
    label: "De Usa Wrestling",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7615",
    label: "Werunwithyou",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7616",
    label: "Delaware Coastal Flying Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7617",
    label: "Scarlet Scorpions",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7618",
    label: "Keyrock Community Improvement Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7619",
    label: "Fisher Of Men Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7620",
    label: "Union Street Meeting House",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7621",
    label: "Marci And Martin Karplus Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7622",
    label: "Jrbf Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7623",
    label: "The Adrian Bias Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7624",
    label: "Acquis Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7625",
    label: "Moving Forward Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7626",
    label: "Puri Family Giving Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7627",
    label: "The Mcnamara Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7628",
    label: "The Mullins Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7629",
    label: "Delaware Now Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7630",
    label: "Acts 4 36-37 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7631",
    label: "Capturing Hope Empowerment Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7632",
    label: "Delaware Venom Softball Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7633",
    label: "Global Level Access Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7634",
    label: "Enitan Odutola Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7635",
    label: "Proclaim Success",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7636",
    label: "Go Honduras Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7637",
    label: "Onfoundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7638",
    label: "Community Improvement Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7639",
    label: "Sussex Montessori School Pto Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7640",
    label: "Khanversations Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7641",
    label: "I Am Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7642",
    label: "Dorcas Charities Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7643",
    label: "Tajasa Empowerment Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7644",
    label: "Women In Ethics And Compliance Global",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7645",
    label: "Haskins Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7646",
    label: "Eatseed Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7647",
    label: "Alsama Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7648",
    label: "Gospel Of Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7649",
    label: "Conrad Class Of 1970 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7650",
    label: "Internet Fire Brigade Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7651",
    label: "Hearts4Ghana Inc Odey Acquaye",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7652",
    label: "New Foundations Of Learning Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7653",
    label: "Grace And Mercy Temple Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7654",
    label: "Dover Strong Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7655",
    label: "Shanghai Sonatas Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7656",
    label: "Friends Of Thousand Acre Marsh Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7657",
    label: "World Warriors Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7658",
    label: "Delaware Spirit Coaches Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7659",
    label: "Abbot Mary Charity Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7660",
    label: "Naked Truth Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7661",
    label: "Brand Plucked Out Of The Fire Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7662",
    label: "Friends Of Jesus Collective Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7663",
    label: "Black Micropigmentation Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7664",
    label: "Mariner Middle Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7665",
    label: "Flow Falling Love Ones Wings Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7666",
    label: "Pathlight Affordable Housing Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7667",
    label: "Garments From God Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7668",
    label: "Missed Opts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7669",
    label: "Healthcare Awareness Education Charity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7670",
    label: "Mike And Rebecca Bogan Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7671",
    label: "Foundation Source 002144 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7672",
    label: "N Eric & Olga N Jorgensen Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7673",
    label: "Butler Ventures Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7674",
    label: "The 2-4-3 Giving Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7675",
    label: "Dead Relatives Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7676",
    label: "Morgan Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7677",
    label: "Simple Paradox Union",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7678",
    label: "This Life Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7679",
    label: "Stallworth Oh Yes Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7680",
    label: "Seven Seas Community Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7681",
    label: "Gift Of Good Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7682",
    label: "Remote Student Exchange Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7683",
    label: "Maritime Legal Aid & Advocacy Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7684",
    label: "Adoptees With Guatemalan Roots Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7685",
    label: "Zola Counseling Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7686",
    label: "Sunrise Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7687",
    label: "Soleri Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7688",
    label: "George Macke And Ida Diamond Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7689",
    label: "Picabu Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7690",
    label: "Polish American Softball And Baseball Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7691",
    label: "Know Your Rights Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7692",
    label: "John And Laura Broderick Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7693",
    label: "Berglund-Weiss Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7694",
    label: "Jurgens Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7695",
    label: "Radix Earth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7696",
    label: "Pfrankmd Heals Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7697",
    label: "Granger-Williams Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7698",
    label: "Divine Interventions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7699",
    label: "Constance Williams Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7700",
    label: "Sunflower Guardians",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7701",
    label: "Project For World Peace",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7702",
    label: "Wildflower Farm Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7703",
    label: "Making Life Better Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7704",
    label: "Honglu Zhang And Peijie Yang Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7705",
    label: "Jensen Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7706",
    label: "Integrative Athletics Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7707",
    label: "Military History Institute Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7708",
    label: "Charles Morlang Private Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7709",
    label: "Carol Paulson Private Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7710",
    label: "Original Position Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7711",
    label: "Cape Henlopen Girls Basketball Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7712",
    label: "Nobody Film Festival Insitute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7713",
    label: "The Mcallister Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7714",
    label: "Livun Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7715",
    label: "Complete The Work Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7716",
    label: "Caribbean Islands Manta Conservation Program Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7717",
    label: "Wrld Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7718",
    label: "Zombie Movement Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7719",
    label: "Zg Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7720",
    label: "Sierra Pine Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7721",
    label: "De Llano Rolnick Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7722",
    label: "Children Need Support",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7723",
    label: "Resm Africa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7724",
    label: "Kolba Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7725",
    label: "Rager Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7726",
    label: "Acs Private Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7727",
    label: "Love& Hope Rescue Mission",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7728",
    label: "Fuller Center For Housing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7729",
    label: "Inclusion In Crypto Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7730",
    label: "Six String Giving Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7731",
    label: "Patriot Ocean Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7732",
    label: "The Andover Companies Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7733",
    label: "Friends Of Union Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7734",
    label: "Open Hearts N O W Community Support Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7735",
    label: "Delaware First Responders Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7736",
    label: "The Thomas & Nancy Hulsebosch Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7737",
    label: "Walkowski Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7738",
    label: "Lifting Hands International Ministery Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7739",
    label: "Sos Afghanistan Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7740",
    label: "100 Black Men Of Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7741",
    label: "Michael Blackson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7742",
    label: "Sandhu Special Needs Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7743",
    label: "The Schellman Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7744",
    label: "Face Behind The Mask Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7745",
    label: "Towanda Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7746",
    label: "Jennifer And James Wood Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7747",
    label: "Kenneth & Tricia Robertson Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7748",
    label: "Pentecostal Church Of God Soul Seeker Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7749",
    label: "Cross Chapel Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7750",
    label: "Africa Aid International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7751",
    label: "Raffo-Meringolo Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7752",
    label: "Borglin Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7753",
    label: "Children Compassion Organization Of Central Haiti",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7754",
    label: "Tri-State Cat Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7755",
    label: "Fleming Famly Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7756",
    label: "Impact13 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7757",
    label: "The Shipley Educational Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7758",
    label: "C&C Low Income Housing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7759",
    label: "Invoke Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7760",
    label: "Dream Conduit Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7761",
    label: "Jyotish Patel Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7762",
    label: "Mountain Field Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7763",
    label: "Jl Cox Jr Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7764",
    label: "Lions Virtual Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7765",
    label: "Taiber Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7766",
    label: "Fisher Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7767",
    label: "Greenfish Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7768",
    label: "Day To Day Vermont Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7769",
    label: "Bermuda Highway Sober Living Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7770",
    label: "Black Seed Scholarship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7771",
    label: "Desai Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7772",
    label: "Mehyar Science Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7773",
    label: "Investor Stewardship Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7774",
    label: "Little People - Big Dreams",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7775",
    label: "Root Out Racism Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7776",
    label: "Al King Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7777",
    label: "Victor Franco Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7778",
    label: "A Part Of The Whole Family Foundati On",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7779",
    label: "Local Journalism Initiative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7780",
    label: "Slaughter Beach Historical Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7781",
    label: "Initiative For A Better Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7782",
    label: "Cj Kindness Porject Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7783",
    label: "Reiner Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7784",
    label: "Alesio Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7785",
    label: "The Robert P Tesagrossa Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7786",
    label: "The Burrell Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7787",
    label: "Hbcu New Venture Challenge Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7788",
    label: "Branch Energy Org Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7789",
    label: "Ascend Education And Healthcare Charity Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7790",
    label: "Mavis Legacy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7791",
    label: "Mcintyre Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7792",
    label: "Positive Money Us Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7793",
    label: "Thorins Promise",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7794",
    label: "Prospering Powerful Minds Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7795",
    label: "Andrew & Barbara Lee Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7796",
    label: "Make All The Difference Mad Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7797",
    label: "Spirit Of Kindness Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7798",
    label: "Engineering Concepts Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7799",
    label: "International Christian Church Of Holiness Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7800",
    label: "Primed Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7801",
    label: "The Brown Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7802",
    label: "Better Future Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7803",
    label: "Pandemic Proof",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7804",
    label: "Abc Parenting Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7805",
    label: "Almeida Carreira Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7806",
    label: "Gupta Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7807",
    label: "The Simbio Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7808",
    label: "Synq Alliance Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7809",
    label: "346 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7810",
    label: "The Clark & Shannon Buerk Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7811",
    label: "Dr Lana Joseph Ford Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7812",
    label: "Xin Mu-Renger And John J Renger Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7813",
    label: "Fortune Tower Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7814",
    label: "The Shaw Ferriel Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7815",
    label: "Denicola Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7816",
    label: "Oh Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7817",
    label: "Lily Pad Fund For Nature",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7818",
    label: "Spiritual Yoga Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7819",
    label: "Coletta Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7820",
    label: "Minds Of The Culture Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7821",
    label: "Power Of Zero Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7822",
    label: "The Van Kirk Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7823",
    label: "Snow Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7824",
    label: "Wu Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7825",
    label: "Bamert Seeds For Success Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7826",
    label: "Harris Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7827",
    label: "Hingwe & Sharma Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7828",
    label: "Living Waters Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7829",
    label: "Percent Impact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7830",
    label: "Youths For Excellence Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7831",
    label: "Restore Hope International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7832",
    label: "Omnicolosx Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7833",
    label: "Goodneighbor Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7834",
    label: "Nicks Power Of Play Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7835",
    label: "Naji Global Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7836",
    label: "Returning Hero Ranch Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7837",
    label: "Enterprise Coaching And Development Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7838",
    label: "Alexa Joy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7839",
    label: "Sunya Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7840",
    label: "Christians For Impact Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7841",
    label: "Touching Hearts Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7842",
    label: "Blockchain Lawyers Forum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7843",
    label: "Power Love And Sound Mind Ministry For Jesus Christ Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7844",
    label: "Delawre State Education Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7845",
    label: "Iglesia De Dios Casa Taller Del Alfarero Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7846",
    label: "Memories Of Murdered Mothers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7847",
    label: "Delaware Chaarg",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7848",
    label: "Nima Ghamsari Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7849",
    label: "Welbridge Center For Poverty Prevention Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7850",
    label: "Life Resources Outreach Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7851",
    label: "Window Bros Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7852",
    label: "Friends Of Bob Behr Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7853",
    label: "Paragen Electronic Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7854",
    label: "Buttonwood Colored School Museum & Community Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7855",
    label: "Sifford & Wilson Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7856",
    label: "Sharon & Bob Ford Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7857",
    label: "Southern Delaware Womens Multisport Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7858",
    label: "Odessa High School Fine Arts Booster Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7859",
    label: "Frennea Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7860",
    label: "Adler Schuppert Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7861",
    label: "The Existential Earth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7862",
    label: "Delaware Womenade Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7863",
    label: "Pretty Big Project Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7864",
    label: "Delaware Urban Greens Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7865",
    label: "Sarkisian Foundation For The Arts And Engineering",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7866",
    label: "The Crook Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7867",
    label: "Grumpy Gramps Cycling Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7868",
    label: "G4 Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7869",
    label: "Cottonwood Spring",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7870",
    label: "Yearrrn Academy 365",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7871",
    label: "Hu Gallery Of Art Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7872",
    label: "Moore Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7873",
    label: "Sharanya And Prasad Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7874",
    label: "The Golden Valley Ranch Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7875",
    label: "Squareroot Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7876",
    label: "Madison Digital Asset Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7877",
    label: "Thomas Dupuis Jr Michelle Dupuis & Thomas Dupuis Tr Fbo Jimmy Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7878",
    label: "Kinder And Jeff Butler Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7879",
    label: "The Williamson Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7880",
    label: "Mente Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7881",
    label: "Altruistic Partners Empowering Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7882",
    label: "Drumwright Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7883",
    label: "The Vig Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7884",
    label: "Nyssa Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7885",
    label: "K6 Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7886",
    label: "Watersplash Giving Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7887",
    label: "D & E Cantal Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7888",
    label: "Jeff And Laura Putnam Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7889",
    label: "Delivering Our Own Reparations Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7890",
    label: "Changing Tomorrow Animal Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7891",
    label: "Srs Impact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7892",
    label: "Young Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7893",
    label: "Safe Haven Healing",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7894",
    label: "Delaware School Based Health Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7895",
    label: "Vines And Forest Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7896",
    label: "The Hy5 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7897",
    label: "Donald F And Janice L Blake Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7898",
    label: "Delaware Grassroots Organizing Fund Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7899",
    label: "Aguilera Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7900",
    label: "The Corliss Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7901",
    label: "The Chasnoff Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7902",
    label: "Peng Field Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7903",
    label: "The Noteman-Jones Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7904",
    label: "Blue Circle Health Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7905",
    label: "First Institute Of All Medicines",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7906",
    label: "Kids Create Together",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7907",
    label: "Akda Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7908",
    label: "Hudak-Flowers Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7909",
    label: "Delaware Angels Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7910",
    label: "Rocket Learning Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7911",
    label: "Cupcakes For Cancer Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7912",
    label: "John & Kristine Slater Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7913",
    label: "Ever Scouts Education Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7914",
    label: "Phrygian Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7915",
    label: "The J Lazy G Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7916",
    label: "Doctor James Zhou Liu Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7917",
    label: "Helen Yuehua Gu Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7918",
    label: "Vo Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7919",
    label: "Artemis Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7920",
    label: "High Impact Athletes",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7921",
    label: "Ampoh Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7922",
    label: "Cavallino Classic Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7923",
    label: "Northern Compass Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7924",
    label: "Flex Sports De Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7925",
    label: "The Borba Families Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7926",
    label: "Sobey Garrett Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7927",
    label: "In-Causa Foundation For Growth And Connection",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7928",
    label: "Link 4 Success Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7929",
    label: "National Auxiliary Of Lincoln University",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7930",
    label: "National Rural Lenders Roundtable",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7931",
    label: "Fundacion Centro Latino Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7932",
    label: "New Life Adult Enrichment Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7933",
    label: "Malengo Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7934",
    label: "Ahava18 Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7935",
    label: "Delaware Recreation Education And Athletics Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7936",
    label: "Vision Without Borders Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7937",
    label: "Chabad Of Southern Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7938",
    label: "National Miss Juneteenth Scholarship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7939",
    label: "Teamup Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7940",
    label: "Spritely Networked Communities Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7941",
    label: "Her Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7942",
    label: "Peace Love And Llamas Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7943",
    label: "Beck Family Giving Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7944",
    label: "Anne Ulnick Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7945",
    label: "American Youth Football Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7946",
    label: "Raes Pantry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7947",
    label: "Hugging Initiative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7948",
    label: "Project Green Pastures Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7949",
    label: "Powering Our Future Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7950",
    label: "Barrels Of Love Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7951",
    label: "Warrior Training Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7952",
    label: "Glowing Goose Production Company",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7953",
    label: "Blue Earth 360 Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7954",
    label: "Higher Ground Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7955",
    label: "Delmar Antique Fire Apparatus Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7956",
    label: "Express Language Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7957",
    label: "Galvez Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7958",
    label: "Peninsula Ladies Golf Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7959",
    label: "The Teddy Balkind Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7960",
    label: "Joe & Allison Markell Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7961",
    label: "Amazing Turnaround Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7962",
    label: "Beekeeping For Veterans Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7963",
    label: "Cares Lmm Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7964",
    label: "Diamond State Ducks Black",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7965",
    label: "Doyly Carte Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7966",
    label: "Amateur Athletic Union Of The United States",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7967",
    label: "Ignite Life Science Usa Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7968",
    label: "Jalen S Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7969",
    label: "Fort Miles Museum And Historical Area Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7970",
    label: "Avi It Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7971",
    label: "Alishan Charity Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7972",
    label: "Ordinal Research Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7973",
    label: "Advancement Project Education Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7974",
    label: "God Provides",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7975",
    label: "Tri-Again Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7976",
    label: "Tenets Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7977",
    label: "Health Educated Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7978",
    label: "Restorative Hope College Scholarship Program Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7979",
    label: "C & S Tan Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7980",
    label: "Max Hay Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7981",
    label: "Shanes Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7982",
    label: "Charter School Of Wilmington Ptso",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7983",
    label: "Grateful Acres",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7984",
    label: "Edith Dee Green Charitable Tr B",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7985",
    label: "Edith Dee Green Charitable Tr C",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7986",
    label: "The Luester T Mertz Legacy Trust For The Joyce Theater Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7987",
    label: "Luesther T Mertz Legacy Tr For The Public Theater",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7988",
    label: "Rose C Caselli Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7989",
    label: "Teton Impact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7990",
    label: "Tecumseh Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7991",
    label: "American Guild Of Organists Ago",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7992",
    label: "Sovereign Joy Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7993",
    label: "Athru Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7994",
    label: "Cast-Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7995",
    label: "Friends Of School Hill Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7996",
    label: "Delaware Health Equity Coalition",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7997",
    label: "Sparks Project Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7998",
    label: "Motivating Other Mothers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-7999",
    label: "Face The Facts De",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8000",
    label: "Kind Kids Early Learning Center Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8001",
    label: "Soul Pod Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8002",
    label: "Vatsim Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8003",
    label: "Mid-Atlantic Goat Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8004",
    label: "Ron Ludington Skaters Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8005",
    label: "Frantic Music Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8006",
    label: "Institute For Follicular Lymphoma Innovation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8007",
    label: "Rosaries & Scapulars Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8008",
    label: "The Ossuary",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8009",
    label: "Providence Community Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8010",
    label: "Arab Management Council Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8011",
    label: "Vu Nhan Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8012",
    label: "Cape Girls Soccer Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8013",
    label: "National Association Of Bankruptcy Trustees Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8014",
    label: "Modern Giving Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8015",
    label: "Mount Holly Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8016",
    label: "Li Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8017",
    label: "Thrive Girls Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8018",
    label: "Amateur Athletic Union Of The United States Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8019",
    label: "Miles For Mason Memorial Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8020",
    label: "The S Lee Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8021",
    label: "The Jay And Avital Cohen Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8022",
    label: "Arias Hair Love Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8023",
    label: "Women In Aviation International Delaware Diamond Chapter",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8024",
    label: "Sussex Pride Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8025",
    label: "Activating Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8026",
    label: "Special Operations Forces Innovators & Entrepreneurs Institu",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8027",
    label: "Fathers House Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8028",
    label: "Incarnation Ministries Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8029",
    label: "United-Wc Co",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8030",
    label: "Fit 4 Duty",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8031",
    label: "Promise Land Youth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8032",
    label: "Uplift Schools Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8033",
    label: "Neosultancy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8034",
    label: "Bais Chana High School Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8035",
    label: "B H S Hope",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8036",
    label: "Caribbean Culture Awareness Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8037",
    label: "Human Restoration Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8038",
    label: "Help Our People Elevate",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8039",
    label: "Mad Farmers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8040",
    label: "Door3 Qualified Disaster Relief Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8041",
    label: "Borsanera Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8042",
    label: "Second Chance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8043",
    label: "Inventaverse Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8044",
    label: "Iqlesia Pentecostes Un Nuevo Comienzo Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8045",
    label: "Do For Self",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8046",
    label: "Legends Elite Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8047",
    label: "Stand Up 2 Breast Cancer Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8048",
    label: "Weingarten Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8049",
    label: "National Association For The Advancement Of Future Minorities C",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8050",
    label: "Rescue Lives Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8051",
    label: "Artistic Force Creative & Performing Arts Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8052",
    label: "Heart & Valor Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8053",
    label: "Climate Science International Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8054",
    label: "Friends Of Pirates Lacrosse",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8055",
    label: "Empire Community Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8056",
    label: "Military Women In Maintenance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8057",
    label: "Helping Hands For Humanity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8058",
    label: "Done With Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8059",
    label: "Iglesia Pentecostal Jesucristo Pan De Vida - Juan 6 35",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8060",
    label: "La Plaza Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8061",
    label: "Delmarva Elite Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8062",
    label: "Diffusion Of Hope Charity Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8063",
    label: "Lorna Jordan Charitable Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8064",
    label: "Animal Healthcare Awareness Charity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8065",
    label: "Wife Her Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8066",
    label: "Friends Of West Africa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8067",
    label: "Queen Of Heaven & Earth House Of Restoration Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8068",
    label: "Intermarium Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8069",
    label: "No Mandate Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8070",
    label: "Mental Health Alternatives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8071",
    label: "Rescue For Christ Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8072",
    label: "Wecare World Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8073",
    label: "People In Mission International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8074",
    label: "Mtka Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8075",
    label: "Our Watch Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8076",
    label: "Elevations Transitional Living Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8077",
    label: "Ecosystems And Mental Health Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8078",
    label: "Rmv 2 Scholarships Plus Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8079",
    label: "Paiges Kindness Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8080",
    label: "Gbbc Giving Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8081",
    label: "Purposefully Transforming Lives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8082",
    label: "Carmen Rodriguez S Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8083",
    label: "Families Set Free Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8084",
    label: "Angelsrushin Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8085",
    label: "Roll The Cloud Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8086",
    label: "Living Waters Faith Christian Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8087",
    label: "Task Force Butler Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8088",
    label: "Pike Delta Eta Firetruck Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8089",
    label: "Clifford Stevens Scholarship Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8090",
    label: "Gramhal Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8091",
    label: "Friends Of Coopers Farm Cemetery Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8092",
    label: "Gharib Charity Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8093",
    label: "Hometown Helpers De",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8094",
    label: "Life On Lifes Terms Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8095",
    label: "Languages Of Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8096",
    label: "Los Padrinos Of The Usaf Academy Association Of Graduates Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8097",
    label: "Baldwin Family Foundation 501C3",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8098",
    label: "World Society Of Belief In Divine Socialism",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8099",
    label: "Be Together Today Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8100",
    label: "Amci Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8101",
    label: "New Hope Canoe Club Ministry - Team Atlantic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8102",
    label: "Pure Intention Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8103",
    label: "Unleash Her Potential- Community Development Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8104",
    label: "One Fact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8105",
    label: "B&R Hu Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8106",
    label: "Elizabeth Murray Ellis Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8107",
    label: "Ludba Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8108",
    label: "Rusty Rabbit Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8109",
    label: "Samaritan Thrift Store Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8110",
    label: "United Wish Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8111",
    label: "Stored In My Heart",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8112",
    label: "Brojora Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8113",
    label: "Willowmere Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8114",
    label: "E T Y Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8115",
    label: "Hindu And Sikh Religious Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8116",
    label: "Black In Physics Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8117",
    label: "Connie Bornman First Responder Relief Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8118",
    label: "Teach21 Enrichment Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8119",
    label: "Ding Lei Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8120",
    label: "Shondaland Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8121",
    label: "Vollman Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8122",
    label: "La Via De James Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8123",
    label: "Advancement Of Digital Art Museum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8124",
    label: "Seeafricagrow Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8125",
    label: "The Valour Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8126",
    label: "Northamericaseemaandhraassociation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8127",
    label: "Feeding 5000 Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8128",
    label: "Edmond F Leong Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8129",
    label: "Granite Partners Foundation Of Minnesota Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8130",
    label: "Green Seed Investment Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8131",
    label: "Rails Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8132",
    label: "Life Point Family Worship Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8133",
    label: "Jacobs Refuge Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8134",
    label: "Adult Transition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8135",
    label: "Kanchan Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8136",
    label: "Living The Dream Corvette Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8137",
    label: "Second Chance Latam Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8138",
    label: "Chapman Group Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8139",
    label: "Virgil Abloh Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8140",
    label: "Firm Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8141",
    label: "United Aid And Logistics Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8142",
    label: "Because Of Them Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8143",
    label: "Carry With Pride Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8144",
    label: "Room- A Sketchbook For Analytic Action",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8145",
    label: "Healthspan Alpha Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8146",
    label: "Unique Minds Changing Lives Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8147",
    label: "One Step At A Time Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8148",
    label: "I R A -Iriseagain Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8149",
    label: "Organization Of Chinese Career Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8150",
    label: "Graceland Medical Mission",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8151",
    label: "Leadhership International Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8152",
    label: "Southern Delaware Copd Coalition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8153",
    label: "Legacy Academy Of Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8154",
    label: "Play More Give Back Charity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8155",
    label: "Jin Xiong Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8156",
    label: "Abundance Legacy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8157",
    label: "Give With Love Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8158",
    label: "Dorothy G Henry Education Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-8159", label: "Sodelo", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-8160",
    label: "Republic Of Georgia Dog Refuge Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8161",
    label: "Art Society At Loblolly Acres Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8162",
    label: "Adulting Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8163",
    label: "Judah Life Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8164",
    label: "Million Mile Project Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8165",
    label: "Caring From The Heart Adult Programs And Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8166",
    label: "Isaiah Home 613",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8167",
    label: "Orhot Shmuel Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8168",
    label: "Humane Animal Partners Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8169",
    label: "Emerald Outreach Organization Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8170",
    label: "First State Abortion Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8171",
    label: "H & W Sanctuary Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8172",
    label: "Hope 929 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8173",
    label: "Raftin Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8174",
    label: "Pretty Moody Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8175",
    label: "Women Rock Wings Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8176",
    label: "Something 4 Us By Us",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8177",
    label: "Engineering The Future",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8178",
    label: "Cape Henlopen Football Booster Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8179",
    label: "Laverne Booker - Kornegay",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8180",
    label: "Northern Delaware Wrestling Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8181",
    label: "World Allstar Federation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8182",
    label: "Alpha Pi Nu Nursing Sorority Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8183",
    label: "Loyal Legacy Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8184",
    label: "Chai Charity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8185",
    label: "Nobarriers Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8186",
    label: "She Tank Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8187",
    label: "Humbly Assisting Humanity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8188",
    label: "Fishing For A Change Co",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8189",
    label: "In Cube Digital Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8190",
    label: "Bijoy International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8191",
    label: "Rvt Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8192",
    label: "Vernon Family Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8193",
    label: "Pampalena C Watson Excellence In Education Scholarship",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8194",
    label: "Chispa Foundation Coalition Of Hispanics For Progressive Action",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8195",
    label: "Clarke Education Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8196",
    label: "Fore Joe Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8197",
    label: "Gastro Girl Patient Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8198",
    label: "Pam Price Community Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8199",
    label: "Exor Foundation Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8200",
    label: "Finao 1 Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8201",
    label: "Captain Paul Watson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8202",
    label: "Ssu Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8203",
    label: "Owig Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8204",
    label: "House Of Prayer For All People Elijah Endtime Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8205",
    label: "Gba Equality Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8206",
    label: "Dolley Freedom Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8207",
    label: "Reblue Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8208",
    label: "Sails Up Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8209",
    label: "Reset Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8210",
    label: "Stony Brook Black And Latino Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8211",
    label: "Climate Literacy And Community Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8212",
    label: "Peoples Community Center-Community Development Corpo",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8213",
    label: "Her Care Clinic Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8214",
    label: "Nurses Nextdoor Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8215",
    label: "Rewired Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8216",
    label: "Agile In Color Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8217",
    label: "Rise Above All Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8218",
    label: "Bethel Cares",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8219",
    label: "Jesses Place Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8220",
    label: "Zeta Scholars Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8221",
    label: "Power Of Partnerships",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8222",
    label: "The Mello Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8223",
    label: "Follrichs Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8224",
    label: "Delaware Bears Youth Footbal Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8225",
    label: "Intellectual Dark Web Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8226",
    label: "Anderson Discoveries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8227",
    label: "Full Bucket Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8228",
    label: "Qxi Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8229",
    label: "Descendants Of Jane Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8230",
    label: "Wilmington Community Growth Organization Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8231",
    label: "Fix Her Crown Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8232",
    label: "Gods Grain Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8233",
    label: "Bessler Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8234",
    label: "The Sammann Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8235",
    label: "Video Game Gospel Ministries Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8236",
    label: "Community Health Workers Association Of Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8237",
    label: "Breakwater Alliance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8238",
    label: "Team4Humanity Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8239",
    label: "New Life Church & Community Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8240",
    label: "The Hartly Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8241",
    label: "Oasis Of Love For The Nations Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8242",
    label: "Smyrna Center Of Diversity",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8243",
    label: "Duck Creek Cemetery Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8244",
    label: "The Foster Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8245",
    label: "Vaishnav Sangh Of Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8246",
    label: "Passion And Perseverance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8247",
    label: "Culley Carlson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8248",
    label: "Pottenger Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8249",
    label: "Choir School Community Services Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8250",
    label: "Digital Education And Safety Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8251",
    label: "Mid-Atlantic Hearing Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8252",
    label: "Z-Gi Giving Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8253",
    label: "The Melanie Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8254",
    label: "The Frank De Angeli Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8255",
    label: "Kellam Trinity Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8256",
    label: "Calm Nonprofit Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8257",
    label: "Nu Beginning Therapy Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8258",
    label: "Shecanic Apprenticeship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8259",
    label: "Picture Postcard History Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8260",
    label: "Women Of Exception",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8261",
    label: "Haskell Peak Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8262",
    label: "The William And Pamela Rayburn Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8263",
    label: "Seaford Community Garden",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8264",
    label: "Peace Week Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8265",
    label: "Kairos Philanthropy Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8266",
    label: "Delaware Risers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8267",
    label: "Black Women In Aviation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8268",
    label: "Revak Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8269",
    label: "Tobyns Triumph",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8270",
    label: "W U W Gurl Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8271",
    label: "Hearts & Souls Of Humanity Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8272",
    label: "Gscp Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8273",
    label: "Israel Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8274",
    label: "Garon & Suh Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8275",
    label: "Happy Willow Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8276",
    label: "Robert Richardson Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8277",
    label: "Seed And Food Relief Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8278",
    label: "Staytonville Cemetery Restoration & Maintenance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8279",
    label: "Chun L And Hsin Y Hsieh Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8280",
    label: "Trubel&Co",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8281",
    label: "The Mark And Becky Joye Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8282",
    label: "Congregation Software Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8283",
    label: "Neptunes Navy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8284",
    label: "Cg Helps Ukraine",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8285",
    label: "Guided Approach To Positivity Youth Mentoring Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8286",
    label: "News Product Alliance Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8287",
    label: "Renaissance Community Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8288",
    label: "Be The Light Global",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8289",
    label: "Global Emancipation Network Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8290",
    label: "Heart For The City De Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8291",
    label: "Life After Ect Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8292",
    label: "Global Portal Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8293",
    label: "Valkyrie Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8294",
    label: "New Beginning Community Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8295",
    label: "Overcome The World",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8296",
    label: "Cape Henlopen Boys Basketball Booster Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8297",
    label: "Young Pro Global 2030",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8298",
    label: "Dsd Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8299",
    label: "Sangro Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8300",
    label: "Emergent Repair Relief",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8301",
    label: "Carter Community Advancement Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8302",
    label: "Ocean Conservation International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8303",
    label: "Giuseppe Verdi Club Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8304",
    label: "Delmar Youth Basketball League",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8305",
    label: "Dylan Rodriguez I Made It Home Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8306",
    label: "Paw Treats By Maggie And Friends Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8307",
    label: "Bridges Between Us Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8308",
    label: "K-Day Geriatric Research Center Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8309",
    label: "Joyful Heritage Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8310",
    label: "Teach Rural Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8311",
    label: "Principality Of Mathair Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8312",
    label: "Sussex Preservation Coalition Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8313",
    label: "Beneficial Ai Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8314",
    label:
      "Praise God Messianic Ministry Us Minority Chamber Of Agri & Ind Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8315",
    label: "Fb4K Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8316",
    label: "Walton Family Charitable Foundation 2022",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8317",
    label: "Delaware Ffa Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8318",
    label: "Newcastle Cotillion Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8319",
    label: "Delaware Valley Scale Modelers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8320",
    label: "Cpt Brian Faunce Memorial Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8321",
    label: "Masjid Baitul Aman Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8322",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8323",
    label: "A New Beginning Sam Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8324",
    label: "Pi Mu Epsilon Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8325",
    label: "Pi Mu Epsilon Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8326",
    label: "Sigma Theta Tau International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8327",
    label: "Depression And Bipolar Support Alliance",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8328",
    label: "Quality Deer Management Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8329",
    label: "Quality Deer Management Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8330",
    label: "Honor Society Of Phi Kappa Phi",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8331",
    label: "All About Pink Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8332",
    label: "Lions International Trading Pins Club Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8333",
    label: "Babcock Urological Endow Fd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8334",
    label: "Operation Warm Heart - Dover Afb First Sergeants Council",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8335",
    label: "Morning Star Institutional Church Of God In Christ",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8336",
    label: "Pop Warner Little Scholars Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8337",
    label: "London Village Baptist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8338",
    label: "Centerpoint Community",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8339",
    label: "Arc Of Delaware Inc Affiliates Of The Arc Of Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8340",
    label: "Milton Lions Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8341",
    label: "Harrington New Century Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8342",
    label: "Lakeside Community Homeowners Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8343",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8344",
    label: "Rebuilders Apostolic Ministeries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8345",
    label: "U S China Health Products Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8346",
    label: "Community Unity Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8347",
    label: "New Castle 100 Archers Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8348",
    label: "Delaware Four Wheelers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  { value: "nonprofit-8349", label: "Sigpa", color: "#00B8D9", isFixed: true },
  {
    value: "nonprofit-8350",
    label: "Camp Elna",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8351",
    label: "Soil And Water Conservation Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8352",
    label: "Omega Psi Phi Fraternity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8353",
    label: "Sigma Outreach And Education Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8354",
    label: "Military Order Of The Cootie Of The United States",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8355",
    label: "Knights Of Peter Claver",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8356",
    label: "Noor Foundation Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8357",
    label: "African American Heritage Center Of Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8358",
    label: "Three Sisters Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8359",
    label: "Dover First Seventh Day Adventist Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8360",
    label: "Woodland Homes Civic Association Inc Newport Heights",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8361",
    label: "Sussex County Stamp Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8362",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8363",
    label: "Delta Kappa Gamma Society International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8364",
    label: "Middletown Area Chamber Of Commerce Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8365",
    label: "Family First International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8366",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8367",
    label: "Wilmington High School Alumni Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8368",
    label: "National Association For Black",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8369",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8370",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8371",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8372",
    label: "Healthy Start A Bankers Healthcare Group Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8373",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8374",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8375",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8376",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8377",
    label: "The Desca Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8378",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8379",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8380",
    label: "Ancient Egyptian Arabic Order Nobles Mystic Shrine Of N & S Amer",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8381",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8382",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8383",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8384",
    label: "Moccasin Lake Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8385",
    label: "Womensport International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8386",
    label: "Pinkerton Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8387",
    label: "Lucky Seven Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8388",
    label: "American Federation Of State County & Municipal Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8389",
    label: "Edwards Mother Earth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8390",
    label: "Peach Tree Acres Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8391",
    label: "Delaware State Education Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8392",
    label: "Delaware State Education Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8393",
    label: "Delaware State Education Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8394",
    label: "Delaware State Education Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8395",
    label: "Sigma Phi Alpha Dental Hygiene Honor Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8396",
    label: "Spiritual Assembly Of The Bahais Of New Castle County West",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8397",
    label: "Spiritual Assembly Of The Bahais Of Wilmington",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8398",
    label: "Gods Word On It Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8399",
    label: "Society Of Health And Physical Educators Of Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8400",
    label: "American Legion Auxiliary",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8401",
    label: "Great Council Of The U S Improved Order Of Red Men Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8402",
    label: "Great Council Of The U S Improved Order Of Red Men Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8403",
    label: "Great Council Of The U S Improved Order Of Red Men Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8404",
    label: "Sigma Theta Tau International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8405",
    label: "Rodel Charitable Foundation De",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8406",
    label: "Rotary International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8407",
    label: "Marine Corps League",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8408",
    label: "American Statistical Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8409",
    label: "East Sussex Lodge No 2542 Loyal Order Of Moose",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8410",
    label: "American Federation Of State County & Municipal Employees",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8411",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8412",
    label: "National Association For The Advancement Of Colored People",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8413",
    label: "Mary Mikesell Charitable Tr",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8414",
    label: "East Sussex Chapter 2204-Women Of The Moose",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8415",
    label: "John And Diane Scelfo Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8416",
    label: "Sigma Gamma Rho Sorority Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8417",
    label: "Blood Of The Lamb Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8418",
    label: "Gienapp Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8419",
    label: "International Police Association United States Section",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8420",
    label: "Temple Of Praise Worldwide Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8421",
    label: "Freedom In Motion Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8422",
    label: "Holistic Helpcare Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8423",
    label: "Friends Of Pjl Madagascar",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8424",
    label: "No Longer Running Behind Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8425",
    label: "One Of Us Global Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8426",
    label: "Dong And Yu Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8427",
    label: "Diao & Wu Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8428",
    label: "Keith Elliott Family Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8429",
    label: "Jay & Grace Wang Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8430",
    label: "The Filbrandt Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8431",
    label: "Break New Ground Charitable Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8432",
    label: "Prashtopia Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8433",
    label: "Team Cure Cholangio",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8434",
    label: "Hvako Mbando Heritage Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8435",
    label: "Cy-Rr-F Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8436",
    label: "Kamps Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8437",
    label: "Fernando & Rosa Otero Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8438",
    label: "Cline Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8439",
    label: "Zbc Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8440",
    label: "Verdus Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8441",
    label: "Amerilife Gives Back Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8442",
    label: "The Moonshine Impact Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8443",
    label: "Rivers Legacy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8444",
    label: "Bibliophile Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8445",
    label: "Leber Legacy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8446",
    label: "Downing-Giron Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8447",
    label: "Sabino Family Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8448",
    label: "Social Awakening",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8449",
    label: "Roc Programming Language Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8450",
    label: "Patan Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8451",
    label: "Matthew & Pamela Sutherland Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8452",
    label: "Cullens For A Cause Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8453",
    label: "Havoc High School Hockey",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8454",
    label: "Against All Odds Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8455",
    label: "Tsalach Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8456",
    label: "Kritters Kafe Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8457",
    label: "Kumar Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8458",
    label: "Melusines Haven",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8459",
    label: "Barr-Campbell Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8460",
    label: "Amateur Athletic Union Of The United States",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8461",
    label: "Talanta Africa Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8462",
    label: "Next Generation Outcome",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8463",
    label: "Healing Streams Network Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8464",
    label: "Portosaurs Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8465",
    label: "Felon Friendly",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8466",
    label: "Nascod Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8467",
    label: "Legacy Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8468",
    label: "Driver Development Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8469",
    label: "M8L Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8470",
    label: "Dilwyne Badminton Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8471",
    label: "Institute For Social Research Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8472",
    label: "Smyrna Nfl Flag",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8473",
    label: "Christo Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8474",
    label: "Forecasting Research Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8475",
    label: "No Promises",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8476",
    label: "Delaware Elementary Teacher S Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8477",
    label: "Fauna Mental Health Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8478",
    label: "Goals - Girls Opportunities And Leadership In Stem",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8479",
    label: "Weprevail Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8480",
    label: "Gfwc Blue Hen Chicks Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8481",
    label: "Nommos For The Oceans",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8482",
    label: "Faa Managers Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8483",
    label: "Mastering Adaption & Development Center Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8484",
    label: "Proximity Builders",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8485",
    label: "Peace Page Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8486",
    label: "Justice Think Tank",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8487",
    label: "Code4Dev Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8488",
    label: "Full Community Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8489",
    label: "Anishinaabe",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8490",
    label: "Art Of Giving Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8491",
    label: "Disability In Publishing Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8492",
    label: "Schell Brothers Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8493",
    label: "Masch Risk Management Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8494",
    label: "Bapa Network Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8495",
    label: "Londyns Corner Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8496",
    label: "Rosewater Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8497",
    label: "Cr Wrestling Parents Assocication",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8498",
    label: "Corgis For A Cause Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8499",
    label: "Brick Mill Booster",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8500",
    label: "King Foundation Of Sussex",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8501",
    label: "Go Create Project",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8502",
    label: "Newport Skatepark",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8503",
    label: "Books For The Future",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8504",
    label: "Community Bloom Outreach Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8505",
    label: "Remedy Social Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8506",
    label: "Mosaic Hearts Organization",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8507",
    label: "Phoenix Family Resources",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8508",
    label: "Confers Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8509",
    label: "Bloop Museum Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8510",
    label: "Wellness Without Borders Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8511",
    label: "Digital Growth And Education Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8512",
    label: "A Dude With Food Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8513",
    label: "Interfaith Community Housing Rental Portfolio Llc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8514",
    label: "Kevin Regan Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8515",
    label: "Sunset Bridge Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8516",
    label: "Task Force Mentor Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8517",
    label: "Yoga 4 Everybody Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8518",
    label: "Gracious Angelic Care Homes Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8519",
    label: "South Asian Americans For Change Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8520",
    label: "Trans Formative Schools Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8521",
    label: "Amlat Ministry Partners Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8522",
    label: "Life Restored Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8523",
    label: "Pushing Through Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8524",
    label: "Wilmington Unity Project Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8525",
    label: "William Penn Baseball Booster Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8526",
    label: "Pretty Girl Power",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8527",
    label: "Verily Magazine Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8528",
    label: "Civil Liberties Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8529",
    label: "Mriya Report Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8530",
    label: "Social Ai Research Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8531",
    label: "Godel Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8532",
    label: "Refuge Of Hope",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8533",
    label: "Amateur Athletic Union Of The United States",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8534",
    label: "Helpers Org Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8535",
    label: "Re Precision Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8536",
    label: "Foundation For Stoic Philosophy",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8537",
    label: "Kailey J Morrell Memorial Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8538",
    label: "Helping Hands And Hoofs Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8539",
    label: "Save Oromia Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8540",
    label: "Salone Renaissance Foundation Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8541",
    label: "Mitzvah Distributions Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8542",
    label: "Simply Strong Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8543",
    label: "Fortuitous Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8544",
    label: "India Philanthropy Alliance Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8545",
    label: "Rotary International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8546",
    label: "Carpentries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8547",
    label: "Ela Care Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8548",
    label: "Delmarva Youth And Family Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8549",
    label: "Tullopy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8550",
    label: "U2 Elite Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8551",
    label: "Zachary Scott Jones Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8552",
    label: "New Direction Academic And Technology Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8553",
    label: "Delaware Camp Conquer Lions Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8554",
    label: "Uniting Brown Ballers Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8555",
    label: "Yenching Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8556",
    label: "Golden Clover Association Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8557",
    label: "Friends Of Caesar Rodney Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8558",
    label: "Ocean Forest Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8559",
    label: "Brightway Education Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8560",
    label: "The Laska Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8561",
    label: "Ignite Firewalk Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8562",
    label: "Rose In Bloom Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8563",
    label: "Meliorism Works Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8564",
    label: "Buenorang Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8565",
    label: "Afroarts And Media Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8566",
    label: "Southeast Regional Middle East And Islamic Studies Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8567",
    label: "National Institute For Mental Health & Technology",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8568",
    label: "Royal House Of Sori Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8569",
    label: "Woman Life & Freedom Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8570",
    label: "302 Lacrosse",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8571",
    label: "Hilkiah Family Life Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8572",
    label: "Caving Academy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8573",
    label: "Endtime Church Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8574",
    label: "Waters Of Abundant Life Mwc Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8575",
    label: "Roots Community Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8576",
    label: "Compassion Street Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8577",
    label: "An Corr Pipes And Drums Ltd",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8578",
    label: "Oyster River Pages Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8579",
    label: "Fortis & Friends Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8580",
    label: "Duffle Bags And More",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8581",
    label: "Terminate Trafficking",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8582",
    label: "Affia Affia Family Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8583",
    label: "Dutch Neck Sportsman Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8584",
    label: "Arte Youth Program Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8585",
    label: "Route 1 Sports",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8586",
    label: "Gridcrowd Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8587",
    label: "Vatusa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8588",
    label: "Home Grown City Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8589",
    label: "Jude Akiyjika Ministries Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8590",
    label: "Capstone Community Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8591",
    label: "Skills Non Profit Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8592",
    label: "No Limit Nonprofit Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8593",
    label: "Veterans Enhancement Community Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8594",
    label: "Meesi Fondation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8595",
    label: "Safe Haven Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8596",
    label: "Delaware Super Cup Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8597",
    label: "Paul Walters Outpost Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8598",
    label: "Scn2A Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8599",
    label: "Buddysplace Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8600",
    label: "Healing The Roots Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8601",
    label: "Delaware Interscholastic Golf Coaches Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8602",
    label: "Missionary World Relief Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8603",
    label: "Neighbors United",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8604",
    label: "Love For Newcomers Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8605",
    label: "Psychiatric Leadership Collaborative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8606",
    label: "Delaware Fraternal Order Of Police Lodge 10 Fund",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8607",
    label: "Save Groundwater Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8608",
    label: "Route Surgeon Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8609",
    label: "Southeastern Delaware Artist Studio Tour",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8610",
    label: "Bris Fight For Change",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8611",
    label: "Tomorrows Leaders Of Delaware Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8612",
    label: "Delaware Diamond Elite Softball Origination",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8613",
    label: "Brandywine Partners W3R Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8614",
    label: "Refund Real Estate Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8615",
    label: "Health Cant Wait Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8616",
    label: "From The Core Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8617",
    label: "Justin Mcintyre Charitable Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8618",
    label: "Brain Cancer Answer Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8619",
    label: "Illinois Design Challenge Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8620",
    label: "In His Footsteps Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8621",
    label: "Psg Equity Charitable Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8622",
    label: "Brandywine Hundred Historical Society Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8623",
    label: "Charter School Athletic Association Of New York Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8624",
    label: "Incredible One Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8625",
    label: "Christians Seeking Justice Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8626",
    label: "M A S K Off Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8627",
    label: "Ejaad Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8628",
    label: "Anka Funds Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8629",
    label: "Planned Parenthood Direct Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8630",
    label: "Hellas National Baseball Team Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8631",
    label: "Practically Yours Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8632",
    label: "Media Arts Nonprofit Corpoation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8633",
    label: "River Life Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8634",
    label: "Green Hope Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8635",
    label: "Honey Hole Youth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8636",
    label: "Consul Securities Corp",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8637",
    label: "Empowered Voice Initiative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8638",
    label: "Eaa Delmarva Chapter 1683 Club Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8639",
    label: "Teckethix Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8640",
    label: "Hands Of Favor Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8641",
    label: "Victor Preston Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8642",
    label: "Mydefense Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8643",
    label: "American Academy Of Sciences And Letters Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8644",
    label: "Good Friends Of The First State Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8645",
    label: "Pdx Bots Co",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8646",
    label: "We Re Listening Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8647",
    label: "Heva Housing Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8648",
    label: "Pennsylvania Mill Save Initiative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8649",
    label: "James R Kyger Jr Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8650",
    label: "A World Of Good Initiative Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8651",
    label: "Triad Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8652",
    label: "Richey Elementary Pto Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8653",
    label: "New Music In New York And North America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8654",
    label: "Vedic Promise Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8655",
    label: "Us 4 No More",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8656",
    label: "Executive Insider Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8657",
    label: "Yellowill Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8658",
    label: "Sarah Bodman Greenhill Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8659",
    label: "Delaware Bat Rehabilitation And Conservation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8660",
    label: "Labours Of Love Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8661",
    label: "Heritage Impact Capital Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8662",
    label: "Uncommon Heroes Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8663",
    label: "Too Much Heart",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8664",
    label: "Rising Tide Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8665",
    label: "Future Leaders Development Group",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8666",
    label: "Electric Athletics Parent Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8667",
    label: "Serve The People Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8668",
    label: "Dream Force Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8669",
    label: "Giving To The Hopeful Or Hopeless Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8670",
    label: "Ensaniya Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8671",
    label: "Kusum-Jyoti Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8672",
    label: "Quest Fellowship Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8673",
    label: "All Val S Children Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8674",
    label: "Funds For Child Poverty Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8675",
    label: "Ascendia Research Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8676",
    label: "Tarbell Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8677",
    label: "Pape Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8678",
    label: "Jxtx Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8679",
    label: "Volunteers For Veterans Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8680",
    label: "Alianza Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8681",
    label: "American Association Of University Women Coastal Georgetown Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8682",
    label: "Stevenson Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8683",
    label: "National Divorce Support Alliance Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8684",
    label: "Scarletts Hope Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8685",
    label: "Rcg87 Foundation Usa",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8686",
    label: "Angioatlas",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8687",
    label: "Dsh Elite Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8688",
    label: "Up Unlimited Possibilities Through Education And Sports Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8689",
    label: "Gabriels German Shepherd Dog Rescue Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8690",
    label: "Tssr Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8691",
    label: "Elevation Growth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8692",
    label: "Kingdom Ambassadors Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8693",
    label: "Delaware Stallions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8694",
    label: "Family Oriented Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8695",
    label: "Global Animal Rescue And Response Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8696",
    label: "Cloud Innovation Syndicate",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8697",
    label: "Dmv Destroyers",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8698",
    label: "Grooming To Greatness Incorporated",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8699",
    label: "Data Institute Of Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8700",
    label: "Dandelion Philosophy Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8701",
    label: "Postnatal Support Advocates Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8702",
    label: "Dream Keepers Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8703",
    label: "Modern Outcomes Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8704",
    label: "Toivoa Coaching",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8705",
    label: "Phoenix Charity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8706",
    label: "Hub 302 Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8707",
    label: "Community Life Center",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8708",
    label: "Delaware Caribbean Events",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8709",
    label: "Adinkra Alliance Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8710",
    label: "K9 Service Companion Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8711",
    label: "Dimensional Neighbors Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8712",
    label: "Makind A Difference Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8713",
    label: "Manjerico Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8714",
    label: "Sunbird Goodside Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8715",
    label: "Delaware Field Of Dreams Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8716",
    label: "Earth And Us Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8717",
    label: "United In Film Usa Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8718",
    label: "Dsfg Cares",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8719",
    label: "Z Revolution Center Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8720",
    label: "Us Mef2C Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8721",
    label: "Preserve Our Park Coalition Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8722",
    label: "Greater Life International Ministry Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8723",
    label: "Italian-American Heritage Club Of Sussex County Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8724",
    label: "International Fusion Energy Consortium",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8725",
    label: "See My Heart Services Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8726",
    label: "Delaware Lodge 37 Af & Am Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8727",
    label: "Institute For Sexual Empowerment",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8728",
    label: "Red Bird Legacy Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8729",
    label: "Conversations In Color Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8730",
    label: "Feed De",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8731",
    label: "Concord Mall Playhouse Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8732",
    label: "Dikan Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8733",
    label: "Johnetta Betsch Cole Legacy Institute Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8734",
    label: "Mayday Moms Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8735",
    label: "Suburban Protector Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8736",
    label: "Chap Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8737",
    label: "Una Mejor Manera Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8738",
    label: "Steam Pathways Initiative",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8739",
    label: "Nurturing Tomorrow Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8740",
    label: "Star Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8741",
    label: "Be The Plug Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8742",
    label: "Angel Iq Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8743",
    label: "Conrad Sports Boosters",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8744",
    label: "Delaware Community Improvement Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8745",
    label: "Ready Force Cyber",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8746",
    label: "House That Nana Built Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8747",
    label: "Alliance For Hospitality Equity And Diversity Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8748",
    label: "Clayton Elementary Pto",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8749",
    label: "Rho Sigma Alpha Sorority Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8750",
    label: "Phoenix Performance Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8751",
    label: "Delaware Healthcare Sterile Processing Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8752",
    label: "Culture For Peace Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8753",
    label:
      "Alternative Dwelling Foundation Inc Delaware Non-Profit Corporation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8754",
    label: "Sojourners Of Del Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8755",
    label: "Catmandu Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8756",
    label: "Natural Glow Self Love Club",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8757",
    label: "Scholastic Chess League Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8758",
    label: "Techforce Counseling & Community Services",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8759",
    label: "Early Transition In Tech Assocation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8760",
    label: "Snowlight Learning Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8761",
    label: "Delaware Made",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8762",
    label: "Free Riders Independent Mc Delaware Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8763",
    label: "Afloat Plan Home Limited",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8764",
    label: "Harvest Healing Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8765",
    label: "Veterans Fighting Fascism Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8766",
    label: "Build A Dream Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8767",
    label: "Purposeful Ventures Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8768",
    label: "Xaviers Cafe S Nonprofit Outreach Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8769",
    label: "Seed Of Hope Outreach International Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8770",
    label: "Techhope Transitions Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8771",
    label: "Pret Pet Living Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8772",
    label: "Blakstaff Management Group Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8773",
    label: "Sophos Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8774",
    label: "Emerging Youth Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8775",
    label: "Opinion Two Nonprofit Organization Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8776",
    label: "Troops Of Saint George Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8777",
    label: "St Pauls Alumni Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8778",
    label: "Nacopay Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8779",
    label: "Christian Life Coaches Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8780",
    label: "Upper Room International Christian Center Delaware",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8781",
    label: "South Asia Speaks Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8782",
    label: "Intersectional Ventures Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8783",
    label: "United Ostomy Association Of America Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8784",
    label: "Hugh Obrian Youth Leadership",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8785",
    label: "Toastmasters International",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8786",
    label: "International Forest Products Transport Association",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8787",
    label: "The Upjohn Fund Of San Francisco",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8788",
    label: "Patterson Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8789",
    label: "Iglesia De Cristo Torre Fuerte",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8790",
    label: "Don And Roy Splawn Charitable Foundation West Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8791",
    label: "Oates Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8792",
    label: "Dixon & Carol Doll Family Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8793",
    label: "Nancy C And Dale Dougherty Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8794",
    label: "Dewey Beach Feral Cat Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8795",
    label: "Eshwar Institute",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8796",
    label: "River Of Life Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8797",
    label: "American Legion Auxiliary",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8798",
    label: "Change Of Heart Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8799",
    label: "Hoyt Memorial Cme Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8800",
    label: "Fraternal Order Of Eagles",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8801",
    label: "Icons Community Empowerment Initiatives",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8802",
    label: "American Malacological Society",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8803",
    label: "Flip Wilson Char Tr-Denver",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8804",
    label: "The Association Of Research Institutes In Art History",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8805",
    label: "People Set Free Ministries",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8806",
    label: "Los Angeles Chamber Orchestra Foundation 208C0700",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8807",
    label: "The Three Sisters Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8808",
    label: "European Council Of International Schools Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8809",
    label: "Fiduciary Portfolio Investments Inc",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8810",
    label: "New Covenant Christian Church",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8811",
    label: "The Gromet Foundation",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "nonprofit-8812",
    label: "Cepa Foundation Inc",
    color: "#00B8D9",
    isFixed: true,
  },
];
