import { Box, Button, Grid, Typography } from "@mui/material";
import { ThemeProvider, createTheme, responsiveFontSizes, styled } from "@mui/material/styles";
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FaFistRaised } from "react-icons/fa";
import { SectionHeader } from "../../components";
import { data } from "../../data/data_file";


export default function HowWeWorkBottomSection() {
  return (
    <ThemeProvider theme={theme}>
      <Grid container className="cl-section-row-content-wrap" style={{ paddingTop: 0, justifyContent: "center" }}>
        <Grid item sx={sectionStyle} container style={{ alignItems: "center" }}>
          <SectionHeader sectionTitle={"How We Work"} />
          {data.about_us.how_we_work.map((item, index) =>
            index % 2 === 0 ? (
              <Grid key={index} sx={{
                padding:"7.14vw 0",flexDirection:"row",display:"flex", alignItems:"center",
                "@media screen and (max-width:900px)": {
                flexDirection:"column-reverse",
              },}}>
                <Grid item xs={12} sm={12} md={6} lg={6} >
                  <ScrollAnimation animateIn="slideInLeft">
                    <img
                      src={item.image}
                      style={{ width: "100%", borderRadius: "40px",  objectPosition: "top", objectFit: "cover" }}
                      alt={"background"}
                      loading="lazy"
                    />
                  </ScrollAnimation>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}  sx={{"@media screen and (min-width:900px)": {
                paddingLeft:"3.14vw",
                
              },}}  >
                  <ScrollAnimation animateIn="slideInRight">
                    <Grid
                      item
                      container
                      alignContent="left"
                      justifyContent="left"
                    >
                      <Box
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 1,
                          boxShadow: "3px 5px 5px lightgrey"
                        }}
                      >
                        <FaFistRaised size={40} style={{ color: "#F1B9D0" }} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      container
                      sx={{ my: 1 }}
                      alignContent="left"
                      justifyContent="left"
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "2rem",
                          lineHeight: "2rem",
                          // fontSize: { xs: "5.0vw", sm: "5.0vw", md: "2.0vw", lg: "2.5vw" },
                          marginTop: { xs: "10px", sm: "10px" }
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      sx={{ my: 1 }}
                      alignContent="left"
                      justifyContent="left"
                    >
                      <Typography variant="subtitle3" sx={{ textAlign: "left" }}>
                        {item.snippet}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextButton href={item.link} variant="contained">
                        {item.call_to_action}
                      </TextButton>
                    </Grid>
                  </ScrollAnimation>
                </Grid>
              </Grid>
            ) : (
              <Grid key={index} sx={{
                padding:"3.14vw 0",flexDirection:"row",display:"flex",alignItems:"center",
                "@media screen and (max-width:900px)": {
                flexDirection:"column",
                
              },}}>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{"@media screen and (min-width:900px)": {
                paddingRight:"3.14vw",
                
              },}}>
                  <ScrollAnimation animateIn="slideInLeft">
                    <Grid
                      item
                      container
                      alignContent="left"
                      justifyContent="left"
                    >
                      <Box
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          p: 1,
                          boxShadow: "3px 5px 5px lightgrey"
                        }}
                      >
                        <FaFistRaised size={40} style={{ color: "#F1B9D0" }} />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      container
                      sx={{ my: 1 }}
                      alignContent="left"
                      justifyContent="left"
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "2rem",
                          lineHeight: "2rem",
                          // fontSize: { xs: "5.0vw", sm: "5.0vw", md: "2.0rem", lg: "2.5rem" },
                          marginTop: { xs: "10px", sm: "10px" }
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      sx={{ my: 1 }}
                      alignContent="left"
                      justifyContent="left"
                    >
                      <Typography variant="subtitle3" sx={{ textAlign: "left" }}>
                        {item.snippet}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <TextButton href={item.link} variant="contained">
                        {item.call_to_action}
                      </TextButton>
                    </Grid>
                  </ScrollAnimation>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} style={{width:"100%"}}>
                  <ScrollAnimation animateIn="slideInRight">
                    <img
                      src={item.image}
                      style={{ width: "100%", borderRadius: "40px" }}
                      alt={"background"}
                      loading="lazy"
                    />
                  </ScrollAnimation>
                </Grid>
              </Grid>
            ))}

        </Grid>




        <CustomButton
          variant="contained"
          href="/programs"
          sx={{
            marginBottom: { xs: "40px", sm: "40px", md: "80px", lg: "80px" },
            width:"500px",
            "@media screen and (max-width:900px)": {
              width:"80%",
            },
          }}
        >
          View Our Programs
        </CustomButton>
      </Grid>
    </ThemeProvider>
  );
}


let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h4 = {
  fontSize: "1.8vw",
  fontWeight: "900",
  textAlign: "left",
  fontFamily: "Changa",
};

theme.typography.h3 = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "3vw",
  lineHeight: "1.1em",
  "@media screen and (max-width:960px)": {
    fontSize: "28px !important",
    lineHeight: "1.1em",
  },
};

theme.typography.subtitle1 = {
  lineHeight: "1.2em",
  marginLeft: "7.14vw",
  fontSize: "2.2vw",
  fontWeight: 500,
  color: "#000",
  fontFamily: "Changa",
  alignSelf: "center",
  "@media (max-width: 1077.8443113772px)": {
    fontSize: "18px",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft: "0",
    // marginTop: "16px",
  },
};

theme.typography.subtitle2 = {
  lineHeight: "1.2em",
  fontSize: "1.6vw",
  fontWeight: 500,
  fontFamily: "Changa",
  "@media (max-width: 1077.8443113772px)": {
    fontSize: "18px",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft: "0",
    // marginTop: "16px",
  },
};

theme.typography.subtitle3 = {
  lineHeight: "1.2em",
  fontSize: "1.2vw",
  fontWeight: 500,
  fontFamily: "Changa",
  textAlign: "left",
  "@media (max-width: 1077.8443113772px)": {
    fontSize: "18px",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft: "0",
  },
};

const TextButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "700",
  textAlign: "left",
  justifyContent:"flex-start",
  lineHeight: "1.2rem",
  fontSize: "20px",
  padding: 0,
  paddingBottom: "20px",
  width:"100%",
  color: "rgb(98, 182, 208)",
  backgroundColor: "transparent",
  boxShadow: "none",
  textTransform: "capitalize",
  "&:hover": {
    color: "rgb(98, 182, 208)",
    backgroundColor: "transparent",
    boxShadow: "none",
  }
});

const sectionStyle = {
  paddingTop: "3.14vw",
  paddingLeft: "7.14vw",
  paddingRight: "7.14vw",
  "@media screen and (max-width: 790px)": {
    paddingTop: "7.28vw",
    paddingBottom: "0",
  },
};

const CustomButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "900",
  backgroundColor: "rgb(98, 182, 208)",
  fontSize: "20px",
  borderRadius: "30px",
  width: "60%",
  paddingBottom: "10px",
  paddingTop: "10px",
  // width:"100%",
  "&:hover": {
    backgroundColor: "#FFFF",
    color: "rgb(98, 182, 208)",
  },
});
