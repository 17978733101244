import { Box, CircularProgress, CssBaseline, Grid, ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import api from '../../api';
import { PageHeader } from '../../components';
import Payment from "./Payment";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_PK);
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_PK);

export default function ReviewDonation({page_title}) {
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const values = location.state;
  const fullName = `${values.firstName} ${values.lastName}`;
  const message = values.message;
  const phoneNumber = values.phoneNumber;
  const amount = (values.donationAmount * 100).toFixed(0);
  const description = `${fullName} is donating $${values.donationAmount} in support of the ${values.programTitle}.`;
  const email = values.emailAddress;

  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0)
 
    const fetchedData = async () => {
      const response = await api.CreatePaymentIntent({ 
        donationAmount: amount, 
        description: description, 
        emailAddress: email,
        message: message,
        phoneNumber: phoneNumber,
        fullName: fullName
      });

      setClientSecret(response);
    };
    fetchedData()
  }, []);

  useEffect(() => {
    setIsLoading(true);

    if (clientSecret) {
      setIsLoading(false);
    }
  }, [clientSecret]);

  const options = {
    clientSecret,
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Changa&display=swap'
      }
    ],
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <PageHeader title="Fund The Future" background={"#F1B9D0"} />
        <Box sx={{ pb: 8, backgroundColor: 'white' }}>
          <Grid style={{ paddingLeft: "7.14vw", paddingRight: "7.14vw", paddingTop: "3.14vw" }}>
            {isLoading ? 
                <Grid container alignItems="center" justifyContent="center">
                  <CircularProgress size={100} /> 
                </Grid>
              : 
                clientSecret && (
                  <Elements stripe={stripePromise} options={options}>
                    <Payment data={values} />
                  </Elements>
                )
            }
          </Grid>
        </Box>
      </main>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.topText = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
}

theme.typography.formStyle = {
  paddingLeft:"1.14vw", 
  paddingRight:"1.14vw",
  paddingTop:"1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop:"3.14vw",
    paddingBottom:"3.14vw"
  },
};

theme.typography.fieldTitle = {
  color: "#000", 
  fontFamily: "Changa",
  fontSize: "18px",
  fontWeight: "900",
  lineHeight:"18px",
};