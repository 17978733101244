// CONVERT DATE OBJECT TO STRING - MONTH NAME, DAY, YEAR
export function ConvertToReadableDate(date) {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleDateString('default', { month: 'long' });
  const day = dateObj.getDate();
  const year = dateObj.getUTCFullYear();
  const newDate = `${month} ${day}, ${year}`;

  return newDate;
}

// CONVERT DATE OBJECT TO STRING - {MONTH NAME, DAY, YEAR} {HOUR:MIN:SEC}
export function ConvertToReadableDateWithTime(temp_date) {
  const date = new Date(temp_date);
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var newDate = hours + ':' + minutes + ' ' + ampm;

  return newDate;
}