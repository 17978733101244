import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React, { useRef } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { care_fair_data } from "../../data/data_file";


export default function CareFairPartners() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const swiperRef = useRef();

  theme.typography.subtitle1 = {
    fontWeight: 500,
    fontFamily: "Changa",
  };
  theme.typography.h3 = {
    
    "@media (max-width: 900px)": {
      fontSize: "3rem",
    },
  };
  const createCarouselSwiperImage = (index, options = {}) => (
    <SwiperSlide className="partnerSlide" key={index}>
      <Link to={index.link} target="_blank">
      <img
        style={{
          objectFit: "contain",
          objectPosition: "center",
          width: "150px",
                    height: "150px",
          // borderRadius: "30px",
        }}
        src={index.img}
      />
      </Link>
    </SwiperSlide>
  );
  const swiperChildren = (
    <div>{care_fair_data.partners.map(createCarouselSwiperImage)}</div>
  );
  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        padding: "7.14vw 0px 0px",
        display: "flex",
        flexDirection:"column",
        flexWrap: "wrap",
        marginTop: "-1px",
      }}
    >
      <ThemeProvider theme={theme}>
      <Typography
                    color="black"
                    gutterBottom
                    // variant="h3"
                    sx={{
                      padding: " 0px 7.14vw",
                      fontFamily: "Changa",
                      lineHeight: {xl: "3.5rem", lg:"3rem", md:"2.7rem",sm:"2.3rem", xs:"2rem"},
                      fontWeight: 700,
                      fontSize:{xl: "3.5rem", lg:"3rem", md:"2.7rem",sm:"2.5rem", xs:"2.3rem"},
                    }}
                  >
                    {"Our Partners"}
                  </Typography>
                  <Grid sx={{display:"flex", flexDirection:"column","@media screen and (max-width: 900px)": {
                          flexDirection:"column-reverse",justifyContent:"center"
                        }, }}>
                  <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      padding: "0px 7.14vw",
                      paddingBottom: "25px",
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        lg: "1.7rem",
                      },
                      "@media screen and (max-width: 900px)": {
                        paddingTop: "5%",paddingBottom:0
                      },
                    }}
                  >
                    {"We'd like to thank our partners for helping us to bridge the gaps between community members and leaders to create a better world."}
                  </Typography>
                  <Grid sx={{display:"flex", flexDirection:"row",justifyContent:"space-between","@media screen and (max-width: 900px)": {
                          flexDirection:"column"
                        },}}>
                          <Swiper wrapperClass="partnerWrapper"
                      onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                      }}
                      slidesPerView={"auto"}
                      // effect={"cube"}
                      grabCursor={true}
                      allowTouchMove={true}
                      spaceBetween={50}
                      loop={false}
                     
                      pagination={false}
                      // modules={[EffectCube, Pagination]}
                      className="partnerSwiper"
                    >
                      {swiperChildren.props.children}
                    </Swiper>
                    {/* {care_fair_data.partners.map((item, index) => (
                        <Grid > 
                <img
                  src={item}
                  style={{
                    width: "100px",
                    maxHeight: "100px",
                    height:"100%",
                  }}
                  alt={"background"}
                  loading="lazy"
                />
                </Grid>
                      ))} */}
                     
                  
                  </Grid>
                  </Grid>
       
      </ThemeProvider>
    </Grid>
  );
}
