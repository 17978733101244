import { Box, CssBaseline, Grid, ThemeProvider, Typography, createTheme, responsiveFontSizes } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import { CustomRedirectionButton } from '../../components/FormComponents/FormComponents';


export default function SurveySubmission({page_title}) {
  const navigate = useNavigate();

  const handleHomeRedirect = () => navigate("/");
  
  const handleProgramsRedirect = () => navigate("/programs");
  
  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
      
        {/* <PageHeader title="Connect With Us" background={"rgb(98, 182, 208)"} isContact={1} /> */}
        <Box sx={{ pb: 12, pt: 12, mt: 8, mb: 8 }}>
          <Grid sx={{ paddingLeft: "7.14vw", paddingRight: "7.14vw", paddingTop: "3.14vw" }}>
            <Grid container>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item sx={{ mb: 3 }}>
                  <Typography variant="thankYouTitle">Thank You. We will send you an email with your survey responses shortly.</Typography>
                </Grid>
                <Grid item sx={{ mb: 3 }}>
                  <CustomRedirectionButton
                    href="/"
                    variant="contained"
                    onClick={handleHomeRedirect}
                  >
                    Return Home
                  </CustomRedirectionButton>
                </Grid>
                <Grid item>
                  <CustomRedirectionButton
                    href="/programs"
                    variant="contained"
                    onClick={handleProgramsRedirect}
                  >
                    View Our Programs
                  </CustomRedirectionButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </main>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.thankYouTitle = {
  fontFamily: "Changa",
  fontSize: "2.7rem",
  lineHeight: "2.7rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.8rem",
  lineHeight: "1.8rem",
  },
}

