import { validate } from "validate.js";

export const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
    },
  },
  emailAddress: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 300,
    },
  },
  phoneNumber: {
    presence: { allowEmpty: false, message: 'is required' },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      message: function(value, attribute, validatorOptions, attributes, globalOptions) {
        return validate.format("^%{num} is not a valid phone number", {
          num: value
        });
      }
    }
  },
  topic: {
    presence: { allowEmpty: false, message: 'is required' },
  },
  message: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 1000,
    },
  },
  opt_in: {
    presence: { allowEmpty: false, message: 'is required' }
  },
};