import { ConvertToReadableDate, ConvertToReadableDateWithTime } from './dateHelpers';

export function DocumentDefinition(
  id,
  first_name, 
  last_name, 
  donation_amount, 
  created_at, 
  program_title,
  payment_method_type,
  last_4,
  message,
  card_type,
) {
  return {
    content: [
      {
        image: 'clarintel',
        alignment: 'center',
        width: 150,
      },
      { 
        text: `\nDear ${first_name} ${last_name},`,
      },
      {
        text: `\nThank you for your heartfelt donation of $${parseFloat(donation_amount).toFixed(2)} to Clarintel, Inc. on ${ConvertToReadableDate(created_at)}. We truly appreciate your generous support for the individuals and organizations that we help to drive a positive impact for communities and individuals in need.`,
      },
      {
        text: `\nThough the demand for opportunities generating positive social impact has reached an all-time high, nonprofits struggle with acquiring the attention and resources required to effectively respond to and meet the diverse needs of our communities. `
      },
      {
        text: `\n“At the beginning of the 21st century, two thirds of Americans gave. Today, that is down to under 50% for the first time,” said Una Osili, the associate dean for research and international programs at the Lilly Family School of Philanthropy at Indiana University and the Giving USA report’s lead researcher.`
      },
      {
        text: `\nThanks to you, Clarintel will continue to work directly with community members, like the students at the University of Maryland College Park, and the brothers of Phi Beta Sigma Fraternity and Alpha Kappa Psi Fraternity, to create and deliver innovative solutions that bridge the gaps between community leaders and members to drive positive impact all over the world. Our mission is to promote and redefine social action through clarity and intelligence.`
      },
      {
        text: `\nThank you for your ongoing support. We can’t change the world without you! `
      },
      {
        text: `\n`
      },
      {layout: 'noBorders',table: { 
        widths:['50%', '50%'],
        
          body:[
            [{
          image: 'signature',
          width: 150,
        },{
          image: 'signature2',
          width: 150,
        },
      ],
      [{
        text: `\nBrandon Wallerson`
      },{
        text: `\n Kwesi Larbi`
      },],
      [{
        text: `President & CEO of Clarintel, Inc.`
      },{
        text: `Vice President & CTO of Clarintel, Inc.`
      },],
        
        ],
        
    }},
      
      
      {
        text: `\n\nPlease find a summary of your donation below:`
      },
      {
        text: [
          { text: `\n\nDonation ID: `, bold: true },
          `${id}`,
        ]
      },
      {
        text: [
          { text: `Organization: `, bold: true },
          `Clarintel, Inc.`
        ]
      },
      {
        text: [
          { text: `Program/Donation Reason: `, bold: true },
          `${program_title}`
        ]
      },
      {
        text: [
          { text: `Donor Name: `, bold: true } ,
          `${first_name} ${last_name}`
        ]
      },
      {
        text: [
          { text: `Donation Amount: `, bold: true }, 
          `$${parseFloat(donation_amount).toFixed(2)}`
        ]
      },
      {
        text: [
          { text: `Donation Date & Time: `, bold: true },
          `${ConvertToReadableDateWithTime(created_at)}`,
        ]
      },
      {
        text: [
          { text: `Donation Method: `, bold: true },
          `${card_type} ${payment_method_type} ****${last_4}`
        ]
      },
      message !== '' ? {
        text: [
          { text: `Donation Message: `, bold: true },
          `${message}`
        ],
      } : null
    ],
    images: {
      clarintel: 'https://i.postimg.cc/cJqDHjP4/clarintel-full.png',
      signature: 'https://staging.clarintel.org/images-holder/b3b825ec-860b-452f-94a2-95fd04bb9a15.png',
      signature2: 'https://staging.clarintel.org/images-holder/ee2c6470-1c18-4344-b84e-54fc6e0d4c3f.png'

    },
    styles: {
      
    },
 
  }
};