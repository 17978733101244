export const Programs = [
  {
    programTitle: "General Donation to Clarintel, Inc.",
    programDescription: "Clarintel, Inc. was established in 2020 to simplify access to knowledgable resources and opportunities that clarify and amplify community engagement. We create the environment for individuals to gain attention for the social issues affecting their communities, while connecting with the impact makers and organizations driving a positive impact."
  },
  {
    programTitle: "Apprenticeship & Internship Rotational (AIR) Programs",
    programDescription: "Clarintel's Apprenticeship & Internship Rotational (A.I.R.) Program unites educational knowledge with real world experiences that empower high school and college/university students to create a sustainable, positive impact that transforms communities in need. AIR programs are designed to increase the impact of, and overall engagement in, social impact opportunities through community focused solutions and strategies created in partnership with the youth and young adults experiencing social issues on a daily basis."
  },
  {
    programTitle: "Philanthropic Access Initiatives",
    programDescription: "This initiative amplifies the community's understanding of social issues and engagement in social action through the creation of technological and data driven solutions that simplify and streamline access to philanthropic and social issue focused data, information, & opportunities. All tools and solutions created by Clarintel will be free and open to all individuals and community organizations. While Clarintel's Philanthropic Access Initiatives empower all individuals to drive a positive impact, we primarily focus on assisting community based organizations generating less than $1,000,000 in revenue to accomplish their missions. To learn about our progress, or if your interested in creating cutting edge solutions to community level social issues, feel free to reach out!"
  },
  {
    programTitle: "Community Unity & Capacity Building Programs",
    programDescription: "Our Community Unity & Capacity Building Programs connect impact makers and change agents with the opportunities and community based organizations serving the needs and interests of individuals impacted by community level social issues. Through Clarintel, impact makers can find and build relationships with organization they can trust to drive a sustainable, measurable, positive impact. Likewise, we provide the opportunity for community organizations to strengthen their community presence while attracting new donors, volunteers, supporters, and employee prospects. We are in the process of planning our first Community Unity & Capacity Building event to serve impoverished to low income communities and are currently partnering with community based organizations looking to connect with impact makers on a personal level throughout the DC, Maryland, and Virginia area. "
  },
  {
    programTitle: "Clarintel Care Fair",
    programDescription: "The Care Fair connects students and community members to the nonprofits and community based organizations driving a positive impact within underrepresented, marginalized, and impoverished communities throughout DC, Maryland, and Virginia. At the event, attendees can engage in any form of philanthropic activity to support and strengthen the credible nonprofits and community-based organizations working to create a better world."
  },
];