import React, { useEffect } from "react";

import CareFairAboutSection from "../../sections/CareFairSections/CareFairAboutSection";
import CareFairLandingSection from "../../sections/CareFairSections/CareFairLandingSection";
import CareFairPartners from "../../sections/CareFairSections/CareFairPartners";
import DonationSharingSection from "../../sections/CareFairSections/DonationSharingSection";
import ForTheLeadersSection from "../../sections/CareFairSections/ForTheLeadersSection";
import ForThePeopleSection from "../../sections/CareFairSections/ForThePeopleSection";
import LogisticsSection from "../../sections/CareFairSections/LogisticsSection";
import PartnerCollabSection from "../../sections/CareFairSections/PartnerCollabSection";
import TheVenueSection from "../../sections/CareFairSections/TheVenueSection";


export default function CareFairPage({page_title}) {
  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0)
  }, []);
  return (
    <main> 
    <CareFairLandingSection/>
    <LogisticsSection/>
    <CareFairPartners/>
    <CareFairAboutSection/>
    <TheVenueSection/>
    <ForThePeopleSection/>
    <ForTheLeadersSection/>
    <DonationSharingSection/>
    {/* <ProgramAreaSection/> */}
    <PartnerCollabSection/>
    </main>
  )
}
