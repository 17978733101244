import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React, { useRef, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import SwiperCore from "swiper";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import { EffectCube, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "./index.css";

export default function TheVenueSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  let [currentSlide, setCurrentSlide] = useState(0);
  const swiper = useSwiper();
  const swiperRef = useRef();
  const next = () => {
    swiperRef.current.slideTo(currentSlide === 3 ? 0 : currentSlide + 1);
    setCurrentSlide(currentSlide === 3 ? 0 : currentSlide + 1);
  };
  const prev = () => {
    swiperRef.current.slideTo(currentSlide === 0 ? 3 : currentSlide - 1);
    setCurrentSlide(currentSlide === 0 ? 3 : currentSlide - 1);
  };

  const updateCurrentSlide = (index) => {
    // swiperRef.current.slideTo(index);
    setCurrentSlide(index);
  };
  const buttonStyle = { fontSize: 20, padding: "5px 20px", margin: "5px 0px" };
  const containerStyle = { margin: "5px 0 20px", display: "block" };
  theme.typography.subtitle1 = {
    fontWeight: 500,
    fontFamily: "Changa",
    // "@media (max-width: 1285px)": {
    //   lineHeight: "18px",

    //   fontSize: "18px !important",
    // },
    // "@media screen and (max-width: 900px)": {
    //   // lineHeight: "1.1em",
    //   marginTop: "12px",
    // },
  };
  theme.typography.subtitle2 = {
    textAlign: "left",
    fontFamily: "Changa",
    fontWeight: "700",
    fontSize: "1.5rem",
    lineHeight: "1.5rem",
    color: "#000",
    "@media screen and (max-width: 900px)": {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  };
  theme.typography.h3 = {
    "@media (max-width: 900px)": {
      fontSize: "3rem",
    },
  };
  // theme.typography.h6 = {
  //   fontSize: "0.6rem",
  //   "@media (min-width:300px)": {
  //     fontSize: ".77rem",
  //   },
  //   "@media (min-width:500px)": {
  //     fontSize: "1.1rem",
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "2.2rem",
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     fontSize: "2.9rem",
  //   },
  // };
  const createCarouselItemImage = (index, options = {}) => (
    <div style={{ display: "flex", height: "100%" }} key={index}>
      <img
        style={{
          objectFit: "cover",
          objectPosition: "center",
          borderRadius: "30%",
        }}
        src={`/images/stamp${index}.jpg`}
      />
      <p style={{ fontWeight: 500, fontFamily: "Changa" }} className="legend">
        Legend {index}
      </p>
    </div>
  );

  const createCarouselSwiperImage = (index, options = {}) => (
    <SwiperSlide key={index}>
      <img
        style={{
          objectFit: "cover",
          objectPosition: "center",
          width: "100%",
          height: "100%",
          borderRadius: "30px",
        }}
        src={`/images/stamp${index}.jpg`}
      />
    </SwiperSlide>
  );
  const image_descriptions = [
    "The University of Maryland, College Park is the state's flagship university and one of the nation's preeminent public research universities. A global leader in research, entrepreneurship and innovation, the university is home to more than 40,700 students, 14,000 faculty and staff, and nearly 400,000 alumni all dedicated to the pursuit of Fearless Ideas. Located just outside Washington, D.C., we discover and share new knowledge every day through our renowned research enterprise and programs in academics, the arts and athletics.",
    "The heart of student activity on campus, the Adele H. Stamp Student Union houses nearly all of the university's student organizations, receiving more than 19,000 visitors daily. Stamp's mission is to provide a safe and inviting campus center where all UMD students, alumni, faculty, staff, and community members cultivate lifelong relationships founded on engagement, learning, multiculturalism, and citizenship.",
    "The Adele H. Stamp Student Union welcomes you to the University of Maryland. Maryland touts 800-plus student organizations, dozens of prestigious living and learning communities, and countless other ways to get involved. We are pleased to serve the entire University community including faculty, staff and visitors. Be sure to stop by the Adele H. Stamp Student Union when you first come to campus!",
    "The Grand Ballroom is one of the largest reception halls in the College Park area. It is perfect for large receptions, meetings, banquets, ceremonies, education sessions, concerts, lectures, trade shows, and more.",
    
  ];

  const image_titles = [
    "The University of Maryland, College Park",
    "The Adele H. Stamp Student Union",
    "Inside the Adele H. Stamp Student Union",
    "The Grand Ballroom",
  ];

  const baseChildren = <div>{[1, 2, 3, 4].map(createCarouselItemImage)}</div>;
  const swiperChildren = (
    <div>{[1, 2, 3, 4].map(createCarouselSwiperImage)}</div>
  );

  SwiperCore.use([Pagination]);
  SwiperCore.use([EffectCube]);
  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        padding: "3.14vw 7.14vw 0px",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-1px",
      }}
    >
      <ThemeProvider theme={theme}>
        <Typography
          color="black"
          gutterBottom
          // variant="h3"
          sx={{
            fontFamily: "Changa",
            lineHeight: {
              xl: "3.5rem",
              lg: "3rem",
              md: "2.7rem",
              sm: "2.3rem",
              xs: "2rem",
            },
            fontWeight: 700,
            fontSize: {
              xl: "3.5rem",
              lg: "3rem",
              md: "2.7rem",
              sm: "2.5rem",
              xs: "2.3rem",
            },
          }}
        >
          {"The Venue"}
        </Typography>
        <Grid
          container
          sx={{
            width: "100%",
            // justifyContent: "space-evenly",
            alignItems: "center",
            display: "flex",
            flexWrap: "initial",
            flexDirection: "row-reverse",
            overflow: "visible",

            "@media screen and (max-width: 900px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              top: "-1px",
              background: "#FFF",
              width: "50%",
              // width: "50vw",
              // "@media screen and (min-width: 769px)": {
              //   maxHeight: "50vw",
              // },
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              sx={{
                "@media screen and (min-width: 769px)": {
                  height: "calc(100% - 7.14vw)",
                  maxHeight: "calc(100% - 72px)",
                },
                "@media screen and (max-width: 900px)": {
                  // padding: "3.14vw 0",
                  paddingRight: 0,
                },
                // padding: "3.14vw",
                // paddingRight: "3.14vw",
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                boxSizing: "inherit",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  "@media screen and (min-width: 769px)": {
                    // padding: "3.14vw",
                    paddingLeft: 0,
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  style={{ width: "100%" }}
                >
                  <div style={containerStyle}>
                    {/* <p style={containerStyle}>
                            Use the buttons below to change the selected item in the carousel
                            <br />
                            <small>
                                <i>
                                    Note that the external controls might not respect the carousel boundaries but the
                                    carousel won't go past it.
                                </i>
                            </small>
                        </p>
                        <p>External slide value: {currentSlide}</p> */}

                    <Swiper
                      onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                      }}
                      effect={"cube"}
                      grabCursor={false}
                      allowTouchMove={true}
                      onSlideChange={(element) => {
                        currentSlide === 3
                          ? updateCurrentSlide(0)
                          : updateCurrentSlide(element.realIndex);
                      }}
                      loop={true}
                      wrapperClass="venueWrapper"
                      cubeEffect={{
                        shadow: true,
                        slideShadows: true,
                        shadowOffset: 0,
                        shadowScale: 0.94,
                      }}
                      pagination={false}
                      modules={[EffectCube, Pagination]}
                      className="mySwiper"
                    >
                      {swiperChildren.props.children}
                    </Swiper>
                    <Grid
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-evenly",
                        paddingTop: "20px",
                      }}
                    >
                      <Grid onClick={() => prev()}>
                        <div
                          style={{ transform: "rotateY(180deg)" }}
                          className="center-con"
                        >
                          <div className="round">
                            <div id="cta">
                              <span className="arrow primera next  "></span>
                              <span className="arrow segunda next  "></span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid onClick={() => next()}>
                        <div className="center-con">
                          <div className="round">
                            <div id="cta">
                              <span className="arrow primera next  "></span>
                              <span className="arrow segunda next  "></span>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    {/* <img style={{width:"300px",height:"300px"}} src={`/images/riggs-${currentSlide+1}.png`} /> */}
                  </div>
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Grid>
          {/* Group 1 */}
          <Grid item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            sx={{
              position: "relative",
              minWidth: "50%",
              height: "100%",
              justifyContent: "center",
              display: "flex",
              background: "FFF",
              overflow: "hidden",
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              container
              sx={{
                // width: "calc(100% )",
                // height: "calc(100% - 7.14vw)",
                // maxWidth: "calc(100% - 72px)",
                // maxHeight: "calc(100% - 72px)",
                width: "calc(100%- 72px)",
                display: "flex",
                height: "100%",
                maxHeight: "calc(100% )",
                paddingRight: "50px",
                alignItems: "center",
                justifyContent: "center",
                // paddingRight:"20px",
                "@media screen and (max-width: 900px)": {
                  padding: "0px 3.14vw",
                  padding: "0px",

                  maxWidth: "100%",
                },
                // position: "absolute",
                zIndex: 1,
                // right: 0,
                backgroundSize: "cover",
              }}
            >
              <ScrollAnimation
                animateIn="fadeInLeft"
                initiallyVisible={false}
                style={{
                  // width: "70%",
                  display: "flex",
                  width: "100%",
                  height:"100%",
                }}
              >
                <Grid
                  sx={{
                    paddingTop: "15px",
                    height: "100%",
                    width: "100%",
                    "@media screen and (max-width: 900px)": {
                      paddingBottom: "3vw",
                      height: "100%",
                    },
                  }}
                >
                  <div style={{height:"100%",display:"flex",flexDirection:"column", }}>
                    <Grid sx={{display:"flex",flexDirection:"column", paddingBottom:"25px",justifyContent:"flex-start"}}>
                    <Typography
                      color="black"
                      variant="subtitle1"
                      sx={{
                        fontWeight: 700,
                        fontSize: {
                          xs: "1.7rem",
                          sm: "1.9rem",
                          md: "2.1rem",
                          lg: "2.3rem",
                        },
                        lineHeight: {
                          xs: "1.7rem",
                          sm: "1.9rem",
                          md: "2.1rem",
                          lg: "2.3rem",
                        },
                      }}
                    >
                      {`${image_titles[currentSlide]}`}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        // paddingTop: "15px",
                        fontSize: {
                          xs: "1rem",
                          sm: "1.3rem",
                          md: "1.3rem",
                        },
                        lineHeight: {
                          xs: "1rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                        },
                      }}
                    >
                      {"The Adele H. Stamp Student Union"}
                    </Typography>

                    <Typography
                      sx={{
                        // paddingTop: "15px",
                        fontSize: {
                          xs: "1rem",
                          sm: "1.3rem",
                          md: "1.3rem",
                        },
                        lineHeight: {
                          xs: "1rem",
                          sm: "1.3rem",
                          md: "1.3rem",
                        },
                      }}
                      variant="subtitle2"
                    >
                      {"3972 Campus Drive College Park, MD 20742"}
                    </Typography>
                    <Typography
                      sx={{
                        // paddingTop: "15px",
                        fontSize: {
                          xs: "1rem",
                          sm: "1.3rem",
                          md: "1.3rem",
                        },
                        lineHeight: {
                          xs: "1rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                        },
                      }}
                      variant="subtitle2"
                    >
                      {"Thursday, April 4, 2024"}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        pb: "15px",
                        fontSize: {
                          xs: "1rem",
                          sm: "1.3rem",
                          md: "1.3rem",
                        },
                        lineHeight: {
                          xs: "1rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                        },
                      }}
                    >
                      {"12:00pm EST - 8:00pm EST"}
                    </Typography>
                    </Grid>
                    <Typography
                      color="black"
                      gutterBottom
                      variant="subtitle1"
                      sx={{
                        // paddingTop: "15px",
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                        },
                        lineHeight: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                        },
                      }}
                    >
                      {image_descriptions[currentSlide]}
                    </Typography>
             
                  </div>
                </Grid>
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
}
