import React from 'react';
import Lottie from 'react-lottie';
import * as handHeartAnimationData from '../../assets/animations/hand-heart.json';


export default function LottieControl() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: handHeartAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div>
      <Lottie 
        options={defaultOptions}
        height={200}
        width={200}
      />
    </div>
  )
}
