import React from "react";
import { Grid, Typography } from "@mui/material";
import { ConvertToReadableDateWithTime } from '../../helpers/dateHelpers';

export default function ReceiptDetails({ props: { 
  id, 
  first_name, 
  last_name, 
  program_title, 
  donation_amount, 
  created_at, 
  last_4, 
  payment_method_type, 
  message,
  card_type,
}}) {

  return (
    <Grid item sx={{ mb: 3 }} container direction="column">
      <Grid container direction="row" spacing={1} justifyContent="left" alignItems="left">
        <Grid item>
          <Typography variant="thankYouReceipt">Donation ID:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="thankYouDetails">{id}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} justifyContent="left" alignItems="left">
        <Grid item>
          <Typography variant="thankYouReceipt">Organization:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="thankYouDetails">Clarintel, Inc.</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} justifyContent="left" alignItems="left">
        <Grid item>
          <Typography variant="thankYouReceipt">Program/Donation Reason:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="thankYouDetails">{program_title}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} justifyContent="left" alignItems="left">
        <Grid item>
          <Typography variant="thankYouReceipt">Donor Name:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="thankYouDetails">{first_name} {last_name}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} justifyContent="left" alignItems="left">
        <Grid item>
          <Typography variant="thankYouReceipt">Donation Amount:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="thankYouDetails">${parseFloat(donation_amount).toFixed(2)}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} justifyContent="left" alignItems="left">
        <Grid item>
          <Typography variant="thankYouReceipt">Donation Date & Time:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="thankYouDetails">{ConvertToReadableDateWithTime(created_at)}</Typography>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={1} justifyContent="left" alignItems="left">
        <Grid item>
          <Typography variant="thankYouReceipt">Donation Method:</Typography>
        </Grid>
        <Grid item>
          <Typography variant="thankYouDetails">{card_type} {payment_method_type} ****{last_4}</Typography>
        </Grid>
      </Grid>
      {message !== '' && (
        <Grid container direction="row" spacing={1} justifyContent="left" alignItems="left">
          <Grid item>
            <Typography variant="thankYouReceipt">Donation Message:</Typography>
          </Grid>
          <Grid item>
            <Typography variant="thankYouDetails">{message}</Typography>
          </Grid>
        </Grid>)}
    </Grid>
  );
}
