import {
  Button,
  CssBaseline,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  Typography,
} from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
// import emailjs from "emailjs-com";
import React, { useEffect, useMemo, useRef, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router";
import AsyncCreatableSelect from "react-select/async-creatable";
import { PhoneNumberPatternFormatCustom } from '../../components/InputComponents/InputComponents';

// import 'swiper/css';
import SwiperCore from "swiper";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { social_issues } from "./social_issues";

import "swiper/css/navigation";
import { single, validate } from "validate.js";
import api from "../../api";
import {
  CustomCheckbox,
  CustomFormControlLabel,
  CustomMenuItem,
  CustomMessageBox,
  CustomSelect,
  CustomSubmitButton,
  CustomTextField,
} from "../../components/FormComponents/FormComponents";
import { genders, races } from "../../data/ContactData/data";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { schema } from "./cksurveySchema";
import {
  factorsForPhilanthropy,
  nonprofitActionsforTrust,
  philanthropicActionOptions,
  philanthropicInfluences,
  trackYourImpact
} from "./data";
import "./index.css";
import { nonprofits } from "./nonprofits";
SwiperCore.use([Navigation]);

// const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
// const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
// const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

export default function CKSurvey({page_title}) {
  const surveyForm = useRef();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const submitRef = useRef(null);

  const ref1TOC = useRef(null);
  const ref2TOC = useRef(null);
  const ref3TOC = useRef(null);
  const ref4TOC = useRef(null);
  const ref5TOC = useRef(null);
  // const ref6TOC = useRef(null);
  // const ref7TOC = useRef(null);
  const submitTOC = useRef(null);

  function useIsInViewport(id, tocRef) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const arr = [ref1, ref2, ref3, ref4, ref5, ref6, ref7, submitRef];
    var ref = arr[id - 1];

    const observer = useMemo(() => {
      let options = {
        // root: document.querySelector("#main"),
        root: null,
        rootMargin: "0px",
        threshold: 0.4,
      };

      return new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        options
      );
    }, [isIntersecting]);

    useEffect(() => {
        document.title = page_title;
  
      observer.observe(ref.current);

      return () => {
        // observer.unobserve(ref.current);
        observer.disconnect();
      };
    }, [ref, observer]);
    if (isIntersecting) {
      handleSetSection(id, tocRef);
      // if (width < 900 && tocRef) {
      //   setTimeout(
      //     // () => tocRef.current?.scrollIntoView(true, { behavior: "smooth" }),
      //     0
      //   );
      // }
    }
    return isIntersecting;
  }

  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  // const [infoSharingConsent, setInfoSharingConsent] = useState(true);
  const [answer1, setAnswer1] = useState([]);
  const [answer2, setAnswer2] = useState(-1);
  // const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState([]);

  const [answer5, setAnswer5] = useState([]);
  const [answer6, setAnswer6] = useState([]);
  const [answer7, setAnswer7] = useState([]);
  const [answer8, setAnswer8] = useState([]);

  const [answer9, setAnswer9] = useState("");
  const [answer10, setAnswer10] = useState([]);
  const [answer11, setAnswer11] = useState("");
  const [answer12, setAnswer12] = useState("");
  
 
  const [enter_raffle, setEnterRaffle] = useState(null);
  const [umd_attendance, setUMDAttendance] = useState(null);

  const [currentSection, setCurrentSection] = useState(1);
  const [swiper, setSwiper] = useState(null);
  const slideTo = (index) => swiper.slideTo(index);

  // const history = useNavigate();

  const handleSetSection = (e, tocRef) => {
    setCurrentSection(e);
    if (width < 900) {
      slideTo(e - 1);
    }
  };

  const handleSetValue = (value, setValue, id, type) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [id]: type === 'checkbubble' ? value : Object.values(value.map(i => i.label)),
      },
      touched: {
        ...formState.touched,
        [id]: true,
      },
    }));
    setValue(value);
  };

  const handleChange = (e) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
  };

  const hasError = (field) => formState.errors[field] ? formState.touched[field] ? true : false : false;

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values, currentSection]);

  const sendEmail = (e) => {
    e.preventDefault();

    try {
      api.PostSurvey(formState.values);
      // console.log(`Posting survey succeeded!`);
      navigate('/philanthropic-spectrum-survey/submitted');
    } catch (error) {
      // console.log(`Posting survey failed: ${error}`);
    }

    setFormState((formState) => ({
      ...formState,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    }));
  };

  const CustomCreatableSelect = ({ data, question, answer, setAnwser }) => {
    const filterColors = (inputValue) => {
      return data.filter((i) =>
        i.label.toLowerCase().includes(inputValue.toLowerCase())
      );
    };
    
    const promiseOptions = (inputValue) =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(filterColors(inputValue));
        }, 500);
      });
    
    return (
      <AsyncCreatableSelect
        isMulti
        value={answer}
        cacheOptions
        defaultOptions={data.slice(0, 100)}
        loadOptions={promiseOptions}
        onChange={(newValue) => handleSetValue(newValue, setAnwser, question, 'arraylist')}
        name={question}
        menuPortalTarget={document.body}
        styles={{
          container: (base) => ({
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }),
          control: (base, state) => ({
            ...base,
            borderRadius: "20px",
            fontFamily: "Changa",
            minHeight: "70px",
            borderColor: state.isFocused ? "#62B6D0" : "lightgrey",
            boxShadow: state.isFocused ? "0 0 0 1px #62B6D0" : 0,
            "&:hover": { borderColor: "#62B6D0" },
          }),
          menu: (base) => ({
            ...base,
            marginTop: "10px",
            zIndex: 9999,
            fontFamily: "Changa",
            birderRadius: "20px",
          }),
          option: (base, state) => ({
            ...base,
            fontFamily: "Changa",
            backgroundColor: state.isFocused ? "#62B6D0" : "#FFF",
            "&:hover": { backgroundColor: "#62B6D0" },
          }),
          multiValue: (styles, { data }) => {
            // const color = ;
            return {
              ...styles,
              backgroundColor: "#62B6D0",
              borderRadius: "40px",
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#FFF",
          }),
          multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: "rgba(255,255,255,.7)",
            ":hover": {
              backgroundColor: "transparent",
              color: "white",
            },
          }),
        }}
        options={data}
      />
    );
  };

  const tableOfContentData = [
    {
      id: 1,
      section: "section-1",
      title: "My Philanthropic Influences",
      refVar: ref1,
      tocRef: ref1TOC,
    },
    {
      id: 2,
      section: "section-2",
      title: "My Philanthropic Interests",
      refVar: ref2,
      tocRef: ref2TOC,
    },
    {
      id: 3,
      section: "section-3",
      title: "My Philanthropic Impact",
      refVar: ref3,
      tocRef: ref3TOC,
    },
   
    {
      id: 4,
      section: "section-4",
      title: "Contact Information",
      refVar: ref4,
      tocRef: ref4TOC,
    },
    {
      id: 5,
      section: "section-5",
      title: "The Amazon Raffle ",
      refVar: ref5,
      tocRef: ref5TOC,
    },
    {
      id: 8,
      section: "submit-button",
      title: null,
      refVar: submitRef,
      tocRef: submitTOC,
    },
  ];

  const isFormComplete = () => {
    return (
      Object.keys(formState?.values).length > 0 &&
      formState.values?.question_1?.length > 0 &&
          formState.values?.question_2?.toString().length > 0 &&
          formState.values?.question_3?.toString().length > 0 &&
          formState.values?.question_4?.length > 0 &&
      formState.values?.question_5?.toString().length > 0 &&
          formState.values?.question_6?.length > 0 &&
          formState.values?.question_7?.length > 0 &&
          formState.values?.question_8?.length > 0  &&
      formState.values?.question_9?.toString().length > 0 &&
      formState.values?.question_10?.length > 0 &&
      formState.values?.question_11?.toString().length > 0 &&
      formState.values?.question_12?.toString().length > 0 &&
      formState.values?.first_name?.length > 0 &&
      formState.values?.last_name?.toString().length > 0 &&
      formState.values?.email_address?.length > 0 &&
      formState.values?.phone_number?.length > 0 &&
      ((formState.values?.enter_raffle !== false &&
        formState.values?.umd_student === true &&
        (formState.values?.instagram_username?.length > 0 ||
          formState.values?.twitter_username?.length > 0 ||
          formState.values?.facebook_username?.length > 0 ||
          formState.values?.linkedin_username?.length > 0)) ||
        (formState.values?.enter_raffle !== false &&
          formState.values?.umd_student === false &&
          (formState.values?.instagram_username?.length > 0 ||
            formState.values?.twitter_username?.length > 0 ||
            formState.values?.facebook_username?.length > 0 ||
            formState.values?.linkedin_username?.length > 0)) ||
        formState.values?.enter_raffle === false) &&
      formState.isValid
    );
  };

  const isSectionComplete = (section) => {
    switch (section) {
      case "section-1":
        // console.log(
        //   section,
        //   formState.values?.question_1?.length > 0,
        //   formState.values?.question_2?.toString().length > 0 ,
        //   formState.values?.question_3?.toString().length > 0,
        //   formState.values?.question_4?.length > 0 
        // );
        return (
          Object.keys(formState?.values).length > 0 &&
          formState.values?.question_1?.length > 0 &&
          formState.values?.question_2?.toString().length > 0 &&
          formState.values?.question_3?.toString().length > 0 &&
          formState.values?.question_4?.length > 0 
        );
      case "section-2":
        // console.log(
        //   section,
        //   formState.values?.question_4?.length > 0 &&
        //     formState.values?.question_5?.toString().length > 0 &&
        //     formState.values?.question_6?.length > 0
        // );
        return (
          Object.keys(formState?.values).length > 0 &&
          formState.values?.question_5?.toString().length > 0 &&
          formState.values?.question_6?.length > 0 &&
          formState.values?.question_7?.length > 0 &&
          formState.values?.question_8?.length > 0 
        );
      case "section-3":
        // console.log(
        //   section,
        //   formState.values?.question_7?.length > 0 &&
        //     formState.values?.question_8?.length > 0 &&
        //     formState.values?.question_9?.length > 0
        // );
        return (
          Object.keys(formState?.values).length > 0 &&
          
          formState.values?.question_9?.toString().length > 0 &&
          formState.values?.question_10?.length > 0 &&
          formState.values?.question_11?.toString().length > 0 &&
          formState.values?.question_12?.toString().length > 0
        );
      
      case "section-4":
        return (
          !single(formState.values.email_address, schema.email_address) &&
          !single(formState.values.phone_number, schema.phone_number) &&
          Object.keys(formState?.values).length > 0 &&
          formState.values?.first_name?.length > 0 &&
          formState.values?.last_name?.toString().length > 0 &&
          formState.values?.email_address?.length > 0 &&
          formState.values?.phone_number?.length > 0
          // formState.values?.question_16!==-1 &&
          // formState.values?.question_17 !==-1 > 0
        );
      case "section-5":
        return (
          Object.keys(formState?.values).length > 0 &&
          ((formState.values?.enter_raffle !== false &&
            formState.values?.umd_student === true &&
            (formState.values?.instagram_username?.length > 0 ||
              formState.values?.twitter_username?.length > 0 ||
              formState.values?.facebook_username?.length > 0 ||
              formState.values?.linkedin_username?.length > 0)) ||
            (formState.values?.enter_raffle !== false &&
              formState.values?.umd_student === false &&
              (formState.values?.instagram_username?.length > 0 ||
                formState.values?.twitter_username?.length > 0 ||
                formState.values?.facebook_username?.length > 0 ||
                formState.values?.linkedin_username?.length > 0)) ||
            formState.values?.enter_raffle === false)
          // formState.values?.question_16!==-1 &&
          // formState.values?.question_17 !==-1 > 0
        );
      default:
        return false;
    }
  };

  const TableOfContentItem = ({ id, section, title, refVar, tocRef }) => {
    const isIn = useIsInViewport(id, tocRef);
    // const { width, height } = useWindowDimensions();
    
    return (
      <>
        {id !== 8 ? (
          <Grid
            ref={tocRef}
            sx={{
              overflowAnchor: "none",
              boxShadow:
              currentSection === id
                  ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)"
                  : 0,
              background: currentSection === id ? "#f3f3f5" : "transparent",
  
              alignItems: "center",
              borderRadius: "10px",
              height: 50,
              display: "flex",
              transition: "all 1s linear",
              width: "100%",
              justifyContent: "center",
              "@media screen and (max-width: 900px)": {
                width: "200px",
              },
            }}
          >
            <Link
              // smooth
              href={"/community-unity"}
              sx={{
                textDecoration: "none",
                color: "#000",
                padding: "10px",
                width: "100%",
                justifyContent: "center",
                "@media screen and (max-width: 900px)": {
                  width: "200px",
                },
              }}
              className="link"
              onClick={(e) => {
                e.preventDefault(); // Stop Page Reloading
                e.stopPropagation();
                refVar.current?.scrollIntoView(true, { behavior: "smooth" });
                handleSetSection(id, null);
              }}
            >
              {" "}
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "center",
                    padding: "7px 0px",
                    width: "10%",
                    transition: "all .5s ease-in-out,transform .5s"
                  }}
                >
                  {isSectionComplete(section) ? (
                    <svg
                      width="35"
                      height="35"
                      x="0"
                      y="0"
                      viewBox="-100 -100 512 512"
                    >
                      <g>
                        <path
                          d="M165 0C74.019 0 0 74.019 0 165s74.019 165 165 165 165-74.019 165-165S255.981 0 165 0zm0 300c-74.44 0-135-60.561-135-135S90.56 30 165 30s135 60.561 135 135-60.561 135-135 135z"
                          fill="#62B6D0"
                          opacity="1"
                          data-original="#62B6D0"
                        ></path>
                        <path
                          d="m226.872 106.664-84.854 84.853-38.89-38.891c-5.857-5.857-15.355-5.858-21.213-.001-5.858 5.858-5.858 15.355 0 21.213l49.496 49.498a15 15 0 0 0 10.606 4.394h.001c3.978 0 7.793-1.581 10.606-4.393l95.461-95.459c5.858-5.858 5.858-15.355 0-21.213-5.858-5.858-15.355-5.859-21.213-.001z"
                          fill="#62B6D0"
                          opacity="1"
                          data-original="#62B6D0"
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    <svg
                      fill={isSectionComplete(section) ? "#000" : "current"}
                      width="30"
                      height="30"
                      x="0"
                      y="0"
                      viewBox="0 0 15 15"
                    >
                      <g>
                        <circle
                          r="6"
                          cx={8}
                          cy={8}
                          fill={isSectionComplete(section) ? "#000" : "white"}
                        />
                        <path d="M9 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
                        <path d="M7.5 1a6.5 6.5 0 1 0 0 13 6.5 6.5 0 0 0 0-13zM2 7.5a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"></path>
                      </g>
                    </svg>
                  )}
                </Grid>
                <Typography
                  variant="subtitle1"
                  sx={{
                    paddingLeft: "10px",
                    fontSize: "1rem",
                    lineHeight: "1rem",
                    width: "90%",
                    color: currentSection === id ? "#62B6D0" : "#000",
                    fontWeight: currentSection === id ? 700 : 500,
                    "@media screen and (max-width: 900px)": {
                      fontSize: "1rem",
                      lineHeight: "1rem", //"color:"rgb(98, 182, 208)","
                    },
                  }}
                >
                  {title}
                </Typography>
              </Grid>
            </Link>
          </Grid>
        ) : (
          <Grid
            item
            ref={tocRef}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomSubmitButton
              variant="contained"
              type="submit"
              onClick={sendEmail}
              sx={{
                width: "200px",
                height: 50,
                margin: 0,
                fontSize: "1rem",
                "@media screen and (min-width: 900px)": {
                  marginTop: "20px",
                },
              }}
              disabled={!isFormComplete()}
            >
              Submit My Survey
            </CustomSubmitButton>
          </Grid>
        )}
      </>
    );
  };
  
  const { width } = useWindowDimensions();

  var statTheme = {
    width: "50%",
    "@media screen and (max-width: 900px)": {
      width: "100%",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid
        id="main"
        sx={{
          pb: width > 900 ? 8 : 0,
          pt: "120px",
          backgroundColor: "#f3f3f5",
          overflowX:"clip",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ justifyContent: "center", textAlign:"center" , width:"100%", padding: "100px 3.14vw 3.14vw"}} sx={statTheme}>
        <Grid 
              container 
              direction="row" 
              sx={{ 
                "@media screen and (max-width: 900px)": {
                  paddingBottom: "0",
                },
                paddingBottom: "3.14vw", 
              }}
            >
              <Grid item xs={12}>
                <Typography
                  // variant="h2" 
                          sx={{
                            margin: 0,
                            display: "flex",
                            fontFamily: "Changa",
                            color: "#62B6D0",
                            fontSize:"1.25rem",
                            lineHeight:"1.6rem",
                            "@media screen and (max-width: 900px)": {
                              flexDirection:"column",
                            }
        
                          }}
                >
                  {/* The Clarintel Apprenticeship Rotational Emergence Program */}
                  The Philanthropic Spectrum Survey
                  <Typography
                    variant="h6"
                    component={'span'}
                    style={{
                      letterSpacing: "-0.09em",
                      fontFamily: "Changa",
                      paddingLeft: "5px",
                      lineHeight:"1.6rem",
                    }}
                  >
                    {`----`}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
        <Typography
          variant="h3"
        >
          {/* {"Help nonprofits understand how you want to drive a positive impact."} */}
          {"How do you want your nonprofits to drive a positive impact?"}

        </Typography>
      </Grid>
        <Grid
          container
          direction={width > 900 ? "row" : "column-reverse"}
          sx={{ display: "flex", zIndex: 999, justifyContent: "center",  }}
        >
          {width > 900 ? (
            <Grid
              item
              sx={{
                background: "#f3f3f5",
                borderRadius: "30px",

                minWidth: "29vw",
                width: "auto",
                height: "auto",
                justifyContent: "flex-end",
                display: "flex",
                paddingLeft: "3vw",
                // "@media screen and (max-width: 900px)": {
                //   display:"none"
                // },
              }}
            >
              <Grid
                item
                sx={{
                  background: "#f7f7f7",
                  padding: "3.14vw 1.14vw",
                  width: "28vw",
                  minHeight: "75vh",
                  justifyContent: "space-between",
                  height: "fit-content",
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "center",
                  top: "20%",
                  position: "sticky",
                  borderRadius: 10,
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                }}
              >
                <Typography
                  color="black"
                  gutterBottom
                  variant="body1"
                  sx={{
                    fontFamily: "Changa",
                    fontWeight: "700",
                    textAlign: "center",
                    // width:"75%",
                    fontSize: {
                      xs: "1.3rem",
                      sm: "1.5rem",
                      md: "1.7rem",
                      lg: "2rem",
                    },
                    lineHeight: {
                      xs: "1.3rem",
                      sm: "1.5rem",
                      md: "1.7rem",
                      lg: "2rem",
                    },
                    paddingBottom: "15px",
                    margin: 0,
                  }}
                >
                  Track Your Progress
                </Typography>
                {tableOfContentData.map((data, index) => (
                  <TableOfContentItem key={index}
                    id={data.id}
                    title={data.title}
                    section={data.section}
                    refVar={data.refVar}
                    tocRef={data.tocRef}
                  />
                ))}
              </Grid>
            </Grid>
          ) : (
            <Swiper 
            // className="mobile-swiper"
              onSwiper={setSwiper}
              slidesPerView={"auto"}
              freeMode
              navigation
              autoHeight
              setWrapperSize
              // centeredSlides ={currentSection===1? false:true}
              // centered
              centeredSlidesBounds
              // wrapperClass="mobile-swiper-wrapper"
              modules={[Navigation]}

              breakpoints={{
                640: {
                  slidesPerView: "auto",
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: "auto",
                  spaceBetween: 20,
                  // slidesOffsetAfter:440
                },
                1024: {
                  slidesPerView: "auto",
                  spaceBetween: 30,
                },
              }}
              // modules={[Pagination]}
              // className="mySwiper"
              style={{
                padding: "3.14vw 1.14vw",
                zIndex: 999,
                width: "100%",
                height: "15vh",
                paddingRight: "30px",
                overflowY: "hidden",
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                overflowX: "scroll",
                // top: "80%",
                bottom: "-5px",
                position: "sticky",
                // borderRadius: 10,
                background: "#f7f7f7",
                "--swiper-navigation-size": "15px",
              }}
            >
              {tableOfContentData.map((data, index) => (
                <SwiperSlide className="mobile-swiper-slide" key={index}
                  style={{
                    display: "flex",
                    marginTop: "12px",
                    paddingRight: "20px",
                  }}
                >
                  <article className="mobile-swiper">
                <div className="mobile-swiper-container">
                  <TableOfContentItem
                    id={data.id} key={index}
                    title={data.title}
                    section={data.section}
                    refVar={data.refVar}
                    tocRef={data.tocRef}
                  />
                  </div>
                  </article>
                </SwiperSlide>
              ))}

            </Swiper>
          )}
          <Grid
            sx={{
              paddingLeft: "7.14vw",
              paddingRight: "7.14vw",
              // paddingTop: "7.14vw",
              width: "65vw",
              "@media screen and (max-width: 900px)": {
                width: "100vw",
                // paddingTop: "3.14vw",
              },
            }}
          >
            <Grid container direction="column">
              <Grid item sx={{ pt: "15px" }}>
                <Typography variant="questionText">
                  Accessing philanthropic opportunities that make it easy to
                  understand how community needs are fulfilled is extremely
                  difficult. We believe that if nonprofits knew more about how
                  impact makers like you wanted to drive a positive impact
                  within your community, they will be better positioned and
                  equipped to respond to the social issues you care about
                  through philanthropic opportunities that you trust to create a
                  sustainable, measurable positive impact.
                  <br />
                  <br />
                  The philanthropic spectrum survey does exactly that: it
                  discovers the factors that impact makers and change agents
                  feel are the most important to consider when engaging in
                  philanthropy and opportunities to drive a positive impact.
                  <br />
                  <br />
                  With your help, nonprofits and community-based organizations
                  can strengthen their initiatives and programs for effectively
                  meeting and responding to the needs of our communities.
                  {/* <br />
                  <br />
                  Thank you. */}
                </Typography>
              </Grid>
            </Grid>

            <form
              ref={surveyForm}
              headers="application/json"
              name="contact-form"
              onSubmit={sendEmail}
            >
              {/* Header */}
              <Grid container sx={{ flexDirection: "row" }}>
                <div
                  id="section-1"
                  ref={ref1}
                  style={{
                    width: "100vw",
                    // minHeight: "100vh",
                    scrollMarginTop: "150px",
                  }}
                >
                  <Grid
                    container
                    justifyContent="left"
                    sx={{ marginTop: "50px", flexDirection: "column" }}
                  >
                    <Typography
                      variant="contactTitle"
                      sx={{
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.4rem",
                          lg: "1.7rem",
                        },
                        lineHeight: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.4rem",
                          lg: "1.7rem",
                        },
                      }}
                    >
                  My Philanthropic Influences                    
                  </Typography>
                    <Typography
                      variant="contactSubTitle"
                      sx={{ pt: "15px", lineHeight: "1.1rem" }}
                    >
                      Pleae tell us about how you feel about the world,
                      philanthropy, and positive impact.
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingBottom: "1.14vw",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        background: "#f7f7f7",
                        width: "100%",
                        position: "sticky",
                        marginBottom: "30px",
                        borderRadius: 10,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                        padding: "3.14vw 7.14vw",
                      }}
                    >
                      {/* Name and Email Address */}

                      <Grid
                        sx={{
                          width: "30%",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <ScrollAnimation animateIn="fadeInDown">
                          <Typography
                            sx={{
                              color: "#62B6D0",
                              fontSize: 30,
                              padding: 0,
                              fontFamily: "Changa",
                            }}
                          >
                            001
                          </Typography>
                        </ScrollAnimation>
                      </Grid>

                      {/* Question 1 */}

                      <Typography variant="fieldTitle">
                      What influences you to engage in philanthropy?
                      </Typography>
                      <Typography variant="fieldTitle" style={{display:"flex", width:"100%", paddingTop:"10px"}}>
                      Please choose an answer from the list below or type in and select the option to create your answer if it is not included in the list. 
                      </Typography>
                      <CustomCreatableSelect
                        data={philanthropicInfluences}
                        question={"question_1"}
                        answer={answer1}
                        setAnwser={setAnswer1}
                        name="question_1"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        background: "#f7f7f7",
                        width: "100%",
                        position: "sticky",
                        marginBottom: "30px",
                        borderRadius: 10,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                        padding: "3.14vw 7.14vw",
                      }}
                    >
                      {/* Name and Email Address */}

                      <Grid
                        sx={{
                          width: "30%",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <ScrollAnimation animateIn="fadeInDown">
                          <Typography
                            sx={{
                              color: "#62B6D0",
                              fontSize: 30,
                              padding: 0,
                              fontFamily: "Changa",
                            }}
                          >
                            002
                          </Typography>
                        </ScrollAnimation>
                      </Grid>
                      <Typography variant="fieldTitle">
                      How often do you engage in philanthropy or other forms of community impact? 
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        sx={{
                          justifyContent: "space-between",
                          paddingTop: "15px",
                          alignItems: "flex-end",
                          padding: "3.14vw 0",

                          "@media screen and (max-width: 900px)": {
                            alignSelf: "center",
                            paddingBottom: "3.14vw",
                            // flexDirection:"column",
                          },
                        }}
                      >
                        <Typography
                          color="#000"
                          variant="h1"
                          style={{
                            paddingTop: 10,
                            paddingRight: 5,
                            margin: 0,
                            width: "auto",
                          }}
                        >
                          {"Not Much"}
                        </Typography>

                        <Grid
                          container
                          direction="column"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "auto",
                          }}
                        >
                          <Typography
                            color="#000"
                            variant="h1"
                            style={{
                              paddingBottom: 10,
                              margin: 0,
                              width: "auto",
                            }}
                          >
                            {"1"}
                          </Typography>
                          <Button
                            onClick={() =>
                              handleSetValue(1, setAnswer2, "question_2", 'checkbubble')
                            }
                            style={{
                              minWidth: "auto",
                              background: answer2 === 1 ? "#F1B9D0" : "#FFF",
                              borderColor: "#F1B9D0",
                              borderWidth: "2px",
                              borderStyle: "solid",
                              borderRadius: 50,
                              height: 20,
                              width: 20,
                            }}
                          />
                        </Grid>

                        <Grid
                          container
                          direction="column"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "auto",
                          }}
                        >
                          <Typography
                            color="#000"
                            variant="h1"
                            style={{
                              paddingBottom: 10,
                              margin: 0,
                              width: "auto",
                            }}
                          >
                            {"2"}
                          </Typography>
                          <Button
                            onClick={() =>
                              handleSetValue(2, setAnswer2, "question_2", 'checkbubble')
                            }
                            style={{
                              minWidth: "auto",
                              background: answer2 === 2 ? "#F1B9D0" : "#FFF",
                              borderColor: "#F1B9D0",
                              borderWidth: "2px",
                              borderStyle: "solid",
                              borderRadius: 50,
                              height: 20,
                              width: 20,
                            }}
                          />
                        </Grid>

                        <Grid
                          container
                          direction="column"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "auto",
                          }}
                        >
                          <Typography
                            color="#000"
                            variant="h1"
                            style={{
                              paddingBottom: 10,
                              margin: 0,
                              width: "auto",
                            }}
                          >
                            {"3"}
                          </Typography>
                          <Button
                            onClick={() =>
                              handleSetValue(3, setAnswer2, "question_2", 'checkbubble')
                            }
                            style={{
                              minWidth: "auto",
                              background: answer2 === 3 ? "#F1B9D0" : "#FFF",
                              borderColor: "#F1B9D0",
                              borderWidth: "2px",
                              borderStyle: "solid",
                              borderRadius: 50,
                              height: 20,
                              width: 20,
                            }}
                          />
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "auto",
                          }}
                        >
                          <Typography
                            color="#000"
                            variant="h1"
                            style={{
                              paddingBottom: 10,
                              margin: 0,
                              width: "auto",
                            }}
                          >
                            {"4"}
                          </Typography>
                          <Button
                            onClick={() =>
                              handleSetValue(4, setAnswer2, "question_2", 'checkbubble')
                            }
                            style={{
                              minWidth: "auto",
                              background: answer2 === 4 ? "#F1B9D0" : "#FFF",
                              borderColor: "#F1B9D0",
                              borderWidth: "2px",
                              borderStyle: "solid",
                              borderRadius: 50,
                              height: 20,
                              width: 20,
                            }}
                          />
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "auto",
                          }}
                        >
                          <Typography
                            color="#000"
                            variant="h1"
                            style={{
                              paddingBottom: 10,
                              margin: 0,
                              width: "auto",
                            }}
                          >
                            {"5"}
                          </Typography>
                          <Button
                            onClick={() =>
                              handleSetValue(5, setAnswer2, "question_2", 'checkbubble')
                            }
                            style={{
                              minWidth: "auto",
                              background: answer2 === 5 ? "#F1B9D0" : "#FFF",
                              borderColor: "#F1B9D0",
                              borderWidth: "2px",
                              borderStyle: "solid",
                              borderRadius: 50,
                              height: 20,
                              width: 20,
                            }}
                          />
                        </Grid>
                        <Typography
                          gutterBottom
                          variant="h1"
                          style={{
                            paddingTop: 10,
                            paddingLeft: 5,
                            margin: 0,
                            width: "auto",
                          }}
                        >
                          {"A Lot"}
                        </Typography>
                      </Grid>
                    </Grid>
                  
                     
                      <Grid
                        container
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          paddingBottom: "1.14vw",
                        }}
                      >
                        <Grid
                          item
                          sx={{
                            background: "#f7f7f7",
                            width: "100%",
                            position: "sticky",
                            marginBottom: "30px",
                            borderRadius: 10,
                            boxShadow:
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                            padding: "3.14vw 7.14vw",
                          }}
                        >
                          {/* Name and Email Address */}

                          <Grid
                            sx={{
                              width: "30%",
                              "@media screen and (max-width: 900px)": {
                                width: "100%",
                              },
                            }}
                          >
                            <ScrollAnimation animateIn="fadeInDown">
                              <Typography
                                sx={{
                                  color: "#62B6D0",
                                  fontSize: 30,
                                  padding: 0,
                                  fontFamily: "Changa",
                                }}
                              >
                                003
                              </Typography>
                            </ScrollAnimation>
                          </Grid>

                          <Typography variant="fieldTitle">
                            {"What does philanthropy and social action mean to\
                            you?"}
                          </Typography>

                          <CustomMessageBox
                            name="question_3"
                            placeholder="Question 3"
                            variant="outlined"
                            multiline
                            rows={3}
                            id="question_3"
                            fullWidth
                            type="text"
                            onChange={handleChange}
                            helperText={
                              hasError("question_3")
                                ? formState.errors.question_3[0]
                                : null
                            }
                            error={hasError("question_3")}
                          />
                        </Grid>
                      </Grid>
                      
                      <Grid
                    container
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingBottom: "1.14vw",
                    }}
                  >
                    
                    
                    <Grid
                      item
                      sx={{
                        background: "#f7f7f7",
                        width: "100%",
                        position: "sticky",
                        borderRadius: 10,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                        padding: "3.14vw 7.14vw",
                      }}
                    >
                      {/* Name and Email Address */}

                      <Grid
                        sx={{
                          width: "30%",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <ScrollAnimation animateIn="fadeInDown">
                          <Typography
                            sx={{
                              color: "#62B6D0",
                              fontSize: 30,
                              padding: 0,
                              fontFamily: "Changa",
                            }}
                          >
                            004
                          </Typography>
                        </ScrollAnimation>
                      </Grid>
                      <Typography variant="fieldTitle">
                        What type of philanthropy ie. donations, volunteering,
                        social media support, or petition signing do you engage
                        in the most? Please choose a few from the list. You can
                        also enter your option in the text box below.{" "}
                      </Typography>
                      <Typography variant="fieldTitle" style={{paddingTop:"10px",width:"100%",display:"flex"}}>
                      Please choose an answer from the list below or type in and select the option to create your answer if it is not included in the list. 
                      </Typography>
                      <CustomCreatableSelect
                        data={philanthropicActionOptions}
                        question={"question_4"}
                        answer={answer4}
                        setAnwser={setAnswer4}
                        name="question_4"
                        onChange={handleChange}
                      />
                    </Grid>
                   
                    
                  </Grid>                  </Grid>
                </div>
              </Grid>
              <Grid container sx={{ flexDirection: "row" }}>
                <div
                  ref={ref2}
                  style={{
                    width: "100vw",
                    // minHeight: "100vh",
                    scrollMarginTop: "150px",
                  }}
                >
                  <Grid
                    container
                    justifyContent="left"
                    id="section-2"
                    sx={{ flexDirection: "column" }}
                  >
                    <Typography
                      variant="contactTitle"
                      sx={{
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.4rem",
                          lg: "1.7rem",
                        },
                        lineHeight: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.4rem",
                          lg: "1.7rem",
                        },
                      }}
                    >
                      My Philanthropic Interests
                    </Typography>
                    <Typography
                      variant="contactSubTitle"
                      sx={{ pt: "15px", lineHeight: "1.1rem" }}
                    >
                      Please tell us about the positive impact you've made.
                    </Typography>
                  </Grid>
                 
            
             
                  <Grid
                      item
                      sx={{
                        background: "#f7f7f7",
                        width: "100%",
                        position: "sticky",
                        borderRadius: 10,
                        marginBottom: "30px",
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                        padding: "3.14vw 7.14vw",
                      }}
                    >
                      {/* Name and Email Address */}

                      <Grid
                        sx={{
                          width: "30%",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <ScrollAnimation animateIn="fadeInDown">
                          <Typography
                            sx={{
                              color: "#62B6D0",
                              fontSize: 30,
                              padding: 0,
                              fontFamily: "Changa",
                            }}
                          >
                            005
                          </Typography>
                        </ScrollAnimation>
                      </Grid>
                      <Typography variant="fieldTitle">
                        What are some social issues that you care about and
                        would like to solve today?
                      </Typography>
                      <Typography variant="fieldTitle" style={{paddingTop:"10px",width:"100%",display:"flex"}}>
                      Please search for and choose an answer from the list below or type in and select the option to create your answer if it is not included in the list. 
                      </Typography>
                      <CustomCreatableSelect
                        data={social_issues}
                        question={"question_5"}
                        answer={answer5}
                        setAnwser={setAnswer5}
                      />
                    </Grid>
                  <Grid
                    container
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingBottom: "1.14vw",
                    }}
                  >
                    
                    
                    <Grid
                    item
                    sx={{
                      background: "#f7f7f7",
                      width: "100%",
                      position: "sticky",
                      marginBottom: "30px",
                      borderRadius: 10,
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                      padding: "3.14vw 7.14vw",
                    }}
                  >
                    {/* Name and Email Address */}

                    <Grid
                      sx={{
                        width: "30%",
                        "@media screen and (max-width: 900px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <ScrollAnimation animateIn="fadeInDown">
                        <Typography
                          sx={{
                            color: "#62B6D0",
                            fontSize: 30,
                            padding: 0,
                            fontFamily: "Changa",
                          }}
                        >
                          006
                        </Typography>
                      </ScrollAnimation>
                    </Grid>
                    <Typography variant="fieldTitle">
                      What does a nonprofit or community organization need to do
                      to gain your trust?{" "}
                    </Typography>
                    <Typography variant="fieldTitle" style={{paddingTop:"10px",width:"100%",display:"flex"}}>
                      Please choose an answer from the list below or type in and select the option to create your answer if it is not included in the list. 
                      </Typography>
                    <CustomCreatableSelect
                      data={nonprofitActionsforTrust}
                      question={"question_6"}
                      answer={answer6}
                      setAnwser={setAnswer6}
                    />
                  </Grid>
                  <Grid
                      item
                      sx={{
                        background: "#f7f7f7",
                        width: "100%",
                        position: "sticky",
                        marginBottom: "30px",
                        borderRadius: 10,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                        padding: "3.14vw 7.14vw",
                      }}
                    >
                      {/* Name and Email Address */}

                      <Grid
                        sx={{
                          width: "30%",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <ScrollAnimation animateIn="fadeInDown">
                          <Typography
                            sx={{
                              color: "#62B6D0",
                              fontSize: 30,
                              padding: 0,
                              fontFamily: "Changa",
                            }}
                          >
                            007
                          </Typography>
                        </ScrollAnimation>
                      </Grid>
                      <Typography variant="fieldTitle">
                        What is the most important factor that you consider when
                        building trust with a community organization or
                        nonprofit before you engage in philanthropic actions ie.
                        donating or volunteering?{" "}
                      </Typography>
                      <Typography variant="fieldTitle" style={{paddingTop:"10px",width:"100%",display:"flex"}}>
                      Please choose an answer from the list below or type in and select the option to create your answer if it is not included in the list. 
                      </Typography>
                      <CustomCreatableSelect
                        data={factorsForPhilanthropy}
                        question={"question_7"}
                        answer={answer7}
                        setAnwser={setAnswer7}
                      />
                    </Grid>
                  <Grid
                    item
                    sx={{
                      background: "#f7f7f7",
                      width: "100%",
                      position: "sticky",
                      marginBottom: "30px",
                      borderRadius: 10,
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                      padding: "3.14vw 7.14vw",
                    }}
                  >
                    {/* Name and Email Address */}

                    <Grid
                      sx={{
                        width: "30%",
                        "@media screen and (max-width: 900px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <ScrollAnimation animateIn="fadeInDown">
                        <Typography
                          sx={{
                            color: "#62B6D0",
                            fontSize: 30,
                            padding: 0,
                            fontFamily: "Changa",
                          }}
                        >
                          008
                        </Typography>
                      </ScrollAnimation>
                    </Grid>
                    <Typography variant="fieldTitle">
                      Please list any nonprofits you have engaged with, trust as
                      a credible organization, or just made you feel really
                      good.{" "}
                    </Typography>
                    <Typography variant="fieldTitle" style={{paddingTop:"10px",width:"100%",display:"flex"}}>
                      Please search for and choose an answer from the list below or type in and select the option to create your answer if it is not included in the list. 
                      </Typography>
                    <CustomCreatableSelect
                      data={nonprofits}
                      question={"question_8"}
                      answer={answer8}
                      setAnwser={setAnswer8}
                    />
                  </Grid>
                  
                  </Grid>

                </div>
              </Grid>
            
              <Grid
                ref={ref3}
                container
                style={{ flexDirection: "row", scrollMarginTop: "150px" }}
              >
                {/* <div  style={{ width: "100vw", minHeight: "100vh", }} > */}
                <Grid
                  container
                  justifyContent="left"
                  id="section-3"
                  sx={{ flexDirection: "column" }}
                >
                  <Typography
                    variant="contactTitle"
                    sx={{
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.4rem",
                        lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.4rem",
                        lg: "1.7rem",
                      },
                    }}
                  >
                    My Philanthropic Impact
                  </Typography>
                  <Typography
                    variant="contactSubTitle"
                    sx={{ pt: "15px", lineHeight: "1.1rem" }}
                  >
                    Please tell us about your philanthropic history
                  </Typography>
                </Grid>
                <Grid
                  container
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                    paddingBottom: "1.14vw",
                    // minHeight: "100vh",
                  }}
                >
                  <Grid
                    item
                    sx={{
                      background: "#f7f7f7",
                      width: "100%",
                      position: "sticky",
                      marginBottom: "30px",
                      borderRadius: 10,
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                      padding: "3.14vw 7.14vw",
                    }}
                  >
                    {/* Name and Email Address */}

                    <Grid
                      sx={{
                        width: "30%",
                        "@media screen and (max-width: 900px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <ScrollAnimation animateIn="fadeInDown">
                        <Typography
                          sx={{
                            color: "#62B6D0",
                            fontSize: 30,
                            padding: 0,
                            fontFamily: "Changa",
                          }}
                        >
                          009
                        </Typography>
                      </ScrollAnimation>
                    </Grid>
                    <Typography variant="fieldTitle">
                      Do you care about what happens after you engage in
                      philanthropy?
                    </Typography>

                    <Grid
                      container
                      direction="column"
                      sx={{
                        justifyContent: "space-between",
                        paddingTop: "15px",
                        "@media screen and (max-width: 900px)": {
                          alignItems: "center",
                          width: "100%",
                          // flexDirection:"column",
                        },
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          paddingBottom: "25px",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleSetValue(true, setAnswer9, "question_9", 'checkbubble')
                          }
                          style={{
                            // minWidth: "auto",
                            justifyContent: "center",
                            background:
                              answer9 === true ? "rgba(98,182,208,1)" : "#FFF",
                            borderColor:
                              answer9 === true ? "rgba(0,0,0,0)" : "#f7f7f7",
                            borderWidth: answer9 === true ? "0px" : "2px",
                            borderStyle: "solid",
                            textAlign: "center",
                            boxShadow:
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                            borderRadius: 30,
                            height: 85,
                            width: "100%",
                            margin: "1.14vw",
                            maxWidth: 350,
                          }}
                        >
                          <Typography
                            color={answer9 === true ? "#FFF" : "#000"}
                            variant="h1"
                            sx={{
                              padding: "1.14vw",
                              margin: 0,
                              width: "auto",
                              "@media screen and (max-width: 900px)": {
                                fontSize: "1.2rem",
                              },
                            }}
                          >
                            {"Yes"}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          paddingBottom: "25px",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleSetValue(false, setAnswer9, "question_9", 'checkbubble')
                          }
                          style={{
                            // minWidth: "auto",
                            justifyContent: "center",
                            background:
                              answer9 === false
                                ? "rgba(98,182,208,1)"
                                : "#FFF",
                            borderColor:
                              answer9 === false ? "rgba(0,0,0,0)" : "#f7f7f7",
                            borderWidth: answer9 === false ? "0px" : "2px",
                            borderStyle: "solid",
                            textAlign: "center",
                            boxShadow:
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                            borderRadius: 30,
                            height: 85,
                            width: "100%",
                            margin: "1.14vw",
                            maxWidth: 350,
                          }}
                        >
                          <Typography
                            variant="h1"
                            color={answer9 === false ? "#FFF" : "#000"}
                            sx={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              margin: 0,
                              width: "auto",
                              "@media screen and (max-width: 900px)": {
                                fontSize: "1.2rem",
                              },
                            }}
                          >
                            {"No"}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  
                  <Grid
                    item
                    sx={{
                      background: "#f7f7f7",
                      width: "100%",
                      position: "sticky",
                      borderRadius: 10,
                      marginBottom: "30px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                      padding: "3.14vw 7.14vw",
                    }}
                  >
                    {/* Name and Email Address */}

                    <Grid
                      sx={{
                        width: "30%",
                        "@media screen and (max-width: 900px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <ScrollAnimation animateIn="fadeInDown">
                        <Typography
                          sx={{
                            color: "#62B6D0",
                            fontSize: 30,
                            padding: 0,
                            fontFamily: "Changa",
                          }}
                        >
                          010
                        </Typography>
                      </ScrollAnimation>
                    </Grid>
                    <Typography variant="fieldTitle">
                      How do you track your impact after you engage in
                      philanthropy?{" "}
                    </Typography>
                    <Typography variant="fieldTitle" style={{paddingTop:"10px",width:"100%",display:"flex"}}>
                      Please choose an answer from the list below or type in and select the option to create your answer if it is not included in the list. 
                      </Typography>
                    <CustomCreatableSelect
                      data={trackYourImpact}
                      question={"question_10"}
                      answer={answer10}
                      setAnwser={setAnswer10}
                    />
                  </Grid>
                  <Grid
                    item
                    sx={{
                      background: "#f7f7f7",
                      width: "100%",
                      position: "sticky",
                      borderRadius: 10,
                      marginBottom: "30px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                      padding: "3.14vw 7.14vw",
                    }}
                  >
                    {/* Name and Email Address */}

                    <Grid
                      sx={{
                        width: "30%",
                        "@media screen and (max-width: 900px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <ScrollAnimation animateIn="fadeInDown">
                        <Typography
                          sx={{
                            color: "#62B6D0",
                            fontSize: 30,
                            padding: 0,
                            fontFamily: "Changa",
                          }}
                        >
                          011
                        </Typography>
                      </ScrollAnimation>
                    </Grid>
                    <Typography variant="fieldTitle">
                      Would you download an app where change agents and impact
                      makers like you could track, promote, and respond to
                      social issues through community led approaches in a matter
                      of seconds at any time, from any place?{" "}
                    </Typography>

                    <Grid
                      container
                      direction="column"
                      sx={{
                        justifyContent: "space-between",
                        paddingTop: "15px",
                        "@media screen and (max-width: 900px)": {
                          alignItems: "center",
                          width: "100%",
                          // flexDirection:"column",
                        },
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          paddingBottom: "25px",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleSetValue(true, setAnswer11, "question_11", 'checkbubble')
                          }
                          style={{
                            // minWidth: "auto",
                            justifyContent: "center",
                            background:
                              answer11 === true ? "rgba(98,182,208,1)" : "#FFF",
                            borderColor:
                              answer11 === true ? "rgba(0,0,0,0)" : "#f7f7f7",
                            borderWidth: answer11 === true ? "0px" : "2px",
                            borderStyle: "solid",
                            textAlign: "center",
                            boxShadow:
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                            borderRadius: 30,
                            height: 85,
                            width: "100%",
                            margin: "1.14vw",
                            maxWidth: 350,
                          }}
                        >
                          <Typography
                            color={answer11 === true ? "#FFF" : "#000"}
                            variant="h1"
                            sx={{
                              padding: "1.14vw",
                              margin: 0,
                              width: "auto",
                              "@media screen and (max-width: 900px)": {
                                fontSize: "1.2rem",
                              },
                            }}
                          >
                            {"Yes"}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          paddingBottom: "25px",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleSetValue(false, setAnswer11, "question_11", 'checkbubble')
                          }
                          style={{
                            // minWidth: "auto",
                            justifyContent: "center",
                            background:
                              answer11 === false
                                ? "rgba(98,182,208,1)"
                                : "#FFF",
                            borderColor:
                              answer11 === false ? "rgba(0,0,0,0)" : "#f7f7f7",
                            borderWidth: answer11 === false ? "0px" : "2px",
                            borderStyle: "solid",
                            textAlign: "center",
                            boxShadow:
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                            borderRadius: 30,
                            height: 85,
                            width: "100%",
                            margin: "1.14vw",
                            maxWidth: 350,
                          }}
                        >
                          <Typography
                            variant="h1"
                            color={answer11 === false ? "#FFF" : "#000"}
                            sx={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              margin: 0,
                              width: "auto",
                              "@media screen and (max-width: 900px)": {
                                fontSize: "1.2rem",
                              },
                            }}
                          >
                            {"No"}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      background: "#f7f7f7",
                      width: "100%",
                      position: "sticky",
                      borderRadius: 10,
                      marginBottom: "30px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                      padding: "3.14vw 7.14vw",
                    }}
                  >
                    {/* Name and Email Address */}

                    <Grid
                      sx={{
                        width: "30%",
                        "@media screen and (max-width: 900px)": {
                          width: "100%",
                        },
                      }}
                    >
                      <ScrollAnimation animateIn="fadeInDown">
                        <Typography
                          sx={{
                            color: "#62B6D0",
                            fontSize: 30,
                            padding: 0,
                            fontFamily: "Changa",
                          }}
                        >
                          012
                        </Typography>
                      </ScrollAnimation>
                    </Grid>
                    <Typography variant="fieldTitle">
                      Would you recommend this survey to others?
                    </Typography>

                    <Grid
                      container
                      direction="column"
                      sx={{
                        justifyContent: "space-between",
                        paddingTop: "15px",
                        "@media screen and (max-width: 790px)": {
                          alignSelf: "center",
                          width: "100%",
                          // flexDirection:"column",
                        },
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "center",
                          paddingBottom: "25px",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleSetValue(true, setAnswer12, "question_12", 'checkbubble')
                          }
                          style={{
                            // minWidth: "auto",
                            justifyContent: "center",
                            background:
                              answer12 === true ? "rgba(98,182,208,1)" : "#FFF",
                            borderColor:
                            answer12 === true ? "rgba(0,0,0,0)" : "#f7f7f7",
                            borderWidth: answer12 === true ? "0px" : "2px",
                            borderStyle: "solid",
                            textAlign: "center",
                            boxShadow:
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                            borderRadius: 30,
                            height: 85,
                            width: "100%",
                            margin: "1.14vw",
                            maxWidth: 350,
                          }}
                        >
                          <Typography
                            color={answer12 === true ? "#FFF" : "#000"}
                            variant="h1"
                            sx={{
                              padding: "1.14vw",
                              margin: 0,
                              width: "auto",
                              "@media screen and (max-width: 900px)": {
                                fontSize: "1.2rem",
                              },
                            }}
                          >
                            {"Yes"}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          justifyContent: "center",
                          paddingBottom: "25px",
                        }}
                      >
                        <Button
                          onClick={() =>
                            handleSetValue(false, setAnswer12, "question_12", 'checkbubble')
                          }
                          style={{
                            // minWidth: "auto",
                            justifyContent: "center",
                            background:
                            answer12 === false
                                ? "rgba(98,182,208,1)"
                                : "#FFF",
                            borderColor:
                              answer12 === false ? "rgba(0,0,0,0)" : "#f7f7f7",
                            borderWidth: answer12 === false ? "0px" : "2px",
                            borderStyle: "solid",
                            textAlign: "center",
                            boxShadow:
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                            borderRadius: 30,
                            height: 85,
                            width: "100%",
                            margin: "1.14vw",
                            maxWidth: 350,
                          }}
                        >
                          <Typography
                            variant="h1"
                            color={answer12 === false ? "#FFF" : "#000"}
                            sx={{
                              paddingTop: 10,
                              paddingBottom: 10,
                              margin: 0,
                              width: "auto",
                              "@media screen and (max-width: 900px)": {
                                fontSize: "1.2rem",
                              },
                            }}
                          >
                            {"No"}
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Topic */}
                </Grid>
                {/* </div> */}
              </Grid>
              <Grid container style={{ flexDirection: "row" }}>
                <div
                  ref={ref4}
                  style={{
                    width: "100vw",
                    // minHeight: "100vh",
                    scrollMarginTop: "150px",
                  }}
                >
                  <Grid
                    container
                    justifyContent="left"
                    id="section-4"
                    sx={{ flexDirection: "column" }}
                  >
                    <Typography
                      variant="contactTitle"
                      sx={{
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.4rem",
                          lg: "1.7rem",
                        },
                        lineHeight: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.4rem",
                          lg: "1.7rem",
                        },
                      }}
                    >
                      Contact Information
                    </Typography>
                    <Typography
                      variant="contactSubTitle"
                      sx={{ pt: "15px", lineHeight: "1.1rem" }}
                    >
                      Please tell us about yourself. This will help us to help
                      you even more.
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    sx={{
                      flexDirection: "row",
                      alignItems: "center",
                      paddingBottom: "1.14vw",
                      // minHeight: "100vh",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        background: "#f7f7f7",
                        width: "100%",
                        // minHeight: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        position: "sticky",
                        borderRadius: 10,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                        padding: "3.14vw 7.14vw",
                      }}
                    >
                      <Grid
                        sx={{
                          width: "30%",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <ScrollAnimation animateIn="fadeInDown">
                          <Typography
                            sx={{
                              color: "#62B6D0",
                              fontSize: 30,
                              padding: 0,
                              fontFamily: "Changa",
                            }}
                          >
                            013
                          </Typography>
                        </ScrollAnimation>
                      </Grid>
                      <Typography variant="fieldTitle">
                        Please enter your contact information. We will share a copy of your survey with you. This information is only used for tracking purposes. Clarintel will not reach out to you unless specified below.
                      </Typography>

                      {/* Name and Email Address */}
                      <Grid
                        container
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          typography="formStyleLeft"
                        >
                          <Typography variant="fieldTitle">
                            First Name*
                          </Typography>
                          <CustomTextField
                            name="first_name"
                            placeholder="First Name"
                            variant="outlined"
                            size="medium"
                            id="first_name"
                            fullWidth
                            type="text"
                            onChange={handleChange}
                            helperText={
                              hasError("first_name")
                                ? formState.errors.first_name[0]
                                : null
                            }
                            error={hasError("first_name")}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          typography="formStyleRight"
                        >
                          <Typography variant="fieldTitle">
                            Last Name*
                          </Typography>
                          <CustomTextField
                            name="last_name"
                            placeholder="Last Name"
                            variant="outlined"
                            size="medium"
                            id="last_name"
                            fullWidth
                            type="text"
                            onChange={handleChange}
                            helperText={
                              hasError("last_name")
                                ? formState.errors.last_name[0]
                                : null
                            }
                            error={hasError("last_name")}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          typography="formStyleLeft"
                        >
                          <Typography variant="fieldTitle">
                            Email Address*
                          </Typography>
                          <CustomTextField
                            name="email_address"
                            placeholder="Email Address"
                            variant="outlined"
                            size="medium"
                            id="email"
                            fullWidth
                            type="email"
                            onChange={handleChange}
                            helperText={
                              hasError("email_address")
                                ? formState.errors.email_address[0]
                                : null
                            }
                            error={hasError("email_address")}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          typography="formStyleRight"
                        >
                          <Typography variant="fieldTitle">
                            Phone Number*
                          </Typography>
                          <CustomTextField
                            name="phone_number"
                            placeholder="(123) 345-6789"
                            variant="outlined"
                            size="medium"
                            id="phone"
                            fullWidth
                            type="tel"
                            InputProps={{ 
                              inputComponent: PhoneNumberPatternFormatCustom
                            }}
                            onChange={handleChange}
                            helperText={
                              hasError("phone_number")
                                ? formState.errors.phone_number[0]
                                : null
                            }
                            error={hasError("phone_number")}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          typography="formStyleLeft"
                        >
                          <Typography variant="fieldTitle">{"Race"}</Typography>
                          <FormControl fullWidth>
                            {formState.values.race === undefined ? (
                              <InputLabel
                                sx={{
                                  top: "calc(50% - 1.3em)",
                                  fontFamily: "Changa",
                                  fontSize: "15px",
                                  paddingLeft: "5px",
                                  color: "#A1A1A1",
                                }}
                                disableAnimation
                                shrink={false}
                                focused={false}
                                id="item_type_label"
                              >
                                {"Race"}
                              </InputLabel>
                            ) : null}
                            <CustomSelect
                              id="race"
                              fullWidth
                              value={formState.values.race || ""}
                              onChange={handleChange}
                              name="race"
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  background: "white",
                                  borderRadius: "40px",
                                },
                                height: "70px",

                                fontFamily: "Changa",
                                fontSize: "18px",
                                marginTop: "10px",
                              }}
                              error={hasError("race")}
                            >
                              {races.map((race, item) => (
                                <CustomMenuItem key={item} value={race}>
                                  {race}
                                </CustomMenuItem>
                              ))}
                            </CustomSelect>
                          </FormControl>
                          <FormHelperText>
                            {hasError("race") ? formState.errors.race[0] : null}
                          </FormHelperText>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={6}
                          typography="formStyleRight"
                        >
                          <Typography variant="fieldTitle">
                            {"Gender"}
                          </Typography>
                          <FormControl fullWidth>
                            {formState.values.race === undefined ? (
                              <InputLabel
                                sx={{
                                  top: "calc(50% - 1.3em)",
                                  fontFamily: "Changa",
                                  fontSize: "15px",
                                  paddingLeft: "5px",
                                  color: "#A1A1A1",
                                }}
                                disableAnimation
                                shrink={false}
                                focused={false}
                                id="item_type_label"
                              >
                                {"Gender"}
                              </InputLabel>
                            ) : null}
                            <CustomSelect
                              id="gender"
                              fullWidth
                              value={formState.values.gender || ""}
                              onChange={handleChange}
                              name="gender"
                              style={{
                                borderRadius: "40px",
                                height: "70px",
                                fontFamily: "Changa",
                                fontSize: "18px",
                                marginTop: "10px",
                              }}
                              error={hasError("gender")}
                            >
                              {genders.map((gender, item) => (
                                <CustomMenuItem key={item} value={gender}>
                                  {gender}
                                </CustomMenuItem>
                              ))}
                            </CustomSelect>
                          </FormControl>
                          <FormHelperText>
                            {hasError("gender")
                              ? formState.errors.gender[0]
                              : null}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      {/* Opt-ins */}
                      <Grid item xs={12} typography="formStyle">
                        <Typography variant="fieldTitle">
                          Do you want to enroll in Clarintel newsletters and
                          emails?{" "}
                        </Typography>
                        <FormGroup>
                          <CustomFormControlLabel
                            control={
                              <CustomCheckbox
                                name="opt_in"
                                onChange={handleChange}
                              />
                            }
                            label="I would like stay up to date with the latest news from Clarintel."
                            // helperText={
                            //   hasError("opt_in")
                            //     ? formState.errors.opt_in[0]
                            //     : null
                            // }
                            error={hasError("opt_in")
                            ? formState.errors.opt_in[0]
                            : null}
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>

                    {/* Topic */}
                  </Grid>
                </div>
              </Grid>
              <Grid
                container
                sx={{
                  flexDirection: "row",
                  alignItems: "center",
                  paddingBottom: "1.14vw",
                }}
              >
                <div
                  ref={ref5}
                  style={{
                    width: "100%",
                    // minHeight: "100vh",
                    scrollMarginTop: "150px",
                  }}
                >
                  <Grid
                    container
                    justifyContent="left"
                    id="section-5"
                    sx={{ flexDirection: "column" }}
                  >
                    <Typography
                      variant="contactTitle"
                      sx={{
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.4rem",
                          lg: "1.7rem",
                        },
                        lineHeight: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.4rem",
                          lg: "1.7rem",
                        },
                      }}
                    >
                      The Amazon Raffle{" "}
                    </Typography>
                    <Typography
                      variant="contactSubTitle"
                      sx={{ pt: "15px", lineHeight: "1.1rem" }}
                    >
                      Please fill out the below section if you would like to
                      enter our Amazon Raffle.
                    </Typography>
                    <Grid
                      item
                      sx={{
                        background: "#f7f7f7",
                        width: "100%",
                        "@media screen and (max-width: 900px)": {
                          marginBottom:"20px",
                        },
                        // minHeight: "100vh",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-evenly",
                        position: "sticky",
                        borderRadius: 10,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1)",
                        padding: "3.14vw 7.14vw",
                      }}
                    >
                      <Grid
                        sx={{
                          width: "30%",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <ScrollAnimation animateIn="fadeInDown">
                          <Typography
                            sx={{
                              color: "#62B6D0",
                              fontSize: 30,
                              padding: 0,
                              fontFamily: "Changa",
                            }}
                          >
                            014
                          </Typography>
                        </ScrollAnimation>
                      </Grid>
                      {/* <Typography variant="fieldTitle">
                      This section is optional unless you would like to
                      participate in our raffle.{" "}
                    </Typography> */}

                      {/* Name and Email Address */}
                      {/* <Grid
                        container
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      > */}
                      <Grid
                        item
                        xs={12}
                        sx={{ pb: "1.14vw" }}
                        typography="formStyle"
                      >
                        <Typography variant="fieldTitle">
                          Do you want to enter our raffle?*{" "}
                        </Typography>
                        <FormGroup>
                          <Grid
                            container
                            direction="row"
                            sx={{
                              justifyContent: "space-between",
                              maxWidth: "200px",
                              paddingTop: "15px",
                              "@media screen and (max-width: 790px)": {
                                alignSelf: "center",
                                // flexDirection:"column",
                              },
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "auto",
                              }}
                            >
                              <Typography
                                variant="h2"
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                  paddingRight: 10,
                                  margin: 0,
                                  width: "auto",
                                }}
                              >
                                {"Yes"}
                              </Typography>
                              <Button
                                onClick={() =>
                                  handleSetValue(
                                    true,
                                    setEnterRaffle,
                                    "enter_raffle", 'checkbubble'
                                  )
                                }
                                style={{
                                  minWidth: "auto",
                                  background:
                                    enter_raffle === true ? "#F1B9D0" : "#FFF",
                                  borderColor: "#F1B9D0",
                                  borderWidth: "2px",
                                  borderStyle: "solid",
                                  borderRadius: 50,
                                  height: 20,
                                  width: 20,
                                }}
                              />
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "auto",
                              }}
                            >
                              <Typography
                                gutterBottom
                                variant="h2"
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                  paddingRight: 10,
                                  margin: 0,
                                  width: "auto",
                                }}
                              >
                                {"No"}
                              </Typography>
                              <Button
                                onClick={() =>
                                  handleSetValue(
                                    false,
                                    setEnterRaffle,
                                    "enter_raffle",
                                    'checkbubble'
                                  )
                                }
                                style={{
                                  minWidth: "auto",
                                  background:
                                    enter_raffle === false ? "#F1B9D0" : "#FFF",
                                  borderColor: "#F1B9D0",
                                  borderWidth: "2px",
                                  borderStyle: "solid",
                                  borderRadius: 50,
                                  height: 20,
                                  width: 20,
                                }}
                              />
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} typography="formStyle">
                        <Typography variant="fieldTitle">
                          Do you attend the University of Maryland College Park?*{" "}
                        </Typography>
                        <FormGroup>
                          <Grid
                            container
                            direction="row"
                            sx={{
                              justifyContent: "space-between",
                              maxWidth: "200px",
                              paddingTop: "15px",
                              "@media screen and (max-width: 790px)": {
                                alignSelf: "center",
                                // flexDirection:"column",
                              },
                            }}
                          >
                            <Grid
                              container
                              direction="row"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "auto",
                              }}
                            >
                              <Typography
                                variant="h2"
                                style={{
                                  paddingTop: 10,
                                  paddingBottom: 10,
                                  paddingRight: 10,
                                  margin: 0,
                                  width: "auto",
                                }}
                              >
                                {"Yes"}
                              </Typography>
                              <Button
                                disabled={!enter_raffle}
                                onClick={() =>
                                  handleSetValue(
                                    true,
                                    setUMDAttendance,
                                    "umd_student", 'checkbubble'
                                  )
                                }
                                style={{
                                  minWidth: "auto",
                                  background: !enter_raffle
                                    ? "rgba(0, 0, 0, 0.05)"
                                    : umd_attendance === true
                                    ? "#F1B9D0"
                                    : "#FFF",
                                  borderColor: !enter_raffle
                                    ? "rgba(0, 0, 0, 0.15)"
                                    : "#F1B9D0",
                                  borderWidth: "2px",
                                  borderStyle: "solid",
                                  borderRadius: 50,
                                  height: 20,
                                  width: 20,
                                }}
                              />
                            </Grid>
                            <Grid
                              container
                              direction="row"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "auto",
                              }}
                            >
                              <Typography
                                gutterBottom
                                variant="h2"
                                style={{
                                  margin: 0,
                                  paddingRight: 10,
                                  width: "auto",
                                }}
                              >
                                {"No"}
                              </Typography>
                              <Button
                                disabled={!enter_raffle}
                                onClick={() =>
                                  handleSetValue(
                                    false,
                                    setUMDAttendance,
                                    "umd_student", 'checkbubble'
                                  )
                                }
                                style={{
                                  minWidth: "auto",
                                  background: !enter_raffle
                                    ? "rgba(0, 0, 0, 0.05)"
                                    : umd_attendance === false
                                    ? "#F1B9D0"
                                    : "#FFF",
                                  borderColor: !enter_raffle
                                    ? "rgba(0, 0, 0, 0.15)"
                                    : "#F1B9D0",
                                  borderWidth: "2px",
                                  borderStyle: "solid",
                                  borderRadius: 50,
                                  height: 20,
                                  width: 20,
                                }}
                              />
                            </Grid>
                          </Grid>
                          {umd_attendance ? (
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              sx={{ pb: "1.14vw", pt: "1.14vw" }}
                              typography="formStyleLeft"
                            >
                              <Typography variant="fieldTitle">
                                Enter your UID
                              </Typography>
                              <CustomTextField
                                disabled={!enter_raffle}
                                name="uid"
                                placeholder="UID"
                                variant="outlined"
                                size="medium"
                                id="uid"
                                fullWidth
                                type="text"
                                style={{
                                  borderColor: !enter_raffle
                                    ? "rgba(0, 0, 0, 0.15)"
                                    : "#62B6D0",
                                }}
                                onChange={handleChange}
                                helperText={
                                  hasError("uid")
                                    ? formState.errors.uid[0]
                                    : null
                                }
                                error={hasError("uid")}
                              />
                            </Grid>
                          ) : null}
                        </FormGroup>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        typography="formStyle"
                        style={{ paddingTop: "40px" }}
                      >
                        <Typography variant="fieldTitle">
                          To finalize raffle entry, please enter your social
                          media usernames below and follow @clarintel and
                          @akpsiot on Instagram, Facebook, LinkedIn, and
                          Twitter. Even if you are not entering the raffle,
                          enter your username below and connect with us anyways!
                          We will be sure to give you exclusive access to what
                          we have planned. If your username is the same for
                          both, you only need to enter your username once.
                        </Typography>
                        <FormGroup style={{ paddingTop: "10px" }}>
                          
                          <Grid
                            container
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              typography="formStyleLeft"
                            >
                              <Typography variant="fieldTitle">
                                Instagram
                              </Typography>
                              <CustomTextField
                                name="instagram_username"
                                placeholder="Instagram Username"
                                variant="outlined"
                                size="medium"
                                id="instagram_username"
                                fullWidth
                                type="text"
                                onChange={handleChange}
                                disabled={!enter_raffle}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                      borderColor: !enter_raffle
                                        ? "rgba(0, 0, 0, 0.35)"
                                        : "#62B6D0",
                                      borderWidth: "1.5px",
                                    },
                                  },
                                }}
                                helperText={
                                  hasError("instagram_username")
                                    ? formState.errors.instagram_username[0]
                                    : null
                                }
                                error={hasError("instagram_username")}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              typography="formStyleRight"
                            >
                              <Typography variant="fieldTitle">
                                Twitter
                              </Typography>
                              <CustomTextField
                                name="twitter_username"
                                placeholder="Twitter Username"
                                variant="outlined"
                                size="medium"
                                id="twitter_username"
                                fullWidth
                                type="text"
                                onChange={handleChange}
                                disabled={!enter_raffle}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                      borderColor: !enter_raffle
                                        ? "rgba(0, 0, 0, 0.35)"
                                        : "#62B6D0",
                                      borderWidth: "1.5px",
                                    },
                                  },
                                }}
                                helperText={
                                  hasError("twitter_username")
                                    ? formState.errors.twitter_username[0]
                                    : null
                                }
                                error={hasError("twitter_username")}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              typography="formStyleLeft"
                            >
                              <Typography variant="fieldTitle">
                                Facebook
                              </Typography>
                              <CustomTextField
                                name="facebook_username"
                                placeholder="Facebook Username"
                                variant="outlined"
                                size="medium"
                                id="facebook_username"
                                fullWidth
                                type="text"
                                onChange={handleChange}
                                disabled={!enter_raffle}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                      borderColor: !enter_raffle
                                        ? "rgba(0, 0, 0, 0.35)"
                                        : "#62B6D0",
                                      borderWidth: "1.5px",
                                    },
                                  },
                                }}
                                helperText={
                                  hasError("facebook_username")
                                    ? formState.errors.facebook_username[0]
                                    : null
                                }
                                error={hasError("facebook_username")}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={12}
                              lg={6}
                              typography="formStyleRight"
                            >
                              <Typography variant="fieldTitle">
                                LinkedIn
                              </Typography>
                              <CustomTextField
                                name="linkedin_username"
                                placeholder="Linkedin Username"
                                variant="outlined"
                                size="medium"
                                id="linkedin_username"
                                disabled={!enter_raffle}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "&:hover fieldset": {
                                      borderColor: !enter_raffle
                                        ? "rgba(0, 0, 0, 0.35)"
                                        : "#62B6D0",
                                      borderWidth: "1.5px",
                                    },
                                  },
                                }}
                                fullWidth
                                type="text"
                                onChange={handleChange}
                                helperText={
                                  hasError("linkedin_username")
                                    ? formState.errors.linkedin_username[0]
                                    : null
                                }
                                error={hasError("linkedin_username")}
                              />
                            </Grid>
                          </Grid>
                        </FormGroup>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        typography="formStyle"
                        sx={{ paddingTop: "40px" }}
                      >
                        <Typography variant="fieldTitle">
                          If you entered your username, do you want Clarintel to
                          follow you back on social media and repost your social
                          action? If so, check this box and use
                          #Technology4PositiveImpact on the posts that you would
                          like us to share.
                        </Typography>
                        <FormGroup style={{ paddingTop: "10px" }}>
                          <CustomFormControlLabel
                            control={
                              <CustomCheckbox
                                disabled={!enter_raffle}
                                name="social_media_follow"
                                onChange={handleChange}
                              />
                            }
                            label="I would like Clarintel to follow me back and repost my positive impact."
                            // helperText={
                            //   hasError("social_media_follow")
                            //     ? formState.errors.social_media_follow[0]
                            //     : null
                            // }
                            error={hasError("social_media_follow")
                            ? formState.errors.social_media_follow[0]
                            : null}
                          />
                        </FormGroup>
                      </Grid>
                      {/* </Grid> */}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {/* Submit Button */}
              <Grid
                item
                ref={submitRef}
                id={"submit-button"}
                xs={12}
                typography="formStyle"
                sx={{
                  // display: "flex",
                  height: "20vh",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  "@media screen and (max-width: 900px)": {
                    display: "none",
                  },
                }}
              >
                <CustomSubmitButton
                  variant="contained"
                  type="submit"
                  disabled={!isFormComplete()}
                  style={{ width: "100%" }}
                >
                  Submit My Survey
                </CustomSubmitButton>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
      {/* </main> */}
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h1 = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "1rem",
  fontWeight: "500",
  lineHeight: "1rem",

  textAlign: "left",
  "@media screen and (max-width: 900px)": {
    fontSize: ".8rem",
    lineHeight: ".8rem",
  },
};

theme.typography.h2 = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "1.2rem",
  fontWeight: "500",
  lineHeight: "1.2rem",

  textAlign: "left",
  "@media screen and (max-width: 900px)": {
    fontSize: "1rem",
    lineHeight: "1rem",
  },
};

theme.typography.h3 = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "2.5rem",
  lineHeight: "2.5rem",
  "@media screen and (min-width: 60em)": {
    fontSize: "5rem",
  lineHeight: "5rem",
  },
  
  // "@media screen and (max-width:600px)": {
  //   fontSize: "2.5rem !important",
  //   lineHeight: "3.5 rem",
  // },
};

theme.typography.contactTitle = {
  fontFamily: "Changa",

  fontWeight: "900",
  paddingTop: "40px",

  "@media screen and (max-width: 900px)": {
    paddingTop: "25px",
  },
};

theme.typography.contactSubTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",

  paddingBottom: "30px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.fieldTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontWeight: "900",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1rem",
    lineHeight: "1rem",
  },
};

theme.typography.formStyleLeft = {
  // paddingLeft:"1.14vw",
  paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 1200px)": {
    paddingTop: "1.14vw",
    paddingBottom: "1.14vw",
    paddingLeft: 0,
  },
};

theme.typography.formStyleRight = {
  paddingLeft: "1.14vw",
  // paddingRight:"1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 1200px)": {
    paddingTop: "1.14vw",
    paddingBottom: "1.14vw",
    paddingLeft: 0,
  },
};

theme.typography.formStyle = {
  // paddingLeft:"1.14vw",
  // paddingRight:"1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.subtitle1 = {
  fontFamily: "Changa",
};

theme.typography.questionText = {
  fontFamily: "Changa",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};
