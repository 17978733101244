import { Box, CssBaseline, Grid, ThemeProvider, Typography, createTheme, responsiveFontSizes } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router";
import { CustomRedirectionButton, CustomSendDonationButton } from '../../components/FormComponents/FormComponents';
import AttendeeLetter from './AttendeeLetter';


export default function AttendeeRegistrationSubmission({page_title}) {
  const navigate = useNavigate();

  const handleHomeRedirect = () => navigate("/");
  
  const handleProgramsRedirect = () => navigate("/programs");
  
  const handleDonateRedirect = () => navigate("/donate");

  const handleContactRedirect = () => navigate("/contact");

  sessionStorage.removeItem("attendee_registration")
  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
        {/* <PageHeader title="Connect With Us" background={"rgb(98, 182, 208)"} isContact={1} /> */}
        <Box sx={{ pb: 4, pt: 12, mt: 8,  }}>
          <Grid sx={{ paddingLeft: "7.14vw", paddingRight: "7.14vw",  }}>
            <Grid container>
              <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid sx={{position:"relative",  maxHeight:"450px"}}> 
                  <img
                    src="/images/registration_completion.png"
                    style={{
                      width: "100%",
                      height:"100%",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                  </Grid>
              <Grid  sx={{ mb: 3 }}>
                  <Typography variant="thankYouTitle">Thank you for registering! We can't wait to meet you!</Typography>
                </Grid>
                <Grid  sx={{ mb: 3 }}>
                  <Typography variant="thankYouSubtext">We will send you an email with your registration details shortly. In the meantime, would you mind taking a quick survey to help nonprofits create the change that you want to see in the world? </Typography>
                </Grid>
                <AttendeeLetter />
                <Grid  sx={{ mb: 3,width:"100%" , justifyContent:"center", display:"flex", }}>
                  <CustomRedirectionButton
                    href="/philanthropic-spectrum-survey"
                    variant="contained"
                    onClick={handleProgramsRedirect}
                  >
                    Take Our Survey
                  </CustomRedirectionButton>
                </Grid>
                <Grid  sx={{ mb: 3,width:"100%" , justifyContent:"center" , display:"flex",}}>
                  <CustomSendDonationButton sx={{width:"100%"}}
                    href="/donate"
                    variant="contained"
                    onClick={handleDonateRedirect}
                  >
                    Donate
                  </CustomSendDonationButton>
                </Grid>
                <Grid  sx={{ mb: 3,width:"100%" , justifyContent:"center" , display:"flex",}}>
                  <CustomRedirectionButton
                    href="/programs"
                    variant="contained"
                    onClick={handleProgramsRedirect}
                  >
                    View Our Programs
                  </CustomRedirectionButton>
                </Grid>
                <Grid  sx={{ mb: 3,width:"100%" , justifyContent:"center", display:"flex", }}>
                  <CustomRedirectionButton
                    href="/contact"
                    variant="contained"
                    onClick={handleContactRedirect}
                  >
                    Contact Us
                  </CustomRedirectionButton>
                </Grid>
                <Grid  sx={{ mb: 3,width:"100%" , justifyContent:"center", display:"flex",}}>
                  <CustomRedirectionButton
                    href="/"
                    variant="contained"
                    onClick={handleHomeRedirect}
                  >
                    Back to Home Page
                  </CustomRedirectionButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.thankYouTitle = {
  fontFamily: "Changa",
  color:"#62B6D0",
  fontSize: "3rem",
  lineHeight: "3rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "2.5rem",
  lineHeight: "2.5rem",
  },
}

theme.typography.thankYouSubtext = {
  fontFamily: "Changa",
  fontSize: "2rem",
  lineHeight: "2rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.5rem",
  lineHeight: "1.5rem",
  },
}