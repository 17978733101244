import {
  AppBar,
  Button,
  ClickAwayListener,
  Drawer,
  Grow,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HideOnScroll from "../HideOnScroll/HideOnScroll";
import AppNavbarButton from "./AppNavbarButton/AppNavbarButton";
import AppNavbarMenuContent from "./AppNavbarMenuContent/AppNavbarMenuContent";
import "./styles.css";

export default function AppNavbar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setMenuOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState(0);

  const [aboutOpen, setAboutOpen] = React.useState(false);
  const [programOpen, setProgramOpen] = React.useState(false);
  const [careOpen, setCareOpen] = React.useState(false);

  const aboutAnchorRef = React.useRef(null);
  const programAnchorRef = React.useRef(null);
  const careAnchorRef = React.useRef(null);

  // return focus to the button when we transitioned from !open -> open
  const prevAboutOpen = React.useRef(aboutOpen);
  const prevProgramOpen = React.useRef(programOpen);
  const prevCareOpen = React.useRef(careOpen);

  const handleToggle = (prevOpen, setOpen) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (anchorRef, event, setOpen) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event, setOpen) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  useEffect(() => {
    setCurrentPath(
      location.state?.currentPath != null
        ? location.state.currentPath
        : props.currentPath
    );
  }, []);

  const handleCurrentTab = (val, path) => {
    setCurrentPath(val);

    navigate(path, {
      state: {
        currentPath: val,
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        PaperProps={{
          sx: {
            background: "#FFFF",
            color: "white",
            width: "100vw",
          },
        }}
        open={open}
        anchor="right"
        transitionDuration={1000}
      >
        <AppNavbarMenuContent drawerClose={() => setMenuOpen(false)} />
      </Drawer>
      <HideOnScroll style={{ padding: 0 }}>
        <AppBar
          sx={{
            backgroundColor: "#FFFF",
            boxShadow: "none",
            zIndex: 1000,
            width: "100vw",
          }}
        >
          <Box sx={{ display: { xs: "none", md: "none", lg: "initial" } }}>
            <div
              style={{
                top: 0,
                margin: "15px 0px",
                padding: "0px 15px",
                minHeight: "72px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div>
                  <Link
                    href="/"
                    component="button"
                    onClick={() => handleCurrentTab(0, "/")}
                    sx={{ width: "120px", paddingLeft: "20px" }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src="/images/logo.png"
                      alt="main-logo"
                    />
                  </Link>
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    onMouseEnter={() =>
                      handleToggle(prevAboutOpen, setAboutOpen)
                    }
                    style={{ width: "fit-content" }}
                    onMouseLeave={() =>
                      handleToggle(prevAboutOpen, setAboutOpen)
                    }
                  >
                    <Button
                      ref={aboutAnchorRef}
                      id="composition-button"
                      aria-controls={aboutOpen ? "composition-menu" : undefined}
                      aria-expanded={aboutOpen ? "true" : undefined}
                      aria-haspopup="true"
                      href="/about"
                      onClick={() => handleCurrentTab(1, "/about")}
                    >
                      <Typography
                        variant="nav"
                        sx={{
                          color: currentPath === 1 ? "#43b1d3" : "#000",
                          fontWeight: currentPath === 1 ? "700" : "500",
                        }}
                      >
                        About Us
                      </Typography>
                    </Button>
                    <Popper
                      open={aboutOpen}
                      anchorEl={aboutAnchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={(e) =>
                                handleClose(aboutAnchorRef, e, setAboutOpen)
                              }
                            >
                              <MenuList
                                autoFocusItem={aboutOpen}
                                // id="composition-menu"
                                // aria-labelledby="composition-button"
                                onKeyDown={(e) =>
                                  handleListKeyDown(e, setAboutOpen)
                                }
                              >
                                <MenuItem
                                  sx={{ color: "#000", fontSize: "1rem" }}
                                  component="a"
                                  onClick={(e) =>(
                                    handleClose(aboutAnchorRef, e, setAboutOpen),
                                    handleCurrentTab(1, "/about/leadership"))
                                  }
                                  href="/about/leadership"
                    
                                >
                                  Our Leadership
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                  <div
                    onMouseEnter={() =>
                      handleToggle(prevProgramOpen, setProgramOpen)
                    }
                    style={{ width: "fit-content" }}
                    onMouseLeave={() =>
                      handleToggle(prevProgramOpen, setProgramOpen)
                    }
                  >
                    <Button
                      ref={programAnchorRef}
                      id="composition-button"
                      aria-controls={
                        programOpen ? "composition-menu" : undefined
                      }
                      aria-expanded={programOpen ? "true" : undefined}
                      aria-haspopup="true"
                      href="/programs"
                      onClick={() => handleCurrentTab(2, "/programs")}
                    >
                      <Typography
                        variant="nav"
                        sx={{
                          color: currentPath === 2 ? "#43b1d3" : "#000",
                          fontWeight: currentPath === 2 ? "700" : "500",
                        }}
                      >
                        Our Programs
                      </Typography>
                    </Button>
                    <Popper
                      open={programOpen}
                      anchorEl={programAnchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={(e) =>
                                handleClose(programAnchorRef, e, setProgramOpen)
                              }
                            >
                              <MenuList
                                autoFocusItem={programOpen}
                                // id="composition-menu"
                                // aria-labelledby="composition-button"
                                onKeyDown={(e) =>
                                  handleListKeyDown(e, setProgramOpen)
                                }
                              >
                                <MenuItem
                                  sx={{ color: "#000", fontSize: "1rem" }}
                                  href="/programs/air"
                                  component="a"
                                  onClick={(e) =>(
                                    handleClose(
                                      programAnchorRef,
                                      e,
                                      setProgramOpen
                                    ),
                                   
                      handleCurrentTab(2, "/programs/air"))
                                  }
                                >
                                  AIR Program
                                </MenuItem>
                                <MenuItem
                                  sx={{ color: "#000", fontSize: "1rem" }}
                                  component="a"
                                  href="/programs/philanthropic-access"
                                  onClick={(e) =>(
                                    handleClose(
                                      programAnchorRef,
                                      e,
                                      setProgramOpen
                                    ),
                                   
                      handleCurrentTab(2, "/programs/philanthropic-access"))
                                  }
                                >
                                  Philanthropic Access
                                </MenuItem>
                                <MenuItem
                                component="a"
                                href="/programs/unity"
                                onClick={(e) =>(
                                  handleClose(
                                    programAnchorRef,
                                    e,
                                    setProgramOpen
                                  ),
                                 
                    handleCurrentTab(2, "/programs/unity"))
                                }
                                  sx={{ color: "#000", fontSize: "1rem" }}
                                 
                                >
                                  Commmunity Unity & Capacity Building
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                  <Button
                    href="/contact"
                    onClick={() => handleCurrentTab(4, "/contact")}
                  >
                    <Typography
                      variant="nav"
                      sx={{
                        color: currentPath === 4 ? "#43b1d3" : "#000",
                        fontWeight: currentPath === 4 ? "700" : "500",
                      }}
                    >
                      Connect With Us
                    </Typography>
                  </Button>
                  <Button
                    href="/philanthropic-spectrum-survey"
                    onClick={() =>
                      handleCurrentTab(5, "/philanthropic-spectrum-survey")
                    }
                  >
                    <Typography
                      variant="nav"
                      sx={{
                        color: currentPath === 5 ? "#43b1d3" : "#000",
                        fontWeight: currentPath === 5 ? "700" : "500",
                      }}
                    >
                      Philanthropic Spectrum Survey
                    </Typography>
                  </Button>
                  <div
                    onMouseEnter={() =>
                      handleToggle(prevCareOpen, setCareOpen)
                    }
                    style={{ width: "fit-content" }}
                    onMouseLeave={() =>
                      handleToggle(prevCareOpen, setCareOpen)
                    }
                  >
                    <Button
                      ref={careAnchorRef}
                      id="composition-button"
                      aria-controls={careOpen ? "composition-menu" : undefined}
                      aria-expanded={careOpen ? "true" : undefined}
                      aria-haspopup="true"
                    href="/care"
                    onClick={() =>( handleCurrentTab(7, "/care"),handleToggle(prevCareOpen, setCareOpen))}
                  >
                    <Typography
                      variant="nav"
                      sx={{
                        color: currentPath === 7 ? "#43b1d3" : "#000",
                        fontWeight: currentPath === 7 ? "700" : "500",
                      }}
                    >
                      Care Fair
                    </Typography>
                  </Button>
                  <Popper
                      open={careOpen}
                      anchorEl={careAnchorRef.current}
                      role={undefined}
                      placement="bottom-start"
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom-start"
                                ? "left top"
                                : "left bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={(e) =>
                                handleClose(careAnchorRef, e, setCareOpen)
                              }
                            >
                              <MenuList
                                autoFocusItem={careOpen}
                                // id="composition-menu"
                                // aria-labelledby="composition-button"
                                onKeyDown={(e) =>
                                  handleListKeyDown(e, setCareOpen)
                                }
                              >
                                <MenuItem
                                  sx={{ color: "#000", fontSize: "1rem" }}
                                  component="a"
                                  href="/care"
                                  onClick={(e) =>
                                    (handleClose(
                                      careAnchorRef,
                                      e,
                                      setCareOpen
                                    ),handleCurrentTab(7, "/care"))
                                  }
                                >
                                  About the Care Fair
                                </MenuItem>
                                <MenuItem
                                  sx={{ color: "#000", fontSize: "1rem" }}
                                  // component="a"
                                  // href="/care/register"
                                  onClick={()=>window.open('https://forms.office.com/r/54GNhL0Byx')}
                                 target="_blank"
                                >
                                  Nonprofit Registration
                                </MenuItem>
                                <MenuItem
                                component="a"
                                href="/care/register"
                                  sx={{ color: "#000", fontSize: "1rem" }}
                                  onClick={(e) =>
                                    (handleClose(
                                      careAnchorRef,
                                      e,
                                      setCareOpen,
                                      sessionStorage.setItem("registrationType", JSON.stringify(1)),
                                    ),navigate("/care/register",{state:{registrationType:1}}),window.location.reload(true))
                                  }
                                >
                                  Attendee Registration
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <Button
                    href="/donate"
                    sx={{
                      marginLeft: "auto",
                      backgroundColor: "#F1B9D0",
                      border: "3px solid #F1B9D0",
                      fontWeight: 700,
                      fontSize: "1.2rem",
                      borderRadius: "50px",
                      padding: "5px 15px",
                      width: "90px",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "rgba(245,185,208,.3)",
                        border: "3px solid rgba(245,185,208,0)",
                        color: "#F1B9D0",
                      },
                    }}
                  >
                    <Typography variant="navButton">Donate</Typography>
                  </Button>
                </div>
              </div>
              {/* <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item container alignItems="center" direction="row" justifyContent="space-between">
                  <Grid item sx={{ display: { xs: 'flex', md: 'flex', lg: 'none' }}}>
                    <AppNavbarButton open={() => setOpen(true)} />
                  </Grid>
                  <Grid item>
                    <Link
                      href="/"
                      component="button"
                      onClick={() => handleCurrentTab(0, "/")}
                      sx={{ width:"80px" }}
                    >
                      <img
                        style={{ width: "100%" }}
                        src="/images/logo.png"
                        alt="main-logo"
                      />
                    </Link>
                  </Grid>
                  <Grid item sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }}>
                    <Box 
                      sx={{ 
                        flexGrow: 1, 
                        display: { xs: 'none', md: 'flex', lg: 'flex' }
                      }}
                    >
                      <Grid item>
                        <Button 
                          href="/about"
                          onClick={() => handleCurrentTab(1, "/about")}  
                        > 
                          <Typography 
                            variant="nav" 
                            sx={{ 
                              color: currentPath === 1 ? "#43b1d3" : "#000",
                              fontWeight: currentPath === 1? "700" : "500"
                            }}
                          >
                            About Us
                          </Typography> 
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button 
                          href="/programs"
                          onClick={() => handleCurrentTab(2, "/programs")}  
                        >
                          <Typography 
                            variant="nav" 
                            sx={{ 
                              color: currentPath === 2 ? "#43b1d3" : "#000",
                              fontWeight: currentPath === 2 ? "700" : "500"
                            }}
                          >
                            Our Programs
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          href="/contact"
                          onClick={() => handleCurrentTab(4, "/contact")}
                        >
                          <Typography 
                            variant="nav" 
                            sx={{ 
                              color: currentPath === 4 ? "#43b1d3" : "#000",
                              fontWeight: currentPath === 4 ? "700" : "500"
                            }}
                          >
                            Connect With Us
                          </Typography>
                        </Button>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button  
                      href="/donate"
                      sx={{
                        marginLeft:"10px",
                        backgroundColor: "#F1B9D0",
                        border: "3px solid #F1B9D0",
                        fontWeight: 700,
                        fontSize:  "1.2rem",
                        borderRadius: "50px",
                        padding:  "5px 15px",
                        width: "90px",
                        color: "#FFF",
                        '&:hover': {
                          backgroundColor: "rgba(245,185,208,.3)",
                          border: "3px solid rgba(245,185,208,0)",
                          color: "#F1B9D0"
                        },
                      }}
                    >
                      <Typography variant="navButton">Donate</Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid> */}
            </div>
          </Box>
          <Box sx={{ display: { xs: "initial", md: "initial", lg: "none" } }}>
            <div
              style={{
                top: 0,
                margin: "15px 0px",
                padding: "0px 15px",
                minHeight: "72px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  justifyContent: "space-between",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <div>
                  <AppNavbarButton open={() => setMenuOpen(true)} />
                </div>
                <div>
                  <Link
                    href="/"
                    component="button"
                    onClick={() => handleCurrentTab(0, "/")}
                    sx={{ width: "80px" }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src="/images/logo.png"
                      alt="main-logo"
                    />
                  </Link>
                </div>
                <div>
                  <Button
                    href="/donate"
                    sx={{
                      backgroundColor: "#F1B9D0",
                      border: "3px solid #F1B9D0",
                      fontWeight: 700,
                      fontSize: "1.2rem",
                      borderRadius: "50px",
                      padding: "5px 15px",
                      width: "90px",
                      color: "#FFF",
                      "&:hover": {
                        backgroundColor: "rgba(245,185,208,.3)",
                        border: "3px solid rgba(245,185,208,0)",
                        color: "#F1B9D0",
                      },
                    }}
                  >
                    <Typography variant="navButton">Donate</Typography>
                  </Button>
                </div>
              </div>
            </div>
          </Box>
        </AppBar>
      </HideOnScroll>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h3 = {
  fontSize: "2.5rem",
  lineHeight: "2.5rem",
  color: "#dfd0ff",
  fontWeight: 700,
  width: "100%",
  fontFamily: "Changa",
  overflowWrap: "break-word",
  "@media (max-width:790px)": {
    fontSize: "24px !important",
  },
};

theme.typography.body1 = {
  fontSize: "1.9rem",
  lineHeight: "1.9rem",
  fontWeight: 500,
  color: "white",
  fontFamily: "Changa",
  overflowWrap: "break-word",

  "@media (max-width:790px)": {
    fontSize: "18px",
  },
};

theme.typography.h5 = {
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  fontWeight: 700,
  fontFamily: "Changa",
  color: "#FFF",
  overflowWrap: "break-word",
  "@media (max-width:790px)": {
    fontSize: "15px ",
  },
};

theme.typography.button = {
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  fontWeight: 500,
  fontFamily: "Changa",
  overflowWrap: "break-word",
  "@media (max-width:790px)": {
    fontSize: "16px !important",
  },
};

theme.typography.header2 = {
  fontSize: "1.8rem",
  marginBottom: 0,
  marginRight: "24px",
  marginTop: 0,
};

theme.typography.nav = {
  fontSize: "1.2rem",
  color: "black",
  fontWeight: "500",
  padding: "0px 15px",
};

theme.typography.navButton = {
  fontSize: "1.2rem",
  fontWeight: "700",
  "@media (max-width:950px)": {
    fontSize: "1rem !important",
  },
};

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};
