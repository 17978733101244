import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { CustomSubmitButton } from "../../components/FormComponents/FormComponents";
import { data } from "../../data/data_file";

export default function LogisticsSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const navigate = useNavigate();

  theme.typography.h4 = {
    textAlign: "left",
    color:"#FFFF",
    textTransform: "uppercase",
    fontFamily: "Changa",
    // fontSize: "2.5rem",
    // lineHeight: "2.5rem",
    paddingBottom:"5px",
    fontFamily: "Changa",
  fontSize: "5rem",
  lineHeight: "5rem",
  "@media screen and (max-width:960px)": {
    fontSize: "3rem",
    lineHeight: "3rem",
  },
    // "@media screen and (max-width:600px)": {
    //   fontSize: "2.5rem !important",
    //   lineHeight: "3.5 rem",
    // },
  };
  theme.typography.subtitle1 = {
    textAlign: "left",
    fontFamily: "Changa",
    fontWeight: "500",
    fontSize: "1.9rem",
      lineHeight: "1.9rem",
    color: "#FFF",
    "@media screen and (max-width: 900px)": {
      fontSize: "1.3rem",
      lineHeight: "1.3rem",
    },
  };

  theme.typography.subtitle2 = {
    textAlign: "left",
    fontFamily: "Changa",
    fontWeight: "500",
    fontSize: "1.3rem",
      lineHeight: "1.3rem",
    color: "#FFF",
    "@media screen and (max-width: 900px)": {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  };
  theme.typography.h3 = {
    color: "#000",
    fontFamily: "Changa",
    fontSize: "7rem",
    lineHeight: "7rem",
    "@media screen and (max-width:960px)": {
      fontSize: "5rem",
      lineHeight: "5rem",
    },
  };

  var statTheme = {
    width: "50%",
    "@media screen and (max-width: 790px)": {
      width: "100%",
    },
  };
  var statTheme2 = {
    width: "50%",
    "@media screen and (max-width: 790px)": {
      width: "100%",
    },
  };
  return (
    <Grid container sx={{ height: "85vh" }}>
      <ThemeProvider theme={theme}>
        <img
          src={data.event_page.image}
          style={{ width: "100%", height: "100%" ,objectFit:"cover",objectPosition:"center"}}
          alt={"background"}
          loading="lazy"
        />
        <Grid
          sx={{
            position: "absolute",
            maxWidth:"800px",
            
            background: "rgba(0, 0, 0, 0.4)",
            borderRadius: "50px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.25)",
            backdropFilter: "blur(7.6px)",
            alignSelf: "center",
            "-webkit-backdrop-filter": " blur(7.6px)",
            width: "60vw",
            right: "50px",
            padding: "0 3.14vw 5.14vw",
            // height: "50vh",
            display:"flex",
            flexDirection:"column",
            justifyContent:"space-between",
            "@media screen and (max-width: 900px)": {
              // width: "90vw",
              maxWidth:"90vw",
              width:"100%",
              right: "0",
              left:"0",
              margin:"auto"
              }
          }}
        >
          {/* <Grid item style={{ width: "100%", paddingBottom: "2vw" }}>
              <Typography
                // variant="h2"
                sx={{
                  margin: 0,
                  display: "flex",
                  fontFamily: "Changa",
                  fontWeight:700,
                  // color: "#62B6D0",
                  color: "#62B6D0",

                  fontSize: "1.25rem",
                  lineHeight: "1.6rem",
                  // "@media screen and (max-width: 900px)": {
                  //   flexDirection:"column",
                  // }
                }}
              >
                {`The Way Forward`}
                <Typography
                  variant="h6"
                  component={"span"}
                  style={{
                    letterSpacing: "-0.09em",
                    fontFamily: "Changa",
                    paddingLeft: "5px",
                    lineHeight: "1.6rem",
                  }}
                >
                  {`----`}
                </Typography>
              </Typography>
            </Grid> */}
            <Grid sx={{pt:"30px",pb:"20px"}}>
          <svg style={{right:"10px",position:"relative"}}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="50"
            height="50"
            x="0"
            y="0"
            viewBox="0 0 128 128"
          >
            <g>
              <path
                fill="#62b6d0"
                d="M64 1a42.9 42.9 0 0 0-42.9 42.9c0 21.75 36.16 74.23 42.08 82.67a1 1 0 0 0 1.64 0c5.92-8.44 42.08-60.92 42.08-82.67A42.9 42.9 0 0 0 64 1z"
                opacity="1"
                data-original="#e95a6a"
                className=""
              ></path>
              <circle
                cx="64"
                cy="42.73"
                r="28.83"
                fill="#ffffff"
                opacity="1"
                data-original="#dbe2eb"
                className=""
              ></circle>
              <path
                fill="#62b6d0"
                d="M76.46 31.26C72.2 27 65 29.05 64 34.12c-1-5.07-8.2-7.16-12.46-2.86-8.33 8.05-.65 24 12.46 28.64 13.12-4.61 20.79-20.59 12.46-28.64z"
                opacity="1"
                data-original="#ef5361"
                className=""
              ></path>
              <path
                fill="#62b6d0"
                d="M64 59.9a24.58 24.58 0 0 1-4.94-2.41 23.54 23.54 0 0 0 2.73 1.16C74.91 54 82.58 38.06 74.25 30a7.4 7.4 0 0 0-.67-.6 7.79 7.79 0 0 1 2.88 1.85c8.33 8.06.65 24.04-12.46 28.65z"
                opacity="1"
                data-original="#da2a4750"
                className=""
              ></path>
            </g>
          </svg>
          </Grid>
          <Grid>
          <Typography variant="h4">{"Logistics"}</Typography>
          <Typography variant="subtitle2">
            {"The Adele H. Stamp Student Union"}
          </Typography>

          <Typography variant="subtitle2">
            {"3972 Campus Drive College Park, MD 20742"}
          </Typography>
          <Typography variant="subtitle2">{"Thursday, April 4, 2024"}</Typography>
          <Typography variant="subtitle2">{"12:00pm EST - 8:00pm EST"}</Typography>
          <Typography sx={{paddingTop:"20px"}} variant="subtitle1">{data.event_page.tagline}</Typography>

          <Grid
            container
            // typography="formStyle"
            sx={{
             "@media screen and (max-width: 900px)": {
                    flexDirection:"column",
                    alignItems:"center"
                  },
              display: "flex",
              // justifyContent: "center",
              flex:0,
              flexBasis:"auto"
            }}
          >
            <CustomSubmitButton
        
              sx={{ width: "50%", margin: 0, mt: "20px","@media screen and (max-width: 900px)": {
                width: "80%",
              }, }}
              variant="contained"
              type="button"
              onClick={(e) =>
                (sessionStorage.setItem("registrationType", JSON.stringify(1)),navigate("/care/register",{state:{registrationType:1},replace:true}))
                
              } 
              >
              Register Myself
            </CustomSubmitButton>
            <Grid sx={{margin: 0, marginTop: "20px",display:"flex",width:"50%","@media screen and (max-width: 900px)": {
                width: "80%",
              },}}>
            <Link to="https://forms.office.com/r/54GNhL0Byx"  target="_blank" style={{ textDecoration:"none",width:"100%" }} >
            <CustomSubmitButton
        
              sx={{background:"#F1B9D0", width:"100%"}}
              variant="contained"
              type="button"
              >
              Register My Org
            </CustomSubmitButton>
            </Link>
            </Grid>
          </Grid>
          </Grid>

        </Grid>
      </ThemeProvider>
    </Grid>
  );
}
