import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router";
import { CustomSubmitButton } from "../../components/FormComponents/FormComponents";
import { care_fair_data } from "../../data/data_file";

export default function ForThePeopleSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const navigate = useNavigate();

  theme.typography.subtitle1 = {
    
    fontWeight: 500,
    fontFamily: "Changa",
    // "@media (max-width: 1285px)": {
    //   lineHeight: "18px",

    //   fontSize: "18px !important",
    // },
    // "@media screen and (max-width: 790px)": {
    //   // lineHeight: "1.1em",
    //   marginTop: "12px",
    // },
  };
  theme.typography.h3 = {
    
    "@media (max-width: 900px)": {
      fontSize: "3rem",
    },
  };
  // theme.typography.h6 = {
  //   fontSize: "0.6rem",
  //   "@media (min-width:300px)": {
  //     fontSize: ".77rem",
  //   },
  //   "@media (min-width:500px)": {
  //     fontSize: "1.1rem",
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "2.2rem",
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     fontSize: "2.9rem",
  //   },
  // };

  

  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        padding: "3.14vw 7.14vw 0px",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-1px",
      }}
    >
      <ThemeProvider theme={theme}>
      <Typography
                    color="black"
                    gutterBottom
                    // variant="h3"
                    sx={{
                      fontFamily: "Changa",
                      lineHeight: {xl: "3.5rem", lg:"3rem", md:"2.7rem",sm:"2.3rem", xs:"2rem"},
                      fontWeight: 700,
                      fontSize:{xl: "3.5rem", lg:"3rem", md:"2.7rem",sm:"2.5rem", xs:"2.3rem"},
                    }}
                  >
                    {care_fair_data.ftp_title}
                  </Typography>
        {/* Group 1 */}
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            display: "flex",
            flexWrap: "initial",
            flexDirection: "row",
            // overflow: "hidden",
            height:"100%",
            "@media screen and (max-width: 1000px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Grid
            // xs={12}
            // sm={12}
            // md={4}
            // lg={4}
            sx={{
              position: "relative",
              height: "100%",
              // maxHeight:"65vh",
              // minWidth:"315px",
              justifyContent: "flex-start",
              display: "flex",
              background: "FFF",
              width: "100%",
              overflow: "visible",
              "@media screen and (max-width: 1000px)": {
                width: "100%",
                
                
              },
            }}
          >
            <Grid
              container
              sx={{
                // width: "calc(100% )",
                // height: "calc(100% - 7.14vw)",
                // maxWidth: "calc(100% - 72px)",
                // maxHeight: "calc(100% - 72px)",
                // width: "calc(100%- 72px)",
                display: "flex",
                height: "100%",
                maxHeight: "calc(100% )",
                // padding: "72px 10px",
                alignItems: "center",
                justifyContent: "center",
                // paddingRight:"20px",
                "@media screen and (max-width: 900px)": {
             
                  display:"none"
                },
              
                // position: "absolute",
                zIndex: 1,
                // right: 0,
                backgroundSize: "cover",
              }}
            >
              <ScrollAnimation
                animateIn="fadeInRight"
                initiallyVisible={false}
                style={{ 
                  // width: "70%", 
                  display: "flex" ,height:"100%",}}
              >
                <Grid
                  sx={{
                    paddingTop: "15px",
                    height: "100%",
                    width: "100%",
                    "@media screen and (max-width: 900px)": {
                      paddingBottom: "3vw",
                      height: "100%",
                      display:"none"
                    },
                  }}
                >
                  <img
                    src="images/for-the-people.png"
                    style={{
                      width: "100%", 
                      borderRadius: "40px",
                      height: "100%",maxHeight:"45vh",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                </Grid>
                
              </ScrollAnimation>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              top: "-1px",
              minHeight:"100%",
              justifyContent:"center",
              background: "#FFF",
            }}
            sx={{
              // width: "50vw",
              // "@media screen and (min-width: 769px)": {
              //   maxHeight: "50vw",
              // },
              "@media screen and (max-width: 790px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              sx={{
                minHeight:"100%",
                "@media screen and (min-width: 769px)": {
                  // height: "calc(100% - 7.14vw)",
                  // maxHeight: "calc(100% - 72px)",
                },
                "@media screen and (max-width: 1000px)": {
                  // padding: "3.14vw 0",
                  padding: 0,
                },
                // padding: "3.14vw",
                paddingLeft: "3.14vw",
              }}
              style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
                width: "100%",
                boxSizing: "inherit",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  minHeight:"100%",
                  "@media screen and (min-width: 900px)": {
                    // padding: "3.14vw",
                    paddingLeft: 0,
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  style={{ width: "100%",justifyContent: "center", minHeight:"100%",
                  display: "flex",
                  flexDirection: "column" }}
                >
                  {/* <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "1.5rem", sm: "1.6rem", md: "1.8rem",  },
                      lineHeight: { xs: "1.5rem", sm: "1.6rem", md: "1.8rem", lg: "2rem" },
                    }}
                  >
                    {care_fair_data.subtitle_1}
                  </Typography> */}
                  <Typography
                    color="black"
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      paddingBottom:"25px",
                      textTransform:"uppercase",
                      fontSize: { xs: "2.1em", sm: "2.3rem", md: "2.7rem", lg:"3.2rem" },
                      lineHeight: { xs: "1.6rem", sm: "1.8rem", md: "2.2rem",  lg:"2.7rem"},
                    }}
                  >
                    
                    <span
                    style={{
                     color:"#62B6D0"
                   }}
                    
                  >
                    {care_fair_data.people_title}
                  </span>
                  {care_fair_data.people_focus}

                  </Typography>
                  <Grid
                  sx={{
                    paddingTop: "15px",
                    height: "100%",
                    display:"none",

                    width: "100%",
                    "@media screen and (max-width: 900px)": {
                      paddingBottom: "3vw",
                      height: "100%",
                      display:"flex"
                    },
                  }}
                >
                  <img
                    src="/images/community-2.jpg"
                    style={{
                      width: "100%", 
                      height: "100%",maxHeight:"65vh",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                </Grid>
                  <Grid>
                  <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      paddingTop: "15px",
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        // lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        // lg: "1.7rem",
                      },
                    }}
                  >
                   {`The Clarintel Care Fair is `}<span style={{fontWeight:"700"}}>FREE AND OPEN</span>{`${care_fair_data.the_people}`}
                  </Typography>
                  <Grid
                  item
                  xs={12}
                  typography="formStyle"
                  sx={{
                    display: "flex",
                    paddingTop:"15px"
                  }}
                >
                  <CustomSubmitButton
                  sx={{margin:0, width:"300px",marginBottom:"35px", "@media screen and (max-width: 900px)": {
                    width:"100%"
                  },}}
                    variant="contained"
                    type="submit"
                    // disabled={!isFormComplete()}
                    onClick={(e) =>
                      (sessionStorage.setItem("registrationType", JSON.stringify(1)),navigate("/care/register",{state:{registrationType:1},replace:true}))
                      
                    }                  >
                    Register Now
                  </CustomSubmitButton>
                </Grid>
                </Grid>
                
                </ScrollAnimation>
              </Grid>
              
            </Grid>
          </Grid>
          
        </Grid>

        {/* <Grid sx={{display:"flex", width:"100%", flexDirection:"row",justifyContent:"space-between","@media screen and (max-width: 900px)": {
                          flexDirection:"column",justifyContent:"center"
                        },}}>
                    {care_fair_data.event_goals.map((item, index) => (
                        <Grid sx={{display:"flex",flexDirection:"column",padding:""}}>
                        <Typography
                        color="#62b6d0"
                        variant="h1"
                        sx={{
                          fontFamily: "Changa",
                          lineHeight: "8rem",
                          fontWeight: 700,
                          fontSize:"8rem",
                          "@media screen and (max-width: 900px)": {
                            lineHeight: "6rem",
                          fontWeight: 700,
                          fontSize:"6rem",
                          },
                          "@media screen and (max-width: 600px)": {
                            lineHeight: "4.5rem",
                          fontWeight: 700,
                          fontSize:"4.5rem",
                          },
                        }}
                      >
                        {item.stat}
                      </Typography>
                      <Typography
                        color="black"
                        gutterBottom
                        variant="none"
                        sx={{
                          fontFamily: "Changa",
                          lineHeight: "1.3rem",
                          fontWeight: 700,
                          fontSize:"1.3rem",
                          "@media screen and (max-width: 900px)": {
                            lineHeight: "1.1rem",
                          fontWeight: 700,
                          fontSize:"1.1rem",
                          },
                          "@media screen and (max-width: 600px)": {
                            lineHeight: "1rem",
                          fontWeight: 700,
                          fontSize:"1rem",
                          },
                        }}
                      >
                        {item.goal}
                      </Typography>
                        </Grid>
                      ))}
                      </Grid> */}
                  
      </ThemeProvider>
    </Grid>
  );
}
