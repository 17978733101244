import { validate } from "validate.js";

export const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128,
    },
  },
  emailAddress: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 300,
    },
  },
  phoneNumber: {
    presence: { allowEmpty: false, message: "is required" },
    format: {
      pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      message: function (
        value,
        attribute,
        validatorOptions,
        attributes,
        globalOptions,
      ) {
        return validate.format("^%{num} is not a valid phone number", {
          num: value,
        });
      },
    },
  },
  program: {
    presence: { allowEmpty: false, message: "is required" },
  },
  message: {
    presence: { allowEmpty: true },
    length: {
      maximum: 500,
    },
  },
  donationAmount: {
    presence: { allowEmpty: false, message: "is required" },
    numericality: {
      noStrings: true,
      greaterThan: 0.99,
      notLessThan: "must be greater than $0.99 USD",
    },
  },
};

