import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
// import axios from "axios";
// import { useTracking } from 'react-tracking';


// function removeLocationHash(){
//   var noHashURL = window.location.href.replace(/#.*$/, '');
//   window.history.replaceState('', document.title, noHashURL) ;
// }

// window.addEventListener("popstate", function(event){
//   removeLocationHash();
// });

// window.addEventListener("hashchange", function(event){
//   event.preventDefault();
//   removeLocationHash();
// });

// window.addEventListener("load", function(){
//   removeLocationHash();
// });


// axios.get(process.env.REACT_APP_API_REGISTRY)
// .then(function (response) {
//   trackEvent({user: response.data, event: 'page view', current_time: getDate() });
// });

// const t0 = performance.now();

// function renderCallback(e) {
//   console.log('Rendering done!', e);
//   const t1 = performance.now();
//   console.log(`Mount took ~${(t1 - t0) / 1000} seconds`);
// }

const root = ReactDOM.createRoot(document.getElementById('root'));

const history = createBrowserHistory();

root.render(
  <BrowserRouter history={history}>
    {/* <ScrollToTop /> */}
    <App />
  </BrowserRouter>
);

