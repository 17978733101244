import React, { useEffect } from "react";
import EventCollabSection from "../sections/DACSection/EventCollabSection";
import FundraiseDataSection from "../sections/DACSection/FundraiseDataSection";


export default function FundraiserPage({op_data,page_title}) {
  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0)
  }, []);
  return (
    <main> 
     
      <FundraiseDataSection op_data={op_data}/>

    <EventCollabSection/>
    </main>
  )
}
