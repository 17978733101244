import { Box, Button, Grid, Typography } from "@mui/material";
import { createTheme, responsiveFontSizes, styled, ThemeProvider } from "@mui/material/styles";
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FaFistRaised } from "react-icons/fa";
import { SectionHeader } from '../../components';


export default function HowWeWorkTopSection() {
  return (
    <ThemeProvider theme={theme}>
      <Grid container className="cl-section-row-content-wrap" alignContent="center" justifyContent="center" style={{ paddingTop: 0 }}>
        <Grid item sx={sectionStyle} direction="row" container spacing={2}>
          <SectionHeader sectionTitle={"How We Work"} />
          <Grid item container direction="column" xs={12} sm={12} md={4} lg={4}>
            <ScrollAnimation animateIn="slideInLeft">
              <Grid 
                item 
                container
                alignContent="center" 
                justifyContent="center"
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 1,
                    boxShadow: "3px 5px 5px lightgrey"
                  }}
                >
                  <FaFistRaised size={40} style={{ color: "#F1B9D0" }} />
                </Box>
              </Grid>
              <Grid 
                item 
                container 
                sx={{ my: 1 }}
                alignContent="center" 
                justifyContent="center"
              >
                <Typography 
                  variant="h4"
                  sx={{
                    fontSize: { xs: "5.0vw", sm: "5.0vw", md: "2.0vw", lg: "2.5vw" },
                    marginTop: { xs: "10px", sm: "10px" }
                  }}
                >
                  Nonprofit Collaboration
                </Typography>
              </Grid>
              <Grid 
                item 
                container 
                sx={{ my: 1 }} 
                alignContent="center" 
                justifyContent="center"
              >
                <Typography variant="subtitle3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </Grid>
            </ScrollAnimation>
          </Grid>

          <Grid item container direction="column" xs={12} sm={12} md={4} lg={4}>
            <ScrollAnimation animateIn="fadeInDown">
              <Grid 
                item 
                container 
                alignContent="center" 
                justifyContent="center"
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 1,
                    boxShadow: "3px 5px 5px lightgrey"
                  }}
                >
                  <FaFistRaised size={40} style={{ color: "#F1B9D0" }} />
                </Box>
              </Grid>
              <Grid 
                item 
                container 
                sx={{ my: 1 }} 
                alignContent="center" 
                justifyContent="center"
              >
                <Typography 
                  variant="h4"
                  sx={{
                    fontSize: { xs: "5.0vw", sm: "5.0vw", md: "2.0vw", lg: "2.5vw" },
                    marginTop: { xs: "10px", sm: "10px" }
                  }}
                >
                  Community Unity
                </Typography>
              </Grid>
              <Grid 
                item 
                container 
                sx={{ my: 1 }} 
                alignContent="center" 
                justifyContent="center"
              >
                <Typography variant="subtitle3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </Grid>
            </ScrollAnimation>
          </Grid>

          <Grid item container direction="column" xs={12} sm={12} md={4} lg={4}>
            <ScrollAnimation animateIn="slideInRight">
              <Grid 
                item 
                container 
                alignContent="center" 
                justifyContent="center"
              >
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 1,
                    boxShadow: "3px 5px 5px lightgrey"
                  }}
                >
                  <FaFistRaised size={40} style={{ color: "#F1B9D0" }} />
                </Box>
              </Grid>
              <Grid 
                item 
                container 
                sx={{ my: 1 }} 
                alignContent="center" 
                justifyContent="center"
              >
                <Typography 
                  variant="h4" 
                  sx={{
                    fontSize: { xs: "5.0vw", sm: "5.0vw", md: "2.0vw", lg: "2.5vw" },
                    marginTop: { xs: "10px", sm: "10px" }
                  }}
                >
                  Relationships
                </Typography>
              </Grid>
              <Grid 
                item 
                container 
                sx={{ my: 1 }} 
                alignContent="center" 
                justifyContent="center"
              >
                <Typography variant="subtitle3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </Typography>
              </Grid>
            </ScrollAnimation>
          </Grid>
        </Grid>
        
        <CustomButton 
          variant="contained" 
          href="/programs" 
          sx={{ 
            marginTop: { xs: "40px", sm: "40px", md: "80px", lg: "80px" }, 
            width: { xs: "100%", sm: "100%", md: "100%", lg: "50%" } 
          }}
        >
          View Our Programs
        </CustomButton>
      </Grid>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h4 = {
  fontSize: "1.8vw",
  fontWeight: "900",
  textAlign:"center",
  fontFamily: "Changa",
};

theme.typography.h3= {
  color: "#000", 
  fontFamily: "Changa",
  fontSize: "3vw",
  lineHeight: "1.1em",
  "@media screen and (max-width:960px)": {
    fontSize: "28px !important",
    lineHeight:"1.1em",
  },
};

theme.typography.subtitle1 ={
  lineHeight:"1.2em",
  marginLeft:"7.14vw",
  fontSize:"2.2vw",
  fontWeight:500,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  "@media (max-width: 1077.8443113772px)":{
    fontSize: "18px",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft:"0",
    marginTop:"16px",
  },
};

theme.typography.subtitle2 ={
  lineHeight:"1.2em",
  fontSize:"1.6vw",
  fontWeight: 500,
  fontFamily: "Changa",
  "@media (max-width: 1077.8443113772px)":{
    fontSize: "18px",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft:"0",
    marginTop:"16px",
  },
};

theme.typography.subtitle3 = {
  lineHeight:"1.2em",
  fontSize:"1.2vw",
  fontWeight: 500,
  fontFamily: "Changa",
  textAlign: "center",
  "@media (max-width: 1077.8443113772px)":{
    fontSize: "18px",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft:"0",
    marginTop:"16px",
  },
}

const sectionStyle = {
  paddingTop: "3.14vw",
  paddingLeft: "7.14vw",
  paddingRight: "7.14vw",
  "@media screen and (max-width: 790px)": {
    paddingTop: "7.28vw",
    paddingBottom: "0",
  },
};

const CustomButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "900",
  backgroundColor: "rgb(98, 182, 208)",
  fontSize: "20px",
  borderRadius: "30px",
  width: "60%",
  paddingBottom: "10px",
  paddingTop: "10px",
  "&:hover": {
    backgroundColor: "#FFFF",
    color: "rgb(98, 182, 208)",
  },
  "@media screen and (min-width: 768px)": {
    width: "100%"
  },
 
});
