import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default function ImpactAreasSection({ impact_areas, eventType }) {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  theme.typography.h3 = {
    color: "#000",
    fontWeight: 700,
    fontFamily: "Changa",
    
  };

  theme.typography.subtitle1 = {
    lineHeight: "1.2em",
    marginRight: "7.14vw",
    fontSize: "2vw",
    fontWeight: 500,
    color: "#000",
    fontFamily: "Changa",
    alignSelf: "center",
    "@media (max-width: 1077.8443113772px)": {
      fontSize: "18px !important",
    },
    "@media screen and (max-width: 900px)": {
      marginLeft: "0",
      marginTop: "16px",
    },
  };

  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        // padding: "10.28vw 0px 0px",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-1px",
      }}
    >
      <ThemeProvider theme={theme}>
        {impact_areas.map((area, index) =>
          // index % 2 === 0 ? (
              <Grid key={index}
                sx={{
                  display: "flex",
                  width: "100%",
                  minHeight: "500px",
                  alignItems: "center",
                  flexDirection: "column",
                  // "@media screen and (max-width: 900px)": {
                  //   flexDirection: "row"

                  // },
                }}
              >
                <Grid
                  sx={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    minHeight: "50vw",
                    display: "flex",
                    justifyContent: "center",
                    // minHeight: "40vw",
                    background: "FFF",
                    overflow: "hidden",
                    "@media screen and (max-width: 900px)": {
                      height: "60vw",

                      minHeight: "unset",
                      width: "80vw",
                      alignItems: "center",
                    },
                  }}
                >
                  <Grid
                    container
                    sx={{
                      width: "100%",
                      height: "100%",

                      // maxWidth: "calc(100% - 72px)",
                      // maxHeight: "calc(100% - 72px)",
                      // width: "calc(100%- 72px)",
                      // maxWidth: "calc(100%- 72px)",
                      maxHeight: "100%",
                      // padding: "4.14vw 3.14vw",
                      padding: "3.14vw 2vw 3.14vw 4.14vw",
                      // paddingRight: "20px",
                      position: "absolute",
                      zIndex: 1,
                      // right: 0,
                      backgroundSize: "cover",
                      "@media screen and (max-width: 900px)": {
                        // height:"50vw",
                        // width: "80vw",
                        alignItems: "center",
                        padding: "0",
                        paddingBottom: "3.14vw",
                      },
                    }}
                  >
                    <ScrollAnimation
                      animateIn="fadeInLeft"
                      // style={{maxHeight:"400px"}}
                      style={{ width: "100%", height: "100%" }}
                      initiallyVisible={true}
                    >
                      <img
                        src={area.image}
                        style={{
                          width: "100%",
                          height: "100%",
                          "--aspect": "100%",
                          // position: "absolute",
                          objectFit: "cover",
                          borderRadius: 30,
                        }}
                        alt={"focus"}
                        loading="lazy"
                      />
                    </ScrollAnimation>
                  </Grid>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "space-between",
                    top: "-1px",
                    background: "#FFF",
                  }}
                  sx={{
                    width: "50%",
                    alignSelf:index % 2 === 0 ? "flex-start" : "flex-end",
                    // width: "100%",
                    
                    "@media screen and (max-width: 900px)": {
                      width: "100%",
                      alignSelf:"center",
                    },
                  }}
                >
                  <Grid
                    sx={{
                      "@media screen and (min-width: 900px)": {
                        height: "calc(100% - 7.14vw)",
                        maxHeight: "calc(100% - 72px)",
                      },
                      "@media screen and (max-width: 900px)": {
                        width: "100%",
                        padding: "3.14vw",
                      },
                      padding: "3.14vw 0 3.14vw 3.14vw ",
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      boxSizing: "inherit",
                    }}
                  >
                    <ScrollAnimation
                      animateIn="fadeIn"
                      initiallyVisible={true}
                      style={{ width: "100%" }}
                    >
                      <Grid
                        container
                        direction="column"
                        sx={{
                          "@media screen and (min-width: 900px)": {
                            // padding: "0vw 5vw 0vw 0",
                          },
                          "@media screen and (max-width: 900px)": {
                            // padding: "0 4.14vw",
                            // alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          },
                        }}
                      >
                        {/* <Grid
                    container
                    style={{
                      fontSize: 10,
                      // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
                      background: "#FFF",
                      color: "black",
                      borderRadius: 180,

                      width: "75px",
                      height: "75px",
                      boxShadow: eventType===0 ? "0px 2px 9px rgba(98,182,208)" : "0px 2px 9px rgba(241 ,185, 208)",

                      marginBottom: "15px",
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      textAlign: "center",
                      justifyContent: "center"
                    }}
                  >
                    <svg width="40" height="40" x="0" y="0" viewBox="0 0 32 32" fill={eventType===0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"} ><g><path d="M6 11V7a2 2 0 1 1 4 0v4a2 2 0 1 1-4 0zM20 9h-4V4a2 2 0 1 1 4 0zM25 9h-4V5a2 2 0 1 1 4 0zM26 13v5.343a3.995 3.995 0 0 1-1.172 2.828l-2.243 2.243A2.004 2.004 0 0 0 22 24.828V30H9v-5.333c0-.433-.14-.854-.4-1.2l-1.8-2.4a4.002 4.002 0 0 1-.8-2.4v-4.223A3.959 3.959 0 0 0 8 15c.951 0 1.813-.347 2.5-.903A3.958 3.958 0 0 0 13 15c.499 0 .97-.104 1.408-.276a4 4 0 0 0 2.982 2.23C15.802 18.6 15 20.29 15 22a1 1 0 1 0 2 0c0-1.659 1.211-3.408 3.6-5.2A.999.999 0 0 0 20 15h-2c-1.103 0-2-.897-2-2v-2h8a2 2 0 0 1 2 2z" ></path><path d="M11 11V5a2 2 0 1 1 4 0v4a1 1 0 0 0-1 1v2.723A1.987 1.987 0 0 1 13 13a2 2 0 0 1-2-2z" fill={eventType===0 ? "rgba(98,182,208)" : "rgba(241 ,185, 208)"} ></path></g></svg>
                  </Grid> */}
                        <Typography
                          color="black"
                          variant="h3"
                          sx={{fontSize: {
                            xs: "1.3rem",
                            sm: "1.3rem",
                            md: "1.4rem",
                            lg: "1.7rem",
                          },
                          lineHeight: {
                            xs: "1.3rem",
                            sm: "1.3rem",
                            md: "1.4rem",
                            lg: "1.7rem",
                          },}}
                        >
                          {area.title}
                        </Typography>
                        <Grid>
                          <Typography
                            color="black"
                            gutterBottom
                            variant="body1"
                            sx={{
                              fontFamily: "Changa",
                              padding: "0",
                              marginTop: "10px",
                              fontSize: {
                                xs: "1.1rem",
                                sm: "1.1rem",
                                md: "1.2rem",
                                lg: "1.5rem",
                              },
                              lineHeight: {
                                xs: "1.3rem",
                                sm: "1.3rem",
                                md: "1.4rem",
                                lg: "1.7rem",
                              },
                            }}
                          >
                            {area.description}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ScrollAnimation>
                  </Grid>
                </Grid>
              </Grid>
            
        )}
      </ThemeProvider>
    </Grid>
  );
}
