import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import React from 'react';
import { GetLastWord, GetSentence } from '../../helpers/sentenceHelpers';
import './pageHeaderStyles.css';

export default function PageHeader({ 
  title, 
  description, 
  background, 
  backgroundImage, 
  subtext,
  isContact,
  isJoinUs
}) {
  const lastWord = GetLastWord(title);
  const partialSentence = GetSentence(title, lastWord);

  return (
    <ThemeProvider theme={theme}>
      <div className="fl-row fl-row-full-width fl-row-bg-color fl-node-5nhrxv7mldz4 fl-row-custom-height fl-row-align-center fl-row-has-layers">
        <div
          className="fl-row-content-wrap hero" 
          style={{
            paddingTop:"150px",
            background: background ? background : null,
            backgroundImage: backgroundImage ? `url(${backgroundImage})` : null
          }}
        >
          <div className="fl-builder-layer fl-builder-shape-layer fl-builder-bottom-edge-layer fl-builder-shape-hero-curve">
            <svg className="fl-builder-layer-align-bottom-center" viewBox="0 0 1440 385" style={{ width:"100%",height:100}} preserveAspectRatio="none" > {/*style={{ height: "385px" }}*/}
              <defs></defs>
              <g className="fl-shape-content">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 321.305V385H1440V0.68457C888.562 441.592 130.16 334.811 103.368 330.875C72.2024 329.323 37.3462 326.39 0 321.305Z" fill="white"></path>
              </g>
            </svg>
          </div>
          <div className="fl-row-content fl-row-fixed-width fl-node-content">     
            <div className="fl-col-group fl-node-q80scwek2mxi">
              <div className="fl-col fl-node-5pzxeh48s6fl">
                <div className="fl-col-content fl-node-content">
                  <div className="fl-module fl-module-4h-heading-slant fl-node-m0ohvpa9fc2t" data-node="m0ohvpa9fc2t" >
                    <div className="fl-module-content fl-node-content">
                      <h1 className="fourh-heading" >
                        <span className="fourh-heading-text" style={{paddingLeft:"7.14vw",paddingTop:"10px", display:'flex'}}>
                          {` ${partialSentence} `}
                          <span 
                            className="slant"  
                            style={{
                              background:background === "#ffbac8" || background==="#F1B9D0" ? "rgb(98, 182, 208)" : "#F1B9D0"
                            }}
                          >
                            {lastWord}
                          </span>
                        </span>
                      </h1>
                    </div>
                  </div>
                  {description ? 
                    <div className="fl-module fl-module-4h-subheading fl-node-42al3gzjmn16">
                      <div className="fl-module-content fl-node-content">
                        <span className="fourh-subheading-text subheading" style={{fontSize:isJoinUs? "2rem" : null,lineHeight:isJoinUs? "2rem" : null}}>
                          {description}
                        </span>
                      </div>
                    </div>
                    :
                    <></>
                  }
                  {subtext ? 
                    <div className="fl-module fl-module-4h-subheading fl-node-42al3gzjmn16">
                      <div className="fl-module-content fl-node-content">
                        <a href="/sponsorship" className="fourh-subheading-text subheading2">
                          {subtext}
                        </a>
                      </div>
                    </div>
                    :
                    <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}


let theme = createTheme();

theme = responsiveFontSizes(theme);