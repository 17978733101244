import { Box, Button, Grid, Typography } from "@mui/material";
import { createTheme, responsiveFontSizes, styled } from "@mui/material/styles";
import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { SectionHeader } from '../../components';
import { data } from '../../data/data_file';

export default function OurStorySection() {
  return (
    <Grid container className="cl-section-row-content-wrap">
      <Grid item sx={sectionStyle}>
        <SectionHeader sectionTitle={"Who We Are"} />
        <Grid item xs={12} md={6} lg={6} sx={{paddingRight:{sm:"0", md:"3.14vw" },marginBottom: {sm:"10px", md:"70px",lg:"200px" }}}>
          <ScrollAnimation animateIn="fadeInUp">
            <Typography sx={{fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},
                            fontWeight:500,
                            color: "#000", 
                            fontFamily: "Changa", }}>
            {data.about_us.story_text1}
            </Typography>
          </ScrollAnimation>
        </Grid>
      </Grid>

      <Box component="img" src="/images/circle1.png" alt="circle-1" className="top-right-badge lazyloaded" />


      <Grid item container justifyContent="right" sx={{marginTop:{ lg:"50px" }}}>
      <Box component="img" src="/images/circle2.png" alt="circle-2" className="bottom-left-badge lazyloaded" />

        <Grid item xs={12} md={6} lg={6} sx={sectionStyle}>
          <ScrollAnimation animateIn="fadeInUp">
            <Typography sx={{fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1.2rem", lg: "1.5rem"},
                            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},
                            fontWeight:500,
                            color: "#000", 
                            fontFamily: "Changa", }}>
            {data.about_us.story_text2}

              <br />
              <br />

              {data.about_us.call_to_action}
            </Typography>

            <br />
            <br />

            <CustomButton variant="contained" href="/about/leadership">
              Dedicated Leadership
            </CustomButton>
          </ScrollAnimation>
        </Grid>
      </Grid>
    </Grid>
  );
}


let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.subtitle2 ={
  lineHeight:"1.2rem",
  fontSize:"1.2rem",
  fontWeight: 500,
  fontFamily: "Changa",
  "@media (max-width: 1077.8443113772px)":{
    fontSize: "20px",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft:"0",
    marginTop:"16px",
  },
};

const sectionStyle = {
  paddingTop: "3.14vw",
  paddingLeft: "7.14vw",
  paddingRight: "7.14vw",
  "@media screen and (max-width: 1200px)": {
    paddingTop: "0",
    marginTop:"100px",
    paddingBottom: "0",
  },
  "@media screen and (max-width: 900px)": {
    paddingTop: "0",
    marginTop:"0",
    paddingBottom: "0",
  },
};



const CustomButton = styled(Button)({
  fontFamily: "Changa",
  fontWeight: "900",
  backgroundColor: "rgb(98, 182, 208)",
  fontSize: "18px",
  borderRadius: "30px",
  width: "100%",
  paddingBottom: "10px",
  paddingTop: "10px",
  "&:hover": {
    backgroundColor: "#FFFF",
    color: "rgb(98, 182, 208)",
  },
  "@media screen and (max-width: 768px)": {
    width: "100%"
  },
 
 
});