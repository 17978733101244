import {
  Divider,
  Grid,
  Typography,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";
import { CustomRedirectionButton } from "../../components/FormComponents/FormComponents";
import { ConvertToReadableDate } from "../../helpers/dateHelpers";
import { DocumentDefinition } from "../../helpers/pdfHelper";
import ReceiptDetails from "./ReceiptDetails";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function ReceiptPDF() {
  const pdfRef = useRef();
  const location = useLocation();
  const {
    id,
    first_name,
    last_name,
    donation_amount,
    program_title,
    payment_method_type,
    last_4,
    message,
    created_at,
    card_type,
  } = location.state;

  const  handleThankYouLetter =  (e) => {

     pdfMake
      .createPdf(
        DocumentDefinition(
          id,
          first_name,
          last_name,
          donation_amount,
          created_at,
          program_title,
          payment_method_type,
          last_4,
          message,
          card_type
        ), 
      )
      .download("Clarintel Thank You Letter.pdf");
  };

  return (
    <>
      <Grid container ref={pdfRef}>
        <CustomGrid item sx={{width:"100%" }}>
          <Grid item sx={{ mb: 3 }}>
            <Divider
              sx={{ borderColor: "#62B6D0", borderBottomWidth: "thick" }}
            />
          </Grid>

          <Grid item sx={{ mb: 3 }}>
            <Typography variant="thankYouParagraph">
              Dear {first_name} {last_name},
              <br />
              <br />
              Thank you for your heartfelt donation of $
              {parseFloat(donation_amount).toFixed(2)} to Clarintel, Inc. on{" "}
              {ConvertToReadableDate(created_at)}
              . We truly appreciate your generous support for the individuals
              and organizations that we help to drive a positive impact for
              communities and individuals in need.
              <br />
              <br />
              Though the demand for opportunities generating positive social
              impact has reached an all-time high, nonprofits struggle with
              acquiring the attention and resources required to effectively
              respond to and meet the diverse needs of our communities.
              <br />
              <br />
              “At the beginning of the 21st century, two thirds of Americans
              gave. Today, that is down to under 50% for the first time,” said
              Una Osili, the associate dean for research and international
              programs at the Lilly Family School of Philanthropy at Indiana
              University and the Giving USA report’s lead researcher.
              <br />
              <br />
              Thanks to you, Clarintel will continue to work directly with
              community members, like the students at the University of Maryland
              College Park, and the brothers of Phi Beta Sigma Fraternity and
              Alpha Kappa Psi Fraternity, to create and deliver innovative
              solutions that bridge the gaps between community leaders and
              members to drive positive impact all over the world. Our mission
              is to promote and redefine social action through clarity and
              intelligence.
              <br />
              <br />
              Thank you for your ongoing support. We can’t change the world
              without you!
              <br />
              <br />
              Thank You!
            </Typography>
            <Grid
              container
              sx={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                display: "flex",
                flexWrap: "initial",
                flexDirection: "row",
                overflow: "hidden",
                padding:"15px 0",
                "@media screen and (max-width: 900px)": {
                  width: "100%",
                  flexDirection: "column",
                },
              }}
            >
             
                <Grid>
                  <Grid item>
                    <img
                      className="signature"
                      src="/images/signature.png"
                      loading="lazy"
                      alt="signature"
                      style={{ width: "20%" }}
                    />
                  </Grid>
                  <Grid item sx={{ mb: 2 }}>
                    <Typography variant="thankYouParagraph">
                      Brandon Wallerson
                    </Typography>
                    <Typography variant="thankYouParagraph">
                      President & CEO of Clarintel
                    </Typography>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid item>
                    <img
                      className="signature"
                      src="/images/kwesi-sig.png"
                      loading="lazy"
                      alt="signature"
                      style={{ width: "20%" }}
                    />
                  </Grid>
                  <Grid item sx={{ mb: 2 }}>
                    <Typography variant="thankYouParagraph">
                      Kwesi Larbi
                    </Typography>
                    <Typography variant="thankYouParagraph">
                      Vice President & CTO of Clarintel
                    </Typography>
                  </Grid>
                </Grid>
                </Grid>

              <Grid
                  container
                  justifyContent="flex-start"
                  alignContent="center"
                  item
                  sx={{ mb: 3 }}
                >
                  <img
                    src="/images/clarintel_full.png"
                    alt="logo"
                    loading="lazy"
                    style={{ objectFit: "cover", width: "200px" }}
                  />
                </Grid>
          </Grid>
          <Grid item sx={{ mb: 3 }}>
            <Divider
              sx={{ borderColor: "#62B6D0", borderBottomWidth: "thick" }}
            />
          </Grid>
          <Typography
            variant="thankYouParagraph"
            sx={{ mt: 2, fontWeight: 700, mb: 2, fontSize: "1.2rem" }}
          >
            Please find a summary of your donation below:
          </Typography>
          <ReceiptDetails props={location.state} />
          <Grid item sx={{ mb: 3 }}>
            <Divider
              sx={{ borderColor: "#62B6D0", borderBottomWidth: "thick" }}
            />
          </Grid>
        </CustomGrid>
      </Grid>
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item sx={{ mb: 3 }}>
          <CustomRedirectionButton
            variant="contained"
            onClick={handleThankYouLetter}
          >
            Print Donation Receipt
          </CustomRedirectionButton>
        </Grid>
        <Grid item>
          <CustomRedirectionButton href="/" variant="contained">
            Back to Home Page
          </CustomRedirectionButton>
        </Grid>
      </Grid>
    </>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

const CustomGrid = styled("Grid")(({ theme }) => ({
  // paddingLeft: 14,
  // paddingRight: 14,
  // [theme.breakpoints.down("md")]: {
  //   paddingLeft: 0,
  //   paddingRight: 0,
  // },
  // [theme.breakpoints.up("md")]: {
  //   paddingLeft: 10,
  //   paddingRight: 10,
  // },
}));

theme.typography.topText = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.thankYouTitle = {
  fontFamily: "Changa",
  fontSize: "2.7rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "2.7rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
  },
};

theme.typography.thankYouText = {
  fontFamily: "Changa",
  fontSize: "2.0rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "2.7rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.8rem",
  },
};

theme.typography.thankYouParagraph = {
  fontFamily: "Changa",
  fontSize: "1.1rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.thankYouDetails = {
  fontFamily: "Changa",
  fontSize: "1.1rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.thankYouReceipt = {
  fontFamily: "Changa",
  fontSize: "1.1rem",
  textAlign: "left",
  flexGrow: 1,
  fontWeight: "bold",
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.info = {
  fontFamily: "Changa",
  fontSize: "1.1rem",
  textAlign: "left",
  fontWeight: "bold",
  fontStyle: "italic",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.contact = {
  fontFamily: "Changa",
  fontSize: "1.1rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};
