import {
  Box,
  CssBaseline,
  FormControl,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import emailjs from "emailjs-com";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import validate from "validate.js";
import api from "../../api";
import { PageHeader } from "../../components";
import {
  CustomCheckbox,
  CustomFormControlLabel,
  CustomMenuItem,
  CustomMessageBox,
  CustomSelect,
  CustomSubmitButton,
  CustomTextField,
} from "../../components/FormComponents/FormComponents";
import { PhoneNumberPatternFormatCustom } from "../../components/InputComponents/InputComponents";
import { topics } from "../../data/ContactData/data";
import { schema } from "./contactSchema";

const SERVICE_ID = process.env.REACT_APP_CONTACT_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

const formValues = {
  message: "",
};

export default function Contact({ page_title }) {
  const contactForm = useRef();
  const navigate = useNavigate();
  const [formState, setFormState] = useState({
    isValid: false,
    values: formValues,
    touched: {},
    errors: {},
  });

  const characterLimit = 1000;

  useEffect(() => {
    const errors = validate(formState.values, schema);
    document.title = page_title;
    window.scrollTo(0, 0);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, contactForm.current, PUBLIC_KEY)
      .then((res) => {
        // console.log("SUCCESS!", res.status, res.text);
        api.SendEmailMessage(formState.values);
        navigate("/contact/submitted");
      })
      // .catch((err) => console.log(`FAILED...${err}`));
  };

  const handleChange = (e) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true,
      },
    }));
  };

  const hasError = (field) =>
    formState.errors[field] ? (formState.touched[field] ? true : false) : false;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        <PageHeader
          title="Connect With Us"
          background={"rgb(98, 182, 208)"}
          isContact={1}
        />
        <Box
          sx={{
            pb: 8,
            backgroundColor: "white",
          }}
        >
          <Grid
            style={{
              paddingLeft: "7.14vw",
              paddingRight: "7.14vw",
              paddingTop: "3.14vw",
            }}
          >
            <Grid container direction="column">
              <Grid item>
                <Typography variant="questionTitle">
                  Questions and Comments
                </Typography>
              </Grid>
              <Grid item sx={{ pt: "15px", lineHeight: "1.1rem" }}>
                <Typography variant="questionText">
                  Thank you for your interest in Clarintel, the future of
                  philanthropy and positive impact.
                  <br />
                  <br />
                  If you have any questions and comments, or if you would like
                  to share your ideas, interest, and support, feel free to
                  contact us using the form below. We'd love to connect with you
                  and hear your thoughts.
                  <br />
                  <br />
                  To better serve you and provide you with the information you
                  need, please provide as much detail as you can regarding your
                  inquiry, and we will get back to you as soon as we can.
                  <br />
                  <br />
                  We can't wait to meet you!
                  <br />
                  <br />
                  Thank you.
                </Typography>
              </Grid>
            </Grid>
            <form
              ref={contactForm}
              headers="application/json"
              name="contact-form"
              onSubmit={sendEmail}
            >
              <Grid container direction="row">
                {/* Header */}
                <Grid container justifyContent="left" xs={12}>
                  <Typography variant="contactTitle">
                    Connect With a Clarintel Representative
                  </Typography>
                  <Typography variant="contactSubTitle" sx={{ pt: "15px" }}>
                    To ensure that you receive a quick and accurate response to
                    your inquiry, please select a topic from the dropdown list
                    below. We will respond to you shortly.
                  </Typography>
                </Grid>

                {/* Name and Email Address */}
                <Grid
                  container
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleLeft"
                  >
                    <Typography variant="fieldTitle">First Name*</Typography>
                    <CustomTextField
                      name="firstName"
                      placeholder="First Name"
                      variant="outlined"
                      size="medium"
                      id="firstName"
                      fullWidth
                      type="text"
                      onChange={handleChange}
                      helperText={
                        hasError("firstName")
                          ? formState.errors.firstName[0]
                          : null
                      }
                      error={hasError("firstName")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleRight"
                  >
                    <Typography variant="fieldTitle">Last Name*</Typography>
                    <CustomTextField
                      name="lastName"
                      placeholder="Last Name"
                      variant="outlined"
                      size="medium"
                      id="lastName"
                      fullWidth
                      type="text"
                      onChange={handleChange}
                      helperText={
                        hasError("lastName")
                          ? formState.errors.lastName[0]
                          : null
                      }
                      error={hasError("lastName")}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ flexDirection: "row", alignItems: "center" }}
                >
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleLeft"
                  >
                    <Typography variant="fieldTitle">Email Address*</Typography>
                    <CustomTextField
                      name="emailAddress"
                      placeholder="Email Address"
                      variant="outlined"
                      size="medium"
                      id="email"
                      fullWidth
                      type="email"
                      onChange={handleChange}
                      helperText={
                        hasError("emailAddress")
                          ? formState.errors.emailAddress[0]
                          : null
                      }
                      error={hasError("emailAddress")}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    typography="formStyleRight"
                  >
                    <Typography variant="fieldTitle">Phone Number*</Typography>
                    <CustomTextField
                      name="phoneNumber"
                      placeholder="(123) 345-6789"
                      variant="outlined"
                      size="medium"
                      id="phone"
                      fullWidth
                      InputProps={{
                        inputComponent: PhoneNumberPatternFormatCustom,
                      }}
                      type="tel"
                      onChange={handleChange}
                      helperText={
                        hasError("phoneNumber")
                          ? formState.errors.phoneNumber[0]
                          : null
                      }
                      error={hasError("phoneNumber")}
                    />
                  </Grid>
                </Grid>

                {/* Topic */}
                <Grid item xs={12} sm={12} typography="formStyle">
                  <Typography variant="fieldTitle">
                    Select an Inquiry Topic*
                  </Typography>
                  <FormControl fullWidth>
                    {formState.values.topic === undefined ? (
                      <InputLabel
                        sx={{
                          top: "calc(50% - 1.3em)",
                          fontFamily: "Changa",
                          fontSize: "15px",
                          paddingLeft: "5px",
                          color: "#A1A1A1",
                        }}
                        disableAnimation
                        shrink={false}
                        focused={false}
                        id="item_type_label"
                      >
                        Select your Inquiry Topic
                      </InputLabel>
                    ) : null}
                    <CustomSelect
                      id="topic"
                      fullWidth
                      value={formState.values.topic || ""}
                      onChange={handleChange}
                      name="topic"
                      style={{
                        borderRadius: "40px",
                        height: "70px",
                        fontFamily: "Changa",
                        fontSize: "18px",
                        marginTop: "10px",
                      }}
                      error={hasError("topic")}
                    >
                      {topics.map((topic, item) => (
                        <CustomMenuItem key={item} value={topic}>
                          {topic}
                        </CustomMenuItem>
                      ))}
                    </CustomSelect>
                  </FormControl>
                  <FormHelperText>
                    {hasError("topic") ? formState.errors.topic[0] : null}
                  </FormHelperText>
                </Grid>

                {/* Message */}
                <Grid item xs={12} typography="formStyle" sx={{ pb: "1.14vw" }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="fieldTitle">
                      Messsage (1000 Character Limit)*
                    </Typography>
                    <Typography variant="fieldTitle">
                      {formState.values.message.length}/{characterLimit}
                    </Typography>
                  </Grid>
                  <CustomMessageBox
                    name="message"
                    placeholder="Message"
                    variant="outlined"
                    multiline
                    rows={8}
                    id="message"
                    fullWidth
                    type="text"
                    onChange={handleChange}
                    helperText={
                      hasError("message") ? formState.errors.message[0] : null
                    }
                    error={hasError("message")}
                  />
                </Grid>

                {/* Opt-ins */}
                <Grid item xs={12} typography="formStyle">
                  <Typography variant="fieldTitle">Opt-In</Typography>
                  <FormGroup>
                    <CustomFormControlLabel
                      control={
                        <CustomCheckbox name="opt_in" onChange={handleChange} />
                      }
                      label="Send me the latest news from Clarintel"
                      helperText={
                        hasError("opt_in") ? formState.errors.opt_in[0] : null
                      }
                      error={hasError("opt_in")}
                    />
                  </FormGroup>
                </Grid>

                {/* Submit Button */}
                <Grid
                  item
                  xs={12}
                  typography="formStyle"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CustomSubmitButton
                    variant="contained"
                    type="submit"
                    disabled={!formState.isValid}
                  >
                    Send
                  </CustomSubmitButton>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Box>
      </main>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.contactTitle = {
  fontFamily: "Changa",
  fontSize: "2rem",
  lineHeight: "2rem",
  fontWeight: "900",
  paddingTop: "40px",

  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
    paddingTop: "25px",
  },
};

theme.typography.contactSubTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "1.3rem",
  lineHeight: "1.3rem",

  paddingBottom: "30px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.fieldTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "18px",
  fontWeight: "900",
  lineHeight: "18px",
};

theme.typography.formStyleLeft = {
  // paddingLeft:"1.14vw",
  paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.formStyleRight = {
  paddingLeft: "1.14vw",
  // paddingRight:"1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.formStyle = {
  // paddingLeft:"1.14vw",
  // paddingRight:"1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.smallText = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "20px",
  lineHeight: "20px",
  "@media screen and (max-width: 900px)": {
    fontSize: "18px",
    lineHeight: "18px",
  },
};

theme.typography.questionTitle = {
  fontFamily: "Changa",
  fontSize: "2rem",
  lineHeight: "2rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.questionText = {
  fontFamily: "Changa",
  fontSize: "1.3rem",
  lineHeight: "1rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};
