import { Box, Grid, Stack, Typography } from "@mui/material";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import React from 'react';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
} from "react-icons/fa6";


export default function HelpClarintelSection() {
  return (
    <Grid container sx={{justifyContent:"center", padding: "0",
    "@media screen and (max-width: 900px)": {
      padding: "30px 0px 0px",
    },}}>
      <Grid 
        container
        sx={{
          fontSize: 10,
          background: "#FFF",
          color: "black",
          // paddingLeft: "7.14vw",
          // paddingRight: "7.14vw",
          width:{ xs: "80%",  md: "65%",lg: "50%" },
          height:"25vw",
          minHeight:"300px",
          justifyContent:"center",
          // paddingBottom: "7.14vw",
          display: "flex",
          alignItems:"center",
          flexWrap: "wrap",
          position:"relative",
        }}
      >
        <ThemeProvider theme={theme}>         
            <Grid sx={{
              height:"30vw",
              minHeight:"350px",
              top:0,
              left:0,
              position:"absolute",
              background: "#62B6D0",
              width: "3px",
            }}/>
            
            <Grid sx={{
              height:"3px",
              background: "#F1B9D0",
              width: {xs:"100vw",md:"95vw",},
              position:"absolute",
              right:{ xs: -20, sm: -30, md: -30,lg: -75, xl: -90 },
              top:15,
              
            }}/>
            
            <Grid sx={{
              height:"30vw",
              minHeight:"350px",
              bottom:0,
              position:"absolute",
              right:0,
              background: "#62B6D0",
              width: "3px",
              
            }}/>
            <Grid sx={{
              height:"3px",
              background: "#F1B9D0",
              width: "95vw",
              position:"absolute",
              left:{ xs: -20, sm: -30, md: -30,lg: -75, xl: -90 },
              bottom:15,
              
            }}/>
             <Grid container direction="column" alignItems="center" justifyContent="flex-start" >
                      
                      <Typography 
                        variant="title" 
                        sx={{ 
                          fontSize: { xs: "1.3rem", sm:"1.7rem", md: "2rem", lg: "2.3rem",  },
                          pt: { xs: "15px", md: "15px", lg: "30px"  },
                          textAlign:"center",
                          pb: "10px",
                          lineHeight: { xs: "1.3rem", sm:"1.7rem", md: "2rem", lg: "2.3rem",  },
                        }}
                      >
                        {"Help Clarintel Provide"}
                      </Typography>
                      <Typography 
                        variant="subtitle" 
                        sx={{ 
                          fontSize: { xs: "1rem", sm:"1.3rem", md: "1.5rem", lg: "1.7rem",  },
                          lineHeight: { xs: "1rem", sm:"1.3rem", md: "1.5rem", lg: "1.7rem",  },

                        }}
                      >
                        {"#Technology4PostiveImpact"}
                      </Typography>
                      <Box 
                      // style={{marginTop: "30px"}}
                      >
                        {/* <Typography style={{ color: '#62B6D0', textAlign:"center", fontFamily:"Changa",fontWeight: '900', fontSize: '1.6rem', paddingBottom: '30px' }}>Follow Us On Social Media</Typography> */}
                        <Stack direction="row"  sx={{justifyContent:"center",}}>
                          <div style={{padding:"10px"}}>
                            <a href="https://www.linkedin.com/company/clarintel/about/"
                            target="_blank" rel="noopener noreferrer"><FaLinkedin size={30} color="#62B6D0" /></a>
                          </div>
                          <div style={{padding:"10px"}}>
                            <a  href="https://twitter.com/ClarintelInc"
                            target="_blank" rel="noopener noreferrer"><FaXTwitter size={30} color="#62B6D0" /></a>
                          </div>
                          <div style={{padding:"10px"}}>
                            <a href="https://www.facebook.com/profile.php?id=61552601214541" target="_blank" rel="noopener noreferrer"><FaFacebook size={30} color="#62B6D0" /></a>
                          </div>
                          <div style={{padding:"10px"}}>
                            <a href="https://www.instagram.com/clarintel/"
                            target="_blank" rel="noopener noreferrer"><FaInstagram size={30} color="#62B6D0" /></a>
                          </div>
                        </Stack>
                      </Box>
                  {/* <CssButton variant="contained">
                    Donate
                  </CssButton> */}
                  {/* <Tooltip title="Donate to Clarintel">  */}
                    {/* <Button  href="/donate"
                      sx={{
                        backgroundColor: "#F1B9D0",
                        border: "3px solid #F1B9D0",
                        fontWeight: 700,
                        fontSize:  "1.2rem",
                        borderRadius: "50px",
                        padding:  "5px 15px",
                        color: "#FFF",
                        // padding: { xs: "10px 20px", md: "10px 40px", lg: "15px 50px" }
                     '&:hover': {
                      backgroundColor: "rgba(245,185,208,.3)",
                      border: "3px solid rgba(245,185,208,0)",
                        color: "#F1B9D0"
                      }
                    }}
                  >
                    <Typography 
                      variant="navButton"
                    >
                      Donate
                    </Typography>
                    </Button> */}
                    </Grid>

                  {/* </Tooltip> */}
        </ThemeProvider>
      </Grid>
    </Grid>
  );
}


let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920
    },
  },
});

theme.typography.title = {
  color: "#000", 
  fontFamily: "Changa",
  lineHeight:"1.1em",
  fontWeight: 700
}

theme.typography.subtitle = {
  fontWeight: 200,
  color: "#000", 
  fontFamily: "Changa", 
}

theme.typography.btn1 ={
  fontWeight:700,
  fontFamily: "Changa", 
  fontSize:"1.2rem",
  alignSelf:"center",
}

theme = responsiveFontSizes(theme);

theme.typography.h3= {
  color: "#000", 
  fontFamily: "Changa",
  fontSize: "2.5vw",
  lineHeight:"1.1em",
  "@media screen and (max-width:960px)": {
    fontSize: "28px !important",
    lineHeight:"1.1em",

  },
};

theme.typography.navButton = {
  fontSize: "1.2rem",
  fontWeight: "700",
  fontFamily: "Changa",
}

theme.typography.subtitle1 ={
  lineHeight:"1.2em",
  // marginLeft:"7.14vw",
  fontWeight:200,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center"
}

theme.typography.h4 ={
  lineHeight:"1.2em",
  // fontSize: { xs:"1.9vw"},
  fontWeight:700,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  textTransform: "uppercase",
  // "@media (max-width: 1077.8443113772px)":{
  //   fontSize: "18px !important",
  // },
  // "@media screen and (max-width: 768px)": {
  //   marginLeft:"0",
  //   marginTop:"16px",
  // },
}

theme.typography.subtitle2 ={
  lineHeight:"1.2em",
  fontWeight:200,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  paddingBottom: "30px",
}

theme.typography.btn1 ={
  fontWeight:200,
  color: "#ffff", 
  fontFamily: "Changa", 
  alignSelf:"center",
  textTransform: "capitalize !important"
}