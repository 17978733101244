export const footerBannerData = {
  promotionBannerTitle: "Help Clarintel Provide #Technology4PositiveImpact",
  dontationButtonText: "Donate",
  donationBannerLink: "/"
};

export const footerData = [
  {
    title: "About",
    subTopics: [
      {
        subTitle: 'About Clarintel',
        link: '/'
      },
      {
        subTitle: 'Nonprofit Colaboration',
        link: '/'
      },
      {
        subTitle: 'Leadership',
        link: '/'
      },
      {
        subTitle: 'Contact Us',
        link: '/'
      }
    ]
  },
  {
    title: "Programs",
    subTopics: [
      {
        subTitle: 'Programs & Events',
        link: '/'
      },
      {
        subTitle: 'Program Area 1',
        link: '/'
      },
      {
        subTitle: 'Program Area 2',
        link: '/'
      },
      {
        subTitle: 'Program Area 3',
        link: '/'
      }
    ]
  },
  {
    title: "Support Clarintel",
    subTopics: [
      {
        subTitle: 'Ways to Support',
        link: '/'
      },
      {
        subTitle: 'Become a Partner',
        link: '/'
      }
    ]
  },
  {
    title: "Get Involved",
    subTopics: [
      {
        subTitle: 'Opportunities',
        link: '/'
      }
    ]
  },
  {
    title: "Join Our Community",
    blurb1: "Keep up-to-date with the latest news and events at Clarintel!",
    fillerText: "Enter your email address",
    buttonText: "Subscribe"
  },
  {
    title: "Follow us on social media",
    socials: [
      {
        social1: "youtube",
        link: "/"
      },
      {
        social2: "linkedin",
        link: "/",
      },
      {
        social3: "twitter",
        link: "/"
      },
      {
        social4: "facebook",
        link: "/"
      },
      {
        social5: "instagram",
        link: "/"
      }
    ]
  }
];