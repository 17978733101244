import {
  Grid,
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Terms from "../CareFairPages/Terms";

const formValues = {
  message: "",
};

export default function CommunityGuidelines({ page_title }) {
  const [useMyInfo, setuseMyInfo] = useState(false);

  const donationForm = useRef();
  const navigate = useNavigate();
  const [characterLimit] = useState(1000);
  const [connectedSocialIssues, setSocialIssues] = useState([]);
  const [event_reasons, setevent_reasons] = useState([]);
  const [repCount, setRepCount] = useState([0]);

  // const handleIncrease = (person) => {
  //   let temp = [...repCount];
  //   temp.push(person)
  //   setRepCount([person,...repCount])
  //   console.log(repCount,temp)

  // }

  // const handleDecrease = (person) => {
  //   // let tempArr =
  //   // // const index = tempArr.indexOf(person);
  //   // console.log(tempArr)

  //   // if (index > -1) { // only splice array when item is found
  //   //   console.log(repCount.splice(person, 1))
  //   console.log(repCount)
  //     setRepCount(repCount.splice(person, 1));
  //     console.log(repCount)// 2nd parameter means remove
  //   // }

  //   // setRepCount(tempArr)
  // }
 


  useEffect(() => {
    document.title = page_title;

    // setRepCount([0]);

  }, []);

  useLayoutEffect(() => {}, [repCount]);

  

 

  // const label = { inputProps: { 'aria-label': 'Color switch demo' } };

  
  return (
    <Grid
      sx={{ pb: 8, backgroundColor: "white", padding: "150px 3.14vw 50px" }}
    >
      <ThemeProvider theme={theme}>
        <Grid sx={{ backgroundColor: "white" }}>
          <Grid
            sx={{
              // paddingLeft: "7.14vw",
              // paddingRight: "7.14vw",
              paddingTop: "50px",
              "@media screen and (max-width: 900px)": {
                paddingTop: 0,
              },
            }}
          >
            <Terms />
          </Grid>
        </Grid>

      
      </ThemeProvider>
    </Grid>
  );
}
let theme = createTheme();

theme = responsiveFontSizes(theme);

theme = responsiveFontSizes(theme);

theme.typography.contactTitle = {
  fontFamily: "Changa",
  fontSize: "2.7rem",
  lineHeight: "2.7rem",
  fontWeight: "900",
  color: "#000",
  // paddingTop: "40px",

  "@media screen and (max-width: 900px)": {
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
    paddingTop: "25px",
  },
};

theme.typography.contactSubTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontWeight: 500,
  fontSize: "1.3rem",
  lineHeight: "1.3rem",

  paddingBottom: "30px",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.fieldTitle = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "18px",
  fontWeight: "900",
  lineHeight: "18px",
};

theme.typography.h3 = {
  color: "#000",
  fontFamily: "Changa",
  fontSize: "2.2rem",
  lineHeight: "2.2rem",

  "@media screen and (max-width:900px)": {
    fontSize: "1.5rem !important",
    lineHeight: "1.5rem",
  },
};

theme.typography.formStyle = {
  // paddingLeft: "1.14vw",
  // paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.formStyleLeft = {
  paddingRight: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.formStyleRight = {
  paddingLeft: "1.14vw",
  paddingTop: "1.14vw",
  "@media screen and (max-width: 900px)": {
    paddingTop: "3.14vw",
    paddingBottom: "3.14vw",
  },
};

theme.typography.topText = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.subtitle1 = {
  lineHeight: "1.5rem",
  fontSize: "1.3rem",
  fontWeight: 500,
  color: "#000",
  fontFamily: "Changa",
  alignSelf: "center",
  textAlign: "left",
  "@media (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};
