import React, { useEffect } from "react";
import RegisterSection from "../../sections/DACSection/RegisterSection";

export default function RegisterPage({page_title,op_data}) {
  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0)
  }, []);
  return (
    <main> 
    <RegisterSection op_data={op_data}/>
    </main>
  )
}
