import {
  CssBaseline,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes
} from "@mui/material";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { PageHeader } from "./components";
import { CustomRedirectionButton } from "./components/FormComponents/FormComponents";

export default function Error({ page_title }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0);
  }, []);

  const handleHomeRedirect = () => navigate("/");

  const handleProgramsRedirect = () => navigate("/programs");

  const handleDonateRedirect = () => navigate("/donate");

  const handleContactRedirect = () => navigate("/contact");
  return (
    <Grid
      sx={{ display: "flex", flexDirection: "column", paddingBottom: "50px" }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main>
          <PageHeader title="Error" background={"#F1B9D0"} />
          <Grid
            container
            direction="column"
            alignItems="center"
          >
            <Grid
              sx={{
                width: "100vw",
                // maxHeight: "50vh",
               
              }}
            >
              <img
                src="/images/error.png"
                style={{
                  width: "100%",
                  maxHeight: "50vh",
                 
                  objectFit: "contain",
                }}
                alt={"background"}
                loading="lazy"
              />
            </Grid>
            <Grid sx={{                padding:"20px 3.14vw",
}}>
            <Typography
            variant="contactTitle"
            sx={{  fontFamily: "Changa",
                fontWeight:900,
              fontSize: {
                xs: "1.1rem",
                sm: "1.1rem",
                md: "1.2rem",
                lg: "1.5rem",
              },
              lineHeight: {
                xs: "1.3rem",
                sm: "1.3rem",
                md: "1.4rem",
                lg: "1.7rem",
              },
            }}
          >
            {location?.state?.message?location?.state?.message: "Can't find what you're looking for? Don't worry, it happens to us too."}
          </Typography>
          </Grid>
            <Grid item sx={{ mb: 3,  maxWidth: "300px",width:"100%",display:"flex", justifyContent:"center","@media screen and (max-width: 900px)": {
                width:"90vw"} }}>
              <CustomRedirectionButton
                sx={{ margin: 0, width: "100%", }}
                href="/contact"
                variant="contained"
                onClick={handleContactRedirect}
              >
                Contact Us
              </CustomRedirectionButton>
            </Grid>
            <Grid item sx={{ mb: 3, maxWidth: "300px",width:"100%", justifyContent:"center",display:"flex","@media screen and (max-width: 900px)": {
                width:"90vw"
              },}}>
              <CustomRedirectionButton
                sx={{ margin: 0, width: "100%" }}
                href="/"
                variant="contained"
                onClick={handleHomeRedirect}
              >
                Back to Home Page
              </CustomRedirectionButton>
            </Grid>
          </Grid>
        </main>
      </ThemeProvider>
    </Grid>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.topText = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.thankYouTitle = {
  fontFamily: "Changa",
  fontSize: "2.7rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "2.7rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
  },
};

theme.typography.thankYouText = {
  fontFamily: "Changa",
  fontSize: "1.7rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.7rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.thankYouParagraph = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
};

theme.typography.thankYouDetails = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
};

theme.typography.thankYouReceipt = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "left",
  flexGrow: 1,
  fontWeight: "bold",
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
};

theme.typography.info = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "left",
  fontWeight: "bold",
  fontStyle: "italic",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
};

theme.typography.contact = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.0rem",
  },
};
