import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import React from "react";
import { data } from "../../data/data_file";

export default function ProgramLandingSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  theme.typography.h3= {
    color: "#000", 
  fontFamily: "Changa",
  fontSize: "2.5rem",
  lineHeight: "2.5rem",
  "@media screen and (max-width:960px)": {
    fontSize: "2rem",
    lineHeight: "2rem",
  },
  };

  theme.typography.subtitle1 ={
    lineHeight:"1.8rem",
    paddingLeft:"7.14vw",
    fontSize:"1.8rem",
    fontWeight:500,
    color: "#000", 
    fontFamily: "Changa", 
    alignSelf:"center",
    
    "@media screen and (max-width: 900px)": {
      fontSize:"1.5rem",
      lineHeight:"1.5rem",
      marginLeft:"0",
      marginTop:"16px",
      padding:0
    },
  }

  var statTheme = {
    width:"50%",
    "@media screen and (max-width: 790px)": {
      width: "100%",
    },
  };
  var statTheme2 = {
    width:"50%",
    "@media screen and (max-width: 790px)": {
      width: "100%",
    },
  };
  return (
    <Grid container >
    <Grid
    container
    style={{
      fontSize: 10,
      // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
      background: "#FFF",
      color: "black",
      paddingLeft: "7.14vw",
      paddingRight: "7.14vw",
      paddingTop: "150PX",
      paddingBottom: "7.14vw",
      display: "flex",
      alignItems:"center",
      flexWrap: "wrap",
    }}
  >
    <ThemeProvider theme={theme}>
    <Grid
        container
        style={{ textAlign: "left",alignItems:"center" }}
        sx={{
          paddingTop: "3.14vw",
          "@media screen and (max-width: 790px)": {
            paddingTop: "7.28vw",
            paddingBottom: "0",
          },
        }}
      >
      <Grid item style={{ width: "100%",paddingBottom:"3vw" }}>
        <Typography
          // variant="h2" 
                  sx={{
                    margin: 0,
                    display: "flex",
                    fontFamily: "Changa",
                    color: "#62B6D0",
                    fontSize:"1.25rem",
                    lineHeight:"1.6rem",
                    // "@media screen and (max-width: 900px)": {
                    //   flexDirection:"column",
                    // }

                  }}
        >
          {`Programs & Events `}
          <Typography
            variant="h6"
            component={'span'}
            style={{
              letterSpacing: "-0.09em",
              fontFamily: "Changa",
              paddingLeft: "5px",
              lineHeight:"1.6rem",
            }}
          >
            {`----`}
          </Typography>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} style={{ justifyContent: "center" }} sx={statTheme}>
        <Typography
          variant="h3"
        >
          {data.programs_page.title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} style={{ justifyContent: "center" }} sx={statTheme2}>
        <Typography
          variant="subtitle1"
        >
          {data.programs_page.title_text}        
        </Typography>
      </Grid>
      </Grid>
    </ThemeProvider>
 
  </Grid>
  
  <img
        src={data.programs_page.image}
        style={{ width: "100%" }}
        alt={"background"}
        loading="lazy"
      />
  </Grid>

  )
}
