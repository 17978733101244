import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { NumericFormat, PatternFormat } from 'react-number-format';

export const PhoneNumberPatternFormatCustom = forwardRef(function PatternFormatCustom(
  props,
  ref,
) {
    const { onChange, ...other } = props;

    return (
      <PatternFormat 
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            }
          })
        }}
        format="(###) ###-####"
      />
    );
});

export const EmployerIdentificationNumberPatternFormatCustom = forwardRef(function PatternFormatCustom(
  props,
  ref,
) {
    const { onChange, ...other } = props;

    return (
      <PatternFormat 
        {...other}
        getInputRef={ref}
        onValueChange={values => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            }
          })
        }}
        format="##-#######"
      />
    );
});

export const NumericFormatCustom = forwardRef(function NumbericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat 
      {...other}
      getInputRef={ref}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: parseFloat(values.value)
          }
        })
      }}
      thousandSeparator
      allowNegative={false}
      decimalScale={2}
      prefix="$"
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

PhoneNumberPatternFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

EmployerIdentificationNumberPatternFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};