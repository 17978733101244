import {
  Divider,
  Grid,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import pdfMake from "pdfmake/build/pdfmake.js";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useRef } from "react";
import { useLocation } from "react-router-dom";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function AttendeeLetter() {
  const pdfRef = useRef();
  const location = useLocation();
  const {
    firstName,
    lastName,

    message,
    created_at,
  } = location.state;

  // const handleThankYouLetter = (e) => {
  //   pdfMake.createPdf(
  //     DocumentDefinition(
  //       id,
  //       firstName,
  //       lastName,
  //       donation_amount,
  //       created_at,
  //       program_title,
  //       payment_method_type,
  //       last_4,
  //       message,
  //       card_type,
  //     )
  //   ).download('Clarintel Thank You Letter.pdf');
  // };

  return (
    <Grid container ref={pdfRef}>
      <ThemeProvider theme={theme}>
        <Grid>
          <Grid item sx={{ mb: 3 }}>
            <Divider
              sx={{ borderColor: "#62B6D0", borderBottomWidth: "thick" }}
            />
          </Grid>

          <Grid item sx={{ mb: 3 }}>
            <Typography variant="subtitle1">
              Dear {firstName} {lastName},
              <br />
              <br />
              From us and the over 100 nonprofits attending the Clarintel Care
              Fair, thank you for registering! We truly appreciate all that you
              contribute to creating a better world.
              <br />
              <br />
              Thanks to your generosity and commitment, over 100 nonprofits and
              community-based organizations will receive the support needed to
              serve the hundreds of thousands of people in need within
              impoverished, marginalized, and underrepresented communities
              around the world.
              <br />
              <br />
              Your decision to support your community leaders, and your
              dedication to making the world better through positive impact
              truly makes a difference.
              <br />
              <br />
              We can’t wait to meet you at the Adele H. Stamp Student Union on
              April 4, 2024, from 12:00pm EST to 8:00pm EST!
              <br />
              <br />
              From the Bottom of Our Hearts, Thank You.
              <br />
              <br />
              <br />
              <br />
              The Clarintel Team.
            </Typography>
          </Grid>
          <Grid
            container
            justifyContent="flex-start"
            alignContent="center"
            sx={{ mb: 3 }}
          >
            <img
              src="/images/clarintel_full.png"
              alt="logo"
              loading="lazy"
              style={{ objectFit: "cover", width: "200px" }}
            />
          </Grid>
          {/* <Grid item>
            <img 
              className="signature"
              src="/images/signature.png" 
              loading="lazy" 
              alt="signature"
              style={{ width: "20%" }}
            />
          </Grid>
          <Grid item sx={{ mb: 2 }}>
            <Typography variant="thankYouParagraph">Brandon Wallerson</Typography>
            <Typography variant="thankYouParagraph">
              President & CEO of Clarintel
            </Typography> 
            <Grid 
            container 
            justifyContent="flex-start" 
            alignContent="center" 
            item 
            sx={{ mb: 3 }}
          >
            <img 
              src="/images/clarintel_full.png" 
              alt="logo" 
              loading="lazy"
              style={{ objectFit: 'cover', width: '200px' }}
            />
          </Grid>
            
          </Grid> */}
          <Grid item sx={{ mb: 3 }}>
            <Divider
              sx={{ borderColor: "#62B6D0", borderBottomWidth: "thick" }}
            />
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

const CustomGrid = styled("Grid")(({ theme }) => ({
  // paddingLeft: 14,
  // paddingRight: 14,
  // [theme.breakpoints.down('md')]: {
  //   paddingLeft: 0,
  //   paddingRight: 0,
  // },
  // [theme.breakpoints.up('md')]: {
  //   paddingLeft: 10,
  //   paddingRight: 10
  // },
}));

theme.typography.topText = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  lineHeight: "1.5rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.3rem",
  },
};

theme.typography.thankYouTitle = {
  fontFamily: "Changa",
  fontSize: "2.7rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "2.7rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
  },
};

theme.typography.thankYouText = {
  fontFamily: "Changa",
  fontSize: "2.0rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "2.7rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.3rem",
    lineHeight: "1.8rem",
  },
};

theme.typography.subtitle1 = {
  fontFamily: "Changa",
  fontWeight: 500,
  fontSize: "1.1rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.thankYouDetails = {
  fontFamily: "Changa",
  fontSize: "1.1rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.thankYouReceipt = {
  fontFamily: "Changa",
  fontSize: "1.1rem",
  textAlign: "left",
  flexGrow: 1,
  fontWeight: "bold",
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.info = {
  fontFamily: "Changa",
  fontSize: "1.1rem",
  textAlign: "left",
  fontWeight: "bold",
  fontStyle: "italic",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};

theme.typography.contact = {
  fontFamily: "Changa",
  fontSize: "1.1rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
  },
};
