import React, { useEffect } from "react";
import ProgramAreaSection from "../sections/ProgramSection/HomeProgramAreasSection";
import PartnerCollabSection from "../sections/ProgramSection/PartnerCollabSection";
import ProgramIntroSection from "../sections/ProgramSection/ProgramIntroSection";
import ProgramLandingSection from "../sections/ProgramSection/ProgramLandingSection";


export default function Programs({page_title}) {
  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0)
  }, []);
  return (
    <main> 
    <ProgramLandingSection/>
    <ProgramIntroSection/>
    <ProgramAreaSection/>
    <PartnerCollabSection/>
    </main>
  )
}
