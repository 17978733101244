import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
// import { useStickyroll } from "@stickyroll/hooks";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default function CollabInterestSection() {
  // const headlines = [
  //   "Digital Acts Of Care",
  //   "Connected & DAC Posts",
  //   "Instant Action Button",
  // ];



  // const [wrapper, ] =
  //   useStickyroll({
  //     pages: headlines,
  //   });
  const text = "Interested in Collaborating, Partnering, or Discussing More with Us?";
  const text2 =
    "Interested in collaborating? Connect with us.";
  const text3 =
  "Through free programs, data, and technology, we make it easy to access social issue information, philanthropic opportunities and organizations that drive a positive impact you can trust within your community and beyond.";
  
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  theme.typography.h3 = {
    fontSize: "2.5rem",
    fontFamily: "Changa",
    lineHeight: "2.5rem",
    "@media (max-width: 1077px)": {
      fontSize: "2rem",
      lineHeight: "2rem",
    },
    "@media (max-width: 900px)": {
      fontSize: "1.7rem",
      lineHeight: "1.7rem",
    },
  };
  theme.typography.h4 = {
    fontSize: "1.2rem",
    fontFamily: "Changa",
    lineHeight: "1.2rem",
    fontWeight: 500,
    "@media (max-width: 1077px)": {
   
      fontSize: "1.1rem",
      lineHeight: "1.1rem",
    },
    "@media (max-width: 700px)": {
      fontSize: "1rem",
      lineHeight: "1rem",
    },
  };

  theme.typography.subtitle1 = {
    lineHeight: "1.2em",
    marginLeft: "7.14vw",
    fontSize: "2rem",
    fontWeight: 500,
    color: "#000",
    fontFamily: "Changa",
    alignSelf: "center",
    "@media (max-width: 1077.8443113772px)": {
      fontSize: "18px !important",
    },
    "@media screen and (max-width: 790px)": {
      marginLeft: "0",
      marginTop: "16px",
    },
  }


  return (
    <Grid
      container
      sx={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-1px",
        padding: "150px 0px 0px",
        "@media screen and (max-width: 900px)": {
          padding: "100px 0px 0px",
        },

      }}
    >
      <ThemeProvider theme={theme}>


        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            paddingBottom: "50px",
            alignItems: "center",
            display: "flex",
            flexWrap: "initial",
            flexDirection: "row",
            overflow: "hidden",

            "@media screen and (max-width: 790px)": {
              width: "100%",
              flexDirection: "column",

            },
          }}
        >

          <Grid

            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              top: "-1px",
              background: "#FFF",
              width: "50vw",
              height: "100%",
              "@media screen and (min-width: 769px)": {
                // maxHeight: "50vw",
              },
              "@media screen and (max-width: 790px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              sx={{

                "@media screen and (min-width: 769px)": {
                  // height: "calc(100% - 7.14vw)",
                  height: "100% ",
                  padding: "0 3.14vw",


                  // maxHeight: "calc(100% - 72px)",
                },
                "@media screen and (max-width: 790px)": {
                  height: "100% ",
                  padding: "3.14vw",

                },
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
                boxSizing: "inherit",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  height: "100%",
                  "@media screen and (min-width: 769px)": {
                    padding: "0 3.14vw",

                  },
                  "@media screen and (max-width: 790px)": {
                    // paddingLeft: "3.14vw",
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={true}

                  style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}
                >
                  <Typography
                    color="black"
                    gutterBottom
                    variant="h3"
                    sx={{
                      fontFamily: "Changa",
                        pb: { xs: "5px", sm: "10px", md: "15px", lg: "30px"  },
                        color: "#000", 
                        lineHeight:"1.1em",
                        fontWeight: 700
                    }}
                  >
                    {text}
                  </Typography>
                
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            sx={{
              position: "relative",
              width: "50vw",
              minHeight: "400px",
              background: "#FFF",
              overflow: "hidden",
              borderTopLeftRadius: 30,
              borderBottomLeftRadius: 30,
              "@media screen and (max-width: 790px)": {
                width: "100%",
                // minHeight: "65vw",
                borderRadius: 0,
                minHeight:200,
              },
            }}
          >
            <Grid
              container
              style={{
                width: "calc(100% )",
                height: "calc(100% )",
                top:0,
                left:0,
                right:0,
                bottom:0,
                // maxWidth: "calc(100% - 72px)",
                // maxHeight: "calc(100% - 36px)",
                // width: "calc(100% )",
                // height: "calc(100%)",
                // maxWidth: "calc(100% )",
                // maxHeight: "calc(100% )",
                // padding:"72px",
                position: "absolute",
                zIndex: 1,
                backgroundSize: "cover",

              }}
            >
              <ScrollAnimation
                animateIn="fadeInRight"
                initiallyVisible={true}
                style={{ width: "100%" }}
              >
                <Grid sx={{borderTopLeftRadius: 30,
                    borderBottomLeftRadius: 30,"@media screen and (max-width: 790px)": {
                width: "100%",
                height: "100%",
                borderRadius: 0,
              },}}>
                <img
                  src="/images/contact-landing.jpg"
                  style={{
                    width: "100%",
                    height: "100%",
                    "--aspect": "100%",
                    position: "absolute",
                    objectFit: "cover",
                    

                  }}
                  alt={"focus"}
                  loading="lazy"
                />
                </Grid>
                <Grid
                  container
                  sx={{
                    width: "100%",
                    background: "rgba(0,0,0,.7)",
                    minHeight: "180px",
                    "@media screen and (max-width: 900px)": {
                      minHeight: "100%",
                    },
                    bottom: 0,
                    position: "absolute",
                    borderBottomLeftRadius: 30,
                    "@media screen and (max-width: 790px)": {
                      // width: "100%",
                      // height: "100%",
                      borderRadius: 0,
                    },
                    // borderBottomLeftRadius: 30,
                    // borderBottomRightRadius: 30,

                  }}
                >


                  <Grid
                    container
                    sx={{
                      width: "100%",
                      background: "rgba(0,0,0,.7)",
                      height: "60px",
                      bottom: 0,
                      position: "absolute",
                      borderBottomLeftRadius: 30,
                      "@media screen and (max-width: 790px)": {
                        // width: "100%",
                        // height: "100%",
                        borderRadius: 0,
                      },
                      // borderBottomLeftRadius: 30,
                      // borderBottomRightRadius: 30,

                    }}
                  >
                    <Typography
                      color="white"
                      gutterBottom
                      variant="h4"
                      sx={{
                        
                        padding: "0px 3.14vw 0px",
                        "@media screen and (min-width: 768px)": {
                          padding: "0px 3.14vw 0px",
                       
                        },
                        bottom: 15,
                        zIndex: 10,
                        position: "absolute"
                      }}
                    >
                      {text2}
                    </Typography>
                  </Grid>
                  <Typography
                    color="white"
                    gutterBottom
                    variant="h4"
                    sx={{
                      pt:10,
                      bottom: "35%",
                      zIndex: 10,
                      padding: "0px 3.14vw 0px",
                      "@media screen and (max-width: 790px)": {
                        padding: "10px 3.14vw 0px",
                        bottom:"30%"
                      },
                      position: "absolute"
                    }}
                  >
                    {text3}
                  </Typography>
                </Grid>
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>

  )
}
