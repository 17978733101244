import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { CustomSubmitButton } from "../../components/FormComponents/FormComponents";
import { care_fair_data } from "../../data/data_file";

export default function ForTheLeadersSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const navigate = useNavigate();

  theme.typography.subtitle1 = {
    fontWeight: 500,
    fontFamily: "Changa",
    // "@media (max-width: 1285px)": {
    //   lineHeight: "18px",

    //   fontSize: "18px !important",
    // },
    // "@media screen and (max-width: 900px)": {
    //   // lineHeight: "1.1em",
    //   marginTop: "12px",
    // },
  };
  theme.typography.h3 = {
    "@media (max-width: 900px)": {
      fontSize: "3rem",
    },
  };
  // theme.typography.h6 = {
  //   fontSize: "0.6rem",
  //   "@media (min-width:300px)": {
  //     fontSize: ".77rem",
  //   },
  //   "@media (min-width:500px)": {
  //     fontSize: "1.1rem",
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "2.2rem",
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     fontSize: "2.9rem",
  //   },
  // };

  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        padding: "7.14vw 7.14vw 0px",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-1px",
        justifyContent: "center",
      }}
    >
      <ThemeProvider theme={theme}>
        <Typography
          color="black"
          gutterBottom
          // variant="h3"
          sx={{
            fontFamily: "Changa",
            width: "100%",
            lineHeight: {
              xl: "3.5rem",
              lg: "3rem",
              md: "2.7rem",
              sm: "2.3rem",
              xs: "2rem",
            },
            fontWeight: 700,
            fontSize: {
              xl: "3.5rem",
              lg: "3rem",
              md: "2.7rem",
              sm: "2.5rem",
              xs: "2.3rem",
            },
          }}
        >
          {care_fair_data.ftl_title}
        </Typography>
        {/* Group 1 */}

        <Grid
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignContent: "center",
            top: "-1px",
            height: "100%",
            justifyContent: "space-between",
            background: "#FFF",
          }}
          sx={{
            // width: "50vw",
            // "@media screen and (min-width: 769px)": {
            //   maxHeight: "50vw",
            // },
            "@media screen and (max-width: 900px)": {
              width: "100%",
            },
          }}
        >
          <Grid
            sx={{
              "@media screen and (min-width: 769px)": {
                height: "calc(100% - 7.14vw)",
                maxHeight: "calc(100% - 72px)",
              },
              "@media screen and (max-width: 900px)": {
                // padding: "3.14vw 0",
              },
              // padding: "3.14vw",
            }}
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              width: "100%",
              boxSizing: "inherit",
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                height: "100%",
                "@media screen and (min-width: 769px)": {
                  // padding: "3.14vw",
                  paddingLeft: 0,
                },
              }}
            >
              <ScrollAnimation
                animateIn="fadeIn"
                initiallyVisible={true}
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                
                <Typography
                    color="black"
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      textTransform: "uppercase",
                      pb:"20px",
                      fontSize: { xs: "2.1em", sm: "2.3rem", md: "2.7rem", lg:"2.9rem" },
                      lineHeight: { xs: "1.6rem", sm: "1.8rem", md: "2.2rem",  lg:"2.7rem"},
                    }}
                  >
                    <span
                      style={{
                        color: "#62B6D0",
                      }}
                    >
                                          {care_fair_data.leader_title}

                    </span>
                    {care_fair_data.leader_focus}

                  </Typography>
                <Grid sx={{flexDirection:"row-reverse",display:"flex", "@media screen and (max-width: 1100px)": {
                          flexDirection:"column",
                        },}}>
                <Grid
                  // xs={12}
                  // sm={12}
                  // md={4}
                  // lg={4}
                  sx={{
                    position: "relative",
                    height: "100%",
                    // minHeight:"75vh",
                    // maxHeight: "50vh",
                    // padding:"1.14vw",
                    justifyContent: "center",
                    flexDirection:"row",
                    display: "flex",
                    background: "FFF",
                    width: "40%",
                    overflow: "hidden",
                    "@media screen and (max-width: 1100px)": {
                      width: "100%",
                    },
                  }}
                >
                  <ScrollAnimation
                    animateIn=""
                    initiallyVisible={true}
                    style={{
                      
                      display: "flex",
                    }}
                  >
                    <Grid
                      sx={{
                        // paddingTop: "15px",
                        height: "100%",
                        width: "100%",
                        "@media screen and (max-width: 900px)": {
                          paddingBottom: "3vw",
                          height: "100%",
                        },
                      }}
                    >
                      <img
                        src="images/for-the-leaders.png"
                        style={{
                          width: "100%",
                          borderRadius: "40px",
                          height: "100%",
                          objectFit:"contain",
                        }}
                        alt={"background"}
                        loading="lazy"
                      />
                    </Grid>
                  </ScrollAnimation>
                </Grid>
                <Grid sx={{width: "60%", justifyContent:"space-around", display:"flex",flexDirection:"column",paddingRight:"1.14vw", "@media screen and (max-width: 1100px)": {
                          width: "100%",
                        },}}>
                         
                 
            
                  <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      paddingTop: "15px",
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        // lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        // lg: "1.7rem",
                      },
                    }}
                  >
                    {`${care_fair_data.the_leaders} Event registration includes:`}
                  </Typography>
                  <Grid sx={{display:"flex", flexDirection:"row", justifyContent:"space-between","@media screen and (max-width: 900px)": {
                          flexDirection:"column",justifyContent:"center",alignItems:"center",
                        },}}>
                    {care_fair_data.leader_support.map((item, index) => (
                        <Grid key={index} sx={{display:"flex",flexDirection:"column",paddingBottom:"15px",alignItems:"center",}}>
                        <img
                  src={item.img}
                  style={{
                    width: "150px",
                    maxHeight: "150px",
                    height:"100%",
                  }}
                  alt={"background"}
                  loading="lazy"
                />
                      <Typography
                        color="black"
                        gutterBottom
                        variant="none"
                        sx={{
                          fontFamily: "Changa",
                          lineHeight: "1.3rem",
                          pt:"20px",
                          fontWeight: 700,
                          fontSize:"1.3rem",
                          "@media screen and (max-width: 900px)": {
                            lineHeight: "1.1rem",
                          fontWeight: 700,
                          fontSize:"1.1rem",
                          },
                          "@media screen and (max-width: 600px)": {
                            lineHeight: "1rem",
                          fontWeight: 700,
                          fontSize:"1rem",
                          },
                        }}
                      >
                        {item.goal}
                      </Typography>
                        </Grid>
                      ))}
                      </Grid>
                      <Link to="https://forms.office.com/r/54GNhL0Byx"  target="_blank" >
                    <CustomSubmitButton
                      sx={{
                        background:"#F1B9D0",
                        margin: 0,
                        margin:"20px 0",
                        width: "260px",
                        fontSize: "20px",
                        "@media screen and (max-width: 900px)": {
                          fontSize: "17px",
                          width:"100%"
                        },
                      }}
                      variant="contained"
                      type="button"
                            >
                      Register My Org
                    </CustomSubmitButton>
                    </Link>
                </Grid>
                </Grid>
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
          sx={{
            maxWidth: "500px",
            width: "100%",
            backgroundColor: "#C5C5C5",
            height: "3px",
            marginTop: "7.14vw",
          }}
        /> */}
      </ThemeProvider>
    </Grid>
  );
}
