export const donation_data = {
  id: 3,
  link: "/programs/reporting",
  image: "/images/career-fair.jpg",
  program_area: "Donation",
  program_snippet: "Thanks you for your support.",
  title: "Thank you for your support.",
  event_type: -1,
  donation_type: "Direct",
};
export const care_fair_data = {
  id: 3,
  about: "",
  image: "",
  title_1: "About the Event",
  title_2: "Why This Matters",
  ftp_title: "For the People",
  ftl_title: "For the Leaders",
  section1_image: "/images/about_image.png",
  section2_image: "/images/matters_image.png",
  event_goals: [
    { goal: "Student Organizations", stat: "10+" },
    { goal: "Nonprofit & CBO’s", stat: "100+" },
    { goal: "Community Members & Students", stat: "1K+" },
  ],
  partners:[
    {img:'/images/pbs-logo.png',link:"https://epsi1972.com/"},
    {img:'/images/akpsi-logo.jpeg',link:"https://www.akpsi-umd.org/"},
    {img:'/images/he-logo.png',link:"https://www.instagram.com/umdzetas/"},
    {img:'/images/bgv-logo.png',link:"https://www.blackgirlsvote.org/"},
    {img:'/images/nu-logo.png',link:"https://www.instagram.com/umd_iotas/"},
    {img:'/images/tnt-logo.png',link:"https://tntnupes.com/"},
  ],
  subtitle_1: "Clarity in Impact.",
  subtitle_2: "Intelligence in Action.",
  ftp_subtitle: "Our mission is to promote and redefine social action through ",
  ftp_subtitle_focus_text: "clarity and intelligence.",
  about_the_event:
    "The Care Fair connects students and community members to the nonprofits and community based organizations driving a positive impact within underrepresented, marginalized, and impoverished communities throughout DC, Maryland, and Virginia. At the event, attendees can engage in any form of philanthropic activity to support and strengthen the credible nonprofits and community-based organizations working to create a better world.",
  clarity:
    "Today, the demand for opportunities that generate a positive social impact are at an all-time high. However, nonprofits continue to struggle with effectively meeting the diverse expectations of their communities. To improve the health, welfare, and wellbeing of our communities, individuals and nonprofits need the ability to easily discover and access the resources required to drive a sustainable, positive impact.",
  intelligence:
    "The Clarintel Care Fair bridges the gaps between communities and community leaders to dramatically simplify community engagement. At the event, community members and change agents can easily access the credible nonprofits and organizations driving a positive impact within their communities and beyond. Likewise, nonprofits and community-based organizations can easily attract and discover the volunteers, donors, supporters, and potential employees that will help them to accomplish their missions and transform their communities.",
  the_people:
    " to community members and students of all ages. Join us for the chance to strengthen the nonprofits serving underrepresented, marginalized, and impoverished communities throughout DC, Maryland, and Virginia to create the change that you want to see in the world.",
  the_leaders:
    "The Clarintel Care Fair is FREE AND OPEN TO ALL nonprofit, student, and community-based organizations serving underrepresented, marginalized, low income, and impoverished communities across the globe. Community leaders may be required to provide additional proof of tax exemption upon request by Clarintel staff. At the event, nonprofits can engage with over 1,000 community members and accept donations, network with other community leaders, gather employee prospects, recruit volunteers, and more!",
    donation_sharing_summary:"At Clarintel, we believe that positive impact can only happen when our communities and nonprofits have the tools and resources needed to transform their communitities. Not onnly is the Care Fair free for all attendees and organizations, but Clarintel is giving 50% of donations back to the nonprofits and community based organizations that attend the Care Fair and partner with us to create the change that our communities need. All donations provided by Clarintel will be shared evenly between each group showcased below.",
    //This means that for every dollar we receive over the $14,000 needed to run the event, half of that dollar goes back to the community leaders driving a positive impact within your community and beyond.
    attendee_ds:
    "By just attending the the Care Fair, nonprofits and community based organizations automatically receive a portion of the 25% of donations received by Clarintel at the Care Fair. With the help of community members like you, nonprofits and community based organizations will be able to attend the event for free while amplifying their positive impact like never before.",
    student_ds:
    "To ensure that Clarintel provides the next generation with the opportunity to create the change that they want to see in the world, we are providing 10% of donations that we receiveat the Care Fair to student organizations that attend the event. We are currently looking for up to 15 student organizations to attend the event. For eligibility, all you have to do is prove that you are a 501(c)(3) organization or that you are connected to a 501(c)(3) organization, and demonstrate that you are activley driving a positive impact at you're university's campus. If you're interested, reach out to find out how you can attend the Care Fair!",
    partner_ds:
    "Community leaders that partner with Clarintel to ensure that the Care Fair bridges the gaps between nonprofits, community based organizations, and community members  will be eligible for 15% of donations received by Clarintel at the Care Fair. We are currently looking for 10 partners to help us connect community members to the nonprofits and community based organizations driving a postive impact within their community and beyond.",
  leader_title: "Community Focused, ",
  leader_focus: "Positive Impact First",
  leader_support: [
    { goal: "Booth & Table", img: "images/leaders_image2.png" },
    { goal: "2 Event Representative Tickets", img: "images/leaders_image1.png" },
    { goal: "Donation Sharing", img: "images/leaders_image3.png"},
  ],
  people_title:"Create the Change ",
  people_focus:"That You Want to See in the World.",
};

export const data = {
  mission:
    "Our mission is to promote and redefine social action through clarity and intelligence.",
  who_we_are: {
    title:
      "Clarintel bridges the gaps between communities to drive a positive impact.",

    // description: "Through free programs, data, and technology, we make it easy to access social issue information, philanthropic opportunities and organizations that drive a positive impact you can trust within your community and beyond.",
    description:
      "We connect people impacted by social issues with the change agents and organizations driving sustainable, positive impact within their communities and beyond. Through Clarintel, you can create the change you want to see in a matter of seconds.",
    // 'With the tap of a finger, anyone impacted by social issues can connect directly with community members and organizations to generate a measurable, sustainable, positive impact they can understand, feel, and trust. Through Clarintel, you can create the change you want to see in a matter of seconds.',
  },
  what_we_do: [
    {
      title: "Inspire Social Innovation",
      description:
        "We collaborate with community members and organizations to understand the impact of social issues and to create cutting edge solutions that transform the lives of those in need.",
    },
    {
      // title: "Clarify Philanthropic Action",
      title: "Clarify Community Action",
      description:
        // 'We provide free access to a transparent, centralized collection of social issue data and community focused opportunities tailored to individual needs and interests.',
        "We organize social issue and philanthropic information in a centralized, transparent collection tailored to community needs and interests accessible to anyone for free.",
    },
    {
      // title: "Unify Community Engagement",
      title: "Unify Positive Engagement",

      description:
        "We create the environment for individuals to unify in response to social issues through impact driven opportunities that transform communities.",
    },
  ],
  about_us: {
    title: "What is Clarintel?",
    intro:
      "Clarintel is a 501(c)(3) nonprofit dedicated to creating programs and solutions that simplify impact making, amplify philanthropic engagement, and unify communities to drive a positive impact.",
    mission:
      "Our mission is to promote and redefine social action through clarity and intelligence.",
    belief:
      "At Clarintel, we believe that transparent data and strong relationships can unite individuals to drive and amplify positive impact within their communities and beyond.",
    story_text1:
      "Currently, very few opportunities exist for individuals and community organizers to gain public support, attention, and buy-in for social impact opportunities that combat social issues at the local and community level. With smaller, local, community based, and grassroot nonprofits making up over 65% of the social sector, impoverished to low income communities in need continue to suffer as smaller nonprofits find themselves struggling or unable to respond to the growing demand for community focused opportunities, services, and solutions.",
    story_text2:
      "Clarintel, Inc. was established in 2020 to simplify access to knowledgable resources and opportunities that clarify and amplify community engagement. We create the environment for individuals to gain attention for the social issues affecting their communities, while connecting with the impact makers and organizations driving a positive impact.",
    call_to_action: "Are you ready to change the world?",
    values: [
      {
        title: "Truth",
        description: "Valuing data leads to truth.",
      },
      {
        title: "Understanding",
        description:
          "Understanding the connection in our differences will strengthen relationships and make life easier.",
      },
      {
        title: "Unity",
        description:
          "We are one. We can unify based on anything if we choose to see it that way.",
      },
      {
        title: "Connections",
        description: "We are connected. What we do affects other people.",
      },
      {
        title: "Equity",
        description:
          "Human equity must incorporate race to fully understand the social constructs that make us feel different.",
      },
      {
        title: "Love",
        description:
          "Love is Attention. Love is Care. Love is Understanding. Love is Respect. Love is Empathy. Love is A. C.U.R.E, and the world just needs a little more love.",
      },
    ],
    how_we_work: [
      {
        title: "Collaborate With Impact Makers",
        image: "/images/collaborate.jpg",
        snippet:
          "Clarintel works directly with nonprofits, community based organizations, and impact makers serving underrepresented, low income, and impoverished communities to build innovative solutions and programs that transform lives through positive impact.",
        description: "",
        call_to_action:
          "Contact us to learn about how to become a Clarintel Partner.",
        link: "/contact",
      },
      {
        title: "Understand the Community",
        image: "/images/connect.jpg",
        snippet:
          "We take the time to learn about each community we serve, and provide credible opportunities and organizations that effectively respond to community needs and interests.",
        description: "",
        call_to_action: "Learn about our programs.", //Programs Page
        link: "/programs",
      },
      {
        title: "Simplify the Impact Making Process",
        image: "/images/create.jpg",
        snippet:
          "We make it easy to access community focused solutions and programs aligned with your interests and tailored to your communities needs. Through Clarintel, you can drive a positive impact that you trust at any time, from any place.",
        description: "",
        // call_to_action: 'Join the future of philanthropy.', //Join Us Page
        call_to_action: "Support the future of philanthropy.", //Join Us Page
        // link: '/donate'
        link: "/donate",
      },
    ],
    leadership: {
      title: "Our Dedicated Leaders",
      intro1:
        "Clarintel is led by a dedicated team holding almost a decades worth of experience developing, marketing, managing, and supporting technological solutions for government entities, educational institutions, nonprofits, small businesses, and Fortune 100 clients. Clarintel founders have dedicated their skills, experiences, and passions to the lifetime commitment of increasing the health, welfare, and wellbeing of our underrepresented, impoverished, and low income communities.",
      intro2:
        "We are committed to our mission, to promote and redefine social acton through clarity and intelligence. We understand that  nonprofits are vital to the health and welfare of communities in need, and intend for all solutions, offerings, and tools provided to nonprofits and community members to remain free, forever.",
      executives: [
        {
          image: "",
          name: "Brandon Wallerson",
          title: "President & CEO",
          description:
            "Brandon Wallerson, Clarintel’s President & Chief Executive Officer, drives our mission forward through the effective planning, organization, and execution of our strategies, efforts,and approaches. Mr. Wallerson has over 5 years of  experience developing, executing, and managing outreach endeavors, partnerships, and events for Phi Beta Sigma Fraternity, Inc. and the University of Maryland College Park Department of Computer Science. In addition, he has over 8 years of experience developing and managing technical and artificial intelligence programs and solutions for multiple Fortune 50 companies. ",
        },
        {
          image: "",
          name: "Kwesi Larbi",
          title: "Vice President & Chief Technology Officer",
          description:
            "Kwesi Larbi, Clarintel’s Vice President and Chief Technology Officer, is responsible for the oversight, development, tracking, and execution of Clarintel’s technical approaches and provides supervision for Clarintel’s engineering, artificial intelligence, and data focused staff. Mr. Larbi holds over 5 years of technical consulting and management experience with nonprofits and small businesses in the United States, Liberia, and Ghana, and has spent the previous 4 years managing the technological modernization effort for the US Department of Energy. In addition, Mr. Larbi spent 4 years strategizing, developing, and executing budgets, fundraising endeavors, marketing campaigns, and community focused approaches for Phi Beta Sigma, Inc. Due to his efforts and leadership, his organization won Organization of the Year at the University of Maryland College Park. Mr. Larbi holds a degree in Economics from the University of Maryland College Park.",
        },
        // {
        //     image: "",
        //     name: "Osamuyimen (Muyi) Gbowa",
        //     title: "Secretary & Cheif Marketing Officer",
        //     description: "Osamuyimen Gbowa, Clarintel’s Secretary and Chief Marketing Officer, is responsible for the oversight, development, tracking, and execution of Clarintel’s strategic marketing, nonprofit acquisition, and community outreach efforts. He has 7 years of experience with marketing products and developing marketing strategies for small businesses and startups based in the US and Nigeria. ",
        // },
      ],
      board_members: [],
    },
  },
  leaders: [
    {
      name: "Brandon Wallerson",
      title: "President & CEO",
      image: "/images/brandon-wallerson.png",
      bio: "Brandon has over 7 years of experience with Fortune 100 companies and quit his job at Microsoft as a Program Manager in the security space to dedicate his life to positive impact.",
    },
    {
      name: "Kwesi Larbi",
      title: "Vice President & CTO",
      bio: "Kwesi has experience with consulting small businesses, and has spent the past 3 years managing the technological modernization effort for the Energy Information Administration.",
      image: "/images/kwesi-larbi.jpg",
    },
  ],
  air_program_details: [
    {
      name: "Carlos Vanzego",
      title: "AIR Apprentice 2023",
      image: "/images/carlos_vanzego.jpg",
      organization: "Phi Beta Sigma Fraternity Incorporated",
      occupation:
        "Recent Graduate of the University of Maryland College Park & Professional Fitness Consultant",
      bio: "Carlos boasts a unique journey from healthcare to technology. With a background in fitness and a transition to software sales, he has honed strong interpersonal skills, a passion for technology, and adept problem-solving abilities. Committed to refining his programming skills, collaborating with diverse teams, and staying abreast of emerging technologies, he's poised for continuous growth in the tech world.",
      quote:
        "I have always been an advocate for helping others in any way that I can, I believe that philanthropy is one of the best ways to do so. Through philanthropy and social action, I understand that I can address pressing issues, support the less fortunate, and work towards a more just and compassionate world. These efforts not only benefit others but also bring a sense of fulfillment and purpose to my own life. It's a reminder that we're all connected, and by coming together to help one another, we can create a brighter, more equitable future for everyone.",
    },
    {
      name: "Ayman Bootwala",
      title: "AIR Intern 2023",
      image: "/images/ayman_bootwala.png",
      organization: "Alpha Kappa Psi Omega Theta Chapter",
      occupation:
        "Student @ the University of Maryland College Park & Consultant",
      bio: "Ayman Bootwala is currently a student at the University of Maryland, College Park majoring in Applied Mathematics and Economics. He is also involved with QUEST Honors Program, the Center for Social Value Creation, the Office of Career Services, and the Office of Experiential Learning. Outside of her academics, Ayman is involved on campus as the Executive Vice President, Consulting Group Founder and Lead for AKPsi as well as Co-President of the Cricket Club. Ayman is passionate about leading others to success in the community. Whether that is by using his experience as a senior at the University of Maryland or his passion for ESG financial research!",
      quote:
        "Philanthropy and social impact matter so much to me because it was heavily overlooked in my hometown leading to those who are underprivileged in my area to be left unserved “by the affluent around them. I volunteered for them in highschool and it has continued to fuel my passion for service in college.",
    },
    {
      name: "Meilin Yuan",
      title: "AIR Intern 2023",
      image: "/images/meilin_yuan.png",
      organization: "Alpha Kappa Psi Omega Theta Chapter",
      occupation:
        "Student @ the University of Maryland College Park & Consultant",
      bio: "Meilin Yuan is currently a student at the University of Maryland, College Park majoring in Information Systems with a minor in Sustainability.  She is also involved with the QUEST Honors Program, University Honors, and the Global Fellows Program. Outside of her academics, Meilin is involved on campus as an Events Organizer for Technica and a PR Director for the Gamer Symphony Orchestra. Meilin enjoys hiking, playing the clarinet, and sketching! Meilin is passionate about positive social impact and community service. She served as the Recording Secretary of her high school Key Club and continues to participate in community service efforts around campus such as trash pick-ups and community gardening.",
      quote:
        "Social impact and volunteering efforts allow everybody to give back to their communities no matter how big or small. It is amazing to see people come together from various backgrounds in order to accomplish a common goal for the greater good.",
    },
    {
      name: "Hudson Grill",
      title: "AIR Intern 2023",
      image: "/images/hudson.png",
      organization: "Alpha Kappa Psi Omega Theta Chapter - Consultants",
      occupation:
        "Student @ the University of Maryland College Park & Consultant",
      bio: "Hudson Grill is an Aerospace Engineer at the University of Maryland. As a member of Alpha Kappa Psi’s inaugural consulting team, he applies his unique skill set to help others turn their ideas into realities.",
      quote:
        "Social impact and volunteering efforts allow everybody to give back to their communities no matter how big or small. It is amazing to see people come together from various backgrounds in order to accomplish a common goal for the greater good.",
    },
    {
      name: "Jimmy Herman",
      title: "AIR Intern 2023",
      organization: "Alpha Kappa Psi Omega Theta Chapter - Consultants",
      image: "/images/jimmy_2.png",
      occupation:
        "Student @ the University of Maryland College Park & Consultant",
      bio: "Jimmy is a sophomore information systems major at the University of Maryland looking to combine his interests in technology and finance with his passion for helping people to improve his community.",
      quote:
        "Philanthropy and social impact are important to me because they provide a meaningful way for me to contribute to the betterment of my community, fostering a sense of connection and shared responsibility.",
    },
  ],
  opportunities_page: {
    sub_text:
      "Clarintel provides free events, data, and technology that bridge the gaps between communities and community leaders, and amplifies positive impact.",
    method:
      "Clarintel builds our programs on the belief that data and relationships are the key to driving revolutionary change within communities in need. For us to develop, manage, and provide all of our solutions, offerings, and tools to nonprofits and community members for free, we rely on community leaders and change agents, like you, to support our mission, to promote and redefine social action through clarity and intelligence. ",
    intro:
      "With your help, Clarintel creates and provides in person, grant-based, and technology focused solutions that unite communities and community leaders, amplify positive impact, and lead to a sustainable, measurable positive impact.",
    opportunities: [
      {
        id: 0,
        link: "/programs/air",

        title: "A.I.R. Program",
        snippet:
          "Clarintel’s Apprenticeship & Internship Rotational (A.I.R.) Program unites high school and college aged students with real world experiences that build the next generation of impact driven leaders dedicated to positive impact.",
        about:
          "Clarintel’s Apprenticeship & Internship Rotational (A.I.R.) Program unites educational knowledge with real world experiences that empower students of all ages to create a sustainable, positive impact that transforms communities in need. AIR Program participants use their skills and abilities to increase the impact of, and overall engagement in, social impact opportunities through innovative community focused solutions and strategies. View our program participants above and follow us on all social media platforms @clarintel to stay up to date on how we are working with the the next generation of leaders to drive a positive impact within our community and beyond.",
        donation_type: "AIR Program",
        image: "/images/air-p3.jpg",
        event_type: 0,
        philopics: [
          "Apprenticeship",
          "Internship",
          "Education",
          "Career Development",
        ],
        impact_intro:
          "Our programs inspire impact makers to support and strengthen nonprofits responding to the growing needs of impoverished, low income, and underrepresented communities. To ensure that we drive a sustainable, positive impact, our programs cover the below impact areas.",
        goals: {
          text: "The AIR program provides participants with the ability to build leadership, management, and technical skills through the completion of community focused, data driven pro-bono projects that drive a sustainable, measurable, positive impact. All projects and solutions completed will focus on community engagement and social issue understanding, enhance the technical and non-technical abilities of participants, and produce a measurable, tangible solution that produces positive impact visible and applicable to the community at-large. For more information on this program, or if you are interested in getting involved, connect with us! Through this program, Clarintel will achieve the below goals:",
          details: [
            "Enroll and mentor 10 interns through 2 AIR program rotations in order to respond to the needs of underrepresented, low income, impoverished, and at-risk communities through the next generation of leaders committed to building a future through positive impact.",
            // 'Provide at least 2 community engagement opportunities showcasing cutting edge solutions created by AIR Program participants.',
            "Connect and partner with community members and organizations to utilize the innovative solutions created by AIR program participants so that Clarintel can track and measure our progress towards achieving our misson and revolutionizing philanthropy.",
            "Provide community members with the ability to access opportunities and information that promote knowledgeable philanthropic action so that community level philanthropic opportunities are available to community members at any time, from any place.",
            // 'Partner with 2 student organizations and 10 community based organizations.',
            // 'Offer 2 community facing opporutnities for AIR program participant exposure.',
            // 'Release solutions created by AIR Program participants to the public for ampifying and enhancing community engagemnt and social issue understanding.'
          ],
        },
        funding: {
          text: "To ensure that we provide AIR program participants with the tools, resources, and opportunties that connect their academic knowledge to real world experiences that drive positive impact, donations towards this program will help us:",
          details: [
            "Build and support the infrastructure and technology needed for the execution of mission critical tasks required to respond to the needs of communities and community organizations.",
            "Recruit talented, dedicated impact makers that are passionate about social innovation to develop cutting edge technologies that shape the future.",
            "Cover expenses related to managing, executing, and sustaining projects completed by AIR Program Participants, and other fees related to the AIR Program experience including but not limited to marketing, external efforts, learning opportunities, and Clarintel hosted community engagement and exposure efforts.",
          ],
          funding_goals: [
            {
              key: 0,
              link: "/",
              number_objective: 120000,
              objective_description: "Funding Goal",
            },
          ],
        },
        impact_areas: [
          {
            title: "Social Outcomes & Quality of Public Service",
            image: "/images/social_outcomes.jpg",
            description:
              "Clarintel's programs amplify social outcomes and increase the quality of public service through community level, data driven solutions. For each program, Clarintel works closely with communities and organizations accross multiple social issues and causes to ensure that we continously enhance community driven endevours towards the elimination of social issues.",
            // 'Clarintel\'s programs streamline community engagement to amplify positive social outcomes and to increase the quality of public service through community level, data driven solutions. For change agents, impact makers, activists, and community based organizations, Clarintel provides data focused tools and solutions catered to their needs for enhancing the understanidng of and engagement in opportunities that drive a positive impact. Through each program, Clarintel collaborates with communities and organizations accross multiple social issues and causes to ensure that our solutions and endevours continously enhance community driven endevours towards the elimination of social issues.',
          },

          {
            title: "Socio-Economic Inequality",
            image: "/images/socioeconomic.jpg",
            description:
              "Clarintel programs help people access to employment and volunteer opportunities that transform communities while providing real world experience through social innovation. We collaboarte with nonprofits to provide communities with opportunities for economic stability and career longevity that address the needs of their communities and beyond.",
            // 'Clarintel provides individuals with access to employment and volunteer opportunities that transform communities while providing real world experience through social innovation. By prioritizing the positive impact, Clarintel provides individuals with first hand access to opportunities that not only increase economic stability and career longevity, but increases the health and welfare of their communities and beyond.',
          },
        ],
      },
      {
        id: 1,
        title: "Philanthropic Access",
        event_type: 1,
        tagline:
          "We make it easy for you to access the opportunities and organizations driving a positive impact.",
        snippet:
          "Clarintel simplifies philanthropy and makes achieving a measurable, sustainable positve impact easy, understandable, and accessible to everyone, everywhere, no matter what.",
        program_description:
          "Our initiatives amplify the community's understanding of social issues and engagement in social action by providing solutions that simplify and streamline access to philanthropic and social issue focused data, information, organizations, & opportunities.",
        // philopics: [
        // 	'Data & Technology',
        // 	'Resource Accessibility',
        // 	'Community Engagement',
        // 	'Socio-Economic Inequality',
        // ],
        // impact_intro:
        // 	'Our initiatives inspire impact makers to support and strengthen the change agents and community based organizations responding to the growing needs of impoverished, low income, underrepresented, and at-risk communities. To ensure that we drive a sustainable, positive impact, our programs cover the below impact areas.',
        about:
          "Our Philanthropic Access Initiative amplifies the community's understanding of social issues and engagement in social action by simplifying and streamline access to philanthropic and social issue focused data, information, & opportunities. All tools and solutions created by Clarintel will be free and open to all individuals and community organizations. While Clarintel's Philanthropic Access Initiatives empower all individuals to drive a positive impact, we primarily focus on assisting community based organizations generating less than $1,000,000 in revenue to accomplish their missions. Clarintel is currently in the proceess of centralizing philanthropic information and opportunities for community engagement. To learn about our progress, or if your interested in creating cutting edge solutions to community level social issues, feel free to reach out and follow us on social media @clarintel!",
        image: "/images/network.jpg", // /images/community_unity.jpg
        link: "/programs/philanthropic-access",
        philopics: [
          "Data & Technology",
          "Resource Accessibility",
          "Community Engagement",
          "Social Issue Understanding",
          "Web & App Development",
          "Data Collection & Analysis",
          "Socio-Economic Inequality",
        ],
        impact_intro:
          "Our programs inspire impact makers and nonprofits to unite and strengthen their communities through philanthropy and positive impact. To ensure that we drive a sustainable, positive impact, our programs cover the below impact areas.",
        goals: {
          text: "All solutions created by Clarintel will be available for free to everyone via the Clarintel App and website. We will rely on communty members like you to hold us accountable to providing quality services, data, and information that create a measurable positive impact, streamline community engagement, and increase social issue understanding. Through this initiative, Clarintel aims to accomplish the below goals:",
          // text: "We will rely on communty members like you to hold us accountable to creating the environment for easily accessible, community level resources that amplify positive impact and philanthorpic understanding. In addition, Clarintel aims to accomplish the below goals:",
          details: [
            "Provide community members with the ability to perform community critical tasks on the Clarintel App and to access real time information relevant to philanthropy, community engagement, and social issue understanding so that Clarintel can streamline the impact making process and increase access to community focused resources.",
            "By April, 2025 Host at least 500+ community led opportunities on the Clarintel App and centralize a diverse collection of information connected to 1,000+ social issues and community based organizations.",
          ],
        },
        funding: {
          text: "To ensure that we provide free, impactful solutions that enhances understanding of social issues and enagement in community focused opportunities that drive positive impact, donations towards this program will help us:",
          details: [
            "Build, maintain, and expand our data and network infrastructure to reach our goal of making a vast amount of philanthropic resources avalaible to the general public.",
            "Provide our platform and services for free, forever so that individuals and community based organizations operating in and serving underrepresented, impoverished, low income, and at-risk communities.",
            "Cover expenses related to growing our workforce; developing, maintaining, and scaling our community focused technological and data solutions and efforts, data hosting and storage, and other technological and networking costs discovered throughout development.",
          ],
          funding_goals: [
            {
              key: 0,
              link: "/",
              number_objective: 200000,
              objective_description: "Funding Goal",
            },
          ],
        },
        impact_areas: [
          {
            title: "Access to Community Resources",
            image: "/images/community_access.jpg",
            description:
              "Clarintel's programs empower individuals to drive a positive impact by simplifying access to credible resources that effectively meet and address the needs of the community. We create the enviornment for community members to easily and confidently track, manage, and understand all aspects of philanthropy while promoting and responding to social issues active within their communities and beyond.",
            // 'Clarintel centralize community based organizations and opportunities operating in and serving impoverished and low income communities to reduce the time, cost, and effort associated with driving a postive impact. Our programs empower individuals by dramaticaly simplifying access to credible resources that drive a positve impact.We create the enviornment for community members to easily and confidently track, manage, and understand all aspects of philanthropy while promoting and responding to social issues active within their communities and beyond.
          },
          {
            title: "Nonprofit Access to Data, Information, & Technology",
            image: "/images/nonprofit_data_access.jpg",
            description:
              "Clarintel equips organizations with the tools, data, and information needed to drive a positive impact on a community, local, and national level. Clarintel's solutions help nonprofits to respond to social issues prevalent in communities around the world within the environment built to drive positive impact.",
            // 'Clarintel equips community members and organizations with the tools, data, and information needed to drive a positive impact on a community, local, and national level. Clarintel collects and social issues prevalent in communities around the world within the environment built to drive positive impact.',
          },
          {
            title: "Social Outcomes & Quality of Public Service",
            image: "/images/social_outcomes.jpg",
            description:
              "Clarintel's programs amplify social outcomes and increase the quality of public service through community level, data driven solutions. For each program, Clarintel works closely with communities and organizations accross multiple social issues and causes to ensure that we continously enhance community driven endevours towards the elimination of social issues.",
            // 'Clarintel\'s programs streamline community engagement to amplify positive social outcomes and to increase the quality of public service through community level, data driven solutions. For change agents, impact makers, activists, and community based organizations, Clarintel provides data focused tools and solutions catered to their needs for enhancing the understanidng of and engagement in opportunities that drive a positive impact. Through each program, Clarintel collaborates with communities and organizations accross multiple social issues and causes to ensure that our solutions and endevours continously enhance community driven endevours towards the elimination of social issues.',
          },
        ],
      },
      {
        id: 2,
        image: "/images/volunteer.jpg",
        link: "/opportunities/nonprofit-fair",
        title: "Community Unity & Capacity Building",
        tagline:
          "We connect impact makers with the organizations and opportunities that drive a positive impact within their community and beyond.",
        snippet:
          "Our initatives connect impact makers with the organizations and opportunities driving a positive impact within their community and beyond. These programs dramatically reduce the amount of time, effort, and cost spent searching for ways to create a better world.",
        about:
          "Our Community Unity & Capacity Building Programs connect impact makers and change agents with the opportunities and community based organizations serving the needs and interests of individuals impacted by community level social issues. Through Clarintel, impact makers can find and build relationships with organization they can trust to drive a sustainable, measurable, positive impact. Likewise, we provide the opportunity for community organizations to strengthen their community presence while attracting new donors, volunteers, supporters, and employee prospects. We are in the process of planning our first Community Unity & Capacity Building event to serve impoverished to low income communities and are currently partnering with community based organizations looking to connect with impact makers on a personal level throughout the DC, Maryland, and Virginia area. To learn more about this program, or if your're interested in getting involved, feel free to reach out! To stay up to date, follow us on social media @clarintel.",

        type: "event",
        event_type: 1,
        date: "TBA",
        location: "TBA",
        start_time: "",
        end_time: "",
        impact_intro:
          "To ensure that we bridge the gaps between communities and community leaders to drive and amplify positive impact, our programs cover the below impact areas.",
        philopics: [
          "All Ages",
          "Community Engagement",
          // '',
          "Grassroot Nonprofits",
          "Small & Medium Sized Nonprofits",
          "Job Opportunities",
          "Volunteer Opportunities",
        ],
        goals: {
          text: "We rely on communty members like you to hold us accountable to providing quality events and opportunities that bridge the gaps between communities and community leaders. In addition, Clarintel aims to accomplish the below goals:",
          details: [
            "Connect 150 nonprofits with at least 1,000 community members at our Innagural Community Unity and Capacity Building Event in order to increase the amount of community enegagement within the DC, Maryalnd, and Viriginia areas.",
            "Provide free marketing and awareness campaigns for the community based organizations attending our Community Unity & Capacity Building Events including their active philanthropic opportunities so that philanthropic organizations have easier access to the impact makers and change agents driving a positive impact.",
            "Provide one centralized location Community Based Organizations and nonprofit attendees to connect with impact makers and receive support, donation, employee prospects, opportunities, partnerships, and volunteers so that nonprofits are better equipped to accomplish their missions.",
          ],
        },
        attendee_registrations: {
          text: "Clarintel's Nonprofit Volunteer and Career Fair inspires communities to find, engage, and connect with the organizations, nonprofits, and opportunities driving a positive impact within their communities and beyond. This event is FREE AND OPEN to the public. Attendee registration includes:",
          details: [
            "Open to individuals of all ages.",
            "Access to 150 community and local level nonprofits and community based organizations.",
            "Free gifts for all attendees.",
            "Refreshments provided while supplies last.",
          ],
          attendee_goals: [
            {
              key: 0,
              link: "/",
              number_objective: 500,
              objective_description: "Community Members",
            },
          ],
        },
        funding: {
          text: "To ensure that we continously provide free opportunities that bridges the gaps between communities and community leaders to drive a measurable, sustainable positive impact, donations towards this initiative will help us to:",
          details: [
            "Donate to nonprofits and community based organizations that attend our events",
            "Provide nonprofits and community based organizations with free QR codes and beta access to the Social Center App for simplifying the impact making process",
            "Enhance community access to nonprofit information and opportunities through the Clarintel website.",
            "Cover all expenses related to the planning, hosting, management, and execution of the event including, but not limited to: venue booking for hosting 150 community based organizations and 1,000+ community members, event staffing, set-up, marketing, and other fees related to the event experience.",
          ],
          funding_goals: [
            {
              key: 0,
              link: "/",
              number_objective: 15000,
              objective_description: "Funding Goal",
            },
          ],
        },
        // volunteering: "We are actively searching for and recruiting talented individuals to help us serve our communities and community leaders. Volunteers will help drive our mission through hands-on activities that build leadership, management, and technical skills applicable to solving real world challenges. If you're interested in changing the world, feel free to reach out. We accept volunteers of all ages!",
        // location_details: "Clarintel is currently raising $5,000 to secure the The Adele H. Stamp Student Union at the University of Maryland College Park. With your help, we will host the first Nonprofit and Career Vounteer Fair in October, 2023 to serve nonprofits and community members in impoverished, low income, and under-reprsented communities in DC, Maryland, and Virginia.",
        // sponsorship: {
        //     text: "Clarintel offers sponsorship packages to both nonprofits and startups/small businesses. The sponsorship packages consist of three (3) tiers for nonprofits, and one (1) tier for startups/small businesses. In addition to registrations, nonprofits can purchase these packages to increase their visibility and engagement at the Nonprofit Career & Volunteer Fair.",
        //     sponsor_goals: [
        //         {
        //           key: 0, link: "/",
        //           number_objective: 75,
        //           objective_description: "Nonprofits & Community Based Sponsors"
        //         },
        //         {
        //             key: 1, link: "/",
        //             number_objective: 100,
        //             objective_description: "Small Business & Startup Sponsors"
        //           },

        //       ],
        //     details: {

        //         nonprofit_packages:
        //         {
        //             title: "Nonprofit Sponsorship Packages",
        //             details: [
        //                 {
        //                     title:"Platinum",
        //                     price:500,
        //                     description:"The platinum package includes: the opportunity for prime booth location at the fair, front page promotion on Clarintel website, enhanced visibility, signage, and balloons at your booth for the duration of the fair, recognition as a Platinum sponsor and the inclusion of your logo in the printed fair booklet, and the opportunity to be recognized as a Platinum Sponsor on signs throughout the public registration and non-fair areas during the fair.",
        //                 },
        //                 {
        //                     title:"Gold",
        //                     price:350,
        //                     description:"The gold package includes: enhanced signage and balloons at your fair booth and recognition as a Gold Sponsor on printed fair booklet and website.",
        //                 },
        //                 {
        //                     title:"Silver",
        //                     price:0,
        //                     description:"For nonprofit registrants, the silver package allows you to donate an unlimited amount of branded items with your organization’s logo to enhance the attendee experience at the fair. Items may include, but are not limited to: bags, pencils and pens, hand sanitizer, masks, t-shirts, USB drives, socks, and chargers.",
        //                 },
        //             ],
        //         },
        //         no_presence: {
        //             title: "No Presence Sponsorship Package - Open to Small Businesses & Startups",
        //             details: [
        //                 {
        //                     title:"No Presence Package",
        //                     price:100,
        //                     description:"Small Businesses and Startups can purchase a No-Show Sponsorship package for $100. This package allows startups and small businesses to donate at most 150 branded items to Clarintel for enhancing the volunteer experience at the fair. Items accepted for donation may include, but are not limited to: bags, pencils and pens, hand sanitizer, masks, t-shirts, USB drives, socks, and chargers. For this option, presence at the Nonprofit Career & Volunteer fair is not required or included.",
        //                 },
        //             ],
        //         }
        //     },
        // },

        impact_areas: [
          // {
          //     image:"/images/capacity_building.jpg",
          //     title: "Nonprofit Capacity Building",
          //     description: "Clarintel's nonprofit capacity building programs provide community leaders with the ability to save money, increase their operational efficiency, and amplify their positive impact through free marketing and fundraising opportunities. Through our events, Clarintel helps nonprofits met and exceed their funding goals by raising donation for nonprofit partners and event registants. Clarintel also provides free marketing for nonprofit organizations."
          // },
          {
            title: "Advocacy & Awareness Raising",
            image: "/images/advocacy.jpg",
            description:
              "Clarintel's programs help individuals build a firm foundation that encourages knowledgable, sustainable, transparent philanthropy. Community advocacy and engagement are the cornerstone to building strong, healthy, caring communities who understand the power of positive impact, which is the key to human equity.",
            // "Clarintel's programs empower individuals to personally respond to and promote the social causes and issues they experience on a daily basis while utilizing support from community leaders driving a postive impact. With programs focused on amplifying the visibility of community level social issues and solutions, Clarintel helps individuals build a firm foundation that encourages knowledgable, sustainable, transparent philanthropy. Community advocacy and engagement are the cornerstone to building strong, healthy, caring communities who understand the power of positive impact, which is the key to human equity.",
          },
          {
            title: "Socio-Economic Inequality",
            image: "/images/socioeconomic.jpg",
            description:
              "Clarintel programs help people access to employment and volunteer opportunities that transform communities while providing real world experience through social innovation. We collaboarte with nonprofits to provide communities with opportunities for economic stability and career longevity that address the needs of their communities and beyond.",
            // 'Clarintel provides individuals with access to employment and volunteer opportunities that transform communities while providing real world experience through social innovation. By prioritizing the positive impact, Clarintel provides individuals with first hand access to opportunities that not only increase economic stability and career longevity, but increases the health and welfare of their communities and beyond.',
          },
          {
            title: "Access to Community Resources",
            image: "/images/community_access.jpg",
            description:
              "Clarintel's programs empower individuals to drive a positive impact by simplifying access to credible resources that effectively meet and address the needs of the community. We create the enviornment for community members to easily and confidently track, manage, and understand all aspects of philanthropy while promoting and responding to social issues active within their communities and beyond.",
            // 'Clarintel centralize community based organizations and opportunities operating in and serving impoverished and low income communities to reduce the time, cost, and effort associated with driving a postive impact. Our programs empower individuals by dramaticaly simplifying access to credible resources that drive a positve impact.We create the enviornment for community members to easily and confidently track, manage, and understand all aspects of philanthropy while promoting and responding to social issues active within their communities and beyond.',
          },
        ],
      },
    ],
  },
  event_page: {
    tagline:
      "Discover nonprofits and community based organizations like never before at the event created for the community, by the community.",
    image: "/images/stamp2.jpg",
    title: "Clarintel Care Fair",
  },
  programs_page: {
    snippet:
      "Clarintel programs empower communities and community leaders to create a measurable, sustainable, positive impact.",
    image: "/images/program-landing.jpg",
    title: "Clarity and intelligence, every step of the way.",
    title_text:
      "End to end community focused solutions for nonprofits, community leaders, and change agents.",
    quote: {
      content:
        "Recent studies have shown that 55% of nonprofits say their organization needs to invest in technology in order to increase fundraising, and 60% say their donors expect a better experience than their current technology provides. ",
      source: "Salesforce",
      source_link:
        "https://www.salesforce.org/resources/report/nonprofit-trends-report-fifth-edition/",
    },
    // intro: 'Clarintel\'s impact driven, data focused programs make it easy to discover, understand, and engage with the organizations and opportunities built to respond to and meet the growing demands for the elimination of community focused social issues. Our programs dramatically reduce the time, money, and effort taken to access easily understandable community based resorces and engagement opportunities from credible organizations. With each endevour, Clarintel ensures that you have the tools and resources needed to understand the social issues impacting your community, to discover and cofnfidently engage with the credible organizations and opportunities responding to community needs and interests, and to easily recognize the impact that your actions have on your community and beyond. Through Clarintel, gaining community support and buy-in for opportunities that drive a positive impact has never been easier.',
    intro:
      "Clarintel's impact driven, data focused programs make it easy to discover, understand, and engage with the organizations and opportunities built to respond to and meet the growing demands for the elimination of community focused social issues. Our programs dramatically reduce the time, money, and effort taken to access easily understandable community based resorces and engagement opportunities from credible organizations. Through Clarintel, gaining community support and buy-in for opportunities that drive a positive impact has never been easier.",
    subtitle: "Clarintel Program Goals & Objectives",
    subtitle_text:
      "Our programs are built on the belief that the most effective way to drive positive impact is through the continuous development of technologies that adapt to active social issues, public sentiment, and organizational capabilities on a local and community level. As a result, our programs and services will continue to grow, expand, and adjust to effectively cater to nonprofit and community needs. We intend for all solutions, offerings, and tools provided to nonprofits and community members to remain free, forever.",
    subtitle_text2:
      "Clarintel serves as a central location for organizations, community members, change agents, and impact makers to find the support, opportunities, and allies needed to drive a positive impact within their community and beyond. Our technology, programs, and services serve individuals of all ages, and are open to all organizations generating less than $1,000,000 in reported revenue that operate in and serve impoverished, low income, and underrepresented communities.",
    objectives: [
      {
        id: 0,
        goal: "$1.4M",
        goal_text: "Raised in Total Funding to Build a Better Future by 2025.",
      },
      {
        id: 1,
        goal: "9K",
        goal_text: "Impacted Community Members by 2025.",
      },
      {
        id: 2,
        goal: "450",
        goal_text:
          "Assisted Nonprofit and Community Based Organization by 2025.",
      },
    ],
  },
  program_areas: [
    {
      id: 0,
      link: "/programs/air",
      program_area: "A.I.R. Program",
      program_snippet:
        "Clarintel’s Apprenticeship & Internship Rotational (A.I.R.) Program unites high school and college/university students with real world experiences that build the next generation of impact driven leaders dedicated to positive impact.",
      // program_description: "Clarintel's community engagement programs focus on bringing the community to the community leaders driving posoitive impact in underrepresented, low income, and impoverished communities. We empower communities and community leaders through with the opportunity to connect with impact makers, change agents, donors, volunteers, employee prospects, and supporters like you!",
      program_description:
        "Clarintel’s Apprenticeship & Internship Rotational (A.I.R.) Programs unites educational knowledge with real world experiences that create a sustainable, positive impact and transform impoverished to low income communities. AIR programs are designed to increase the impact of, and overall engagement in, social impact through community focused solutions created in partnership with the youth experiencing social issues on a daily basis.",
      impact_intro:
        "Our programs inspire impact makers to support and strengthen nonprofits responding to the growing needs of impoverished, low income, and underrepresented communities. To ensure that we drive a sustainable, positive impact, our programs cover the below impact areas.",
      opportunity_ids: [0],
      impact_areas: [
        {
          image: "/images/capacity_building.jpg",
          title: "Nonprofit Capacity Building",
          description:
            "Clarintel's programs provide community leaders with the ability to save money while increasing  operational efficiency, and amplifying their positive impact. We help nonprofits met and exceed their funding goals by raising donations for nonprofit partners and event registants and by offering free marketing services for nonprofits.",
        },
        {
          title: "Socio-Economic Inequality",
          image: "/images/socioeconomic.jpg",
          description:
            "Clarintel programs help people access to employment and volunteer opportunities that transform communities while providing real world experience through social innovation. We collaboarte with nonprofits to provide communities with opportunities for economic stability and career longevity that address the needs of their communities and beyond.",
          // 'Clarintel provides individuals with access to employment and volunteer opportunities that transform communities while providing real world experience through social innovation. By prioritizing the positive impact, Clarintel provides individuals with first hand access to opportunities that not only increase economic stability and career longevity, but increases the health and welfare of their communities and beyond.',
        },
        {
          title: "Access to Community Resources",
          image: "/images/community_access.jpg",
          description:
            "Clarintel's programs empower individuals to drive a positive impact by simplifying access to credible resources that effectively meet and address the needs of the community. We create the enviornment for community members to easily and confidently track, manage, and understand all aspects of philanthropy while promoting and responding to social issues active within their communities and beyond.",
          // 'Clarintel centralize community based organizations and opportunities operating in and serving impoverished and low income communities to reduce the time, cost, and effort associated with driving a postive impact. Our programs empower individuals by dramaticaly simplifying access to credible resources that drive a positve impact.We create the enviornment for community members to easily and confidently track, manage, and understand all aspects of philanthropy while promoting and responding to social issues active within their communities and beyond.',
        },
      ],
    },
    {
      id: 1,
      link: "/programs/philanthropic-access",
      program_area: "Philanthropic Access",
      program_snippet:
        "Clarintel simplifies philanthropy and makes achieving a measurable, sustainable positve impact easy, understandable, and accessible to everyone, everywhere, no matter what.",
      program_description:
        "Our programs amplify the community's understanding of social issues and engagement in social action by providing you with the technological solutions that simplify and streamline access to philanthropic and social issue focused data, information, & opportunities.",
      impact_intro:
        "Our programs enable community members to easily access the information needed to validate, trust, and enage with community based organizations and philanthropic oppportunities that drive positive impact. To ensure that we provide credible data and information that increases philanthropic and social issue understanding, community engagement,and nonprofit efficeincy, our programs cover the below impact areas.",
      opportunity_ids: [1, 2, 3],

      // program: [
      //     {
      //         title: "Data Aggregation, Organization, Publication, & API Access",
      //     },
      //     {
      //         title: "Clarintel App & Web Platform",
      //     },
      //     {
      //         title: "Free Philanthropic Data Analysis, Visualization, and Reporting Tools",
      //         description: "",
      //     },
      //     {
      //         title: "Free Artificial Intelligence Tools including Model Building, Training, and Publication",
      //     },
      // ],
      impact_areas: [
        {
          title: "Access to Community Resources",
          image: "/images/community_access.jpg",
          description:
            "Clarintel's programs empower individuals to drive a positive impact by simplifying access to credible resources that effectively meet and address the needs of the community. We create the enviornment for community members to easily and confidently track, manage, and understand all aspects of philanthropy while promoting and responding to social issues active within their communities and beyond.",
          // 'Clarintel centralize community based organizations and opportunities operating in and serving impoverished and low income communities to reduce the time, cost, and effort associated with driving a postive impact. Our programs empower individuals by dramaticaly simplifying access to credible resources that drive a positve impact.We create the enviornment for community members to easily and confidently track, manage, and understand all aspects of philanthropy while promoting and responding to social issues active within their communities and beyond.',
        },
        {
          title: "Nonprofit Access to Data, Information, & Technology",
          image: "/images/nonprofit_data_access.jpg",
          description:
            "Clarintel equips nonprofits with the tools, data, and information needed to drive a positive impact on a community, local, and national level. Clarintel collects public information about nonprofits and philanthropy, and create free solutions to problems currently faced by nonprofits.",
        },
        {
          title: "Social Outcomes & Quality of Public Service",
          image: "/images/social_outcomes.jpg",
          description:
            "Clarintel's programs amplify social outcomes and increase the quality of public service through community level, data driven solutions. For each program, Clarintel works closely with communities and organizations accross multiple social issues and causes to ensure that we continously enhance community driven endevours towards the elimination of social issues.",
          // 'Clarintel\'s programs streamline community engagement to amplify positive social outcomes and to increase the quality of public service through community level, data driven solutions. For change agents, impact makers, activists, and community based organizations, Clarintel provides data focused tools and solutions catered to their needs for enhancing the understanidng of and engagement in opportunities that drive a positive impact. Through each program, Clarintel collaborates with communities and organizations accross multiple social issues and causes to ensure that our solutions and endevours continously enhance community driven endevours towards the elimination of social issues.',
        },
      ],
    },
    {
      id: 2,
      link: "/programs/unity",
      program_area: "Community Unity & Capacity Building",
      program_snippet:
        "Our programs connect impact makers with the community based organizations and opportunities driving a positive impact within their community and beyond. These programs dramatically reduce the amount of time, effort, and cost spent searching for ways to create a better world.",
      program_description:
        "This program is designed to connect impact makers and community members with the community based organizations serving the needs and interests of their communities. Individuals can find and build relationships with organization they can trust to drive a sustainable, measurable, positive impact. Likewise, community organizations will have the opportunity to strengthen their community presence while attracting new donors, volunteers, supporters, and employee prospects that dramatically enhance their ability to accomplish their missions.",
      impact_intro:
        "Our programs enable community members to hold Clarintel and our connected partners accountable to our positive impact. Our reporting will aslo make it easy to see the results of your community engagement and positive impact. To ensure that our reports revlolutionize social impact and philanthropy, our programs cover the below impact areas.",
      program: [
        //     {
        //         title: "Community Member Surveying & Reporting",
        //     },
        //     {
        //         title: "Nonprofit Surveying & Reporting",
        //     },
      ],
      opportunity_ids: [],
      impact_areas: [
        {
          title: "Social Outcomes & Quality of Public Service",
          image: "/images/social_outcomes.jpg",
          description:
            "Clarintel's programs amplify social outcomes and increase the quality of public service through community level, data driven solutions. For each program, Clarintel works closely with communities and organizations accross multiple social issues and causes to ensure that we continously enhance community driven endevours towards the elimination of social issues.",
          // 'Clarintel\'s programs streamline community engagement to amplify positive social outcomes and to increase the quality of public service through community level, data driven solutions. For change agents, impact makers, activists, and community based organizations, Clarintel provides data focused tools and solutions catered to their needs for enhancing the understanidng of and engagement in opportunities that drive a positive impact. Through each program, Clarintel collaborates with communities and organizations accross multiple social issues and causes to ensure that our solutions and endevours continously enhance community driven endevours towards the elimination of social issues.',
        },
        {
          title: "Nonprofit Accountabiliy",
          image: "/images/accountability.jpg",
          description:
            "Clarintel programs hold nonprofits accountable to their missions, goals, objectives, and most imporantly, positive impact. Clarintel continuously surveys nonprofits and change agents like you to ensure that your actions generate a measurable, sustainalbe, positive impact.",
        },
        {
          title: "Misinformation",
          image: "/images/misinformation.jpg",
          description:
            "Clarintel directors and staff perform a thorough validation process on all information collected from public sources to verify the credibility of the data and information provided to individuals and nonprofits. In addition, Clarintel works with nonprofits to validate their information, impact, financials, and credibility prior to partnership and event admission.",
        },
      ],
    },
  ],
};
