import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme
} from "@mui/material/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/element/css/autoplay";
// import required modules
import SwiperCore from "swiper";
import { Autoplay, Navigation, Scrollbar } from "swiper/modules";
import "./index.css";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
export default function Carousel({flyer_data,isLeaders}) {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  const { width, height } = useWindowDimensions();
  let theme = createTheme();
  SwiperCore.use([Autoplay]);
  SwiperCore.use([Scrollbar]);
  SwiperCore.use([Navigation]);

  theme.typography.subtitle1 = {
    marginTop: "1rem",
    display:"block",
    textAlign:"left",
    fontWeight:"500",
    color: "#000",
    fontFamily: "Changa",
    // "@media (max-width: 1077.8443113772px)": {
    //   fontSize: "2vw",
    // },
    // "@media screen and (max-width: 900px)": {
    //   marginLeft: "0",
    //   marginTop: "16px",
    // },
  }
  theme.typography.subtitle2 = {
    marginTop: "1rem",
    display:"block",
    textAlign:"left",
    fontWeight:"500",
    color: "#000",
    fontFamily: "Changa",
    // "@media (max-width: 1077.8443113772px)": {
    //   fontSize: "2vw",
    // },
    // "@media screen and (max-width: 900px)": {
    //   marginLeft: "0",
    //   marginTop: "16px",
    // },
  }
  

  return (
    <div style={{ width:"100%" }}>
        <ThemeProvider theme={theme}>
      <swiper-container
        // install Swiper modules
        // modules={[Navigation, Pagination, Scrollbar, A11y]}
        className="mySwiper"
        ref={sliderRef}
        style={{
          // width: "100%",
          
          "--swiper-navigation-size": "15px",
          // marginLeft: "calc((var(--client-width) - 100%) / 2 * -1)",
        }}
        // centeredSlides={true}
        slides-per-view={"auto"}
        space-between={ 25}
        // effect={'coverflow'}
        rewind="true"
        loop-add-blank-slides="false"
        loop="false"
        follow-finger="true"
        // centered-slides="true"
        free-mode = {{
          enabled: "true",
          sticky: "true",
        }}
        scrollbar="false"
        // draggable="true"
        speed="10000"
        edge-swipe-threshold="0"
        observer="true"
        resistance="false"
        // lazy-preload-prev-next={3}
        no-swiping="false"
        touch-release-on-edges="true"
        simulate-touch="true"
        loop-prevents-sliding="false"
        touch-events-target="wrapper"
        allow-touch-move="true"
        // lazyPreloaderClass="blah"
        // breakpoints= {{
        //   // when window width is >= 320px
        //   320: {
        //     slidesPerView: "2",
        //     spaceBetween: 30
        //   },
        //   // when window width is >= 480px
        //   480: {
        //     slidesPerView: "2",
        //     spaceBetween: 30
        //   },
        //   // when window width is >= 640px
        //   640: {
        //     slidesPerView: "2",
        //     spaceBetween: 30
        //   }}}
        // autoplay="true" autoplay-wait-for-transition="true" 
        // autoplay-delay="1" autoplay-disable-on-interaction="false"
          // navigation= {{
          //   enabled:true,
          //   // nextEl:'.next-arrow',
          //   // prevEl:'.prev-arrow',
          //   clickable: true, 
          // }}
        modules={[Autoplay]}
        // pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        
      >
        {/* <div style={{ }}> */}
          {flyer_data.map((card, index) => (
            <swiper-slide class="card" key={index}>
              {/* <div className="slide__image-wrapper">

                <img className="slide__image" alt={card.title} src={card.src}  />
                </div>
                <article className="slide__content">
                <h2 className="slide__headline">{card.title}</h2>

          <h2 className="slide__headline">{card.description}</h2>
          <button className="slide__action btn">{card.button}</button>
            </article> */}

              <article className="work__card ">
                <div className="card__container">
                  <div className="container__first-image">
                    <figure className="z-1">
                      <picture>
                        <source
                          data-srcset="data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27374%27 height=%27498%27 style=%27background:%23CCC%27 /%3E"
                          data-sizes="100vw"
                          type="image/webp"
                          srcset="data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27374%27 height=%27498%27 style=%27background:%23CCC%27 /%3E"
                        />
                        <img style={{aspectRatio:"3/4",objectFit:"cover"}}
                          className="fade-in ls-is-cached " //lazyloaded
                          data-srcset={card.image}
                        //   data-srcset="https://assets.bolden.nl/eyJidWNrZXQiOiJhc3NldHMuYm9sZGVuLm5sIiwia2V5IjoidXBsb2Fkcy90ZWFtLXBob3Rvcy91ZnVrLWNldGluY2FuXzIwMjMtMDctMTMtMTUxOTE3X2FmYWYuanBnIiwiZWRpdHMiOnsianBlZyI6eyJxdWFsaXR5Ijo4NSwicHJvZ3Jlc3NpdmUiOnRydWUsInRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pemVTY2FucyI6dHJ1ZX0sInJlc2l6ZSI6eyJ3aWR0aCI6Mzc0LCJoZWlnaHQiOjQ5OCwiZml0IjoiY292ZXIifX19 374w, https://assets.bolden.nl/eyJidWNrZXQiOiJhc3NldHMuYm9sZGVuLm5sIiwia2V5IjoidXBsb2Fkcy90ZWFtLXBob3Rvcy91ZnVrLWNldGluY2FuXzIwMjMtMDctMTMtMTUxOTE3X2FmYWYuanBnIiwiZWRpdHMiOnsianBlZyI6eyJxdWFsaXR5Ijo2MywicHJvZ3Jlc3NpdmUiOnRydWUsInRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pemVTY2FucyI6dHJ1ZX0sInJlc2l6ZSI6eyJ3aWR0aCI6NzQ4LCJoZWlnaHQiOjk5NywiZml0IjoiY292ZXIifX19 748w, https://assets.bolden.nl/eyJidWNrZXQiOiJhc3NldHMuYm9sZGVuLm5sIiwia2V5IjoidXBsb2Fkcy90ZWFtLXBob3Rvcy91ZnVrLWNldGluY2FuXzIwMjMtMDctMTMtMTUxOTE3X2FmYWYuanBnIiwiZWRpdHMiOnsianBlZyI6eyJxdWFsaXR5Ijo0MiwicHJvZ3Jlc3NpdmUiOnRydWUsInRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pemVTY2FucyI6dHJ1ZX0sInJlc2l6ZSI6eyJ3aWR0aCI6MTEyMiwiaGVpZ2h0IjoxNDk2LCJmaXQiOiJjb3ZlciJ9fX0= 1122w"
                          data-sizes="100vw"
                          alt="Ufuk cetincan"
                          width="2126"
                          height="2834"
                          sizes="100vw"
                          srcSet={card.image}
                        //   srcset="https://assets.bolden.nl/eyJidWNrZXQiOiJhc3NldHMuYm9sZGVuLm5sIiwia2V5IjoidXBsb2Fkcy90ZWFtLXBob3Rvcy91ZnVrLWNldGluY2FuXzIwMjMtMDctMTMtMTUxOTE3X2FmYWYuanBnIiwiZWRpdHMiOnsianBlZyI6eyJxdWFsaXR5Ijo4NSwicHJvZ3Jlc3NpdmUiOnRydWUsInRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pemVTY2FucyI6dHJ1ZX0sInJlc2l6ZSI6eyJ3aWR0aCI6Mzc0LCJoZWlnaHQiOjQ5OCwiZml0IjoiY292ZXIifX19 374w, https://assets.bolden.nl/eyJidWNrZXQiOiJhc3NldHMuYm9sZGVuLm5sIiwia2V5IjoidXBsb2Fkcy90ZWFtLXBob3Rvcy91ZnVrLWNldGluY2FuXzIwMjMtMDctMTMtMTUxOTE3X2FmYWYuanBnIiwiZWRpdHMiOnsianBlZyI6eyJxdWFsaXR5Ijo2MywicHJvZ3Jlc3NpdmUiOnRydWUsInRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pemVTY2FucyI6dHJ1ZX0sInJlc2l6ZSI6eyJ3aWR0aCI6NzQ4LCJoZWlnaHQiOjk5NywiZml0IjoiY292ZXIifX19 748w, https://assets.bolden.nl/eyJidWNrZXQiOiJhc3NldHMuYm9sZGVuLm5sIiwia2V5IjoidXBsb2Fkcy90ZWFtLXBob3Rvcy91ZnVrLWNldGluY2FuXzIwMjMtMDctMTMtMTUxOTE3X2FmYWYuanBnIiwiZWRpdHMiOnsianBlZyI6eyJxdWFsaXR5Ijo0MiwicHJvZ3Jlc3NpdmUiOnRydWUsInRyZWxsaXNRdWFudGlzYXRpb24iOnRydWUsIm92ZXJzaG9vdERlcmluZ2luZyI6dHJ1ZSwib3B0aW1pemVTY2FucyI6dHJ1ZX0sInJlc2l6ZSI6eyJ3aWR0aCI6MTEyMiwiaGVpZ2h0IjoxNDk2LCJmaXQiOiJjb3ZlciJ9fX0= 1122w"
                        />
                      </picture>
                    </figure>
                  </div>
                  <div className="container__hover-image">
                    <figure className="ma0 pa0 relative z-1">
                      <picture>
                        <source
                          data-srcset="data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27374%27 height=%27498%27 style=%27background:%23CCC%27 /%3E"
                          data-sizes="100vw"
                          type="image/webp"
                          srcset="data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27374%27 height=%27498%27 style=%27background:%23CCC%27 /%3E"
                        />
                        <img style={{aspectRatio:"3/4",objectFit:"cover"}}
                          className="fade-in ls-is-cached " //lazyloaded
                          data-srcset={card.image}
                          data-sizes="100vw"
                          alt="UFUK DARK"
                          width="2480"
                          height="3508"
                          sizes="100vw"
                          srcSet={card.image}
                        //   srcset="https://assets.bolden.nl/eyJidWNrZXQiOiJhc3NldHMuYm9sZGVuLm5sIiwia2V5IjoidXBsb2Fkcy90ZWFtLXBob3Rvcy9VRlVLX0RBUksucG5nIiwiZWRpdHMiOnsicG5nIjp7InF1YWxpdHkiOjg1LCJwcm9ncmVzc2l2ZSI6dHJ1ZX0sInJlc2l6ZSI6eyJ3aWR0aCI6Mzc0LCJoZWlnaHQiOjQ5OCwiZml0IjoiY292ZXIifX19 374w, https://assets.bolden.nl/eyJidWNrZXQiOiJhc3NldHMuYm9sZGVuLm5sIiwia2V5IjoidXBsb2Fkcy90ZWFtLXBob3Rvcy9VRlVLX0RBUksucG5nIiwiZWRpdHMiOnsicG5nIjp7InF1YWxpdHkiOjYzLCJwcm9ncmVzc2l2ZSI6dHJ1ZX0sInJlc2l6ZSI6eyJ3aWR0aCI6NzQ4LCJoZWlnaHQiOjk5NywiZml0IjoiY292ZXIifX19 748w, https://assets.bolden.nl/eyJidWNrZXQiOiJhc3NldHMuYm9sZGVuLm5sIiwia2V5IjoidXBsb2Fkcy90ZWFtLXBob3Rvcy9VRlVLX0RBUksucG5nIiwiZWRpdHMiOnsicG5nIjp7InF1YWxpdHkiOjQyLCJwcm9ncmVzc2l2ZSI6dHJ1ZX0sInJlc2l6ZSI6eyJ3aWR0aCI6MTEyMiwiaGVpZ2h0IjoxNDk2LCJmaXQiOiJjb3ZlciJ9fX0= 1122w"
                        />
                      </picture>
                    </figure>
                    <Grid className="text-container2" sx={{display:"flex", overflowY:"auto", flexDirection:"column",position:"absolute",zIndex:1,top:0,background:"rgba(0,0,0,.5)", height:"100%",width:"100%", padding:"1.14vw",justifyContent:isLeaders?"flex-end":"unset", paddingBottom:isLeaders?"64px":"auto"
                // "@media screen and (min-width: 60em)": {
                //     position:"relative", color:"#000", top:"30px"
                //   }
                  }}>
              {/* <Typography variant="subtitle1" sx={{marginRight:".5rem", color:"#FFF"}}>
                Name
                

              </Typography>
              <Typography variant="subtitle1" sx={{color:"#FFF",fontWeight:500}}>
                    Title
                </Typography> */}
                 <Typography variant="subtitle1" sx={{display:card.organization? "block":"none",color:"#FFF", fontStyle:"italic",  fontSize: { xs: "1rem", sm: "1.1rem", md: "1.3rem", lg: "1.3rem"},
                            lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.3rem", lg: "1.3rem"},}}>
                    AIR Program Participant since 2023
                </Typography>
                <Typography variant="subtitle1" sx={{display:card.organization? "block":"none", color:"#FFF", fontWeight:"700", fontSize: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},
                            lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},}}>
                    Organization:
                </Typography>
                <Typography variant="subtitle1" sx={{ display:card.organization? "block":"none",color:"#FFF", fontWeight:"300", margin:0  , fontSize: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},
                            lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.3rem", lg: "1.3rem"},}}>
                     {card.organization}
                </Typography>
                <Typography variant="subtitle1" sx={{ display:card.occupation? "block":"none", color:"#FFF", fontWeight:"700", fontSize: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},
                            lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},}}>
                    Occupation:
                </Typography>
                <Typography variant="subtitle1" sx={{ display:card.occupation? "block":"none",color:"#FFF",fontWeight:"300", margin:0  ,fontSize: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},
                            lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.3rem", lg: "1.3rem"},}}>
                    {card.occupation}
                </Typography>
                <Typography variant="subtitle1" sx={{color:"#FFF", fontWeight:"700", fontSize: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},
                            lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},}}>
                    Bio
                </Typography>
                 <Typography variant="subtitle1" sx={{color:"#FFF",fontWeight:"300", margin:0  , fontSize: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},
                            lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.3rem", lg: "1.3rem"},}}>
                    {card.bio}
                </Typography>
                
                <Typography variant="subtitle1" sx={{color:"#FFF", display:card.quote? "block":"none", fontWeight:"700", fontSize: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},
                            lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},}}>
                    What does philanthropy mean to you?
                </Typography>
                 <Typography variant="subtitle1" sx={{display:card.quote? "block":"none",color:"#FFF",fontWeight:"300", margin:0  , fontSize: { xs: "1rem", sm: "1.1rem", md: "1.1rem", lg: "1.3rem"},
                            lineHeight: { xs: "1rem", sm: "1.1rem", md: "1.3rem", lg: "1.3rem"},}}>
                    {card.quote}
                </Typography>
              </Grid>
                  </div>
                </div>
                
              </article>
              <Grid className="text-container" style={{display:"flex",position:"relative", paddingBottom:"50px"}}>
              <Typography variant="subtitle2" sx={{marginRight:".3rem", fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1rem", lg: "1.3rem"},
                            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},}}>
                {card.name}
                

              </Typography>
              <Typography variant="subtitle2" sx={{opacity:.3,  fontSize: { xs: "1.1rem", sm: "1.1rem", md: "1rem", lg: "1.3rem"},
                            lineHeight:   { xs: "1.3rem", sm: "1.3rem", md: "1.4rem", lg: "1.7rem"},}}>
                    {card.title}
                </Typography>
              </Grid>
            </swiper-slide>
          ))}
        {/* </div> */}
        
      </swiper-container>
      {/* <Grid sx= {{position:"absolute", width:"100%", bottom:"50px"}}>
      <div className="prev-arrow" onClick={handlePrev} />
      <div className="next-arrow" onClick={handleNext} />
      </Grid> */}
      </ThemeProvider>
      
    </div>
  );
}
