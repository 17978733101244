// GET LAST WORD OF STRING
export function GetLastWord(string) {
  const words = string.split(' ');
  return words[words.length - 1];
}
  
// GET SENTENCE WITHOUT LAST WORD
export function GetSentence(string, word) {
  let arr = string.split(' ');
  let res = '';

  arr = arr.filter(i => i !== word);
  res = arr.join(' ');

  return res;
}
