import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { CustomSubmitButton } from "../../components/FormComponents/FormComponents";
import { care_fair_data } from "../../data/data_file";

export default function DonationSharingSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  const navigate = useNavigate();

  theme.typography.subtitle1 = {
    fontWeight: 500,
    fontFamily: "Changa",
    // "@media (max-width: 1285px)": {
    //   lineHeight: "18px",

    //   fontSize: "18px !important",
    // },
    // "@media screen and (max-width: 790px)": {
    //   // lineHeight: "1.1em",
    //   marginTop: "12px",
    // },
  };
  theme.typography.h3 = {
    "@media (max-width: 900px)": {
      fontSize: "3rem",
    },
  };
  // theme.typography.h6 = {
  //   fontSize: "0.6rem",
  //   "@media (min-width:300px)": {
  //     fontSize: ".77rem",
  //   },
  //   "@media (min-width:500px)": {
  //     fontSize: "1.1rem",
  //   },
  //   [theme.breakpoints.up("md")]: {
  //     fontSize: "2.2rem",
  //   },
  //   [theme.breakpoints.up("lg")]: {
  //     fontSize: "2.9rem",
  //   },
  // };

  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        padding: "7.14vw 7.14vw 0px",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-1px",
      }}
    >
      <ThemeProvider theme={theme}>
        <Typography
          color="black"
          // variant="h3"
          sx={{
            fontFamily: "Changa",
            lineHeight: {
              xl: "3.5rem",
              lg: "3rem",
              md: "2.7rem",
              sm: "2.3rem",
              xs: "2rem",
            },
            fontWeight: 700,
            fontSize: {
              xl: "3.5rem",
              lg: "3rem",
              md: "2.7rem",
              sm: "2.5rem",
              xs: "2.3rem",
            },
          }}
        >
          {"Donation Sharing & Community Reinvestment"}
        </Typography>
        <Typography
                      color="black"
                      gutterBottom
                      variant="subtitle1"
                      sx={{
                        padding: "15px 0",
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                          // lg: "1.7rem",
                        },
                        lineHeight: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                          // lg: "1.7rem",
                        },
                      }}
                    >
                      {care_fair_data.donation_sharing_summary}
                    </Typography>
        {/* Group 1 */}
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            display: "flex",
            flexWrap: "initial",
            flexDirection: "row",
            padding:"3.14vw 0",
            // overflow: "hidden",
            height: "100%",
            "@media screen and (max-width: 900px)": {
              width: "100%",
              flexDirection: "column-reverse",
            },
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              top: "-1px",
              minHeight: "100%",
              justifyContent: "center",
              background: "#FFF",
            }}
            sx={{
              // width: "50vw",
              // "@media screen and (min-width: 769px)": {
              //   maxHeight: "50vw",
              // },
              "@media screen and (max-width: 790px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              sx={{
                minHeight: "100%",
                "@media screen and (min-width: 769px)": {
                  // height: "calc(100% - 7.14vw)",
                  // maxHeight: "calc(100% - 72px)",
                },
                "@media screen and (max-width: 900px)": {
                  // padding: "3.14vw 0",
                  padding: 0,
                },
                // padding: "3.14vw",
                paddingRight: "3.14vw",
              }}
              style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
                width: "100%",
                boxSizing: "inherit",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  minHeight: "100%",
                  "@media screen and (min-width: 900px)": {
                    // padding: "3.14vw",
                    paddingLeft: 0,
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    minHeight: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    color="black"
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      paddingTop: "25px",
                      textTransform: "uppercase",
                      fontSize: {
                        xs: "2.1em",
                        sm: "2.3rem",
                        md: "2.7rem",
                        lg: "3.2rem",
                      },
                      lineHeight: {
                        xs: "1.6rem",
                        sm: "1.8rem",
                        md: "2.2rem",
                        lg: "2.7rem",
                      },
                    }}
                  >
                    <span
                      style={{
                        color: "#62B6D0",
                      }}
                    >
                      {"25% "}
                    </span>
                    {"for Community Exhibitors"}
                  </Typography>
                 
                  <Grid>
                    <Typography
                      color="black"
                      gutterBottom
                      variant="subtitle1"
                      sx={{
                        paddingTop: "15px",
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                          // lg: "1.7rem",
                        },
                        lineHeight: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                          // lg: "1.7rem",
                        },
                      }}
                    >
                      {care_fair_data.attendee_ds}
                    </Typography>
                    <Grid sx={{ display: "flex" }}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        typography="formStyle"
                        sx={{
                          display: "flex",
                          paddingTop: "15px",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                         <Link to="https://forms.office.com/r/54GNhL0Byx"  target="_blank" style={{width:"100%"}} >
                    <CustomSubmitButton
                      sx={{
                        background:"#F1B9D0",
                        margin: 0,
                        margin:"20px 0",
                        width: "260px",
                        fontSize: "20px",
                        "@media screen and (max-width: 900px)": {
                          fontSize: "17px",
                          width:"100%"
                        },
                      }}
                      variant="contained"
                      type="button"
                            >
                      Register My Org
                    </CustomSubmitButton>
                    </Link>
                      </Grid>
                    </Grid>
                  </Grid>
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            // xs={12}
            // sm={12}
            // md={4}
            // lg={4}
            sx={{
              position: "relative",
              height: "100%",
              // maxHeight:"65vh",
              // minWidth:"315px",
              justifyContent: "flex-start",
              display: "flex",
              background: "FFF",
              minWidth: "30vw",

              overflow: "visible",
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                height: "100%",
                maxHeight: "calc(100% )",
                // padding: "72px 10px",
                alignItems: "center",
                justifyContent: "center",
                // paddingRight:"20px",
                
                // position: "absolute",
                zIndex: 1,
                // right: 0,
                backgroundSize: "cover",
              }}
            >
              <ScrollAnimation
                animateIn="bounceInRight"
                initiallyVisible={false}
                style={{
                  // width: "70%",
                  display: "flex",
                  height: "100%",
                }}
              >
                <Grid
                  sx={{
                    paddingTop: "15px",
                    height: "100%",                      
                    width: "100%",

                    "@media screen and (max-width: 900px)": {
                      paddingBottom: "3vw",
                      maxWidth: "100%",

                      height: "100%",
                    },
                  }}
                >
                  <img
                    src="images/nonprofit_attendees.png"
                    style={{
                      width: "100%",
                      borderRadius: "40px",
                      height: "100%",
                      maxHeight: "45vh",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                </Grid>
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Grid>
        {/* Group 2 */}
        <Grid
          container
          sx={{
            width: "100%",
            paddingTop:"15px",
            justifyContent: "space-evenly",
            display: "flex",
            flexWrap: "initial",
            flexDirection: "row",
            padding:"3.14vw 0",
            // overflow: "hidden",
            height: "100%",
            "@media screen and (max-width: 900px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Grid
            // xs={12}
            // sm={12}
            // md={4}
            // lg={4}
            sx={{
              position: "relative",
              height: "100%",
              // maxHeight:"65vh",
              // minWidth:"315px",
              justifyContent: "flex-start",
              display: "flex",
              background: "FFF",
              minWidth: "30vw",
              overflow: "visible",
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              container
              sx={{
                // width: "calc(100% )",
                // height: "calc(100% - 7.14vw)",
                // maxWidth: "calc(100% - 72px)",
                // maxHeight: "calc(100% - 72px)",
                // width: "calc(100%- 72px)",
                display: "flex",
                height: "100%",
                maxHeight: "calc(100% )",
                // padding: "72px 10px",
                alignItems: "center",
                justifyContent: "center",
                // paddingRight:"20px",
              

                // position: "absolute",
                zIndex: 1,
                // right: 0,
                backgroundSize: "cover",
              }}
            >
              <ScrollAnimation
                animateIn="bounceInLeft"
                initiallyVisible={false}
                style={{
                  // width: "70%",
                  display: "flex",
                  height: "100%",
                }}
              >
                <Grid
                  sx={{
                    paddingTop: "15px",
                    height: "100%",                      
                    width: "100%",

                    "@media screen and (max-width: 900px)": {
                      paddingBottom: "3vw",
                      maxWidth: "100%",

                      height: "100%",
                    },
                  }}
                >
                  <img
                    src="images/student-group.png"
                    style={{
                      width: "100%",
                      borderRadius: "40px",
                      height: "100%",
                      maxHeight: "45vh",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                </Grid>
              </ScrollAnimation>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              top: "-1px",
              minHeight: "100%",
              width: "100%",
              justifyContent: "center",
              background: "#FFF",
            }}
            sx={{
              // width: "50vw",
              // "@media screen and (min-width: 769px)": {
              //   maxHeight: "50vw",
              // },
              "@media screen and (max-width: 790px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              sx={{
                minHeight: "100%",
                "@media screen and (min-width: 769px)": {
                  // height: "calc(100% - 7.14vw)",
                  // maxHeight: "calc(100% - 72px)",
                },
                "@media screen and (max-width: 900px)": {
                  // padding: "3.14vw 0",
                  padding: 0,
                },
                // padding: "3.14vw",
                paddingLeft: "3.14vw",
              }}
              style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
                width: "100%",
                boxSizing: "inherit",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  minHeight: "100%",
                  "@media screen and (min-width: 900px)": {
                    // padding: "3.14vw",
                    paddingLeft: 0,
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    minHeight: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    color="black"
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      paddingTop: "25px",
                      textTransform: "uppercase",
                      fontSize: {
                        xs: "2.1em",
                        sm: "2.3rem",
                        md: "2.7rem",
                        lg: "3.2rem",
                      },
                      lineHeight: {
                        xs: "1.6rem",
                        sm: "1.8rem",
                        md: "2.2rem",
                        lg: "2.7rem",
                      },
                    }}
                  >
                    <span
                      style={{
                        color: "#62B6D0",
                      }}
                    >
                      {"10% "}
                    </span>
                    {"for Student Group Exhibitors"}
                  </Typography>
                 
                  <Grid>
                    <Typography
                      color="black"
                      gutterBottom
                      variant="subtitle1"
                      sx={{
                        paddingTop: "15px",
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                          // lg: "1.7rem",
                        },
                        lineHeight: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                          // lg: "1.7rem",
                        },
                      }}
                    >
                      {care_fair_data.student_ds}
                    </Typography>
                    <Grid sx={{ display: "flex" }}>
                     
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        typography="formStyle"
                        sx={{
                          display: "flex",
                          paddingTop: "15px",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <CustomSubmitButton
                          sx={{
                            margin: 0,
                            width: "300px",
                            marginBottom: "35px",
                            "@media screen and (max-width: 900px)": {
                              width: "100%",
                            },
                          }}
                          variant="contained"
                          type="submit"
                          // disabled={!isFormComplete()}
                          onClick={() =>
                            navigate("/contact", {
                              state: { registrationType: 1 },
                            })
                          }
                        >
                          Contact Us
                        </CustomSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
         {/* Group 3 */}
         <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            display: "flex",
            padding:"3.14vw 0",
            flexWrap: "initial",
            flexDirection: "row",
            // overflow: "hidden",
            height: "100%",
            "@media screen and (max-width: 900px)": {
              width: "100%",
              flexDirection: "column-reverse",
            },
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              top: "-1px",
              minHeight: "100%",
              justifyContent: "center",
              background: "#FFF",
            }}
            sx={{
              // width: "50vw",
              // "@media screen and (min-width: 769px)": {
              //   maxHeight: "50vw",
              // },
              "@media screen and (max-width: 790px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              sx={{
                minHeight: "100%",
                "@media screen and (min-width: 769px)": {
                  // height: "calc(100% - 7.14vw)",
                  // maxHeight: "calc(100% - 72px)",
                },
                "@media screen and (max-width: 900px)": {
                  // padding: "3.14vw 0",
                  padding: 0,
                },
                // padding: "3.14vw",
                paddingRight: "3.14vw",
              }}
              style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "center",
                width: "100%",
                boxSizing: "inherit",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  minHeight: "100%",
                  "@media screen and (min-width: 900px)": {
                    // padding: "3.14vw",
                    paddingLeft: 0,
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    minHeight: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    color="black"
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      paddingTop: "25px",
                      textTransform: "uppercase",
                      fontSize: {
                        xs: "2.1em",
                        sm: "2.3rem",
                        md: "2.7rem",
                        lg: "3.2rem",
                      },
                      lineHeight: {
                        xs: "1.6rem",
                        sm: "1.8rem",
                        md: "2.2rem",
                        lg: "2.7rem",
                      },
                    }}
                  >
                    <span
                      style={{
                        color: "#62B6D0",
                      }}
                    >
                      {"15% "}
                    </span>
                    {"for Community Leader Partners"}
                  </Typography>
                 
                  <Grid>
                    <Typography
                      color="black"
                      gutterBottom
                      variant="subtitle1"
                      sx={{
                        paddingTop: "15px",
                        fontSize: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                          // lg: "1.7rem",
                        },
                        lineHeight: {
                          xs: "1.3rem",
                          sm: "1.3rem",
                          md: "1.5rem",
                          // lg: "1.7rem",
                        },
                      }}
                    >
                      {care_fair_data.partner_ds}
                    </Typography>
                    <Grid sx={{ display: "flex" }}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        typography="formStyle"
                        sx={{
                          display: "flex",
                          paddingTop: "15px",
                          "@media screen and (max-width: 900px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <CustomSubmitButton
                          sx={{
                            margin: 0,
                            width: "300px",
                            marginBottom: "35px",
                            "@media screen and (max-width: 900px)": {
                              width: "100%",
                            },
                          }}
                          variant="contained"
                          type="submit"
                          // disabled={!isFormComplete()}
                          onClick={() =>
                            navigate("/contact", {
                              state: { registrationType: 1 },
                            })
                          }
                        >
                          Contact Us
                        </CustomSubmitButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            // xs={12}
            // sm={12}
            // md={4}
            // lg={4}
            sx={{
              position: "relative",
              height: "100%",
              // maxHeight:"65vh",
              // minWidth:"315px",
              justifyContent: "flex-start",
              display: "flex",
              background: "FFF",
              minWidth: "30vw",

              overflow: "visible",
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                height: "100%",
                maxHeight: "calc(100% )",
                // padding: "72px 10px",
                alignItems: "center",
                justifyContent: "center",
                // paddingRight:"20px",
                
                // position: "absolute",
                zIndex: 1,
                // right: 0,
                backgroundSize: "cover",
              }}
            >
              <ScrollAnimation
                animateIn="bounceInRight"
                initiallyVisible={false}
                style={{
                  // width: "70%",
                  display: "flex",
                  height: "100%",
                }}
              >
                <Grid
                  sx={{
                    paddingTop: "15px",
                    height: "100%",                      
                    width: "100%",

                    "@media screen and (max-width: 900px)": {
                      paddingBottom: "3vw",
                      maxWidth: "100%",

                      height: "100%",
                    },
                  }}
                >
                  <img
                    src="images/nonprofit_partners.png"
                    style={{
                      width: "100%",
                      borderRadius: "40px",
                      height: "100%",
                      maxHeight: "45vh",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                </Grid>
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
}
