import { Grid, Typography } from '@mui/material';
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import React from 'react';

export default function SectionHeader({ sectionTitle }) {
  return (
    <ThemeProvider theme={theme}>
      <Grid item style={{ width: "100%",paddingBottom:"1.14vw" }}>
        <Typography
          // variant="h2" 
                  sx={{
                    margin: 0,
                    display: "flex",
                    fontFamily: "Changa",
                    color: "#62B6D0",
                    fontSize:"1.25rem",
                    lineHeight:"1.6rem",
                    // "@media screen and (max-width: 900px)": {
                    //   flexDirection:"column",
                    // }

                  }}
        >
          {sectionTitle}
          <Typography
            variant="h6"
            component={'span'}
            style={{
              letterSpacing: "-0.09em",
              fontFamily: "Changa",
              paddingLeft: "5px",
              lineHeight:"1.6rem",
            }}
          >
            {`----`}
          </Typography>
        </Typography>
      </Grid>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h3= {
  color: "#000", 
  fontFamily: "Changa",
  fontSize: "3vw",
  lineHeight:"1.1em",

  "@media screen and (max-width:960px)": {
    fontSize: "28px !important",
    lineHeight:"1.1em",

  },
};

theme.typography.subtitle1 ={
  lineHeight:"1.2em",
  marginLeft:"7.14vw",
  fontSize:"2vw",
  fontWeight:500,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  "@media (max-width: 1077.8443113772px)":{
    fontSize: "18px !important",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft:"0",
    marginTop:"16px",
  },
}
