import { Grid, Typography } from "@mui/material";import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { SectionHeader } from '../../components';
import { data } from '../../data/data_file';

export default function AboutUsSection() {
  return (
    <Grid container className="cl-section-row-content-wrap">
      <Grid container direction="row" sx={aboutUsStyle}>
        <SectionHeader sectionTitle={"About Us"} />
        <Grid 
          item xs={12} sm={12} md={6} lg={6} 
          style={{ 
            justifyContent: "center",
          }} 
          sx={statTheme}
        >
          <ScrollAnimation animateIn="slideInLeft">
            <Typography variant="h3">
              {data.about_us.mission}
            </Typography>
          </ScrollAnimation>
        </Grid>
        <Grid 
          item xs={12} sm={12} md={6} lg={6} 
          style={{ 
            justifyContent: "center", 
          }} 
          sx={statTheme2}
        >
          <ScrollAnimation animateIn="slideInRight">
            <Typography  variant="subtitle1">
            {data.about_us.belief}
            </Typography>
          </ScrollAnimation>
        </Grid>
      </Grid>
    </Grid>
  );
}


const aboutUsStyle = {
  paddingLeft: "7.14vw",
  paddingRight: "7.14vw",
  "@media screen and (max-width: 790px)": {
    paddingTop: "7.28vw",
    paddingBottom: "0",
  },
};

const statTheme = {
  width:"50%",
  "@media screen and (max-width: 790px)": {
    width: "100%",
  },
};

const statTheme2 = {
  width:"50%",
  "@media screen and (max-width: 790px)": {
    width: "100%",
  },
};