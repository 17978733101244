import { CssBaseline, Grid } from "@mui/material";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material/styles";
import React, { useEffect } from "react";
import { PageHeader } from "../../components";
import { data } from "../../data/data_file";
import { AboutUsSection, HowWeWorkBottomSection, OurStorySection, ValuesSection } from "../../sections";
import './OurStory.css';


export default function About({page_title}) {
  useEffect(() => {
    document.title = page_title;
    window.scrollTo(0, 0)
  }, []);
 

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main> 
        <PageHeader 
          title={data.about_us.title} 
          description={data.about_us.intro} 
          background={"rgb(98, 182, 208)"}
        />
        <section className="cl-section-row cl-full-section-width">
          <AboutUsSection />
        </section>
        <section className="cl-section-row cl-full-section-width">
          <OurStorySection />
        </section>
        <section className="cl-section-row cl-full-section-width">
          <Grid container className="cl-section-row-content-wrap">
            <ValuesSection />
          </Grid>
        </section>
        <section className="cl-section-row cl-full-section-width">
          <HowWeWorkBottomSection />
        </section>
      </main>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.h4 = {
  fontSize: "1.8vw",
  fontWeight: "900",
  textAlign:"center",
  fontFamily: "Changa",
};

theme.typography.h3= {
  color: "#000", 
fontFamily: "Changa",
fontSize: "2.5rem",
lineHeight: "2.5rem",
"@media screen and (max-width:960px)": {
  fontSize: "2rem",
  lineHeight: "2rem",
},
};

theme.typography.subtitle1 ={
  lineHeight:"1.8rem",
  paddingLeft:"7.14vw",
  fontSize:"1.8rem",
  fontWeight:500,
  color: "#000", 
  fontFamily: "Changa", 
  alignSelf:"center",
  
  "@media screen and (max-width: 900px)": {
    fontSize:"1.5rem",
    lineHeight:"1.5rem",
    marginLeft:"0",
    marginTop:"16px",
    padding:0
  },
}

theme.typography.subtitle3 = {
  lineHeight:"1.2em",
  fontSize:"1.2vw",
  fontWeight: 500,
  fontFamily: "Changa",
  textAlign: "center",
  "@media (max-width: 1077.8443113772px)":{
    fontSize: "18px",
  },
  "@media screen and (max-width: 790px)": {
    marginLeft:"0",
    marginTop:"16px",
  },
}
