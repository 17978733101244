import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { care_fair_data } from "../../data/data_file";

export default function CareFairAboutSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  theme.typography.subtitle1 = {
    fontWeight: 500,
    fontFamily: "Changa",
  };
  theme.typography.h3 = {
    
    "@media (max-width: 900px)": {
      fontSize: "3rem",
    },
  };
 
  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        padding: "7.14vw 7.14vw 0px",
        display: "flex",
        flexDirection:"column",
        flexWrap: "wrap",
        marginTop: "-1px",
      }}
    >
      <ThemeProvider theme={theme}>
      <Typography
                    color="black"
                    gutterBottom
                    // variant="h3"
                    sx={{
                      fontFamily: "Changa",
                      lineHeight: {xl: "3.5rem", lg:"3rem", md:"2.7rem",sm:"2.3rem", xs:"2rem"},
                      fontWeight: 700,
                      fontSize:{xl: "3.5rem", lg:"3rem", md:"2.7rem",sm:"2.5rem", xs:"2.3rem"},
                    }}
                  >
                    {care_fair_data.title_1}
                  </Typography>
                  <Grid sx={{display:"flex", flexDirection:"column","@media screen and (max-width: 900px)": {
                          flexDirection:"column-reverse",justifyContent:"center"
                        }, }}>
                  <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                     
                      paddingBottom: "25px",
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        lg: "1.7rem",
                      },
                      "@media screen and (max-width: 900px)": {
                        paddingTop: "5%",paddingBottom:0
                      },
                    }}
                  >
                    {care_fair_data.about_the_event}
                  </Typography>
                  <Grid sx={{display:"flex", flexDirection:"row",justifyContent:"space-between","@media screen and (max-width: 900px)": {
                          flexDirection:"column",justifyContent:"center"
                        },}}>
                    {care_fair_data.event_goals.map((item, index) => (
                        <Grid key={index} sx={{display:"flex",flexDirection:"column",padding:""}}>
                        <Typography
                        color="#62b6d0"
                        variant="h1"
                        sx={{
                          fontFamily: "Changa",
                          lineHeight: "8rem",
                          fontWeight: 700,
                          fontSize:"8rem",
                          "@media screen and (max-width: 900px)": {
                            lineHeight: "6rem",
                          fontWeight: 700,
                          fontSize:"6rem",
                          },
                          "@media screen and (max-width: 600px)": {
                            lineHeight: "4.5rem",
                          fontWeight: 700,
                          fontSize:"4.5rem",
                          },
                        }}
                      >
                        {item.stat}
                      </Typography>
                      <Typography
                        color="black"
                        gutterBottom
                        variant="none"
                        sx={{
                          fontFamily: "Changa",
                          lineHeight: "1.3rem",
                          fontWeight: 700,
                          fontSize:"1.3rem",
                          "@media screen and (max-width: 900px)": {
                            lineHeight: "1.1rem",
                          fontWeight: 700,
                          fontSize:"1.1rem",
                          },
                          "@media screen and (max-width: 600px)": {
                            lineHeight: "1rem",
                          fontWeight: 700,
                          fontSize:"1rem",
                          },
                        }}
                      >
                        {item.goal}
                      </Typography>
                        </Grid>
                      ))}
                      <Grid sx={{position:"absolute", mt:"15px", right:"10%","@media screen and (min-width: 900px)": {
                            display:"none"
                          },}}> 
                  <img
                    src="images/goal.png"
                    style={{
                      width: "25vw",
                      maxHeight: "50vw",
                      height:"100%",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                  </Grid>
                  
                  </Grid>
                  </Grid>
        {/* Group 1 */}
        <Grid sx={{padding: "3.14vw 0 0",}}>
        <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "1.7rem", sm: "1.9rem", md: "2.1rem", lg: "2.3rem" },
                      lineHeight:  { xs: "1.7rem", sm: "1.9rem", md: "2.1rem", lg: "2.3rem" },
                 
                    "@media screen and (min-width: 900px)": {
                     display:"none"
                    },
                    }}
                  >
                    {care_fair_data.subtitle_1}
                  </Typography>
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
            display: "flex",
            flexWrap: "initial",
            flexDirection: "row",
            overflow: "hidden",

            "@media screen and (max-width: 900px)": {
              width: "100%",
              flexDirection: "column-reverse",
            },
          }}
        >
          
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              top: "-1px",
              background: "#FFF",
            }}
            sx={{
              // width: "50vw",
              // "@media screen and (min-width: 769px)": {
              //   maxHeight: "50vw",
              // },
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            
            <Grid
              sx={{
                "@media screen and (min-width: 769px)": {
                  height: "calc(100% - 7.14vw)",
                  maxHeight: "calc(100% - 72px)",
                },
                
                // padding: "3.14vw",
                paddingRight: "3.14vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                boxSizing: "inherit",
              }}
              
            >
              
              <Grid
                container
                direction="row"
                sx={{
                  "@media screen and (min-width: 769px)": {
                    
                    paddingLeft: 0,
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  style={{ width: "100%",height:"100%" }}
                >
                 
                 <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      fontSize: { xs: "1.7rem", sm: "1.9rem", md: "2.1rem", lg: "2.3rem" },
                      lineHeight:  { xs: "1.7rem", sm: "1.9rem", md: "2.1rem", lg: "2.3rem" },
                 
                    "@media screen and (max-width: 900px)": {
                     display:"none"
                    },
                    }}
                  >
                    {care_fair_data.subtitle_1}
                  </Typography>
                  <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      paddingTop: "15px",
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        lg: "1.7rem",
                      },
                    }}
                  >
                    {care_fair_data.clarity}
                  </Typography>
                  
                
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Grid>
          <Grid
          item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              position: "relative",
              minWidth: "35%",
              height: "100%",
              justifyContent: "center",
              display: "flex",
              background: "FFF",
              overflow: "hidden",
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              container
              sx={{
                // width: "calc(100% )",
                // height: "calc(100% - 7.14vw)",
                // maxWidth: "calc(100% - 72px)",
                // maxHeight: "calc(100% - 72px)",
                width: "calc(100%- 72px)",
                display: "flex",
                height: "100%",
                maxHeight: "calc(100% )",
                padding: "72px 10px",
                alignItems: "center",
                justifyContent: "center",
                // paddingRight:"20px",
                "@media screen and (max-width: 900px)": {
                  // padding: "20px 3.14vw 72px 3.14vw",
                  padding: "20px 3.14vw 20px 3.14vw",


                  maxWidth: "100%",
                },
                // position: "absolute",
                zIndex: 1,
                // right: 0,
                backgroundSize: "cover",
              }}
            >
              <ScrollAnimation
                animateIn="animate__bounceInRight"
                delay={0}
                initiallyVisible={false}
                style={{ 
                  // width: "70%", 
                  display: "flex" ,height:"100%"}}
              >
                <Grid
                  sx={{
                    paddingTop: "15px",
                    height: "100%",
                    width: "100%",
                    "@media screen and (max-width: 900px)": {
                      paddingBottom: "3vw",
                      height: "100%",
                    },
                  }}
                >
                  <img
                    src="images/about_image.png"
                    style={{
                      width: "100%",
                      borderRadius: "40px",
                      height: "100%",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                </Grid>
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Grid>
</Grid>
        {/* Group 2 */}
        {/* <Typography
                    color="black"
                    gutterBottom
                    // variant="h3"
                    sx={{
                      fontFamily: "Changa",
                      lineHeight: {xl: "3.5rem", lg:"3rem", md:"2.7rem",sm:"2.3rem", xs:"2rem"},
                      fontWeight: 700,
                      paddingTop:"20px",
                      fontSize:{xl: "3.5rem", lg:"3rem", md:"2.7rem",sm:"2.5rem", xs:"2.3rem"},
                    }}
                  >
                    {care_fair_data.title_2}
                  </Typography> */}
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
            display: "flex",
            flexWrap: "initial",
            flexDirection: "row",
            overflow: "hidden",

            "@media screen and (max-width: 900px)": {
              width: "100%",
              flexDirection: "column",
            },
          }}
        >
          
           <Grid
           item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            sx={{
              position: "relative",
              minWidth: "35%",
              height: "100%",
              justifyContent: "center",
              display: "flex",
              background: "FFF",
              overflow: "hidden",
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            <Grid
              container
              sx={{
                // width: "calc(100% )",
                // height: "calc(100% - 7.14vw)",
                // maxWidth: "calc(100% - 72px)",
                // maxHeight: "calc(100% - 72px)",
                width: "calc(100%- 72px)",
                display: "flex",
                height: "100%",
                maxHeight: "calc(100% )",
                padding: "72px 10px",
                alignItems: "center",
                justifyContent: "center",
                // paddingRight:"20px",
                "@media screen and (max-width: 900px)": {
                  // padding: "20px 3.14vw 72px 3.14vw",
                  padding: "20px 3.14vw 20px 3.14vw",

                  maxWidth: "100%",
                },
                // position: "absolute",
                zIndex: 1,
                // right: 0,
                backgroundSize: "cover",
              }}
            >
              <ScrollAnimation
                animateIn="animate__bounceInLeft"
                delay={0}
                initiallyVisible={false}
                style={{ 
                  // width: "70%", 
                  display: "flex" }}
              >
                <Grid
                  sx={{
                    paddingTop: "15px",
                    height: "100%",
                    width: "100%",
                    "@media screen and (max-width: 900px)": {
                      paddingBottom: "3vw",
                      height: "100%",
                    },
                  }}
                >
                  <img
                    src="images/matters_image.png"
                    style={{
                      width: "100%",
                      borderRadius: "40px",
                      height: "100%",
                    }}
                    alt={"background"}
                    loading="lazy"
                  />
                </Grid>
              </ScrollAnimation>
            </Grid>
          </Grid>
          
          <Grid
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignContent: "center",
              top: "-1px",
              background: "#FFF",
            }}
            sx={{
              // width: "50vw",
              // "@media screen and (min-width: 769px)": {
              //   maxHeight: "50vw",
              // },
              "@media screen and (max-width: 900px)": {
                width: "100%",
              },
            }}
          >
            
            <Grid
              sx={{
                "@media screen and (min-width: 769px)": {
                  height: "calc(100% - 7.14vw)",
                  maxHeight: "calc(100% - 72px)",
                },
                "@media screen and (max-width: 900px)": {
                  padding: "3.14vw 0",
                },
                // padding: "3.14vw",
                paddingLeft: "3.14vw",
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                boxSizing: "inherit",
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  "@media screen and (min-width: 769px)": {
                    padding: "3.14vw",
                    paddingLeft: 0,
                  },
                }}
              >
                <ScrollAnimation
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  style={{ width: "100%" }}
                >
                 
                  <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      fontWeight: 700,
                      
                      fontSize: { xs: "1.7rem", sm: "1.9rem", md: "2.1rem", lg: "2.3rem" },
                      lineHeight:  { xs: "1.7rem", sm: "1.9rem", md: "2.1rem", lg: "2.3rem" },
                    
                    }}
                  >
                    {care_fair_data.subtitle_2}
                  </Typography>
                  <Typography
                    color="black"
                    gutterBottom
                    variant="subtitle1"
                    sx={{
                      paddingTop: "15px",
                      fontSize: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        lg: "1.7rem",
                      },
                      lineHeight: {
                        xs: "1.3rem",
                        sm: "1.3rem",
                        md: "1.5rem",
                        lg: "1.7rem",
                      },
                    }}
                  >
                    {care_fair_data.intelligence}
                  </Typography>
                </ScrollAnimation>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </Grid>
  );
}
