import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Typography
} from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validate } from 'validate.js';
import {
  CustomMenuItem,
  CustomMessageBox,
  CustomSelect,
  CustomSendDonationButton,
  CustomTextField,
  DonationTextField
} from '../../components/FormComponents/FormComponents';
import {
  NumericFormatCustom,
  PhoneNumberPatternFormatCustom,
} from '../../components/InputComponents/InputComponents';
import { Programs } from '../../data/DonationData/data';
import { schema } from './donateSchema';

const formValues = {
  message: '',
};

export default function CheckoutForm() {
  const donationForm = useRef();
  const navigate = useNavigate();
  const [characterLimit] = useState(500);
  const [formState, setFormState] = useState({
    isValid: false,
    values: formValues,
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  
  const handleChange = (e) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      },
      touched: {
        ...formState.touched,
        [e.target.name]: true
      }
    }));
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    navigate('/donate/review', { 
      state: {
        firstName: formState.values.firstName,
        lastName: formState.values.lastName,
        emailAddress: formState.values.emailAddress,
        phoneNumber: formState.values.phoneNumber,
        message: formState.values.message,
        donationAmount: formState.values.donationAmount,
        programTitle: formState.values.program.programTitle,
        programDescription: formState.values.program.programDescription
      } 
    });
  };

  const hasError = (field) => formState.errors[field] ? formState.touched[field] ? true : false : false;

  return (
    <>
      <Grid container sx={{ mb: 8 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="topText">
              Clarintel provides free events, data, and technology that provide a measurable increase in nonprofit community visibility, philanthropic engagement, operational efficiency, employment, and technological aptitude, increase individual access to nonprofits and philanthropic opportunities of interest, and expand the positive impact driven by change agents and nonprofits on all levels.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <form ref={donationForm} onSubmit={handleSubmit}>
        <Grid container direction="row">
          {/* First Name and Last Name */}
          <Grid item xs={12} sm={12} md={6} lg={6} typography="formStyle">
            <Typography variant="fieldTitle">First Name*</Typography>
            <CustomTextField 
              name="firstName"
              placeholder="First Name"
              variant="outlined"
              size="medium"
              id="firstName"
              fullWidth
              type="text"
              onChange={handleChange}
              helperText={
                hasError('firstName') ? formState.errors.firstName[0] : null
              }
              error={hasError('firstName')}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} typography="formStyle">
            <Typography variant="fieldTitle">Last Name*</Typography>
            <CustomTextField 
              name="lastName"
              placeholder="Last Name"
              variant="outlined"
              size="medium"
              id="lastName"
              fullWidth
              type="text"
              onChange={handleChange}
              helperText={
                hasError('lastName') ? formState.errors.lastName[0] : null
              }
              error={hasError('lastName')}
            />
          </Grid>

          {/* Email Address */}
          <Grid item xs={12} sm={12} md={12} lg={6} typography="formStyle">
            <Typography variant="fieldTitle">Email Address*</Typography>
            <CustomTextField 
              name="emailAddress"
              placeholder="Email Address"
              variant="outlined"
              size="medium"
              id="email"
              fullWidth
              type="email"
              onChange={handleChange}
              helperText={
                hasError('emailAddress') ? formState.errors.emailAddress[0] : null
              }
              error={hasError('emailAddress')}
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12} sm={12} md={12} lg={6} typography="formStyle">
            <Typography variant="fieldTitle">Phone Number*</Typography>
            <CustomTextField 
              name="phoneNumber"
              placeholder="(123) 345-6789"
              variant="outlined"
              size="medium"
              id="phone"
              fullWidth
              InputProps={{ 
                inputComponent: PhoneNumberPatternFormatCustom
              }}
              type="tel"
              onChange={handleChange}
              helperText={
                hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null
              }
              error={hasError('phoneNumber')}
            />
          </Grid>

          {/* Message */}
          <Grid item xs={12} typography="formStyle" sx={{  pb:"1.14vw"}} >
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="fieldTitle">
                Messsage (Optional - 500 Character Limit)
              </Typography>
              <Typography variant="fieldTitle">{formState.values.message.length}/{characterLimit}</Typography>
            </Grid>
            <CustomMessageBox
              name="message"
              placeholder="Message"
              variant="outlined"
              multiline
              rows={6}
              id="message"
              fullWidth
              type="text"
              onChange={handleChange}
              helperText={
                hasError('message') ? formState.errors.message[0] : null
              }
              error={hasError('message')}
            />
          </Grid>

          {/* Donation Focus */}
          <Grid item xs={12} sm={12} typography="formStyle" >
            <Typography variant="fieldTitle">Where would you like Clarintel to apply your donation?</Typography>
            <FormControl fullWidth>
              {formState.values.program === undefined ? (
                <InputLabel 
                  sx={{
                    top:"calc(50% - 1.3em)",  
                    fontFamily: "Changa",
                    fontSize: "15px", 
                    paddingLeft:"5px",
                    color:"#A1A1A1"
                  }} 
                  disableAnimation 
                  shrink={false} 
                  focused={false} 
                  id='item_type_label'
                >
                  Select a donation area
                </InputLabel>
                ) : null
              }
              <CustomSelect
                id="program"
                fullWidth
                value={formState.values.program || ''}
                onChange={handleChange}
                name="program"
                style={{
                  borderRadius: "40px",
                  minWidth:"100%",
                 
                  height: "70px",
                  fontFamily: "Changa",
                  fontSize: "18px",
                  marginTop:"10px",
                }}
                error={hasError('program')}
              >
                {Programs.map((program, item) => (
                  <CustomMenuItem style={{ textWrap:"wrap",}} key={item} value={program}>{program.programTitle}</CustomMenuItem>
                ))}
              </CustomSelect>
            </FormControl>
            <FormHelperText>
              {hasError('program') ? formState.errors.program[0] : null}
            </FormHelperText>
          </Grid>
          
          {/* Donation Total */}
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item sx={{display:"flex", flexDirection:"column",alignItems:"center"}}  typography="formStyle">
              <Typography 
                sx={{ 
                  fontWeight: "900", 
                  textAlign: "center", 
                  fontFamily: "Changa", 
                  fontSize: "18px",
                }}
              >
               Your Donation*
              </Typography>
              <Typography 
                sx={{ 
                  color: "grey", 
                  textAlign: "center", 
                  fontFamily: "Changa", 
                  fontSize: "14px",
                  paddingBottom:"30px",
                }}
              >
                Clarintel currently uses Stripe to process donations. In order to process your donation, Stripe charges us a 2.9% processing fee including $0.30 for each donation we accept through their service. Beneath your donation amount, please view the actual amount you are donating given Stripes service and processing fees. For your donation to be processed successfully, please enter at least $1.00. For your reference, a $10.61 total donation is the same as donating $10.00.
              </Typography>
              
              <DonationTextField 
                name="donationAmount"
                id="donationAmount"
                onChange={handleChange}
                sx={{maxWidth:"500px"}}
                InputProps={{ 
                  inputComponent: NumericFormatCustom
                }}
                helperText={
                  hasError('donationAmount') ? formState.errors.donationAmount[0] : null
                }
                error={hasError('donationAmount')}
              />
            </Grid>
          </Grid>
          <Typography 
                sx={{ 
                  color: "grey", 
                  width:"100%",
                  textAlign: "center", 
                  fontFamily: "Changa", 
                  fontSize: "14px",
                  display:formState.values.donationAmount?"block":"none",
                }}
              >
                {"Actual donation amount: $" + (formState.values.donationAmount - formState.values.donationAmount*.029 - .3 ).toFixed(2)}
              </Typography>
          {/* Submit Button */}
          <Grid 
            item 
            xs={12} 
            typography="formStyle" 
            sx={{
              display: "flex",
              justifyContent: "center" }} 
          >
            <CustomSendDonationButton
              variant="contained"
              type="submit"
              disabled={!formState.isValid}
            >
              Review Donation
            </CustomSendDonationButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
