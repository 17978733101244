import { CssBaseline } from '@mui/material';
import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import { Route, Routes } from 'react-router-dom';
import './app.css';
import { AppFooter, AppNavbar } from './components';
import { data } from './data/data_file';
import {
  About,
  Contact,
  ContactFormSubmission,
  Donate,
  DonationSuccess,
  Home,
  Leadership,
  ReviewDonation,
} from './pages';
import AirProgramPage from './pages/AirProgram';
import CKSurvey from './pages/CKSurvey/CKSurvey';
import CKSurveyFormSubmission from './pages/CKSurvey/CKSurveyFormSubmission';
import FundraiserPage from './pages/Fundraiser';
import Programs from './pages/Programs';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
import Error from './Error';
import AttendeeRegistrationSubmission from './pages/AttendeeRegistrationPage/AttendeeRegistrationSubmission';
import AttendeeTerms from './pages/AttendeeRegistrationPage/AttendeeTerms';
import CareFairPage from './pages/CareFairPages/CareFairPage';
import CommunityGuidelines from './pages/NonprofitRegistrationPage/CommunityGuidelines';
import RegisterPage from './pages/RegistrationPage/Register';
// import SocialCenter from './pages/SocialCenter/SocialCenterPage';
// register Swiper custom elements
register();

ReactGA.initialize(process.env.REACT_APP_API_GOOGLE_ANALYTICS);

function removeLocationHash(){
  var noHashURL = window.location.href.replace(/#.*$/, '');
  window.history.replaceState('', document.title, noHashURL) ;
}

const pathToVar = {
  '/':0,
  '/about':1,
  '/about/leadership': 1,
  '/programs':2,
  '/programs/air':2,
  '/programs/philanthropic-access':2,
  '/programs/unity':2,
  '/join-us':3,
  '/join-us/completion':3,
  '/contact':4,
  '/contact/submitted':4,
  '/philanthropic-spectrum-survey':5,
  '/philanthropic-spectrum-survey/submitted':5,
  // '/register':6,
  // '/register/general':6,
  '/register/nonprofits':6,
  '/care':7,
'/care/register':7,
'/care/register/success':7,
'/care/register/payment':7,
'/care/register/nonprofit_terms':7,
'/care/register/terms':7,
'/care/register/payment/success':7,


};

window.addEventListener("popstate", function(event){
  removeLocationHash();
});

window.addEventListener("hashchange", function(event){
  event.preventDefault();
  removeLocationHash();
});

window.addEventListener("load", function(){
  removeLocationHash();
});

export default function App() {
  useEffect(() => {
    ReactGA.send({ 
      hitType: "pageview", 
      page: window.location.pathname + window.location.search 
    });
  }, []);

  return (
    <>
      <div>

        <CssBaseline />
        <AppNavbar currentPath={pathToVar[window.location.pathname]} />
        <Routes>     
        {/* <Route path='*' element={<NotFound />}/> */}

          <Route exact path="/" element={<Home page_title="Clarintel, Inc."/>} />
          <Route path="/about" element={<About page_title="About Us - Clarintel, Inc."/>} />
          <Route path="/about/leadership" element={<Leadership page_title="Our Leaders - Clarintel, Inc." leaders={data.leaders}/>} />
          <Route path="/contact" element={<Contact page_title="Contact Us - Clarintel, Inc."/>} />
          <Route path="/contact/submitted" element={<ContactFormSubmission page_title="Contact Us Submission - Clarintel, Inc." />} />
          <Route path="/philanthropic-spectrum-survey" element={<CKSurvey page_title="The Philanthropic Spectrum Survey - Clarintel, Inc."/>} />
          <Route path="/philanthropic-spectrum-survey/submitted" element={<CKSurveyFormSubmission page_title="Survey Submission - Clarintel, Inc." />} />
          <Route path="/programs" element={<Programs page_title="Our Programs - Clarintel, Inc." />} /> 
          <Route 
            path="/programs/air" 
            element={<AirProgramPage op_data={data.opportunities_page.opportunities[0]} flyer_data={data.air_program_details} page_title="AIR Program - Clarintel, Inc." />} 
          />
          <Route 
            path="/programs/philanthropic-access" 
            element={<FundraiserPage op_data={data.opportunities_page.opportunities[1]} page_title={"Philanthropic Access - Clarintel, Inc."} />} 
          />
          <Route 
            path="/programs/unity" 
            element={<FundraiserPage op_data={data.opportunities_page.opportunities[2]} page_title={"Community Unity - Clarintel, Inc."} />}  
          />
          <Route path="/donate" element={<Donate page_title={"Fund the Future - Clarintel, Inc."}/>} /> 
          <Route path="/donate/review" element={<ReviewDonation page_title={"Review My Donation - Clarintel, Inc."}/>} />
          <Route path="/donate/success" element={<DonationSuccess page_title={"Donation Complete - Clarintel, Inc."}/>} />
          <Route path="/care" element={<CareFairPage op_data={data.opportunities_page.opportunities[2]} page_title={"Care Fair - Clarintel, Inc."}/>} />
          <Route path="/care/register" element={<RegisterPage op_data={data.opportunities_page.opportunities[2]} page_title={"Register Today - Clarintel, Inc."}/>} />
          <Route path="/care/register/success" element={<AttendeeRegistrationSubmission op_data={data.opportunities_page.opportunities[2]} page_title={"Thank You - Clarintel, Inc."}/>} />
          <Route path="/care/register/terms" element={<AttendeeTerms page_title={"Terms & Agreements - Clarintel, Inc."}/>} />

          {/* <Route path="/register/general" element={<AttendeeRegisteration op_data={data.opportunities_page.opportunities[2]} page_title={"Attendee Registration - Clarintel, Inc."}/>} />
          <Route path="/register/general-old" element={<AR op_data={data.opportunities_page.opportunities[2]} page_title={"Old - Clarintel, Inc."}/>} />

          <Route path="/register/nonprofits" element={<NonprofitRegistrationPage op_data={data.opportunities_page.opportunities[2]} page_title={"Nonprofit Registration - Clarintel, Inc."}/>} /> */}
          
          {/* <Route path="/care/register/payment" element={<ReviewNonprofitRegistrations page_title={"Review My Registratoin - Clarintel, Inc."}/>} />
          <Route path="/care/register/payment/success" element={<NonprofitPaymentSuccess page_title={"Nonprofit Registration Complete - Clarintel, Inc."}/>} />
          <Route path="/care/register/nonprofit_terms" element={<NonprofitTerms page_title={"Terms & Agreements - Clarintel, Inc."}/>} /> */}

          <Route path="/community-guidelines" element={<CommunityGuidelines page_title={"Terms & Agreements - Clarintel, Inc."}/>} />

          <Route path="*" element={<Error page_title={"Error"}/>} />
          <Route path="/error" element={<Error page_title={"Error"}/>} />

        </Routes>

        <AppFooter />
      </div>
    </>
  );
}
