import { Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { CustomSubmitButton } from "../../components/FormComponents/FormComponents";

export default function CareFairLandingSection() {

  const navigate = useNavigate();

  let theme = createTheme();
  theme = responsiveFontSizes(theme);
  theme.typography.h3 = {
    color: "#000",
    fontFamily: "Changa",
    fontSize: "7rem",
    lineHeight: "7rem",
    "@media screen and (max-width:900px)": {
      fontSize: "5rem",
      lineHeight: "4.5rem",
      paddingBottom:"30px",
      paddingTop:"30px",
    },
    "@media screen and (max-width:600px)": {
      fontSize: "4rem",
      lineHeight: "3.5rem",
      paddingBottom:"30px",
      paddingTop:"30px",
    },
  };

  

  var statTheme = {
    width: "50%",
    "@media screen and (max-width: 790px)": {
      width: "100%",
    },
  };
  var statTheme2 = {
    width: "50%",
    "@media screen and (max-width: 790px)": {
      width: "100%",
    },
  };
  return (
    <Grid container>
      <Grid
        container
        style={{
          fontSize: 10,
          // background: "linear-gradient(180deg, #1A1F35 0%, #000 100%)",
          background: "#FFF",
          color: "black",
          paddingLeft: "7.14vw",
          paddingRight: "7.14vw",
          paddingTop: "150PX",
          paddingBottom: "7.14vw",
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <ThemeProvider theme={theme}>
          <Grid
            container
            style={{ textAlign: "left", alignItems: "center" }}
            sx={{
              paddingTop: "3.14vw",
              "@media screen and (max-width: 790px)": {
                paddingTop: "7.28vw",
                paddingBottom: "0",
              },
            }}
          >
                 
            <Grid item style={{ width: "100%", paddingBottom: "3vw" }} xs={12}>
              <Typography
                // variant="h2"
                sx={{
                  margin: 0,
                  display: "flex",
                  fontFamily: "Changa",
                  color: "#62B6D0",
                  fontSize: "1.25rem",
                  lineHeight: "1.6rem",
                  "@media screen and (max-width: 900px)": {
                    flexDirection:"column",
                    textAlign:"center"
                  }
                }}
              >
                {`Clarintel Presents `}
                <Typography
                  variant="h6"
                  component={"span"}
                  sx={{
                    letterSpacing: "-0.09em",
                    fontFamily: "Changa",
                    paddingLeft: "5px",
                    lineHeight: "1.6rem",
                    "@media screen and (max-width: 900px)": {
                      display:"none"
                    }
                  }}
                >
                  {`----`}
                </Typography>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ justifyContent: "center" }}
              sx={[statTheme,{"@media screen and (max-width: 900px)": {
                textAlign:"center",
              }}]}
            >
              <Typography variant="h3">{"The Clarintel Care Fair"}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ justifyContent: "center", display: "flex", flexDirection:"column",padding:"0px 25px" }}
              sx={statTheme2}
            >
              <CustomSubmitButton
                sx={{
                  margin: "15px 0 15px 0",
                  backgroundColor: "#62B6D0",
                  padding:"1.14vw",
                  width:"auto !important",
                  color: "#FFF",
                  fontSize: "48px",
                  borderRadius: "50px",
                  lineHeight: "64px",
                  "@media screen and (max-width: 900px)": {
                    display:"none"
                  },
                  transition: "all 1s ease-out",
                  // "@media screen and (max-width: 900px)": {
                  //   padding:"3.14vw",
                  // },
                  "@media screen and (max-width: 1000px)": {
                    fontSize:"36px",
                    lineHeight:"36px"
                  },
                  "&:hover": {
                    backgroundColor: "#62B6D0",
                    color: "#FFFF",
                    transform: "scale(1.3)",
                    transitionTimingFunction: "linear",
                    marginBottom:"scale(1.3)",
                    transition: "all 1s ease",
                  },
                }}
                variant="contained"
                type="button"
                onClick={(e) =>
                  (sessionStorage.setItem("registrationType", JSON.stringify(1)),navigate("/care/register",{state:{registrationType:1},replace:true}))
                  
                }              >
                Register Myself
              </CustomSubmitButton>
              <Link to="https://forms.office.com/r/54GNhL0Byx"  target="_blank" >
              <CustomSubmitButton
                sx={{
                  margin: "15px 0 15px 0",
                  backgroundColor: "#F1B9D0",
                  padding:"1.14vw",
                  width:"100% !important",
                  color: "#FFF",
                  fontSize: "48px",
                  borderRadius: "50px",
                  lineHeight: "64px",
                  "@media screen and (max-width: 900px)": {
                    display:"none"
                  },
                  transition: "all 1s ease-out",
                  // "@media screen and (max-width: 900px)": {
                  //   padding:"3.14vw",
                  // },
                  "@media screen and (max-width: 1000px)": {
                    fontSize:"36px",
                    lineHeight:"36px"
                  },
                  "&:hover": {
                    backgroundColor: "#F1B9D0",
                    color: "#FFFF",
                    transform: "scale(1.3)",
                    transitionTimingFunction: "linear",

                    transition: "all 1s ease",
                  },
                }}
                variant="contained"
                type="button"
                        >
                Register My Org
              </CustomSubmitButton>
              </Link>
            </Grid>
            
          </Grid>
        </ThemeProvider>
      </Grid>

    </Grid>
  );
}
