import { useScrollTrigger, Slide } from "@mui/material";

export default function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide style={{ paddingRight: 0 }}
      appear={false}
      direction={"down"}
      in={!trigger}
    >
      {children}
    </Slide>
  );
}