import { Button, Grid, Typography } from "@mui/material";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes
} from "@mui/material/styles";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

export default function PartnerCollabSection() {
  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  theme.typography.h3 = {
    fontSize: "2.5rem",
    fontFamily: "Changa",
    lineHeight: "2.5rem",
    "@media (max-width: 1077px)": {
      fontSize: "2rem",
      lineHeight: "2rem",
    },
    "@media (max-width: 900px)": {
      fontSize: "1.7rem",
      lineHeight: "1.7rem",
    },
  };
  theme.typography.h4 = {
    fontSize: "1.3rem",
    fontFamily: "Changa",
    lineHeight: "1.3rem",
    fontWeight: 500,
    // "@media (max-width: 1077px)": {
    //   fontSize: "1.2rem",
    //   lineHeight: "1.2rem",
    // },
    // "@media (max-width: 700px)": {
    //   fontSize: "1.1rem",
    //   lineHeight: "1.1rem",
    // },
  };
  theme.typography.subtitle1 = {
    lineHeight: "1.5rem",
    fontSize: "1.5rem",
    fontWeight: 500,
    fontFamily: "Changa",
    "@media (max-width: 1285px)": {
      lineHeight: "18px",

      fontSize: "18px !important",
    },
    "@media screen and (max-width: 790px)": {
      lineHeight: "1.1em",
      marginTop: "12px",
    },
  };
  
  
  const text = "Interested in Collaborating, Partnering, or Discussing More with Us?";
  const text2 =
    "Through free programs, data, and technology, we make it easy to access social issue information, philanthropic opportunities and organizations that drive a positive impact you can trust within your community and beyond.";

  // var theory = `[Methodology] Our programs are developed with 1 thought in mind... drive revolutionary change within philanthropy and social impact: data and relationships. Through our unique framework, ... creates the environment where unbreakable bonds are formed using credible information, data, and truth that deepens societal understanding, increases knowledgeable philanthropic engagement, enhances social unification, and ultimately leads to human equity.`;
  
  return (
    <Grid
      container
      style={{
        fontSize: 10,
        background: "#FFFF",
        color: "white",
        padding: "7.14vw",
        paddingTop:"7.14vw",
        display: "flex",
        flexWrap: "wrap",
        marginTop: "-1px",
        

      }}
    >
      <ThemeProvider theme={theme}>
              <Grid
                container
                direction="row"
                sx={{
                  justifyContent:"center",
                  textAlign:"center",
                  // "@media screen and (min-width: 769px)": {
                  //   padding: "0px 5vw 0px",
                  // },
                }}
              >
                <ScrollAnimation animatePreScroll={false}
                  animateIn="fadeIn"
                  initiallyVisible={false}
                  style={{ width: "100%" }}
                >
                  <ThemeProvider theme={theme}>
                    <Typography
                      color="black"
                      gutterBottom
                      variant="h3"
                      style={{ textAlign:"left",paddingBottom:5 }}>
                      {text}
                    </Typography>
                  </ThemeProvider>
                  <Grid>
                      <Typography
                        color="black"
                        gutterBottom
                        variant="h4"
                        sx={{
                          fontFamily: "Changa",
                          textAlign:"left",

                          paddingBottom: "10px",
                          
                        }}
                      >
                        {text2}
                      </Typography>
                  </Grid>
                </ScrollAnimation>
                <Button
                href="/contact"
                sx={{
                  // top: "-1px",
                  fontSize: "15px",
                  "&:hover": {
                    background: "rgba(98, 182, 208,0.7) !important",
                  },
                    borderRadius: 30,
                 
                }}
                
                style={{
                  color: "white",
                  fontFamily: "Changa",
                  fontWeight: 700,
                  letterSpacing: ".15em",
                  display: "flex",
                  padding: "15px 30px",
                  lineHeight: "1em",
                  backgroundColor: "#62B6D0",
                  transition: "background-color .3s",
                }}
              >
               
                Contact Us
              </Button>
        
        </Grid>
      </ThemeProvider>
    </Grid>
  );
}
