import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Collapse,
  Grid,
  ThemeProvider,
  Typography,
  createTheme, responsiveFontSizes
} from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import api from '../../api';
import { CustomSendDonationButton } from '../../components/FormComponents/FormComponents';
import { ConvertToReadableDateWithTime } from '../../helpers/dateHelpers';


export default function Payment(data) {
  const paymentForm = useRef();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [message, setMessage] = useState({ error: false, statusMessage: null });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    

    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage({ error: false, statusMessage: "Payment Succeeded!" });
          break;
        case "processing":
          setMessage({ error: false, statusMessage: "Your payment is processing." });
          break;
        case "requires_payment_method":
          setMessage({ 
            error: true, 
            statusMessage: `Your payment was not successful, please try again. Reason: ${paymentIntent.last_payment_error.message}`
          });
          break;
        default:
          setMessage({ error: true, statusMessage: "Something went wrong." });
          break;
      }
    });

  }, [stripe]);

  useEffect(() => {
    setTimeout(() => {
      setIsFormLoading(false);
    }, 5000)
  }, []);

  const handleError = (error) => {
    setOpen(true);

    setMessage({ error: true, statusMessage: error.message});

    setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  const handleSuccess = (data) => {
    navigate('/donate/success', { state: data });
  };

  const handleSendingEmail = (data) => {
    const templateParams = {
      email_address: data.email_address,
      to_name: data.first_name,
      donation_id: data.id,
      organization: 'Clarintel, Inc.',
      program: data.program_title,
      donor_name: `${data.first_name} ${data.last_name}`,
      donation_amount: `$${parseFloat(data.donation_amount).toFixed(2)}`,
      donation_date_and_time: ConvertToReadableDateWithTime(data.created_at),
      donation_method: `${data.card_type} ${data.payment_method_type} ****${data.last_4}`,
      donation_message: data.message,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Stripe.js hasn't loaded yet.
    // Make sure to disable form submission until Stripe.js has loaded
    if (!stripe || !elements) {
      return;
    }
    
    setIsLoading(true);

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: { 
        return_url: "http://localhost:3000"
      },
      redirect: "if_required"
    });
    
    // Show error to your customer (for example, payment details incomplete)
    if (error) {
      handleError(error)
    } 
    else if (paymentIntent && paymentIntent.status === "succeeded") {
      // Send receipt to database after confirmedPayment
      const paymentMethodDetails = await api.GetPaymentMethodData(paymentIntent.payment_method);

      const donationReceipt = await api.PostDonationReceipt(
        data, paymentIntent.id, paymentMethodDetails
      );
      //   console.log(data)
      //   console.log(donationReceipt)
      // // send email with donation receipt data
      // handleSendingEmail(donationReceipt);

      // navigate user with receipt data to success page
      handleSuccess(donationReceipt);
    }
    else {
      // console.log({ error: "An unexpected error occurred." });
      handleError("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={6}
      >
        <Grid item xs={12} md={6} lg={6}>
          <Grid item sx={{ mb: 3 }}>
            <Typography variant="title">Program Title:</Typography>
            <Typography variant="description">{data.data.programTitle}</Typography>
          </Grid>
          <Grid item sx={{ mb: 3 }}>
            <Typography variant="title">Program Description:</Typography>
            <Typography variant="description">{data.data.programDescription}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="title">Donation Amount:</Typography>
            <Typography variant="donationAmount">${parseFloat(data.data.donationAmount).toFixed(2)} USD</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <form ref={paymentForm} onSubmit={handleSubmit}>
            <Grid spacing={2} justifyContent="center" alignContent="center" alignItems="center" maxWidth="lg">
              <Grid item sx={{ mb: 6 }}>
                <PaymentElement />
              </Grid>
              <Grid 
                item 
                xs={12} 
                typography="formStyle" 
                sx={{
                  display: "flex",
                  justifyContent: "center" 
                }} 
              >
                {!isFormLoading ? (
                  <>
                    <CustomSendDonationButton
                      variant="contained"
                      type="submit"
                      disabled={isLoading || !stripe || !elements}
                    >
                      {isLoading ? <CircularProgress size={20} /> : "Donate"}
                    </CustomSendDonationButton>
                    {message.error &&
                      <Box 
                        sx={{ 
                          width: '100%', 
                          position: 'fixed', 
                          top: 0, 
                          left: 0, 
                          right: 0, 
                          zIndex: 9999,
                          p: 4
                        }}
                      >
                        <Collapse in={open}>
                          <Alert severity="error">
                            <AlertTitle>
                              <Typography variant="errorMessageTitle">
                                An Error Occured!
                              </Typography>
                            </AlertTitle>
                            <Typography variant="errorMessage">{message.statusMessage}</Typography>
                          </Alert>
                        </Collapse>
                      </Box>
                    }
                  </>
                )
                :
                  <CircularProgress size={40} />
                }
              </Grid>
              {/* Add back button */}
            </Grid>
          </form>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

let theme = createTheme();

theme = responsiveFontSizes(theme);

theme.typography.title = {
  fontFamily: "Changa",
  fontSize: "1.7rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "2.7rem",
  fontWeight: "900",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.5rem",
    lineHeight: "1.7rem",
  },
}

theme.typography.description = {
  fontFamily: "Changa",
  fontSize: "1.5rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "1.8rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
    lineHeight: "1.2rem",
  },
}

theme.typography.errorMessage = {
  fontFamily: "Changa",
  fontSize: "1.0rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "0.9rem",
  },
}

theme.typography.errorMessageTitle = {
  fontFamily: "Changa",
  fontSize: "1.2rem",
  textAlign: "center",
  flexGrow: 1,
  display: "flex",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.0rem",
  },
};

theme.typography.donationAmount = {
  fontFamily: "Changa",
  fontSize: "4.0rem",
  textAlign: "left",
  flexGrow: 1,
  display: "flex",
  lineHeight: "3.5rem",
  "@media screen and (max-width: 900px)": {
    fontSize: "1.8rem",
    lineHeight: "1.8rem",
  },
}